import React from "react";
import { TextField } from "@material-ui/core";
import { fieldToTextField } from "formik-material-ui";
import PriceFormat from "./PriceFormat";

export default function PriceField({ InputProps, ...props }) {
  return (
    <TextField
      {...fieldToTextField(props)}
      InputProps={{
        ...InputProps,
        inputComponent: PriceFormat,
      }}
    />
  );
}
