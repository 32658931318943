import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Typography } from "@material-ui/core";
import { GLOBALS } from "../App";
import { formatPrice } from "../nestiqaUtils";
import StyledTableCell from "./StyledTableCell";

const useStyles = makeStyles(theme => ({
  root: {
    width: "calc(100% - 40px)",
  },
  table: {
    minWidth: 650,
    marginRight: "20px",

    "& .MuiTableRow-root .MuiTableCell-root": {
      padding: "16px 16px 16px 0",
    },
  },
  headerRow: {
    "& .MuiTableCell-root.MuiTableCell-head": {
      border: "none",
    },
  },
  bodyRow: {
    cursor: "pointer",

    "& .MuiTableCell-root.MuiTableCell-body": {
      borderBottom: `1px solid ${theme.palette.secondary.main}`,
    },
  },
  textEmpty: {
    marginTop: "10px",
    marginBottom: "-20px",
  },
}));

export default function ListingApplicationTable(props) {
  const classes = useStyles();
  const { applications } = props;

  if (!Array.isArray(applications) || applications.length === 0) {
    return (
      <Typography
        variant="subtitle1"
        align="left"
        color="textPrimary"
        className={classes.textEmpty}
      >
        {GLOBALS.MESSAGES.NO_ITEMS}
      </Typography>
    );
  }

  return (
    <TableContainer className={classes.root}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow className={classes.headerRow}>
            <TableCell>Full Name</TableCell>
            <TableCell align="center">Household Size</TableCell>
            <TableCell align="center">Submitted</TableCell>
            <TableCell align="center">Showing Done</TableCell>
            <TableCell align="center">Move in</TableCell>
            <TableCell align="center">Income</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {applications.map(row => (
            <TableRow
              key={row.id}
              className={classes.bodyRow}
              hover={true}
              onClick={event => props.onItemClick(event, row.id)}
            >
              <StyledTableCell align="left">
                {row.tenant.firstName && row.tenant.lastName
                  ? `${row.tenant.firstName} ${row.tenant.lastName}`
                  : "NA"}
              </StyledTableCell>
              <StyledTableCell align="center">
                {row.tenant.householdSize}
              </StyledTableCell>
              <StyledTableCell align="center">
                {row.submitted ? row.submitted.toLocaleDateString() : "NA"}
              </StyledTableCell>
              <StyledTableCell align="center">
                {row.listingSeen === "Y" ? "Yes" : "No"}
              </StyledTableCell>
              <StyledTableCell align="center">
                {row.moveInDate ? row.moveInDate.toLocaleDateString() : "NA"}
              </StyledTableCell>
              <StyledTableCell align="center">
                {formatPrice(row.tenant.currentIncome)}
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
