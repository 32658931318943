import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { GLOBALS } from "../../App";
import TenantProfileSection from "./TenantProfileSection";
import homeImg from "../tenant-plan/home.png";
import { useHistory, useParams } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  titleUnderline: {
    width: "25px",
    height: "3px",
    background: theme.palette.secondary.main,
    marginTop: "2px",
  },
  list: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(2),
  },
  listItem: {
    padding: 0,
    display: "flex",
    margin: "10px 0",

    "& > svg": {
      marginRight: "15px",
    },
  },
  wrapperInner: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "flex-end",

    [theme.breakpoints.down("xs")]: {
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
  },
  ctaWrapper: {
    padding: theme.spacing(2),
    paddingBottom: 0,

    "& > img": {
      width: "89px",
      height: "78px",
    },

    "& > .MuiButtonBase-root": {
      width: "142px",
      height: "46px",
      fontSize: "18px",
      fontWeight: 450,
    },

    [theme.breakpoints.down("xs")]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
}));

const TenantPlanSection = ({ activePlan }) => {
  const classes = useStyles();
  const { tenantId } = useParams();
  const history = useHistory();

  return (
    <TenantProfileSection
      title="Plan"
      editButtonText="Upgrade"
      showEditIcon={false}
      isEditable={activePlan.planType === "FR"}
      onEditButtonClick={() => {
        history.push(`/tenant/${tenantId}/plan`);
      }}
    >
      {activePlan.planType === "FR" ? (
        <>
          <Typography variant="h6">
            {GLOBALS.ACCOUNT_PLANS.MODELS.FREE}
          </Typography>
          <div className={classes.titleUnderline} />
          <div className={classes.wrapperInner}>
            <div className={classes.list}>
              {GLOBALS.ACCOUNT_PLANS.FEATURES.FREE.map(el => (
                <div key={el} className={classes.listItem}>
                  <CheckCircleOutlineIcon color="secondary" />
                  <Typography variant="body1">{el}</Typography>
                </div>
              ))}
            </div>

            <div className={classes.ctaWrapper}>
              <img src={homeImg} alt="" />
            </div>
          </div>
        </>
      ) : (
        <>
          <Typography variant="h6">
            {GLOBALS.ACCOUNT_PLANS.MODELS.UNLIMITED}
          </Typography>
          <div className={classes.titleUnderline} />
          <div className={classes.wrapperInner}>
            <div className={classes.list}>
              {GLOBALS.ACCOUNT_PLANS.FEATURES.UNLIMITED.map(el => (
                <div key={el} className={classes.listItem}>
                  <CheckCircleOutlineIcon color="secondary" />
                  <Typography variant="body1">{el}</Typography>
                </div>
              ))}
            </div>

            <div className={classes.ctaWrapper}>
              <Typography variant="subtitle1">Expiration Date:</Typography>
              <Typography variant="body1">
                <strong> {activePlan.endDate}</strong>
              </Typography>
            </div>
          </div>
        </>
      )}
    </TenantProfileSection>
  );
};

export default TenantPlanSection;
