import React from "react";

const RenewIcon = props => {
  return (
    <svg
      xmlns="true"
      width="17.339"
      height="17.873"
      viewBox="0 0 17.339 17.873"
      {...props}
    >
      <g id="recycle-sign" transform="translate(-4.546 0.001)">
        <path
          id="Renew-Icon-Path_388"
          d="M4.778,47.456a1.049,1.049,0,0,1,1.613.028,8.8,8.8,0,0,1,2.167,7.864l.544.158c.3.087.146.569-.346,1.074L6.651,58.748c-.492.506-.974.35-1.078-.348l-.42-2.833c-.1-.7.136-1.17.534-1.054l.721.21a6.518,6.518,0,0,0-1.452-5.478A1.462,1.462,0,0,1,4.778,47.456Z"
          transform="translate(12.629 -44.327)"
          fill="#fff"
        />
        <path
          id="Renew-Icon-Path_389"
          d="M116.607,206.4a10.374,10.374,0,0,1-2.07.212,8.362,8.362,0,0,1-5.724-2.155l-.538.518c-.3.286-.7-.032-.889-.711l-.83-2.9c-.194-.678.181-1.019.838-.761l2.666,1.047c.657.258,1.005.644.779.862l-.41.395a6.539,6.539,0,0,0,5.367,1.376,1.461,1.461,0,0,1,1.64.736A1.05,1.05,0,0,1,116.607,206.4Z"
          transform="translate(-101.603 -188.735)"
          fill="#fff"
        />
        <path
          id="Renew-Icon-Path_390"
          d="M140.4,7.494a8.818,8.818,0,0,1,4.94-6.229l-.249-.717c-.137-.4.316-.629,1.014-.522l2.984.463c.7.108.85.591.34,1.08l-2.068,1.982c-.509.488-1,.65-1.1.361l-.181-.523a6.556,6.556,0,0,0-3.412,4.256,1.461,1.461,0,0,1-1.355,1.181A1.049,1.049,0,0,1,140.4,7.494Z"
          transform="translate(-135.822 0)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export default RenewIcon;
