import {
  Backdrop,
  Button,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";

import React, { useState } from "react";
import { GLOBALS } from "../../App";
import { getApplicationReportData } from "../../utils/ApplicationApi";
import { prepareReportPageContent } from "../../utils/common";

const useStyles = makeStyles(theme => ({
  noReportsText: {
    marginBottom: theme.spacing(1),
  },
  listItem: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: `2px solid ${theme.palette.secondary.main}`,

    "&.MuiListItem-gutters": {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
  listItemHeader: {
    display: "flex",
    justifyContent: "space-between",

    "&.MuiListItem-gutters": {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
  iconButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginLeft: theme.spacing(2),
  },
  left: {
    display: "flex",
    alignItems: "center",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const LandlordReports = ({
  accessToken,
  selectedHouseholdId,
  applicationId,
  showError,
  reports,
  onRequestReportsBtnClick,
}) => {
  if (!reports) return null;

  const selectedHouseholdReports = reports.find(
    v => v.tenant === selectedHouseholdId
  );

  return (
    <>
      {selectedHouseholdReports ? (
        <ReportsReady
          accessToken={accessToken}
          reports={selectedHouseholdReports.reports.split(",")}
          showError={showError}
          applicationId={applicationId}
          selectedHouseholdId={selectedHouseholdId}
        />
      ) : (
        <div>
          <Typography variant="body2">
            {GLOBALS.MESSAGES.NO_SCREENING_REPORTS}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={onRequestReportsBtnClick}
          >
            Request Reports
          </Button>
        </div>
      )}
    </>
  );
};

const ReportsReady = ({
  accessToken,
  reports,
  showError,
  applicationId,
  selectedHouseholdId,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  function reportCallback(isSuccess, result, error) {
    setLoading(false);

    if (isSuccess) {
      try {
        let wnd = window.open("", "_blank");
        let content = prepareReportPageContent(result);
        wnd.document.write(content);
        wnd.document.close();
      } catch (e) {
        showError(GLOBALS.MESSAGES.POPUP_DISABLED_ERROR);
      }
    }
    if (error) {
      showError();
      console.log(error);
    }
  }

  async function callGetApplicationReportData(product) {
    setLoading(true);

    getApplicationReportData(
      accessToken,
      applicationId,
      selectedHouseholdId,
      product,
      reportCallback
    );
  }

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="secondary" />
      </Backdrop>
      <List component="div">
        <ListItem component="div" className={classes.listItemHeader}>
          <Typography variant="body2">
            <strong>Reports</strong>
          </Typography>
          <Typography variant="body2">
            <strong>Actions</strong>
          </Typography>
        </ListItem>
        {reports.map((report, index) => (
          <ListItem component="div" className={classes.listItem} key={index}>
            <div className={classes.left}>
              <Typography variant="body2">{report}</Typography>
            </div>

            <div className={classes.iconButtons}>
              <>
                <IconButton
                  edge="end"
                  aria-label="get-report"
                  onClick={() =>
                    callGetApplicationReportData(report.toLowerCase())
                  }
                >
                  <VisibilityIcon color="secondary" />
                </IconButton>
              </>
            </div>
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default LandlordReports;
