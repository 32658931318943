import { SETTINGS } from "./settings";
import { loadStripe } from "@stripe/stripe-js";
import { ENUMS } from "./enums";
import { MESSAGES } from "./messages";
import { LABELS } from "./labels";
import { PATHS } from "./paths";
import { SITE_TEXTS } from "./siteTexts";
import { META_TAGS } from "./metaTags";
import { PLANS } from "./plans";
import { TERMS } from "./terms";
import { MLS_INFO } from "./mls_info";
import { CREDIT_REPAIR_INFO } from "./credit_repair_info";
import { LANDLORD_EXT_STATUS } from "./landlordExt";

export function initGlobals() {
  const globalsElement = document.getElementById("globals");

  // https://mathiasbynens.be/demo/url-regex
  // https://gist.github.com/dperini/729294
  const validUrlRegex = new RegExp(
    "^" +
      // protocol identifier (optional)
      // short syntax // still required
      "(?:(?:(?:https?|ftp):)?\\/\\/)" +
      // user:pass BasicAuth (optional)
      "(?:\\S+(?::\\S*)?@)?" +
      "(?:" +
      // IP address exclusion
      // private & local networks
      "(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
      "(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
      "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
      // IP address dotted notation octets
      // excludes loopback network 0.0.0.0
      // excludes reserved space >= 224.0.0.0
      // excludes network & broadcast addresses
      // (first & last IP address of each class)
      "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
      "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
      "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
      "|" +
      // host & domain names, may end with dot
      // can be replaced by a shortest alternative
      // (?![-_])(?:[-\\w\\u00a1-\\uffff]{0,63}[^-_]\\.)+
      "(?:" +
      "(?:" +
      "[a-z0-9\\u00a1-\\uffff]" +
      "[a-z0-9\\u00a1-\\uffff_-]{0,62}" +
      ")?" +
      "[a-z0-9\\u00a1-\\uffff]\\." +
      ")+" +
      // TLD identifier name, may end with dot
      "(?:[a-z\\u00a1-\\uffff]{2,}\\.?)" +
      ")" +
      // port number (optional)
      "(?::\\d{2,5})?" +
      // resource path (optional)
      "(?:[/?#]\\S*)?" +
      "$",
    "i"
  );

  let globals = {
    ...ENUMS,
    MESSAGES: MESSAGES,
    LABELS: LABELS,
    MLS_INFO: MLS_INFO,
    CREDIT_REPAIR_INFO: CREDIT_REPAIR_INFO,
    PATHS: PATHS,
    SITE_TEXTS: SITE_TEXTS,
    META_TAGS: META_TAGS,
    ACCOUNT_PLANS: PLANS,
    TERMS_AND_CONDITIONS: TERMS,
    LANDLORD_EXT_STATUS: LANDLORD_EXT_STATUS,
    APP_BASE_URL: window.location.origin,
    // APP_BASE_URL: SETTINGS.LOCAL_BACKEND
    // ? "http://localhost:8000"
    // : "https://dev.nestiqa.com",
    REACT_BASE_URL: window.location.origin,
    API_BASE_URL: SETTINGS.LOCAL_BACKEND
      ? "http://localhost:8000/api"
      : "https://dev.nestiqa.com/api",
    CLAIMS_NAMESPACE: "https://login.dev.nestiqa.com/claims",
    AUTH0_DOMAIN: "login.dev.nestiqa.com",
    AUTH0_CLIENT_ID: "OkRMNaJwHNE67UIixyOU8QcQSXKM59Fe",
    AUTH0_MANAGEMENT_API_ENDPOINT: "https://dev-nestiqa.us.auth0.com/api/v2/",
    GOOGLE_API_KEY: "AIzaSyACjkrxRoPGS_MzvEkbZkDGMc31gh3qtek",
    STRIPE_PUBLISHABLE_KEY:
      "pk_test_51HjopMJHF4IBEftMDkRud4KsdzqZdGO63x0Q20313wDU2DBKllQteVclnvwk91RogHiURf3DLQMxgWMspZaDaOJ400t8sKmTCf",
    DEFAULT_LOC: { lat: 40.777335, lng: -74.194251 },
    DEFAULT_DISTANCE: 20,
    MILE_TO_KM: 1.60934,
    EARTH_RADIUS_KM: 6371,
    US_STATE_REGEX:
      /^(?:A[KLRZ]|C[AOT]|D[CE]|FL|GA|HI|I[ADLN]|K[SY]|LA|M[ADEINOST]|N[CDEHJMVY]|O[HKR]|PA|RI|S[CD]|T[NX]|UT|V[AT]|W[AIVY])*$/,
    US_ZIP_REGEX: /^([0-9]{5}(?:-[0-9]{4})?)*$/,
    FORBIDDEN_CHARACTERS_REGEX: /[^*<>=]/,
    LEGAL_NAME_REGEX: /^[ A-Za-z'-]+$/,
    COMPANY_NAME_REGEX:
      /^\d*[a-zA-Z,.?@&!#’~ _;+()/-][a-zA-Z0-9,.?@&!#’~ _;+()/-]/,
    // SSN_REGEX: /^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/,
    SSN_WITHOUT_DASHES_REGEX:
      /^(?!666|000|9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4}$/,
    SOCIAL_USERNAME_REGEX: /^[a-zA-Z0-9_.]+$/,
    PHONE_NUMBER_REGEX: /^[(]?[2-9]\d{2}[)]?[(\s)?.-]?\d{3}[\s.-]?\d{4}$/,
    VALID_URL_REGEX: validUrlRegex,
    // https://stackoverflow.com/a/61782161/10836189
    NOT_ONLY_WHITESPACE_REGEX: /^(?!\s+$).*/,
    DEFAULT_FETCH_TIMEOUT: 30000,
    TENANT_MIN_AGE: 18,
    REASON_FOR_LEAVING_MAX: 50,
    DATE_FORMAT: "MM/dd/yy",
    ANALYTICS_MEASUREMENT_ID: "G-DV4Q5WXN0Y",
    SOCIALS: {
      TWITTER: "https://twitter.com/nestiqa",
      FACEBOOK: "https://www.facebook.com/nestiqa",
      LINKEDIN: "https://www.linkedin.com/company/nestiqa/",
      INSTAGRAM: "https://www.instagram.com/nest.iqa/",
      BLOG: "https://resources.nestiqa.com/blog",
      FAQ: "https://resources.nestiqa.com/faq",
      LANDLORD_FAQ: "https://resources.nestiqa.com/landlord-faq",
    },
    SKIP_CHOICE_PATHS: ["landlord", "listing/", "household", "listings"],
  };

  if (globalsElement) {
    globals = {
      ...globals,
      ...JSON.parse(document.getElementById("globals").text),
    };
  }

  globals.STRIPE_PROMISE = loadStripe(globals.STRIPE_PUBLISHABLE_KEY);

  return globals;
}
