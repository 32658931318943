export const LABELS = {
  IMAGES_UPLOAD: "Max file size: 10MB.",
  PETS:
    "Example: (1) Sparky, Dog, Siberian Husky, 40lbs, 4 years ; (2) Rocky, Cat, Siamese, 8lbs, 5 years...",
  VEHICLES: "Example: Red Toyota Camry, 2019, Plate A##### registered in NJ...",
  FACEBOOK: "Facebook ID / Username",
  INSTAGRAM: "Instagram ID / Username",
  TWITTER: "Twitter ID / Username",
  LINKEDIN: "LinkedIn URL",
  DOCUMENT: {
    TITLE: "Tenant Documents",
    UPLOAD: "Upload Documents",
    DROP: "Click or Drop Documents Here",
    ADD: "Add Documents",
  },
};
