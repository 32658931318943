import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import theme from "../../theme";

const useStyles = makeStyles(() => ({
  headerShape: {
    display: "flex",
    justifyContent: "center",

    "& > div": {
      width: "52px",
      height: "4px",
      background: theme.palette.primary.main,
    },
  },
  title: {
    textTransform: "uppercase",
  },
}));

export default function ApplicationHeader() {
  const classes = useStyles();

  return (
    <Box component="div">
      <Typography
        className={classes.title}
        variant="h5"
        color="primary"
        align="center"
      >
        Application
      </Typography>
      <div className={classes.headerShape}>
        <div />
      </div>
    </Box>
  );
}
