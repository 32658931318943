export const getReportsStatus = state => state.reports.status;

export const getReports = state => state.reports.reports;

export const getReportsLoading = state => state.reports.loading;

export const getReportsError = state => state.reports.error;

export const getScreeningRequestId = state => state.reports.screeningRequestId;

export const getCreateReportsLater = state => state.reports.createReportsLater;

export const getReportsNotified = state => state.reports.reportsNotified;

export const getToShareReports = state => state.reports.toShareReports;
