import { Button, Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { GLOBALS } from "../App";
import { getFullAddress } from "../nestiqaUtils";
import { Helmet } from "react-helmet";
import useListing from "../hooks/useListing";
import LoginDialog from "../components/LoginDialog";
import useAuth0Nesitqa from "../hooks/useAuth0Nestiqa";
import { createApplication } from "../utils/ApplicationApi";
import NestiqaAlertDialog from "../components/NestiqaAlertDialog";
import { useDispatch, useSelector } from "react-redux";
import { getActiveUser } from "../store/selectors/userSelectors";
import { createTenant } from "../utils/TenantApi";
import { setUser as setReduxUser } from "../store/actions/userActions";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
  },
  title: {
    fontSize: "24px",
  },
}));

const PropertyApplyPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { isAuthenticated, accessToken } = useAuth0Nesitqa("read:users");
  const activeUser = useSelector(getActiveUser);
  const dispatch = useDispatch();
  const { listingNumber } = useParams();
  const [listingParams, setListingParams] = useState({
    listingNumber: listingNumber,
    action: "get-listing",
  });
  const listingResult = useListing(listingParams);
  const [fullAddress, setFullAddress] = useState("");
  const [messageDialogControls, setMessageDialogControls] = useState({
    open: false,
  });

  useEffect(() => {
    setListingParams({
      listingNumber,
      action: "get-listing",
    });
  }, [listingNumber]);

  useEffect(() => {
    if (listingResult.status === "COMPLETE" && listingResult.listing) {
      const listing = listingResult.listing;
      const address = getFullAddress(listing);
      setFullAddress(address);
    }
  }, [listingResult]);

  const [loginDialog, setLoginDialog] = useState({
    open: false,
  });

  const closeLoginDialog = () => {
    setLoginDialog({
      open: false,
    });
  };

  const openLoginDialog = () => {
    setLoginDialog({
      open: true,
      preLogin: () => {
        localStorage.setItem("previousLocation", window.location.pathname);
      },
      action: "apply",
      onClose: closeLoginDialog,
    });
  };

  async function checkAuthentication() {
    if (isAuthenticated) {
      if (!activeUser) return;
      if (activeUser.tenantId) {
        await handleApply(activeUser.tenantId, false);
      } else {
        await createTenant(
          accessToken,
          async (isSuccess, apiResult, apiError) => {
            if (isSuccess) {
              dispatch(setReduxUser({ tenantId: apiResult.tenantId }));
              await handleApply(apiResult.tenantId, true);
            } else {
              handleError();
            }
          }
        );
      }
    } else {
      return openLoginDialog();
    }
  }

  function handleError() {
    setMessageDialogControls({
      open: true,
      title: "Error",
      message: GLOBALS.MESSAGES.ERROR_COMPLETING_ACTION,
      onClose: () => setMessageDialogControls({ open: false }),
    });
  }

  async function handleApply(tenantId, isNewTenant) {
    const data = {
      status: "DRAFT",
      listing: listingResult?.listing?.id,
    };
    const response = await createApplication(data, accessToken);
    if (response.detail) {
      return;
    }
    if (response) {
      if (isNewTenant) {
        history.push(
          GLOBALS.PATHS.CREATE_TENANT.replace(":tenantId", tenantId),
          { from: "/redirect" }
        );
      } else {
        return history.push(`/application/${response.id}/update`);
      }
    }

    return false;
  }

  if (listingResult.status !== "COMPLETE") {
    return (
      <Typography variant="h5" align="center" color="primary">
        Loading...
      </Typography>
    );
  }

  if (listingResult.errors) {
    console.error(listingResult.errors);
    return (
      <Typography variant="h5" align="center" color="error">
        {GLOBALS.MESSAGES.LISTING_NOT_FOUND} {listingParams.listingNumber}
      </Typography>
    );
  }

  return (
    <Container className={classes.root}>
      <NestiqaAlertDialog {...messageDialogControls} />
      <Helmet>
        <title>
          {GLOBALS.META_TAGS.TITLE.UPDATE_APPLICATION_PAGE + fullAddress}
        </title>
        <meta name="robots" content="noindex" />
      </Helmet>

      <LoginDialog {...loginDialog} />

      <Typography
        variant="h6"
        align="center"
        color="primary"
        className={classes.title}
      >
        {GLOBALS.MESSAGES.PROPERTY_APPLY_PAGE_HEADING}
      </Typography>
      <Typography variant="subtitle1">
        {GLOBALS.MESSAGES.PROPERTY_APPLY_PAGE_TEXT}
      </Typography>
      <Typography variant="subtitle1">
        <strong>Address: </strong> {fullAddress}
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        <strong>Landlord: </strong>{" "}
        {listingResult?.listing?.landlord?.firstName +
          " " +
          listingResult?.listing?.landlord?.lastName}
      </Typography>
      <Button variant="contained" color="primary" onClick={checkAuthentication}>
        Apply
      </Button>
    </Container>
  );
};

export default PropertyApplyPage;
