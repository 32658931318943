import { red } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";

const primaryDark = "#22213D";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#9340FF",
      dark: primaryDark,
    },
    secondary: {
      main: "#3CE0E5",
      light: "#C4F5F7",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
    },
    text: {
      primary: primaryDark,
    },
  },
  typography: {
    body1: {
      color: primaryDark,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 767,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

theme.typography.h2 = {
  fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'",
  fontWeight: 300,
  lineHeight: 1.2,
  letterSpacing: "-0.00833em",
  fontSize: "3.75rem",
  [theme.breakpoints.down("sm")]: {
    fontSize: "2.4rem",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "1.8rem",
  },
};

export default theme;
