import { Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setReportStatus } from "../../store/actions/reportsActions";
import { createReports } from "../../utils/ReportsApi";

const VerificationComplete = ({ accessToken, showError }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const createReportsCalback = (isSuccess, result, error) => {
    setLoading(false);

    if (isSuccess) {
      if (result.status === "REPORTS_IN_PROGRESS") {
        dispatch(setReportStatus(result.status));
      } else {
        showError();
      }
    }
    if (error) {
      showError();
      console.error(error);
    }
  };

  async function callCreateReports() {
    setLoading(true);
    createReports(accessToken, createReportsCalback);
  }

  useEffect(() => {
    callCreateReports();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {loading && (
        <Typography variant="h5" align="center" color="primary">
          Loading...
        </Typography>
      )}
    </div>
  );
};

export default VerificationComplete;
