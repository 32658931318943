import React from "react";
import { Typography, Link, Container, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { GLOBALS } from "../App";
import improovHomesImg from "../static/images/partners/improov-homes.png";
import clsx from "clsx";
import { getStaticImgSrc } from "../nestiqaUtils";
import { Helmet } from "react-helmet";
import { logEvent } from "../utils/initGa4React";
import { useGA4React } from "ga-4-react";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
  },
  title: {
    fontSize: "24px",
  },
  brokerOffices: {
    fontWeight: 600,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  mlsCompanies: {
    marginTop: theme.spacing(4),
    marginBottom: "-4px",
  },
  improovHomesImg: {
    width: "100%",
    marginBottom: theme.spacing(1),

    "& img": {
      maxWidth: "120px",
    },

    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  imageLink: {
    "& img": {
      maxWidth: "120px",
      marginBottom: theme.spacing(1),
    },
  },
  creditRepair: {
    "& img": {
      maxWidth: "120px",
      height: "100%",
      marginBottom: theme.spacing(1),
    },
  },
  partnersListWrapper: {
    display: "flex",
    flexFlow: "row wrap",
    width: "100%",
  },
  partnerItem: {
    marginRight: theme.spacing(4),
    marginTop: theme.spacing(2),

    [theme.breakpoints.down("xs")]: {
      marginRight: theme.spacing(3) + 4,
    },

    "& .MuiTypography-root": {
      marginTop: theme.spacing(1),
    },
  },
}));

const Partnerships = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));
  const mls = GLOBALS.MLS_INFO;
  const creditRepair = GLOBALS.CREDIT_REPAIR_INFO;
  const ga = useGA4React();

  function handleCreditRepairClick(item) {
    if (!!ga) {
      logEvent("lead_generation_click", { company: item.name });
    }
    window.open(item.link, "_blank");
  }

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Helmet>
          <title>{GLOBALS.META_TAGS.TITLE.PARTNERSHIPS_PAGE}</title>
        </Helmet>
        <Typography
          variant="h6"
          color="primary"
          align="center"
          className={classes.title}
        >
          Partnerships
        </Typography>
        <Typography
          variant="subtitle1"
          className={classes.brokerOffices}
          align={isMobile ? "center" : "left"}
        >
          Broker Offices
        </Typography>
        <div className={classes.improovHomesImg}>
          <Link href="https://www.improovhomes.com" target="_blank">
            <img src={getStaticImgSrc(improovHomesImg)} alt="" />
          </Link>
        </div>
        <Typography variant="body1" align={isMobile ? "center" : "left"}>
          Improov Homes
        </Typography>

        <Typography
          variant="subtitle1"
          className={clsx(classes.brokerOffices, classes.mlsCompanies)}
        >
          MLS Companies
        </Typography>

        <div className={classes.partnersListWrapper}>
          {Object.values(mls).map(n => (
            <div key={n.name} className={classes.partnerItem}>
              <Link href={n.site} className={classes.imageLink} target="_blank">
                <div
                  style={{
                    background: `url(${n.logo})`,
                    maxWidth: "120px",
                    minHeight: "100px",
                    maxHeight: "100px",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
              </Link>
              <Typography variant="body1">{n.name}</Typography>
            </div>
          ))}
        </div>

        <Typography
          variant="subtitle1"
          className={clsx(classes.brokerOffices, classes.mlsCompanies)}
          align={isMobile ? "center" : "left"}
        >
          Credit Repair
        </Typography>

        <div className={classes.partnersListWrapper}>
          {Object.values(creditRepair).map(n => (
            <div key={n.name} className={classes.partnerItem}>
              <div
                onClick={() => handleCreditRepairClick(n)}
                style={{
                  background: `url(${n.logo})`,
                  width: "120px",
                  height: "120px",
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  cursor: "pointer",
                }}
              />
              <Typography variant="body1">{n.name}</Typography>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default Partnerships;
