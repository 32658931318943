import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  AppBar,
  Grid,
  useMediaQuery,
} from "@material-ui/core";
import { GLOBALS } from "../App";
import { makeStyles } from "@material-ui/core/styles";
import TabPanel, { a11yProps } from "../components/TabPanel";
import RoundTabs from "../components/RoundTabs";
import RoundTab from "../components/RoundTab";
import { getUserLandlordApplications } from "../utils/ApplicationApi";
import useAuth0Nesitqa from "../hooks/useAuth0Nestiqa";
import { Helmet } from "react-helmet";
import LandlordApplications from "../components/LandlordApplications";
import RequireLogin from "../components/RequireLogin";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  tabsBar: {
    backgroundColor: "white",
    boxShadow: theme.shadows[0],
    color: theme.palette.primary.dark,
  },
  tabs: {
    textTransform: "capitalize",
  },
  tabLabels: {
    marginLeft: "20px",
  },
  tabLabelsMobile: {
    fontSize: "1.2rem",
    textTransform: "capitalize !important",
  },
  pageHeading: {
    margin: theme.spacing(2),
  },
}));

export default function LandlordAllApplicationsPage() {
  const classes = useStyles();
  const [curTab, setCurTab] = useState(0);
  const { accessToken, isAuthenticated, loginWithRedirect } =
    useAuth0Nesitqa("");
  const [applications, setApplications] = useState();
  const [prequalifications, setPrequalifications] = useState();
  const [error, setError] = useState();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));

  useEffect(() => {
    if (!accessToken) return;

    function applicationsCallback(isSuccess, apiResult, apiError) {
      if (isSuccess) {
        setApplications(apiResult);
      } else {
        setError(apiError);
      }
    }

    getUserLandlordApplications(accessToken, "APPLY", applicationsCallback);
  }, [accessToken]);

  useEffect(() => {
    if (!applications) return;

    function prequalificationsCallback(isSuccess, apiResult, apiError) {
      if (isSuccess) {
        setPrequalifications(apiResult);
      } else {
        console.error(apiError);
      }
    }

    getUserLandlordApplications(
      accessToken,
      "PREQUALIFY",
      prequalificationsCallback
    );
  }, [applications, accessToken]);

  useEffect(() => {
    if (applications && prequalifications) {
      if (applications.length < 1 && prequalifications.length > 0) {
        setCurTab(1);
      } else {
        setCurTab(0);
      }
    }
  }, [applications, prequalifications]);

  if (error) {
    console.error(error);
    return (
      <Typography variant="h5" align="center" color="error">
        {GLOBALS.MESSAGES.LISTING_NOT_FOUND}
      </Typography>
    );
  }

  function handleLogin() {
    localStorage.setItem("previousLocation", window.location.pathname);
    loginWithRedirect();
  }

  if (!isAuthenticated) {
    return (
      <Container>
        <RequireLogin login={handleLogin} />
      </Container>
    );
  }

  if (!applications) {
    return (
      <Typography variant="h5" align="center" color="primary">
        Loading...
      </Typography>
    );
  }

  const handleTabChange = (event, newTab) => {
    setCurTab(newTab);
  };

  return (
    <Container>
      <Helmet>
        <title>{GLOBALS.META_TAGS.TITLE.OPEN_APPLICATIONS_PAGE}</title>
      </Helmet>
      <Typography variant="h5" align="left" className={classes.pageHeading}>
        All My Open Applications
      </Typography>

      <AppBar position="static" className={classes.tabsBar}>
        <Grid container>
          <Grid item style={{ flexGrow: 1 }}>
            <RoundTabs
              value={curTab}
              onChange={handleTabChange}
              style={{ borderLeft: "2px solid black" }}
            >
              <RoundTab
                className={classes.tabs}
                style={{ zIndex: 2 }}
                label={
                  <Typography
                    className={clsx(
                      isMobile ? classes.tabLabelsMobile : classes.tabLabels
                    )}
                    variant={"h6"}
                  >
                    Applications({applications.length})
                  </Typography>
                }
                {...a11yProps(0)}
              />
              {prequalifications && (
                <RoundTab
                  className={classes.tabs}
                  style={{ zIndex: 1 }}
                  label={
                    <Typography
                      className={clsx(
                        isMobile ? classes.tabLabelsMobile : classes.tabLabels
                      )}
                      variant={"h6"}
                    >
                      Preapprovals({prequalifications.length})
                    </Typography>
                  }
                  {...a11yProps(1)}
                />
              )}
            </RoundTabs>
          </Grid>
        </Grid>
      </AppBar>
      <TabPanel value={curTab} index={0} style={tabPanelStyle}>
        <Typography variant="h5" align="center" color="primary">
          <LandlordApplications applications={applications} />
        </Typography>
      </TabPanel>
      {prequalifications && (
        <TabPanel value={curTab} index={1} style={tabPanelStyle}>
          <Typography variant="h5" align="center" color="primary">
            <LandlordApplications applications={prequalifications} />
          </Typography>
        </TabPanel>
      )}
    </Container>
  );
}

const tabPanelStyle = {
  border: "2px solid black",
  borderTop: "none",
  borderBottomLeftRadius: "30px",
  borderBottomRightRadius: "30px",
};
