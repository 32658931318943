import { Button, Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAuth0Nesitqa from "../hooks/useAuth0Nestiqa";
import { getActiveUser } from "../store/selectors/userSelectors";
import { createLandlord } from "../utils/LandlordApi";
import { setUser as setReduxUser } from "../store/actions/userActions";
import { GLOBALS } from "../App";
import NestiqaAlertDialog from "../components/NestiqaAlertDialog";
import { createTenant } from "../utils/TenantApi";
import { Redirect, useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  loading: {
    marginTop: theme.spacing(12),
  },
  title: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
    width: "100%",
    fontWeight: "400",
  },
  button: {
    marginTop: theme.spacing(1.5),
    boxShadow: "0px 6px 30px rgba(147,64,255,0.3)",
    minWidth: 350,
  },
  buttons: {
    maxWidth: 1000,
    margin: "0 auto",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    paddingBottom: theme.spacing(4),
  },
  buttonWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: theme.spacing(4),
  },
  [theme.breakpoints.down("md")]: {
    title: {
      fontSize: "30px",
    },
  },
  [theme.breakpoints.down("sm")]: {
    buttons: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-around",
      paddingBottom: theme.spacing(2),
    },
    buttonWrapper: {
      paddingTop: theme.spacing(2),
    },
  },
  [theme.breakpoints.down("xs")]: {
    title: {
      fontSize: "22px",
      marginTop: theme.spacing(5),
    },
  },
}));

const UserTypePage = () => {
  const classes = useStyles();

  const { accessToken } = useAuth0Nesitqa();
  const activeUser = useSelector(getActiveUser);
  const dispatch = useDispatch();
  const history = useHistory();

  const [dialogControls, setDialogControls] = useState({
    open: false,
    title: "",
    message: "",
  });

  function showError() {
    setDialogControls({
      open: true,
      title: "Error",
      message: GLOBALS.MESSAGES.ERROR_COMPLETING_ACTION,
      onClose: () => setDialogControls({ open: false }),
    });
  }

  const handleLandlordSelect = async () => {
    await createLandlord(accessToken, (isSuccess, apiResult, apiError) => {
      if (isSuccess) {
        dispatch(setReduxUser({ landlordId: apiResult.landlordId }));
        history.push(GLOBALS.PATHS.LANDLORD);
      } else {
        showError();
      }
    });
  };

  const handleTenantSelect = async () => {
    await createTenant(accessToken, (isSuccess, apiResult, apiError) => {
      if (isSuccess) {
        dispatch(setReduxUser({ tenantId: apiResult.tenantId }));
        history.push(
          GLOBALS.PATHS.CREATE_TENANT.replace(":tenantId", apiResult.tenantId),
          { from: "/redirect" }
        );
      } else {
        showError();
      }
    });
  };

  if (!activeUser || !accessToken) {
    return (
      <Container>
        <Typography
          variant="h5"
          align="center"
          color="primary"
          className={classes.loading}
        >
          Loading...
        </Typography>
      </Container>
    );
  }

  if (activeUser && activeUser.tenantId) {
    return <Redirect to={`/tenant/${activeUser.tenantId}/profile`} />;
  }

  if (activeUser && activeUser.landlordId) {
    return <Redirect to={`/landlord`} />;
  }

  return (
    <Container>
      <NestiqaAlertDialog {...dialogControls} />
      <Typography className={classes.title} variant="h4" align="center">
        {GLOBALS.MESSAGES.USER_TYPE_TITLE}
      </Typography>
      <Typography align="center">
        {" "}
        {GLOBALS.MESSAGES.USER_TYPE_SUBTITLE}
      </Typography>
      <div className={classes.buttons}>
        <div className={classes.buttonWrapper}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            disableElevation
            onClick={handleTenantSelect}
          >
            {GLOBALS.MESSAGES.USER_TYPE_TENANT_BUTTON}
          </Button>
        </div>
        <div className={classes.buttonWrapper}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            disableElevation
            onClick={handleLandlordSelect}
          >
            {GLOBALS.MESSAGES.USER_TYPE_LANDLORD_BUTTON}
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default UserTypePage;
