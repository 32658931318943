import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import useDocuments from "../../hooks/useDocuments";
import TenantProfileSection from "./TenantProfileSection";
import SvgIcon from "@material-ui/core/SvgIcon";
import {
  Button,
  IconButton,
  List,
  ListItem,
  Tooltip,
  Typography,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import { getFileIconFromFilename } from "../../utils/common";
import { GLOBALS } from "../../App";

const useStyles = makeStyles(theme => ({
  listItem: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: `2px solid ${theme.palette.secondary.main}`,

    "&.MuiListItem-gutters": {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
  listItemHeader: {
    display: "flex",
    justifyContent: "space-between",

    "&.MuiListItem-gutters": {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
  iconButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginLeft: theme.spacing(2),
  },
  fileName: {
    whiteSpace: "nowrap",
    overflowX: "hidden",
    textOverflow: "ellipsis",
    marginLeft: theme.spacing(2),
  },
  left: {
    display: "flex",
    alignItems: "center",
    overflowX: "hidden",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const TenantDocumentSection = ({
  isEditable = true,
  documents,
  openEditDialog,
}) => {
  const onEditButtonClick = () => {
    openEditDialog(7);
  };

  return (
    <TenantProfileSection
      title="Documents"
      onEditButtonClick={onEditButtonClick}
      isEditable={isEditable}
    >
      {documents.length !== 0 ? (
        <DocumentContent documents={documents} />
      ) : (
        <Typography color="primary" align="center">
          {GLOBALS.MESSAGES.NO_TENANT_DOCUMENTS}
        </Typography>
      )}
    </TenantProfileSection>
  );
};

export const DocumentContent = ({
  documents,
  applicationId,
  tenantId = null,
  tenantFullName = null,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const [documentParams, setDocumentParams] = useState({});

  const onDownload = document => {
    setLoading(true);
    setDocumentParams({
      action: applicationId
        ? "download-application-document"
        : "download-tenant-document",
      documentId: document.id,
      docName: document.docName,
      applicationId,
    });
  };

  const onDownloadAll = () => {
    setLoading(true);
    setDocumentParams({
      action: "download-all-application-documents",
      tenantId: tenantId,
      applicationId,
      docName: `${tenantFullName} Documents`,
    });
  };

  let tenantDocumentResult = useDocuments(documentParams);

  useEffect(() => {
    if (
      documentParams.action &&
      tenantDocumentResult.status === "COMPLETE" &&
      tenantDocumentResult.document
    ) {
      let element = document.createElement("a");
      element.setAttribute("href", tenantDocumentResult.document);
      element.setAttribute("download", documentParams.docName);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      tenantDocumentResult.clear();
      setLoading(false);
    }
  }, [documentParams, tenantDocumentResult]);

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="secondary" />
      </Backdrop>
      <List component="div">
        <ListItem component="div" className={classes.listItemHeader}>
          <Typography variant="body2">
            <strong>Documents</strong>
          </Typography>
          <Typography variant="body2">
            <strong>Actions</strong>
          </Typography>
        </ListItem>
        {documents.map((file, index) => (
          <ListItem component="div" className={classes.listItem} key={index}>
            <div className={classes.left}>
              <SvgIcon color="secondary">
                {getFileIconFromFilename(file.docName)}
              </SvgIcon>
              <Tooltip
                placement="top-start"
                title={file.docName}
                aria-label={file.docName}
              >
                <Typography className={classes.fileName} variant="body2">
                  {file.docName}
                </Typography>
              </Tooltip>
            </div>

            <div className={classes.iconButtons}>
              <>
                <IconButton
                  edge="end"
                  aria-label="download"
                  onClick={() => onDownload(file)}
                >
                  <VisibilityIcon color="secondary" />
                </IconButton>
              </>
            </div>
          </ListItem>
        ))}
        {applicationId && tenantId && documents.length > 0 && (
          <ListItem component="div" className={classes.iconButtons}>
            <Button onClick={onDownloadAll}>Download All</Button>
          </ListItem>
        )}
      </List>
    </>
  );
};

export default TenantDocumentSection;
