import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";

const StyledTableCell = withStyles(theme => ({
  head: {
    padding: 0,
    "&:last-child": {
      paddingRight: 0,
    },
  },
  body: {
    padding: 0,

    "&:last-child": {
      paddingRight: 0,
    },
  },
}))(TableCell);

export default StyledTableCell;
