import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { format } from "date-fns";
import { GLOBALS } from "../App";
import { IconButton, Link } from "@material-ui/core";
import {
  DeleteOutline as DeleteIcon,
  EditOutlined as EditIcon,
} from "@material-ui/icons";
import { camelToSnake } from "../nestiqaUtils";

const useStyles = makeStyles(theme => ({
  row: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: theme.spacing(1),
  },
  rowRight: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  summary: {
    width: "100%",

    "& .MuiAccordionSummary-content": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },

    "& .MuiAccordionSummary-root": {
      padding: 0,
    },
  },
  details: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  accordian: {
    boxShadow: "none",
    border: "none",
    borderBottom: `2px solid ${theme.palette.secondary.main}`,

    "&::before": {
      display: "none",
    },
  },
  alignRight: {
    textAlign: "right",
  },
  actionsRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  rightMargin: {
    marginRight: theme.spacing(1),
  },
}));

const SavedSearchesAccordion = ({ searches, onRowEdit, onRowDelete }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(
    searches.length === 1 ? searches[0].id : false
  );

  const handleChange = id => (event, isExpanded) => {
    setExpanded(isExpanded ? id : false);
  };

  return (
    <>
      {searches.map((search, i) => {
        const params = new URLSearchParams(camelToSnake(search.searchParams));

        return (
          <Accordion
            expanded={expanded === search.id}
            key={search.id}
            className={classes.accordian}
            onChange={handleChange(search.id)}
          >
            <AccordionSummary id={search.id} className={classes.summary}>
              <div className={classes.row}>
                <Typography variant="body2">
                  {" "}
                  <Link
                    to={`${GLOBALS.PATHS.LISTINGS}?${params.toString()}`}
                    component={RouterLink}
                    underline="none"
                  >
                    {search.searchName}
                  </Link>
                </Typography>
                <div className={classes.rowRight}>
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    onClick={() => onRowEdit(search)}
                  >
                    <EditIcon color="secondary" />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => onRowDelete(search)}
                  >
                    <DeleteIcon color="secondary" />
                  </IconButton>
                </div>
              </div>

              <div className={classes.row}>
                <div />
                <div>
                  {expanded === search.id ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <div className={classes.row}>
                <div>
                  <Typography variant="body2">
                    <strong>Email Alerts</strong>
                  </Typography>
                  <Typography variant="body2">
                    {search.emailAlerts === "Y" ? "Yes" : "No"}
                  </Typography>
                </div>
                <div className={classes.alignRight}>
                  <Typography variant="body2">
                    <strong>Last Modified</strong>
                  </Typography>
                  <Typography variant="body2">
                    {format(new Date(search.lastModified), GLOBALS.DATE_FORMAT)}
                  </Typography>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
};

export default SavedSearchesAccordion;
