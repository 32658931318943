import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { GLOBALS } from "../App";

const Profile = () => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [userMetadata, setUserMetadata] = useState(null);

  useEffect(() => {
    const getUserMetadata = async () => {
      const domain = GLOBALS.AUTH0_DOMAIN;

      try {
        const accessToken = await getAccessTokenSilently({
          audience: `https://${domain}/api/v2/`,
          scope: "read:current_user",
        });
        const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;

        const metadataResponse = await fetch(userDetailsByIdUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const { user_metadata } = await metadataResponse.json();

        setUserMetadata(user_metadata);
      } catch (e) {
        console.log("Failed to get user metadata: " + e.message);
      }
    };

    if (isAuthenticated) {
      getUserMetadata();
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  return isAuthenticated ? (
    <div>
      <img src={user.picture} alt="User" />
      <h2>{user.name}</h2>
      <p>{user.email}</p>
      <h3>User Metadata</h3>
      {userMetadata ? (
        <pre>{JSON.stringify(userMetadata, null, 2)}</pre>
      ) : (
        "No user metadata defined"
      )}
    </div>
  ) : (
    <p>Not authenticated</p>
  );
};

export default Profile;
