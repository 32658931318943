import React from "react";
import clsx from "clsx";
import { Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ReactReadMoreReadLess from "react-read-more-read-less";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import { format } from "date-fns";
import { GLOBALS } from "../../App";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    padding: theme.spacing(1),
    paddingRight: theme.spacing(0),
  },
  readMoreLess: {
    color: theme.palette.primary.main,
  },
  moveInRow: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  moveInDate: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: "flex",
    alignItems: "center",

    "& .MuiSvgIcon-root": {
      marginLeft: "-2px",
      marginRight: theme.spacing(1),
    },
  },
  inlineBlock: {
    display: "inline",
  },
  paddingRight: {
    paddingRight: theme.spacing(6),
  },
  [theme.breakpoints.down("md")]: {
    moveInRow: {
      marginTop: theme.spacing(0),

      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
  },
}));

const ApplicationInfo = ({
  coverLetter,
  moveInDate,
  applicantName,
  isPrintPage = false,
  listingSeen,
}) => {
  const classes = useStyles();
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up("lg"));
  const isLaptop = useMediaQuery(theme => theme.breakpoints.up("md"));
  const isTablet = useMediaQuery(theme => theme.breakpoints.up("sm"));
  let charLimit = 120;
  if (isTablet) charLimit = 120;
  if (isLaptop) charLimit = 180;
  if (isDesktop) charLimit = 220;

  return (
    <div className={clsx(classes.root)}>
      <Typography variant="body1">
        <strong>By:</strong> {applicantName}
      </Typography>
      <div className={classes.moveInRow}>
        <div className={classes.moveInDate}>
          <CalendarTodayIcon color="secondary" />{" "}
          <Typography variant="body1">
            <strong>Desired Move in Date:</strong>{" "}
            {moveInDate ? format(moveInDate, GLOBALS.DATE_FORMAT) : "Flexible"}
          </Typography>
        </div>
        {listingSeen && (
          <Typography variant="body1">
            <strong>Showing Done: </strong>
            {listingSeen === "Y" ? "Yes" : "No"}
          </Typography>
        )}
      </div>
      <div className={classes.paddingRight}>
        <Typography variant="body1" className={classes.inlineBlock}>
          <strong>Cover Letter: </strong>
        </Typography>

        <Typography variant="body2" className={clsx(classes.inlineBlock)}>
          {isPrintPage ? (
            coverLetter
          ) : (
            <ReactReadMoreReadLess
              charLimit={charLimit}
              readMoreText={"Read more"}
              readLessText={"Read less"}
              readMoreClassName={classes.readMoreLess}
              readLessClassName={classes.readMoreLess}
            >
              {coverLetter}
            </ReactReadMoreReadLess>
          )}
        </Typography>
      </div>
    </div>
  );
};

export default ApplicationInfo;
