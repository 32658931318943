import React, { useState, useRef, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import throttle from "lodash/throttle";
import { GLOBALS } from "../../App";
import { buildFetchUrl } from "../../hooks/useListings";
import {
  clearDistanceFrom,
  makeFilters,
} from "../../store/actions/listingsActions";
import { useSelector, useDispatch } from "react-redux";

const useStyles = makeStyles(() => ({
  optionText: {},
  optionSecondaryText: {
    fontSize: "0.75rem",
  },
  listbox: {
    width: "240px",
    backgroundColor: "white",
    position: "absolute",
  },
  link: {
    textDecoration: "none !important",
    color: "#000",
  },
}));

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = GLOBALS.EARTH_RADIUS_KM * c; // Distance in km
  return d;
}

function getPlaceSize(place) {
  var viewport = place.geometry.viewport;
  if (viewport == null) {
    return 0;
  }
  return getDistanceFromLatLonInKm(
    viewport.getCenter().lat(),
    viewport.getCenter().lng(),
    viewport.getNorthEast().lat(),
    viewport.getNorthEast().lng()
  );
}

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

function getOptionForValue(value) {
  let val = value || "";

  return {
    manual: true,
    description: val,
    structured_formatting: {
      main_text_matched_substrings: [{ length: val.length, offset: 0 }],
      main_text: val,
      secondary_text: "",
    },
  };
}

function getPlaceForManualValue(streetAddress) {
  return {
    address_components: [
      {
        types: ["route"],
        short_name: streetAddress,
      },
    ],
  };
}

const withAutocomplete = ComposedComponent => {
  const AutocompleteDataAdapter = props => {
    const {
      resultTypes,
      handlePlaceChange,
      className,
      inputClassName,
      label,
      inputVariant,
      placeholder,
      hasIcon,
      shortLabel,
      initialValue,
      allowManual,
      onInputChange,
      InputProps,
      required,
      searchByLocation,
      searchByListing,
      setSearchByListing,
      ...other
    } = props;

    const classes = useStyles();
    const dispatch = useDispatch();
    const reduxFilters = useSelector(state => state.listings.filters);
    const [value, setValue] = useState(getOptionForValue(initialValue));
    const [inputValue, setInputValue] = useState("");
    const [options, setOptions] = useState([]);
    const loaded = useRef(false);
    const autocompleteRef = useRef();

    if (typeof window !== "undefined" && !loaded.current) {
      if (!document.querySelector("#google-maps")) {
        loadScript(
          "https://maps.googleapis.com/maps/api/js?key=" +
            GLOBALS.GOOGLE_API_KEY +
            "&libraries=places",
          document.querySelector("head"),
          "google-maps"
        );
      }

      loaded.current = true;
    }

    const fetchMaps = useMemo(
      () =>
        throttle((request, callback) => {
          autocompleteService.current.getPlacePredictions(request, callback);
        }, 300),
      []
    );

    const searchListings = useMemo(
      () =>
        throttle(async (filters, callback) => {
          try {
            let response = await fetch(buildFetchUrl(filters), {
              method: "get",
              headers: {
                "Content-Type": "text/plain",
              },
            });
            if (response.ok) {
              let body = await response.json();
              return callback(body.results);
            } else {
              return [];
            }
          } catch (e) {
            return null;
          }
        }, 300),
      []
    );

    useEffect(() => {
      let active = true;

      if (!autocompleteService.current && window.google) {
        autocompleteService.current =
          new window.google.maps.places.AutocompleteService();
      }
      if (!autocompleteService.current) {
        return undefined;
      }

      if (inputValue === "") {
        setOptions(value ? [value] : []);
        return undefined;
      }

      let request = { input: inputValue };
      if (resultTypes) {
        request["types"] = resultTypes;
      }

      fetchMaps(request, results => {
        if (active) {
          let newOptions = [];

          if (results) {
            newOptions.push(...results);
          }

          if (allowManual) {
            newOptions.push(getOptionForValue(inputValue));
          }

          if (value) {
            newOptions.push(value);
          }

          setOptions(newOptions);
        }
      });

      if (!searchByListing) return;
      searchListings({ lstnum: inputValue }, listings => {
        if (!active) return;

        let newOptions = [];

        if (listings) {
          newOptions.push(...listings);
        }

        if (allowManual) {
          newOptions.push(getOptionForValue(inputValue));
        }

        setOptions(pre => [...newOptions, ...pre]);
      });

      return () => {
        active = false;
      };
    }, [
      value,
      fetchMaps,
      inputValue,
      allowManual,
      resultTypes,
      searchListings,
      searchByListing,
    ]);

    useEffect(() => {
      if (!value || !window.google || searchByListing) {
        handlePlaceChange(null, 0, false);
        return;
      }
      if (value.manual) {
        handlePlaceChange(
          getPlaceForManualValue(value.structured_formatting.main_text),
          0,
          true
        );
        return;
      }
      const geocoder = new window.google.maps.Geocoder();
      const { OK } = window.google.maps.GeocoderStatus;
      geocoder.geocode({ placeId: value.place_id }, (results, status) => {
        if (status === OK && results.length === 1) {
          let place = results[0];
          let placeSize = getPlaceSize(place);
          handlePlaceChange(place, placeSize, false);
        }
      });
    }, [value, handlePlaceChange, searchByListing]);

    useEffect(() => {
      setValue(getOptionForValue(initialValue));
      setOptions([]);
    }, [initialValue]);

    const handleResetFilters = () => {};

    let clearIndicator;
    if (autocompleteRef.current) {
      clearIndicator = autocompleteRef.current.getElementsByClassName(
        "MuiAutocomplete-clearIndicator"
      )[0];
    }

    useEffect(() => {
      if (clearIndicator) {
        clearIndicator.addEventListener("click", function () {
          let adjustedFilters = reduxFilters;
          dispatch(clearDistanceFrom());
          if (searchByLocation) {
            delete adjustedFilters.frlg;
            delete adjustedFilters.frlt;
            delete adjustedFilters.dst;
          } else {
            delete adjustedFilters.zc;
            delete adjustedFilters.ct;
            delete adjustedFilters.st;
          }
          dispatch(makeFilters(adjustedFilters));
        });
      }
      // eslint-disable-next-line
    }, [clearIndicator]);

    return (
      <ComposedComponent
        classes={classes}
        className={className}
        inputClassName={inputClassName}
        shortLabel={shortLabel}
        options={options}
        allowManual={allowManual}
        value={value}
        setOptions={setOptions}
        searchByListing={searchByListing}
        setValue={setValue}
        setInputValue={setInputValue}
        setSearchByListing={setSearchByListing}
        onInputChange={onInputChange}
        required={required}
        label={label}
        inputVariant={inputVariant}
        placeholder={placeholder}
        handleResetFilters={handleResetFilters}
        hasIcon={hasIcon}
        ref={autocompleteRef}
        {...other}
      />
    );
  };

  return AutocompleteDataAdapter;
};

export default withAutocomplete;
