import {
  MAKE_FILTERS,
  SET_SEARCH_FILTERS,
  CLEAR_FILTERS,
  SET_HOUSING_TYPE,
  SET_MAX_RENT,
  SET_BEDS,
  SET_BATHS,
  SET_DISTANCE,
  SET_DISTANCE_FROM,
  CLEAR_DISTANCE_FROM,
  SET_DISTANCE_FILTERS,
  SET_DEFAULT_DISTANCE,
  SAVE_LAST_ADDRESS,
  TRIGGER_SEARCH,
} from "./types";

export function triggerSearch(value) {
  return function (dispatch) {
    dispatch({ type: TRIGGER_SEARCH, payload: value });
  };
}

export function makeFilters(value) {
  return function (dispatch) {
    dispatch({ type: MAKE_FILTERS, payload: value });
  };
}

export function setSearchFilters(value) {
  return function (dispatch) {
    dispatch({ type: SET_SEARCH_FILTERS, payload: value });
  };
}

export function clearFilters() {
  return function (dispatch) {
    dispatch({ type: CLEAR_FILTERS });
  };
}

export function setHousingType(value) {
  return function (dispatch) {
    dispatch({ type: SET_HOUSING_TYPE, payload: value });
  };
}

export function setMaxRent(value) {
  return function (dispatch) {
    dispatch({ type: SET_MAX_RENT, payload: value });
  };
}

export function setBeds(value) {
  return function (dispatch) {
    dispatch({ type: SET_BEDS, payload: value });
  };
}

export function setBaths(value) {
  return function (dispatch) {
    dispatch({ type: SET_BATHS, payload: value });
  };
}

export function setDistance(value) {
  return function (dispatch) {
    dispatch({ type: SET_DISTANCE, payload: value });
  };
}

export function setDistanceFrom(value) {
  return function (dispatch) {
    dispatch({ type: SET_DISTANCE_FROM, payload: value });
  };
}

export function clearDistanceFrom() {
  return function (dispatch) {
    dispatch({ type: CLEAR_DISTANCE_FROM });
  };
}

export function setDistanceFilters(value) {
  return function (dispatch) {
    dispatch({ type: SET_DISTANCE_FILTERS, payload: value });
  };
}

export function setDefaultDistance(value) {
  return function (dispatch) {
    dispatch({ type: SET_DEFAULT_DISTANCE, payload: value });
  };
}

export function saveLastAddress(value) {
  return function (dispatch) {
    dispatch({ type: SAVE_LAST_ADDRESS, payload: value });
  };
}
