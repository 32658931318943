import {
  MAKE_FILTERS,
  SET_SEARCH_FILTERS,
  CLEAR_FILTERS,
  SET_HOUSING_TYPE,
  SET_MAX_RENT,
  SET_BEDS,
  SET_BATHS,
  SET_DISTANCE,
  SET_DISTANCE_FROM,
  CLEAR_DISTANCE_FROM,
  SET_DISTANCE_FILTERS,
  SET_DEFAULT_DISTANCE,
  SAVE_LAST_ADDRESS,
  TRIGGER_SEARCH,
} from "../actions/types";

const initialState = {
  filters: {
    bd_min: 0,
    bd_max: 6,
    ba_min: 0,
    ba_max: 10,
    ordering: "RECOMMENDED",
  },
  searchFilters: {
    bd_min: 0,
    bd_max: 6,
    ba_min: 0,
    ba_max: 10,
    ordering: "RECOMMENDED",
  },
  distanceFilters: {},
  distanceFrom: "",
  defaultDistance: null,
  lastAddress: {},
  searchTriggered: false,
};

export default function listingsReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case TRIGGER_SEARCH:
      return {
        ...state,
        searchTriggered: true,
      };
    case MAKE_FILTERS:
      return {
        ...state,
        filters: payload,
      };
    case SET_SEARCH_FILTERS:
      return {
        ...state,
        searchFilters: payload,
      };
    case SET_HOUSING_TYPE:
      return {
        ...state,
        filters: {
          ...state.filters,
          htype: payload,
        },
      };
    case SET_MAX_RENT:
      return {
        ...state,
        filters: {
          ...state.filters,
          rent_max: payload,
        },
      };
    case SET_BEDS:
      const { bd_min, bd_max } = payload;
      return {
        ...state,
        filters: {
          ...state.filters,
          bd_min,
          bd_max,
        },
      };
    case SET_BATHS:
      const { ba_min, ba_max } = payload;
      return {
        ...state,
        filters: {
          ...state.filters,
          ba_min,
          ba_max,
        },
      };
    case SET_DISTANCE:
      return {
        ...state,
        filters: {
          ...state.filters,
          dst: payload,
        },
      };
    case SET_DISTANCE_FROM:
      return {
        ...state,
        distanceFrom: payload,
      };
    case CLEAR_DISTANCE_FROM:
      return {
        ...state,
        distanceFrom: "",
      };
    case SET_DISTANCE_FILTERS:
      return {
        ...state,
        distanceFilters: payload,
      };
    case SET_DEFAULT_DISTANCE:
      return {
        ...state,
        defaultDistance: payload,
      };
    case SAVE_LAST_ADDRESS:
      return {
        ...state,
        lastAddress: payload,
      };
    case CLEAR_FILTERS:
      return initialState;
    default:
      return state;
  }
}
