import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { GLOBALS } from "../../App";

import TenantProfileSection from "./TenantProfileSection";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { getLabel } from "../../nestiqaUtils";

const useStyles = makeStyles(theme => ({
  questionRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  bottomBorder: {
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
  },
  leftPadding: {
    paddingLeft: theme.spacing(2),
  },
  detailsRow: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),

    "& h6": {
      fontSize: "1.1rem",
    },
  },
}));

const TenantBackgroundInformationSection = ({
  bankrupcy,
  eviction,
  refusedRent,
  criminalOffenceCharges,
  criminalOffenceConviction,
  detailsForQuestions,
  isEditable = true,
  openEditDialog,
}) => {
  const onEditButtonClick = () => {
    openEditDialog(5);
  };

  return (
    <TenantProfileSection
      title="Background Information"
      onEditButtonClick={onEditButtonClick}
      isEditable={isEditable}
    >
      <BackgroundInformationContent
        bankrupcy={bankrupcy}
        eviction={eviction}
        refusedRent={refusedRent}
        criminalOffenceCharges={criminalOffenceCharges}
        criminalOffenceConviction={criminalOffenceConviction}
        detailsForQuestions={detailsForQuestions}
      />
    </TenantProfileSection>
  );
};

export const BackgroundInformationContent = ({
  bankrupcy,
  eviction,
  refusedRent,
  criminalOffenceCharges,
  criminalOffenceConviction,
  detailsForQuestions,
}) => {
  const classes = useStyles();

  return (
    <>
      <div className={clsx(classes.questionRow, classes.bottomBorder)}>
        <Typography variant="body2">
          {GLOBALS.SITE_TEXTS.BANKRUPCY_QUESTION}
        </Typography>
        <Typography variant="body2" className={classes.leftPadding}>
          <strong>{getLabel(bankrupcy, "YES_NO") || ""}</strong>
        </Typography>
      </div>
      <div className={clsx(classes.questionRow, classes.bottomBorder)}>
        <Typography variant="body2">
          {GLOBALS.SITE_TEXTS.EVICTION_QUESTION}
        </Typography>
        <Typography variant="body2" className={classes.leftPadding}>
          <strong>{getLabel(eviction, "YES_NO") || ""}</strong>
        </Typography>
      </div>
      <div className={clsx(classes.questionRow, classes.bottomBorder)}>
        <Typography variant="body2">
          {GLOBALS.SITE_TEXTS.REFUSED_RENT_QUESTION}
        </Typography>
        <Typography variant="body2" className={classes.leftPadding}>
          <strong>{getLabel(refusedRent, "YES_NO") || ""}</strong>
        </Typography>
      </div>
      <div className={clsx(classes.questionRow, classes.bottomBorder)}>
        <Typography variant="body2">
          {GLOBALS.SITE_TEXTS.CRIMINAL_CHARGES_QUESTION}
        </Typography>
        <Typography variant="body2" className={classes.leftPadding}>
          <strong>{getLabel(criminalOffenceCharges, "YES_NO") || ""}</strong>
        </Typography>
      </div>
      <div className={clsx(classes.questionRow, classes.bottomBorder)}>
        <Typography variant="body2">
          {GLOBALS.SITE_TEXTS.CRIMINAL_CONVICTION_QUESTION}
        </Typography>
        <Typography variant="body2" className={classes.leftPadding}>
          <strong>{getLabel(criminalOffenceConviction, "YES_NO") || ""}</strong>
        </Typography>
      </div>
      <div className={clsx(classes.detailsRow, classes.bottomBorder)}>
        <Typography variant="h6">Details</Typography>
        <Typography variant="body2">{detailsForQuestions || "N/A"}</Typography>
      </div>
    </>
  );
};

export default TenantBackgroundInformationSection;
