import React from "react";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { GLOBALS } from "../../App";
import ChoiceField from "../ChoiceField";
import { STATES } from "../../constants/states";
import GoogleMapsAutocomplete from "../google-maps/GoogleMapsAutocomplete";
import * as yup from "yup";
import { createProperty, updateListing } from "../../hooks/ListingApi";
import { getAddressFromPlace } from "../../utils/common";
import LandlordScreenFormControls from "./LandlordScreenFormControls";

const useStyles = makeStyles(theme => ({
  textGrids: {
    flexGrow: 1,
  },
  textInputs: {
    backgroundColor: "white",
    width: "100%",
    marginBottom: theme.spacing(2),

    "& .MuiInputLabel-root": {
      color: theme.palette.grey[400],
    },

    "& .MuiInputLabel-shrink": {
      color: theme.palette.primary.main,
    },
  },
  address: {
    backgroundColor: "white",
    borderRadius: "10px",
    width: "100%",

    "& .MuiInputLabel-root": {
      color: theme.palette.grey[400],
    },

    "& .MuiInputLabel-shrink": {
      color: theme.palette.primary.main,
    },
  },
  stepHeading: {
    color: theme.palette.primary.main,
    paddingBottom: theme.spacing(4),
  },
  [theme.breakpoints.down("sm")]: {
    stepHeading: {
      fontSize: "1.8rem",
    },
  },
  [theme.breakpoints.up("md")]: {
    stepHeading: {
      textTransform: "uppercase",
      position: "relative",

      "&::after": {
        content: "''",
        width: "52px",
        height: "4px",
        position: "absolute",
        left: "50%",
        marginLeft: "-26px",
        bottom: "20px",
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
}));

export default function PropertyAddress(props) {
  const {
    accessToken,
    listing,
    setListings,
    setListing,
    showError,
    closeDialog,
    isCreateListing = false,
    handlePropertyCreateSuccess,
  } = props;
  const MSG = GLOBALS.MESSAGES;

  const listingCallback =
    (formikBag, isCreateListing = false) =>
    (isSuccess, apiResult, apiError) => {
      if (isSuccess) {
        if (setListings) {
          setListings(prev => [
            apiResult,
            ...prev.filter(
              listing => listing.listingNumber !== apiResult.listingNumber
            ),
          ]);
        }
        if (setListing) {
          setListing(apiResult);
        }
        if (isCreateListing) {
          handlePropertyCreateSuccess(apiResult.listingNumber);
        }
        closeDialog();
      } else {
        console.error(apiError);
        showError(MSG.ERROR_COMPLETING_ACTION);
        formikBag.setSubmitting(false);
      }
    };

  function onStepSubmit(values, formikBag) {
    if (isCreateListing) {
      createProperty(
        { status: "INACTIVE", ...values },
        accessToken,
        listingCallback(formikBag, true)
      );
    } else {
      updateListing(
        listing.listingNumber,
        values,
        accessToken,
        listingCallback(formikBag)
      );
    }
  }

  function handleSave(formikprops) {
    if (formikprops.dirty) {
      formikprops.submitForm();
    } else {
      closeDialog();
    }
  }

  const validationSchema = yup.object().shape({
    address1: yup
      .string()
      .required(GLOBALS.MESSAGES.FIELD_REQUIRED)
      .min(1)
      .max(50)
      .matches(
        GLOBALS.FORBIDDEN_CHARACTERS_REGEX,
        GLOBALS.MESSAGES.FORBIDDEN_CHARACTERS
      )
      .label("Street Address"),
    address2: yup.string().max(30).label("Apartment, floor or unit"),
    city: yup.string().required(GLOBALS.MESSAGES.FIELD_REQUIRED),
    state: yup
      .string()
      .matches(GLOBALS.US_STATE_REGEX, GLOBALS.MESSAGES.ENTER_VALID_STATE),
    zipCode: yup
      .string()
      .matches(GLOBALS.US_ZIP_REGEX, GLOBALS.MESSAGES.ENTER_VALID_ZIP),
  });

  return (
    <LandlordScreenFormControls
      listing={listing}
      onSubmit={onStepSubmit}
      validationSchema={validationSchema}
      handleSave={handleSave}
      closeDialog={closeDialog}
    >
      <PropertyAddressFormikWrapper listing={listing} />
    </LandlordScreenFormControls>
  );
}

function PropertyAddressFormikWrapper(props) {
  const classes = useStyles();
  const { values, setValues } = props.formikprops;

  const handleListingAddressChange = (newPlace, manual, values, setValues) => {
    if (!newPlace) return;
    if (manual) {
      setValues({
        ...values,
        address1: newPlace.address_components[0].short_name,
      });
    } else {
      const address = getAddressFromPlace(newPlace);
      setValues({ ...values, ...address });
    }
  };

  return (
    <Grid container justify="center">
      <Grid item xs={12}>
        <Typography className={classes.stepHeading} variant="h4" align="center">
          Property Address
        </Typography>
      </Grid>
      <Grid
        container
        item
        direction="column"
        alignItems="stretch"
        xs={12}
        sm={8}
        md={6}
      >
        <Grid item className={classes.textGrids}>
          <GoogleMapsAutocomplete
            className={classes.address}
            inputClassName={classes.textInputs}
            label="Street Address *"
            inputVariant="outlined"
            name="address"
            popupIcon={null}
            resultTypes={["address"]}
            initialValue={props.listing.address1}
            shortLabel
            allowManual
            onInputChange={newInputValue =>
              setValues({ ...values, address1: newInputValue })
            }
            handlePlaceChange={(place, placeSize, manual) =>
              handleListingAddressChange(place, manual, values, setValues)
            }
          />
        </Grid>
        <Grid item className={classes.textGrids}>
          <Field
            component={TextField}
            name="address2"
            type="text"
            label="Apartment, floor or unit"
            variant="outlined"
            className={classes.textInputs}
            InputLabelProps={{
              shrink: Boolean(props.formikprops.values.address2),
            }}
          />
        </Grid>
        <Grid item className={classes.textGrids}>
          <Field
            component={TextField}
            required
            name="city"
            type="text"
            label="City"
            variant="outlined"
            className={classes.textInputs}
            InputLabelProps={{
              shrink: Boolean(props.formikprops.values.city),
            }}
          />
        </Grid>
        <Grid item className={classes.textGrids}>
          <Field
            component={ChoiceField}
            required
            name="state"
            variant="outlined"
            label="State"
            className={classes.textInputs}
            InputLabelProps={{
              shrink: Boolean(props.formikprops.values.state),
            }}
            choices={STATES}
          />
        </Grid>
        <Grid item className={classes.textGrids}>
          <Field
            component={TextField}
            required
            name="zipCode"
            type="text"
            label="Zip Code"
            variant="outlined"
            className={classes.textInputs}
            InputLabelProps={{
              shrink: Boolean(props.formikprops.values.zipCode),
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
