import React, { Children } from "react";
import clsx from "clsx";
import { Formik, Form } from "formik";
import { useMediaQuery } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import Stepper from "@material-ui/core/Stepper";
import StepButton from "@material-ui/core/StepButton";
import Step from "@material-ui/core/Step";
import MobileStepper from "@material-ui/core/MobileStepper";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

import { GLOBALS } from "../../App";
import { checkFormikError } from "../../utils/common";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: props => (props.isEditDialog ? "0px" : theme.spacing(1)),
  },
  stepHeading: {
    color: theme.palette.primary.main,
  },
  formCard: {
    backgroundColor: "#fff",
    maxWidth: "1000px",
    borderRadius: "5px",
    boxShadow: "0px 3px 36px rgba(0, 0, 0, 0.13)",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: props => (props.isEditDialog ? "0px" : theme.spacing(4)),
    paddingBottom: props => (props.isEditDialog ? theme.spacing(2) : "0px"),
    overflowX: props => (props.isEditDialog ? "hidden" : "visible"),
  },
  cardContent: {
    minHeight: "240px",
    paddingTop: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  btnRow: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(5),
    paddingLeft: theme.spacing(5),
  },
  btnContainer: {
    display: "flex",
    flexDirection: "column",
  },
  error: {
    marginRight: "14px",
    marginLeft: "14px",
    marginTop: "3px",
    fontSize: "0.75rem",
    color: theme.palette.error.main,
  },
  linearProgress: {
    marginTop: theme.spacing(4),
  },
  bgGray: {
    backgroundColor: "#F9F9F9",
    borderRadius: "0 0 5px 5px",
  },
  [theme.breakpoints.down("sm")]: {
    stepHeading: {
      fontSize: "1.8rem",
    },
  },
  [theme.breakpoints.down("xs")]: {
    stepHeading: {
      fontSize: "1.5rem",
    },
    cardContent: {
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    btnRow: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  [theme.breakpoints.up("md")]: {
    stepHeading: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      textTransform: "uppercase",
      position: "relative",

      "&::after": {
        content: "''",
        width: "52px",
        height: "4px",
        position: "absolute",
        left: "50%",
        marginLeft: "-26px",
        bottom: "0px",
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
}));

export default function TenantFormControls(props) {
  const history = useHistory();
  const classes = useStyles(props);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));
  const isTablet =
    useMediaQuery(theme => theme.breakpoints.down("md")) && !isMobile;

  const lastStep = 8;
  const noOfSteps = 8;
  const {
    tenant,
    step,
    setStep,
    onSubmit,
    validationSchema,
    children,
    handleNext,
    className,
    isEditDialog = false,
    closeDialog,
  } = props;

  const steps = [
    "Personal Info",
    "Household",
    "Housing History",
    "Income",
    "Background",
    "Additional Info",
    "Documents",
    "Plan",
  ];

  const stepHeadings = [
    "Personal Info",
    "Household",
    "Housing History",
    "Income",
    "Background",
    "Additional Info",
    "Documents",
    "Choose Your Plan",
  ];

  const setNextStep = () => {
    setStep(prevActiveStep => prevActiveStep + 1);
  };

  const setPrevStep = () => {
    setStep(prevActiveStep => prevActiveStep - 1);
  };

  function applyProps(children, props) {
    return Children.map(children, child => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, props);
      }
      return child;
    });
  }

  return (
    <Formik
      initialValues={tenant}
      validationSchema={validationSchema}
      onSubmit={(values, formikBag) => {
        onSubmit(values, formikBag);
      }}
    >
      {formikprops => {
        return (
          <Form className={clsx(classes.root)}>
            {!isEditDialog && (
              <>
                {isMobile ? (
                  <>
                    <Typography
                      className={classes.stepHeading}
                      variant="h4"
                      align="center"
                    >
                      {stepHeadings[step - 1]}
                    </Typography>
                    <MobileStepper
                      variant="dots"
                      steps={noOfSteps}
                      position="static"
                      activeStep={step - 1}
                      nextButton={
                        <Button
                          color="primary"
                          onClick={setNextStep}
                          disabled={step === lastStep}
                        >
                          <KeyboardArrowRight />
                        </Button>
                      }
                      backButton={
                        <Button
                          color="primary"
                          onClick={setPrevStep}
                          disabled={step === 1}
                        >
                          <KeyboardArrowLeft />
                        </Button>
                      }
                    />
                  </>
                ) : (
                  <>
                    <Stepper
                      alternativeLabel={isTablet}
                      nonLinear
                      activeStep={step - 1}
                    >
                      {steps.map((value, index) => (
                        <Step key={value}>
                          <StepButton
                            disabled={formikprops.isSubmitting}
                            onClick={() => setStep(index + 1)}
                          >
                            {value}
                          </StepButton>
                        </Step>
                      ))}
                    </Stepper>
                    <Typography
                      className={classes.stepHeading}
                      variant="h4"
                      align="center"
                    >
                      {stepHeadings[step - 1]}
                    </Typography>
                  </>
                )}
              </>
            )}

            <div className={clsx(classes.formCard, className)}>
              <div className={classes.cardContent}>
                {applyProps(children, {
                  formikprops: formikprops,
                  isEditDialog: isEditDialog,
                })}
                {formikprops.isSubmitting && (
                  <LinearProgress className={classes.linearProgress} />
                )}
              </div>
              {isEditDialog ? (
                <div className={clsx(classes.btnRow)}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={formikprops.isSubmitting}
                    onClick={closeDialog}
                  >
                    Cancel
                  </Button>
                  <div className={classes.btnContainer}>
                    <Button
                      variant="contained"
                      color="primary"
                      align="center"
                      disabled={formikprops.isSubmitting}
                      onClick={() => handleNext(formikprops)}
                    >
                      {step === 2 ? "Close" : "Save"}
                    </Button>
                    {checkFormikError(formikprops) && (
                      <span className={classes.error}>
                        {GLOBALS.MESSAGES.CORRECT_FORM_ERRORS}
                      </span>
                    )}
                  </div>
                </div>
              ) : (
                <>
                  <div className={classes.btnRow}>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={formikprops.isSubmitting || step === 1}
                      onClick={() => setStep(step - 1)}
                    >
                      Back
                    </Button>
                    <div className={classes.btnContainer}>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={formikprops.isSubmitting}
                        onClick={() => handleNext(formikprops)}
                      >
                        {step === 2 ? "Continue" : "Save & Continue"}
                      </Button>
                      {checkFormikError(formikprops) && (
                        <span className={classes.error}>
                          {GLOBALS.MESSAGES.CORRECT_FORM_ERRORS}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className={clsx(classes.btnRow, classes.bgGray)}>
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled={formikprops.isSubmitting}
                      onClick={formikprops.resetForm}
                    >
                      Reset
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      align="center"
                      disabled={formikprops.isSubmitting}
                      onClick={() => {
                        history.push(`/tenant/${tenant.id}/profile`, {
                          caller: "createTenantRedirect",
                        });
                      }}
                    >
                      Continue Later
                    </Button>
                  </div>
                </>
              )}
            </div>

            {/* <Errors errors={errors} /> */}
          </Form>
        );
      }}
    </Formik>
  );
}
