export const META_TAGS = {
  TITLE: {
    HOME_PAGE: "NestIQa | Intelligent Housing | Rental Listings",
    LISTINGS_PAGE: "Find Listings | NestIQa",
    LISTING_DETAILS_PAGE: " | Listing for rent",
    LANDLORD_PAGE: "Landlord | Free Listing Advertising & Tenant Screening | Nestiqa",
    LANDLORD_SCREEN_PAGE: "Tenant Screening | Tenant Background Check | Nestiqa",
    TENANT_PAGE: "Tenant | Nestiqa",
    PRIVACY_POLICY_PAGE: "Privacy Policy | NestIQa",
    PARTNERSHIPS_PAGE: "Partnerships | NestIQa",
    SAMPLE_REPORTS_PAGE: "Sample Application and Reports | NestIQa",
    TERMS_OF_USE_PAGE: "Terms of Use | NestIQa",
    CREATE_TENANT_PAGE: "Create Tenant Profile | NestIQa",
    TENANT_PROFILE_PAGE: "Tenant Profile - ",
    TENANT_APPLICATIONS_PAGE: "My Applications",
    UPDATE_LISTING_PAGE: "Update Listing: ",
    UPDATE_APPLICATION_PAGE: "Application to: ",
    LANDLORD_APPLICATION_PAGE: "Listing Applications: ",
    LANDLORD_LISTING_PAGE: "Manage Listing: ",
    OPEN_APPLICATIONS_PAGE: "Open Applications to My Listings",
    CONTACT_US_PAGE: "Contact Us | NestIQa",
  },
  DESCRIPTION: {
    HOME_PAGE:
      "Renting made easy. Find your new home today. Browse through our apartments and houses at NestIQa. Get preapproved, then apply for as many listings as you want.",
    LISTINGS_PAGE:
      "Search and find the best rental property that fits your needs. Browse through our apartments and houses at NestIQa. Get preapproved, apply in one place to as many listings as you want.",
    CONTACT_US_PAGE: "Tel. 973.991.9810 Support@NestIQa.com",
    LANDLORD_PAGE: "Nestiqa is a free platform for landlords for advertising rental properties, online applications, screening tenants and more.",
    LANDLORD_SCREEN_PAGE: "Screen your tenants with Nestiqa. Free credit, criminal and eviction reports from TransUnion. Advanced rental application.",
  },
};
