import React from "react";
import ListingFormControls from "./ListingFormControls";
import { activateListing, updateListing } from "../../hooks/ListingApi";
import { useHistory } from "react-router-dom";
import { GLOBALS } from "../../App";
import * as yup from "yup";
import { logEvent } from "../../utils/initGa4React";
import { useGA4React } from "ga-4-react";
import { Typography, Box, Tooltip } from "@material-ui/core";
import { Field } from "formik";
import { CheckboxWithLabel } from "formik-material-ui";

export default function ListingPublish(props) {
  const { accessToken, listing, setListing, step, setStep, showError } = props;
  const MSG = GLOBALS.MESSAGES;
  const history = useHistory();
  const ga = useGA4React();

  function onStepSubmit(values, formikBag) {
    updateListing(
      listing.listingNumber,
      {
        ...values,
      },
      accessToken,
      (isSuccess, apiResult, apiError) => {
        if (isSuccess) {
          setListing({
            ...apiResult,
          });
          activateListing(
            listing.listingNumber,
            accessToken,
            (isSuccess, result, error) => {
              if (isSuccess) {
                if (!!ga) {
                  logEvent("landlord_publish_listing", {
                    listing_number: listing.listingNumber,
                  });
                }
                history.push(`/landlord/listing/${listing.listingNumber}`);
              } else {
                console.error(error);
                showError(MSG.ERROR_COMPLETING_ACTION);
                formikBag.setSubmitting(false);
              }
            }
          );
        } else {
          console.error(apiError);
          showError(MSG.ERROR_COMPLETING_ACTION);
          formikBag.setSubmitting(false);
        }
      }
    );
  }

  function handleNext(formikprops) {
    formikprops.submitForm();
  }

  const validationSchema = yup.object().shape({});

  return (
    <ListingFormControls
      listing={listing}
      step={step}
      setStep={setStep}
      onSubmit={onStepSubmit}
      validationSchema={validationSchema}
      handleNext={handleNext}
    >
      <ListingPublishFormikWrapper />
    </ListingFormControls>
  );
}

const ListingPublishFormikWrapper = ({ formikprops }) => {
  return (
    <>
      <Typography variant="body1" color="textPrimary">
        {GLOBALS.SITE_TEXTS.PUBLISH_INSTRUCTIONS}
      </Typography>

      {formikprops.values.landlord.landlordType === "RTR" && (
        <Tooltip
          placement="top-start"
          title="Check if you are the only realtor advertising this listing"
        >
          <Box my={2}>
            <Field
              component={CheckboxWithLabel}
              name="isExclusive"
              type="checkbox"
              Label={{ label: "I am advertising this property exclusively" }}
            />
          </Box>
        </Tooltip>
      )}

      {formikprops.values.landlord.landlordType === "RTR" &&
      !formikprops.values.isExclusive ? (
        <Tooltip
          placement="top-start"
          title="Listing must be exclusive to be advertised on partner sites"
        >
          <Box>
            <Field
              component={CheckboxWithLabel}
              type="checkbox"
              disabled
              checked={false}
              Label={{ label: "Advertise this listing in partner websites" }}
            />
          </Box>
        </Tooltip>
      ) : (
        <Box>
          <Field
            component={CheckboxWithLabel}
            name="advertiseToPartners"
            type="checkbox"
            Label={{ label: "Advertise this listing in partner websites" }}
          />
        </Box>
      )}
    </>
  );
};
