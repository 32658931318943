import React, { useState } from "react";
import { formatter } from "../../utils/common";
import {
  formatPhoneNumber,
  formatListingDate,
  getLabel,
} from "../../nestiqaUtils";
import { makeStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import bedIcon from "../../static/images/bed_icon.png";
import bathIcon from "../../static/images/bath_icon.png";
import { Room } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  summaryWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",

    "& div > .MuiTypography-root": {
      fontWeight: 500,
    },
  },
  address: {
    display: "flex",
    marginLeft: "-5px",

    "& svg": {
      color: theme.palette.secondary.main,
      marginRight: "5px",
    },
  },
  row: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    paddingBottom: theme.spacing(1),

    "& div:first-child p:first-child": {
      paddingBottom: "2px",
    },
  },
  summary: {
    width: "100%",
    padding: 0,

    "& .MuiAccordionSummary-content": {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },

    "& .MuiAccordionSummary-root": {
      padding: 0,
    },
  },
  details: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    paddingLeft: 0,
    paddingRight: 0,
  },
  accordian: {
    boxShadow: "none",

    "&:not(:first-child)": {
      border: "none",
      borderTop: "2px solid",
      borderColor: theme.palette.secondary.main,
    },
  },
  alignRight: {
    textAlign: "right",
  },
  alignIcon: {
    display: "flex",
    justifyContent: "flex-end",
  },
  accordionIcon: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "& > div": {
      marginRight: theme.spacing(3),
      alignItems: "baseline",

      "& > .MuiTypography-body2": {
        marginRight: theme.spacing(1),
      },

      "& > img": {
        marginRight: theme.spacing(1),
      },
    },
  },
  bedsBaths: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& div:first-child": {
      flexGrow: 1,
    },

    "& div:nth-child(3)": {
      marginLeft: "5px",
      flexGrow: 1,
    },

    "& > div": {
      "& span": {
        margin: "0 5px",
      },

      "& img": {
        width: "22px",
      },
    },
  },
  status: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "70px",
    height: "25px",

    "& span": {
      borderRadius: "5px",
      padding: "2px 8px",
      textAlign: "left !important",
      marginRight: "-2px",
      fontSize: "13px",
    },
  },
  colorSubmitted: {
    backgroundColor: "#ECFAFB",
    color: "#3CE0E5",
  },
  colorApproved: {
    backgroundColor: "#EFFBEC",
    color: "#58D865",
  },
  colorRejected: {
    backgroundColor: "#FBECEC",
    color: "#CF7F7F",
  },
  colorPending: {
    backgroundColor: "#FBF7EC",
    color: "#D89A3D",
  },
  smallText: {
    fontSize: "13px",
  },
}));

const TenantApplicationHistoryAccordion = ({ applicationHistory }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleChange = id => (event, isExpanded) => {
    setExpanded(isExpanded ? id : false);
  };

  const getColor = status => {
    switch (status) {
      case "SUBMITTED":
        return classes.colorSubmitted;
      case "APPROVED":
        return classes.colorApproved;
      case "REJECTED":
        return classes.colorRejected;
      case "PROCESSING":
        return classes.colorPending;
      default:
        return "";
    }
  };

  return (
    <>
      {applicationHistory.map(application => (
        <Accordion
          expanded={expanded === application.id}
          key={application.id}
          className={classes.accordian}
          onChange={handleChange(application.id)}
        >
          <AccordionSummary id={application.id} className={classes.summary}>
            <div className={classes.summaryWrapper}>
              <div className={classes.address}>
                <Room />
                <div>
                  <Typography variant="body2">
                    {application.listing.address1}
                    {application.listing.address2 !== ""
                      ? `, ${application.listing.address2}`
                      : null}
                  </Typography>
                  <Typography variant="body2">
                    {application.listing.city}, {application.listing.state},{" "}
                    {application.listing.zipCode}
                  </Typography>
                </div>
              </div>
            </div>
            <div className={classes.alignIcon}>
              {expanded === application.id ? (
                <ExpandLessIcon />
              ) : (
                <ExpandMoreIcon />
              )}
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <div className={classes.row}>
              <div>
                <Typography variant="body2">
                  <strong>Monthly Rent</strong>
                </Typography>
                <Typography variant="body2" className={classes.smallText}>
                  {formatter.format(application.listing.rent)}
                </Typography>
              </div>
              <div className={classes.alignRight}>
                <Typography variant="body2">
                  <strong>Beds/Baths</strong>
                </Typography>
                <div className={classes.bedsBaths}>
                  <div>
                    <span className={classes.smallText}>
                      {getLabel(application.listing.bedrooms, "BEDROOMS")}
                    </span>
                  </div>
                  <div>
                    <img src={bedIcon} alt="bedrooms" />
                  </div>
                  <div>
                    <span className={classes.smallText}>
                      {getLabel(application.listing.bathrooms, "BATHROOMS")}
                    </span>
                  </div>
                  <div>
                    <img src={bathIcon} alt="bathrooms" />
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.row}>
              <div>
                <Typography variant="body2">
                  <strong>Contact Name</strong>
                </Typography>
                <Typography variant="body2" className={classes.smallText}>
                  {application.listing.contactName}
                </Typography>
                <Typography variant="body2" className={classes.smallText}>
                  {formatPhoneNumber(application.listing.contactPhone, true)}
                </Typography>
              </div>
              <div className={classes.alignRight}>
                <Typography variant="body2">
                  <strong>Available</strong>
                </Typography>
                <Typography variant="body2" className={classes.smallText}>
                  {formatListingDate(application.listing.availabilityDate)}
                </Typography>
              </div>
            </div>
            <div className={classes.row}>
              <div>
                <Typography variant="body2">
                  <strong>Submitted</strong>
                </Typography>
                <Typography variant="body2" className={classes.smallText}>
                  {formatListingDate(application.submitted)}
                </Typography>
              </div>
              <div className={classes.alignRight}>
                <Typography variant="body2">
                  <strong>Status</strong>
                </Typography>
                <div className={classes.status}>
                  <span className={getColor(application.status)}>
                    {getLabel(application.status, "APP_STATUSES")}
                  </span>
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default TenantApplicationHistoryAccordion;
