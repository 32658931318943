import { useState, useEffect } from "react";
import { getApplicationWithHousehold } from "../utils/ApplicationApi";
import useAuth0Nesitqa from "./useAuth0Nestiqa";

export default function useLandlordApplicationWithHousehold(applicationId) {
  const [results, setResults] = useState({
    status: "IDLE",
  });
  const { accessToken } = useAuth0Nesitqa("");

  useEffect(() => {
    if (!applicationId || !accessToken) return;

    const setResultsCallback = (isSuccess, apiResult, apiError) => {
      setResults({
        status: "COMPLETE",
        application: isSuccess ? apiResult : null,
        error: isSuccess ? null : apiError,
      });
    };

    setResults({ status: "LOADING" });
    getApplicationWithHousehold(applicationId, accessToken, setResultsCallback);
  }, [applicationId, accessToken]);

  return results;
}
