import { Button, Typography } from "@material-ui/core";
import React from "react";
import { GLOBALS } from "../../App";

const ValidateComm = ({ onContinue, phone = null, email = null }) => {
  let showButton = true;
  let message = "";
  if (email) {
    message = GLOBALS.MESSAGES.VALIDATE_COMM_MSG_EMAIL.replace(
      "[email]",
      email
    );
  } else if (phone) {
    message = GLOBALS.MESSAGES.VALIDATE_COMM_MSG_PHONE.replace(
      "[phone]",
      phone
    );
  } else {
    message = GLOBALS.MESSAGES.VALIDATE_COMM_NOT_AVAILABLE;
    showButton = false;
  }

  return (
    <div>
      <Typography gutterBottom>{message}</Typography>
      {showButton && (
        <Button
          variant="contained"
          disableElevation
          color="primary"
          onClick={onContinue}
        >
          Continue
        </Button>
      )}
    </div>
  );
};

export default ValidateComm;
