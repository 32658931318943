import React from "react";
import { useHistory } from "react-router-dom";
import { formatter } from "../../utils/common";
import { getLabel, getOpenApplicationStatus } from "../../nestiqaUtils";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Room } from "@material-ui/icons";
import { GLOBALS } from "../../App";
import bedIcon from "../../static/images/bed_icon.png";
import bathIcon from "../../static/images/bath_icon.png";
import clsx from "clsx";
import Tooltip from "../tooltip/Tooltip";
import StyledTableCell from "../StyledTableCell";

const useStyles = makeStyles(theme => ({
  table: {
    width: "100%",
  },
  tbody: {
    "& .MuiTableRow-root td:not(:last-child):hover": {
      cursor: "pointer",
    },
  },
  noBorder: {
    border: "none",
  },
  topBorder: {
    borderTop: "2px solid",
    borderColor: theme.palette.secondary.main,
  },
  paddingY: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  status: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",

    "& span": {
      backgroundColor: "#FBF7EC",
      color: "#D89A3D",
      borderRadius: "5px",
      padding: "2px 8px",
    },
  },
  contact: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",

    "& span": {
      flexBasis: "100%",
    },
  },
  address: {
    display: "flex",

    "& svg": {
      color: theme.palette.secondary.main,
    },
  },
  bedsBaths: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "15px",
    marginTop: "1px",

    "& > span": {
      flexGrow: 1,
      margin: "0 15px",
    },

    "& div:nth-child(3)": {
      marginLeft: "10px",
      flexGrow: 1,
    },

    "& > div": {
      "& img": {
        width: "22px",
      },
    },
  },
  contactHeader: {
    paddingLeft: "20px",
  },
  actionWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "& svg": {
      marginRight: "20px",
      fontSize: "28px",
    },
  },
  actionLink: {
    color: theme.palette.primary.main,
    textDecoration: "underline",

    "&:hover": {
      cursor: "pointer",
    },
  },
  tooltip: {
    fontSize: "12px",
    margin: 0,
    padding: "5px",
  },
}));

const HouseholdApplicationTable = ({ applicationHistory, tenantId }) => {
  const classes = useStyles();
  const history = useHistory();
  const isTablet = useMediaQuery(theme => theme.breakpoints.down("sm"));

  const handleClick = application => {
    return history.push(`/application/${application.id}/join`);
  };

  const rowAction = GLOBALS.REQUIRED_ACTIONS.find(
    ({ status }) => status === "JOIN_HOUSEHOLD"
  );

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow className={classes.tableHead}>
          <StyledTableCell
            className={classes.noBorder}
            align="left"
            width="20%"
          >
            Address
          </StyledTableCell>
          <StyledTableCell
            className={classes.noBorder}
            align="center"
            width="15%"
          >
            Info
          </StyledTableCell>
          <StyledTableCell
            className={clsx(classes.noBorder, classes.contactHeader)}
            width="25%"
          >
            Submitted By
          </StyledTableCell>
          <StyledTableCell className={classes.noBorder}>Status</StyledTableCell>
          <StyledTableCell className={classes.noBorder} width="20%">
            Required Action
          </StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody className={classes.tbody}>
        {applicationHistory.map((application, i) => {
          return (
            <TableRow key={application.id}>
              <StyledTableCell
                className={clsx(
                  i !== 0 && classes.topBorder,
                  classes.noBorder,
                  classes.paddingY
                )}
                align="left"
                onClick={() => handleClick(application)}
              >
                <div className={classes.address}>
                  <Room />
                  <div>
                    {application.listing.address1}
                    {application.listing.address2 !== ""
                      ? `, ${application.listing.address2}`
                      : null}
                    <br />
                    {application.listing.city}, {application.listing.state},{" "}
                    {application.listing.zipCode}
                  </div>
                </div>
              </StyledTableCell>
              <StyledTableCell
                className={clsx(
                  i !== 0 && classes.topBorder,
                  classes.noBorder,
                  classes.paddingY
                )}
                align="center"
                onClick={() => handleClick(application)}
              >
                <span>{formatter.format(application.listing.rent)}</span>
                <div className={classes.bedsBaths}>
                  <span>
                    {getLabel(application.listing.bedrooms, "BEDROOMS")}
                  </span>
                  <div>
                    <img src={bedIcon} alt="bedrooms" />
                  </div>
                  <span>
                    {getLabel(application.listing.bathrooms, "BATHROOMS")}
                  </span>
                  <div>
                    <img src={bathIcon} alt="bathrooms" />
                  </div>
                </div>
              </StyledTableCell>
              <StyledTableCell
                className={clsx(
                  i !== 0 && classes.topBorder,
                  classes.noBorder,
                  classes.paddingY,
                  classes.contactHeader
                )}
                align="left"
                onClick={() => handleClick(application)}
              >
                <div className={classes.contact}>{application.tenantName}</div>
              </StyledTableCell>
              <StyledTableCell
                className={clsx(
                  i !== 0 && classes.topBorder,
                  classes.noBorder,
                  classes.paddingY
                )}
                align="left"
                onClick={() => handleClick(application)}
              >
                <div className={classes.status}>
                  <span>
                    {getOpenApplicationStatus(
                      application.status,
                      application.tenantName
                    )}
                  </span>
                </div>
              </StyledTableCell>
              <StyledTableCell
                className={clsx(
                  i !== 0 && classes.topBorder,
                  classes.noBorder,
                  classes.paddingY
                )}
                onClick={() => handleClick(application)}
              >
                <div className={classes.actionWrapper}>
                  <span className={classes.actionLink}>{rowAction.label}</span>

                  {!isTablet ? (
                    <Tooltip
                      householdApp={true}
                      application={application}
                      clickable={true}
                    />
                  ) : (
                    <Tooltip
                      householdApp={true}
                      application={application}
                      clickable={false}
                    />
                  )}
                </div>
              </StyledTableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default HouseholdApplicationTable;
