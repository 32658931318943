import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setUser } from "../store/actions/userActions";
import { getUserInfo } from "../utils/UserApi";
import useAuth0Nesitqa from "./useAuth0Nestiqa";

const useActiveUser = () => {
  const { accessToken } = useAuth0Nesitqa();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!accessToken) return;
    getUserInfo(accessToken, (isSuccess, apiResult, apiError) => {
      if (isSuccess) {
        dispatch(setUser(apiResult));
      } else {
        console.error(apiError);
      }
    });
  }, [accessToken, dispatch]);
};

export default useActiveUser;
