import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { GLOBALS } from "../../App";
import { setReportStatus } from "../../store/actions/reportsActions";
import { initReports } from "../../utils/ReportsApi";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));
const NeedInit = ({ accessToken, showError }) => {
  const classes = useStyles();
  const { tenantId } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const reviewProfile = () => {
    history.push(GLOBALS.PATHS.TENANT_PROFILE.replace(":tenantId", tenantId));
  };

  const initReportsCalback = (isSuccess, result, error) => {
    setLoading(false);

    if (isSuccess) {
      if (result.status === "NEED_VERIFICATION") {
        dispatch(setReportStatus(result.status));
      } else {
        showError();
      }
    }
    if (error) {
      showError();
      console.error(error);
    }
  };

  async function callInitReports() {
    setLoading(true);
    initReports(accessToken, initReportsCalback);
  }

  return (
    <div className={classes.root}>
      <Typography variant="body1">{GLOBALS.MESSAGES.NEED_INIT}</Typography>
      <Button
        disabled={loading}
        className={classes.button}
        color="primary"
        variant="contained"
        onClick={reviewProfile}
      >
        Review Profile
      </Button>
      <Button
        disabled={loading}
        className={classes.button}
        color="primary"
        variant="contained"
        onClick={callInitReports}
      >
        Continue
      </Button>
      {loading && (
        <Typography variant="h5" align="center" color="primary">
          Loading...
        </Typography>
      )}
    </div>
  );
};

export default NeedInit;
