import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { Backdrop, CircularProgress } from "@material-ui/core";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import { useParams } from "react-router-dom";
import tenantPicture from "./../tenant_picture.png";
import { setTenantPicture } from "../../utils/TenantApi";
import useAuth0Nesitqa from "../../hooks/useAuth0Nestiqa";
import { getStaticImgSrc } from "../../nestiqaUtils";
import NestiqaAlertDialog from "../NestiqaAlertDialog";
import { GLOBALS } from "../../App";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  wrapper: {
    position: "relative",

    "& input": {
      display: "none",
    },
  },
  image: {
    width: "180px",
    height: "180px",
    border: "3px solid",
    borderColor: theme.palette.primary.main,
    borderRadius: "0 0 20px 20px",
    boxShadow: theme.shadows[10],
    objectFit: "cover",
    objectPosition: "center",
  },
  name: {
    marginTop: theme.spacing(2),
  },
  buttonLabel: {
    position: "absolute",
    right: "-8px",
    bottom: "0px",
    cursor: "pointer",

    "&:hover .MuiIconButton-root": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    transition: "all 200ms",
    pointerEvents: "none",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  [theme.breakpoints.down("sm")]: {
    root: {
      marginBottom: theme.spacing(1),
    },
  },
  [theme.breakpoints.down("xs")]: {
    image: {
      width: "150px",
      height: "150px",
    },
  },
}));

const TenantProfileImage = props => {
  const classes = useStyles();
  const { tenantId } = useParams();

  const { accessToken } = useAuth0Nesitqa("");

  const [profilePicture, setProfilePicture] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dialogControls, setDialogControls] = useState({
    open: false,
    title: "",
    message: "",
  });

  function showError() {
    setDialogControls({
      open: true,
      title: "Error",
      message: GLOBALS.MESSAGES.ERROR_COMPLETING_ACTION,
    });
  }

  const closeAlert = () => {
    setDialogControls({ open: false });
  };

  const handleImageUpload = e => {
    const [file] = e.target.files;
    setLoading(true);

    setTenantPicture(
      file,
      tenantId,
      accessToken,
      (isSuccess, apiResult, apiError) => {
        setLoading(false);
        if (isSuccess) {
          setProfilePicture(apiResult.picture);
          props.setTenant(tenant => ({ ...tenant, ...apiResult }));
        } else {
          showError();
        }
      }
    );
  };

  const image = profilePicture || props.image || getStaticImgSrc(tenantPicture);

  return (
    <>
      <NestiqaAlertDialog {...dialogControls} onClose={closeAlert} />

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="secondary" />
      </Backdrop>
      <Grid
        className={classes.root}
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <div className={classes.wrapper}>
          <img src={image} alt={props.name} className={classes.image} />
          <input
            type="file"
            id="imgUpload"
            accept="image/*"
            multiple={false}
            onChange={handleImageUpload}
          />
          <label htmlFor="imgUpload" className={classes.buttonLabel}>
            <IconButton size="small" className={classes.button}>
              <CameraAltIcon />
            </IconButton>
          </label>
        </div>
        <Typography variant="h4" align="center" className={classes.name}>
          {props.name}
        </Typography>
      </Grid>
    </>
  );
};

TenantProfileImage.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string.isRequired,
};

export default TenantProfileImage;
