import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { GLOBALS } from "../App";
import { acceptTerms } from "../utils/LandlordApi";
import { logEvent } from "../utils/initGa4React";
import { useGA4React } from "ga-4-react";

const useStyles = makeStyles(theme => ({
  serviceAgreementBox: {
    border: "1px solid #000",
    whiteSpace: "break-spaces",
    padding: theme.spacing(1),
    height: "300px",
    overflowY: "scroll",
  },
  dialogAction: {
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
}));

export default function AcceptTermsDialog({
  open = false,
  onClose,
  showError,
  createListing,
  accessToken,
  setAcceptedTerms,
}) {
  const classes = useStyles();
  const ga = useGA4React();

  function acceptTermsCallback(isSuccess, result, error) {
    onClose();
    if (isSuccess) {
      if (!!ga) {
        logEvent("landlord_accept_terms", {});
      }
      if (createListing) {
        createListing();
      }
      if (setAcceptedTerms) {
        setAcceptedTerms(result.accepted_terms);
      }
    }
    if (error) {
      showError(GLOBALS.MESSAGES.ERROR_COMPLETING_ACTION);
      console.error(error);
    }
  }

  const onAcceptTerms = () => {
    acceptTerms(accessToken, acceptTermsCallback);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Accept Terms</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {GLOBALS.MESSAGES.ACCEPT_TERMS_DIALOG_TEXT}
        </DialogContentText>
        <pre className={classes.serviceAgreementBox}>
          {GLOBALS.TERMS_AND_CONDITIONS.ATTACHMENT_B}
        </pre>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Button variant="contained" color="primary" onClick={onAcceptTerms}>
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
}
