import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Tooltip, Typography } from "@material-ui/core";
import { GLOBALS } from "../App";
import { Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Hidden from "@material-ui/core/Hidden";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import NestiqaAlertDialog from "./NestiqaAlertDialog";
import AcceptTermsDialog from "./AcceptTermsDialog";
import AddPropertyDialog from "./landlord-screen/AddPropertyDialog";
import InviteApplicants from "./landlord-screen/InviteApplicants";
import NestiqaActionDialog from "./NestiqaActionDialog";
import ShareIcon from "@material-ui/icons/Share";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import { useHistory } from "react-router-dom";
import { useGA4React } from "ga-4-react";
import { logEvent } from "../utils/initGa4React";

const useStyles = makeStyles(theme => ({
  cardGrid: {
    padding: theme.spacing(1),
    borderTop: "0px solid #000",
    borderRight: "1px solid #000",
    borderBottom: "1px solid #000",
    borderLeft: "1px solid #000",
    borderRadius: "0px 0px 30px 30px",
  },
  listingsBox: {
    marginBottom: theme.spacing(4),
    marginTop: "5%",
    flexGrow: 1,
  },
  cardMedia: {
    paddingTop: "75%", // 4:3
    borderBottom: "2px solid",
    borderColor: theme.palette.primary.dark,
    borderBottomColor: theme.palette.primary.dark,
  },
  table: {
    minWidth: 650,
  },
  theadTableCell: {
    border: "none",
    fontWeight: "bold",
  },
  tableCell: {
    borderColor: theme.palette.secondary.main,
  },
  cardContent: {
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    padding: "5px 0px 5px 0px",
  },
  card: {
    height: "100%",
    boxShadow: theme.shadows[0],
    position: "relative",
  },
  tableContainer: {
    boxShadow: "none",
  },
  cardFullWidth: {
    flex: "1",
  },
  statusRight: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    flex: "1",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    borderLeft: "1px solid",
    borderRight: "1px solid",
    borderTop: "1px solid",
    borderColor: theme.palette.primary.dark,
    borderBottom: "none",
    borderRadius: "0 20px 0 0",
    textTransform: "capitalize",
    fontSize: "1.2rem",
  },
  headerRight: {
    borderBottom: "1px solid",
    borderColor: theme.palette.primary.dark,
    flexGrow: 2,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  noItemsButton: {
    borderBottom: "1px solid",
    borderColor: theme.palette.primary.dark,
    flexGrow: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  viewButton: {
    marginLeft: theme.spacing(2),
  },
}));

export default function PropertyTable(props) {
  const {
    listings,
    setListings,
    title,
    noPropertiesText,
    showCreate,
    loading,
    error,
    landlord,
    openEditDialog,
    setAcceptedTerms,
    acceptedTerms,
    accessToken,
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const ga = useGA4React();

  const [dialogControls, setDialogControls] = useState({
    open: false,
    title: "",
    message: "",
  });

  const [acceptDialogControls, setAcceptDialogControls] = useState({
    open: false,
  });

  const [addPropertyDialogControls, setAddPropertyDialogControls] = useState({
    open: false,
    listing: {},
    setListings: setListings,
  });

  const closeAddPropertyDialogControls = () => {
    setAddPropertyDialogControls({
      open: false,
      listing: {},
      setListings: setListings,
    });
  };

  const [actionDialogControls, setActionDialogControls] = useState({
    open: false,
  });

  const actionDialogButtons = [
    {
      buttonName: "close",
      handleClick: () => setActionDialogControls({ open: false }),
    },
  ];

  function showError(message) {
    setDialogControls({
      open: true,
      title: "Error",
      message,
    });
  }

  const closeAcceptTermsDialog = () => {
    setAcceptDialogControls({ open: false });
  };

  const handlePropertyCreateSuccess = listingNumber => {
    if (!!ga) {
      logEvent("landlord_created_property", { page: "landlord/screen" });
    }
    setActionDialogControls({
      open: true,
      title: "Success",
      message: (
        <>
          <Typography variant="body1">
            {GLOBALS.MESSAGES.PROPERTY_CREATE_SUCCESS_MSG}
          </Typography>
          <InviteApplicants listingNumber={listingNumber} hideLabel />
        </>
      ),
      buttonMap: actionDialogButtons,
    });
  };

  function createListing() {
    if (landlord && landlord.isComplete === "N") {
      showError(GLOBALS.MESSAGES.COMPLETE_LANDLORD_INFO);
    } else if (!acceptedTerms) {
      // Do Nothing
    } else if (acceptedTerms === "N") {
      setAcceptDialogControls({
        open: true,
        onClose: closeAcceptTermsDialog,
        showError,
        createListing: () =>
          setAddPropertyDialogControls({
            open: true,
            closeDialog: closeAddPropertyDialogControls,
            listing: {},
            setListings: setListings,
            isCreateListing: true,
            handlePropertyCreateSuccess: handlePropertyCreateSuccess,
          }),
        accessToken,
        setAcceptedTerms,
      });
    } else {
      setAddPropertyDialogControls({
        open: true,
        closeDialog: closeAddPropertyDialogControls,
        listing: {},
        setListings: setListings,
        isCreateListing: true,
        handlePropertyCreateSuccess: handlePropertyCreateSuccess,
      });
    }
  }

  const closeAlert = () => {
    setDialogControls({ open: false });
    if (landlord?.isComplete === "N") {
      openEditDialog();
    }
  };

  function getStreetAddress(listing) {
    if (!listing.address1) return "NA";
    let result = listing.address1;
    if (listing.address2) result += `, ${listing.address2}`;
    return result;
  }

  const handleClickView = listingNumber => {
    history.push(`/landlord/screen/property/${listingNumber}`);
  };

  const handleClickEdit = listing => {
    setAddPropertyDialogControls({
      open: true,
      closeDialog: closeAddPropertyDialogControls,
      listing: listing,
      setListings: setListings,
      isCreateListing: false,
    });
  };

  const handleClickInvite = listingNumber => {
    setActionDialogControls({
      open: true,
      title: "Invite Applicants",
      message: <InviteApplicants listingNumber={listingNumber} hideLabel />,
      buttonMap: actionDialogButtons,
    });
  };

  const getActionButtons = row => (
    <>
      <Tooltip title="View Applications" enterTouchDelay={0}>
        <IconButton
          color="secondary"
          className={clsx(classes.actionButton)}
          onClick={() => handleClickView(row.listingNumber)}
        >
          <VisibilityIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Edit" enterTouchDelay={0}>
        <IconButton
          color="secondary"
          className={clsx(classes.actionButton)}
          onClick={() => handleClickEdit(row)}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Invite Applicants" enterTouchDelay={0}>
        <IconButton
          color="secondary"
          className={clsx(classes.actionButton)}
          onClick={() => handleClickInvite(row.listingNumber)}
        >
          <ShareIcon />
        </IconButton>
      </Tooltip>
    </>
  );

  if (loading) {
    return (
      <Typography variant="h5" align="center" color="primary">
        Loading...
      </Typography>
    );
  }

  if (error) {
    console.error(error);
    return (
      <Typography variant="h5" align="center" color="error">
        {GLOBALS.MESSAGES.ERROR_MY_LISTINGS}
      </Typography>
    );
  }

  if (listings?.length === 0 && showCreate) {
    return (
      <>
        <AddPropertyDialog {...addPropertyDialogControls} />
        <AcceptTermsDialog {...acceptDialogControls} />
        <NestiqaAlertDialog {...dialogControls} onClose={closeAlert} />
        <Typography variant="h5" align="center" color="primary">
          {noPropertiesText}
        </Typography>
        <div className={classes.noItemsButton} style={{ border: "none" }}>
          <Button
            color="primary"
            variant="contained"
            onClick={createListing}
            className={classes.button}
          >
            Add Property
          </Button>
        </div>
      </>
    );
  }

  return (
    <>
      <NestiqaActionDialog
        {...actionDialogControls}
        style={{
          maxWidth: "400px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      />
      <AddPropertyDialog {...addPropertyDialogControls} />
      <AcceptTermsDialog {...acceptDialogControls} />
      <NestiqaAlertDialog {...dialogControls} onClose={closeAlert} />
      <Grid item className={classes.listingsBox}>
        <div className={classes.header}>
          <Typography className={classes.title} variant="h6">
            {title}
          </Typography>
          <div className={classes.headerRight}>
            {showCreate && (
              <Button
                color="primary"
                variant="contained"
                onClick={createListing}
                startIcon={<AddIcon />}
                className={classes.button}
              >
                Add Property
              </Button>
            )}
          </div>
        </div>
        <div className={classes.content}>
          <Container className={classes.cardGrid} maxWidth="lg">
            <Grid container spacing={0}>
              <Hidden only="xs">
                <TableContainer
                  className={classes.tableContainer}
                  component={Paper}
                >
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className={classes.theadTableCell}
                          align="left"
                        >
                          Address
                        </TableCell>

                        <TableCell
                          className={classes.theadTableCell}
                          align="center"
                        >
                          Open Applications
                        </TableCell>
                        <TableCell
                          className={classes.theadTableCell}
                          align="center"
                        ></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {listings.map(row => (
                        <TableRow key={row.id}>
                          <TableCell align="left" className={classes.tableCell}>
                            {getStreetAddress(row)}
                            <br />
                            {row.city ? `${row.city}, ` : ""}
                            {row.state || "-"}
                          </TableCell>

                          <TableCell
                            align="center"
                            className={classes.tableCell}
                          >
                            {row.openApplications || 0}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.tableCell}
                          >
                            {getActionButtons(row)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Hidden>
              <Hidden smUp>
                <Grid item key={props.card} className={classes.cardFullWidth}>
                  <Card className={classes.card}>
                    {listings.map(row => (
                      <CardContent key={row.id} className={classes.cardContent}>
                        <Grid container spacing={1}>
                          <Grid container item xs={12} spacing={0}>
                            {getStreetAddress(row)}
                          </Grid>
                          <Grid
                            container
                            item
                            xs={12}
                            spacing={0}
                            alignItems="center"
                          >
                            <Grid container item xs={6} spacing={0}>
                              <Box fontWeight="fontWeightBold" mr={1}>
                                Open Applications:
                              </Box>{" "}
                              {row.openApplications || 0}
                            </Grid>
                            <Grid
                              container
                              item
                              xs={6}
                              spacing={0}
                              className={clsx(classes.statusRight)}
                            >
                              {getActionButtons(row)}
                            </Grid>
                          </Grid>
                        </Grid>
                      </CardContent>
                    ))}
                  </Card>
                </Grid>
              </Hidden>
            </Grid>
          </Container>
        </div>
      </Grid>
    </>
  );
}
