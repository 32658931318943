import React, { useCallback, useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import { Typography } from "@material-ui/core";
import useAuth0Nesitqa from "../hooks/useAuth0Nestiqa";
import { useHistory, useParams } from "react-router-dom";
import { GLOBALS } from "../App";
import NestiqaAlertDialog from "./../components/NestiqaAlertDialog";
import AcceptTerms from "../components/tenant-reports/AcceptTerms";
import { useDispatch, useSelector } from "react-redux";
import {
  getReportsLoading,
  getReportsStatus,
  getScreeningRequestId,
} from "../store/selectors/reportsSelectors";
import { callGetAvailableReports } from "../store/actions/reportsActions";
import NeedsInfo from "../components/tenant-reports/NeedsInfo";
import NeedInit from "../components/tenant-reports/NeedInit";
import NeedVerification from "../components/tenant-reports/NeedVerification";
import VerificationComplete from "../components/tenant-reports/VerificationComplete";
import useTenant from "../hooks/useTenant";

export default function ReportsPage() {
  const { tenantId } = useParams();

  const history = useHistory();
  const { accessToken } = useAuth0Nesitqa("");

  const [alertDialogControls, setAlertDialogControls] = useState({
    open: false,
  });

  const dispatch = useDispatch();
  const status = useSelector(getReportsStatus);
  const loading = useSelector(getReportsLoading);
  const screeningRequestId = useSelector(getScreeningRequestId);

  const showError = useCallback(() => {
    setAlertDialogControls({
      open: true,
      onClose: () => {
        setAlertDialogControls({ open: false });
        dispatch(callGetAvailableReports(accessToken));
      },
      message: GLOBALS.MESSAGES.ERROR_COMPLETING_ACTION,
    });
  }, [accessToken, dispatch]);

  useEffect(() => {
    if (!accessToken) return;
    if (!status) {
      dispatch(callGetAvailableReports(accessToken));
    }
  }, [accessToken, status, dispatch]);

  useEffect(() => {
    if (!status) return;

    const onAlertDialogClose = () => {
      setAlertDialogControls({ open: false });
      history.push(GLOBALS.PATHS.TENANT_PROFILE.replace(":tenantId", tenantId));
    };

    switch (status) {
      case "REPORTS_READY":
        setAlertDialogControls({
          open: true,
          onClose: () => onAlertDialogClose(),
          message: GLOBALS.MESSAGES.REPORTS_READY,
        });
        break;

      case "REPORTS_IN_PROGRESS":
        setAlertDialogControls({
          open: true,
          onClose: () => onAlertDialogClose(),
          message: GLOBALS.MESSAGES.REPORTS_IN_PROGRESS,
        });
        break;

      case "NEED_MANUAL_VERIFICATION":
        setAlertDialogControls({
          open: true,
          onClose: () => {
            setAlertDialogControls({ open: false });
            history.push(
              GLOBALS.PATHS.TENANT_PROFILE.replace(":tenantId", tenantId)
            );
          },
          message: GLOBALS.MESSAGES.MANUAL_VERIFICATION_REQUIRED.replace(
            "[requestId]",
            screeningRequestId
          ),
        });
        break;

      case "VERIFICATION_IN_PROGRESS":
        showError();
        break;

      default:
        break;
    }
  }, [status, history, tenantId, screeningRequestId, showError]);

  const [tenantParams] = useState({
    tenantId: tenantId,
    action: "get-tenant",
  });
  const [tenant, setTenant] = useState();

  const tenantResult = useTenant(tenantParams);

  useEffect(() => {
    if (tenantResult.status === "COMPLETE" && tenantResult.tenant) {
      setTenant(tenantResult.tenant);
    }
  }, [tenantResult]);

  if (tenantResult.error) {
    console.error(tenantResult.error);
    return (
      <Typography variant="h5" align="center" color="error">
        {GLOBALS.MESSAGES.TENANT_NOT_FOUND} {tenantId}
      </Typography>
    );
  }

  if (loading || !accessToken || !tenant) {
    return (
      <Typography variant="h5" align="center" color="primary">
        Loading...
      </Typography>
    );
  }

  if (!status) {
    return null;
  }

  const tenantFullName = `${tenant.firstName} ${tenant.lastName}`;

  let reportPageContent = null;

  switch (status) {
    case "ACCEPT_TERMS":
      reportPageContent = (
        <AcceptTerms accessToken={accessToken} showError={showError} />
      );
      break;

    case "NEED_INFO":
      reportPageContent = (
        <NeedsInfo
          accessToken={accessToken}
          showError={showError}
          tenantFullName={tenantFullName}
        />
      );
      break;

    case "NEED_INIT":
      reportPageContent = (
        <NeedInit accessToken={accessToken} showError={showError} />
      );
      break;

    // TODO
    case "NEED_VERIFICATION":
      reportPageContent = (
        <NeedVerification
          accessToken={accessToken}
          showError={showError}
          tenantFullName={tenantFullName}
        />
      );
      break;

    // TODO
    case "VERIFICATION_COMPLETE":
      reportPageContent = (
        <VerificationComplete accessToken={accessToken} showError={showError} />
      );
      break;

    // For "REPORTS_READY", "REPORTS_IN_PROGRESS", "NEED_MANUAL_VERIFICATION", "VERIFICATION_IN_PROGRESS"
    default:
      reportPageContent = null;
  }

  return (
    <Container maxWidth="lg">
      <Typography variant="h5" align="center" color="primary">
        Reports: {tenantFullName}
      </Typography>
      <NestiqaAlertDialog {...alertDialogControls} />
      {reportPageContent}
    </Container>
  );
}
