import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useCallback,
  useMemo,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Hidden,
  List,
  ListItem,
  Link,
  Container,
  Toolbar,
  AppBar,
  Typography,
  Badge,
  useMediaQuery,
} from "@material-ui/core";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import { GLOBALS } from "../App";
import SearchBar from "./search-bar/SearchBar";
import useAuth0Nesitqa from "../hooks/useAuth0Nestiqa";
import PagesMenu from "./PagesMenu";
import nestiqaLogo from "./nestiqa_logo.png";
import UserMenu from "./UserMenu";
import ReportsNotificationDialog from "./dialogs/ReportsNotificationDialog";
import ApplicationsPendingReportsDialog from "./dialogs/ApplicationsPendingReportsDialog";
import { useSelector, useDispatch } from "react-redux";
import {
  callGetAvailableReports,
  setReportsNotified,
  setReportStatus,
  setToShareReports,
} from "../store/actions/reportsActions";
import { setSubmitApplicationsLater } from "../store/actions/tenantActions";
import { getSubmitApplicationsLater } from "../store/selectors/tenantSelectors";
import {
  setDistanceFrom,
  setDistanceFilters,
  setSearchFilters,
  makeFilters,
  saveLastAddress,
} from "../store/actions/listingsActions";
import {
  getAddressComponents,
  getSearchByDistanceProps,
  getAddressFilter,
  objectHasProperties,
} from "../nestiqaUtils";
import { markNotified } from "../utils/ReportsApi";
import { createTenant, getOpenApplicationsStatus } from "../utils/TenantApi";
import { useIdleTimer } from "react-idle-timer";
import useInterval from "../hooks/useInterval";
import { omit } from "lodash";
import clsx from "clsx";
import { getActiveUser } from "../store/selectors/userSelectors";
import { setUser as setReduxUser } from "../store/actions/userActions";
import NestiqaActionDialog from "./NestiqaActionDialog";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  appBar: {
    backgroundColor: "white",
    boxShadow: theme.shadows[0],
  },
  toolBar: {
    justifyContent: "space-between",
  },
  desktopNavList: {
    display: "flex",
    listStyleType: "none",
    margin: "0 -10px 0 0",
    padding: 0,
    color: "#000",
    fontFamily: '"Segoe UI", sans-serif',

    "& .MuiListItem-root.Mui-selected": {
      background: "none",
      color: theme.palette.secondary.main,
    },

    "& li": {
      float: "left",
      margin: "0 10px",
      textTransform: "uppercase",
      whiteSpace: "nowrap",
      paddingLeft: "8px",
      paddingRight: "8px",

      "& .MuiTypography-root": {
        fontSize: "14px",
        fontWeight: 500,

        "&:hover": {
          textDecoration: "none",
          cursor: "pointer",
          color: theme.palette.secondary.main,
        },
      },
    },
  },
  textInputs: {
    width: "438px",

    [theme.breakpoints.down("md")]: {
      width: "343px",
    },
  },
  leftDiv: {
    display: "flex",
    flexGrow: 1,
    marginLeft: "80px",

    "& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot":
      {
        paddingRight: 0,
        fontFamily: '"Segoe UI", sans-serif',

        "&:before": {
          content: '""',
          borderBottom: "none",
        },

        "& .MuiInputBase-input": {
          border: "1px solid #B2B2B2",
          borderRadius: "5px",
          width: "80%",
          padding: "6px 0 7px 6px",
          boxSizing: "content-box",
          marginRight: "-26px",
          position: "relative",
        },
      },

    "& .MuiInput-underline.Mui-focused:after": {
      transform: "scaleX(1)",
    },

    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      marginLeft: "30px",
      marginRight: "30px",
    },

    [theme.breakpoints.down("xs")]: {
      margin: "10px 0 0",
    },
  },
  logoWrapper: {
    [theme.breakpoints.down("xs")]: {
      width: "90px",
      flexGrow: 1,
    },
  },
  logo: {
    width: "100%",
    height: "100%",
  },
  badge: {
    marginRight: theme.spacing(1),

    "& .MuiBadge-badge": {
      borderRadius: "50%",
      transform: "scale(1) translate(90%, -70%)",
      transformOrigin: "100% 0",
      top: 0,
      right: 0,
    },
  },
  link: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexGrow: 1,
      width: "90px",
    },
  },
  grow: {
    flexGrow: 1,
  },
}));

const COUNT_TIMEOUT = 1000 * 2 * 60; // stop count calls on 2 minutes idle
const DEBOUNCE = 500;
const COUNT_INITIAL_FREQUENCY = 1000 * 30;
const COUNT_REDUCED_FREQUENCY = 1000 * 60 * 5;

export default function NestiqaTopBar() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const reduxFilters = useSelector(state => state.listings.filters);
  const activeUser = useSelector(getActiveUser);
  const submitApplicationsLater = useSelector(getSubmitApplicationsLater);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));
  const { isAuthenticated, accessToken } = useAuth0Nesitqa("");
  const history = useHistory();
  const location = useLocation();
  const [selected, setSelected] = useState(0);
  const [badgeCount, setBadgeCount] = useState(0);
  const [values, setValues] = useState("");
  const [isLandlordRoute, setIsLandlordRoute] = useState(false);
  const [stopCountQuery, setStopCountQuery] = useState(false);
  const [countQueryInterval, setCountQueryInterval] = useState(
    COUNT_INITIAL_FREQUENCY
  );
  const [reportDialogState, setReportDialogState] = useState({
    open: false,
  });
  const [applicationsPendingState, setApplicationsPendingState] = useState({
    open: false,
  });
  const [createTenantDialog, setCreateTenantDialog] = useState({
    open: false,
  });

  const callMarkNotified = useCallback(async () => {
    markNotified(accessToken, (isSuccess, result, error) => {
      if (isSuccess) {
        return true;
      }
      if (error) {
        console.log(error);
      }
    });
  }, [accessToken]);

  const reportsButtonMap = useMemo(
    () => [
      {
        buttonName: "View Reports",
        handleClick: async () => {
          await callMarkNotified();
          setReportDialogState({ open: false });

          if (activeUser.tenantId) {
            const route = GLOBALS.PATHS.TENANT_PROFILE.replace(
              ":tenantId",
              activeUser.tenantId
            );

            history.push(route, { caller: "reportsRedirect" });
          }
        },
      },
      {
        buttonName: "I'll View Later",
        handleClick: async () => {
          await callMarkNotified();
          setReportDialogState({ open: false });
        },
      },
    ],
    [callMarkNotified, history, activeUser]
  );

  const applicationsPendingButtonMap = useMemo(
    () => [
      {
        buttonName: "View Applications",
        handleClick: () => {
          setApplicationsPendingState({ open: false });
          if (activeUser.tenantId) {
            const route =
              GLOBALS.PATHS.APPLICATIONS_PENDING_REPORTS_PAGE.replace(
                ":tenantId",
                activeUser.tenantId
              );
            history.push(route);
          }
        },
      },
      {
        buttonName: "I'll Do It Later",
        handleClick: () => {
          dispatch(setSubmitApplicationsLater());
          setApplicationsPendingState({ open: false });
        },
      },
    ],
    [dispatch, history, setApplicationsPendingState, activeUser]
  );

  const showCreateTenantError = () => {
    setCreateTenantDialog({
      open: true,
      title: "Error",
      message: GLOBALS.MESSAGES.ERROR_COMPLETING_ACTION,
      buttonMap: [
        {
          buttonName: "Ok",
          handleClick: () => {
            setCreateTenantDialog({ open: false });
          },
        },
      ],
    });
  };

  const createTenantButtonMap = useMemo(
    () => [
      {
        buttonName: "Yes",
        handleClick: async () => {
          await createTenant(accessToken, (isSuccess, apiResult, apiError) => {
            if (isSuccess) {
              dispatch(setReduxUser({ tenantId: apiResult.tenantId }));
              history.push(
                GLOBALS.PATHS.CREATE_TENANT.replace(
                  ":tenantId",
                  apiResult.tenantId
                ),
                { from: "/redirect" }
              );
            } else {
              showCreateTenantError();
            }
          });
          setCreateTenantDialog({ open: false });
        },
      },
      {
        buttonName: "No",
        handleClick: () => {
          setCreateTenantDialog({ open: false });
        },
      },
    ],
    [accessToken, dispatch, history]
  );

  const openApplicationsCallback = useCallback(
    (isSuccess, result, error) => {
      if (isSuccess) {
        if (submitApplicationsLater) return;

        const { reportsNotified, reportsStatus, toShareReports } = result;

        const submitPendingApplications = Boolean(
          reportsStatus === "REPORTS_READY" &&
            toShareReports > 0 &&
            !location.pathname.includes("/profile") &&
            !location.pathname.includes("/applications/reports")
        );
        const shouldOpenDialog = Boolean(
          reportsNotified === "N" && reportsStatus === "REPORTS_READY"
        );

        dispatch(setReportsNotified(reportsNotified));
        dispatch(setToShareReports(toShareReports));
        dispatch(setReportStatus(reportsStatus));

        setCountQueryInterval(COUNT_INITIAL_FREQUENCY);
        setBadgeCount(result.total);

        if (submitPendingApplications && shouldOpenDialog && activeUser) {
          // handle opening both dialogs at the same time
          setReportDialogState({
            open: true,
            onClose: () => setReportDialogState({ open: false }),
            buttonMap: [
              {
                buttonName: "View Reports",
                handleClick: async () => {
                  await callMarkNotified();
                  setReportDialogState({ open: false });
                  if (activeUser.tenantId) {
                    const route = GLOBALS.PATHS.TENANT_PROFILE.replace(
                      ":tenantId",
                      activeUser.tenantId
                    );

                    history.push(route, { caller: "reportsRedirect" });
                  }
                },
              },
              {
                buttonName: "I'll View Later",
                handleClick: async () => {
                  await callMarkNotified();
                  setReportDialogState({ open: false });

                  setApplicationsPendingState({
                    open: true,
                    onClose: () => setApplicationsPendingState({ open: false }),
                    buttonMap: applicationsPendingButtonMap,
                    toShareReports,
                  });
                },
              },
            ],
          });
        } else {
          if (shouldOpenDialog && activeUser) {
            dispatch(callGetAvailableReports(accessToken));
            setReportDialogState({
              open: true,
              onClose: () => setReportDialogState({ open: false }),
              buttonMap: reportsButtonMap,
            });
          }
          if (submitPendingApplications && activeUser) {
            setApplicationsPendingState({
              open: true,
              onClose: () => setApplicationsPendingState({ open: false }),
              buttonMap: applicationsPendingButtonMap,
              toShareReports,
            });
          }
        }
      }
      if (error) {
        console.log(error);
        setCountQueryInterval(COUNT_REDUCED_FREQUENCY);
      }
    },
    [
      reportsButtonMap,
      activeUser,
      accessToken,
      dispatch,
      location.pathname,
      applicationsPendingButtonMap,
      callMarkNotified,
      history,
      submitApplicationsLater,
    ]
  );

  useEffect(() => {
    if (
      isAuthenticated &&
      accessToken &&
      activeUser !== null &&
      activeUser.tenantId
    ) {
      if (activeUser.tenantId) {
        getOpenApplicationsStatus(
          activeUser.tenantId,
          accessToken,
          openApplicationsCallback
        );
      }
    }
  }, [accessToken, activeUser, isAuthenticated]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (location.pathname.includes("/landlord")) {
      setIsLandlordRoute(true);
      setStopCountQuery(true);
    } else {
      setIsLandlordRoute(false);
    }
  }, [location.pathname]);

  useLayoutEffect(() => {
    const changeNavItemColor = loc => {
      const findActiveNav = loc => {
        if (activeUser) {
          switch (loc) {
            case GLOBALS.PATHS.ROOT:
              return 0;
            case GLOBALS.PATHS.LISTINGS:
              return 1;
            case GLOBALS.PATHS.TENANT_PROFILE.replace(
              ":tenantId",
              activeUser.tenantId
            ):
              return 2;
            case GLOBALS.PATHS.TENANT_APPLICATIONS.replace(
              ":tenantId",
              activeUser.tenantId
            ):
              return 3;
            case GLOBALS.PATHS.LANDLORD:
              return 4;
            default:
              return null;
          }
        }
      };

      if (location.pathname) {
        const activeNav = findActiveNav(loc);
        setSelected(activeNav);
      }
    };
    changeNavItemColor(location.pathname);
  }, [activeUser, location.pathname]);

  const getApplicationsCount = () => {
    if (isAuthenticated && activeUser.tenantId) {
      getOpenApplicationsStatus(
        activeUser.tenantId,
        accessToken,
        openApplicationsCallback
      );
    }
  };

  const handleOnIdle = event => {
    setStopCountQuery(true);
  };

  const handleOnActive = event => {
    setStopCountQuery(false);
  };

  // Reports status query check
  useIdleTimer({
    timeout: COUNT_TIMEOUT,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    debounce: DEBOUNCE,
  });
  useInterval(getApplicationsCount, countQueryInterval, stopCountQuery);

  const [searchByListing, setSearchByListing] = useState(false);

  const handleSearchChange = (newPlace, newPlaceSize) => {
    if (searchByListing) return;
    if (!newPlace || !newPlace.geometry) return;

    const searchByDistanceProps = getSearchByDistanceProps(
      newPlace,
      newPlaceSize
    );
    dispatch(setDistanceFilters(searchByDistanceProps));

    const from = values.from;
    dispatch(setDistanceFrom(from));
    setValues("");

    const comps = newPlace.address_components;
    const { address } = getAddressComponents(comps);
    dispatch(saveLastAddress(address));

    let adjustedFilters = reduxFilters;

    adjustedFilters = omit(reduxFilters, [
      "frlg",
      "frlt",
      "dst",
      "zc",
      "ct",
      "st",
    ]);
    const configAddress = { ...adjustedFilters, ...address };
    adjustedFilters = getAddressFilter(configAddress);

    if (objectHasProperties(adjustedFilters, ["zc", "ct", "st"])) {
      dispatch(
        makeFilters({
          ...adjustedFilters,
        })
      );
    } else {
      dispatch(
        makeFilters({
          ...adjustedFilters,
          frlg: searchByDistanceProps.frlg,
          frlt: searchByDistanceProps.frlt,
        })
      );
    }

    dispatch(setSearchFilters({ ...adjustedFilters }));

    if (history.location.pathname !== GLOBALS.PATHS.LISTINGS) {
      history.push(GLOBALS.PATHS.LISTINGS);
    }
  };

  function handleMenuRedirect(tenantPath) {
    if (activeUser.tenantId) {
      history.push(`/tenant/${activeUser.tenantId}/${tenantPath}`);
    } else {
      setCreateTenantDialog({
        open: true,
        onClose: () => setCreateTenantDialog({ open: false }),
        buttonMap: createTenantButtonMap,
        title: GLOBALS.MESSAGES.CREATE_TENANT_TITLE,
        message: GLOBALS.MESSAGES.CREATE_TENANT_MESSAGE,
      });
    }
  }

  const updateSelected = selectedIndex => {
    setSelected(selectedIndex);
  };

  return (
    <div className={classes.root}>
      <ReportsNotificationDialog {...reportDialogState} />
      <ApplicationsPendingReportsDialog {...applicationsPendingState} />
      <NestiqaActionDialog {...createTenantDialog} />
      <Container>
        <AppBar position="static" className={classes.appBar}>
          <Toolbar className={classes.toolBar} disableGutters>
            <div className={classes.logoWrapper}>
              <Link
                to={
                  isLandlordRoute ? GLOBALS.PATHS.LANDLORD : GLOBALS.PATHS.ROOT
                }
                component={RouterLink}
                className={clsx(classes.link, isLandlordRoute && classes.grow)}
                underline="none"
              >
                <img
                  src={nestiqaLogo}
                  className={isMobile ? classes.logo : ""}
                  alt="Nestiqa Logo"
                />
              </Link>
            </div>

            {!isLandlordRoute ? (
              <Hidden xsDown>
                <div className={classes.leftDiv}>
                  <SearchBar
                    className={classes.textInputs}
                    size="small"
                    placeholder={GLOBALS.MESSAGES.SEARCH_PLACEHOLDER}
                    popupIcon={null}
                    hasIcon={true}
                    searchByListing={searchByListing}
                    setSearchByListing={setSearchByListing}
                    handlePlaceChange={handleSearchChange}
                    onInputChange={newInputValue =>
                      setValues({ ...values, from: newInputValue })
                    }
                    initialValue={values.from}
                  />
                </div>
              </Hidden>
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexGrow: 1,
                }}
              />
            )}

            <PagesMenu isLandlordRoute={isLandlordRoute} />
            <Hidden mdDown>
              <List className={classes.desktopNavList}>
                {isLandlordRoute ? (
                  <>
                    {isAuthenticated ? (
                      <>
                        <ListItem
                          onClick={() => {
                            updateSelected(4);
                            history.push(GLOBALS.PATHS.LANDLORD);
                          }}
                          selected={selected === 4}
                        >
                          <Typography variant="subtitle2">
                            My Listings
                          </Typography>
                        </ListItem>
                        <ListItem
                          onClick={() => {
                            updateSelected(5);
                            history.push(GLOBALS.PATHS.LANDLORD_ALL_APPS_PAGE);
                          }}
                          selected={selected === 5}
                        >
                          <Typography variant="subtitle2">
                            Open Applications
                          </Typography>
                        </ListItem>
                      </>
                    ) : null}
                  </>
                ) : (
                  <>
                    <ListItem
                      onClick={() => {
                        updateSelected(0);
                        history.push(GLOBALS.PATHS.ROOT);
                      }}
                      selected={selected === 0}
                    >
                      <Typography variant="subtitle2">Home</Typography>
                    </ListItem>
                    <ListItem
                      onClick={() => {
                        updateSelected(1);
                        history.push(GLOBALS.PATHS.LISTINGS);
                      }}
                      selected={selected === 1}
                    >
                      <Typography variant="subtitle2">Listings</Typography>
                    </ListItem>
                    {isAuthenticated ? (
                      <>
                        <ListItem
                          onClick={() => {
                            updateSelected(2);
                            handleMenuRedirect("profile");
                          }}
                          selected={selected === 2}
                        >
                          <Typography variant="subtitle2">
                            My Profile
                          </Typography>
                        </ListItem>
                        <ListItem
                          onClick={() => {
                            updateSelected(3);
                            handleMenuRedirect("applications");
                          }}
                          selected={selected === 3}
                        >
                          <Badge
                            invisible={badgeCount === 0}
                            className={badgeCount > 0 ? classes.badge : null}
                            badgeContent={badgeCount}
                            color="error"
                          >
                            <Typography variant="subtitle2">
                              My Applications
                            </Typography>
                          </Badge>
                        </ListItem>
                      </>
                    ) : null}
                  </>
                )}
              </List>
            </Hidden>

            <UserMenu
              isLandlordRoute={isLandlordRoute}
              handleMenuRedirect={handleMenuRedirect}
            />
          </Toolbar>
        </AppBar>
        {!isLandlordRoute && isMobile && (
          <div className={classes.leftDiv}>
            <SearchBar
              className={classes.textInputs}
              size="small"
              placeholder={GLOBALS.MESSAGES.SEARCH_PLACEHOLDER}
              popupIcon={null}
              hasIcon={true}
              setSearchByListing={setSearchByListing}
              searchByListing={searchByListing}
              handlePlaceChange={handleSearchChange}
              onInputChange={newInputValue =>
                setValues({ ...values, from: newInputValue })
              }
              initialValue={values.from}
            />
          </div>
        )}
      </Container>
    </div>
  );
}
