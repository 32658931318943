import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormLabel, Grid } from "@material-ui/core";
import { Field } from "formik";
import { CheckboxWithLabel, TextField } from "formik-material-ui";
import PhoneNumberField from "../PhoneNumberField";
import { KeyboardDatePicker } from "formik-material-ui-pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { GLOBALS } from "../../App";
import PriceField from "../PriceField";
import ChoiceField from "../ChoiceField";
import ListingFormControls from "./ListingFormControls";
import { updateListing } from "../../hooks/ListingApi";
import * as yup from "yup";

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.dark,
  },
  textGrids: {
    flexGrow: 1,
  },
  textInputs: {
    backgroundColor: "white",
    width: "100%",
  },
  description: {
    flexGrow: 1,
    display: "grid",
  },
  formLabel: {
    fontWeight: 500,
    paddingBottom: theme.spacing(1),
    fontSize: "1.3em",
  },
  checkboxInputs: {},
}));

export default function ListingInfo(props) {
  const { accessToken, listing, setListing, step, setStep, showError } = props;
  const MSG = GLOBALS.MESSAGES;

  function onStepSubmit(values, formikBag) {
    updateListing(
      listing.listingNumber,
      {
        ...values,
      },
      accessToken,
      (isSuccess, apiResult, apiError) => {
        if (isSuccess) {
          setListing({
            ...apiResult,
          });
          setStep(step + 1);
        } else {
          console.error(apiError);
          showError(MSG.ERROR_COMPLETING_ACTION);
          formikBag.setSubmitting(false);
        }
      }
    );
  }

  function handleNext(formikprops) {
    if (formikprops.dirty) {
      formikprops.submitForm();
    } else {
      setStep(step + 1);
    }
  }

  const validationSchema = yup.object().shape({
    description: yup
      .string()
      .required(MSG.FIELD_REQUIRED)
      .matches(GLOBALS.NOT_ONLY_WHITESPACE_REGEX, MSG.NOT_ONLY_WHITESPACE),
    bedrooms: yup.string().required(MSG.FIELD_REQUIRED),
    bathrooms: yup.string().required(MSG.FIELD_REQUIRED),
    housingType: yup.string().required(MSG.FIELD_REQUIRED),
    leaseType: yup.string().required(MSG.FIELD_REQUIRED),
    rent: yup.string().required(MSG.FIELD_REQUIRED),
    catsAllowed: yup.boolean(),
    smallDogsAllowed: yup.boolean(),
    largeDogsAllowed: yup.boolean(),
    petsComment: yup
      .string()
      .max(40, MSG.FIELD_MAX_LENGTH + 40)
      .matches(GLOBALS.NOT_ONLY_WHITESPACE_REGEX, MSG.NOT_ONLY_WHITESPACE)
      .when("pets", {
        is: "OTHER",
        then: yup.string().required(MSG.FIELD_REQUIRED),
      }),
    sqft: yup
      .number()
      .notRequired()
      .integer(MSG.ENTER_VALID_SQFT)
      .min(0, MSG.ENTER_VALID_SQFT),
    contactName: yup
      .string()
      .required(MSG.FIELD_REQUIRED)
      .matches(GLOBALS.NOT_ONLY_WHITESPACE_REGEX, MSG.NOT_ONLY_WHITESPACE),
    contactPhone: yup
      .string()
      .matches(GLOBALS.PHONE_NUMBER_REGEX, GLOBALS.MESSAGES.ENTER_VALID_PHONE)
      .required(),
    availabilityDate: yup.date().typeError(MSG.ENTER_VALID_DATE),
  });

  return (
    <ListingFormControls
      listing={listing}
      step={step}
      setStep={setStep}
      onSubmit={onStepSubmit}
      validationSchema={validationSchema}
      handleNext={handleNext}
    >
      <ListingInfoFormikWrapper />
    </ListingFormControls>
  );
}

const ListingInfoFormikWrapper = ({ formikprops }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      alignItems="flex-start"
      className={classes.root}
      spacing={2}
    >
      <Grid
        item
        container
        xs={12}
        sm={12}
        md={6}
        direction="column"
        className={classes.description}
      >
        <Grid item container spacing={2}>
          <Grid item xs={12}>
            <Field
              component={TextField}
              required
              name="description"
              type="text"
              label="Description"
              variant="outlined"
              multiline
              rows={10}
              className={classes.textInputs}
            />
          </Grid>
          <Grid item className={classes.textGrids} xs={12} md={6}>
            <Field
              component={TextField}
              required
              name="contactName"
              type="text"
              label="Contact Name"
              variant="outlined"
              className={classes.textInputs}
            />
          </Grid>
          <Grid item className={classes.textGrids} xs={12} md={6}>
            <Field
              component={PhoneNumberField}
              required
              name="contactPhone"
              type="text"
              label="Contact Phone"
              variant="outlined"
              className={classes.textInputs}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Field
                component={KeyboardDatePicker}
                required
                name="availabilityDate"
                label="Availability Date"
                format="MM/dd/yyyy"
                inputVariant="outlined"
                variant="inline"
                style={{ width: "100%" }}
                autoOk
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={6} container direction="column">
        <Grid item container spacing={2}>
          <Grid item className={classes.textGrids} xs={12} sm={6} md={4}>
            <Field
              component={PriceField}
              required
              name="rent"
              type="text"
              label="Rent ($/mo)"
              variant="outlined"
              className={classes.textInputs}
            />
          </Grid>
          <Grid item className={classes.textGrids} xs={12} sm={6} md={4}>
            <Field
              component={ChoiceField}
              required
              name="leaseType"
              variant="outlined"
              label="Lease Type"
              className={classes.textInputs}
              choices={GLOBALS.LEASE_TYPES}
            />
          </Grid>
          <Grid item className={classes.textGrids} xs={12} sm={6} md={4}>
            <Field
              component={ChoiceField}
              required
              name="housingType"
              variant="outlined"
              label="Housing Type"
              className={classes.textInputs}
              choices={GLOBALS.HOUSING_TYPES}
            />
          </Grid>
          <Grid item className={classes.textGrids} xs={12} sm={6} md={4}>
            <Field
              component={ChoiceField}
              required
              name="bedrooms"
              variant="outlined"
              label="Bedrooms"
              className={classes.textInputs}
              choices={GLOBALS.BEDROOMS}
            />
          </Grid>
          <Grid item className={classes.textGrids} xs={12} sm={6} md={4}>
            <Field
              component={ChoiceField}
              required
              name="bathrooms"
              variant="outlined"
              label="Bathrooms"
              className={classes.textInputs}
              choices={GLOBALS.BATHROOMS}
            />
          </Grid>
          <Grid item className={classes.textGrids} xs={12} sm={6} md={4}>
            <Field
              component={TextField}
              name="sqft"
              type="text"
              label="Sqft"
              variant="outlined"
              className={classes.textInputs}
            />
          </Grid>
          <Grid item className={classes.textGrids} xs={12}>
            <FormLabel component="legend" className={classes.formLabel}>
              Pets
            </FormLabel>
            <Field
              component={CheckboxWithLabel}
              name="catsAllowed"
              type="checkbox"
              className={classes.checkboxInputs}
              Label={{ label: "Cats Allowed" }}
            />
            <br />
            <Field
              component={CheckboxWithLabel}
              name="smallDogsAllowed"
              type="checkbox"
              className={classes.checkboxInputs}
              Label={{ label: "Small Dogs Allowed" }}
            />
            <br />
            <Field
              component={CheckboxWithLabel}
              name="largeDogsAllowed"
              type="checkbox"
              className={classes.checkboxInputs}
              Label={{ label: "Large Dogs Allowed" }}
            />
          </Grid>
          <Grid item className={classes.textGrids} xs={12}>
            <Field
              component={TextField}
              required={formikprops.values.pets === "OTHER"}
              name="petsComment"
              type="text"
              label="Pets Comment"
              placeholder="Pet rent, breed restrictions, etc."
              variant="outlined"
              className={classes.textInputs}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
