import { Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import { GLOBALS } from "../../App";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import ImageUploader from "react-images-upload";
import { ReactSortable } from "react-sortablejs";
import ClearIcon from "@material-ui/icons/Clear";
import EditImageDialog from "../EditImageDialog";
import ListingFormControls from "./ListingFormControls";
import useListing from "../../hooks/useListing";
import { updateListing } from "../../hooks/ListingApi";
import * as yup from "yup";

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.dark,
  },
  subGrid: {
    marginTop: theme.spacing(2),
  },
  imagesButton: {
    backgroundColor: theme.palette.primary.main,
    textTransform: "uppercase",
    minWidth: "64px",
    padding: "6px 16px !important",
    borderRadius: "4px !important",
    boxShadow: `${theme.shadows[1]} !important`,
  },
  imageUploader: {
    marginTop: theme.spacing(2),

    "& .fileContainer": {
      boxShadow: theme.shadows[2],
      borderRadius: "4px",
    },
  },
  previewContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  previewItem: {
    margin: theme.spacing(1),
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    borderRadius: "10px",
    height: "120px",
    width: "160px",
    textAlign: "center",
    justifyContent: "center",
    display: "flex",
    overflow: "hidden",
    position: "relative",
  },
  preview: {
    maxHeight: "100%",
    maxWidth: "100%",
    objectFit: "contain",
  },
  number: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    position: "absolute",
    top: 0,
    left: 0,
    borderRadius: "0 0 10px 0",
    width: "30px",
  },
  primaryLabelContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  primaryLabel: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    borderRadius: "0 0 10px 10px",
    width: "70px",
  },
  remove: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    position: "absolute",
    top: 0,
    left: 130,
    borderRadius: "0 0 0 10px",
    width: "30px",
    padding: 0,
  },
  textInputs: {
    backgroundColor: "white",
    width: "100%",
  },
}));

export default function ListingPhotos(props) {
  const { accessToken, listing, setListing, step, setStep, showError } = props;
  const [updatedImages, setUpdatedImages] = useState(listing.images);
  const [controls, setControls] = useState({
    listingParams: null,
  });
  const listingResult = useListing(controls.listingParams);
  const MSG = GLOBALS.MESSAGES;

  function onStepSubmit(values, formikBag) {
    updateListing(
      listing.listingNumber,
      { virtualTourUrl: values.virtualTourUrl },
      accessToken,
      (isSuccess, apiResult, apiError) => {
        if (isSuccess) {
          setListing(apiResult);
          setControls({
            formikBag: { ...formikBag },
            listingParams: {
              listing: apiResult,
              action: "update-listing-images",
              updatedImages: updatedImages,
            },
          });
        } else {
          console.error(apiError);
          showError(MSG.ERROR_COMPLETING_ACTION);
          formikBag.setSubmitting(false);
        }
      }
    );
  }

  useEffect(() => {
    if (!controls.listingParams || listingResult.status !== "COMPLETE") return;

    if (listingResult.errors) {
      console.error(listingResult.errors);
      showError(GLOBALS.MESSAGES.ERROR_COMPLETING_ACTION);
      controls.formikBag.setSubmitting(false);
      listingResult.clear();
      return;
    }

    setListing(listingResult.listing);
    setUpdatedImages(listingResult.listing.images);
    listingResult.clear();
    setStep(step + 1);
    controls.formikBag.setSubmitting(false);
    // eslint-disable-next-line
  }, [controls, listingResult, step]);

  function handleNext(formikprops) {
    formikprops.submitForm();
  }

  const validationSchema = yup.object().shape({
    virtualTourUrl: yup
      .string()
      .matches(GLOBALS.VALID_URL_REGEX, GLOBALS.MESSAGES.INVALID_URL_MESSAGE),
  });

  return (
    <ListingFormControls
      listing={listing}
      step={step}
      setStep={setStep}
      onSubmit={onStepSubmit}
      validationSchema={validationSchema}
      handleNext={handleNext}
    >
      <ListingPhotosFormikWrapper
        updatedImages={updatedImages}
        setUpdatedImages={setUpdatedImages}
        listing={listing}
      />
    </ListingFormControls>
  );
}

function ListingPhotosFormikWrapper(props) {
  const classes = useStyles();
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const { updatedImages, setUpdatedImages } = props;

  const onDrop = selected => {
    const files = selected;
    const allFilePromises = [];

    if (props.formikprops.isSubmitting) return;

    // Iterate over all uploaded files
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      if (
        updatedImages.filter(item => item.imageFile === files[i]).length === 0
      ) {
        allFilePromises.push(readFile(file));
      }
    }

    Promise.all(allFilePromises).then(newFilesData => {
      const tarImageFiles = [];

      newFilesData.forEach((newFileData, index) => {
        tarImageFiles.push({
          id: index,
          chosen: false,
          selected: false,
          localFile: true,
          imageFile: newFileData.file,
          imageUrl: newFileData.dataURL,
        });
      });

      setUpdatedImages([
        ...updatedImages.filter(item => !item.toRemove),
        ...tarImageFiles,
        ...updatedImages.filter(item => item.toRemove),
      ]);
    });
  };

  const readFile = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      // Read the image via FileReader API and save image result in state.
      reader.onload = function (e) {
        // Add the file name to the data URL
        let dataURL = e.target.result;
        dataURL = dataURL.replace(";base64", `;name=${file.name};base64`);
        resolve({ file, dataURL });
      };

      reader.readAsDataURL(file);
    });
  };

  const handleClickOpen = () => {
    // setEditDialogOpen(true);
  };

  const handleClose = value => {
    setEditDialogOpen(false);
  };

  const handleRemove = i => {
    // Mark for removal and move to the end of the array
    const tarImageFiles = updatedImages.slice();
    tarImageFiles[i].toRemove = true;
    tarImageFiles.push(tarImageFiles.splice(i, 1)[0]);
    setUpdatedImages(tarImageFiles);
  };

  return (
    <Grid container className={classes.root}>
      <Typography variant="body1" color="textPrimary">
        {GLOBALS.SITE_TEXTS.IMAGES_INSTRUCTIONS}
      </Typography>
      <ImageUploader
        className={classes.imageUploader}
        withIcon={false}
        buttonStyles={{ background: "#9340FF" }}
        buttonText="Add Images"
        label={GLOBALS.LABELS.IMAGES_UPLOAD}
        buttonClassName={classes.imagesButton}
        onChange={onDrop}
        imgExtension={[
          ".jpg",
          ".gif",
          ".png",
          ".webp",
          "heic",
          ".png",
          ".jpeg",
          ".tif",
          ".tiff",
          ".bmp",
          ".eps",
        ]}
        maxFileSize={10485760}
      />
      <ReactSortable
        className={classes.previewContainer}
        list={updatedImages}
        setList={setUpdatedImages}
        direction="horizontal"
        disabled={props.formikprops.isSubmitting}
      >
        {updatedImages
          .filter(item => !item.toRemove)
          .map((item, index) => (
            <div key={index} className={classes.previewItem}>
              <img
                className={classes.preview}
                src={item.localFile ? item.imageUrl : item.standard}
                alt={
                  item.localFile
                    ? item.imageFile.name
                    : `Listing Photo #${item.number}`
                }
                onClick={handleClickOpen}
              />
              <div className={classes.number}>
                <Typography color="inherit" variant="body1">
                  {index + 1}
                </Typography>
              </div>
              <div className={classes.primaryLabelContainer}>
                <div className={classes.primaryLabel} hidden={index !== 0}>
                  <Typography color="inherit" variant="body1">
                    Primary
                  </Typography>
                </div>
                <div
                  className={classes.remove}
                  onClick={() => handleRemove(index)}
                  title="Remove"
                >
                  <Typography color="inherit" variant="body1">
                    <ClearIcon fontSize="inherit" />
                  </Typography>
                </div>
              </div>
            </div>
          ))}
      </ReactSortable>
      <EditImageDialog open={editDialogOpen} onClose={handleClose} />
      <Grid
        container
        alignItems="stretch"
        className={classes.subGrid}
        spacing={2}
      >
        <Grid item xs={12}>
          <Typography variant="body1" color="textPrimary" gutterBottom>
            {GLOBALS.MESSAGES.VIRTUAL_TOUR_MSG}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            component={TextField}
            name="virtualTourUrl"
            variant="outlined"
            label="Virtual Tour URL"
            className={classes.textInputs}
            choices={GLOBALS.PARKING}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
