import React from "react";
import { Button, Typography } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { GLOBALS } from "../App";

const useStyles = makeStyles(theme =>
  createStyles({
    headerCta: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      marginBottom: theme.spacing(4),

      [theme.breakpoints.down("xs")]: {
        flexFlow: "column wrap",
        alignItems: "flex-start",
        justifyContent: "center",

        "& .MuiButtonBase-root": {
          marginTop: theme.spacing(2),
        },
      },
    },
    submitButton: {
      whiteSpace: "nowrap",
      textTransform: "capitalize",
      fontWeight: 400,
      paddingLeft: "24px",
      paddingRight: "24px",
    },
  })
);

const ApplicationsPendingHeader = ({ handleSubmitAll, requesting }) => {
  const classes = useStyles();

  return (
    <div className={classes.headerCta}>
      <div className={classes.textWrapper}>
        <Typography variant="body1">
          {GLOBALS.MESSAGES.INFO_RETRIEVING_APPLICATIONS}
        </Typography>
      </div>
      <Button
        color="primary"
        variant="contained"
        className={classes.submitButton}
        onClick={handleSubmitAll}
        disabled={requesting}
      >
        Submit All
      </Button>
    </div>
  );
};

export default ApplicationsPendingHeader;
