import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Box,
  AppBar,
  Hidden,
  Typography,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import TabPanel, { a11yProps } from "../components/TabPanel";
import RoundTabs from "../components/RoundTabs";
import RoundTab from "../components/RoundTab";
import FiltersBox from "../components/FiltersBox";
import ListingCard from "../components/ListingCard";
import useListings from "../hooks/useListings";
import FilterListIcon from "@material-ui/icons/FilterList";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import FiltersDrawer from "../components/FiltersDrawer";
import NestiqaMap from "../components/google-maps/NestiqaMap";
import { Helmet } from "react-helmet";
import { GLOBALS } from "../App";
import { useSelector, useDispatch } from "react-redux";
import { getSearchFilters } from "../store/selectors/listingsSelectors";
import { useLocationFilters } from "../hooks/useLocationFilters";
import {
  setDefaultDistance,
  setSearchFilters,
  makeFilters,
  triggerSearch,
} from "../store/actions/listingsActions";
import { objectHasProperties, formatNumber } from "../nestiqaUtils";
import { useLocation } from "react-router-dom";
import { logEvent } from "../utils/initGa4React";
import { useGA4React } from "ga-4-react";
import { options } from "../components/RoundTabs";
import SaveSearchDialog from "../components/SaveSearchDialog";
import useAuth0Nesitqa from "../hooks/useAuth0Nestiqa";
import NestiqaAlertDialog from "../components/NestiqaAlertDialog";
import LoginDialog from "../components/LoginDialog";
import { useCookies } from "react-cookie";
import InstructionsTooltip from "../components/InstructionsTooltip";
// import useSessionStorage from "../hooks/useSessionStorage";

const useStyles = makeStyles(theme => ({
  mainGrid: {
    flexDirection: "row",
    flexWrap: "nowrap",
  },
  actionLink: {
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  cardGrid: {
    padding: theme.spacing(0),
    minHeight: "768px",
    position: "relative",
  },
  tabsBar: {
    backgroundColor: "white",
    boxShadow: theme.shadows[0],
    color: theme.palette.primary.dark,
  },
  listingsBox: {
    flexGrow: 1,
  },
  collapsedFilters: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "10px",
    padding: theme.spacing(1),
  },
  filtersIcon: {
    color: "white",
  },
  map: {
    color: "white",
    height: "calc(100vh - 200px)",
  },
  resultsText: {
    margin: "20px auto 0",
    fontSize: "1rem",
  },
  saveText: {
    fontSize: "1rem",
    display: "inline-block",
    cursor: "pointer",
  },
  drawersWrapper: {
    display: "flex",

    "& div:nth-of-type(2)": {
      margin: "0 3px",
    },
  },
  paginationRoot: {
    width: "100%",
    position: "absolute",
    bottom: "-40px",

    "& nav": {
      display: "flex",
      justifyContent: "center",
    },

    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export const PageSize = 30;

export default function ListingsPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const locFilters = useLocationFilters();
  const reduxFilters = useSelector(state => state.listings);
  const searchFilters = useSelector(getSearchFilters);
  const defaultDistance = useSelector(state => state.listings.defaultDistance);
  const searchTriggered = useSelector(state => state.listings.searchTriggered);
  const [curTab, setCurTab] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  // const [firstRender, setFirstRender] = useSessionStorage(
  //   "ns_first_render",
  //   true
  // );

  const [filters, setFilters] = useState({
    bd_min: "0",
    bd_max: "6",
    ...reduxFilters?.filters,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    listings,
    loading,
    pagination: { totalCount: numberOfListings, currentPage },
    handlePaginationChange,
  } = useListings(filters);
  const ga = useGA4React();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));

  // Open drawer on first render on mobile
  // useEffect(() => {
  //   if (firstRender && isMobile) {
  //     setDrawerOpen(true);
  //     setFirstRender(false);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [firstRender, isMobile]);

  const [messageDialogControls, setMessageDialogControls] = useState({
    open: false,
  });

  function showMessage(
    title = "Error",
    message = GLOBALS.MESSAGES.ERROR_COMPLETING_ACTION
  ) {
    setMessageDialogControls({
      open: true,
      title,
      message,
      onClose: () => setMessageDialogControls({ open: false }),
    });
  }

  const [saveDialogControls, setSaveDialogControls] = useState({
    open: false,
  });

  const { isAuthenticated, accessToken } = useAuth0Nesitqa();

  const [loginDialog, setLoginDialog] = useState({
    open: false,
  });

  const closeLoginDialog = () => {
    setLoginDialog({
      open: false,
    });
  };

  const openLoginDialog = () => {
    setLoginDialog({
      open: true,
      preLogin: () => {
        localStorage.setItem(
          "previousLocation",
          window.location.pathname + window.location.search
        );
      },
      action: "save search",
      onClose: closeLoginDialog,
    });
  };

  const openSaveSearchDialog = () => {
    if (isAuthenticated) {
      setSaveDialogControls({ open: true });
    } else {
      openLoginDialog();
    }
  };

  function handleClick() {
    if (!!ga) {
      logEvent("tenant_receive_updates", {});
    }
    openSaveSearchDialog();
  }

  useEffect(() => {
    if (locFilters) {
      const setUserDistanceFilters = async () => {
        await dispatch(setDefaultDistance(locFilters));
      };
      setUserDistanceFilters();
    }

    return () => {
      setFilters({
        bd_min: "0",
        bd_max: "6",
        ...reduxFilters?.filters,
      });
    };
  }, [locFilters, dispatch]); //eslint-disable-line

  // useEffect(() => {
  //   return () => {
  //     setFilters({
  //       bd_min: "0",
  //       bd_max: "6",
  //       ...reduxFilters?.filters,
  //     });
  //     dispatch(clearFilters());
  //   };
  // }, [dispatch]); //eslint-disable-line

  const location = useLocation();

  useEffect(() => {
    if (location && location.state && location.state.fromRedirect) {
      dispatch(triggerSearch());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (objectHasProperties(reduxFilters.searchFilters, ["zc", "ct", "st"]))
      return;

    if (location.search) {
      const setLandingFilter = () => {
        const params = new URLSearchParams(location.search);

        let searchFilters = {};
        params.forEach((value, key) => {
          searchFilters[key] = value;
        });

        dispatch(setSearchFilters(searchFilters));
        dispatch(makeFilters(searchFilters));
      };
      setLandingFilter();
    } else if (defaultDistance) {
      const setLandingFilter = () => {
        dispatch(setSearchFilters({ ...filters, ...defaultDistance }));
      };
      setLandingFilter();
    } else {
      const defaultAppLoc = {
        dst: GLOBALS.DEFAULT_DISTANCE,
        frlg: GLOBALS.DEFAULT_LOC.lng,
        frlt: GLOBALS.DEFAULT_LOC.lat,
      };
      const setLandingFilter = () => {
        dispatch(setSearchFilters({ ...filters, ...defaultAppLoc }));
      };
      setLandingFilter();
    }

    return () => {
      setFilters({
        bd_min: "0",
        bd_max: "6",
        ...reduxFilters?.filters,
      });
    };
  }, [defaultDistance, dispatch]); //eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (event, newTab) => {
    setCurTab(newTab);
  };

  const setFiltersWrapper = filters => {
    setFilters(filters);
  };

  const showCards = listings => {
    if (loading) {
      return (
        <Typography variant="h5" align="center" color="primary">
          Loading...
        </Typography>
      );
    }
    if (listings && listings.length) {
      return listings.map(item => (
        <ListingCard
          listing={item}
          key={item.listing_number}
          xs={12}
          sm={6}
          md={4}
        />
      ));
    } else {
      return (
        <Typography>
          Everyday we add more homes to our site.{" "}
          <span className={classes.actionLink} onClick={handleClick}>
            Click here
          </span>{" "}
          to receive email updates on new homes that meet your criteria.
        </Typography>
      );
    }
  };

  function handleFiltersClick() {
    setDrawerOpen(true);
  }

  const handleMobileSortingClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = option => {
    dispatch(
      setSearchFilters({
        ...searchFilters,
        ordering: option.value,
      })
    );
    handleClose();
  };

  const [cookies, setCookie] = useCookies([
    "ns_filter_acknowledged",
    "ns_sort_acknowledged",
  ]);

  const setAcknowledged = cookie => {
    setCookie(cookie, "true", {
      expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 30),
    });
  };

  return (
    <div>
      <Helmet>
        <title>{GLOBALS.META_TAGS.TITLE.LISTINGS_PAGE}</title>
        <meta
          name="description"
          content={GLOBALS.META_TAGS.DESCRIPTION.LISTINGS_PAGE}
        />
      </Helmet>
      <FiltersDrawer
        initFilters={filters}
        anchor="left"
        open={drawerOpen}
        setDrawerOpen={setDrawerOpen}
        handleSearch={setFiltersWrapper}
      />
      <NestiqaAlertDialog {...messageDialogControls} />
      <LoginDialog {...loginDialog} />
      <SaveSearchDialog
        {...saveDialogControls}
        onClose={() => setSaveDialogControls({ open: false })}
        searchParams={searchFilters}
        showMessage={showMessage}
        accessToken={accessToken}
      />
      <Container maxWidth="lg">
        <Grid container className={classes.mainGrid}>
          <Grid item>
            <Hidden smDown>
              <FiltersBox
                initFilters={filters}
                handleSearch={setFiltersWrapper}
              />
            </Hidden>
          </Grid>
          <Grid item className={classes.listingsBox}>
            <AppBar position="static" className={classes.tabsBar}>
              <Grid container>
                <Grid item>
                  <Hidden mdUp>
                    <div className={classes.drawersWrapper}>
                      <InstructionsTooltip
                        text="Click here to adjust the search filters"
                        open={
                          cookies.ns_filter_acknowledged !== "true" &&
                          !drawerOpen
                        }
                        onAction={() => {
                          if (cookies.ns_filter_acknowledged !== "true") {
                            setAcknowledged("ns_filter_acknowledged");
                          }
                        }}
                      >
                        <Box
                          className={classes.collapsedFilters}
                          onClick={e => {
                            handleFiltersClick(e);
                            if (cookies.ns_filter_acknowledged !== "true") {
                              setAcknowledged("ns_filter_acknowledged");
                            }
                          }}
                        >
                          <FilterListIcon className={classes.filtersIcon} />
                        </Box>
                      </InstructionsTooltip>
                      <InstructionsTooltip
                        text="Click here to sort the results"
                        open={
                          cookies.ns_filter_acknowledged === "true" &&
                          cookies.ns_sort_acknowledged !== "true" &&
                          !drawerOpen
                        }
                        onAction={() => {
                          if (cookies.ns_sort_acknowledged !== "true") {
                            setAcknowledged("ns_sort_acknowledged");
                          }
                        }}
                      >
                        <Box
                          aria-controls="sorting-menu"
                          aria-haspopup="true"
                          className={classes.collapsedFilters}
                          onClick={e => {
                            handleMobileSortingClick(e);
                            if (cookies.ns_sort_acknowledged !== "true") {
                              setAcknowledged("ns_sort_acknowledged");
                            }
                          }}
                        >
                          <ImportExportIcon className={classes.filtersIcon} />
                        </Box>
                      </InstructionsTooltip>
                      <Menu
                        id="sorting-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        {options.map(option => {
                          return (
                            <MenuItem
                              key={option.value}
                              onClick={() => handleMenuItemClick(option)}
                              selected={option.value === searchFilters.ordering}
                            >
                              {option.label}
                            </MenuItem>
                          );
                        })}
                      </Menu>
                    </div>
                  </Hidden>
                </Grid>

                <Grid item style={{ flexGrow: 1 }}>
                  <RoundTabs
                    value={curTab}
                    onChange={handleChange}
                    numberoflistings={!isMobile ? numberOfListings : undefined}
                    openSaveSearchDialog={openSaveSearchDialog}
                  >
                    <RoundTab
                      style={{ zIndex: 2 }}
                      label={<Typography variant={"h5"}>List</Typography>}
                      {...a11yProps(0)}
                    />
                    <RoundTab
                      style={{ zIndex: 1 }}
                      label={<Typography variant={"h5"}>Map</Typography>}
                      {...a11yProps(1)}
                    />
                  </RoundTabs>
                </Grid>
                {isMobile && numberOfListings !== undefined && (
                  <>
                    <Typography
                      className={classes.resultsText}
                      variant="subtitle2"
                    >
                      {`${formatNumber(numberOfListings)} listings found`}
                      {searchTriggered && (
                        <>
                          ,{" "}
                          <Typography
                            className={classes.saveText}
                            variant="subtitle2"
                            color="primary"
                            onClick={openSaveSearchDialog}
                          >
                            Save Search
                          </Typography>
                        </>
                      )}
                    </Typography>
                  </>
                )}
              </Grid>
            </AppBar>
            <TabPanel value={curTab} index={0}>
              <Container className={classes.cardGrid} maxWidth="lg">
                <Grid container spacing={2}>
                  {showCards(listings)}
                </Grid>
                <div className={classes.paginationRoot}>
                  <Pagination
                    count={Math.ceil(numberOfListings / PageSize)}
                    boundaryCount={1}
                    siblingCount={isMobile ? 0 : 1}
                    page={currentPage}
                    onChange={handlePaginationChange}
                    color="primary"
                  />
                </div>
              </Container>
            </TabPanel>
            <TabPanel value={curTab} index={1}>
              <NestiqaMap
                className={classes.map}
                listings={listings}
                curTab={curTab}
              />
            </TabPanel>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
