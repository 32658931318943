import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Typography, Button } from "@material-ui/core";
import LandlordHowItWorks from "../components/landlord/LandlordHowItWorks";
import ListingTable from "../components/ListingTable";
import useAuth0Nesitqa from "../hooks/useAuth0Nestiqa";
import { GLOBALS } from "../App";
import {
  createLandlord,
  getAcceptedTerms,
  getLandlordInfo,
  getLandlordListings,
  getLandlordProperties,
} from "../utils/LandlordApi";
import LandlordInfoSection from "../components/landlord/LandlordInfoSection";
import LandlordEditDialog from "../components/landlord/LandlordEditDialog";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import NestiqaAlertDialog from "../components/NestiqaAlertDialog";
import { makeStyles } from "@material-ui/core/styles";
import PropertyTable from "../components/PropertyTable";
import { getActiveUser } from "../store/selectors/userSelectors";
import NestiqaActionDialog from "../components/NestiqaActionDialog";
import { setUser as setReduxUser } from "../store/actions/userActions";

const useStyles = makeStyles(theme => ({
  loading: {
    marginTop: theme.spacing(12),
  },
  button: {
    marginTop: theme.spacing(1.5),
  },
  buttons: {
    maxWidth: 1000,
    margin: "0 auto",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  buttonWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  [theme.breakpoints.down("sm")]: {
    buttons: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-around",
      marginTop: theme.spacing(1),
    },
  },
}));

export default function LandlordPage() {
  const { isAuthenticated, accessToken } = useAuth0Nesitqa();
  const [landlord, setLandlord] = useState();
  const [activeListings, setActiveListings] = useState();
  const [inactiveListings, setInactiveListings] = useState();
  const [properties, setProperties] = useState();
  const [acceptedTerms, setAcceptedTerms] = useState();
  const [completeDialogOpened, setCompleteDialogOpened] = useState(false);
  const classes = useStyles();
  const activeUser = useSelector(getActiveUser);
  const dispatch = useDispatch();

  const [error, setError] = useState();
  const [step, setStep] = useState(1);

  const history = useHistory();

  const [editDialog, setEditDialog] = useState({
    open: false,
  });

  const openEditDialog = () => {
    setEditDialog({
      open: true,
    });
  };

  const closeEditDialog = () => {
    setEditDialog({
      open: false,
    });
    setStep(1);
  };

  const [completeDialog, setCompleteDialog] = useState({
    open: false,
  });

  const closeCompleteDialog = () => {
    setCompleteDialog({
      open: false,
    });
  };

  const openCompleteDialog = () => {
    if (completeDialogOpened) return;

    setCompleteDialog({
      open: true,
      title: "Complete Landlord Info",
      message: GLOBALS.MESSAGES.COMPLETE_LANDLORD_EXT_INFO,
      onClose: () => {
        closeCompleteDialog();
        openEditDialog();
      },
    });
    setCompleteDialogOpened(true);
  };

  const [createLandlordDialog, setCreateLandlordDialog] = useState({
    open: false,
  });

  const showCreateLandlordError = useCallback(() => {
    setCreateLandlordDialog({
      open: true,
      title: "Error",
      message: GLOBALS.MESSAGES.ERROR_COMPLETING_ACTION,
      buttonMap: [
        {
          buttonName: "Ok",
          handleClick: () => {
            setCreateLandlordDialog({ open: false });
            history.push("/");
          },
        },
      ],
    });
  }, [history]);

  const buttons = useMemo(() => {
    return (
      <div className={classes.buttons}>
        <div className={classes.buttonWrapper}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            disableElevation
            onClick={async () => {
              await createLandlord(
                accessToken,
                (isSuccess, apiResult, apiError) => {
                  if (isSuccess) {
                    setCreateLandlordDialog({ open: false });
                    dispatch(
                      setReduxUser({ landlordId: apiResult.landlordId })
                    );
                  } else {
                    showCreateLandlordError();
                  }
                }
              );
            }}
          >
            Create landlord profile
          </Button>
        </div>
        <div className={classes.buttonWrapper}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            disableElevation
            onClick={() => {
              setCreateLandlordDialog({ open: false });
              history.push("/");
            }}
          >
            No, I am not a landlord
          </Button>
        </div>
      </div>
    );
  }, [
    accessToken,
    classes.button,
    classes.buttonWrapper,
    classes.buttons,
    dispatch,
    history,
    showCreateLandlordError,
  ]);

  useEffect(() => {
    if (accessToken && activeUser && !activeUser.landlordId) {
      setCreateLandlordDialog({
        open: true,
        title: GLOBALS.MESSAGES.CREATE_LANDLORD_TITLE,
        message: (
          <div>
            <Typography>{GLOBALS.MESSAGES.CREATE_LANDLORD_MESSAGE}</Typography>
            {buttons}
          </div>
        ),
        onClose: () => {},
        buttonMap: [],
      });
    }
  }, [
    accessToken,
    activeUser,
    buttons,
    dispatch,
    history,
    showCreateLandlordError,
  ]);

  useEffect(() => {
    if (!history.location?.state?.redirectFromExt) {
      if (landlord?.isComplete === "N") {
        openEditDialog();
      }
    } else {
      openCompleteDialog();
    }

    return () => {
      setCompleteDialog({ open: false });
      setCompleteDialogOpened(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, landlord]);

  useEffect(() => {
    if (!accessToken || !activeUser || !activeUser.landlordId) return;

    function landlordInfoCallback(isSuccess, apiResult, apiError) {
      if (isSuccess) {
        setLandlord(apiResult);
      } else {
        console.error(apiError);
        setError(apiError);
      }
    }

    getLandlordInfo(accessToken, landlordInfoCallback);

    function acceptedTermsCallback(isSuccess, result, error) {
      if (isSuccess) {
        setAcceptedTerms(result.acceptedTerms);
      }
      if (error) {
        console.error(error);
      }
    }

    getAcceptedTerms(accessToken, acceptedTermsCallback);

    return () => {
      setCompleteDialog({ open: false });
    };
  }, [accessToken, activeUser]);

  useEffect(() => {
    if (!landlord || !activeUser || !activeUser.landlordId) return;

    function activeListingsCallback(isSuccess, apiResult, apiError) {
      if (isSuccess) {
        setActiveListings(apiResult.results);
      } else {
        console.error(apiError);
        setActiveListings([]);
      }
    }

    getLandlordListings(accessToken, true, activeListingsCallback);

    return () => {
      setCompleteDialog({ open: false });
    };
  }, [landlord, accessToken, activeUser]);

  useEffect(() => {
    if (!activeListings || !activeUser || !activeUser.landlordId) return;

    function inactiveListingsCallback(isSuccess, apiResult, apiError) {
      if (isSuccess) {
        setInactiveListings(apiResult.results);
      } else {
        console.error(apiError);
        setInactiveListings([]);
      }
    }

    getLandlordListings(accessToken, false, inactiveListingsCallback);

    return () => {
      setCompleteDialog({ open: false });
    };
  }, [activeListings, accessToken, activeUser]);

  useEffect(() => {
    if (!inactiveListings) return;

    function propertiesCallback(isSuccess, apiResult, apiError) {
      if (isSuccess) {
        setProperties(apiResult.results);
      } else {
        console.error(apiError);
        setProperties([]);
      }
    }

    getLandlordProperties(accessToken, propertiesCallback);

    return () => {
      setCompleteDialog({ open: false });
    };
  }, [inactiveListings, accessToken]);

  if (!isAuthenticated) {
    return (
      <Container>
        <Helmet>
          <title>{GLOBALS.META_TAGS.TITLE.LANDLORD_PAGE}</title>
          <meta
          name="description"
          content={GLOBALS.META_TAGS.DESCRIPTION.LANDLORD_PAGE}
        />
        </Helmet>
        <LandlordHowItWorks></LandlordHowItWorks>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Typography variant="h5" align="center" color="error">
          {GLOBALS.MESSAGES.LANDLORD_NOT_FOUND}
        </Typography>
      </Container>
    );
  }

  if (!landlord) {
    return (
      <Container>
        <NestiqaActionDialog {...createLandlordDialog} />
        <Typography
          variant="h5"
          align="center"
          color="primary"
          className={classes.loading}
        >
          Loading...
        </Typography>
      </Container>
    );
  }

  return (
    <Container>
      <Helmet>
        <title>{GLOBALS.META_TAGS.TITLE.LANDLORD_PAGE}</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <NestiqaAlertDialog {...completeDialog} />

      <LandlordEditDialog
        step={step}
        setStep={setStep}
        landlord={landlord}
        setLandlord={setLandlord}
        {...editDialog}
        closeDialog={closeEditDialog}
        acceptedTerms={acceptedTerms}
        setAcceptedTerms={setAcceptedTerms}
      />
      <LandlordInfoSection
        email={landlord.email}
        phoneNumber={landlord.phoneNumber}
        bio={landlord.bio}
        fullName={landlord.fullName}
        facebookId={landlord.facebookId}
        instagramId={landlord.instagramId}
        twitterId={landlord.twitterId}
        linkedinUrl={landlord.linkedinUrl}
        landlord={landlord.landlordType}
        openEditDialog={openEditDialog}
        landlordType={landlord.landlordType}
        address={landlord.fullAddress}
      />
      {activeListings && (
        <ListingTable
          listings={activeListings}
          title="Active Listings"
          showCreate={true}
          landlord={landlord}
          openEditDialog={openEditDialog}
          acceptedTerms={acceptedTerms}
          setAcceptedTerms={setAcceptedTerms}
          accessToken={accessToken}
        />
      )}
      {inactiveListings && inactiveListings.length > 0 && (
        <ListingTable
          listings={inactiveListings}
          title="Inactive Listings"
          showCreate={false}
          landlord={landlord}
          openEditDialog={openEditDialog}
          acceptedTerms={acceptedTerms}
          setAcceptedTerms={setAcceptedTerms}
          accessToken={accessToken}
        />
      )}
      {properties && (
        <PropertyTable
          listings={properties}
          setListings={setProperties}
          title="Unlisted Properties"
          showCreate={true}
          landlord={landlord}
          openEditDialog={openEditDialog}
          acceptedTerms={acceptedTerms}
          setAcceptedTerms={setAcceptedTerms}
          accessToken={accessToken}
          noPropertiesText={GLOBALS.MESSAGES.LANDLORD_JUST_WANT_TO_SCREEN}
        />
      )}
    </Container>
  );
}
