import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import { TextField } from "@material-ui/core";
import { fieldToTextField } from "formik-material-ui";

function PhoneFormat(props) {
  const { inputRef, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      format="(###) ###-####"
      mask="_"
    />
  );
}

PhoneFormat.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default function PhoneNumberField({ InputProps, ...props }) {
  return (
    <TextField
      {...fieldToTextField(props)}
      InputProps={{
        ...InputProps,
        inputComponent: PhoneFormat,
      }}
    />
  );
}
