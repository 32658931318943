import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { formatPrice } from "../nestiqaUtils";
import { makeStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Link,
} from "@material-ui/core";
import { GLOBALS } from "../App";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  summary: {
    width: "100%",
    padding: 0,

    "& .MuiAccordionSummary-content": {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },

    "& .MuiAccordionSummary-root": {
      padding: 0,
    },
  },
  summaryWrapper: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",

    "& div:first-child": {
      flexGrow: 1,
    },

    "& .MuiTypography-root.MuiTypography-body1": {
      fontSize: "16px !important",
      fontWeight: "500 !important",
      whiteSpace: "nowrap",
    },

    "& .MuiTypography-root.MuiTypography-body2": {
      fontSize: "16px !important",
      fontWeight: "400 !important",
      whiteSpace: "nowrap",
    },
  },
  smallMargin: {
    marginRight: theme.spacing(1),
  },
  alignStart: {
    justifyContent: "flex-start !important",
    alignItems: "flex-start !important",
  },
  group: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  title: {
    fontSize: "16px",
    fontWeight: 600,
    display: "flex",
    flexGrow: 1,
  },
  description: {
    textAlign: "end",
    fontSize: "16px",
    fontWeight: 400,
  },
  row: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    paddingBottom: theme.spacing(1),
    fontSize: "16px !important",

    "& > * > p": {
      fontSize: "16px !important",
    },

    "& div:first-child p:first-child": {
      paddingBottom: "2px",
    },
  },
  details: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    paddingLeft: 0,
    paddingRight: 0,
  },
  accordian: {
    boxShadow: "none",
    marginRight: "10px",

    "&:not(:first-of-type)": {
      border: "none",
      borderTop: "2px solid",
      borderColor: theme.palette.secondary.main,
    },

    "&:before": {
      height: 0,
    },

    "&.MuiAccordion-root.Mui-expanded": {
      marginRight: "10px",
    },
  },
  alignIcon: {
    display: "flex",
    justifyContent: "flex-end",
  },
  textEmpty: {
    marginTop: "10px",
    marginBottom: "-20px",
  },
}));

const ListingApplicationAccordion = ({ applications }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleChange = id => (event, isExpanded) => {
    setExpanded(isExpanded ? id : false);
  };

  if (!Array.isArray(applications) || applications.length === 0) {
    return (
      <Typography
        variant="subtitle1"
        align="left"
        color="textPrimary"
        className={classes.textEmpty}
      >
        {GLOBALS.MESSAGES.NO_ITEMS}
      </Typography>
    );
  }

  return (
    <>
      {applications.map(app => (
        <Accordion
          expanded={expanded === app.id}
          key={app.id}
          className={classes.accordian}
          onChange={handleChange(app.id)}
        >
          <AccordionSummary id={app.id} className={classes.summary}>
            <div className={classes.summaryWrapper}>
              {!expanded ? (
                <>
                  <div className={clsx(classes.group, classes.alignStart)}>
                    <Link
                      to={GLOBALS.PATHS.LANDLORD_APPLICATION.replace(
                        ":applicationId",
                        app.id
                      )}
                      component={RouterLink}
                      underline="none"
                    >
                      <Typography
                        className={classes.description}
                        variant="body2"
                        color="textPrimary"
                      >
                        {app.tenant.firstName} {app.tenant.lastName}
                      </Typography>
                    </Link>
                  </div>
                  <Typography
                    className={clsx(classes.description, classes.smallMargin)}
                    variant="body2"
                  >
                    {app.submitted ? app.submitted.toLocaleDateString() : "NA"}
                  </Typography>
                  <div className={classes.alignIcon}>
                    {expanded === app.id ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className={clsx(classes.group, classes.alignStart)}>
                    <Link
                      to={GLOBALS.PATHS.LANDLORD_APPLICATION.replace(
                        ":applicationId",
                        app.id
                      )}
                      component={RouterLink}
                      underline="none"
                    >
                      <Typography
                        className={classes.description}
                        variant="body2"
                        color="textPrimary"
                      >
                        {app.tenant.firstName} {app.tenant.lastName}
                      </Typography>
                    </Link>
                  </div>
                  <div className={classes.alignIcon}>
                    {expanded === app.id ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </div>
                </>
              )}
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <Link
              to={GLOBALS.PATHS.LANDLORD_APPLICATION.replace(
                ":applicationId",
                app.id
              )}
              component={RouterLink}
              underline="none"
            >
              <div className={classes.row}>
                <div className={classes.group}>
                  <Typography
                    className={classes.title}
                    variant="body1"
                    color="textPrimary"
                  >
                    Move in
                  </Typography>
                  <Typography
                    className={classes.description}
                    variant="body2"
                    color="textPrimary"
                  >
                    {app.moveInDate
                      ? app.moveInDate.toLocaleDateString()
                      : "NA"}
                  </Typography>
                </div>
                <div className={classes.group}>
                  <Typography
                    className={classes.title}
                    align="right"
                    variant="body1"
                    color="textPrimary"
                  >
                    Annual Salary
                  </Typography>
                  <Typography
                    className={classes.description}
                    variant="body2"
                    color="textPrimary"
                  >
                    {formatPrice(app.tenant.currentIncome)}
                  </Typography>
                </div>
              </div>
              <div className={classes.row}>
                <div className={classes.group}>
                  <Typography
                    className={classes.title}
                    variant="body1"
                    color="textPrimary"
                  >
                    Household Size
                  </Typography>
                  <Typography
                    className={classes.description}
                    variant="body2"
                    color="textPrimary"
                    style={{ textAlign: "left" }}
                  >
                    {app.tenant.householdSize}
                  </Typography>
                </div>
                <div className={classes.group}>
                  <Typography
                    className={classes.title}
                    align="right"
                    variant="body1"
                    color="textPrimary"
                  >
                    Submitted
                  </Typography>
                  <Typography
                    className={classes.description}
                    variant="body2"
                    color="textPrimary"
                  >
                    {app.submitted ? app.submitted.toLocaleDateString() : "NA"}
                  </Typography>
                </div>
              </div>
              <div className={classes.row}>
                <div className={clsx(classes.group, classes.alignStart)}>
                  <Typography
                    className={classes.title}
                    variant="body1"
                    color="textPrimary"
                  >
                    Showing Done
                  </Typography>
                  <Typography
                    className={classes.description}
                    variant="body2"
                    color="textPrimary"
                  >
                    {app.listingSeen === "Y" ? "Yes" : "No"}
                  </Typography>
                </div>
              </div>
            </Link>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default ListingApplicationAccordion;
