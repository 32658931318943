import React, { useState } from "react";
import {
  Tooltip as TooltipOrig,
  Typography,
  ClickAwayListener,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { trimDotted } from "../../nestiqaUtils";

const useStyles = makeStyles(theme => ({
  tooltip: {
    fontSize: "12px",
    margin: 0,
    padding: "5px",
  },
  popper: {
    top: "-15px !important",
  },
  pets: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",

    [theme.breakpoints.up("xs")]: {
      maxWidth: "200px",
    },

    [theme.breakpoints.up("sm")]: {
      maxWidth: "250px",
    },

    [theme.breakpoints.up("md")]: {
      maxWidth: "200px",
    },
  },
}));

const PetsTooltip = ({ clickable, tooltipText }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  return clickable ? (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <TooltipOrig
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={tooltipText}
        classes={{
          popper: classes.popper,
        }}
      >
        <div onClick={() => setOpen(!open)}>
          <Typography className={classes.pets} variant="subtitle2">
            {trimDotted(tooltipText, 30)}
          </Typography>
        </div>
      </TooltipOrig>
    </ClickAwayListener>
  ) : (
    <TooltipOrig title={<p className={classes.tooltip}>{tooltipText}</p>}>
      <Typography className={classes.pets} variant="subtitle2">
        {trimDotted(tooltipText, 30)}
      </Typography>
    </TooltipOrig>
  );
};

export default PetsTooltip;
