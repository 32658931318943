import React from "react";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import { Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import YesNoField from "../YesNoField";
import { GLOBALS } from "../../App";
import TenantFormControls from "./TenantFormControls";
import { updateTenant } from "../../utils/TenantApi";
import * as yup from "yup";
import { logEvent } from "../../utils/initGa4React";
import { useGA4React } from "ga-4-react";

const useStyles = makeStyles(theme => ({
  textArea: {
    width: "100%",

    "& .MuiInputLabel-root": {
      color: theme.palette.grey[400],
    },

    "& .MuiInputLabel-shrink": {
      color: theme.palette.primary.main,
    },
  },
  yesOrNo: {
    minWidth: "96px",
    margin: "0px",
    marginLeft: theme.spacing(8),
  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(4),
  },
  [theme.breakpoints.down("xs")]: {
    row: {
      flexDirection: "column",
      alignItems: "stretch",
    },
    yesOrNo: {
      margin: "0px",
      marginTop: theme.spacing(1),
    },
  },
}));

export default function TenantBackgroundInfo(props) {
  const {
    accessToken,
    tenant,
    setTenant,
    step,
    setStep,
    showError,
    isEditDialog = false,
    closeDialog,
    formikprops,
  } = props;
  const ga = useGA4React();

  function onStepSubmit(values, formikBag) {
    const updatedValues = {
      id: values.id,
      bankrupcy: values.bankrupcy,
      eviction: values.eviction,
      refusedRent: values.refusedRent,
      criminalOffenceCharges: values.criminalOffenceCharges,
      criminalOffenceConviction: values.criminalOffenceConviction,
      detailsForQuestions: values.detailsForQuestions,
    };

    if(!!ga){
      logEvent("tenant_complete_step", {step: step});
    }

    updateTenant(
      updatedValues,
      accessToken,
      (isSuccess, apiResult, apiError) => {
        if (isSuccess) {
          setTenant(apiResult);
          if (isEditDialog) {
            closeDialog();
          } else {
            setStep(step + 1);
          }
        } else {
          console.error(apiError);
          showError(GLOBALS.MESSAGES.ERROR_UPDATING_TENANT);
          formikBag.setSubmitting(false);
        }
      }
    );
  }

  function handleNext(formikprops) {
    if (formikprops.dirty) {
      formikprops.submitForm();
    } else {
      if (isEditDialog) {
        closeDialog();
      } else {
        setStep(step + 1);
      }
    }
  }

  const validationSchema = yup.object().shape({
    bankrupcy: yup.string().required(GLOBALS.MESSAGES.FIELD_REQUIRED),
    eviction: yup.string().required(GLOBALS.MESSAGES.FIELD_REQUIRED),
    refusedRent: yup.string().required(GLOBALS.MESSAGES.FIELD_REQUIRED),
    criminalOffenceCharges: yup
      .string()
      .required(GLOBALS.MESSAGES.FIELD_REQUIRED),
    criminalOffenceConviction: yup
      .string()
      .required(GLOBALS.MESSAGES.FIELD_REQUIRED),
    detailsForQuestions: yup
      .string()
      .label("Details")
      .when(
        [
          "bankrupcy",
          "eviction",
          "refusedRent",
          "criminalOffenceCharges",
          "criminalOffenceConviction",
        ],
        {
          is: (
            bankrupcy,
            eviction,
            refusedRent,
            criminalOffenceCharges,
            criminalOffenceConviction
          ) =>
            bankrupcy === "Y" ||
            eviction === "Y" ||
            refusedRent === "Y" ||
            criminalOffenceCharges === "Y" ||
            criminalOffenceConviction === "Y",
          then: yup.string().required(),
        }
      ),
  });

  return (
    <TenantFormControls
      tenant={tenant}
      step={step}
      setStep={setStep}
      onSubmit={onStepSubmit}
      validationSchema={validationSchema}
      handleNext={handleNext}
      isEditDialog={isEditDialog}
      closeDialog={closeDialog}
      formikprops={formikprops}
    >
      <AdditionalInfoFormikWrapper />
    </TenantFormControls>
  );
}

function AdditionalInfoFormikWrapper(props) {
  const classes = useStyles();

  const isDetailRequired =
    props.formikprops.values.bankrupcy === "Y" ||
    props.formikprops.values.eviction === "Y" ||
    props.formikprops.values.refusedRent === "Y" ||
    props.formikprops.values.criminalOffenceCharges === "Y" ||
    props.formikprops.values.criminalOffenceConviction === "Y";

  return (
    <Box justifyContent="left" textAlign="left">
      <div className={classes.row}>
        <Typography variant="body1">
          {GLOBALS.SITE_TEXTS.BANKRUPCY_QUESTION}
        </Typography>
        <YesNoField
          className={classes.yesOrNo}
          name="bankrupcy"
          required
          variant="outlined"
          margin="normal"
          handleBlur={props.formikprops.handleBlur}
        />
      </div>
      <div className={classes.row}>
        <Typography variant="body1">
          {GLOBALS.SITE_TEXTS.EVICTION_QUESTION}
        </Typography>
        <YesNoField
          className={classes.yesOrNo}
          name="eviction"
          required
          variant="outlined"
          margin="normal"
          handleBlur={props.formikprops.handleBlur}
        />
      </div>
      <div className={classes.row}>
        <Typography variant="body1">
          {GLOBALS.SITE_TEXTS.REFUSED_RENT_QUESTION}
        </Typography>
        <YesNoField
          className={classes.yesOrNo}
          name="refusedRent"
          required
          variant="outlined"
          margin="normal"
          handleBlur={props.formikprops.handleBlur}
        />
      </div>
      <div className={classes.row}>
        <Typography variant="body1">
          {GLOBALS.SITE_TEXTS.CRIMINAL_CHARGES_QUESTION}
        </Typography>
        <YesNoField
          className={classes.yesOrNo}
          name="criminalOffenceCharges"
          required
          variant="outlined"
          margin="normal"
          handleBlur={props.formikprops.handleBlur}
        />
      </div>
      <div className={classes.row}>
        <Typography variant="body1">
          {GLOBALS.SITE_TEXTS.CRIMINAL_CONVICTION_QUESTION}
        </Typography>
        <YesNoField
          className={classes.yesOrNo}
          name="criminalOffenceConviction"
          required
          variant="outlined"
          margin="normal"
          handleBlur={props.formikprops.handleBlur}
        />
      </div>
      <Typography variant="body1" align="left">
        {GLOBALS.SITE_TEXTS.DETAILS_IF_YES}
      </Typography>
      <Field
        required={isDetailRequired}
        className={classes.textArea}
        component={TextField}
        name="detailsForQuestions"
        type="text"
        label="Enter Details"
        variant="outlined"
        margin="normal"
        multiline
        fullWidth
        rows={6}
      />
    </Box>
  );
}
