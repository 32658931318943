import React, { useState } from "react";
import {
  Typography,
  Grid,
  Tooltip,
  IconButton,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import clsx from "clsx";
import QRCode from "qrcode.react";
import { GLOBALS } from "../../App";
import NestiqaActionDialog from "../NestiqaActionDialog";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { QrScanIcon, FacebookSquare, TwitterSquare } from "../icons";
import LinkIcon from "@material-ui/icons/Link";
import MailIcon from "@material-ui/icons/Mail";
import SmsIcon from "@material-ui/icons/Sms";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-material-ui";
import * as yup from "yup";
import useAuth0Nesitqa from "../../hooks/useAuth0Nestiqa";
import { inviteByEmail, inviteBySms } from "../../hooks/ListingApi";
import NestiqaAlertDialog from "../NestiqaAlertDialog";
import PhoneNumberField from "../PhoneNumberField";

const useStyles = makeStyles(theme => ({
  linkButton: {
    padding: "9px",
    marginLeft: "2px",
    marginBottom: "12px",
    position: "relative",
  },
  socialShareButton: {
    padding: "14px 9px 9px 9px !important",
    color: "rgba(0, 0, 0, 0.54)",

    "&:focus": {
      outline: "none",
      border: "none",
    },
  },
  button: {
    textTransform: "capitalize",
  },

  shareWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: theme.spacing(3),

    "& .MuiTypography-root.MuiTypography-subtitle2": {
      fontSize: "16px",
      marginRight: "5px",
    },

    "& button": {
      marginBottom: 0,
    },
  },
  mobileShareWrapper: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      marginTop: "20px",

      "& h6:first-child": {
        flexGrow: 1,
      },

      "& button:last-child": {
        marginRight: "-4px",
      },
    },
  },
  floatingCopySuccess: {
    fontSize: "1rem",
    position: "absolute",
    top: "-24px",
    left: "-20px",
    transition: "all .5s ease-in",
    whiteSpace: "nowrap",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(1),

    "& > * + *": {
      marginLeft: theme.spacing(1),
    },
  },
  inviteForm: {
    width: "100%",
  },
  inputField: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
}));

const InviteByEmail = ({ onClose, listingNumber, onError, onSuccess }) => {
  const classes = useStyles();

  const { accessToken } = useAuth0Nesitqa("");

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(GLOBALS.MESSAGES.ENTER_VALID_EMAIL)
      .required(GLOBALS.MESSAGES.ENTER_VALID_EMAIL),
    name: yup.string(),
  });

  const inviteCallback = (isSuccess, apiResult, apiError) => {
    onClose();
    if (isSuccess) {
      onSuccess(GLOBALS.MESSAGES.EMAIL_INVITE_SUCCESS);
    } else if (apiError) {
      onError();
    }
  };

  return (
    <Formik
      initialValues={{ email: "", name: "" }}
      validationSchema={validationSchema}
      onSubmit={(values, formikBag) => {
        if (!accessToken) return;
        inviteByEmail(
          listingNumber,
          accessToken,
          values.email,
          values.name,
          inviteCallback
        );
      }}
    >
      {formikprops => (
        <Form className={classes.inviteForm}>
          <Field
            placeholder="Email"
            component={TextField}
            className={classes.inputField}
            required
            name={`email`}
            type="email"
            variant="outlined"
            size="small"
            inputProps={{
              autoComplete: "off",
            }}
          />
          <Field
            placeholder="Name"
            component={TextField}
            className={classes.inputField}
            name={`name`}
            type="text"
            variant="outlined"
            size="small"
            inputProps={{
              autoComplete: "off",
            }}
          />
          <div className={classes.buttonGroup}>
            <Button
              color="primary"
              size="small"
              disabled={formikprops.isSubmitting}
              onClick={formikprops.submitForm}
            >
              Send
            </Button>
            <Button
              color="primary"
              size="small"
              disabled={formikprops.isSubmitting}
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const InviteBySms = ({ onClose, listingNumber, onError, onSuccess }) => {
  const classes = useStyles();

  const { accessToken } = useAuth0Nesitqa("");

  const validationSchema = yup.object().shape({
    phone: yup
      .string()
      .matches(GLOBALS.PHONE_NUMBER_REGEX, GLOBALS.MESSAGES.ENTER_VALID_PHONE)
      .required(GLOBALS.MESSAGES.ENTER_VALID_PHONE),
    name: yup.string(),
  });

  const inviteCallback = (isSuccess, apiResult, apiError) => {
    onClose();
    if (isSuccess) {
      onSuccess(GLOBALS.MESSAGES.SMS_INVITE_SUCCESS);
    } else if (apiError) {
      onError();
    }
  };

  return (
    <Formik
      initialValues={{ phone: "", name: "" }}
      validationSchema={validationSchema}
      onSubmit={(values, formikBag) => {
        if (!accessToken) return;
        inviteBySms(
          listingNumber,
          accessToken,
          values.phone,
          values.name,
          inviteCallback
        );
      }}
    >
      {formikprops => (
        <Form className={classes.inviteForm}>
          <Field
            placeholder="Phone Number"
            component={PhoneNumberField}
            className={classes.inputField}
            required
            name={`phone`}
            type="text"
            variant="outlined"
            size="small"
            inputProps={{
              autoComplete: "off",
            }}
          />
          <Field
            placeholder="Name"
            component={TextField}
            className={classes.inputField}
            name={`name`}
            type="text"
            variant="outlined"
            size="small"
            inputProps={{
              autoComplete: "off",
            }}
          />
          <div className={classes.buttonGroup}>
            <Button
              color="primary"
              size="small"
              disabled={formikprops.isSubmitting}
              onClick={formikprops.submitForm}
            >
              Send
            </Button>
            <Button
              color="primary"
              size="small"
              disabled={formikprops.isSubmitting}
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const InviteApplicants = ({ listingNumber, hideLabel }) => {
  const classes = useStyles();
  const [listingLink, setListingLink] = useState(
    `${GLOBALS.APP_BASE_URL}/listing/${listingNumber}/apply`
  );

  const [showCopyMessage, setShowCopyMessage] = useState(false);

  const [actionDialogControls, setActionDialogControls] = useState({
    open: false,
  });

  const [alertDialogControls, setAlertDialogControls] = useState({
    open: false,
  });

  const closeAlertDialog = () => setAlertDialogControls({ open: false });

  const showError = () => {
    setAlertDialogControls({
      open: true,
      title: "Error",
      message: GLOBALS.MESSAGES.ERROR_CONTACT_SUPPORT,
    });
  };

  const showSuccess = message => {
    setAlertDialogControls({
      open: true,
      title: "Success",
      message,
    });
  };

  const handleCopy = () => {
    setListingLink(`${GLOBALS.APP_BASE_URL}/listing/${listingNumber}/apply`);
    navigator.clipboard.writeText(listingLink);
    setTimeout(() => {
      setShowCopyMessage(false);
    }, 2000);
    setShowCopyMessage(true);
  };

  const downloadQR = () => {
    const canvas = document.getElementById(`${listingNumber}-qr`);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${listingNumber}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const closeActionDialog = () => setActionDialogControls({ open: false });

  const qrActionDialogButtons = [
    {
      buttonName: "download",
      handleClick: () => downloadQR(),
    },
    {
      buttonName: "close",
      handleClick: closeActionDialog,
    },
  ];

  const handleClickQR = () => {
    setActionDialogControls({
      open: true,
      title: "Scan or download QR code",
      message: (
        <QRCode
          id={`${listingNumber}-qr`}
          value={listingLink}
          includeMargin={true}
        />
      ),
      buttonMap: qrActionDialogButtons,
    });
  };

  const handleClickEmail = () => {
    setActionDialogControls({
      open: true,
      title: "Send Email Invitation",
      message: (
        <InviteByEmail
          listingNumber={listingNumber}
          onClose={closeActionDialog}
          onError={showError}
          onSuccess={showSuccess}
        />
      ),
      buttonMap: null,
    });
  };

  const handleClickSms = () => {
    setActionDialogControls({
      open: true,
      title: "Send Text Invitation",
      message: (
        <InviteBySms
          listingNumber={listingNumber}
          onClose={closeActionDialog}
          onError={showError}
          onSuccess={showSuccess}
        />
      ),
      buttonMap: null,
    });
  };

  const shareUrl =
    process.env.NODE_ENV === "development"
      ? `https://nestiqa.appspot.com/landlord/listing/${listingNumber}/apply`
      : listingLink;

  return (
    <Grid
      item
      xs={12}
      className={clsx(classes.shareWrapper, classes.mobileShareWrapper)}
      style={{ position: "relative" }}
    >
      <>
        <NestiqaActionDialog
          {...actionDialogControls}
          style={{ width: "400px", display: "flex", justifyContent: "center" }}
        />

        <NestiqaAlertDialog
          {...alertDialogControls}
          onClose={closeAlertDialog}
        />

        {!hideLabel && (
          <Typography variant="subtitle2">Invite Applicants: </Typography>
        )}

        <Tooltip title="Invite by Email">
          <IconButton
            aria-label="email"
            className={classes.linkButton}
            onClick={handleClickEmail}
          >
            <MailIcon fontSize="inherit" color="secondary" />
          </IconButton>
        </Tooltip>

        <Tooltip title="Invite by Text Message">
          <IconButton
            aria-label="sms"
            className={classes.linkButton}
            onClick={handleClickSms}
          >
            <SmsIcon fontSize="inherit" color="secondary" />
          </IconButton>
        </Tooltip>

        <Tooltip title="Get a link to share with candidates">
          <IconButton
            aria-label="copy"
            className={classes.linkButton}
            onClick={handleCopy}
          >
            <span
              className={classes.floatingCopySuccess}
              style={
                showCopyMessage
                  ? { visibility: "visible" }
                  : { visibility: "hidden" }
              }
            >
              Link copied
            </span>
            <LinkIcon fontSize="inherit" color="secondary" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Get QR code">
          <IconButton
            aria-label="qr-code"
            className={classes.linkButton}
            onClick={handleClickQR}
          >
            <QrScanIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Share to Facebook">
          <FacebookShareButton
            aria-label="facebook"
            className={clsx(classes.socialShareButton, classes.linkButton)}
            url={shareUrl}
          >
            <FacebookSquare fontSize="inherit" />
          </FacebookShareButton>
        </Tooltip>
        <Tooltip title="Share to Twitter">
          <TwitterShareButton
            aria-label="twitter"
            className={clsx(classes.socialShareButton, classes.linkButton)}
            url={shareUrl}
          >
            <TwitterSquare fontSize="inherit" />
          </TwitterShareButton>
        </Tooltip>
      </>
    </Grid>
  );
};

export default InviteApplicants;
