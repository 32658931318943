import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import { GLOBALS } from "../../App";
import { getStaticImgSrc } from "../../nestiqaUtils";
import { useGA4React } from "ga-4-react";
import { logEvent } from "../../utils/initGa4React";
import checkMark from "./check.png";
import hero from "./hero.png";
import howitworks1 from "./howitworks1.png";
import howitworks2 from "./howitworks2.png";
import howitworks3 from "./howitworks3.png";
import marker from "./marker.png";
import blob1 from "./blob1.png";
import blob2 from "./blob2.png";
import blob3 from "./blob3.png";
import blob4 from "./blob4.png";
import blob5 from "./blob5.png";
import blob6 from "./blob6.png";
import blob7 from "./blob7.png";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import useAuth0Nesitqa from "../../hooks/useAuth0Nestiqa";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    maxWidth: 1000,
    margin: "0 auto",
  },
  title: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    fontWeight: "700",
    fontSize: "3rem",
    maxWidth: 650,
  },
  heroSection: {
    display: "flex",
    position: "relative",
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  sectionPoints: {
    marginTop: theme.spacing(4),
    listStyle: "none",
    color: theme.palette.primary.main,
    paddingLeft: theme.spacing(2),
    width: "100%",
  },
  sectionPoint: {
    marginBottom: theme.spacing(1),
    position: "relative",

    "&::before": {
      content: `" "`,
      backgroundImage: `url(${getStaticImgSrc(checkMark)})`,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      position: "absolute",
      height: 20,
      width: 20,
      left: -24,
    },
  },
  indent: {
    marginLeft: theme.spacing(4),
  },
  heroText: {
    flexBasis: "50%",
  },
  heroImg: {
    flexBasis: "50%",
    "& > img": {
      width: "100%",
      marginTop: theme.spacing(-14),
    },
    [theme.breakpoints.down("md")]: {
      "& > img": {
        marginTop: theme.spacing(-4),
      },
    },

    [theme.breakpoints.down("xs")]: {
      "& > img": {
        maxWidth: 400,
        display: "block",
        margin: "0 auto",
      },
    },
  },
  heroButton: {
    width: 180,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginRight: theme.spacing(4),

    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
    },
  },
  heroButtons: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(4),
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  howItWorksTitle: {
    paddingTop: theme.spacing(20),
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(0),
    },
  },
  howItWorksSection1: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  howItWorksImg1: {
    position: "relative",
    width: "40%",
    "& > img": {
      width: "100%",
      marginTop: theme.spacing(-30),
    },
    [theme.breakpoints.down("md")]: {
      "& > img": {
        marginTop: theme.spacing(-20),
      },
    },
    [theme.breakpoints.down("xs")]: {
      margin: "0 auto",
      "& > img": {
        maxWidth: 400,
        display: "block",
        marginTop: theme.spacing(3),
      },
    },
  },
  howItWorksTextWrapper: {
    width: "50%",
    paddingTop: theme.spacing(6),
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  successStoriesTextWrapper: {
    paddingTop: theme.spacing(6),
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  howItWorksText: {
    fonntSize: "1.8rem",
    fontWeight: "700",
  },
  howItWorksSubText: {
    display: "block",
    fontSize: "1.2rem",
    paddingTop: theme.spacing(4),
  },
  howItWorksImg2: {
    paddingTop: theme.spacing(8),
    position: "relative",
    width: "100%",
    maxWidth: 500,
    display: "block",
    margin: "0 auto",
    "& > img": {
      width: "100%",
    },
  },
  howItWorksImg3: {
    position: "relative",
    width: "100%",
    maxWidth: 700,
    display: "block",
    margin: "0 auto",
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(4),
    "& > img": {
      width: "100%",
    },
  },
  howItWorksImg4: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    position: "relative",
    width: "100%",
    maxWidth: 400,
    display: "block",
    margin: "0 auto",
    "& > img": {
      width: "100%",
    },
  },
  marker: {
    paddingTop: theme.spacing(10),
    width: "100%",
    maxWidth: 100,
    display: "block",
    margin: "0 auto",
    "& > img": {
      width: "100%",
    },
  },
  button: {
    minWidth: 350,
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    margin: theme.spacing(2),
  },
  buttons: {
    maxWidth: 1000,
    margin: "0 auto",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(6),
  },
  buttonWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: theme.spacing(4),
  },
  mobile: {
    display: "none !important",
  },
  notMobile: {
    display: "block !important",
  },
  [theme.breakpoints.down("md")]: {
    title: {
      fontSize: "2.5rem",
    },
    howItWorksText: {
      fontSize: "22px",
    },
    button: {
      minWidth: 300,
    },
  },

  [theme.breakpoints.down("xs")]: {
    title: {
      fontSize: "1.8rem",
    },
    howItWorksText: {
      fontSize: "20px",
    },
    buttons: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-around",
      paddingBottom: theme.spacing(3),
    },
    button: {
      marginTop: theme.spacing(0),
    },
    buttonWrapper: {
      paddingTop: theme.spacing(3),
    },
    blob: {
      display: "none !important",
    },
    mobile: {
      display: "block !important",
    },
    notMobile: {
      display: "none !important",
    },
  },
}));

export default function LandlordHowItWorks(props) {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const howItWorks = useRef();

  const ga = useGA4React();
  const history = useHistory();
  const { loginWithRedirect } = useAuth0Nesitqa();

  const help = () => {
    if (!!ga) {
      logEvent("landlord_request_help");
    }
    history.push(GLOBALS.PATHS.CONTACT_US_PAGE);
  };

  const viewSamples = () => {
    if (!!ga) {
      logEvent("landlord_view_samples");
    }
    history.push(GLOBALS.PATHS.SAMPLE_REPORTS_PAGE);
  };

  const signup = () => {
    if (!!ga) {
      logEvent("landlord_ready_to_list", { page: "landlord" });
    }
    localStorage.setItem("previousLocation", window.location.pathname);
    loginWithRedirect({ screen_hint: "signup" });
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h4" color="primary">
        Guess what? Now you can get the most detailed information on potential
        tenants - <em>for free</em>.
      </Typography>

      <div className={classes.heroSection}>
        <div className={classes.heroText}>
          <ul className={classes.sectionPoints}>
            <li className={classes.sectionPoint}>
              Screening with Nestiqa is free for landlords
            </li>
            <li className={classes.sectionPoint}>
              Our screening reports give you access to tenants' rental history,
              household details, income, pets and more
            </li>
            <li className={classes.sectionPoint}>Our reports include:</li>
            <li className={clsx(classes.sectionPoint, classes.indent)}>
              Full credit report with score from TransUnion
            </li>
            <li className={clsx(classes.sectionPoint, classes.indent)}>
              Nationwide criminal report
            </li>
            <li className={clsx(classes.sectionPoint, classes.indent)}>
              Nationwide eviction report
            </li>
          </ul>
          <div className={classes.heroButtons}>
            <Button
              variant="contained"
              color="primary"
              className={classes.heroButton}
              disableElevation
              onClick={() => {
                signup();
              }}
            >
              Get Started
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.heroButton}
              disableElevation
              onClick={() => {
                if (howItWorks.current) {
                  window.scrollTo({
                    behavior: "smooth",
                    top: howItWorks.current.offsetTop,
                  });
                }
              }}
            >
              How it works
            </Button>
          </div>
        </div>
        <div className={classes.heroImg}>
          <img src={getStaticImgSrc(hero)} alt="hero" />
        </div>
        <img
          src={getStaticImgSrc(blob1)}
          alt="blob"
          className={classes.blob}
          style={{
            width: 100,
            position: "absolute",
            right: -100,
            top: -150,
            zIndex: -1,
          }}
        />
        <img
          src={getStaticImgSrc(blob2)}
          alt="blob"
          className={classes.blob}
          style={{
            width: 80,
            position: "absolute",
            left: -50,
            bottom: -150,
            zIndex: -1,
          }}
        />
      </div>

      <Typography
        className={clsx(classes.title, classes.howItWorksTitle)}
        variant="h4"
        color="primary"
        ref={howItWorks}
      >
        How it Works
      </Typography>

      <div className={classes.howItWorksSection1}>
        <div className={classes.howItWorksTextWrapper}>
          <Typography
            variant="h5"
            color="primary"
            className={classes.howItWorksText}
          >
            STEP 1: CREATE ACCOUNT
          </Typography>
          <Typography
            variant="p"
            color="primary"
            className={classes.howItWorksSubText}
          >
            Create your free account. It only takes a few seconds.
          </Typography>
        </div>
        <div className={classes.howItWorksImg1}>
          <img src={getStaticImgSrc(howitworks1)} alt="how it works" />
          <img
            src={getStaticImgSrc(blob3)}
            alt="blob"
            className={classes.blob}
            style={{
              width: 200,
              position: "absolute",
              right: "-10%",
              bottom: "50%",
              zIndex: -1,
            }}
          />
        </div>
      </div>

      <div className={clsx(classes.howItWorksImg2, classes.notMobile)}>
        <img src={getStaticImgSrc(howitworks2)} alt="how it works" />
        <img
          src={getStaticImgSrc(blob5)}
          alt="blob"
          className={classes.blob}
          style={{
            width: 250,
            position: "absolute",
            right: -200,
            bottom: "20%",
            zIndex: -1,
          }}
        />
        <img
          src={getStaticImgSrc(blob4)}
          alt="blob"
          className={classes.blob}
          style={{
            width: 300,
            position: "absolute",
            left: -300,
            bottom: "45%",
            zIndex: -1,
          }}
        />
      </div>

      <div className={classes.howItWorksSection2}>
        <div className={classes.howItWorksTextWrapper}>
          <Typography
            variant="h5"
            color="primary"
            className={classes.howItWorksText}
          >
            STEP 2: INVITE APPLICANTS
          </Typography>
          <Typography
            variant="body1"
            color="primary"
            className={classes.howItWorksSubText}
          >
            Invite any tenant you want to screen. They'll pay the $35
            application fee and complete the application.
          </Typography>
        </div>
      </div>

      <div className={clsx(classes.howItWorksImg2, classes.mobile)}>
        <img src={getStaticImgSrc(howitworks2)} alt="how it works" />
      </div>

      <div className={clsx(classes.howItWorksImg3, classes.notMobile)}>
        <img src={getStaticImgSrc(howitworks3)} alt="how it works" />
        <img
          src={getStaticImgSrc(blob6)}
          alt="blob"
          className={classes.blob}
          style={{
            width: 200,
            position: "absolute",
            left: -250,
            top: 20,
            zIndex: -1,
            transform: "rotate(-25deg)",
          }}
        />
        <img
          src={getStaticImgSrc(blob7)}
          alt="blob"
          className={classes.blob}
          style={{
            width: 250,
            position: "absolute",
            right: -270,
            bottom: -100,
            zIndex: -1,
            transform: "rotate(-190deg)",
          }}
        />
      </div>

      <div className={classes.howItWorksSection3}>
        <div className={classes.howItWorksTextWrapper}>
          <Typography
            variant="h5"
            color="primary"
            className={classes.howItWorksText}
          >
            STEP 3: REVIEW APPLICATIONS
          </Typography>
          <Typography
            variant="p"
            color="primary"
            className={classes.howItWorksSubText}
          >
            Review the information and make a decision.
          </Typography>
        </div>
      </div>
      <div className={clsx(classes.howItWorksImg3, classes.mobile)}>
        <img src={getStaticImgSrc(howitworks3)} alt="how it works" />
      </div>
      <div className={clsx(classes.howItWorksImg4, classes.notMobile)}>
        <img src={getStaticImgSrc(hero)} alt="hero" />
      </div>

      <Typography
        className={clsx(classes.title, classes.howItWorksTitle)}
        variant="h4"
        color="primary"
        ref={howItWorks}
      >
        Sample Application
      </Typography>

          <Typography
            variant="p"
            color="primary"
            className={classes.howItWorksSubText}
          >
            Curious what the application looks like? We prepared some samples
            for you of a rental application and screening reports. Click below
            to view:
          </Typography>
          <div className={classes.buttons}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              disableElevation
              onClick={viewSamples}
            >
              View Samples
            </Button>
          </div>

      <Typography
        className={clsx(classes.title, classes.howItWorksTitle)}
        variant="h4"
        color="primary"
        ref={howItWorks}
      >
        Success Stories
      </Typography>

      <div className={classes.howItWorksSection1}>
        <div className={classes.successStoriesTextWrapper}>
          <Typography
            variant="h5"
            color="primary"
            className={classes.howItWorksText}
          >
            "No other site gave me as much information about my future tenant as
            Nestiqa"
          </Typography>
          <Typography
            variant="p"
            color="primary"
            className={classes.howItWorksSubText}
          >
            Steve, NJ
          </Typography>
        </div>
      </div>

      <div className={classes.howItWorksSection2}>
        <div className={classes.successStoriesTextWrapper}>
          <Typography
            variant="h5"
            color="primary"
            className={classes.howItWorksText}
          >
            "Thanks to Nestiqa the due diligence of my candidates became very
            simple!"
          </Typography>
          <Typography
            variant="body1"
            color="primary"
            className={classes.howItWorksSubText}
          >
            Melissa, FL
          </Typography>
        </div>
      </div>

      <div className={classes.marker}>
        <img src={getStaticImgSrc(marker)} alt="marker" />
      </div>

      <Typography
        className={classes.title}
        variant="h4"
        align="center"
        color="primary"
        style={{ margin: "0 auto", maxWidth: "300px" }}
      >
        What are you waiting for?
      </Typography>

      <div className={classes.buttons}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          disableElevation
          onClick={() => {
            signup();
          }}
        >
          Get Started Now
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          disableElevation
          onClick={() => {
            help();
          }}
        >
          I need help
        </Button>
      </div>
    </div>
  );
}
