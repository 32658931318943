import React, { Children } from "react";
import clsx from "clsx";
import { Formik, Form } from "formik";
import { useMediaQuery } from "@material-ui/core";
import {
  Button,
  LinearProgress,
  Stepper,
  Step,
  StepButton,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import MobileStepper from "@material-ui/core/MobileStepper";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { useHistory } from "react-router-dom";

import { GLOBALS } from "../../App";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: props => (props.isEditDialog ? "0px" : theme.spacing(2)),
  },
  stepHeading: {
    color: theme.palette.primary.main,
  },
  formCard: {
    backgroundColor: "#fff",
    maxWidth: "1000px",
    borderRadius: "5px",
    boxShadow: "0px 3px 36px rgba(0, 0, 0, 0.13)",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: props => (props.isEditDialog ? "0px" : theme.spacing(4)),

    "& .MuiInputLabel-root": {
      color: theme.palette.grey[400],
    },

    "& .MuiInputLabel-shrink": {
      color: theme.palette.primary.main,
    },
  },
  cardContent: {
    minHeight: "240px",
    paddingTop: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    marginBottom: theme.spacing(3),
  },
  btnRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(5),
    paddingLeft: theme.spacing(5),
  },
  linearProgress: {
    marginTop: theme.spacing(4),
  },
  bgGray: {
    backgroundColor: "#F9F9F9",
    borderRadius: "0 0 5px 5px",
  },
  [theme.breakpoints.down("sm")]: {
    stepHeading: {
      fontSize: "1.8rem",
    },
  },
  [theme.breakpoints.up("md")]: {
    stepHeading: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      textTransform: "uppercase",
      position: "relative",

      "&::after": {
        content: "''",
        width: "52px",
        height: "4px",
        position: "absolute",
        left: "50%",
        marginLeft: "-26px",
        bottom: "0px",
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
}));

export default function ListingFormControls(props) {
  const classes = useStyles();
  const history = useHistory();

  const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));
  const isTablet =
    useMediaQuery(theme => theme.breakpoints.down("md")) && !isMobile;

  const lastStep = 6;
  const noOfSteps = 6;

  const {
    listing,
    step,
    setStep,
    onSubmit,
    validationSchema,
    children,
    handleNext,
  } = props;

  const steps = [
    "Listing Address",
    "Listing Info",
    "Features",
    "Photos",
    "Preview",
    "Publish",
  ];

  const setNextStep = () => {
    setStep(prevActiveStep => prevActiveStep + 1);
  };

  const setPrevStep = () => {
    setStep(prevActiveStep => prevActiveStep - 1);
  };

  function applyProps(children, props) {
    return Children.map(children, child => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, props);
      }
      return child;
    });
  }

  return (
    <Formik
      initialValues={listing}
      validationSchema={validationSchema}
      onSubmit={(values, formikBag) => {
        onSubmit(values, formikBag);
      }}
    >
      {formikprops => (
        <Form className={clsx(classes.root)}>
          {isMobile ? (
            <>
              <Typography
                className={classes.stepHeading}
                variant="h4"
                align="center"
              >
                {steps[step - 1]}
              </Typography>
              <MobileStepper
                variant="dots"
                steps={noOfSteps}
                position="static"
                activeStep={step - 1}
                nextButton={
                  <Button
                    color="primary"
                    onClick={setNextStep}
                    disabled={step === lastStep}
                  >
                    <KeyboardArrowRight />
                  </Button>
                }
                backButton={
                  <Button
                    color="primary"
                    onClick={setPrevStep}
                    disabled={step === 1}
                  >
                    <KeyboardArrowLeft />
                  </Button>
                }
              />
            </>
          ) : (
            <>
              <Stepper
                alternativeLabel={isTablet}
                nonLinear
                activeStep={step - 1}
              >
                {steps.map((value, index) => (
                  <Step key={value}>
                    <StepButton
                      disabled={formikprops.isSubmitting}
                      onClick={() => setStep(index + 1)}
                    >
                      {value}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
              <Typography
                className={classes.stepHeading}
                variant="h4"
                align="center"
              >
                {steps[step - 1]}
              </Typography>
            </>
          )}
          <div className={clsx(classes.formCard)}>
            <div className={classes.cardContent}>
              {applyProps(children, { formikprops: formikprops })}
              {formikprops.isSubmitting && (
                <LinearProgress className={classes.linearProgress} />
              )}
            </div>
            <div className={classes.btnRow}>
              <Button
                variant="contained"
                color="primary"
                disabled={formikprops.isSubmitting || step === 1}
                onClick={() => setStep(step - 1)}
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={formikprops.isSubmitting}
                onClick={() => handleNext(formikprops)}
              >
                {step === lastStep ? "Save and Publish" : "Save and Continue"}
              </Button>
            </div>
            <div className={clsx(classes.btnRow, classes.bgGray)}>
              <Button
                variant="outlined"
                color="primary"
                disabled={
                  formikprops.isSubmitting ||
                  [4, 5].includes(step) ||
                  !formikprops.dirty
                }
                onClick={formikprops.resetForm}
              >
                Reset
              </Button>
              <Button
                variant="outlined"
                color="primary"
                align="center"
                disabled={formikprops.isSubmitting}
                onClick={() => {
                  history.push(GLOBALS.PATHS.LANDLORD);
                }}
              >
                Continue Later
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
