import { useEffect } from "react";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Container, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { turnOffSearchAlerts } from "../utils/UserApi";
import NestiqaAlertDialog from "../components/NestiqaAlertDialog";

import { GLOBALS } from "../App";

const useStyles = makeStyles(theme => ({
  viewListingsBtn: {
    display: "block",
    margin: "0 auto",
    marginTop: theme.spacing(4),
  },
}));

const TurnOffAlerts = () => {
  const classes = useStyles();
  const history = useHistory();
  const [result, setResult] = useState();

  const [dialogControls, setDialogControls] = useState({
    open: false,
    title: "",
    message: "",
  });

  const showError = (error = GLOBALS.MESSAGES.ERROR_COMPLETING_ACTION) => {
    setDialogControls({
      open: true,
      title: "Error",
      message: error,
    });
  };

  function turnOffAlertsCallback(isSuccess, result, error) {
    if (isSuccess) {
      setResult(result);
    } else {
      showError();
    }
  }

  const { turnOffCode } = useParams();

  async function callTurnOffAlerts() {
    turnOffSearchAlerts(turnOffCode, turnOffAlertsCallback);
  }

  useEffect(() => {
    callTurnOffAlerts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!result) {
    return (
      <Container>
        <NestiqaAlertDialog
          {...dialogControls}
          onClose={() => setDialogControls({ open: false })}
        />
        <Typography variant="h5" align="center" color="primary">
          Loading...
        </Typography>
      </Container>
    );
  }

  return (
    <Container>
      <NestiqaAlertDialog
        {...dialogControls}
        onClose={() => setDialogControls({ open: false })}
      />
      <Typography variant="h5" align="center" color="primary">
        Email alerts turned off successfully for the search {result.searchName}
      </Typography>
      <Button
        className={classes.viewListingsBtn}
        variant="contained"
        color="primary"
        onClick={() => {
          history.push(GLOBALS.PATHS.LISTINGS);
        }}
      >
        View Listings
      </Button>
    </Container>
  );
};

export default TurnOffAlerts;
