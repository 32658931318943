import React, { useState } from "react";
import clsx from "clsx";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import PhoneNumberField from "../PhoneNumberField";
import InputAdornment from "@material-ui/core/InputAdornment";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import BusinessIcon from "@material-ui/icons/Business";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { makeStyles } from "@material-ui/core/styles";
import { GLOBALS } from "../../App";
import LandlordFormControls from "./landlordFormControls";
import ChoiceField from "./../ChoiceField";
import * as yup from "yup";

import { updateLandlord, setLandlordPicture } from "../../utils/LandlordApi";
import tenantPicture from "./../tenant_picture.png";

const useStyles = makeStyles(theme => ({
  datePicker: {},
  inputField: {
    width: "100%",

    "& .MuiInputLabel-root": {
      color: theme.palette.grey[400],
    },

    "& .MuiInputLabel-shrink": {
      color: theme.palette.primary.main,
    },
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "auto 1fr 1fr",
    gridTemplateAreas: `
      "image firstName lastName"
      "image companyName landlordType"
      "image email phone"
      "bio bio bio"
      "social social social"
    `,
    columnGap: theme.spacing(2),
  },
  firstName: {
    gridArea: "firstName",
  },
  lastName: {
    gridArea: "lastName",
  },
  companyName: {
    gridArea: "companyName",
  },
  landlordType: {
    gridArea: "landlordType",
    "& .MuiSelect-icon": {
      right: "40px",
    },
  },
  email: {
    gridArea: "email",
  },
  phone: {
    gridArea: "phone",
  },
  bio: {
    gridArea: "bio",
  },
  socialField: {
    width: "100%",
  },
  socialFields: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridColumnGap: theme.spacing(2),
  },
  social: {
    gridArea: "social",
  },
  image: {
    width: "216px",
    height: "216px",
    gridArea: "image",
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: "5px",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    backgroundColor: "#fff",

    "& label": {
      cursor: "pointer",
      width: "100%",
      height: "100%",
    },

    "& img": {
      objectFit: "cover",
      objectPosition: "center",
      width: "100%",
      height: "100%",
    },

    "& input": {
      display: "none",
    },
  },
  [theme.breakpoints.down("md")]: {
    grid: {
      paddingTop: theme.spacing(18),
      gridTemplateColumns: "1fr 1fr",
      gridTemplateAreas: `
      "firstName lastName"
      "companyName landlordType"
      "email phone"
      "bio bio"
      "social social"
      `,
      position: "relative",
    },
    image: {
      position: "absolute",
      width: "160px",
      height: "160px",
      top: "-40px",
      left: "50%",
      marginLeft: "-80px",
    },
    root: {
      marginTop: "0px",
    },
    socialFields: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
  },
  [theme.breakpoints.down("xs")]: {
    grid: {
      gridTemplateColumns: "1fr",
      gridTemplateAreas: `
      "firstName"
      "lastName"
      "companyName"
      "landlordType"
      "email"
      "phone"
      "bio"
      "social"
      `,
      position: "relative",
    },
    socialFields: {
      gridTemplateColumns: "1fr",
    },
  },
}));

export default function LandlordInfo(props) {
  const {
    step,
    setStep,
    accessToken,
    landlord,
    setLandlord,
    showError,
    closeDialog,
  } = props;

  const classes = useStyles(props);

  function onSubmit(values, formikBag) {
    const updatedValues = {
      id: values.id,
      firstName: values.firstName,
      lastName: values.lastName,
      companyName: values.companyName,
      landlordType: values.landlordType,
      email: values.email,
      phoneNumber: values.phoneNumber,
      bio: values.bio,
      facebookId: values.facebookId,
      instagramId: values.instagramId,
      twitterId: values.twitterId,
      linkedinUrl: values.linkedinUrl,
    };
    updateLandlord(
      updatedValues,
      accessToken,
      (isSuccess, apiResult, apiError) => {
        if (isSuccess) {
          setLandlord(landlord => ({ ...landlord, ...apiResult }));
          setStep(step + 1);
        } else {
          console.error(apiError);
          showError(GLOBALS.MESSAGES.ERROR_UPDATING_LANDLORD);
          formikBag.setSubmitting(false);
        }
      }
    );
  }

  function handleSave(formikprops) {
    if (formikprops.dirty) {
      formikprops.submitForm();
    } else {
      setStep(step + 1);
    }
  }

  const validationSchema = yup.object().shape({
    firstName: yup
      .string()
      .min(2)
      .max(50)
      .matches(GLOBALS.LEGAL_NAME_REGEX, GLOBALS.MESSAGES.ONLY_ALPHA)
      .required(GLOBALS.MESSAGES.FIELD_REQUIRED)
      .label("First Name"),
    lastName: yup
      .string()
      .min(2)
      .max(50)
      .matches(GLOBALS.LEGAL_NAME_REGEX, GLOBALS.MESSAGES.ONLY_ALPHA)
      .required(GLOBALS.MESSAGES.FIELD_REQUIRED)
      .label("Last Name"),

    companyName: yup
      .string()
      .matches(GLOBALS.COMPANY_NAME_REGEX, GLOBALS.MESSAGES.COMPANY_NAME_ERROR),
    landlordType: yup.string().required(GLOBALS.MESSAGES.FIELD_REQUIRED),
    email: yup
      .string()
      .email(GLOBALS.MESSAGES.ENTER_VALID_EMAIL)
      .required(GLOBALS.MESSAGES.FIELD_REQUIRED),
    phoneNumber: yup
      .string()
      .matches(GLOBALS.PHONE_NUMBER_REGEX, GLOBALS.MESSAGES.ENTER_VALID_PHONE)
      .required(),
    facebookId: yup
      .string()
      .matches(
        GLOBALS.SOCIAL_USERNAME_REGEX,
        GLOBALS.MESSAGES.SOCIAL_USERNAME_VALIDATION_MSG.replace(
          "[social]",
          "Facebook"
        )
      ),
    instagramId: yup
      .string()
      .matches(
        GLOBALS.SOCIAL_USERNAME_REGEX,
        GLOBALS.MESSAGES.SOCIAL_USERNAME_VALIDATION_MSG.replace(
          "[social]",
          "Instagram"
        )
      ),
    twitterId: yup
      .string()
      .matches(
        GLOBALS.SOCIAL_USERNAME_REGEX,
        GLOBALS.MESSAGES.SOCIAL_USERNAME_VALIDATION_MSG.replace(
          "[social]",
          "Twitter"
        )
      ),
    linkedinUrl: yup
      .string()
      .matches(GLOBALS.VALID_URL_REGEX, GLOBALS.MESSAGES.INVALID_URL_MESSAGE),
  });

  const [profilePicture, setProfilePicture] = useState(null);

  function handleImageUpload(e) {
    const [file] = e.target.files;

    setLandlordPicture(
      file,
      landlord.id,
      accessToken,
      (isSuccess, apiResult, apiError) => {
        if (isSuccess) {
          setProfilePicture(apiResult.picture);
          setLandlord(landlord => ({ ...landlord, ...apiResult }));
        }
      }
    );
  }

  return (
    <LandlordFormControls
      step={step}
      setStep={setStep}
      landlord={landlord}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      handleSave={handleSave}
      closeDialog={closeDialog}
      className={classes.root}
    >
      <LandlordInfoFormikWrapper
        handleImageUpload={handleImageUpload}
        profilePicture={profilePicture}
        landlord={landlord}
      />
    </LandlordFormControls>
  );
}

const LandlordInfoFormikWrapper = ({
  formikprops,
  handleImageUpload,
  profilePicture,
  landlord,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.grid}>
      {/* First Name */}

      <div className={classes.image}>
        <input
          type="file"
          id="imgUpload"
          accept="image/*"
          multiple={false}
          onChange={handleImageUpload}
        />
        <label htmlFor="imgUpload">
          <img
            src={
              profilePicture
                ? profilePicture
                : landlord.picture !== null
                ? landlord.picture
                : `${tenantPicture}`
            }
            alt={landlord.firstName}
          />
        </label>
      </div>

      <Field
        className={clsx(classes.inputField, classes.firstName)}
        component={TextField}
        required={formikprops.values.companyName === ""}
        name="firstName"
        type="text"
        label="First Name"
        variant="outlined"
        margin="normal" //Check if margins are needed or covered by classes
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <PersonOutlineIcon color="secondary" />
            </InputAdornment>
          ),
        }}
      />

      {/* Last Name */}

      <Field
        className={clsx(classes.inputField, classes.lastName)}
        component={TextField}
        required={formikprops.values.companyName === ""}
        name="lastName"
        type="text"
        label="Last Name"
        variant="outlined"
        margin="normal"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <PersonOutlineIcon color="secondary" />
            </InputAdornment>
          ),
        }}
      />

      {/* Company Name */}

      <Field
        className={clsx(classes.inputField, classes.companyName)}
        component={TextField}
        type="text"
        required={
          formikprops.values.firstName === "" &&
          formikprops.values.lastName === ""
        }
        label="Company Name"
        name="companyName"
        variant="outlined"
        margin="normal"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <BusinessIcon color="secondary" />
            </InputAdornment>
          ),
        }}
      />

      {/* Landlord Type */}

      <Field
        className={clsx(classes.inputField, classes.landlordType)}
        component={ChoiceField}
        name="landlordType"
        required
        variant="outlined"
        label="Landlord Type"
        margin="normal"
        choices={GLOBALS.LANDLORD_TYPES}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <PersonOutlineIcon color="secondary" />
            </InputAdornment>
          ),
        }}
      />

      {/* Email */}

      <Field
        className={clsx(classes.inputField, classes.email)}
        component={TextField}
        required
        name="email"
        type="email"
        label="E-mail"
        variant="outlined"
        margin="normal"
        disabled={landlord.email}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <MailOutlineIcon color="secondary" />
            </InputAdornment>
          ),
        }}
      />

      {/* Phone */}

      <Field
        className={clsx(classes.inputField, classes.phone)}
        component={PhoneNumberField}
        required
        name="phoneNumber"
        type="text"
        label="Phone Number"
        variant="outlined"
        margin="normal"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <PhoneIphoneIcon color="secondary" />
            </InputAdornment>
          ),
        }}
      />

      {/* Bio */}

      <Field
        className={clsx(classes.inputField, classes.bio)}
        component={TextField}
        name="bio"
        type="text"
        label="Bio"
        variant="outlined"
        margin="normal"
        multiline
        rows={6}
      />

      {/* Social */}
      <div className={classes.social}>
        <div className={clsx(classes.socialFields)}>
          <Field
            className={clsx(classes.inputField)}
            component={TextField}
            name="facebookId"
            type="text"
            label="Facebook Username"
            variant="outlined"
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <FacebookIcon color="secondary" />
                </InputAdornment>
              ),
            }}
          />

          <Field
            className={clsx(classes.inputField)}
            component={TextField}
            name="instagramId"
            type="text"
            label="Instagram Username"
            variant="outlined"
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <InstagramIcon color="secondary" />
                </InputAdornment>
              ),
            }}
          />

          <Field
            className={clsx(classes.inputField)}
            component={TextField}
            name="twitterId"
            type="text"
            label="Twitter Username"
            variant="outlined"
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <TwitterIcon color="secondary" />
                </InputAdornment>
              ),
            }}
          />
          <Field
            className={clsx(classes.inputField)}
            component={TextField}
            name="linkedinUrl"
            type="text"
            label="LinkedIn URL"
            variant="outlined"
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <LinkedInIcon color="secondary" />
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </div>
  );
};
