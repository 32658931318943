import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Link as NavLink, useHistory } from "react-router-dom";
import { TextField, Grid, Typography, Link } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import withAutocomplete from "./withAutocomplete";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { GLOBALS } from "../../App";
import {
  listingNumberRegex,
  allowedAddressMixed,
} from "../../constants/expressions";

const SearchBar = React.forwardRef(
  (
    {
      className,
      classes,
      inputClassName,
      shortLabel,
      options,
      allowManual,
      value,
      setOptions,
      setValue,
      setInputValue,
      onInputChange,
      required,
      label,
      inputVariant,
      placeholder,
      handleResetFilters,
      hasIcon,
      searchByListing,
      setSearchByListing,
      ...rest
    },
    ref
  ) => {
    const history = useHistory();

    return (
      <Autocomplete
        {...rest}
        ref={ref}
        classes={{
          root: className,
          listbox: classes.listbox,
          inputRoot: inputClassName,
        }}
        getOptionLabel={option => {
          if (typeof option === "string") return option;

          if (option.hasOwnProperty("listing_number"))
            return option.listing_number;

          return shortLabel
            ? option.structured_formatting.main_text
            : option.description;
        }}
        filterOptions={x => x}
        options={options}
        clearOnBlur={!searchByListing || !allowManual}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        onChange={(event, newValue) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);

          if (searchByListing && !!newValue) {
            if (newValue.hasOwnProperty("structured_formatting")) {
              setInputValue(newValue);
            } else {
              history.push(
                GLOBALS.PATHS.LISTING_DETAILS.replace(
                  ":listingNumber",
                  newValue.listing_number
                )
              );
              onInputChange("");
            }
          }
        }}
        onInputChange={(event, newInputValue) => {
          const isListingNumber = listingNumberRegex.test(newInputValue);
          const secondCond = allowedAddressMixed.test(newInputValue);
          const isListingInput = Boolean(isListingNumber || secondCond);

          if (isListingInput) {
            setSearchByListing(true);
          } else {
            setSearchByListing(false);
          }

          setInputValue(newInputValue);
          if (onInputChange) {
            onInputChange(newInputValue);
          }
        }}
        renderInput={params => (
          <TextField
            {...params}
            required={required}
            label={label}
            variant={inputVariant}
            placeholder={placeholder}
            onReset={handleResetFilters}
            InputProps={
              hasIcon
                ? {
                    ...params.InputProps,
                    endAdornment: <Search color="primary" fontSize="small" />,
                  }
                : { ...params.InputProps }
            }
          />
        )}
        renderOption={(option, { inputValue }) => {
          if (searchByListing && option.hasOwnProperty("listing_number")) {
            const matches = match(option.listing_number, inputValue);
            const parts = parse(option.listing_number, matches);

            return (
              <Grid
                container
                alignItems="center"
                className={classes.suggestion}
              >
                <Link
                  className={classes.link}
                  component={NavLink}
                  to={GLOBALS.PATHS.LISTING_DETAILS.replace(
                    ":listingNumber",
                    option.listing_number
                  )}
                >
                  <Grid item xs>
                    <span>Listing</span>{" "}
                    {parts.map((part, index) => {
                      return (
                        <span
                          className={classes.optionText}
                          key={index}
                          style={{ fontWeight: part.highlight ? 700 : 400 }}
                        >
                          {part.text}
                        </span>
                      );
                    })}
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      className={classes.optionSecondaryText}
                    >
                      {option.address1}
                    </Typography>
                  </Grid>
                </Link>
              </Grid>
            );
          } else {
            if (!option.structured_formatting) return;

            const matches =
              option.structured_formatting.main_text_matched_substrings;
            const parts = parse(
              option.structured_formatting.main_text,
              matches.map(match => [match.offset, match.offset + match.length])
            );

            return (
              <Grid
                container
                alignItems="center"
                className={classes.suggestion}
              >
                <Grid item xs>
                  {parts.map((part, index) => (
                    <span
                      className={classes.optionText}
                      key={index}
                      style={{ fontWeight: part.highlight ? 700 : 400 }}
                    >
                      {part.text}
                    </span>
                  ))}

                  <Typography
                    variant="body2"
                    color="textSecondary"
                    className={classes.optionSecondaryText}
                  >
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            );
          }
        }}
      />
    );
  }
);

export default withAutocomplete(SearchBar);
