import { SET_SUBMIT_APPLICATIONS_LATER } from "../actions/types";

const initialState = {
  submitApplicationsLater: false,
};

export default function tenantReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_SUBMIT_APPLICATIONS_LATER:
      return { ...state, submitApplicationsLater: true };
    default:
      return state;
  }
}
