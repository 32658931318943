import { useState, useEffect } from "react";
import { snakeToCamel } from "../nestiqaUtils";
import {
  createTenantDocument,
  downloadTenantDocument,
  downloadApplicationDocument,
  downloadAllApplicationDocuments,
} from "./DocumentApi";
import useAuth0Nesitqa from "./useAuth0Nestiqa";

export default function useDocument(params) {
  const [results, setResults] = useState({
    status: "IDLE",
  });
  const [documentControls, setDocumentControls] = useState();

  const { accessToken } = useAuth0Nesitqa("");

  function clearResults() {
    setResults({ status: "IDLE" });
  }

  function prepareResult(documents) {
    const result = snakeToCamel(documents);
    return result;
  }

  function clearDocumentResults() {
    setResults({
      status: "COMPLETE",
      documents: results?.documents,
      clear: clearResults,
    });
  }

  function setDownloadCallback(isSuccess, apiResult, apiError) {
    setResults({
      status: "COMPLETE",
      documents: results?.documents,
      document: isSuccess ? apiResult : null,
      error: isSuccess ? null : apiError,
      clear: clearDocumentResults,
    });
  }
  useEffect(() => {
    if (!params || !accessToken) return;

    if (params.action !== "download-tenant-document")
      setResults({ status: "LOADING" });
    switch (params.action) {
      case "create-tenant-documents":
        setDocumentControls({
          results: [],
          errors: [],
          documents: params.documents,
        });
        break;
      case "download-tenant-document":
        downloadTenantDocument(
          params.documentId,
          accessToken,
          setDownloadCallback
        );
        break;
      case "download-application-document":
        downloadApplicationDocument(
          params.applicationId,
          params.documentId,
          accessToken,
          setDownloadCallback
        );
        break;
      case "download-all-application-documents":
        downloadAllApplicationDocuments(
          params.applicationId,
          params.tenantId,
          accessToken,
          setDownloadCallback
        );
        break;
      default:
        setResults({
          status: "COMPLETE",
          error: `${params.action} is not a valid action`,
          clear: clearResults,
        });
        break;
    }
    // eslint-disable-next-line
  }, [params, accessToken]);

  useEffect(() => {
    if (!documentControls || !accessToken) return;

    const fs = documentControls.documents;

    if (!fs || fs.length === 0) {
      let tarDocumnets = {};
      if (documentControls.results.length > 0) {
        tarDocumnets = {
          ...documentControls.results[0],
        };
      }
      setResults({
        status: "COMPLETE",
        documents: prepareResult(tarDocumnets),
        errors:
          documentControls.errors.length === 0 ? null : documentControls.errors,
        clear: clearResults,
      });
      setDocumentControls(null);
      return;
    }

    const documenteResultsCallback = (isSuccess, result, error) => {
      const tarDocumentControls = {
        ...documentControls,
        documents: fs.slice(0, -1),
      };
      if (isSuccess) {
        tarDocumentControls.results = [result, ...tarDocumentControls.results];
      } else {
        tarDocumentControls.errors = [...tarDocumentControls.errors, error];
      }
      setDocumentControls(tarDocumentControls);
    };

    const handleItem = async currentItem => {
      await createTenantDocument(
        currentItem,
        accessToken,
        documenteResultsCallback
      );
    };

    handleItem(fs[fs.length - 1]);
  }, [documentControls, accessToken]);
  return results;
}
