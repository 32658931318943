import React from "react";
import { MenuItem } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { fieldToTextField } from "formik-material-ui";

export default function ChoiceField(props) {
  const { choices, ...other } = props;

  const { value, ...otherTextFieldProps } = fieldToTextField(other);

  return (
    <TextField
      {...otherTextFieldProps}
      value={value ? value : ""}
      type="text"
      select
    >
      {choices.map((choice, index) => (
        <MenuItem key={index} value={String(choice.value)}>
          {choice.label}
        </MenuItem>
      ))}
      <MenuItem key="EMPTY" value=""></MenuItem>
    </TextField>
  );
}
