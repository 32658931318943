import React from "react";
import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import { GLOBALS } from "../../App";

const useStyle = makeStyles(theme => ({
  marginRight: {
    marginRight: theme.spacing(2),
  },
}));

const UserHasLandord = ({ onYesClick }) => {
  const history = useHistory();
  const classes = useStyle();

  const handleNo = () => {
    history.push("/");
  };

  return (
    <div>
      <Typography gutterBottom>
        {GLOBALS.MESSAGES.USER_HAS_LANDLORD_MSG}
      </Typography>
      <Button
        className={classes.marginRight}
        variant="contained"
        disableElevation
        color="primary"
        onClick={onYesClick}
      >
        Yes
      </Button>
      <Button color="primary" variant="outlined" onClick={handleNo}>
        No
      </Button>
    </div>
  );
};

export default UserHasLandord;
