export const ENUMS = {
  HOUSING_TYPES: [
    {
      value: "SFH",
      label: "Single Family",
    },
    {
      value: "DUP",
      label: "Duplex",
    },
    {
      value: "TRI",
      label: "Triplex",
    },
    {
      value: "FPX",
      label: "Fourplex",
    },
    {
      value: "TOW",
      label: "TownHouse",
    },
    {
      value: "CON",
      label: "Condo",
    },
    {
      value: "RM",
      label: "Room",
    },
    {
      value: "OTR",
      label: "Other",
    },
  ],
  STATUSES: [
    {
      value: "ACTIVE",
      label: "Active",
    },
    {
      value: "INACTIVE",
      label: "Inactive",
    },
    {
      value: "EXPIRED",
      label: "Expired",
    },
    {
      value: "DELETED",
      label: "Deleted",
    },
    {
      value: "DRAFT",
      label: "Draft",
    },
    {
      value: "PENDING_VERIFICATION",
      label: "Pending Verification",
    },
  ],
  APP_ACTIONS: [
    {
      value: "APPLY",
      label: "Application",
    },
    {
      value: "PREQUALIFY",
      label: "Preapprove Request",
    },
  ],
  APP_STATUSES: [
    {
      value: "DRAFT",
      label: "Draft",
    },
    {
      value: "SUBMITTED",
      label: "Submitted",
    },
    {
      value: "APPROVED",
      label: "Approved",
    },
    {
      value: "REJECTED",
      label: "Rejected",
    },
    {
      value: "EXPIRED",
      label: "Expired",
    },
    {
      value: "DELETED",
      label: "Deleted",
    },
    {
      value: "PENDING",
      label: "Pending",
    },
    {
      value: "PROCESSING",
      label: "Processing",
    },
    {
      value: "PENDING_INFO",
      label: "Pending Info",
    },
    {
      value: "PENDING_REPORTS",
      label: "Pending Reports",
    },
    {
      value: "PENDING_HOUSEHOLD",
      label: "Pending Household",
    },
    {
      value: "PENDING_APPLY",
      label: "Pending Apply",
    },
  ],
  PLANS: [
    {
      value: "FR",
      label: "Free",
    },
    {
      value: "UN",
      label: "Unlimited",
    },
  ],
  BEDROOMS: [
    {
      value: 0,
      label: "0",
      labelLong: "Studio",
    },
    {
      value: 1,
      label: "1",
      labelLong: "1 Bedroom",
    },
    {
      value: 2,
      label: "2",
      labelLong: "2 Bedrooms",
    },
    {
      value: 3,
      label: "3",
      labelLong: "3 Bedrooms",
    },
    {
      value: 4,
      label: "4",
      labelLong: "4 Bedrooms",
    },
    {
      value: 5,
      label: "5",
      labelLong: "5 Bedrooms",
    },
    {
      value: 6,
      label: "6+",
      labelLong: "6+ Bedrooms",
    },
    {
      value: 7,
      label: "Other",
      labelLong: "Other",
    },
  ],
  BATHROOMS: [
    {
      value: 0,
      label: "0",
      labelLong: "None",
    },
    {
      value: 1,
      label: "Sh",
      labelLong: "Shared",
    },
    {
      value: 2,
      label: "1",
      labelLong: "1 Bathroom",
    },
    {
      value: 3,
      label: "1.5",
      labelLong: "1.5 Bathrooms",
    },
    {
      value: 4,
      label: "2",
      labelLong: "2 Bathrooms",
    },
    {
      value: 5,
      label: "2.5",
      labelLong: "2.5 Bathrooms",
    },
    {
      value: 6,
      label: "3",
      labelLong: "3 Bathrooms",
    },
    {
      value: 7,
      label: "3.5",
      labelLong: "3.5 Bathrooms",
    },
    {
      value: 8,
      label: "4",
      labelLong: "4 Bathrooms",
    },
    {
      value: 9,
      label: "4.5",
      labelLong: "4.5 Bathrooms",
    },
    {
      value: 10,
      label: "5+",
      labelLong: "5+ Bathrooms",
    },
  ],
  PARKING: [
    {
      value: "NON",
      label: "None",
    },
    {
      value: "GAR",
      label: "Garage",
    },
    {
      value: "STR",
      label: "Street",
    },
    {
      value: "OFS",
      label: "Off Street",
    },
    {
      value: "FEE",
      label: "Additional Fee",
    },
  ],
  LAUNDRY: [
    {
      value: "NON",
      label: "None",
    },
    {
      value: "INU",
      label: "In Unit",
    },
    {
      value: "ONS",
      label: "On Site",
    },
    {
      value: "HKP",
      label: "Hookup Available",
    },
  ],
  LANDLORD_TYPES: [
    {
      value: "PRI",
      label: "Private Landlord",
    },
    {
      value: "RTR",
      label: "Realtor",
    },
    {
      value: "MNG",
      label: "Management Company",
    },
    {
      value: "OTR",
      label: "Other",
    },
  ],
  PETS: [
    {
      value: "YES",
      label: "Yes",
    },
    {
      value: "NO",
      label: "No",
    },
    {
      value: "CATS",
      label: "Cats Only",
    },
    {
      value: "DOGS",
      label: "Dogs Only",
    },
    {
      value: "OTHER",
      label: "Other",
    },
  ],
  LEASE_TYPES: [
    {
      value: "NEGOTIABLE",
      label: "Negotiable",
    },
    {
      value: "MONTHLY",
      label: "Monthly",
    },
    {
      value: "ANNUAL",
      label: "Annual",
    },
    {
      value: "BIANNUAL",
      label: "Bi-Annual",
    },
    {
      value: "OTHER",
      label: "Other",
    },
  ],
  AMENITIES: [
    {
      value: "BBQ",
      label: "BBQ Area",
    },
    {
      value: "BILLIARDS",
      label: "Billiards Room",
    },
    {
      value: "CLUBHOUSE",
      label: "Club House",
    },
    {
      value: "DOG_PARK",
      label: "Dog Park",
    },
    {
      value: "DOORMAN",
      label: "Doorman",
    },
    {
      value: "ELEVATOR",
      label: "Elevator",
    },
    {
      value: "GYM",
      label: "Gym / Fitness Center",
    },
    {
      value: "HOT_TUB",
      label: "Hot Tub",
    },
    {
      value: "INTERNET",
      label: "Internet",
    },
    {
      value: "JOGGING",
      label: "Jogging Trails",
    },
    {
      value: "LAKE",
      label: "Lake",
    },
    {
      value: "LOFT",
      label: "Loft",
    },
    {
      value: "PLAYGROUND",
      label: "Playground",
    },
    {
      value: "ROOFTOP",
      label: "Rooftop",
    },
    {
      value: "POOL",
      label: "Pool",
    },
    {
      value: "SAUNA",
      label: "Sauna",
    },
    {
      value: "STORAGE",
      label: "Storage",
    },
    {
      value: "TENNIS",
      label: "Tennis Courts",
    },
    {
      value: "WHEELCHAIR",
      label: "Wheelchair Access",
    },
    {
      value: "YARD",
      label: "Yard",
    },
  ],
  FEATURES: [
    {
      value: "AC",
      label: "Air Conditioning",
    },
    {
      value: "ALARM",
      label: "Alarm",
    },
    {
      value: "BALCONY",
      label: "Balcony",
    },
    {
      value: "CARPET",
      label: "Carpet",
    },
    {
      value: "CEILING_FANS",
      label: "Ceiling Fans",
    },
    {
      value: "CENTRAL_HEATING",
      label: "Central Heating",
    },
    {
      value: "DISHWASHER",
      label: "Dishwasher",
    },
    {
      value: "DRYER",
      label: "Dryer",
    },
    {
      value: "FIREPLACE",
      label: "Fireplace",
    },
    {
      value: "FURNISHED",
      label: "Furnished",
    },
    {
      value: "GD",
      label: "Garbage Disposal",
    },
    {
      value: "HARDWOOD",
      label: "Hardwood Floors",
    },
    {
      value: "MICROWAVE",
      label: "Microwave",
    },
    {
      value: "RENOVATED",
      label: "Renovated",
    },
    {
      value: "FRIDGE",
      label: "Refrigerator",
    },
    {
      value: "STOVE",
      label: "Stove",
    },
    {
      value: "TILES",
      label: "Tiles",
    },
    {
      value: "WALKIN_CLOSETS",
      label: "Walk-in Closets",
    },
    {
      value: "WASHER",
      label: "Washer",
    },
  ],
  REQUIRED_ACTIONS: [
    {
      status: "DRAFT",
      label: "Complete and Submit",
      link: "/application/{applicationId}/update",
      tooltipText:
        "Complete your application and submit it for the listing owner to review.",
    },
    {
      status: "PENDING_INFO",
      label: "Complete and Submit",
      link: "/application/{applicationId}/update",
      tooltipText:
        "The listing owner has requested you to provide the following information: {applicationComment} Please provide the information and resubmit the application.",
    },
    {
      status: "PENDING_HOUSEHOLD",
      label: "Invite Household Members",
      link: "/tenant/{tenantId}/profile",
      tooltipText:
        "The listing owner has requested to see the full profiles of all adults in your household. Please have all adults in your household create users on Nestiqa. You can invite a user to join your household from your tenant profile.",
    },
    {
      status: "PENDING_REPORTS",
      label: "Get Reports for Household",
      link: "/application/{applicationId}/update",
      tooltipText:
        "The listing owner has requested all adults in your household to have screening reports. In order to allow it all adults in your household must have their own screening reports and then click “Join” to this application from their “My Applications” section.",
    },
    {
      status: "PENDING_APPLY",
      label: "Upgrade Plan and Apply",
      link: "/tenant/{tenantId}/plan",
      tooltipText:
        "You are preapproved for this listing. Upgrade your plan to apply.",
    },
    {
      status: "SUBMITTED",
      label: "Join",
      link: "/application/{applicationId}/join",
      tooltipText:
        "Your household member {tenantName} submitted this application. You need to join this application to give your consent for sharing your information with the listing owner.",
    },
    {
      status: "JOIN_HOUSEHOLD",
      label: "Join",
      link: "/application/{applicationId}/join",
      tooltipText:
        "Your household member {tenantName} submitted this application. You need to join this application to give your consent for sharing your information with the listing owner.",
    },
    {
      status: "PROCESSING",
      label: "Processing",
      link: "/",
      tooltipText:
        "The application is processing. We will email you when there is an update.",
    },
    {
      status: "APPROVED",
      label: "Approved",
      link: "/",
      tooltipText:
        "Your application was approved by the landlord",
    },
    {
      status: "REJECTED",
      label: "Rejected",
      link: "/",
      tooltipText:
        "Your application was rejected by the landlord",
    },
    {
      status: "EXPIRED",
      label: "Expired",
      link: "/",
      tooltipText:
        "This application has expired. If you're still interested and the listing is still available please submit a new application.",
    },
  ],
  YES_NO: [
    {
      value: "Y",
      label: "Yes",
    },
    {
      value: "N",
      label: "No",
    },
  ],
  INCOME_TYPE: [
    {
      value: "EMPLOYMENT",
      label: "Employment",
    },
    {
      value: "ALIMONY",
      label: "Alimony",
    },
    {
      value: "CHILD_SUPPORT",
      label: "Child Support",
    },
    {
      value: "PENSION",
      label: "Pension",
    },
    {
      value: "SOCIAL_SECURITY",
      label: "Social Security",
    },
    {
      value: "SECTION8",
      label: "Section 8",
    },
    {
      value: "SOTA",
      label: "SOTA",
    },
    {
      value: "TRA",
      label: "TRA",
    },
    {
      value: "OTHER_HOUSING_ASSISTANCE",
      label: "Other Housing Assistance",
    },
    {
      value: "OTHER_INCOME",
      label: "Other Income",
    },
  ],
};
