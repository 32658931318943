import React from "react";

const DuplicateIcon = props => {
  return (
    <svg
      id="iconfinder_ic_content_copy_48px_3669369"
      xmlns="true"
      width="26.718"
      height="26.717"
      viewBox="0 0 26.718 26.717"
      {...props}
    >
      <path id="Duplicate-Icon" d="M0,0H26.718V26.717H0Z" fill="none" />
      <path
        id="Duplicate-Icon-Path_377"
        d="M19.585,2H6.226A2.226,2.226,0,0,0,4,4.226V19.812H6.226V4.226H19.585Zm3.34,4.453H10.679A2.226,2.226,0,0,0,8.453,8.679V24.264a2.226,2.226,0,0,0,2.226,2.226H22.925a2.226,2.226,0,0,0,2.226-2.226V8.679A2.226,2.226,0,0,0,22.925,6.453Zm0,17.812H10.679V8.679H22.925Z"
        transform="translate(-4 -2)"
        fill="#3ce0e5"
      />
    </svg>
  );
};

export default DuplicateIcon;
