import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import { useCookies } from "react-cookie";
import * as yup from "yup";
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  LinearProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { GLOBALS } from "../App";
import { newsletterSignup } from "../utils/UserApi";
import NestiqaAlertDialog from "./NestiqaAlertDialog";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const useStyles = makeStyles(theme => ({
  dialogAction: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",

    "& > button": {
      marginLeft: theme.spacing(2),
    },
  },
  dialogForm: {
    width: "100%",

    "& > *": {
      width: "100%",
    },
  },

  linearProgress: {
    marginTop: theme.spacing(2),
  },
}));

const NEWSLETTER_TIMEOUT = 10 * 1000;

export default function NewsletterDialog({ isAuthenticated }) {
  const classes = useStyles();

  const [tenSecsOnPage, setTenSecsOnPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newsletter, setNewsletter] = useState("tenant");
  const [newsletterDialogOpen, setNewsletterDialogOpen] = useState(false);

  const [dialogControls, setDialogControls] = useState({
    open: false,
    title: "",
    message: "",
  });

  function resultsCallback(isSuccess, result, error) {
    setLoading(false);
    setCookie("ns_newsletter_opened", "true", {
      expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365),
    });
    if (isSuccess) {
      setDialogControls({
        open: true,
        title: "Success",
        message: "You were successfully added to the newsletter",
      });
      setNewsletterDialogOpen(false);
    } else {
      setDialogControls({
        open: true,
        title: "Error",
        message: GLOBALS.MESSAGES.ERROR_COMPLETING_ACTION,
      });
      setNewsletterDialogOpen(false);
    }
  }

  async function callNewsletterSignup(email) {
    setLoading(true);
    newsletterSignup(newsletter, email, resultsCallback);
  }

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(GLOBALS.MESSAGES.ENTER_VALID_EMAIL)
      .required(GLOBALS.MESSAGES.ENTER_VALID_EMAIL),
  });

  const location = useLocation();

  const timeoutRef = React.useRef(null);

  useEffect(() => {
    window.clearTimeout(timeoutRef.current);
    setTenSecsOnPage(false);
    if (location.pathname === "/" || location.pathname === "/listings") {
      setNewsletter("tenant");
    } else if (location.pathname.startsWith("/landlord")) {
      setNewsletter("landlord");
    }

    timeoutRef.current = window.setTimeout(() => {
      setTenSecsOnPage(true);
    }, NEWSLETTER_TIMEOUT);

    return () => window.clearTimeout(timeoutRef.current);
  }, [location.pathname]);

  const [cookies, setCookie] = useCookies(["ns_newsletter_opened"]);

  useEffect(() => {
    if (
      location.pathname === "/" &&
      tenSecsOnPage &&
      !isAuthenticated &&
      cookies.ns_newsletter_opened !== "true"
    ) {
      setNewsletterDialogOpen(true);
    }
  }, [tenSecsOnPage, isAuthenticated, cookies, location.pathname]);

  const closeDialog = () => {
    setNewsletterDialogOpen(false);
    setCookie("ns_newsletter_opened", "true", {
      expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 365),
    });
  };

  return (
    <>
      <NestiqaAlertDialog
        {...dialogControls}
        onClose={() => setDialogControls({ open: false })}
      />
      <Dialog open={newsletterDialogOpen} onClose={closeDialog}>
        <DialogTitle>{GLOBALS.MESSAGES.NEWSLETTER_DIALOG_TITLE}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {newsletter === "tenant"
              ? GLOBALS.MESSAGES.NEWSLETTER_DIALOG_TENANT
              : newsletter === "landlord"
              ? GLOBALS.MESSAGES.NEWSLETTER_DIALOG_LANDLORD
              : ""}
          </DialogContentText>

          <Formik
            initialValues={{ email: "" }}
            validationSchema={validationSchema}
            onSubmit={(values, formikBag) => {
              callNewsletterSignup(values.email);
            }}
          >
            {formikprops => (
              <Form className={classes.dialogForm}>
                <Field
                  placeholder="Email"
                  component={TextField}
                  required
                  name={`email`}
                  type="email"
                  variant="outlined"
                  size="small"
                  inputProps={{
                    autoComplete: "off",
                  }}
                />

                {loading && (
                  <LinearProgress className={classes.linearProgress} />
                )}

                <div className={classes.dialogAction}>
                  <Button
                    disabled={loading}
                    onClick={() => {
                      closeDialog();
                    }}
                  >
                    No Thanks
                  </Button>
                  <Button
                    disabled={loading}
                    variant="contained"
                    color="primary"
                    onClick={formikprops.submitForm}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
}
