import React, { useState } from "react";
import Container from "@material-ui/core/Container";
import { Button } from "@material-ui/core";
import { cancelPlan, purchaseInquiry } from "../utils/TenantApi";
import { resetReports } from "../utils/ReportsApi";
import { resetReportsLandlord } from "../utils/LandlordApi";
import useAuth0Nesitqa from "../hooks/useAuth0Nestiqa";
import NestiqaAlertDialog from "../components/NestiqaAlertDialog";
import RequireLogin from "../components/RequireLogin";
import { newsletterSignup } from "../utils/UserApi";

export default function UserAdministrationPage() {
  const { accessToken, isAuthenticated, loginWithRedirect } =
    useAuth0Nesitqa("");
  const [dialogControls, setDialogControls] = useState({
    open: false,
    title: "",
    message: "",
  });

  function resultsCallback(isSuccess, result, error) {
    console.log("Success: " + isSuccess);
    if (isSuccess) {
      setDialogControls({
        open: true,
        title: "Success",
        message: "Operation successful",
      });
    } else {
      setDialogControls({
        open: true,
        title: "Error",
        message: "Operation failed",
      });
    }
  }

  async function callCancelPlan() {
    cancelPlan(accessToken, resultsCallback);
  }

  async function callResetReports() {
    resetReports(accessToken, resultsCallback);
  }

  async function callResetReportsLandlord() {
    resetReportsLandlord(accessToken, resultsCallback);
  }

  async function callNewsletterSignup() {
    newsletterSignup('tenant','support@nestiqa.com', resultsCallback);
  }

  async function callPurchaseInquiry() {
    purchaseInquiry(accessToken, resultsCallback);
  }

  function handleLogin() {
    localStorage.setItem("previousLocation", window.location.pathname);
    loginWithRedirect();
  }

  if (!isAuthenticated) {
    return (
      <Container>
        <RequireLogin login={handleLogin} />
      </Container>
    );
  }

  return (
    <Container maxWidth="lg">
      <NestiqaAlertDialog
        {...dialogControls}
        onClose={() => setDialogControls({ open: false })}
      />
      <Button onClick={callCancelPlan}>Cancel Plan</Button>
      <Button onClick={callResetReports}>Reset Reports</Button>
      <Button onClick={callResetReportsLandlord}>Reset Reports Landlord</Button>
      <Button onClick={callNewsletterSignup}>Newsletter signup</Button>
      <Button onClick={callPurchaseInquiry}>Purchase Inquiry</Button>
    </Container>
  );
}
