import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { GLOBALS } from "../App";
import useAuth0Nesitqa from "../hooks/useAuth0Nestiqa";
import { useDispatch, useSelector } from "react-redux";
import { clearReports } from "../store/actions/reportsActions";
import { getActiveUser } from "../store/selectors/userSelectors";
import { clearUser } from "../store/actions/userActions";

const useStyles = makeStyles(theme => ({
  loginSection: {
    width: "69px",
    display: "flex",
    justifyContent: "flex-end",

    [theme.breakpoints.down("xs")]: {
      marginRight: "-15px",
    },
  },
  landlordTypograph: {
    color: theme.palette.primary.main,
  },
  landlordButton: {
    color: theme.palette.primary.dark,
    padding: "12px 8px",
  },
  loginButton: {
    color: theme.palette.primary.dark,
    lineHeight: "1.57",
    letterSpacing: "0.00714em",
    whiteSpace: "nowrap",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.main,
    textTransform: "capitalize",
  },
  landlordLink: {
    textDecoration: "none",
    color: theme.palette.primary.dark,
  },
}));

const UserMenu = ({ handleMenuRedirect, isLandlordRoute }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const activeUser = useSelector(getActiveUser);
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up("lg"));
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0Nesitqa("");
  const [anchorEl, setAnchorEl] = useState(null);
  const menuList = ["profile", "applications"];
  const location = useLocation();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    const returnToPath = location.pathname.includes("/landlord")
      ? "/landlord"
      : "";
    dispatch(clearUser());
    dispatch(clearReports());
    handleClose();
    localStorage.removeItem("ns_free_consultation_declined");
    logout({ returnTo: GLOBALS.APP_BASE_URL + returnToPath });
  };

  const redirect = tenantPath => {
    handleMenuRedirect(tenantPath);
    handleClose();
  };

  function handleLogin() {
    localStorage.setItem("previousLocation", window.location.pathname);
    loginWithRedirect();
  }

  if (isAuthenticated) {
    return (
      <div className={classes.loginSection}>
        <Button
          aria-controls="user-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <Avatar
            alt="User Avatar"
            src={activeUser ? activeUser.picture : ""}
          />
        </Button>
        <Menu
          id="user-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {!isLandlordRoute &&
            (isDesktop
              ? null
              : [
                  menuList.map(item => (
                    <MenuItem onClick={() => redirect(item)}>
                      <Typography variant="subtitle1" className={classes.link}>
                        my {item}
                      </Typography>
                    </MenuItem>
                  )),
                ])}
          <MenuItem onClick={handleClose}>
            <Typography
              variant="subtitle1"
              className={classes.landlordTypograph}
            >
              <Link
                to={GLOBALS.PATHS.USER_SAVED_SEARCHES_PAGE}
                component={RouterLink}
                variant="subtitle1"
                className={classes.link}
                underline="none"
              >
                My Saved Searches
              </Link>
            </Typography>
          </MenuItem>
          {!isLandlordRoute && (
            <MenuItem onClick={handleClose}>
              <Typography
                variant="subtitle1"
                className={classes.landlordTypograph}
              >
                <Link
                  to={GLOBALS.PATHS.LANDLORD}
                  component={RouterLink}
                  variant="subtitle1"
                  className={classes.link}
                  underline="none"
                >
                  Switch to Landlord View
                </Link>
              </Typography>
            </MenuItem>
          )}
          {isLandlordRoute && (
            <MenuItem onClick={handleClose}>
              <Typography
                variant="subtitle1"
                className={classes.landlordTypograph}
              >
                <Link
                  to={GLOBALS.PATHS.ROOT}
                  component={RouterLink}
                  variant="subtitle1"
                  className={classes.link}
                  underline="none"
                >
                  Switch to Tenant View
                </Link>
              </Typography>
            </MenuItem>
          )}
          <MenuItem onClick={handleSignOut}>
            <Typography variant="subtitle1" className={classes.link}>
              Sign Out
            </Typography>
          </MenuItem>
        </Menu>
      </div>
    );
  } else {
    return (
      <div className={classes.loginSection}>
        <Button className={classes.loginButton} onClick={() => handleLogin()}>
          Sign In
        </Button>
      </div>
    );
  }
};

export default UserMenu;
