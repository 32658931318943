import React from "react";
import ListingDetails from "../ListingDetails";
import ListingFormControls from "./ListingFormControls";
import { GLOBALS } from "../../App";
import * as yup from "yup";
import { Typography } from "@material-ui/core";

export default function ListingPreview(props) {
  const { listing, step, setStep } = props;

  function onStepSubmit() {
    setStep(step + 1);
  }

  function handleNext(formikprops) {
    formikprops.submitForm();
  }

  const validationSchema = yup.object().shape({});

  return (
    <ListingFormControls
      listing={listing}
      step={step}
      setStep={setStep}
      onSubmit={onStepSubmit}
      validationSchema={validationSchema}
      handleNext={handleNext}
    >
      <Typography
        variant="subtitle1"
        align="center"
        color="primary"
        style={{ marginBottom: "20px" }}
      >
        {GLOBALS.SITE_TEXTS.PREVIEW_TEXT_STEP}
      </Typography>

      <ListingDetails listing={listing} preview />
    </ListingFormControls>
  );
}
