import React from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import App from "./App";
import theme from "./theme";
import { Auth0Provider } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { GLOBALS } from "./App";
import { Provider } from "react-redux";
import store from "./store/store";
import initGa4React from "./utils/initGa4React";
import { CookiesProvider } from "react-cookie";

if (GLOBALS.SENTRY_DSN) {
  Sentry.init({
    dsn: GLOBALS.SENTRY_DSN,
    environment: GLOBALS.SENTRY_ENV || "dev",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1,
    release: GLOBALS.FRONTEND_VERSION || "unknown",
  });
}

(async () => {
  await initGa4React();

  ReactDOM.render(
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Auth0Provider
          domain={GLOBALS.AUTH0_DOMAIN}
          clientId={GLOBALS.AUTH0_CLIENT_ID}
          redirectUri={GLOBALS.APP_BASE_URL + GLOBALS.PATHS.REDIRECT_USER}
          audience={GLOBALS.AUTH0_MANAGEMENT_API_ENDPOINT}
        >
          <CookiesProvider>
            <App />
          </CookiesProvider>
        </Auth0Provider>
      </ThemeProvider>
    </Provider>,
    document.querySelector("#root")
  );
})();
