import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import StyledTableCell from "../StyledTableCell";

const useStyles = makeStyles(theme => ({
  table: {
    width: "100%",
  },
  noBorder: {
    border: "none",
  },
  bottomBorder: {
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
  },
  padding: {
    paddingRight: theme.spacing(2),
  },
  paddingNone: {
    paddingRight: theme.spacing(0),
  },
  phoneWidth: {
    minWidth: "116px",
  },
}));

const CreateTenantTable = ({
  columns = [],
  rows = [],
  isHousehold = false,
}) => {
  const classes = useStyles();

  return (
    <>
      <Table size="small" className={classes.table}>
        <TableHead>
          <TableRow>
            {columns.map((col, i) => (
              <StyledTableCell
                key={col.field}
                className={clsx(classes.padding, classes.noBorder)}
                align={i === columns.length - 1 ? "right" : "left"}
              >
                {col.headerName}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => (
            <TableRow key={i}>
              {row.map((cell, j) => {
                return (
                  <StyledTableCell
                    key={cell.field}
                    align={j === columns.length - 1 ? "right" : "left"}
                    className={clsx(
                      cell.field !== "actions" && classes.padding,
                      ["landlord", "contact"].includes(cell.field) &&
                        classes.phoneWidth,
                      j === 0 && classes.paddingNone,
                      classes.bottomBorder
                    )}
                  >
                    {cell.value}
                  </StyledTableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default CreateTenantTable;
