import React, { useState } from "react";
import clsx from "clsx";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import PhoneNumberField from "../PhoneNumberField";
import { KeyboardDatePicker } from "formik-material-ui-pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import InputAdornment from "@material-ui/core/InputAdornment";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import CakeIcon from "@material-ui/icons/Cake";
import WorkIcon from "@material-ui/icons/WorkOutline";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Link from "@material-ui/core/Link";
import DateFnsUtils from "@date-io/date-fns";
import { GLOBALS } from "../../App";
import TenantFormControls from "./TenantFormControls";
import * as yup from "yup";

import { updateTenant, setTenantPicture } from "../../utils/TenantApi";
import tenantPicture from "./../tenant_picture.png";
import { useMediaQuery } from "@material-ui/core";
import { logEvent } from "../../utils/initGa4React";
import { useGA4React } from "ga-4-react";

const useStyles = makeStyles(theme => ({
  datePicker: {},
  inputField: {
    width: "100%",

    "& .MuiInputLabel-root": {
      color: theme.palette.grey[400],
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      maxWidth: `calc(100% - 28px)`,
    },

    "& .MuiInputLabel-shrink": {
      maxWidth: `100%`,
      color: theme.palette.primary.main,
    },

    "& a": {
      height: "24px",
    },
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "auto 1fr 1fr",
    gridTemplateAreas: `
      "image firstName dob"
      "image lastName email"
      "image occupation phone"
      "bio bio bio"
      "social social social"
    `,
    columnGap: theme.spacing(2),
  },
  firstName: {
    gridArea: "firstName",
    display: "flex",
  },
  twoThirds: {
    minWidth: `calc(66% - ${theme.spacing(2)}px)`,
    marginRight: theme.spacing(2),
  },
  middleName: {
    gridArea: "middleName",
  },
  lastName: {
    gridArea: "lastName",
  },
  occupation: {
    gridArea: "occupation",
  },
  dob: {
    gridArea: "dob",
  },
  email: {
    gridArea: "email",
  },
  phone: {
    gridArea: "phone",
  },
  bio: {
    gridArea: "bio",
  },
  socialField: {
    width: "100%",
  },
  socialFields: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridColumnGap: theme.spacing(2),
  },
  social: {
    gridArea: "social",
  },
  image: {
    width: "216px",
    height: "216px",
    gridArea: "image",
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: "5px",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    backgroundColor: "#fff",

    "& label": {
      cursor: "pointer",
      width: "100%",
      height: "100%",
    },

    "& img": {
      objectFit: "cover",
      objectPosition: "center",
      width: "100%",
      height: "100%",
    },

    "& input": {
      display: "none",
    },
  },
  [theme.breakpoints.down("md")]: {
    grid: {
      paddingTop: props =>
        props.isEditDialog ? theme.spacing(18) : theme.spacing(8),
      gridTemplateColumns: "1fr 1fr",
      gridTemplateAreas: `
      "firstName dob"
      "lastName email"
      "occupation phone"
      "bio bio"
      "social social"
      `,
      position: "relative",
    },
    image: {
      position: "absolute",
      width: "160px",
      height: "160px",
      top: props => (props.isEditDialog ? "-40px" : "-120px"),
      left: "50%",
      marginLeft: "-80px",
    },
    root: {
      marginTop: props =>
        props.isEditDialog ? "0px" : `${theme.spacing(10)}px !important`,
    },
    socialFields: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
  },
  [theme.breakpoints.down("xs")]: {
    image: {
      position: "absolute",
      width: "140px",
      height: "140px",
      top: props => (props.isEditDialog ? "-20px" : "-100px"),
      left: "50%",
      marginLeft: "-70px",
    },
    grid: {
      gridTemplateColumns: "1fr",
      gridTemplateAreas: `
      "firstName"
      "middleName"
      "lastName"
      "occupation"
      "dob"
      "email"
      "phone"
      "bio"
      "social"
      `,
      position: "relative",
    },
    socialFields: {
      gridTemplateColumns: "1fr",
    },
  },
}));

export default function TenantPersonalInfo(props) {
  const {
    accessToken,
    tenant,
    setTenant,
    step,
    setStep,
    showError,
    isEditDialog = false,
    closeDialog,
  } = props;

  const classes = useStyles(props);
  const ga = useGA4React();

  function onStepSubmit(values, formikBag) {
    const updatedValues = {
      id: values.id,
      firstName: values.firstName,
      lastName: values.lastName,
      middleName: values.middleName,
      email: values.email,
      phoneNumber: values.phoneNumber,
      dob: values.dob,
      bio: values.bio,
      occupation: values.occupation,
      facebookId: values.facebookId,
      instagramId: values.instagramId,
      twitterId: values.twitterId,
      linkedinUrl: values.linkedinUrl,
    };

    if (!!ga) {
      logEvent("tenant_complete_step", { step: step });
    }

    updateTenant(
      updatedValues,
      accessToken,
      (isSuccess, apiResult, apiError) => {
        if (isSuccess) {
          setTenant(apiResult);
          if (isEditDialog) {
            closeDialog();
          } else {
            setStep(step + 1);
          }
        } else {
          console.error(apiError);
          showError(GLOBALS.MESSAGES.ERROR_UPDATING_TENANT);
          formikBag.setSubmitting(false);
        }
      }
    );
  }

  function handleNext(formikprops) {
    if (formikprops.dirty) {
      formikprops.submitForm();
    } else {
      if (isEditDialog) {
        closeDialog();
      } else {
        setStep(step + 1);
      }
    }
  }

  const [profilePicture, setProfilePicture] = useState(null);

  function handleImageUpload(e) {
    const [file] = e.target.files;

    setTenantPicture(
      file,
      tenant.id,
      accessToken,
      (isSuccess, apiResult, apiError) => {
        if (isSuccess) {
          setProfilePicture(apiResult.picture);
          setTenant(tenant => ({ ...tenant, ...apiResult }));
        }
      }
    );
  }

  const validationSchema = yup.object().shape({
    firstName: yup
      .string()
      .min(2)
      .max(50)
      .matches(GLOBALS.LEGAL_NAME_REGEX, GLOBALS.MESSAGES.ONLY_ALPHA)
      .required(GLOBALS.MESSAGES.FIELD_REQUIRED)
      .label("First Name"),
    middleName: yup
      .string()
      .max(50)
      .matches(GLOBALS.LEGAL_NAME_REGEX, GLOBALS.MESSAGES.ONLY_ALPHA)
      .label("Middle Name"),
    lastName: yup
      .string()
      .min(2)
      .max(50)
      .matches(GLOBALS.LEGAL_NAME_REGEX, GLOBALS.MESSAGES.ONLY_ALPHA)
      .required(GLOBALS.MESSAGES.FIELD_REQUIRED)
      .label("Last Name"),
    email: yup
      .string()
      .email(GLOBALS.MESSAGES.ENTER_VALID_EMAIL)
      .required(GLOBALS.MESSAGES.FIELD_REQUIRED),
    phoneNumber: yup
      .string()
      .matches(GLOBALS.PHONE_NUMBER_REGEX, GLOBALS.MESSAGES.ENTER_VALID_PHONE)
      .required(),
    dob: yup.date().typeError(GLOBALS.MESSAGES.ENTER_VALID_DATE),
    facebookId: yup
      .string()
      .matches(
        GLOBALS.SOCIAL_USERNAME_REGEX,
        GLOBALS.MESSAGES.SOCIAL_USERNAME_VALIDATION_MSG.replace(
          "[social]",
          "Facebook"
        )
      ),
    instagramId: yup
      .string()
      .matches(
        GLOBALS.SOCIAL_USERNAME_REGEX,
        GLOBALS.MESSAGES.SOCIAL_USERNAME_VALIDATION_MSG.replace(
          "[social]",
          "Instagram"
        )
      ),
    twitterId: yup
      .string()
      .matches(
        GLOBALS.SOCIAL_USERNAME_REGEX,
        GLOBALS.MESSAGES.SOCIAL_USERNAME_VALIDATION_MSG.replace(
          "[social]",
          "Twitter"
        )
      ),
    linkedinUrl: yup
      .string()
      .matches(GLOBALS.VALID_URL_REGEX, GLOBALS.MESSAGES.INVALID_URL_MESSAGE),
  });

  return (
    <TenantFormControls
      tenant={tenant}
      step={step}
      setStep={setStep}
      onSubmit={onStepSubmit}
      validationSchema={validationSchema}
      handleNext={handleNext}
      isEditDialog={isEditDialog}
      closeDialog={closeDialog}
      className={classes.root}
    >
      <PersonalInfoFormikWrapper
        tenant={tenant}
        profilePicture={profilePicture}
        handleImageUpload={handleImageUpload}
        isEditDialog={isEditDialog}
      />
    </TenantFormControls>
  );
}

const PersonalInfoFormikWrapper = ({ formikprops, ...props }) => {
  const { tenant, profilePicture, handleImageUpload } = props;
  const classes = useStyles(props);
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));

  function getMaxDate() {
    const MIN_AGE = GLOBALS.TENANT_MIN_AGE;
    let date = new Date();
    date.setFullYear(date.getFullYear() - MIN_AGE);
    return date;
  }

  return (
    <div className={classes.grid}>
      {/* First Name */}

      <div className={classes.image}>
        <input
          type="file"
          id="imgUpload"
          accept="image/*"
          multiple={false}
          onChange={handleImageUpload}
        />
        <label htmlFor="imgUpload">
          <img
            src={
              profilePicture
                ? profilePicture
                : tenant.picture !== null
                ? tenant.picture
                : `${tenantPicture}`
            }
            alt={tenant.firstName}
          />
        </label>
      </div>

      {isMobile ? (
        <>
          <Field
            className={clsx(classes.inputField, classes.firstName)}
            component={TextField}
            required
            name="firstName"
            type="text"
            label="First Name"
            variant="outlined"
            margin="normal" //Check if margins are needed or covered by classes
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <PersonOutlineIcon color="secondary" />
                </InputAdornment>
              ),
            }}
          />

          {/* Middle Name */}

          <Field
            className={clsx(classes.inputField, classes.middleName)}
            component={TextField}
            type="text"
            label="Middle Name"
            name="middleName"
            variant="outlined"
            margin="normal"
            InputProps={{
              endAdornment: isMobile ? (
                <InputAdornment position="end">
                  <PersonOutlineIcon color="secondary" />
                </InputAdornment>
              ) : null,
            }}
          />
        </>
      ) : (
        <div className={classes.firstName}>
          <Field
            className={clsx(classes.inputField, classes.twoThirds)}
            component={TextField}
            required
            name="firstName"
            type="text"
            label="First Name"
            variant="outlined"
            margin="normal" //Check if margins are needed or covered by classes
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <PersonOutlineIcon color="secondary" />
                </InputAdornment>
              ),
            }}
          />

          {/* Middle Name */}

          <Field
            className={clsx(classes.inputField)}
            component={TextField}
            type="text"
            label="Middle Name"
            name="middleName"
            variant="outlined"
            margin="normal"
          />
        </div>
      )}

      {/* Last Name */}

      <Field
        className={clsx(classes.inputField, classes.lastName)}
        component={TextField}
        required
        name="lastName"
        type="text"
        label="Last Name"
        variant="outlined"
        margin="normal"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <PersonOutlineIcon color="secondary" />
            </InputAdornment>
          ),
        }}
      />

      {/* Occupation */}

      <Field
        className={clsx(classes.inputField, classes.occupation)}
        component={TextField}
        type="text"
        label="Occupation"
        name="occupation"
        variant="outlined"
        margin="normal"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <WorkIcon color="secondary" />
            </InputAdornment>
          ),
        }}
      />

      {/* DOB */}

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Field
          className={clsx(classes.inputField, classes.dob, classes.datePicker)}
          component={KeyboardDatePicker}
          required
          name="dob"
          label="Date of Birth"
          format="MM/dd/yyyy"
          disableFuture
          inputVariant="outlined"
          variant="inline"
          margin="normal"
          autoOk
          maxDate={getMaxDate()}
          keyboardIcon={<CakeIcon color="secondary" />}
          KeyboardButtonProps={{ edge: "end" }}
        />
      </MuiPickersUtilsProvider>

      {/* Email */}

      <Field
        className={clsx(classes.inputField, classes.email)}
        component={TextField}
        required
        name="email"
        type="email"
        label="E-mail"
        variant="outlined"
        margin="normal"
        disabled={props.tenant.email}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <MailOutlineIcon color="secondary" />
            </InputAdornment>
          ),
        }}
      />

      {/* Phone */}

      <Field
        className={clsx(classes.inputField, classes.phone)}
        component={PhoneNumberField}
        required
        name="phoneNumber"
        type="text"
        label="Phone Number"
        variant="outlined"
        margin="normal"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <PhoneIphoneIcon color="secondary" />
            </InputAdornment>
          ),
        }}
      />

      {/* Bio */}

      <Field
        className={clsx(classes.inputField, classes.bio)}
        component={TextField}
        name="bio"
        type="text"
        label="About Me"
        placeholder="I’ve just finished college and moving in with my partner. We are both quiet and clean…"
        variant="outlined"
        margin="normal"
        multiline
        rows={6}
      />

      {/* Social */}
      <div className={classes.social}>
        <div className={clsx(classes.socialFields)}>
          <Tooltip
            title={GLOBALS.MESSAGES.FACEBOOK_TOOLTIP_TEXT}
            placement="top-start"
          >
            <div>
              <Field
                className={clsx(classes.inputField)}
                component={TextField}
                name="facebookId"
                type="text"
                label="Facebook Username"
                variant="outlined"
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {formikprops.values.facebookId ? (
                        <Link
                          component="a"
                          underline="none"
                          href={`https://www.facebook.com/${formikprops.values.facebookId}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FacebookIcon color="secondary" />
                        </Link>
                      ) : (
                        <FacebookIcon color="secondary" />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </Tooltip>

          <Tooltip
            title={GLOBALS.MESSAGES.INSTAGRAM_TOOLTIP_TEXT}
            placement="top-start"
          >
            <div>
              <Field
                className={clsx(classes.inputField)}
                component={TextField}
                name="instagramId"
                type="text"
                label="Instagram Username"
                variant="outlined"
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {formikprops.values.instagramId ? (
                        <Link
                          component="a"
                          underline="none"
                          href={`https://www.instagram.com/${formikprops.values.instagramId}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <InstagramIcon color="secondary" />
                        </Link>
                      ) : (
                        <InstagramIcon color="secondary" />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </Tooltip>

          <Tooltip
            title={GLOBALS.MESSAGES.TWITTER_TOOLTIP_TEXT}
            placement="top-start"
          >
            <div>
              <Field
                className={clsx(classes.inputField)}
                component={TextField}
                name="twitterId"
                type="text"
                label="Twitter Username"
                variant="outlined"
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {formikprops.values.twitterId ? (
                        <Link
                          component="a"
                          underline="none"
                          href={`https://www.twitter.com/${formikprops.values.twitterId}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <TwitterIcon color="secondary" />
                        </Link>
                      ) : (
                        <TwitterIcon color="secondary" />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </Tooltip>

          <Tooltip
            title={GLOBALS.MESSAGES.LINKEDIN_TOOLTIP_TEXT}
            placement="top-start"
          >
            <div>
              <Field
                className={clsx(classes.inputField)}
                component={TextField}
                name="linkedinUrl"
                type="text"
                label="LinkedIn URL"
                variant="outlined"
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {formikprops.values.linkedinUrl ? (
                        <Link
                          component="a"
                          underline="none"
                          href={`${formikprops.values.linkedinUrl}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <LinkedInIcon color="secondary" />
                        </Link>
                      ) : (
                        <LinkedInIcon color="secondary" />
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
