import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TenantProfileSection from "./TenantProfileSection";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  bottomBorder: {
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
  },
  detailsRow: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),

    "& h6": {
      fontSize: "1.1rem",
    },
  },
}));

const TenantAdditionalInformationSection = ({
  pets,
  vehicles,
  isEditable = true,
  openEditDialog,
}) => {
  const onEditButtonClick = () => {
    openEditDialog(6);
  };

  return (
    <TenantProfileSection
      title="Additional Information"
      onEditButtonClick={onEditButtonClick}
      isEditable={isEditable}
    >
      <AdditionalInformationContent pets={pets} vehicles={vehicles} />
    </TenantProfileSection>
  );
};

export const AdditionalInformationContent = ({ pets, vehicles }) => {
  const classes = useStyles();

  return (
    <>
      <div className={clsx(classes.detailsRow, classes.bottomBorder)}>
        <Typography variant="h6">Pets</Typography>
        <Typography variant="body2">{pets || "None"}</Typography>
      </div>
      <div className={clsx(classes.detailsRow, classes.bottomBorder)}>
        <Typography variant="h6">Vehicles</Typography>
        <Typography variant="body2">{vehicles || "None"}</Typography>
      </div>
    </>
  );
};

export default TenantAdditionalInformationSection;
