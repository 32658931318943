import React from "react";

const FacebookSquare = props => {
  return (
    <svg
      xmlns="true"
      width="26.066"
      height="24.82"
      viewBox="0 0 26.066 24.82"
      {...props}
    >
      <g id="facebook_1_" transform="translate(0 0.001)">
        <path
          id="Facebook-Square-Icon"
          d="M12.973,24.82H4a4,4,0,0,1-4-4V4A4,4,0,0,1,4,0H22.065a4,4,0,0,1,4,4V20.82a4,4,0,0,1-4,4H16.743V16.168h3.146l.472-3.478H16.743V10.474c0-1.014.307-1.689,1.806-1.689h1.918v-3.1a27.055,27.055,0,0,0-2.811-.137,4.3,4.3,0,0,0-4.684,4.585v2.56H9.838v3.478h3.135v8.652Z"
          transform="translate(0 -0.001)"
          fill="#3ce0e5"
        />
      </g>
    </svg>
  );
};

export default FacebookSquare;
