import { Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { GLOBALS } from "../../App";
import { callGetAvailableReports } from "../../store/actions/reportsActions";
import * as yup from "yup";

import { submitInfo } from "../../utils/ReportsApi";
import { TextField } from "formik-material-ui";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
  },
  ssnField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    display: "block",
  },
}));

const NeedsInfo = ({ accessToken, showError, tenantFullName }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const submitInfoCalback = (isSuccess, result, error) => {
    if (isSuccess) {
      dispatch(callGetAvailableReports(accessToken));
    }
    if (error) {
      showError();
      console.error(error);
    }
  };

  async function callSubmitInfo(ssn) {
    const formatedSSN =
      ssn.substr(0, 3) + "-" + ssn.substr(3, 2) + "-" + ssn.substr(5);
    submitInfo(accessToken, formatedSSN, submitInfoCalback);
  }

  const validationSchema = yup.object().shape({
    ssn: yup
      .string()
      .required(GLOBALS.MESSAGES.FIELD_REQUIRED)
      .matches(GLOBALS.SSN_WITHOUT_DASHES_REGEX, GLOBALS.MESSAGES.SSN_ERROR),
    verifySsn: yup
      .string()
      .required(GLOBALS.MESSAGES.FIELD_REQUIRED)
      .equals([yup.ref("ssn")], GLOBALS.MESSAGES.VERIFY_SSN_ERROR),
  });

  return (
    <div className={classes.root}>
      <Typography gutterBottom>
        {GLOBALS.MESSAGES.NEED_INFO_NOTE.replace("[fullname]", tenantFullName)}
      </Typography>
      <Formik
        initialValues={{ ssn: "", verifySsn: "" }}
        validationSchema={validationSchema}
        onSubmit={(values, formikBag) => {
          callSubmitInfo(values.ssn);
        }}
      >
        {formikprops => (
          <Form className={classes.dialogRow}>
            <Typography variant="body1">
              {GLOBALS.MESSAGES.PROVIDE_SSN}
            </Typography>
            <Field
              component={TextField}
              required
              name={`ssn`}
              variant="outlined"
              size="small"
              type="password"
              inputProps={{
                autoComplete: "off",
              }}
              className={classes.ssnField}
              onChange={e =>
                // Allow max 9 characters
                formikprops.setFieldValue("ssn", e.target.value.slice(0, 9))
              }
            />
            <Typography variant="body1">
              {GLOBALS.MESSAGES.VERIFY_SSN}
            </Typography>
            <Field
              component={TextField}
              required
              name={`verifySsn`}
              variant="outlined"
              size="small"
              type="password"
              inputProps={{
                autoComplete: "off",
              }}
              className={classes.ssnField}
              onChange={e =>
                formikprops.setFieldValue(
                  "verifySsn",
                  e.target.value.slice(0, 9)
                )
              }
            />
            <Button
              color="primary"
              variant="contained"
              onClick={formikprops.submitForm}
            >
              Continue
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NeedsInfo;
