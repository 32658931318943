import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { GLOBALS } from "../../App";

const useStyles = makeStyles(theme => ({
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    minWidth: "600px",

    [theme.breakpoints.down("xs")]: {
      minWidth: "unset",
    },
  },
  section: {
    "& > .MuiTypography-root": {
      fontSize: "1.05rem",
    },
  },
  description: {
    marginTop: "-5px",
    marginBottom: "15px",

    "& > .MuiTypography-root": {
      fontSize: "16px",
    },
  },
}));

const ProgressIndicatorDialog = ({
  open,
  onClose,
  title,
  mandatory,
  niceToHave,
}) => {
  const classes = useStyles();
  const { MANDATORY, NICE_TO_HAVE } = GLOBALS.MESSAGES.PROGRESS_BAR;
  const profileComplete =
    mandatory && !mandatory.length && niceToHave && !niceToHave.length;

  const getMessageLabel = (obj, el) => {
    for (const [key, value] of Object.entries(obj)) {
      if (key === el) return value;
    }
  };

  const incomplete = (
    <>
      {mandatory && !mandatory.length && !!niceToHave.length && (
        <div className={classes.description}>
          <Typography variant="body2">
            {GLOBALS.MESSAGES.PROFILE_MANDATORY_COMPLETE}
          </Typography>
        </div>
      )}
      {mandatory && !!mandatory.length && (
        <div className={classes.section}>
          <Typography variant="body1">Mandatory:</Typography>
          <ul>
            {mandatory.map(el => (
              <li key={el}>{getMessageLabel(MANDATORY, el)}</li>
            ))}
          </ul>
        </div>
      )}
      {niceToHave && niceToHave.length && (
        <div className={classes.section}>
          <Typography variant="body1">Nice to have:</Typography>
          <ul>
            {niceToHave.map(el => (
              <li key={el}>{getMessageLabel(NICE_TO_HAVE, el)}</li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
  const complete = <Typography variant="body1">No actions needed</Typography>;

  return (
    <Dialog
      open={open || false}
      onClose={onClose}
      aria-labelledby="progress-indicator-dialog"
      aria-describedby="progress-indicator-dialog-description"
    >
      <DialogTitle id="progress-indicator-dialog">
        {profileComplete ? "Your profile is complete" : title}
      </DialogTitle>
      <DialogContent>
        <div className={classes.contentWrapper}>
          {profileComplete ? complete : incomplete}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ProgressIndicatorDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  title: PropTypes.string,
  mandatory: PropTypes.arrayOf(PropTypes.string),
  toComplete: PropTypes.arrayOf(PropTypes.string),
};

export default ProgressIndicatorDialog;
