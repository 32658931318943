import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import menuIcon from "./menu.png";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";
import { GLOBALS } from "../App";

const useStyles = makeStyles(theme => ({
  root: {
    display: "none",

    [theme.breakpoints.down("md")]: {
      display: "flex",
      marginLeft: "30px",
    },

    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.main,
  },
}));

export default function PagesMenu({ isLandlordRoute }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const landlordOptions = [
    {
      text: "My Listings",
      route: GLOBALS.PATHS.LANDLORD,
    },
    {
      text: "Open Applications",
      route: GLOBALS.PATHS.LANDLORD_ALL_APPS_PAGE,
    },
    {
      text: "Tenant View",
      route: GLOBALS.PATHS.ROOT,
    },
  ];

  const tenantOptions = [
    {
      text: "Home",
      route: GLOBALS.PATHS.ROOT,
    },
    {
      text: "Listings",
      route: GLOBALS.PATHS.LISTINGS,
    },
  ];

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <Button
        aria-controls="pages-menu"
        aria-haspopup="true"
        size="small"
        onClick={handleClick}
      >
        <img src={menuIcon} alt="Menu Icon" />
      </Button>
      <Menu
        id="pages-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {isLandlordRoute
          ? [
              landlordOptions.map(item => (
                <MenuItem onClick={handleClose}>
                  <Typography variant="subtitle1">
                    <Link
                      to={item.route}
                      component={RouterLink}
                      variant="subtitle1"
                      className={classes.link}
                      underline="none"
                    >
                      {item.text}
                    </Link>
                  </Typography>
                </MenuItem>
              )),
            ]
          : [
              tenantOptions.map(item => (
                <MenuItem onClick={handleClose}>
                  <Typography variant="subtitle1">
                    <Link
                      to={item.route}
                      component={RouterLink}
                      variant="subtitle1"
                      className={classes.link}
                      underline="none"
                    >
                      {item.text}
                    </Link>
                  </Typography>
                </MenuItem>
              )),
            ]}
      </Menu>
    </div>
  );
}
