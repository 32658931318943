import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  LinearProgress,
  Typography,
  Tooltip,
  Link,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import { GLOBALS } from "../../App";
import InfoIcon from "@material-ui/icons/Info";
import ProgressIndicatorDialog from "../dialogs/ProgressIndicatorDialog";
import useProfileCompleteness from "../../hooks/useProfileCompleteness";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: "-15px",
    marginBottom: theme.spacing(9),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },

  progressRoot: {
    height: "17px",
    borderRadius: 50,

    "&:hover": {
      cursor: "pointer",
    },

    [theme.breakpoints.up("md")]: {
      maxWidth: "75vw",
      margin: "0 200px",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "75vw",
      margin: "0 auto",
    },
  },
  wrapper: {
    width: "100%",
  },
  red: {
    backgroundColor: red.A400,
  },
  themeSecondary: {
    backgroundColor: theme.palette.secondary.main,
  },
  barSecondary: {
    backgroundColor: "#EEF5F5",
  },
  title: {
    display: "flex",
    alignItems: "center",
    fontSize: "16px",
    fontWeight: 400,
    margin: "auto 0",

    "& span": {
      marginRight: "3px",
      fontSize: "22px",
      fontWeight: 500,
    },
  },
  inner: {
    position: "relative",
    width: "20%",
    height: "15px",
    background: "green",
    borderRight: "1px solid #fff",
    zIndex: 3,
  },
  noBorder: {
    borderTopRightRadius: "0 !important",
    borderBottomRightRadius: "0 !important",
  },
  label: {
    background: "#fff",
    boxShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
    textAlign: "center",
    borderRadius: "6px",
    color: "#000",
    position: "relative",
    padding: "10px",
    marginTop: "10px",

    "&:after": {
      content: "''",
      zIndex: 10,
      position: "absolute",
      bottom: "100%",
      left: "43%",
      marginTop: "-5px",
      borderWidth: "8px",
      transform: "rotate(90deg)",
      borderStyle: "solid",
      borderColor: "transparent rgba(0, 0, 0, 0.05) transparent transparent",
    },

    "&:hover": {
      cursor: "pointer",
    },

    "& .MuiTypography-root.MuiTypography-body2": {
      fontSize: "16px",
      fontWeight: 500,

      "& span": {
        fontSize: "14px",
        fontWeight: 400,
      },
    },
  },
  tooltip: {
    fontSize: "12px",
    margin: 0,
    padding: "5px",
  },
  icon: {
    fontSize: props => (props.iconSize !== "" ? props.iconSize : "1.5rem"),
    position: "absolute",
    right: "-32px",
    top: "20px",
  },
  progressWrapper: {
    marginTop: "10px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",

    [theme.breakpoints.down("md")]: {
      margin: "10px 40px 0",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "10px 30px 0",
    },
  },
  labelWrapper: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
}));

function LinearProgressWithLabel(props) {
  const classes = useStyles();

  return (
    <Box
      className={classes.progressWrapper}
      display="flex"
      alignItems="center"
      position="relative"
    >
      <Box width="100%">
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <div className={classes.labelWrapper}>
        <Box className={classes.label} minWidth={35} onClick={props.onClick}>
          <Typography variant="body2" color="textSecondary">
            {`${Math.round(props.value)}%`} <span>Complete</span>
          </Typography>
        </Box>
        <Tooltip
          interactive
          enterTouchDelay={0}
          title={
            <p className={classes.tooltip}>
              {GLOBALS.MESSAGES.TIPS_FOR_STRONG_PROFILE}{" "}
              <Link
                component="a"
                underline="none"
                color="secondary"
                href="https://resources.nestiqa.com/faq/#how-to-build-tenant-profile"
                target="_blank"
                rel="noopener noreferrer"
              >
                click here
              </Link>
            </p>
          }
        >
          <InfoIcon className={classes.icon} color="secondary" />
        </Tooltip>
      </div>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

const TenantProfileCompleteness = ({ tenant }) => {
  const { APPLICATION_SUCCESSFUL } = GLOBALS.MESSAGES;
  const classes = useStyles();
  const [messageDialogControls, setMessageDialogControls] = useState({
    open: false,
  });
  const { progress, messages } = useProfileCompleteness(tenant);

  const handleClick = () => {
    setMessageDialogControls({
      open: true,
      title: "To complete your profile:",
      mandatory: messages.mandatory,
      niceToHave: messages.niceToHave,
      message: APPLICATION_SUCCESSFUL,
      onClose: () => setMessageDialogControls({ open: false }),
    });
  };

  return (
    <>
      <ProgressIndicatorDialog {...messageDialogControls} />
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <LinearProgressWithLabel
            value={progress}
            classes={{
              root: classes.progressRoot,
              barColorPrimary:
                tenant.isComplete === "Y"
                  ? classes.themeSecondary
                  : classes.red,
              colorPrimary: classes.barSecondary,
            }}
            onClick={handleClick}
          />
        </div>
      </div>
    </>
  );
};

export default TenantProfileCompleteness;
