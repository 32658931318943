import React, { useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { Formik, Form, Field, FieldArray } from "formik";
import { TextField } from "formik-material-ui";
import {
  Typography,
  Box,
  Button,
  InputLabel,
  useMediaQuery,
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import { KeyboardDatePicker } from "formik-material-ui-pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import PhoneIphoneOutlinedIcon from "@material-ui/icons/PhoneIphoneOutlined";
import AttachMoneyOutlinedIcon from "@material-ui/icons/AttachMoneyOutlined";
import {
  DeleteOutline as DeleteIcon,
  EditOutlined as EditIcon,
} from "@material-ui/icons";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import * as yup from "yup";

import { GLOBALS } from "../../App";
import TenantFormControls from "./TenantFormControls";
import { updateHousingHistory } from "../../utils/TenantApi";
import {
  checkFormikError,
  formatter,
  getAddressFromPlace,
  sortByYesNoFieldAndDates,
} from "../../utils/common";
import PriceField from "../PriceField";
import YesNoField from "../YesNoField";
import PhoneNumberField from "../PhoneNumberField";
import CreateTenantTable from "./CreateTenantTable";
import CreateTenantDialog from "./CreateTenantDialog";
import TenantHousingHistoryAccordion from "./../tenant-profile/TenantHousingHistoryAccordion";
import { STATES } from "../../constants/states";
import ChoiceField from "../ChoiceField";
import GoogleMapsAutoComplete from "../google-maps/GoogleMapsAutocomplete";
import { logEvent } from "../../utils/initGa4React";
import { useGA4React } from "ga-4-react";

const useStyles = makeStyles(theme => ({
  topRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(4),
  },
  left: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  error: {
    fontSize: "0.75rem",
    color: "#FF2D44",
    marginTop: "3px",
  },
  addButton: {
    marginLeft: theme.spacing(4),
    whiteSpace: "nowrap",
  },
  formButtonContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginTop: theme.spacing(3),
  },
  btnContainer: {
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "column",
  },
  inputField: {
    width: "100%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),

    "& .MuiInputLabel-root": {
      color: theme.palette.grey[400],
    },

    "& .MuiInputLabel-shrink": {
      color: theme.palette.primary.main,
    },
  },
  isCurrentRowField: {
    flexBasis: "50%",
  },
  datePicker: {},
  isCurrentRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  mobileRow: {
    display: "flex",
    flexDirection: "column",
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
  },
  mobileTopRow: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  mobileBottomRow: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
  },
  mainGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateRows: "1fr 1fr",
    columnGap: theme.spacing(3),
    gridTemplateAreas: `
    "addressGrid dateGrid"
    "addressGrid landlordGrid"
    `,
  },
  dateGrid: {
    gridArea: "dateGrid",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: theme.spacing(2),
  },
  landlordGrid: {
    gridArea: "landlordGrid",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: theme.spacing(2),
    gridTemplateAreas: `
    "landlordName landlordPhoneNumber"
    "reasonForLeaving reasonForLeaving"
    `,
  },
  landlordName: {
    gridArea: "landlordName",
  },
  landlordPhoneNumber: {
    gridArea: "landlordPhoneNumber",
  },
  reasonForLeaving: {
    gridArea: "reasonForLeaving",
  },
  addressGrid: {
    gridArea: "addressGrid",
    display: "grid",
    gridTemplateColumns: "2fr 1fr",
    columnGap: theme.spacing(2),
    gridTemplateAreas: `
    "address1 address1"
    "address2 address2"
    "city city"
    "state zipCode"
    `,
  },
  address1: {
    gridArea: "address1",
  },
  address2: {
    gridArea: "address2",
  },
  city: {
    gridArea: "city",
  },
  state: {
    gridArea: "state",

    "& .MuiSelect-icon": {
      right: "40px",
    },
  },
  zipCode: {
    gridArea: "zipCode",
  },
  [theme.breakpoints.down("xs")]: {
    topRow: {
      flexDirection: "column",

      "& .MuiFormControlLabel-root": {
        display: "block",
        margin: `0 auto`,
        marginTop: theme.spacing(2),
      },
    },

    addButton: {
      marginTop: theme.spacing(2),
      marginLeft: "0px",
    },
    mainGrid: {
      gridTemplateColumns: "1fr",
      gridTemplateRows: "auto",
      gridTemplateAreas: `
      "addressGrid"
      "dateGrid"
      "landlordGrid"
    `,
    },
    landlordGrid: {
      gridTemplateColumns: "1fr",
      gridTemplateAreas: `
      "landlordName"
      "landlordPhoneNumber"
      "reasonForLeaving"
      `,
    },
    dateGrid: {
      gridTemplateColumns: "1fr",
    },
    addressGrid: {
      gridTemplateColumns: "1fr",
      gridTemplateAreas: `
      "address1"
      "address2"
      "city"
      "state"
      "zipCode"
      `,
    },
  },
}));

export default function TenantHousingHistory(props) {
  const {
    accessToken,
    tenant,
    setTenant,
    step,
    setStep,
    showError,
    currentAddressLength,
    setCurrentAddressLength,
    isEditDialog = false,
    closeDialog,
  } = props;
  const ga = useGA4React();

  function onStepSubmit(values, formikBag) {
    if (!!ga) {
      logEvent("tenant_complete_step", { step: step });
    }

    updateHousingHistory(
      values,
      accessToken,
      (isSuccess, apiResult, apiError) => {
        if (isSuccess) {
          setTenant({
            ...tenant,
            housingHistory: apiResult.housingHistory,
          });
          if (isEditDialog) {
            closeDialog();
          } else {
            setStep(step + 1);
          }
        } else {
          console.error(apiError);
          showError(GLOBALS.MESSAGES.ERROR_UPDATING_TENANT);
          formikBag.setSubmitting(false);
        }
      }
    );
  }

  function handleNext(formikprops) {
    const currentLength = formikprops.values.housingHistory.filter(
      history => history.isCurrent === "Y"
    ).length;

    if (currentLength !== 1) {
      setCurrentAddressLength({ state: currentLength, error: true });
    } else {
      setCurrentAddressLength({ state: 1, error: false });

      if (formikprops.dirty) {
        formikprops.submitForm();
      } else {
        if (isEditDialog) {
          closeDialog();
        } else {
          setStep(step + 1);
        }
      }
    }
  }

  const validationSchema = yup.object().shape({
    housingHistory: yup.array().of(
      yup.object().shape({
        startDate: yup
          .date()
          .required()
          .typeError(GLOBALS.MESSAGES.ENTER_VALID_DATE),
        endDate: yup
          .mixed()
          .nullable()
          .when("isCurrent", {
            is: "N",
            then: yup
              .date()
              .required(GLOBALS.MESSAGES.END_DATE_OR_CURRENT_REQUIRED),
          }),
        address1: yup.string().required(GLOBALS.MESSAGES.FIELD_REQUIRED),
        address2: yup.string(),
        city: yup.string(),
        state: yup.string(),
        zipCode: yup.string(),
        rentAmount: yup.string().required(GLOBALS.MESSAGES.FIELD_REQUIRED),
        isCurrent: yup.string().required(GLOBALS.MESSAGES.FIELD_REQUIRED),
        reasonForLeaving: yup
          .string()
          .max(
            GLOBALS.REASON_FOR_LEAVING_MAX,
            `${GLOBALS.MESSAGES.FIELD_MAX_LENGTH}${GLOBALS.REASON_FOR_LEAVING_MAX}`
          ),
      })
    ),
  });

  return (
    <TenantFormControls
      tenant={tenant}
      step={step}
      setStep={setStep}
      onSubmit={onStepSubmit}
      validationSchema={validationSchema}
      handleNext={handleNext}
      isEditDialog={isEditDialog}
      closeDialog={closeDialog}
    >
      <HousingHistoryFormikWrapper
        currentAddressLength={currentAddressLength}
        setCurrentAddressLength={setCurrentAddressLength}
      />
    </TenantFormControls>
  );
}

function HousingHistoryFormikWrapper({ formikprops, currentAddressLength }) {
  const classes = useStyles();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));
  const arrayHelpersRef = useRef(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const sortedHistory = useMemo(() => {
    return sortByYesNoFieldAndDates(
      formikprops.values.housingHistory,
      "isCurrent",
      "startDate",
      "endDate"
    );
  }, [formikprops.values.housingHistory]);

  const [dialogForm, setDialogForm] = useState({
    index: sortedHistory.length,
    isNew: true,
    initialValues: {
      startDate: null,
      endDate: null,
      landlordName: "",
      landlordPhoneNumber: "",
      reasonForLeaving: "",
      rentAmount: "",
      isCurrent:
        sortedHistory.reduce((p, h) => (h.isCurrent === "Y" ? p + 1 : p), 0) ===
        0
          ? "Y"
          : "N",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zipCode: "",
    },
  });

  useEffect(() => {
    setDialogForm(prev => ({
      ...prev,
      index: sortedHistory.length,
      initialValues: {
        ...prev.initialValues,
        isCurrent:
          sortedHistory.reduce(
            (p, h) => (h.isCurrent === "Y" ? p + 1 : p),
            0
          ) === 0
            ? "Y"
            : "N",
      },
    }));
  }, [sortedHistory]);

  const handleDialogClose = () => {
    setDialogOpen(false);
    setDialogForm({
      index: sortedHistory.length,
      isNew: true,
      initialValues: {
        startDate: null,
        endDate: null,
        landlordName: "",
        landlordPhoneNumber: "",
        reasonForLeaving: "",
        rentAmount: "",
        isCurrent:
          sortedHistory.reduce(
            (p, h) => (h.isCurrent === "Y" ? p + 1 : p),
            0
          ) === 0
            ? "Y"
            : "N",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipCode: "",
      },
    });
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const onRowDelete = index => {
    arrayHelpersRef.current?.remove(index);
  };

  const onRowEdit = index => {
    setDialogForm({
      index,
      isNew: false,
      initialValues: sortedHistory[index],
    });
    handleDialogOpen();
  };

  const onDialogSubmit = (isNew, index, row) => {
    if (isNew) {
      arrayHelpersRef.current.push(row);
    } else {
      arrayHelpersRef.current.replace(index, row);
    }
    handleDialogClose();
  };

  const columns = [
    {
      field: "dates",
      headerName: "Dates",
    },
    {
      field: "fullAddress",
      headerName: "Address",
    },
    {
      field: "landlord",
      headerName: "Landlord",
    },
    {
      field: "reasonForLeaving",
      headerName: "Reason for Leaving",
    },
    {
      field: "rentAmount",
      headerName: "Rent ($/mo)",
    },
    {
      field: "actions",
      headerName: "Actions",
    },
  ];

  const rows = sortedHistory.map((history, index) => {
    return [
      {
        field: "dates",
        value:
          format(new Date(history.startDate), GLOBALS.DATE_FORMAT) +
          " - " +
          (history.isCurrent === "Y"
            ? "Current"
            : history.endDate
            ? format(new Date(history.endDate), GLOBALS.DATE_FORMAT)
            : ""),
      },
      {
        field: "fullAddress",
        value: history.fullAddress || history.address1,
      },
      {
        field: "landlord",
        value: (
          <>
            {history.landlordName || "-"}
            <br />
            {history.landlordPhoneNumber || "-"}
          </>
        ),
      },
      {
        field: "reasonForLeaving",
        value: history.reasonForLeaving,
      },
      {
        field: "rentAmount",
        value: formatter.format(history.rentAmount),
      },
      {
        field: "actions",
        value: (
          <>
            <IconButton
              edge="end"
              aria-label="edit"
              onClick={() => onRowEdit(index)}
            >
              <EditIcon color="secondary" />
            </IconButton>
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => onRowDelete(index)}
            >
              <DeleteIcon color="secondary" />
            </IconButton>
          </>
        ),
      },
    ];
  });

  const housingHistory = sortedHistory.map((history, index) => ({
    ...history,
    actions: (
      <>
        <IconButton
          edge="end"
          aria-label="edit"
          onClick={() => onRowEdit(index)}
        >
          <EditIcon color="secondary" />
        </IconButton>
        <IconButton
          edge="end"
          aria-label="delete"
          onClick={() => onRowDelete(index)}
        >
          <DeleteIcon color="secondary" />
        </IconButton>
      </>
    ),
  }));

  return (
    <Box justifyContent="left" textAlign="left">
      <div className={classes.topRow}>
        <div className={classes.left}>
          <Typography variant="body1">
            {GLOBALS.MESSAGES.ADD_HOUSING_HISTORY}
          </Typography>
          {currentAddressLength.error && (
            <>
              {currentAddressLength.state === 0 ? (
                <Typography variant="body2" className={classes.error}>
                  {GLOBALS.MESSAGES.NO_CURRENT_ADDRESS_ERROR}
                </Typography>
              ) : (
                <Typography variant="body2" className={classes.error}>
                  {GLOBALS.MESSAGES.MULTIPLE_CURRENT_ADDRESS_ERROR}
                </Typography>
              )}
            </>
          )}
        </div>

        <Button
          color="primary"
          variant="contained"
          className={classes.addButton}
          onClick={handleDialogOpen}
        >
          Add Address
        </Button>
      </div>
      {isMobile ? (
        <TenantHousingHistoryAccordion housingHistory={housingHistory} />
      ) : rows.length !== 0 ? (
        <CreateTenantTable columns={columns} rows={rows} />
      ) : null}
      <FieldArray
        name="housingHistory"
        render={arrayHelpers => {
          arrayHelpersRef.current = arrayHelpers;
          return null;
        }}
      />
      <CreateTenantDialog
        isWide
        open={dialogOpen}
        title={dialogForm.isNew ? "Add Address" : "Edit Address"}
        onClose={handleDialogClose}
        form={
          <AddHousinghistoryForm
            handleDialogClose={handleDialogClose}
            onSubmit={onDialogSubmit}
            {...dialogForm}
          />
        }
      />
    </Box>
  );
}

const AddHousinghistoryForm = ({
  handleDialogClose,
  onSubmit,
  initialValues,
  index,
  isNew,
}) => {
  const classes = useStyles();

  //Separate validation for phone number to allow either empty or valid US phone
  function validatePhone(value) {
    let error;
    if (value) {
      let phoneField = yup.string().matches(
        GLOBALS.PHONE_NUMBER_REGEX,
        GLOBALS.MESSAGES.ENTER_VALID_PHONE
      );

      if (!phoneField.isValidSync(value)) {
        error = GLOBALS.MESSAGES.ENTER_VALID_PHONE;
      }
    }
    return error;
  }

  const addHousingHistoryValidationSchema = yup.object().shape({
    startDate: yup
      .date()
      .required()
      .typeError(GLOBALS.MESSAGES.ENTER_VALID_DATE),
    endDate: yup
      .mixed()
      .nullable()
      .when("isCurrent", {
        is: "N",
        then: yup
          .date()
          .required(GLOBALS.MESSAGES.END_DATE_OR_CURRENT_REQUIRED),
      }),
    rentAmount: yup.string().required(GLOBALS.MESSAGES.FIELD_REQUIRED),
    isCurrent: yup.string().required(GLOBALS.MESSAGES.FIELD_REQUIRED),
    reasonForLeaving: yup
      .string()
      .max(
        GLOBALS.REASON_FOR_LEAVING_MAX,
        `${GLOBALS.MESSAGES.FIELD_MAX_LENGTH}${GLOBALS.REASON_FOR_LEAVING_MAX}`
      ),
    address1: yup
      .string()
      .required(GLOBALS.MESSAGES.FIELD_REQUIRED)
      .min(1)
      .max(50)
      .matches(
        GLOBALS.FORBIDDEN_CHARACTERS_REGEX,
        GLOBALS.MESSAGES.FORBIDDEN_CHARACTERS
      )
      .label("Street Address"),
    address2: yup.string().max(30).label("Apartment, floor or unit"),
    city: yup.string().required(GLOBALS.MESSAGES.FIELD_REQUIRED),
    state: yup
      .string()
      .matches(GLOBALS.US_STATE_REGEX, GLOBALS.MESSAGES.ENTER_VALID_STATE),
    zipCode: yup
      .string()
      .matches(GLOBALS.US_ZIP_REGEX, GLOBALS.MESSAGES.ENTER_VALID_ZIP),
  });

  const handleAddressChange = (newPlace, manual, values, setValues) => {
    if (!newPlace) return;
    if (manual) {
      setValues({
        ...values,
        address1: newPlace.address_components[0].short_name,
      });
    } else {
      const address = getAddressFromPlace(newPlace);
      setValues({ ...values, ...address });
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={addHousingHistoryValidationSchema}
        onSubmit={(values, formikBag) => {
          onSubmit(isNew, index, {
            ...values,
          });
        }}
      >
        {formikprops => {
          const { values, setValues } = formikprops;

          return (
            <Form>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className={classes.mainGrid}>
                  <div className={classes.dateGrid}>
                    <Field
                      className={clsx(classes.inputField, classes.rentAmount)}
                      component={PriceField}
                      required
                      name={`rentAmount`}
                      type="text"
                      label="Rent ($/mo)"
                      variant="outlined"
                      margin="normal"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <AttachMoneyOutlinedIcon color="secondary" />
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        autoComplete: "off",
                      }}
                    />

                    <div className={classes.isCurrentRow}>
                      <InputLabel htmlFor="isCurrent">
                        Is this your current address?
                      </InputLabel>
                      <YesNoField
                        name={`isCurrent`}
                        className={clsx(classes.isCurrentRowField)}
                        required
                        variant="outlined"
                        margin="normal"
                        defaultValue="N"
                        label=""
                        handleBlur={formikprops.handleBlur}
                      />
                    </div>

                    <Field
                      className={clsx(
                        classes.inputField,
                        classes.startDate,
                        classes.datePicker
                      )}
                      component={KeyboardDatePicker}
                      required
                      name={`startDate`}
                      label="Start Date"
                      format="MM/dd/yyyy"
                      disableFuture
                      inputVariant="outlined"
                      variant="inline"
                      margin="normal"
                      autoOk
                      KeyboardButtonProps={{ edge: "end" }}
                      keyboardIcon={<EventOutlinedIcon color="secondary" />}
                      inputProps={{
                        autoComplete: "off",
                      }}
                      InputLabelProps={{
                        shrink: formikprops.values.startDate !== null,
                      }}
                    />
                    {formikprops.values.isCurrent === "Y" ? (
                      <div />
                    ) : (
                      <Field
                        className={clsx(
                          classes.inputField,
                          classes.endDate,
                          classes.datePicker
                        )}
                        component={KeyboardDatePicker}
                        required={formikprops.values.isCurrent === "N"}
                        disabled={formikprops.values.isCurrent === "Y"}
                        minDate={new Date(formikprops.values.startDate)}
                        minDateMessage={
                          GLOBALS.MESSAGES.END_DATE_BEFORE_START_DATE
                        }
                        name={`endDate`}
                        label="End Date"
                        format="MM/dd/yyyy"
                        disableFuture
                        inputVariant="outlined"
                        variant="inline"
                        margin="normal"
                        autoOk
                        KeyboardButtonProps={{ edge: "end" }}
                        keyboardIcon={
                          <EventOutlinedIcon
                            color={
                              formikprops.values.isCurrent === "Y" ||
                              !formikprops.values.startDate
                                ? "disabled"
                                : "secondary"
                            }
                          />
                        }
                        inputProps={{
                          autoComplete: "off",
                        }}
                        InputLabelProps={{
                          shrink: formikprops.values.endDate !== null,
                        }}
                      />
                    )}
                  </div>

                  <div className={classes.landlordGrid}>
                    <Field
                      className={clsx(classes.inputField, classes.landlordName)}
                      component={TextField}
                      name={`landlordName`}
                      type="text"
                      label="Landlord Name"
                      variant="outlined"
                      margin="normal"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <PersonOutlineIcon color="secondary" />
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        autoComplete: "off",
                      }}
                    />
                    <Field
                      className={clsx(
                        classes.inputField,
                        classes.landlordPhoneNumber
                      )}
                      component={PhoneNumberField}
                      name={`landlordPhoneNumber`}
                      type="text"
                      label="Landlord Phone"
                      variant="outlined"
                      margin="normal"
                      validate={validatePhone}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <PhoneIphoneOutlinedIcon color="secondary" />
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        autoComplete: "off",
                      }}
                    />
                    <Field
                      className={clsx(
                        classes.inputField,
                        classes.reasonForLeaving
                      )}
                      component={TextField}
                      name={`reasonForLeaving`}
                      type="text"
                      label="Reason for Leaving"
                      variant="outlined"
                      margin="normal"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <ExitToAppOutlinedIcon color="secondary" />
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        autoComplete: "off",
                      }}
                    />
                  </div>

                  <div className={classes.addressGrid}>
                    <GoogleMapsAutoComplete
                      className={clsx(classes.inputField, classes.address1)}
                      label="Street Address"
                      required
                      inputVariant="outlined"
                      name="address1"
                      popupIcon={null}
                      resultTypes={["address"]}
                      initialValue={formikprops.values.address1}
                      shortLabel
                      allowManual
                      onInputChange={newInputValue =>
                        setValues({ ...values, address1: newInputValue })
                      }
                      handlePlaceChange={(place, placeSize, manual) =>
                        handleAddressChange(place, manual, values, setValues)
                      }
                    />
                    <Field
                      className={clsx(classes.inputField, classes.address2)}
                      component={TextField}
                      name={`address2`}
                      type="text"
                      label="Apartment, floor or unit"
                      variant="outlined"
                      margin="normal"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <HomeOutlinedIcon color="secondary" />
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        autoComplete: "off",
                      }}
                    />
                    <Field
                      className={clsx(classes.inputField, classes.city)}
                      component={TextField}
                      required
                      name={`city`}
                      type="text"
                      label="City"
                      variant="outlined"
                      margin="normal"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <HomeOutlinedIcon color="secondary" />
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        autoComplete: "off",
                      }}
                    />
                    <Field
                      className={clsx(classes.inputField, classes.state)}
                      component={ChoiceField}
                      required
                      name={`state`}
                      type="text"
                      label="State"
                      variant="outlined"
                      margin="normal"
                      choices={STATES}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <HomeOutlinedIcon color="secondary" />
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        autoComplete: "off",
                      }}
                    />
                    <Field
                      className={clsx(classes.inputField, classes.zipCode)}
                      component={TextField}
                      required
                      name={`zipCode`}
                      type="text"
                      label="Zip Code"
                      variant="outlined"
                      margin="normal"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <HomeOutlinedIcon color="secondary" />
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        autoComplete: "off",
                      }}
                    />
                  </div>
                </div>

                <div className={classes.formButtonContainer}>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={handleDialogClose}
                  >
                    Cancel
                  </Button>

                  <div className={classes.btnContainer}>
                    <Button color="primary" variant="contained" type="submit">
                      Save
                    </Button>
                    {checkFormikError(formikprops) && (
                      <span className={classes.error}>
                        {GLOBALS.MESSAGES.CORRECT_FORM_ERRORS}
                      </span>
                    )}
                  </div>
                </div>
              </MuiPickersUtilsProvider>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
