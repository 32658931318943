import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Field } from "formik";
import { GLOBALS } from "../../App";
import ChoiceField from "../ChoiceField";
import ListingFormControls from "./ListingFormControls";
import { updateListing } from "../../hooks/ListingApi";
import * as yup from "yup";

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.dark,
  },
  textGrids: {
    flexGrow: 1,
  },
  textInputs: {
    backgroundColor: "white",
    width: "100%",
  },
  description: {
    flexGrow: 1,
  },
  formLabel: {
    fontWeight: 500,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    fontSize: "1.3em",
  },
}));

export default function ListingFeatures(props) {
  const classes = useStyles();
  const { accessToken, listing, setListing, step, setStep, showError } = props;
  const MSG = GLOBALS.MESSAGES;

  const [amenities, setAmenities] = useState(() =>
    listing.amenities ? listing.amenities.split(",") : []
  );
  const [features, setFeatures] = useState(() =>
    listing.features ? listing.features.split(",") : []
  );

  function onStepSubmit(values, formikBag) {
    updateListing(
      listing.listingNumber,
      { ...values, amenities: amenities.join(), features: features.join() },
      accessToken,
      (isSuccess, apiResult, apiError) => {
        if (isSuccess) {
          setListing(apiResult);
          setStep(step + 1);
        } else {
          console.error(apiError);
          showError(MSG.ERROR_COMPLETING_ACTION);
          formikBag.setSubmitting(false);
        }
      }
    );
  }

  function handleNext(formikprops) {
    if (formikprops.dirty || amenities.length) {
      formikprops.submitForm();
    } else {
      setStep(step + 1);
    }
  }

  const validationSchema = yup.object().shape({});

  const handleAmenityChange = (checked, value) => {
    if (checked) {
      setAmenities(prev => [...prev, value]);
    } else {
      setAmenities(prev => prev.filter(v => v !== value));
    }
  };

  const handleFeatureChange = (checked, value) => {
    if (checked) {
      setFeatures(prev => [...prev, value]);
    } else {
      setFeatures(prev => prev.filter(v => v !== value));
    }
  };

  return (
    <ListingFormControls
      listing={listing}
      step={step}
      setStep={setStep}
      onSubmit={onStepSubmit}
      validationSchema={validationSchema}
      handleNext={handleNext}
    >
      <Grid container alignItems="stretch" className={classes.root} spacing={2}>
        <Grid item className={classes.textGrids} xs={12} sm={6}>
          <Field
            component={ChoiceField}
            name="parking"
            variant="outlined"
            label="Parking"
            className={classes.textInputs}
            choices={GLOBALS.PARKING}
          />
        </Grid>
        <Grid item className={classes.textGrids} xs={12} sm={6}>
          <Field
            component={ChoiceField}
            name="laundry"
            variant="outlined"
            label="Laundry"
            className={classes.textInputs}
            choices={GLOBALS.LAUNDRY}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl component="fieldset">
            <FormLabel component="legend" className={classes.formLabel}>
              Amenities
            </FormLabel>
            <FormGroup>
              <Grid container alignItems="flex-start" spacing={0}>
                {GLOBALS.AMENITIES.map(amenity => {
                  return (
                    <Grid item xs={6} key={amenity.value}>
                      <FormControlLabel
                        key={amenity.value}
                        control={
                          <Checkbox
                            checked={amenities.includes(amenity.value)}
                            onChange={e =>
                              handleAmenityChange(
                                e.target.checked,
                                amenity.value
                              )
                            }
                          />
                        }
                        label={amenity.label}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl component="fieldset">
            <FormLabel component="legend" className={classes.formLabel}>
              Features
            </FormLabel>
            <FormGroup>
              <Grid container alignItems="flex-start" spacing={0}>
                {GLOBALS.FEATURES.map(feature => {
                  return (
                    <Grid key={feature.value} item xs={6}>
                      <FormControlLabel
                        key={feature.value}
                        control={
                          <Checkbox
                            checked={features.includes(feature.value)}
                            onChange={e =>
                              handleFeatureChange(
                                e.target.checked,
                                feature.value
                              )
                            }
                          />
                        }
                        label={feature.label}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>
    </ListingFormControls>
  );
}
