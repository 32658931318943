import React from "react";
import TenantPlan from "../tenant-plan/TenantPlan";
import TenantFormControls from "./TenantFormControls";
import { useHistory } from "react-router-dom";

const CreateTenantPlan = props => {
  const { tenant, step, setStep, isEditDialog = false, closeDialog } = props;

  const history = useHistory();

  function handleNext(formikprops) {
    if (isEditDialog) {
      closeDialog();
    } else {
      history.push(`/tenant/${tenant.id}/profile`, {
        caller: "createTenantRedirect",
      });
    }
  }

  return (
    <TenantFormControls
      tenant={tenant}
      step={step}
      setStep={setStep}
      isEditDialog={isEditDialog}
      closeDialog={closeDialog}
      handleNext={handleNext}
    >
      <TenantPlan isCreatePage />
    </TenantFormControls>
  );
};

export default CreateTenantPlan;
