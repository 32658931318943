import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { GLOBALS } from "../../App";

const ReportsNotificationDialog = ({
  title = "Reports Complete",
  open = false,
  onClose,
  message = GLOBALS.MESSAGES.REPORTS_GENERATED_SUCCESS,
  buttonMap = [],
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="nesitqa-alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {buttonMap.map(({ buttonName, handleClick }, index) => {
          return (
            <Button onClick={handleClick} color="primary" key={index}>
              {buttonName}
            </Button>
          );
        })}
      </DialogActions>
    </Dialog>
  );
};

export default ReportsNotificationDialog;
