import React, { useEffect, useState } from "react";
import {
  CardElement,
  PaymentRequestButtonElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import { GLOBALS } from "../../App";

const useStyles = makeStyles(theme => ({
  paper: {
    width: "100%",
    maxWidth: "400px",
  },
  contentText: {
    flexBasis: "100%",
    marginBottom: theme.spacing(1),
  },
  cardElementDiv: {
    marginBottom: theme.spacing(2),
  },
  paymentRequestDiv: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#0000008A",
    "& > p": {
      alignSelf: "flex-start",
    },
  },
  paymentRequestButtonDiv: {
    width: "50%",
    "& > *": {
      width: "100%",
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  buttons: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    "& > *": {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  },
  couponSection: {
    flexWrap: "wrap",
    display: "flex",
    marginBottom: theme.spacing(3),

    "& > *": {
      marginRight: theme.spacing(1),
    },

    "& .MuiInputLabel-root": {
      color: theme.palette.grey[400],
    },

    "& .MuiInputLabel-shrink": {
      color: theme.palette.primary.main,
    },
  },
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: "#fff",
  },
}));

const TenantPlanUpgradeDialog = ({
  open,
  paymentIntent,
  couponApplied,
  showBackdrop,
  handleSuccess,
  handleFailure,
  handleClose,
  handleApplyCoupon,
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const [paymentRequest, setPaymentRequest] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [loading, setLoading] = useState(false);
  const [couponCode, setCouponCode] = useState(null);

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (paymentIntent) {
      setClientSecret(paymentIntent.client_secret);
    }
  }, [paymentIntent]);

  useEffect(() => {
    if (stripe && paymentIntent) {
      const pr = stripe.paymentRequest({
        country: "US",
        currency: paymentIntent.currency || "usd",
        total: {
          label: "Upgrade Plan",
          amount: paymentIntent.amount,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      pr.canMakePayment().then(result => {
        if (result) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe, paymentIntent]);

  const handlePay = async () => {
    if (!stripe || !elements) {
      return;
    }
    setLoading(true);

    const cardElement = elements.getElement(CardElement);
    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: { card: cardElement },
    });

    if (result.error) {
      handleFailure(result.error.message);
    } else {
      let params = {};
      if (result && result.paymentIntent) {
        params = {
          transaction_id: result.paymentIntent.id,
          currency: result.paymentIntent.currency,
          value: result.paymentIntent.amount / 100,
        };
      }
      handleSuccess(params);
    }
    setLoading(false);
  };

  if (paymentRequest) {
    paymentRequest.on("paymentmethod", async ev => {
      setLoading(true);

      // Confirm the paymentIntent
      const { paymentIntent, error: confirmError } =
        await stripe.confirmCardPayment(
          clientSecret,
          { payment_method: ev.paymentMethod.id },
          { handleActions: false }
        );
      let params = {};
      if (paymentIntent) {
        params = {
          transaction_id: paymentIntent.id,
          currency: paymentIntent.currency,
          value: paymentIntent.amount / 100,
        };
      }

      if (confirmError) {
        // Payment failed
        ev.complete("fail");
        handleFailure(confirmError.message);
      } else {
        ev.complete("success");

        // Check if the PaymentIntent requires any actions
        if (paymentIntent.status === "requires_action") {
          // Let Stripe.js handle the rest of the payment flow.
          const { error } = await stripe.confirmCardPayment(clientSecret);
          if (error) {
            // Payment failed
            handleFailure(error.message);
          } else {
            // Payment succeeded.
            handleSuccess(params);
          }
        } else {
          // Payment succeeded.
          handleSuccess(params);
        }
      }
      setLoading(false);
    });
  }

  const handleApply = () => {
    handleApplyCoupon(couponCode);
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={showBackdrop}>
        <CircularProgress color="secondary" />
      </Backdrop>
      <Dialog
        open={open || false}
        onClose={handleClose}
        aria-labelledby="nestiqa-plan-upgrade-dialog"
        aria-describedby="nesitqa-plan-upgrade-dialog-description"
        classes={{ paper: classes.paper }}
      >
        <DialogTitle>
          Upgrade Plan
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {couponApplied ? (
            <DialogContentText color="primary" className={classes.contentText}>
              {GLOBALS.MESSAGES.APPLY_CODE_SUCCESS}
            </DialogContentText>
          ) : (
            <div className={classes.couponSection}>
              <DialogContentText className={classes.contentText}>
                {GLOBALS.MESSAGES.APPLY_PROMO_CODE}
              </DialogContentText>
              <TextField
                label="Enter Code"
                variant="outlined"
                size="small"
                value={couponCode}
                onChange={e => setCouponCode(e.target.value)}
              />
              <Button
                variant="outlined"
                color="primary"
                disabled={!couponCode}
                onClick={handleApply}
              >
                Apply
              </Button>
            </div>
          )}
          <DialogContentText className={classes.contentText}>
            {GLOBALS.MESSAGES.ENTER_CARD_TO_UPGRADE}
          </DialogContentText>
          <div className={classes.cardElementDiv}>
            <CardElement
              options={{
                style: {
                  base: {
                    fontSize: "16px",
                    color: theme.palette.primary.dark,
                  },
                  invalid: {
                    color: "#9e2146",
                  },
                },
                iconStyle: "solid",
              }}
            />
          </div>
          <div className={classes.buttons}>
            <Button
              variant="contained"
              color="primary"
              disabled={!stripe || loading}
              onClick={handlePay}
            >
              Pay {paymentIntent ? `$${paymentIntent?.amount / 100}` : ""}
            </Button>

            {paymentRequest && (
              <div className={classes.paymentRequestDiv}>
                <DialogContentText>Or check out with:</DialogContentText>
                <div className={classes.paymentRequestButtonDiv}>
                  <PaymentRequestButtonElement
                    onClick={handleClose}
                    options={{
                      paymentRequest,
                      style: {
                        paymentRequestButton: {
                          theme: "dark",
                          height: "36px",
                        },
                      },
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TenantPlanUpgradeDialog;
