import React from "react";

const PrintIcon = props => {
  return (
    <svg
      xmlns="true"
      width="24.818"
      height="24.818"
      viewBox="0 0 24.818 24.818"
      {...props}
    >
      <g id="Group_537" data-name="Group 537" transform="translate(0 -0.001)">
        <g id="Layer_55" data-name="Layer 55" transform="translate(0 0.001)">
          <path
            id="Path_378"
            data-name="Path 378"
            d="M23.337,21.891H20.855a.827.827,0,1,1,0-1.655h2.482a.827.827,0,0,0,.827-.827V9.482a.827.827,0,0,0-.827-.827H3.482a.827.827,0,0,0-.827.827v9.927a.827.827,0,0,0,.827.827H5.964a.827.827,0,1,1,0,1.655H3.482A2.482,2.482,0,0,1,1,19.409V9.482A2.482,2.482,0,0,1,3.482,7H23.337a2.482,2.482,0,0,1,2.482,2.482v9.927a2.482,2.482,0,0,1-2.482,2.482Z"
            transform="translate(-1 -2.036)"
            fill="#3ce0e5"
          />
          <path
            id="Path_379"
            data-name="Path 379"
            d="M21.718,28.927H6.827A.827.827,0,0,1,6,28.1V19.827A.827.827,0,0,1,6.827,19H21.718a.827.827,0,0,1,.827.827V28.1A.827.827,0,0,1,21.718,28.927ZM7.655,27.273H20.891V20.655H7.655Z"
            transform="translate(-1.864 -4.109)"
            fill="#3ce0e5"
          />
          <path
            id="Path_380"
            data-name="Path 380"
            d="M21.718,7.618a.827.827,0,0,1-.827-.827V2.655H7.655V6.791A.827.827,0,0,1,6,6.791V1.827A.827.827,0,0,1,6.827,1H21.718a.827.827,0,0,1,.827.827V6.791A.827.827,0,0,1,21.718,7.618Z"
            transform="translate(-1.864 -1)"
            fill="#3ce0e5"
          />
          <rect
            id="Rectangle_230"
            data-name="Rectangle 230"
            width="1.903"
            height="0.952"
            transform="translate(19.07 8.603)"
            fill="#3ce0e5"
          />
          <rect
            id="Rectangle_231"
            data-name="Rectangle 231"
            width="2.855"
            height="0.952"
            transform="translate(14.312 8.603)"
            fill="#3ce0e5"
          />
        </g>
      </g>
    </svg>
  );
};

export default PrintIcon;
