import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MailIcon from "@material-ui/icons/Mail";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import CakeIcon from "@material-ui/icons/Cake";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TenantProfileSection from "./TenantProfileSection";
import { formatPhoneNumber } from "../../nestiqaUtils";
import { Link } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  textWithIcon: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
    marginRight: theme.spacing(3),
  },
  textSpan: {
    paddingLeft: theme.spacing(1),
  },
  topRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    paddingBottom: theme.spacing(1),
  },
  right: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-end",

    "& a": {
      marginLeft: theme.spacing(1),
    },
  },

  [theme.breakpoints.up("sm")]: {
    left: {
      display: "flex",
    },
  },
  [theme.breakpoints.down("xs")]: {
    topRow: {
      flexDirection: "column",
    },
    right: {
      marginTop: theme.spacing(2),

      "& a": {
        marginLeft: "0px",
        marginRight: theme.spacing(1),
      },
    },
  },
}));

const TenantPersonalInformationSection = ({
  email,
  phoneNumber,
  dob,
  bio,
  isEditable = true,
  tenantId,
  facebookId,
  instagramId,
  twitterId,
  linkedinUrl,
  openEditDialog,
}) => {
  const classes = useStyles();

  const onEditButtonClick = () => {
    openEditDialog(1);
  };
  return (
    <TenantProfileSection
      title="Personal Information"
      onEditButtonClick={onEditButtonClick}
      isEditable={isEditable}
    >
      <>
        <div className={classes.topRow}>
          <div className={classes.left}>
            <div className={classes.textWithIcon}>
              <MailIcon color="secondary" />
              <span className={classes.textSpan}>{email || "-"}</span>
            </div>
            <div className={classes.textWithIcon}>
              <PhoneIphoneIcon color="secondary" />
              <span className={classes.textSpan}>
                {formatPhoneNumber(phoneNumber) || "-"}
              </span>
            </div>
            <div className={classes.textWithIcon}>
              <CakeIcon color="secondary" />
              <span className={classes.textSpan}>{dob || "-"}</span>
            </div>
          </div>
          <div className={classes.right}>
            {facebookId && (
              <Link
                component="a"
                underline="none"
                color="secondary"
                href={`https://www.facebook.com/${facebookId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookIcon />
              </Link>
            )}
            {instagramId && (
              <Link
                component="a"
                underline="none"
                color="secondary"
                href={`https://www.instagram.com/${instagramId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon />
              </Link>
            )}
            {twitterId && (
              <Link
                component="a"
                underline="none"
                color="secondary"
                href={`https://www.twitter.com/${twitterId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <TwitterIcon />
              </Link>
            )}
            {linkedinUrl && (
              <Link
                component="a"
                underline="none"
                color="secondary"
                href={linkedinUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon />
              </Link>
            )}
          </div>
        </div>
        <p>{bio}</p>
      </>
    </TenantProfileSection>
  );
};

export default TenantPersonalInformationSection;
