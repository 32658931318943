import React, { useEffect, useState } from "react";
import { Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useAuth0Nesitqa from "../hooks/useAuth0Nestiqa";
import { GLOBALS } from "../App";
import {
  landlordExtComplete,
  landlordExtGetComm,
  landlordExtInfo,
  landlordExtAccept,
  landlordExtProceed,
  landlordExtStart,
  landlordExtStatus,
  landlordExtValidateComm,
} from "../utils/LandlordApi";
import { Helmet } from "react-helmet";
import { useHistory, useParams } from "react-router-dom";
import NestiqaAlertDialog from "../components/NestiqaAlertDialog";
import NestiqaActionDialog from "../components/NestiqaActionDialog";
import UserHasLandord from "../components/landlord-ext/userHasLandord";
import ValidateInfo from "../components/landlord-ext/validateInfo";
import StartExt from "../components/landlord-ext/startExt";
import ValidateComm from "../components/landlord-ext/validateComm";
import CommSent from "../components/landlord-ext/commSent";
import LandlordExtListing from "../components/LandlordExtListings";
import LandlordExtAcceptTerms from "../components/landlord-ext/acceptTerms";

const useStyle = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
  },
}));

export default function LandlordExtPage() {
  const { isAuthenticated, accessToken, loginWithRedirect } = useAuth0Nesitqa();
  const { landlordId } = useParams();
  const history = useHistory();

  const classes = useStyle();
  const landlordExtContinue = sessionStorage.getItem("landlordExtContinue");
  const [result, setResult] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [loading, setLoading] = useState(false);
  const [dialogControls, setDialogControls] = useState({
    open: false,
  });

  const [actionDialogControls, setActionDialogControls] = useState({
    open: false,
  });

  const handleAlertDialogClose = () => {
    setDialogControls({
      open: false,
    });
  };

  const showError = () => {
    setDialogControls({
      open: true,
      title: "Error",
      message: GLOBALS.MESSAGES.ERROR_COMPLETING_ACTION,
      onClose: handleAlertDialogClose,
    });
  };

  const showFailMessage = message => {
    setDialogControls({
      open: true,
      title: "Fail",
      message,
      onClose: handleAlertDialogClose,
    });
  };

  function continueAndLogin() {
    sessionStorage.setItem("landlordExtContinue", true);
    localStorage.setItem("previousLocation", window.location.pathname);
    loginWithRedirect({ screen_hint: "signup" });
  }

  const handleStartContinue = () => {
    if (isAuthenticated) {
      setLoading(true);
      landlordExtStart(accessToken, landlordId, statusCallback);
    } else {
      setActionDialogControls({
        open: true,
        title: "Verification",
        message: GLOBALS.MESSAGES.LANDLORD_EXT_SIGN_UP,
        buttonMap: [
          {
            buttonName: "Create Account",
            handleClick: continueAndLogin,
          },
        ],
      });
    }
  };

  const statusCallback = (isSuccess, apiResult, apiError) => {
    setLoading(false);
    if (isSuccess) {
      setResult(apiResult);

      if (apiResult.phone) {
        setPhone(apiResult.phone);
      }

      if (apiResult.email) {
        setEmail(apiResult.email);
      }

      if (apiResult.result === "FAIL") {
        if (apiResult.status === GLOBALS.LANDLORD_EXT_STATUS.VALIDATE_INFO) {
          showFailMessage(GLOBALS.MESSAGES.VAILDATE_INFO_FAILED);
        } else if (apiResult.status === GLOBALS.LANDLORD_EXT_STATUS.COMM_SENT) {
          showFailMessage(GLOBALS.MESSAGES.COMM_SENT_FAILED);
        }
      }
    } else {
      console.error(apiError);
      showError();
    }
  };

  useEffect(() => {
    if (!landlordId) return;
    setLoading(true);
    landlordExtStatus(landlordId, statusCallback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landlordId]);

  useEffect(() => {
    if (!result || !landlordId) return;

    if (result.status === GLOBALS.LANDLORD_EXT_STATUS.UNAVAILABLE) {
      history.replace("/landlord");
    }

    if (!accessToken) return;

    switch (result.status) {
      case GLOBALS.LANDLORD_EXT_STATUS.START:
        if (isAuthenticated && landlordExtContinue) {
          setLoading(true);
          landlordExtStart(accessToken, landlordId, statusCallback);
        }
        break;

      case GLOBALS.LANDLORD_EXT_STATUS.READY:
        setLoading(true);
        landlordExtComplete(accessToken, landlordId, statusCallback);
        break;

      case GLOBALS.LANDLORD_EXT_STATUS.COMPLETE:
        history.replace(GLOBALS.PATHS.LANDLORD_ALL_APPS_PAGE, {
          redirectFromExt: true,
        });
        break;

      default:
        return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result, accessToken, landlordId]);

  let landlordExtPageContent = null;

  switch (result?.status) {
    case GLOBALS.LANDLORD_EXT_STATUS.START:
      if (isAuthenticated && landlordExtContinue) {
        landlordExtPageContent = null;
      } else {
        landlordExtPageContent = (
          <StartExt name={result.name} handleContinue={handleStartContinue} />
        );
      }
      break;

    case GLOBALS.LANDLORD_EXT_STATUS.USER_HAS_LANDLORD:
      landlordExtPageContent = (
        <UserHasLandord
          onYesClick={() => {
            setLoading(true);
            landlordExtProceed(accessToken, landlordId, statusCallback);
          }}
        />
      );
      break;

    case GLOBALS.LANDLORD_EXT_STATUS.VALIDATE_INFO:
      landlordExtPageContent = (
        <ValidateInfo
          onSubmit={id => {
            setLoading(true);
            landlordExtInfo(accessToken, landlordId, id, statusCallback);
          }}
        />
      );
      break;

    case GLOBALS.LANDLORD_EXT_STATUS.VALIDATE_COMM:
      landlordExtPageContent = (
        <ValidateComm
          onContinue={() => {
            setLoading(true);
            landlordExtGetComm(accessToken, landlordId, statusCallback);
          }}
          phone={phone}
          email={email}
        />
      );
      break;

    case GLOBALS.LANDLORD_EXT_STATUS.COMM_SENT:
      landlordExtPageContent = (
        <CommSent
          onSubmit={code => {
            setLoading(true);
            landlordExtValidateComm(
              accessToken,
              landlordId,
              code,
              statusCallback
            );
          }}
          onResendCode={() => {
            setLoading(true);
            landlordExtGetComm(accessToken, landlordId, statusCallback);
          }}
          phone={phone}
        />
      );
      break;

    case GLOBALS.LANDLORD_EXT_STATUS.ACCEPT_TERMS:
      landlordExtPageContent = (
        <LandlordExtAcceptTerms
          onAcceptTerms={() => {
            setLoading(true);
            landlordExtAccept(accessToken, landlordId, statusCallback);
          }}
        />
      );
      break;

    case GLOBALS.LANDLORD_EXT_STATUS.MANUAL_REQUIRED:
      landlordExtPageContent = (
        <Typography>{GLOBALS.MESSAGES.MANUAL_REQUIRED_MSG}</Typography>
      );
      break;

    default:
      landlordExtPageContent = null;
  }

  if (loading) {
    return (
      <Typography variant="h5" align="center" color="primary">
        Loading...
      </Typography>
    );
  }

  return (
    <Container className={classes.root}>
      <Helmet>
        <title>{GLOBALS.META_TAGS.TITLE.LANDLORD_PAGE}</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <NestiqaAlertDialog {...dialogControls} />
      <NestiqaActionDialog {...actionDialogControls} />
      {landlordExtPageContent}
      {result?.activeListings && result.activeListings.length > 0 && (
        <LandlordExtListing
          listings={result.activeListings}
          title="Active Listings"
        ></LandlordExtListing>
      )}
    </Container>
  );
}
