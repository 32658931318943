import { Typography, Link, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import ReactReadMoreReadLess from "react-read-more-read-less";
import { AdditionalInformationContent } from "../tenant-profile/TenantAdditionalInformationSection";
import { BackgroundInformationContent } from "../tenant-profile/TenantBackgroundInformationSection";
import { HousingHistoryContent } from "../tenant-profile/TenantHousingHistorySection";
import { EmploymentHistoryContent } from "../tenant-profile/TenantEmploymentHistorySection";
import MailIcon from "@material-ui/icons/Mail";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import CakeIcon from "@material-ui/icons/Cake";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { DocumentContent } from "../tenant-profile/TenantDocumentSection";
import TenantProfileSection from "../tenant-profile/TenantProfileSection";
import LandlordReports from "./LandlordAppReports";
import { format } from "date-fns";
import { GLOBALS } from "../../App";
import tenantPicture from "./../tenant_picture.png";

import { formatPhoneNumber, getStaticImgSrc } from "../../nestiqaUtils";

const useStyles = makeStyles(theme => ({
  section: {
    marginTop: theme.spacing(6),
    "& > h5": {
      textTransform: "capitalize",
      marginBottom: theme.spacing(1),
    },
  },
  personalInfo: {
    display: "grid",
    gridTemplateColumns: "150px 1fr",
    gridTemplateAreas: `
    "image content"
    "image bio"
    `,
    gap: theme.spacing(2),
  },
  image: {
    width: "150px",
    minWidth: "150px",
    height: "150px",
    border: "3px solid",
    borderColor: theme.palette.primary.main,
    borderRadius: "0 0 20px 20px",
    boxShadow: theme.shadows[10],
    objectFit: "cover",
    objectPosition: "center",
    gridArea: "image",
  },
  textWithIcon: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
    marginRight: theme.spacing(3),
  },
  textSpan: {
    paddingLeft: theme.spacing(1),
  },
  topRow: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    paddingBottom: theme.spacing(1),
  },
  content: {
    width: "100%",
    gridArea: "content",
  },
  bio: {
    gridArea: "bio",
  },
  right: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-end",

    "& a": {
      marginLeft: theme.spacing(1),
    },
  },
  readMoreLess: {
    color: theme.palette.primary.main,
  },

  [theme.breakpoints.up("sm")]: {
    left: {
      display: "flex",
    },
  },
  [theme.breakpoints.down("sm")]: {
    topRow: {
      flexDirection: "column",
    },
    right: {
      marginTop: theme.spacing(2),

      "& a": {
        marginLeft: "0px",
        marginRight: theme.spacing(1),
      },
    },
    image: {
      width: "120px",
      minWidth: "120px",
      height: "120px",
    },
    personalInfo: {
      gridTemplateColumns: "120px 1fr",
      gridTemplateAreas: `
      "image content"
      "bio bio"
      `,
    },
  },
  [theme.breakpoints.down(500)]: {
    topRow: {
      flexDirection: "column",
    },
    section: {
      "& > h5": {
        fontSize: "1.2rem",
      },
    },
    image: {
      display: "block",
      margin: "0 auto",
    },
    personalInfo: {
      gridTemplateColumns: "1fr",
      gridTemplateAreas: `
      "image"
      "content"
      "bio"
      `,
    },
  },
}));

const LandlordAppTenantProfile = ({
  selectedHousehold: household,
  accessToken,
  applicationId,
  showError,
  appAction,
  onRequestReportsBtnClick,
  reports,
  isPrintPage = false,
}) => {
  const fullName = household.firstName + " " + household.lastName;
  const image = household.picture || getStaticImgSrc(tenantPicture);

  const jobHistory = household.employmentHistory.filter(
    history => history.incomeType === "EMPLOYMENT"
  );

  const otherHistory = household.employmentHistory.filter(
    history => history.incomeType !== "EMPLOYMENT"
  );

  return (
    <TenantProfileSection title={fullName} isEditable={false}>
      <PersonalInformationContent
        email={household.email}
        phoneNumber={household.phoneNumber}
        dob={format(household.dob, GLOBALS.DATE_FORMAT)}
        bio={household.bio}
        facebookId={household.facebookId}
        instagramId={household.instagramId}
        twitterId={household.twitterId}
        linkedinUrl={household.linkedinUrl}
        image={image}
        fullName={fullName}
        isPrintPage={isPrintPage}
      />
      <SectionWithHeading title="Housing History">
        <HousingHistoryContent
          housingHistory={household.housingHistory}
          isPrintPage={isPrintPage}
        />
      </SectionWithHeading>
      <SectionWithHeading title="Income">
        {household.employmentHistory.length === 0 && (
          <Typography color="primary" align="center">
            {GLOBALS.MESSAGES.NO_TENANT_EMPLOYMENT_HISTORY}
          </Typography>
        )}
        {jobHistory.length > 0 && (
          <>
            <Box>
              <Typography variant="h6" color="primary" gutterBottom>
                Employment History
              </Typography>
            </Box>
            <EmploymentHistoryContent employmentHistory={jobHistory} />
          </>
        )}
        {otherHistory.length > 0 && (
          <>
            <Box mt={4}>
              <Typography variant="h6" color="primary" gutterBottom>
                Other Income
              </Typography>
            </Box>
            <EmploymentHistoryContent
              isOtherIncome
              employmentHistory={otherHistory}
            />
          </>
        )}
      </SectionWithHeading>
      <SectionWithHeading title="Background Information">
        <BackgroundInformationContent
          bankrupcy={household.bankrupcy}
          eviction={household.eviction}
          refusedRent={household.refusedRent}
          criminalOffenceCharges={household.criminalOffenceCharges}
          criminalOffenceConviction={household.criminalOffenceConviction}
          detailsForQuestions={household.detailsForQuestions}
        />
      </SectionWithHeading>
      <SectionWithHeading title="Additional Information">
        <AdditionalInformationContent
          pets={household.pets}
          vehicles={household.vehicles}
        />
      </SectionWithHeading>
      {!isPrintPage && (
        <>
          <SectionWithHeading title="Documents">
            <DocumentContent
              documents={household.documents}
              applicationId={applicationId}
              tenantId={household.id}
              tenantFullName={fullName}
            />
          </SectionWithHeading>
          {appAction === "APPLY" && (
            <SectionWithHeading title="Reports">
              <LandlordReports
                accessToken={accessToken}
                selectedHouseholdId={household.id}
                applicationId={applicationId}
                showError={showError}
                onRequestReportsBtnClick={onRequestReportsBtnClick}
                reports={reports}
              />
            </SectionWithHeading>
          )}
        </>
      )}
    </TenantProfileSection>
  );
};

const SectionWithHeading = ({ title, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.section}>
      <Typography variant="h5">{title}</Typography>
      {children}
    </div>
  );
};

const PersonalInformationContent = ({
  email,
  phoneNumber,
  dob,
  bio,
  facebookId,
  instagramId,
  twitterId,
  linkedinUrl,
  image,
  fullName,
  isPrintPage,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.personalInfo}>
      <img src={image} alt={fullName} className={classes.image} />

      <div className={classes.content}>
        <div className={classes.topRow}>
          <div className={classes.left}>
            <div className={classes.textWithIcon}>
              <MailIcon color="secondary" />
              <span className={classes.textSpan}>{email || "-"}</span>
            </div>
            <div className={classes.textWithIcon}>
              <PhoneIphoneIcon color="secondary" />
              <span className={classes.textSpan}>
                {formatPhoneNumber(phoneNumber) || "-"}
              </span>
            </div>
            <div className={classes.textWithIcon}>
              <CakeIcon color="secondary" />
              <span className={classes.textSpan}>{dob || "-"}</span>
            </div>
          </div>
          <div className={classes.right}>
            {facebookId && (
              <Link
                component="a"
                underline="none"
                color="secondary"
                href={`https://www.facebook.com/${facebookId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookIcon />
              </Link>
            )}
            {instagramId && (
              <Link
                component="a"
                underline="none"
                color="secondary"
                href={`https://www.instagram.com/${instagramId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon />
              </Link>
            )}
            {twitterId && (
              <Link
                component="a"
                underline="none"
                color="secondary"
                href={`https://www.twitter.com/${twitterId}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <TwitterIcon />
              </Link>
            )}
            {linkedinUrl && (
              <Link
                component="a"
                underline="none"
                color="secondary"
                href={linkedinUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedInIcon />
              </Link>
            )}
          </div>
        </div>
      </div>
      <div className={classes.bio}>
        {isPrintPage ? (
          bio
        ) : (
          <ReactReadMoreReadLess
            charLimit={300}
            readMoreText={"Read more"}
            readLessText={"Read less"}
            readMoreClassName={classes.readMoreLess}
            readLessClassName={classes.readMoreLess}
          >
            {bio}
          </ReactReadMoreReadLess>
        )}
      </div>
    </div>
  );
};

export default LandlordAppTenantProfile;
