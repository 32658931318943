import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import theme from "../../theme";
import tenantPlaceholder from "../tenant_picture.png";
import ApplicationForm from "./ApplicationForm";
import CircularStatic from "./CircularStatic";
import { Link as RouterLink } from "react-router-dom";
import { GLOBALS } from "../../App";
import ProgressIndicatorDialog from "../dialogs/ProgressIndicatorDialog";
import useProfileCompleteness from "../../hooks/useProfileCompleteness";

const useStyles = makeStyles(() => ({
  detailRoot: {
    flexGrow: 1,
    boxShadow: "0 0 5px rgba(0,0,0,0.2)",
    margin: "20px auto 0",
    maxWidth: "920px",
    "& h5": {
      marginBottom: "15px",
    },
  },
  flexContainer: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    boxSizing: "border-box",
    alignItems: "center",
    borderBottom: "1px solid #3CE0E5",

    [theme.breakpoints.down("xs")]: {
      paddingBottom: theme.spacing(2),
    },
  },
  tenantProfileContainer: {
    backgroundColor: "#FBFBFB",
    minHeight: "50px",
    padding: "35px",
    marginRight: theme.spacing(2),
    textAlign: "center",

    "& > .MuiTypography-root": {
      fontSize: "16px",
      fontWeight: "bold",
      textTransform: "uppercase",
    },

    [theme.breakpoints.down("sm")]: {
      padding: "30px",
    },

    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
      padding: "15px",
    },
  },
  landlordContainer: {
    display: "inline-flex",
    alignItems: "center",
    flexGrow: 1,

    [theme.breakpoints.down("sm")]: {
      flexBasis: "80%",
    },

    [theme.breakpoints.down("xs")]: {
      margin: "20px",
    },
  },
  landlordImage: {
    width: "52px",
    height: "52px",
    borderRadius: "50%",
    marginRight: "17px",
  },
  landlordText: {
    fontSize: "20px",
    lineHeight: "1.5",
  },
  profileError: {
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: "1.57",
    letterSpacing: "0.00714em",
    textAlign: "left",
    color: theme.palette.error.main,
    display: "block",
  },
  reviewButton: {
    fontSize: "16px",
    fontWeight: "lighter",
    textTransform: "capitalize",
  },
  progressContainer: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      padding: "0 15px",
      alignItems: "center",
    },
  },
  progressContainerTablet: {
    marginRight: theme.spacing(6),

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-between",
      flexGrow: 1,
      paddingLeft: "15px",
      margin: "10px 0",
    },
  },
  buttonContainer: {
    padding: "15px",
    marginRight: theme.spacing(2),

    [theme.breakpoints.down("xs")]: {
      margin: 0,
      padding: 0,
    },
  },
  link: {
    textDecoration: "none",
    color: "white",
  },
}));

const ApplicationDetailed = ({ application = {}, token }) => {
  const classes = useStyles();
  const { APPLICATION_SUCCESSFUL, COMPLETE_TENANT_PROFILE } = GLOBALS.MESSAGES;
  const { listing, tenant } = application;
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));
  const [messageDialogControls, setMessageDialogControls] = useState({
    open: false,
  });
  const { progress, messages } = useProfileCompleteness(tenant);

  const handleProgressClick = () => {
    setMessageDialogControls({
      open: true,
      title: "To complete your profile:",
      mandatory: messages.mandatory,
      niceToHave: messages.niceToHave,
      message: APPLICATION_SUCCESSFUL,
      onClose: () => setMessageDialogControls({ open: false }),
    });
  };

  if (!listing || !tenant) {
    return (
      <Container>
        <Typography variant="h5" align="center" color="primary">
          Loading...
        </Typography>
      </Container>
    );
  }

  return (
    <>
      <ProgressIndicatorDialog {...messageDialogControls} />
      <div className={classes.detailRoot}>
        <Grid container>
          <Box className={classes.flexContainer}>
            <Grid
              item
              xs={12}
              sm={2}
              className={classes.tenantProfileContainer}
            >
              <Typography variant="body1" color="primary">
                Tenant Profile
              </Typography>
            </Grid>
            <Grid item xs={12} sm="auto" className={classes.landlordContainer}>
              <img
                className={classes.landlordImage}
                src={tenant.picture || tenantPlaceholder}
                alt="Landlord"
              />
              <Typography
                className={classes.landlordText}
                variant="body1"
                align="left"
              >
                {tenant.firstName} {tenant.lastName}
                {tenant.isComplete === "N" ? (
                  <span className={classes.profileError}>
                    {COMPLETE_TENANT_PROFILE}
                  </span>
                ) : null}
              </Typography>
            </Grid>
            {!isMobile ? (
              <>
                <Grid
                  item
                  sm="auto"
                  className={classes.progressContainerTablet}
                >
                  <CircularStatic
                    onClick={handleProgressClick}
                    value={progress}
                    is_complete={tenant.isComplete}
                  />
                </Grid>
                <Grid item className={classes.buttonContainer}>
                  <Button
                    className={classes.reviewButton}
                    variant="contained"
                    color="primary"
                  >
                    <Link
                      to={`/tenant/${tenant.id}/profile`}
                      component={RouterLink}
                      className={classes.link}
                      underline="none"
                    >
                      Review/Update
                    </Link>
                  </Button>
                </Grid>
              </>
            ) : (
              <div className={classes.progressContainer}>
                <Grid item>
                  <CircularStatic
                    value={tenant.profileCompleteness}
                    is_complete={tenant.isComplete}
                    onClick={handleProgressClick}
                  />
                </Grid>
                <Grid item className={classes.buttonContainer}>
                  <Button
                    className={classes.reviewButton}
                    variant="contained"
                    color="primary"
                  >
                    <Link
                      to={`/tenant/${tenant.id}/profile`}
                      component={RouterLink}
                      className={classes.link}
                      underline="none"
                    >
                      Review/Update
                    </Link>
                  </Button>
                </Grid>
              </div>
            )}
          </Box>
          <Grid item lg={12}>
            <ApplicationForm application={application} token={token} />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ApplicationDetailed;
