import React from "react";
import { Container } from "@material-ui/core";

import TenantPlan from "../components/tenant-plan/TenantPlan";

export default function TenantPlanPage() {
  return (
    <Container maxWidth="lg">
      <TenantPlan />
    </Container>
  );
}
