import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";

const useStyles = makeStyles(theme => ({
  wrapper: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  innerText: {
    fontSize: "12px",
    fontWeight: "bold",

    "& > span": {
      display: "block",
      fontWeight: 450,
    },
  },
  outerCircle: {
    backgroundColor: "transparent",
    border: "5px solid #E0E0E0",
    width: "5rem",
    height: "5rem",
    borderRadius: "50%",
    MozBorderRadius: "50%",
    WebkitBorderRadius: "50%",
  },
  red: {
    color: red.A400,
  },
  themeSecondary: {
    color: theme.palette.secondary.main,
  },
}));

function CircularProgressWithLabel(props) {
  const classes = useStyles();

  return (
    <Box
      onClick={props.onClick}
      className={classes.wrapper}
      position="relative"
      display="inline-flex"
    >
      <div className={classes.outerCircle}>
        <CircularProgress
          classes={{
            circle:
              props.is_complete === "Y" ? classes.themeSecondary : classes.red,
          }}
          size="4.42rem"
          variant="determinate"
          thickness={3}
          {...props}
        />
      </div>
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="subtitle1"
          align="center"
          className={classes.innerText}
        >
          {`${props.value}%`}
          <span>Complete</span>
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
  is_complete: PropTypes.string,
  onClick: PropTypes.func,
};

export default function CircularStatic({ value, is_complete, onClick }) {
  return (
    <CircularProgressWithLabel
      value={value}
      is_complete={is_complete}
      onClick={onClick}
    />
  );
}
