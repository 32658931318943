import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import * as yup from "yup";
import {
  Button,
  Dialog,
  DialogContentText,
  DialogContent,
  DialogActions,
  DialogTitle,
  Typography,
  LinearProgress,
} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import MailIcon from "@material-ui/icons/MailOutline";
import { makeStyles } from "@material-ui/core/styles";
import {
  createHouseholdInvitation,
  editHouseholdMember,
  sendInvitationEmail,
} from "../utils/HouseholdApi";
import { GLOBALS } from "../App";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiDialog-paper": {
      width: "100%",
      maxWidth: "700px",
      padding: theme.spacing(1),
    },
    "& .MuiDialogTitle-root": {
      paddingBottom: "0px",
    },
  },
  dialogRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    "&:not(:last-child)": {
      paddingBottom: theme.spacing(2),
    },

    "& .MuiTextField-root": {
      flex: 1,
    },

    "& .MuiButtonBase-root": {
      marginLeft: theme.spacing(1),
    },
  },
  dialogContent: {
    paddingBottom: theme.spacing(2),
  },
  inviteText: {
    paddingBottom: theme.spacing(1),
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  inviteNowSection: {
    paddingBottom: theme.spacing(2),
  },
  linearProgress: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  [theme.breakpoints.down("xs")]: {
    dialogRow: {
      flexDirection: "column",
      alignItems: "flex-start",

      "& .MuiButtonBase-root": {
        marginLeft: "0px",
        marginTop: theme.spacing(1),
      },

      "& .MuiTextField-root": {
        width: "100%",
      },
    },
  },
}));

const InvitationDialog = ({
  open,
  onClose,
  occupant,
  tenantId,
  accessToken,
  showInviteNow,
}) => {
  const classes = useStyles();
  const [error, setError] = useState();
  const [invitation, setInvitation] = useState();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [sendEmailSuccesful, setSendEmailSuccesful] = useState(false);
  const [inviteNowClicked, setInviteNowClicked] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (isCopied) {
      const id = setTimeout(() => {
        setIsCopied(false);
      }, 3000);

      return () => {
        clearTimeout(id);
      };
    }
  }, [isCopied]);

  const handleClose = () => {
    setError(null);
    setInvitation(null);
    setEmail("");
    setLoading(false);
    setSendEmailSuccesful(false);
    setInviteNowClicked(false);
    onClose();
  };

  useEffect(() => {
    if (
      occupant &&
      occupant.id &&
      (!showInviteNow || (showInviteNow && inviteNowClicked))
    ) {
      createHouseholdInvitation(
        tenantId,
        occupant.id,
        accessToken,
        (isSuccess, apiResult, apiError) => {
          if (isSuccess) {
            setInvitation(apiResult);
          } else {
            console.error(apiError);
            setError(apiError);
          }
        }
      );
      setEmail(occupant.email || "");
    }
    // eslint-disable-next-line
  }, [occupant, inviteNowClicked, showInviteNow]);

  const callSendMailApi = () => {
    sendInvitationEmail(
      tenantId,
      occupant.id,
      accessToken,
      (isSuccess, apiResult, apiError) => {
        setLoading(false);

        if (isSuccess) {
          setSendEmailSuccesful(true);
        } else {
          console.error(apiError);
          setError(apiError);
        }
      }
    );
  };

  const handleSendEmail = email => {
    setLoading(true);
    if (occupant.email === email) {
      callSendMailApi();
    } else {
      editHouseholdMember(
        tenantId,
        { ...occupant, email },
        accessToken,
        (isSuccess, apiResult, apiError) => {
          if (isSuccess) {
            callSendMailApi();
          } else {
            console.error(apiError);
            setError(apiError);
            setLoading(false);
          }
        }
      );
    }
  };

  const getDialogContent = () => {
    if (showInviteNow && !inviteNowClicked) {
      return null;
    }

    if (error) {
      return (
        <>
          <DialogContentText
            id="nesitqa-invitation-dialog-description"
            variant="subtitle2"
          >
            {GLOBALS.MESSAGES.ERROR_COMPLETING_ACTION}
          </DialogContentText>
        </>
      );
    }

    if (!invitation) {
      return (
        <>
          <DialogContentText
            id="nesitqa-invitation-dialog-description"
            variant="subtitle2"
          >
            Loading
          </DialogContentText>
        </>
      );
    }

    const invitationLink =
      GLOBALS.APP_BASE_URL +
      GLOBALS.PATHS.JOIN_HOUSEHOLD.replace(
        ":invitationCode",
        invitation.invitationCode
      );

    const validationSchema = yup.object().shape({
      email: yup
        .string()
        .email(GLOBALS.MESSAGES.ENTER_VALID_EMAIL)
        .required(GLOBALS.MESSAGES.ENTER_VALID_EMAIL),
    });

    return (
      <>
        <DialogContentText
          id="nesitqa-invitation-dialog-description"
          variant="subtitle2"
        >
          {GLOBALS.MESSAGES.INVITATION_DIALOG_TEXT.replace(
            "[full_name]",
            occupant.firstName + " " + occupant.lastName
          )}
        </DialogContentText>
        <div className={classes.dialogRow}>
          <Typography variant="subtitle2">{invitationLink}</Typography>
          <Button
            onClick={() => {
              navigator.clipboard.writeText(invitationLink);
              setIsCopied(true);
            }}
            color="primary"
            size="small"
            startIcon={<FileCopyIcon />}
          >
            {isCopied ? "Link Copied" : "Copy Link"}
          </Button>
        </div>
        <div>
          <Formik
            initialValues={{ email: email }}
            validationSchema={validationSchema}
            onSubmit={(values, formikBag) => {
              handleSendEmail(values.email);
            }}
          >
            {formikprops => (
              <Form className={classes.dialogRow}>
                <Field
                  placeholder="Household Member Email"
                  component={TextField}
                  required
                  name={`email`}
                  type="email"
                  variant="outlined"
                  size="small"
                  inputProps={{
                    autoComplete: "off",
                  }}
                />
                <Button
                  color="primary"
                  size="small"
                  startIcon={<MailIcon />}
                  disabled={loading || sendEmailSuccesful}
                  onClick={formikprops.submitForm}
                >
                  Email Link
                </Button>
              </Form>
            )}
          </Formik>
        </div>
        {loading && <LinearProgress className={classes.linearProgress} />}
        {sendEmailSuccesful && (
          <Typography variant="subtitle2" color="primary">
            Email sent successfully
          </Typography>
        )}
      </>
    );
  };

  const inviteNowSection = (
    <div className={classes.inviteNowSection}>
      <DialogContentText variant="subtitle2" className={classes.inviteText}>
        {GLOBALS.MESSAGES.INVITE_HOUSEHOLD_MEMBER.replace("[full_name]", "")}
      </DialogContentText>
      <Button
        color="primary"
        disabled={inviteNowClicked}
        variant="contained"
        onClick={() => setInviteNowClicked(true)}
        className={classes.marginRight}
      >
        Invite Now
      </Button>
      <Button
        color="primary"
        disabled={inviteNowClicked}
        variant="outlined"
        onClick={onClose}
      >
        Invite Later
      </Button>
    </div>
  );

  return (
    <Dialog
      open={open || false}
      onClose={handleClose}
      className={classes.root}
      aria-labelledby="nestiqa-invitation-dialog"
      aria-describedby="nesitqa-invitation-dialog-description"
    >
      <DialogTitle id="invite-household-dialog-slide-title">
        Invite Household Member
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {showInviteNow && !error && inviteNowSection}

        {getDialogContent()}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {!error ? "Close" : "OK"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InvitationDialog;
