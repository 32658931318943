import { useEffect } from "react";
import React, { useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import {
  Container,
  Typography,
  Button,
  IconButton,
  Link,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useAuth0Nesitqa from "../hooks/useAuth0Nestiqa";
import RequireLogin from "../components/RequireLogin";
import { deleteSavedSearch, getUserSavedSearches } from "../utils/UserApi";
import NestiqaAlertDialog from "../components/NestiqaAlertDialog";
import SaveSearchDialog from "../components/SaveSearchDialog";
import SavedSearchesAccordian from "../components/SavedSearchesAccordian";
import { GLOBALS } from "../App";
import CreateTenantTable from "../components/tenant/CreateTenantTable";
import TenantProfileSection from "../components/tenant-profile/TenantProfileSection";
import {
  DeleteOutline as DeleteIcon,
  EditOutlined as EditIcon,
} from "@material-ui/icons";
import { format } from "date-fns";
import { camelToSnake } from "../nestiqaUtils";

const useStyles = makeStyles(theme => ({
  viewListingsBtn: {
    display: "block",
    margin: "0 auto",
    marginTop: theme.spacing(4),
  },
}));

const UserSavedSearches = () => {
  const classes = useStyles();
  const history = useHistory();
  const { isAuthenticated, accessToken, loginWithRedirect } = useAuth0Nesitqa();
  const [saveDialogControls, setSaveDialogControls] = useState({
    open: false,
  });
  const [dialogControls, setDialogControls] = useState({
    open: false,
    title: "",
    message: "",
  });
  const [savedSearches, setSavedSearches] = useState();

  function handleLogin() {
    localStorage.setItem("previousLocation", window.location.pathname);
    loginWithRedirect();
  }

  const showError = (error = GLOBALS.MESSAGES.ERROR_COMPLETING_ACTION) => {
    setDialogControls({
      open: true,
      title: "Error",
      message: error,
    });
  };

  function showMessage(
    title = "Error",
    message = GLOBALS.MESSAGES.ERROR_COMPLETING_ACTION
  ) {
    setDialogControls({
      open: true,
      title,
      message,
      onClose: () => setDialogControls({ open: false }),
    });
  }

  function savedSearchCallback(isSuccess, result, error) {
    if (isSuccess) {
      setSavedSearches(result);
    } else {
      showError();
    }
  }

  async function callGetSavedSearches() {
    getUserSavedSearches(accessToken, savedSearchCallback);
  }

  useEffect(() => {
    if (!accessToken) return;

    callGetSavedSearches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  function deleteCallback(isSuccess, result, error, id) {
    if (isSuccess) {
      setSavedSearches(prev => ({
        ...prev,
        results: prev.results.filter(search => search.id !== id),
      }));
    } else {
      showError();
    }
  }

  async function callDeleteSearch(id) {
    deleteSavedSearch(accessToken, id, deleteCallback);
  }

  const isTabletOrAbove = useMediaQuery(theme => theme.breakpoints.up("sm"));

  if (!isAuthenticated) {
    return (
      <Container>
        <RequireLogin login={handleLogin} />
      </Container>
    );
  }

  if (!savedSearches) {
    return (
      <Container>
        <NestiqaAlertDialog
          {...dialogControls}
          onClose={() => setDialogControls({ open: false })}
        />
        <Typography variant="h5" align="center" color="primary">
          Loading...
        </Typography>
      </Container>
    );
  }

  if (savedSearches.count === 0) {
    return (
      <Container>
        <NestiqaAlertDialog
          {...dialogControls}
          onClose={() => setDialogControls({ open: false })}
        />
        <Typography variant="h5" align="center" color="primary">
          You have no saved searches
        </Typography>
        <Button
          className={classes.viewListingsBtn}
          variant="contained"
          color="primary"
          onClick={() => {
            history.push(GLOBALS.PATHS.LISTINGS);
          }}
        >
          View Listings
        </Button>
      </Container>
    );
  }

  const otherColumns = [
    {
      field: "searchName",
      headerName: "Search Name",
    },
    {
      field: "emailAlerts",
      headerName: "Email Alerts",
    },
    {
      field: "lastModified",
      headerName: "Last Modified",
    },
    {
      field: "actions",
      headerName: "",
    },
  ];

  const onRowEdit = row => {
    setSaveDialogControls({
      open: true,
      savedSearchId: row.id,
      defaultSearchName: row.searchName,
      defaultEmailAlerts: row.emailAlerts === "Y",
      searchParams: row.searchParams,
      onSuccess: ({ searchName, emailAlerts }) => {
        setSavedSearches(prev => ({
          ...prev,
          results: prev.results.map(search => {
            if (search.id === row.id) {
              return {
                ...search,
                searchName,
                emailAlerts: emailAlerts ? "Y" : "N",
              };
            }

            return search;
          }),
        }));
      },
    });
  };

  const onRowDelete = row => {
    callDeleteSearch(row.id);
  };

  const otherRows = savedSearches.results.map(search => {
    const params = new URLSearchParams(camelToSnake(search.searchParams));

    return [
      {
        field: "searchName",
        value: (
          <Link
            to={`${GLOBALS.PATHS.LISTINGS}?${params.toString()}`}
            component={RouterLink}
            underline="none"
          >
            {search.searchName}
          </Link>
        ),
      },
      {
        field: "emailAlerts",
        value: search.emailAlerts === "Y" ? "Yes" : "No",
      },
      {
        field: "lastModified",
        value: format(new Date(search.lastModified), GLOBALS.DATE_FORMAT),
      },

      {
        field: "actions",
        value: (
          <>
            <IconButton
              edge="end"
              aria-label="edit"
              onClick={() => onRowEdit(search)}
            >
              <EditIcon color="secondary" />
            </IconButton>
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => onRowDelete(search)}
            >
              <DeleteIcon color="secondary" />
            </IconButton>
          </>
        ),
      },
    ];
  });

  return (
    <Container>
      <NestiqaAlertDialog
        {...dialogControls}
        onClose={() => setDialogControls({ open: false })}
      />
      <SaveSearchDialog
        {...saveDialogControls}
        onClose={() => setSaveDialogControls({ open: false })}
        showMessage={showMessage}
        accessToken={accessToken}
      />
      <TenantProfileSection title="My Saved Searches" isEditable={false}>
        {isTabletOrAbove ? (
          <CreateTenantTable columns={otherColumns} rows={otherRows} />
        ) : (
          <SavedSearchesAccordian
            searches={savedSearches.results}
            onRowDelete={onRowDelete}
            onRowEdit={onRowEdit}
          />
        )}
      </TenantProfileSection>
    </Container>
  );
};

export default UserSavedSearches;
