import React, { Children } from "react";
import clsx from "clsx";
import { Formik, Form } from "formik";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: "0px",
  },
  formCard: {
    backgroundColor: "#fff",
    maxWidth: "1000px",
    borderRadius: "5px",
    boxShadow: "0px 3px 36px rgba(0, 0, 0, 0.13)",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "0px",
  },
  cardContent: {
    minHeight: "240px",
    paddingTop: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  btnRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(5),
    paddingLeft: theme.spacing(5),
  },
  linearProgress: {
    marginTop: theme.spacing(4),
  },
}));

export default function LandlordScreenFormControls(props) {
  const classes = useStyles();

  const {
    listing,
    onSubmit,
    validationSchema,
    children,
    handleSave,
    className,
    closeDialog,
  } = props;

  function applyProps(children, props) {
    return Children.map(children, child => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, props);
      }
      return child;
    });
  }

  return (
    <Formik
      initialValues={listing}
      validationSchema={validationSchema}
      onSubmit={(values, formikBag) => {
        onSubmit(values, formikBag);
      }}
    >
      {formikprops => (
        <Form className={clsx(classes.root)}>
          <div className={clsx(classes.formCard, className)}>
            <div className={classes.cardContent}>
              {applyProps(children, { formikprops: formikprops })}
              {formikprops.isSubmitting && (
                <LinearProgress className={classes.linearProgress} />
              )}
            </div>
            <div className={clsx(classes.btnRow)}>
              <Button
                variant="contained"
                color="primary"
                disabled={formikprops.isSubmitting}
                onClick={closeDialog}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                align="center"
                disabled={formikprops.isSubmitting}
                onClick={() => handleSave(formikprops)}
              >
                Save
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
