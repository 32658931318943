import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { GLOBALS } from "../App";
import { Container } from "@material-ui/core";
import useListing from "../hooks/useListing";
import Grid from "@material-ui/core/Grid";
import bedIcon from "../static/images/bed_icon.png";
import bathIcon from "../static/images/bath_icon.png";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Hidden from "@material-ui/core/Hidden";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import noImage from "./noimg640.png";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import { getLabel } from "../nestiqaUtils";
import NestiqaAlertDialog from "./NestiqaAlertDialog";
import AcceptTermsDialog from "./AcceptTermsDialog";
import { logEvent } from "../utils/initGa4React";
import { useGA4React } from "ga-4-react";

const useStyles = makeStyles(theme => ({
  cardGrid: {
    padding: theme.spacing(1),
    borderTop: "0px solid #000",
    borderRight: "1px solid #000",
    borderBottom: "1px solid #000",
    borderLeft: "1px solid #000",
    borderRadius: "0px 0px 30px 30px",
  },
  listingsBox: {
    marginBottom: theme.spacing(4),
    marginTop: "5%",
    flexGrow: 1,
  },
  cardMedia: {
    paddingTop: "75%", // 4:3
    borderBottom: "2px solid",
    borderColor: theme.palette.primary.dark,
    borderBottomColor: theme.palette.primary.dark,
  },
  table: {
    minWidth: 650,
  },
  theadTableCell: {
    border: "none",
    fontWeight: "bold",
  },
  tableCell: {
    borderColor: theme.palette.secondary.main,
  },
  cardContent: {
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    padding: "5px 0px 5px 0px",
  },
  card: {
    height: "100%",
    boxShadow: theme.shadows[0],
    position: "relative",
  },
  tableContainer: {
    boxShadow: "none",
  },
  cardFullWidth: {
    flex: "1",
  },
  statusRight: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    flex: "1",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    borderLeft: "1px solid",
    borderRight: "1px solid",
    borderTop: "1px solid",
    borderColor: theme.palette.primary.dark,
    borderBottom: "none",
    borderRadius: "0 20px 0 0",
    textTransform: "capitalize",
    fontSize: "1.2rem",
  },
  headerRight: {
    borderBottom: "1px solid",
    borderColor: theme.palette.primary.dark,
    flexGrow: 2,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  noItemsButton: {
    borderBottom: "1px solid",
    borderColor: theme.palette.primary.dark,
    flexGrow: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export default function ListingTable(props) {
  const {
    listings,
    title,
    showCreate,
    loading,
    error,
    landlord,
    openEditDialog,
    setAcceptedTerms,
    acceptedTerms,
    accessToken,
  } = props;
  const classes = useStyles();
  const [listingParams, setListingParams] = useState();
  const listingResult = useListing(listingParams);
  const history = useHistory();
  const ga = useGA4React();

  const [dialogControls, setDialogControls] = useState({
    open: false,
    title: "",
    message: "",
  });

  const [acceptDialogControls, setAcceptDialogControls] = useState({
    open: false,
  });

  useEffect(() => {
    if (listingResult.status !== "COMPLETE" || !history) return;
    if (listingResult.listing) {
      if (!!ga) {
        logEvent("landlord_create_listing", {
          listing_number: listingResult.listing.listingNumber,
        });
      }
      history.push(
        `/landlord/listing/${listingResult.listing.listingNumber}/update`
      );
    }
  }, [listingResult, history, ga]);

  function preView(event, listingNumber) {
    event.preventDefault();
    history.push(`/landlord/listing/${listingNumber}`);
  }

  function showError(message) {
    setDialogControls({
      open: true,
      title: "Error",
      message,
    });
  }

  const closeAcceptTermsDialog = () => {
    setAcceptDialogControls({ open: false });
  };

  function createListing() {
    if (landlord && landlord.isComplete === "N") {
      showError(GLOBALS.MESSAGES.COMPLETE_LANDLORD_INFO);
    } else if (!acceptedTerms) {
      // Do Nothing
    } else if (acceptedTerms === "N") {
      setAcceptDialogControls({
        open: true,
        onClose: closeAcceptTermsDialog,
        showError,
        createListing: () =>
          setListingParams({
            action: "create-listing",
            listing: {
              status: "DRAFT",
            },
          }),
        accessToken,
        setAcceptedTerms,
      });
    } else {
      setListingParams({
        action: "create-listing",
        listing: {
          status: "DRAFT",
        },
      });
    }
  }

  const closeAlert = () => {
    setDialogControls({ open: false });
    if (landlord?.isComplete === "N") {
      openEditDialog();
    }
  };

  function getStreetAddress(listing) {
    if (!listing.address1) return "NA";
    let result = listing.address1;
    if (listing.address2) result += `, ${listing.address2}`;
    return result;
  }

  if (loading) {
    return (
      <Typography variant="h5" align="center" color="primary">
        Loading...
      </Typography>
    );
  }

  if (error) {
    console.error(error);
    return (
      <Typography variant="h5" align="center" color="error">
        {GLOBALS.MESSAGES.ERROR_MY_LISTINGS}
      </Typography>
    );
  }

  if (listings?.length === 0 && showCreate) {
    return (
      <>
        <AcceptTermsDialog {...acceptDialogControls} />
        <NestiqaAlertDialog {...dialogControls} onClose={closeAlert} />
        <Typography variant="h5" align="center" color="primary">
          {GLOBALS.MESSAGES.NO_LANDLORD_LISTINGS}
        </Typography>
        <div className={classes.noItemsButton} style={{ border: "none" }}>
          <Button
            color="primary"
            variant="contained"
            onClick={createListing}
            className={classes.button}
          >
            Add Listing to Find Tenants
          </Button>
        </div>
      </>
    );
  }

  return (
    <>
      <AcceptTermsDialog {...acceptDialogControls} />
      <NestiqaAlertDialog {...dialogControls} onClose={closeAlert} />
      <Grid item className={classes.listingsBox}>
        <div className={classes.header}>
          <Typography className={classes.title} variant="h6">
            {title}
          </Typography>
          <div className={classes.headerRight}>
            {showCreate && (
              <Button
                color="primary"
                variant="contained"
                onClick={createListing}
                startIcon={<AddIcon />}
                className={classes.button}
              >
                Add Listing
              </Button>
            )}
          </div>
        </div>
        <div className={classes.content}>
          <Container className={classes.cardGrid} maxWidth="lg">
            <Grid container spacing={0}>
              <Hidden only="xs">
                <TableContainer
                  className={classes.tableContainer}
                  component={Paper}
                >
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.theadTableCell}>
                          Image
                        </TableCell>
                        <TableCell
                          className={classes.theadTableCell}
                          align="left"
                        >
                          Address
                        </TableCell>
                        <TableCell
                          className={classes.theadTableCell}
                          align="center"
                        >
                          Rent
                        </TableCell>
                        <TableCell
                          className={classes.theadTableCell}
                          align="center"
                        >
                          Beds/Baths
                        </TableCell>
                        <TableCell
                          className={classes.theadTableCell}
                          align="center"
                        >
                          Status
                        </TableCell>
                        <TableCell
                          className={classes.theadTableCell}
                          align="center"
                        >
                          Days on Market
                        </TableCell>
                        <TableCell
                          className={classes.theadTableCell}
                          align="center"
                        >
                          Open Applications
                        </TableCell>
                        <TableCell
                          className={classes.theadTableCell}
                          align="center"
                        >
                          Open Preapprovals
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {listings.map(row => (
                        <TableRow
                          key={row.id}
                          style={{ cursor: "pointer", verticalAlign: "top" }}
                          hover={true}
                          onClick={event => preView(event, row.listingNumber)}
                        >
                          <TableCell
                            className={classes.tableCell}
                            component="th"
                            scope="row"
                          >
                            <CardMedia
                              className={classes.cardMedia}
                              image={
                                row.mainImage ||
                                GLOBALS.REACT_BASE_URL + noImage
                              }
                              title="Main Image"
                            />
                          </TableCell>
                          <TableCell align="left" className={classes.tableCell}>
                            {getStreetAddress(row)}
                            <br />
                            {row.city ? `${row.city}, ` : ""}
                            {row.state || "-"}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableCell}
                          >
                            {row.rent ? `$${row.rent}` : ""}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableCell}
                          >
                            {getLabel(row.bedrooms, "BEDROOMS") || ""} /{" "}
                            {getLabel(row.bathrooms, "BATHROOMS") || ""}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableCell}
                          >
                            {getLabel(row.status, "STATUSES")}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableCell}
                          >
                            {Number.isInteger(row.daysOnMarket)
                              ? row.daysOnMarket
                              : ""}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableCell}
                          >
                            {Number.isInteger(row.openApplications)
                              ? row.openApplications
                              : ""}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.tableCell}
                          >
                            {Number.isInteger(row.openPrequalifications)
                              ? row.openPrequalifications
                              : ""}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Hidden>
              <Hidden smUp>
                <Grid item key={props.card} className={classes.cardFullWidth}>
                  <Card className={classes.card}>
                    {listings.map(row => (
                      <CardContent
                        key={row.id}
                        className={classes.cardContent}
                        onClick={event => preView(event, row.listingNumber)}
                      >
                        <Grid container spacing={1}>
                          <Grid container item xs={9} spacing={0}>
                            {getStreetAddress(row)}
                          </Grid>
                          <Grid
                            container
                            item
                            xs={3}
                            spacing={0}
                            className={classes.statusRight}
                          >
                            <Box fontWeight="fontWeightBold" pl={1}>
                              {row.rent ? `$${row.rent}` : ""}
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid container spacing={1} alignItems="center">
                          <Grid
                            container
                            item
                            xs={3}
                            spacing={0}
                            alignItems="flex-end"
                          >
                            <Box variant="body1">
                              {getLabel(row.bedrooms, "BEDROOMS")}
                            </Box>
                            <Box pl={1}>
                              <img
                                src={bedIcon}
                                width="30px"
                                height="30px"
                                alt="Bed Icon"
                              />
                            </Box>
                          </Grid>
                          <Grid
                            container
                            item
                            xs={3}
                            spacing={0}
                            alignItems="flex-end"
                          >
                            <Box variant="body1">
                              {getLabel(row.bathrooms, "BATHROOMS")}
                            </Box>
                            <Box pl={1}>
                              <img
                                src={bathIcon}
                                width="30px"
                                height="30px"
                                alt="Bath Icon"
                              />
                            </Box>
                          </Grid>
                          <Grid container item xs={3} spacing={0}>
                            <Box
                              fontWeight="fontWeightBold"
                              pl={1}
                              className={classes.statusRight}
                            >
                              Leads:{" "}
                              {row.openApplications + row.openPrequalifications}
                            </Box>
                          </Grid>
                        </Grid>
                      </CardContent>
                    ))}
                  </Card>
                </Grid>
              </Hidden>
            </Grid>
          </Container>
        </div>
      </Grid>
    </>
  );
}
