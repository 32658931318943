import { GLOBALS } from "../App";
import { camelToSnake, runFetch } from "../nestiqaUtils";

export const getTenantDocuments = async (accessToken, setResultsCallback) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.TENANT_DOCUMENTS}`,
    "get",
    { "Content-Type": "application/json" },
    null,
    accessToken,
    setResultsCallback
  );
};
export const createTenantDocument = async (
  documentFile,
  accessToken,
  setResultsCallback
) => {
  const formData = new FormData();
  formData.set("doc_file", documentFile.file);
  formData.set("doc_name", documentFile.name);
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.TENANT_DOCUMENTS}`,
    "post",
    {},
    formData,
    accessToken,
    setResultsCallback
  );
};

const fetchAndDownload = (fetchUrl, accessToken, setResultsCallback) => {
  fetch(fetchUrl, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    method: "get",
  })
    .then(async res => {
      if (res.ok) {
        let blob = await res.blob();
        let url = URL.createObjectURL(blob);
        setResultsCallback(true, url);
      } else {
        await setResultsCallback(
          false,
          null,
          res.status + " " + res.statusText
        );
      }
    })
    .catch(e => {
      setResultsCallback(false, null, e);
    });
};

export const downloadTenantDocument = async (
  documentId,
  accessToken,
  setResultsCallback
) => {
  await fetchAndDownload(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.TENANT_DOCUMENTS}${documentId}/download/`,
    accessToken,
    setResultsCallback
  );
};

export const downloadApplicationDocument = async (
  applicationId,
  documentId,
  accessToken,
  setResultsCallback
) => {
  await fetchAndDownload(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.LANDLORD_APPLICATION_API}${applicationId}/documents/${documentId}/download/`,
    accessToken,
    setResultsCallback
  );
};

export const downloadAllApplicationDocuments = async (
  applicationId,
  tenantId,
  accessToken,
  setResultsCallback
) => {
  await fetchAndDownload(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.LANDLORD_APPLICATION_API}${applicationId}/documents/all/${tenantId}/download/`,
    accessToken,
    setResultsCallback
  );
};

export const updateTenantDocument = async (
  document,
  accessToken,
  setResultsCallback
) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.TENANT_DOCUMENTS}${document.id}/`,
    "put",
    { "Content-Type": "application/json" },
    JSON.stringify(camelToSnake(document)),
    accessToken,
    setResultsCallback
  );
};

export const deleteTenantDocument = async (
  documentId,
  accessToken,
  setResultsCallback
) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.TENANT_DOCUMENTS}${documentId}/`,
    "delete",
    { "Content-Type": "application/json" },
    null,
    accessToken,
    (isSuccess, apiResult, apiError) => {
      setResultsCallback(
        isSuccess,
        isSuccess ? documentId : null,
        isSuccess ? null : apiError
      );
    }
  );
};
