import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import RoomIcon from "@material-ui/icons/Room";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import React from "react";
import { formatPrice, getLabel } from "../../nestiqaUtils";
import bedIcon from "../../static/images/bed_icon.png";
import bathIcon from "../../static/images/bath_icon.png";
import { format } from "date-fns";
import { GLOBALS } from "../../App";
import noImage from "../noimg640.png";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    userSelect: "none",
    cursor: "pointer",
    display: "flex",
    alignItems: "flex-start",
    padding: theme.spacing(1),
  },
  mainImage: {
    height: "120px",
    width: "150px",
    objectFit: "cover",
    objectPosition: "center",
    marginRight: theme.spacing(2),
  },
  details: {
    display: "flex",
    justifyContent: "space-between",
  },
  address: {
    display: "flex",
    marginRight: theme.spacing(2),
    "& .MuiTypography-body1": {
      marginLeft: theme.spacing(1),
    },
  },
  availibilityDate: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(2),

    "& .MuiSvgIcon-root": {
      marginRight: theme.spacing(1),
    },
  },
  addressDateDetails: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  subDetails: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    textAlign: "right",

    "& .MuiTypography-h6": {
      lineHeight: 1.15,
    },
  },
  bedBathDetails: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    marginTop: theme.spacing(1),
  },
  bedBath: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "baseline",
    marginLeft: theme.spacing(2),
  },
  icons: {
    width: "21px",
    height: "21px",
    marginLeft: "7px",
  },
  [theme.breakpoints.down("md")]: {
    mainImage: {
      height: "90px",
      width: "120px",
    },
    availibilityDate: {
      display: "none",
    },
    details: {
      flexDirection: "column",
      justifyContent: "flex-start",
    },
    subDetails: {
      flexDirection: "row-reverse",
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(4),
      alignItems: "center",
      justifyContent: "flex-end",
      flexWrap: "wrap",
      textAlign: "left",
    },
    bedBathDetails: {
      marginTop: theme.spacing(0),
      marginRight: "auto",
      flexDirection: "row",
    },
    bedBath: {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(0),
    },
  },
  [theme.breakpoints.down(375)]: {
    mainImage: {
      margin: "0 auto",
      marginBottom: theme.spacing(1),
    },
    root: {
      flexDirection: "column",
    },
    details: {
      padding: "0px",
    },
  },
}));

const ListingInfo = ({ listing, onClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.root} onClick={onClick}>
      <img
        className={classes.mainImage}
        src={listing.mainImage || GLOBALS.REACT_BASE_URL + noImage}
        alt={listing.fullAddress}
      />
      <div className={classes.details}>
        <div className={classes.addressDateDetails}>
          <div className={classes.address}>
            <RoomIcon color="secondary" />
            <Typography variant="body1">{listing.fullAddress}</Typography>
          </div>
          <div className={classes.availibilityDate}>
            <CalendarTodayIcon color="secondary" />{" "}
            <Typography variant="body1">
              <strong>Availability Date:</strong>{" "}
              {listing.availabilityDate
                ? format(listing.availabilityDate, GLOBALS.DATE_FORMAT)
                : "N/A"}
            </Typography>
          </div>
        </div>

        <div className={classes.subDetails}>
          <Typography variant="h6" color="primary">{`${formatPrice(
            listing.rent
          )}/mo`}</Typography>
          <div className={classes.bedBathDetails}>
            <div className={classes.bedBath}>
              <span>{getLabel(listing.bedrooms, "BEDROOMS")}</span>
              <img className={classes.icons} src={bedIcon} alt="bedrooms" />
            </div>
            <div className={classes.bedBath}>
              <span>{getLabel(listing.bathrooms, "BATHROOMS")}</span>
              <img className={classes.icons} src={bathIcon} alt="bathrooms" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingInfo;
