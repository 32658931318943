import React, { useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { Formik, Form, Field, FieldArray } from "formik";
import { TextField } from "formik-material-ui";
import {
  Typography,
  Box,
  Button,
  InputLabel,
  useMediaQuery,
  Tooltip,
  Link,
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import { KeyboardDatePicker } from "formik-material-ui-pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PhoneIphoneOutlinedIcon from "@material-ui/icons/PhoneIphoneOutlined";
import AttachMoneyOutlinedIcon from "@material-ui/icons/AttachMoneyOutlined";
import InfoIcon from "@material-ui/icons/Info";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {
  DeleteOutline as DeleteIcon,
  EditOutlined as EditIcon,
} from "@material-ui/icons";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import * as yup from "yup";

import { GLOBALS } from "../../App";
import TenantFormControls from "./TenantFormControls";
import {
  getTenantApplications,
  updateEmploymentHistory,
} from "../../utils/TenantApi";
import PriceField from "../PriceField";
import YesNoField from "../YesNoField";
import PhoneNumberField from "../PhoneNumberField";
import CreateTenantTable from "./CreateTenantTable";
import CreateTenantDialog from "./CreateTenantDialog";
import TenantEmploymentHistoryAccordion from "../tenant-profile/TenantEmploymentHistoryAccordian";
import {
  checkFormikError,
  formatter,
  sortByYesNoFieldAndDates,
} from "../../utils/common";
import GoogleMapsAutoComplete from "../google-maps/GoogleMapsAutocomplete";
import { logEvent } from "../../utils/initGa4React";
import { useGA4React } from "ga-4-react";
import { getLabel } from "../../nestiqaUtils";
import ChoiceField from "../ChoiceField";

const useStyles = makeStyles(theme => ({
  tooltip: {
    fontSize: "12px",
    margin: 0,
    padding: "5px",
  },
  icon: {
    fontSize: props => (props.iconSize !== "" ? props.iconSize : "1.5rem"),
  },
  topRow: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  tableTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(4),
  },
  left: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  error: {
    fontSize: "0.75rem",
    color: "#FF2D44",
    marginTop: "3px",
  },
  addButton: {
    marginLeft: theme.spacing(4),
    whiteSpace: "nowrap",
  },
  formButtonContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginTop: theme.spacing(3),
  },
  btnContainer: {
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "column",
  },
  inputField: {
    width: "100%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),

    "& .MuiInputLabel-root": {
      color: theme.palette.grey[400],
    },

    "& .MuiInputLabel-shrink": {
      color: theme.palette.primary.main,
    },
  },
  annualSalary: {
    display: "flex",
    alignItems: "center",

    "& > .MuiSvgIcon-root": {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
  },
  isCurrentRowField: {
    flexBasis: "50%",
  },
  datePicker: {},
  isCurrentRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  mobileRow: {
    display: "flex",
    flexDirection: "column",
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
  },
  mobileTopRow: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
  mobileBottomRow: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: theme.spacing(3),
  },
  [theme.breakpoints.down("xs")]: {
    topRow: {
      flexDirection: "column",

      "& .MuiFormControlLabel-root": {
        display: "block",
        margin: `0 auto`,
        marginTop: theme.spacing(2),
      },
    },
    checkboxContainer: {
      marginLeft: "-12px !important",
    },
    addButton: {
      marginTop: theme.spacing(2),
      marginLeft: "0px",
    },
    grid: {
      gridTemplateColumns: "1fr",
    },
  },
}));

export default function TenantEmploymentHistory(props) {
  const {
    accessToken,
    tenant,
    setTenant,
    step,
    setStep,
    showError,
    noPreviousEmployment,
    setCheckBoxValue,
    isEditDialog = false,
    closeDialog,
  } = props;
  const ga = useGA4React();

  const setNoPreviousEmployment = value => {
    setCheckBoxValue("noPreviousEmployment", value);
  };

  function onStepSubmit(values, formikBag) {
    if (!!ga) {
      logEvent("tenant_complete_step", { step: step });
    }

    updateEmploymentHistory(
      values,
      accessToken,
      (isSuccess, apiResult, apiError) => {
        if (isSuccess) {
          getTenantApplications(
            tenant.id,
            accessToken,
            (isSuccess, apiResult, apiError) => {
              if (isSuccess) {
                setTenant({
                  ...apiResult,
                });
                if (isEditDialog) {
                  closeDialog();
                } else {
                  setStep(step + 1);
                }
              } else {
                console.error(apiError);
                showError(GLOBALS.MESSAGES.ERROR_UPDATING_TENANT);
                formikBag.setSubmitting(false);
              }
            }
          );
        } else {
          console.error(apiError);
          showError(GLOBALS.MESSAGES.ERROR_UPDATING_TENANT);
          formikBag.setSubmitting(false);
        }
      }
    );
  }

  function handleNext(formikprops) {
    if (
      formikprops.values.employmentHistory.length === 0 &&
      !noPreviousEmployment.state
    ) {
      setNoPreviousEmployment({ error: true });
    } else {
      setNoPreviousEmployment({ error: false });

      if (formikprops.dirty) {
        formikprops.submitForm();
      } else {
        if (isEditDialog) {
          closeDialog();
        } else {
          setStep(step + 1);
        }
      }
    }
  }

  const validationSchema = yup.object().shape({
    employmentHistory: yup.array().of(
      yup.object().shape({
        startDate: yup.date().typeError(GLOBALS.MESSAGES.ENTER_VALID_DATE),
        endDate: yup
          .mixed()
          .nullable()
          .when("isCurrent", {
            is: "N",
            then: yup
              .date()
              .required(GLOBALS.MESSAGES.END_DATE_OR_CURRENT_REQUIRED),
          }),
        address: yup.string().when("incomeType", {
          is: "EMPLOYMENT",
          then: yup.string().required(GLOBALS.MESSAGES.FIELD_REQUIRED),
        }),
        employer: yup.string().when("incomeType", {
          is: "EMPLOYMENT",
          then: yup.string().required(GLOBALS.MESSAGES.FIELD_REQUIRED),
        }),
        jobTitle: yup.string().when("incomeType", {
          is: "EMPLOYMENT",
          then: yup.string().required(GLOBALS.MESSAGES.FIELD_REQUIRED),
        }),
        annualSalary: yup.string().required(GLOBALS.MESSAGES.FIELD_REQUIRED),
        isCurrent: yup.string().required(GLOBALS.MESSAGES.FIELD_REQUIRED),
        incomeType: yup.string().required(GLOBALS.MESSAGES.FIELD_REQUIRED),
        otherIncomeInfo: yup
          .string()
          .nullable()
          .when("incomeType", {
            is: "OTHER_INCOME",
            then: yup
              .string()
              .max(50)
              .required(GLOBALS.MESSAGES.FIELD_REQUIRED)
              .label("Other Income Information"),
          }),
      })
    ),
  });

  return (
    <TenantFormControls
      tenant={tenant}
      step={step}
      setStep={setStep}
      onSubmit={onStepSubmit}
      validationSchema={validationSchema}
      handleNext={handleNext}
      isEditDialog={isEditDialog}
      closeDialog={closeDialog}
    >
      <EmploymentHistoryFormikWrapper
        noPreviousEmployment={noPreviousEmployment}
        setNoPreviousEmployment={setNoPreviousEmployment}
      />
    </TenantFormControls>
  );
}

function EmploymentHistoryFormikWrapper({
  formikprops,
  noPreviousEmployment,
  setNoPreviousEmployment,
}) {
  const classes = useStyles();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));
  const arrayHelpersRef = useRef(null);
  const [jobDialogOpen, setJobDialogOpen] = useState(false);
  const [otherDialogOpen, setOtherDialogOpen] = useState(false);

  const sortedHistory = useMemo(() => {
    return sortByYesNoFieldAndDates(
      formikprops.values.employmentHistory,
      "isCurrent",
      "startDate",
      "endDate"
    );
  }, [formikprops.values.employmentHistory]);

  const [dialogForm, setDialogForm] = useState({
    index: sortedHistory.length,
    isNew: true,
    initialValues: {
      startDate: null,
      endDate: null,
      employer: "",
      jobTitle: "",
      address: "",
      contactName: "",
      contactPhoneNumber: "",
      annualSalary: "",
      isCurrent: "",
      incomeType: "",
      otherIncomeInfo: "",
    },
  });

  const resetDialogForm = () => {
    setDialogForm({
      index: sortedHistory.length,
      isNew: true,
      initialValues: {
        startDate: null,
        endDate: null,
        employer: "",
        jobTitle: "",
        address: "",
        contactName: "",
        contactPhoneNumber: "",
        annualSalary: "",
        isCurrent: "",
        incomeType: "",
        otherIncomeInfo: "",
      },
    });
  };

  const handleJobDialogClose = () => {
    setJobDialogOpen(false);
    resetDialogForm();
  };

  const handleOtherDialogClose = () => {
    setOtherDialogOpen(false);
    resetDialogForm();
  };

  const handleJobDialogOpen = () => {
    setJobDialogOpen(true);
  };

  const handleOtherDialogOpen = () => {
    setOtherDialogOpen(true);
  };

  const onRowDelete = index => {
    arrayHelpersRef.current?.remove(index);
  };

  const onJobEdit = index => {
    setDialogForm({
      index,
      isNew: false,
      initialValues: sortedHistory[index],
    });
    handleJobDialogOpen();
  };

  const onOtherEdit = index => {
    setDialogForm({
      index,
      isNew: false,
      initialValues: sortedHistory[index],
    });
    handleOtherDialogOpen();
  };

  const onAddJob = () => {
    setDialogForm(prev => ({
      ...prev,
      initialValues: { ...prev.initialValues, incomeType: "EMPLOYMENT" },
    }));
    handleJobDialogOpen();
  };

  const onAddOther = () => {
    setDialogForm(prev => ({
      ...prev,
      initialValues: { ...prev.initialValues, isCurrent: "Y" },
    }));
    handleOtherDialogOpen();
  };

  const onDialogSubmit = (isNew, index, row) => {
    if (isNew) {
      arrayHelpersRef.current.push({ ...row });
    } else {
      arrayHelpersRef.current.replace(index, row);
    }
    handleJobDialogClose();
    handleOtherDialogClose();
  };

  const jobColumns = [
    {
      field: "dates",
      headerName: "Dates",
    },
    {
      field: "jobTitle",
      headerName: "Position",
    },
    {
      field: "address",
      headerName: "Employer Address",
    },
    {
      field: "contact",
      headerName: "Contact",
    },
    {
      field: "annualSalary",
      headerName: "Annual Salary",
    },
    {
      field: "actions",
      headerName: "Actions",
    },
  ];

  const otherColumns = [
    {
      field: "dates",
      headerName: "Dates",
    },
    {
      field: "incomeType",
      headerName: "Income Type",
    },
    {
      field: "contact",
      headerName: "Contact",
    },
    {
      field: "annualSalary",
      headerName: "Annual Income",
    },
    {
      field: "actions",
      headerName: "Actions",
    },
  ];

  const jobRows = sortedHistory
    .map((history, index) => {
      if (history.incomeType !== "EMPLOYMENT") {
        return null;
      }
      return [
        {
          field: "dates",
          value:
            format(new Date(history.startDate), GLOBALS.DATE_FORMAT) +
            " - " +
            (history.isCurrent === "Y"
              ? "Current"
              : history.endDate
              ? format(new Date(history.endDate), GLOBALS.DATE_FORMAT)
              : ""),
        },
        {
          field: "jobTitle",
          value: `${history.jobTitle || "-"} at ${history.employer || "-"}`,
        },
        {
          field: "address",
          value: history.address || "-",
        },
        {
          field: "contact",
          value: (
            <>
              {history.contactName || "-"}
              <br />
              {history.contactPhoneNumber || "-"}
            </>
          ),
        },
        {
          field: "annualSalary",
          value: formatter.format(history.annualSalary),
        },
        {
          field: "actions",
          value: (
            <>
              <IconButton
                edge="end"
                aria-label="edit"
                onClick={() => onJobEdit(index)}
              >
                <EditIcon color="secondary" />
              </IconButton>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => onRowDelete(index)}
              >
                <DeleteIcon color="secondary" />
              </IconButton>
            </>
          ),
        },
      ];
    })
    .filter(history => history);

  const otherRows = sortedHistory
    .map((history, index) => {
      if (history.incomeType === "EMPLOYMENT") {
        return null;
      }
      return [
        {
          field: "dates",
          value:
            format(new Date(history.startDate), GLOBALS.DATE_FORMAT) +
            " - " +
            (history.isCurrent === "Y"
              ? "Current"
              : history.endDate
              ? format(new Date(history.endDate), GLOBALS.DATE_FORMAT)
              : ""),
        },
        {
          field: "incomeType",
          value: getLabel(history.incomeType, "INCOME_TYPE"),
        },
        {
          field: "contact",
          value: (
            <>
              {history.contactName || "-"}
              <br />
              {history.contactPhoneNumber || "-"}
            </>
          ),
        },
        {
          field: "annualSalary",
          value: formatter.format(history.annualSalary),
        },
        {
          field: "actions",
          value: (
            <>
              <IconButton
                edge="end"
                aria-label="edit"
                onClick={() => onOtherEdit(index)}
              >
                <EditIcon color="secondary" />
              </IconButton>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => onRowDelete(index)}
              >
                <DeleteIcon color="secondary" />
              </IconButton>
            </>
          ),
        },
      ];
    })
    .filter(history => history);

  const employmentHistory = sortedHistory.map((history, index) => ({
    ...history,
    actions: (
      <>
        <IconButton
          edge="end"
          aria-label="edit"
          onClick={
            history.incomeType === "EMPLOYMENT"
              ? () => onJobEdit(index)
              : () => onOtherEdit(index)
          }
        >
          <EditIcon color="secondary" />
        </IconButton>
        <IconButton
          edge="end"
          aria-label="delete"
          onClick={() => onRowDelete(index)}
        >
          <DeleteIcon color="secondary" />
        </IconButton>
      </>
    ),
  }));

  const jobHistory = employmentHistory.filter(
    history => history.incomeType === "EMPLOYMENT"
  );

  const otherHistory = employmentHistory.filter(
    history => history.incomeType !== "EMPLOYMENT"
  );

  return (
    <Box justifyContent="left" textAlign="left">
      <div className={classes.topRow}>
        <div className={classes.left}>
          <Typography variant="body1">
            {GLOBALS.MESSAGES.ADD_EMPLOYMENT_HISTORY}
          </Typography>
          <FormControlLabel
            className={classes.checkboxContainer}
            control={
              <Checkbox
                disabled={sortedHistory.length > 0}
                checked={noPreviousEmployment.state}
                onChange={e =>
                  setNoPreviousEmployment({ state: e.target.checked })
                }
                name="noPreviousEmployment"
                color="primary"
                // edge="start"
              />
            }
            label={GLOBALS.MESSAGES.NO_EMPLOYMENT_HISTORY}
          />
          {noPreviousEmployment.error &&
            sortedHistory.length === 0 &&
            !noPreviousEmployment.state && (
              <Typography variant="body2" className={classes.error}>
                {GLOBALS.MESSAGES.ADD_EMPLOYMENT_HISTORY_ERROR}
              </Typography>
            )}
          {sortedHistory.length === 0 && noPreviousEmployment.state && (
            <Typography variant="body2" className={classes.error}>
              {GLOBALS.MESSAGES.NO_EMPLOYMENT_HISTORY_WARNING}
            </Typography>
          )}
        </div>
      </div>

      <div className={classes.tableTitle}>
        <Typography variant="h6" color="primary">
          Employment History
        </Typography>
        <Button
          disabled={noPreviousEmployment.state}
          color="primary"
          variant="contained"
          className={classes.addButton}
          onClick={onAddJob}
        >
          Add Job
        </Button>
      </div>

      {isMobile ? (
        <TenantEmploymentHistoryAccordion employmentHistory={jobHistory} />
      ) : jobRows.length !== 0 ? (
        <CreateTenantTable columns={jobColumns} rows={jobRows} />
      ) : null}

      <div className={classes.tableTitle}>
        <Typography variant="h6" color="primary">
          Other Income
        </Typography>
        <Button
          disabled={noPreviousEmployment.state}
          color="primary"
          variant="contained"
          className={classes.addButton}
          onClick={onAddOther}
        >
          Add other Income
        </Button>
      </div>

      {isMobile ? (
        <TenantEmploymentHistoryAccordion
          isOtherIncome
          employmentHistory={otherHistory}
        />
      ) : otherRows.length !== 0 ? (
        <CreateTenantTable columns={otherColumns} rows={otherRows} />
      ) : null}

      <FieldArray
        name="employmentHistory"
        render={arrayHelpers => {
          arrayHelpersRef.current = arrayHelpers;
          return null;
        }}
      />

      <CreateTenantDialog
        open={jobDialogOpen}
        title={dialogForm.isNew ? "Add Job" : "Edit Job"}
        onClose={handleJobDialogClose}
        form={
          <AddJobHistoryForm
            handleJobDialogClose={handleJobDialogClose}
            onSubmit={onDialogSubmit}
            {...dialogForm}
          />
        }
      />

      <CreateTenantDialog
        open={otherDialogOpen}
        title={dialogForm.isNew ? "Add Other Income" : "Edit Other Income"}
        onClose={handleOtherDialogClose}
        form={
          <AddOtherHistoryForm
            handleOtherDialogClose={handleOtherDialogClose}
            onSubmit={onDialogSubmit}
            {...dialogForm}
          />
        }
      />
    </Box>
  );
}

const AddJobHistoryForm = ({
  handleJobDialogClose,
  onSubmit,
  initialValues,
  index,
  isNew,
}) => {
  const classes = useStyles();

  //Separate validation for phone number to allow either empty or valid US phone
  function validatePhone(value) {
    let error;
    if (value) {
      let phoneField = yup
        .string()
        .matches(
          GLOBALS.PHONE_NUMBER_REGEX,
          GLOBALS.MESSAGES.ENTER_VALID_PHONE
        );
      if (!phoneField.isValidSync(value)) {
        error = GLOBALS.MESSAGES.ENTER_VALID_PHONE;
      }
    }
    return error;
  }

  const addEmploymentHistoryValidationSchema = yup.object().shape({
    startDate: yup.date().typeError(GLOBALS.MESSAGES.ENTER_VALID_DATE),
    endDate: yup
      .date()
      .nullable()
      .when("isCurrent", {
        is: "N",
        then: yup
          .date()
          .required(GLOBALS.MESSAGES.END_DATE_OR_CURRENT_REQUIRED),
      }),
    address: yup.string().required(GLOBALS.MESSAGES.FIELD_REQUIRED),
    employer: yup.string().required(GLOBALS.MESSAGES.FIELD_REQUIRED),
    jobTitle: yup.string().required(GLOBALS.MESSAGES.FIELD_REQUIRED),
    annualSalary: yup.string().required(GLOBALS.MESSAGES.FIELD_REQUIRED),
    isCurrent: yup.string().required(GLOBALS.MESSAGES.FIELD_REQUIRED),
    incomeType: yup.string().required(GLOBALS.MESSAGES.FIELD_REQUIRED),
    otherIncomeInfo: yup
      .string()
      .nullable()
      .when("incomeType", {
        is: "OTHER_INCOME",
        then: yup.string().max(50).required(GLOBALS.MESSAGES.FIELD_REQUIRED),
      }),
  });

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={addEmploymentHistoryValidationSchema}
        onSubmit={(values, formikBag) => {
          onSubmit(isNew, index, {
            ...values,
          });
        }}
      >
        {formikprops => {
          const { values, setValues } = formikprops;

          const handleAddressChange = (newPlace, manual) => {
            if (!newPlace) return;
            if (manual) {
              setValues({
                ...values,
                address: newPlace.address_components[0].short_name,
              });
            } else {
              setValues({ ...values, address: newPlace.formatted_address });
            }
          };

          return (
            <Form>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className={classes.grid}>
                  <div className={clsx(classes.gridItem, classes.annualSalary)}>
                    <Field
                      className={clsx(classes.inputField)}
                      component={PriceField}
                      required
                      name={`annualSalary`}
                      type="text"
                      label="Annual Salary"
                      variant="outlined"
                      margin="normal"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <AttachMoneyOutlinedIcon color="secondary" />
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        autoComplete: "off",
                      }}
                    />
                    <Tooltip
                      interactive
                      enterTouchDelay={0}
                      placement="top"
                      title={
                        <p className={classes.tooltip}>
                          {GLOBALS.MESSAGES.FIND_ANNUAL_SALARY}{" "}
                          <Link
                            component="a"
                            underline="none"
                            color="secondary"
                            href="https://resources.nestiqa.com/faq/#my-annual-salary"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            click here
                          </Link>
                        </p>
                      }
                    >
                      <InfoIcon className={classes.icon} color="secondary" />
                    </Tooltip>
                  </div>

                  <div className={clsx(classes.gridItem, classes.isCurrentRow)}>
                    <InputLabel htmlFor="isCurrent">
                      Is this your current job?
                    </InputLabel>
                    <YesNoField
                      name={`isCurrent`}
                      className={clsx(classes.isCurrentRowField)}
                      required
                      variant="outlined"
                      margin="normal"
                      defaultValue="N"
                      label=""
                      handleBlur={formikprops.handleBlur}
                    />
                  </div>
                  <Field
                    className={clsx(
                      classes.gridItem,
                      classes.inputField,
                      classes.datePicker
                    )}
                    component={KeyboardDatePicker}
                    required
                    name={`startDate`}
                    label="Start Date"
                    format="MM/dd/yyyy"
                    disableFuture
                    inputVariant="outlined"
                    variant="inline"
                    margin="normal"
                    autoOk
                    KeyboardButtonProps={{ edge: "end" }}
                    keyboardIcon={<EventOutlinedIcon color="secondary" />}
                    inputProps={{
                      autoComplete: "off",
                    }}
                    InputLabelProps={{
                      shrink: formikprops.values.startDate !== null,
                    }}
                  />
                  {formikprops.values.isCurrent === "Y" ? (
                    <div />
                  ) : (
                    <Field
                      className={clsx(
                        classes.gridItem,
                        classes.inputField,
                        classes.datePicker
                      )}
                      component={KeyboardDatePicker}
                      required={formikprops.values.isCurrent === "N"}
                      disabled={
                        formikprops.values.isCurrent === "Y" ||
                        !formikprops.values.startDate
                      }
                      minDate={new Date(formikprops.values.startDate)}
                      minDateMessage={
                        GLOBALS.MESSAGES.END_DATE_BEFORE_START_DATE
                      }
                      name={`endDate`}
                      label="End Date"
                      format="MM/dd/yyyy"
                      disableFuture
                      inputVariant="outlined"
                      variant="inline"
                      margin="normal"
                      autoOk
                      KeyboardButtonProps={{ edge: "end" }}
                      keyboardIcon={
                        <EventOutlinedIcon
                          color={
                            formikprops.values.isCurrent === "Y" ||
                            !formikprops.values.startDate
                              ? "disabled"
                              : "secondary"
                          }
                        />
                      }
                      inputProps={{
                        autoComplete: "off",
                      }}
                      InputLabelProps={{
                        shrink: formikprops.values.endDate !== null,
                      }}
                    />
                  )}
                </div>
              </MuiPickersUtilsProvider>

              <Field
                className={clsx(classes.inputField)}
                component={TextField}
                required
                name={`employer`}
                type="text"
                label="Employer"
                variant="outlined"
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PersonOutlineIcon color="secondary" />
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  autoComplete: "off",
                }}
              />
              <Field
                className={clsx(classes.inputField)}
                component={TextField}
                required
                name={`jobTitle`}
                type="text"
                label="Job Title"
                variant="outlined"
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <WorkOutlineIcon color="secondary" />
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  autoComplete: "off",
                }}
              />
              <GoogleMapsAutoComplete
                className={classes.inputField}
                label="Address"
                required
                inputVariant="outlined"
                name="address"
                popupIcon={null}
                resultTypes={["address"]}
                initialValue={formikprops.values.address}
                shortLabel
                allowManual
                onInputChange={newInputValue =>
                  setValues({ ...values, address: newInputValue })
                }
                handlePlaceChange={(place, placeSize, manual) =>
                  handleAddressChange(place, manual)
                }
              />
              <Field
                className={clsx(classes.inputField)}
                component={TextField}
                name={`contactName`}
                type="text"
                label="Contact Name"
                variant="outlined"
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PersonOutlineIcon color="secondary" />
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  autoComplete: "off",
                }}
              />
              <Field
                className={clsx(classes.inputField)}
                component={PhoneNumberField}
                name={`contactPhoneNumber`}
                type="text"
                label="Contact Phone"
                variant="outlined"
                margin="normal"
                validate={validatePhone}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PhoneIphoneOutlinedIcon color="secondary" />
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  autoComplete: "off",
                }}
              />

              <div className={classes.formButtonContainer}>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={handleJobDialogClose}
                >
                  Cancel
                </Button>
                <div className={classes.btnContainer}>
                  <Button color="primary" variant="contained" type="submit">
                    Save
                  </Button>
                  {checkFormikError(formikprops) && (
                    <span className={classes.error}>
                      {GLOBALS.MESSAGES.CORRECT_FORM_ERRORS}
                    </span>
                  )}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

const AddOtherHistoryForm = ({
  handleOtherDialogClose,
  onSubmit,
  initialValues,
  index,
  isNew,
}) => {
  const classes = useStyles();

  //Separate validation for phone number to allow either empty or valid US phone
  function validatePhone(value) {
    let error;
    if (value) {
      let phoneField = yup
        .string()
        .matches(
          GLOBALS.PHONE_NUMBER_REGEX,
          GLOBALS.MESSAGES.ENTER_VALID_PHONE
        );

      if (!phoneField.isValidSync(value)) {
        error = GLOBALS.MESSAGES.ENTER_VALID_PHONE;
      }
    }
    return error;
  }

  const addEmploymentHistoryValidationSchema = yup.object().shape({
    startDate: yup.date().typeError(GLOBALS.MESSAGES.ENTER_VALID_DATE),
    endDate: yup
      .date()
      .nullable()
      .when("isCurrent", {
        is: "N",
        then: yup
          .date()
          .required(GLOBALS.MESSAGES.END_DATE_OR_CURRENT_REQUIRED),
      }),
    // Actually monthly income in this case
    annualSalary: yup.string().required(GLOBALS.MESSAGES.FIELD_REQUIRED),
    isCurrent: yup.string().required(GLOBALS.MESSAGES.FIELD_REQUIRED),
    incomeType: yup.string().required(GLOBALS.MESSAGES.FIELD_REQUIRED),
    otherIncomeInfo: yup
      .string()
      .nullable()
      .when("incomeType", {
        is: "OTHER_INCOME",
        then: yup.string().max(50).required(GLOBALS.MESSAGES.FIELD_REQUIRED),
      }),
  });

  return (
    <>
      <Formik
        initialValues={{
          ...initialValues,
          // convery annual salary to monthly income
          annualSalary: initialValues.annualSalary / 12,
        }}
        validationSchema={addEmploymentHistoryValidationSchema}
        onSubmit={(values, formikBag) => {
          onSubmit(isNew, index, {
            ...values,
            // convery monthly income to annual salary
            annualSalary: values.annualSalary * 12,
          });
        }}
      >
        {formikprops => {
          return (
            <Form>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Field
                  className={clsx(classes.inputField, classes.datePicker)}
                  component={KeyboardDatePicker}
                  required
                  name={`startDate`}
                  label="Start Date"
                  format="MM/dd/yyyy"
                  disableFuture
                  inputVariant="outlined"
                  variant="inline"
                  margin="normal"
                  autoOk
                  KeyboardButtonProps={{ edge: "end" }}
                  keyboardIcon={<EventOutlinedIcon color="secondary" />}
                  inputProps={{
                    autoComplete: "off",
                  }}
                  InputLabelProps={{
                    shrink: formikprops.values.startDate !== null,
                  }}
                />
              </MuiPickersUtilsProvider>
              <Field
                className={clsx(classes.inputField)}
                component={ChoiceField}
                required
                name={`incomeType`}
                type="text"
                label="Income Type"
                variant="outlined"
                margin="normal"
                choices={GLOBALS.INCOME_TYPE.filter(
                  op => op.value !== "EMPLOYMENT"
                )}
                inputProps={{
                  autoComplete: "off",
                }}
              />
              {formikprops.values.incomeType !== "OTHER_INCOME" ? (
                <div />
              ) : (
                <Field
                  className={clsx(classes.inputField)}
                  component={TextField}
                  name={`otherIncomeInfo`}
                  type="text"
                  label="Other Income Information"
                  variant="outlined"
                  margin="normal"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <InfoOutlinedIcon color="secondary" />
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    autoComplete: "off",
                  }}
                />
              )}

              <Field
                className={clsx(classes.inputField)}
                component={TextField}
                name={`contactName`}
                type="text"
                label="Contact Name"
                variant="outlined"
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PersonOutlineIcon color="secondary" />
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  autoComplete: "off",
                }}
              />
              <Field
                className={clsx(classes.inputField)}
                component={PhoneNumberField}
                name={`contactPhoneNumber`}
                type="text"
                label="Contact Phone"
                variant="outlined"
                margin="normal"
                validate={validatePhone}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <PhoneIphoneOutlinedIcon color="secondary" />
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  autoComplete: "off",
                }}
              />
              <Field
                className={clsx(classes.inputField)}
                component={PriceField}
                required
                name={`annualSalary`}
                type="text"
                label="Monthly Income"
                variant="outlined"
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <AttachMoneyOutlinedIcon color="secondary" />
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  autoComplete: "off",
                }}
              />

              <div className={classes.formButtonContainer}>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={handleOtherDialogClose}
                >
                  Cancel
                </Button>
                <div className={classes.btnContainer}>
                  <Button color="primary" variant="contained" type="submit">
                    Save
                  </Button>
                  {checkFormikError(formikprops) && (
                    <span className={classes.error}>
                      {GLOBALS.MESSAGES.CORRECT_FORM_ERRORS}
                    </span>
                  )}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
