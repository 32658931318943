import React, { useEffect } from "react";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import { Typography, Box, FormControlLabel, Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { GLOBALS } from "../../App";
import TenantFormControls from "./TenantFormControls";
import { updateTenant } from "../../utils/TenantApi";
import * as yup from "yup";

const useStyles = makeStyles(theme => ({
  textArea: {
    width: "100%",
    marginBottom: theme.spacing(4),

    "& .MuiInputLabel-root": {
      color: theme.palette.grey[400],
    },

    "& .MuiInputLabel-shrink": {
      color: theme.palette.primary.main,
    },
  },
  checkboxContainer: {
    marginLeft: "-12px !important",
  },
  error: {
    fontSize: "0.75rem",
    color: "#FF2D44",
    marginTop: "3px",
  },
}));

export default function TenantAdditionalInfo(props) {
  const {
    accessToken,
    tenant,
    setTenant,
    step,
    setStep,
    showError,
    isEditDialog = false,
    closeDialog,
    noPets,
    noVehicles,
    setCheckBoxValue,
  } = props;

  const setNoPets = value => {
    setCheckBoxValue("noPets", value);
  };

  const setNoVehicles = value => {
    setCheckBoxValue("noVehicles", value);
  };

  function onStepSubmit(values, formikBag) {
    const updatedValues = {
      id: values.id,
      vehicles: values.vehicles,
      pets: values.pets,
    };
    updateTenant(
      updatedValues,
      accessToken,
      (isSuccess, apiResult, apiError) => {
        if (isSuccess) {
          setTenant(apiResult);
          if (isEditDialog) {
            closeDialog();
          } else {
            setStep(step + 1);
          }
        } else {
          console.error(apiError);
          showError(GLOBALS.MESSAGES.ERROR_UPDATING_TENANT);
          formikBag.setSubmitting(false);
        }
      }
    );
  }

  function handleNext(formikprops) {
    if (
      (formikprops.values.pets.length === 0 && !noPets.state) ||
      (formikprops.values.vehicles.length === 0 && !noVehicles.state)
    ) {
      if (formikprops.values.vehicles.length === 0 && !noVehicles.state) {
        setNoVehicles({ error: true });
      }
      if (formikprops.values.pets.length === 0 && !noPets.state) {
        setNoPets({ error: true });
      }
    } else {
      setNoPets({ error: false });
      setNoVehicles({ error: false });

      if (formikprops.dirty) {
        formikprops.submitForm();
      } else {
        if (isEditDialog) {
          closeDialog();
        } else {
          setStep(step + 1);
        }
      }
    }
  }

  const validationSchema = yup.object().shape({});

  return (
    <TenantFormControls
      tenant={tenant}
      step={step}
      setStep={setStep}
      onSubmit={onStepSubmit}
      validationSchema={validationSchema}
      handleNext={handleNext}
      isEditDialog={isEditDialog}
      closeDialog={closeDialog}
    >
      <AdditionalInfoFormikWrapper
        noPets={noPets}
        setNoPets={setNoPets}
        noVehicles={noVehicles}
        setNoVehicles={setNoVehicles}
      />
    </TenantFormControls>
  );
}

function AdditionalInfoFormikWrapper(props) {
  const classes = useStyles();
  const {
    isEditDialog,
    formikprops: {
      values: { pets, vehicles },
    },
    noPets,
    setNoPets,
    noVehicles,
    setNoVehicles,
  } = props;

  useEffect(() => {
    if (isEditDialog) {
      if (pets) {
        setNoPets(false);
      } else {
        setNoPets(true);
      }

      if (vehicles) {
        setNoVehicles(false);
      } else {
        setNoVehicles(true);
      }
    } else {
      setNoPets(false);
      setNoVehicles(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditDialog, pets, vehicles]);

  return (
    <Box justifyContent="left" textAlign="left">
      <Typography variant="body1">
        {GLOBALS.SITE_TEXTS.PETS_QUESTION}
      </Typography>
      <FormControlLabel
        className={classes.checkboxContainer}
        control={
          <Checkbox
            disabled={pets}
            checked={noPets.state}
            onChange={e => setNoPets({ state: e.target.checked })}
            name="noPets"
            color="primary"
          />
        }
        label={GLOBALS.MESSAGES.NO_PETS}
      />
      {noPets.error && pets.length === 0 && !noPets.state && (
        <Typography variant="body2" className={classes.error}>
          {GLOBALS.MESSAGES.ADD_PETS_ERROR}
        </Typography>
      )}
      <Field
        disabled={noPets.state}
        className={classes.textArea}
        component={TextField}
        name="pets"
        type="text"
        label="Pets"
        placeholder={GLOBALS.LABELS.PETS}
        variant="outlined"
        margin="normal"
        multiline
        fullWidth
        rows={6}
      />

      <Typography variant="body1">
        {GLOBALS.SITE_TEXTS.VEHICLES_QUESTION}
      </Typography>
      <FormControlLabel
        className={classes.checkboxContainer}
        control={
          <Checkbox
            disabled={vehicles}
            checked={noVehicles.state}
            onChange={e => setNoVehicles({ state: e.target.checked })}
            name="noVehicles"
            color="primary"
          />
        }
        label={GLOBALS.MESSAGES.NO_VEHICLES}
      />
      {noVehicles.error && vehicles.length === 0 && !noVehicles.state && (
        <Typography variant="body2" className={classes.error}>
          {GLOBALS.MESSAGES.ADD_VEHICLES_ERROR}
        </Typography>
      )}
      <Field
        disabled={noVehicles.state}
        className={classes.textArea}
        component={TextField}
        name="vehicles"
        type="text"
        label="Vehicles"
        placeholder={GLOBALS.LABELS.VEHICLES}
        variant="outlined"
        margin="normal"
        multiline
        fullWidth
        rows={6}
      />
    </Box>
  );
}
