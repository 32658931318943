import { OPEN_DIALOG, CLOSE_DIALOG } from "../actions/types";

const initialState = {
  PURCHASE_INQUIRY_DIALOG: {
    open: false,
    dialodProps: {},
  },
};

export default function dialogReducer(state = initialState, { type, payload }) {
  switch (type) {
    case OPEN_DIALOG:
      return {
        ...state,
        [payload.dialogType]: {
          open: true,
          dialodProps: payload.dialogProps,
        },
      };
    case CLOSE_DIALOG:
      return {
        ...state,
        [payload.dialogType]: {
          open: false,
          dialodProps: {},
        },
      };
    default:
      return state;
  }
}
