import React, { useMemo, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Paper,
  Popover,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import TenantProfileSection from "../tenant-profile/TenantProfileSection";
import StyledTableCell from "../StyledTableCell";
import { differenceInYears } from "date-fns";
import clsx from "clsx";
import { formatPrice, getStaticImgSrc } from "../../nestiqaUtils";
import placeholderPicture from "./../tenant_picture.png";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { GLOBALS } from "../../App";
import {
  getHeadofHousehold,
  sortByYesNoFieldAndDate,
} from "../../utils/common";

const useStyles = makeStyles(theme => ({
  table: {
    width: "100%",
  },
  tableHeadCell: {
    border: "none",
    padding: 0,
    paddingRight: theme.spacing(1),
  },
  tableBody: {
    border: "none",
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
    padding: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  picture: {
    height: "30px",
    width: "30px",
    borderRadius: "15px",
    objectFit: "cover",
    objectPosition: "center",
    display: "block",
  },

  tableRow: {
    cursor: "pointer",
    userSelect: "none",
    transition: "background-color 100ms",
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },

    "& .MuiTableCell-root:first-of-type": {
      paddingLeft: theme.spacing(2),
    },

    "& .MuiTableCell-root:last-of-type": {
      paddingRight: theme.spacing(2),
    },
  },
  selected: {
    cursor: "default",
    backgroundColor: theme.palette.secondary.light,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  tableHead: {
    cursor: "default",
  },
  row: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    paddingBottom: theme.spacing(1),
  },
  summary: {
    width: "100%",
    position: "relative",

    "& .MuiAccordionSummary-content": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },

    "& .MuiAccordionSummary-root": {
      padding: 0,
    },
  },
  details: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  accordian: {
    boxShadow: "none",

    "&.MuiAccordion-root.Mui-expanded": {
      marginTop: "0px",
      paddingTop: theme.spacing(2),
    },

    "&::before": {
      display: "none",
    },

    "&:not(:last-child)": {
      border: "none",
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
    },
  },
  marginLeft: {
    marginLeft: theme.spacing(4),
    width: `calc(100% - ${theme.spacing(4)}px)`,
  },
  accordianPicture: {
    position: "absolute",
    top: theme.spacing(3),
    left: theme.spacing(1),
  },
  requestPopup: {
    padding: theme.spacing(2),
    "& p": {
      paddingBottom: theme.spacing(1),
    },
  },
  totalIncome: {
    fontWeight: 500,
    lineHeight: "1.5rem",
    fontSize: "0.875rem",
    color: theme.palette.primary.main,
    paddingBottom: theme.spacing(4),
  },
}));

const LandlordAppHousehold = ({
  household,
  selectedHousehold,
  setSelectedHousehold,
  onRequestHouseholdBtnClick,
  isPrintPage = false,
}) => {
  const classes = useStyles();

  const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));

  const [RequestPopupAnchorEl, setRequestPopupAnchorEl] = React.useState(null);

  const handleRequestPopupOpen = event => {
    setRequestPopupAnchorEl(event.currentTarget);
  };

  const handleRequestPopupClose = () => {
    setRequestPopupAnchorEl(null);
  };

  const Open = Boolean(RequestPopupAnchorEl);

  const onRowClick = (e, occupant) => {
    if (isPrintPage) return;

    let age = occupant.dob
      ? differenceInYears(new Date(), new Date(occupant.dob))
      : null;

    // do nothing
    if (!age || age < 18) return;

    if (occupant.hasUser === "N") {
      handleRequestPopupOpen(e);
      return;
    }

    setSelectedHousehold(occupant);
  };

  const { headOfHousehold, otherOccupants } = useMemo(() => {
    return getHeadofHousehold(household);
  }, [household]);

  const sortedOccupants = useMemo(() => {
    return sortByYesNoFieldAndDate(otherOccupants, "coSigner", "dob");
  }, [otherOccupants]);

  const occupantsText = `${household.length} ${
    household.length === 1 ? "occupant" : "occupants"
  }, `;

  const cosigners = household.filter(
    occupant => occupant.coSigner === "Y"
  ).length;

  const cosignersText = cosigners > 1 ? `${cosigners} cosigers, ` : "";

  const totalIncome = household.reduce(
    (acc, occupant) => acc + occupant.annualIncome,
    0
  );

  const totalIncomeText = `total income: ${formatPrice(totalIncome)}`;

  return (
    <TenantProfileSection title="Household" isEditable={false}>
      {!isPrintPage && (
        <Popover
          open={Open}
          anchorEl={RequestPopupAnchorEl}
          anchorOrigin={{
            vertical: 8,
            horizontal: 8,
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          onClose={handleRequestPopupClose}
        >
          <Paper className={classes.requestPopup}>
            <Typography variant="body2">
              {GLOBALS.MESSAGES.NO_PROFILE}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={onRequestHouseholdBtnClick}
            >
              Request Profile
            </Button>
          </Paper>
        </Popover>
      )}
      <Typography
        className={classes.totalIncome}
        align="center"
        gutterBottom
        variant="subtitle1"
        color="textPrimary"
      >
        {occupantsText + cosignersText + totalIncomeText}
      </Typography>
      {!isPrintPage && isMobile ? (
        <HouseholdAccordian
          household={[headOfHousehold, ...sortedOccupants]}
          selectedHousehold={selectedHousehold}
          onRowClick={onRowClick}
          isPrintPage={isPrintPage}
        />
      ) : (
        <HouseholdTable
          household={[headOfHousehold, ...sortedOccupants]}
          selectedHousehold={selectedHousehold}
          onRowClick={onRowClick}
          isPrintPage={isPrintPage}
        />
      )}
    </TenantProfileSection>
  );
};

const HouseholdTable = ({
  household,
  selectedHousehold,
  onRowClick,
  isPrintPage,
}) => {
  const classes = useStyles();

  return (
    <Table className={classes.table} size="small">
      <TableHead>
        <TableRow className={clsx(classes.tableHead)}>
          <StyledTableCell className={classes.tableHeadCell}></StyledTableCell>

          <StyledTableCell className={classes.tableHeadCell}>
            Name
          </StyledTableCell>
          <StyledTableCell className={classes.tableHeadCell} align="center">
            Age
          </StyledTableCell>
          <StyledTableCell className={classes.tableHeadCell} align="center">
            Relationship
          </StyledTableCell>

          <StyledTableCell className={classes.tableHeadCell} align="center">
            Occupation
          </StyledTableCell>
          <StyledTableCell className={classes.tableHeadCell} align="center">
            Annual Income
          </StyledTableCell>
          <StyledTableCell className={classes.tableHeadCell} align="right">
            Co-Signer
          </StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {household.map((occupant, i) =>
          !isPrintPage &&
          occupant.hasUser === "Y" &&
          selectedHousehold.id !== occupant.id ? (
            <Tooltip
              title="Click to see profile"
              placement="top"
              key={occupant.id}
            >
              <HouseHoldRow
                occupant={occupant}
                onRowClick={onRowClick}
                selectedHousehold={selectedHousehold}
                isPrintPage={isPrintPage}
              />
            </Tooltip>
          ) : (
            <HouseHoldRow
              key={occupant.id}
              occupant={occupant}
              onRowClick={onRowClick}
              selectedHousehold={selectedHousehold}
              isPrintPage={isPrintPage}
            />
          )
        )}
      </TableBody>
    </Table>
  );
};

const HouseHoldRow = React.forwardRef(
  (
    { occupant, onRowClick, selectedHousehold, isPrintPage, ...otherProps },
    ref
  ) => {
    const classes = useStyles();
    return (
      <TableRow
        {...otherProps}
        ref={ref}
        onClick={e => onRowClick(e, occupant)}
        className={clsx(
          classes.tableRow,
          !isPrintPage &&
            selectedHousehold.id === occupant.id &&
            classes.selected
        )}
      >
        <StyledTableCell className={clsx(classes.tableBody)}>
          <img
            className={classes.picture}
            src={occupant.picture || getStaticImgSrc(placeholderPicture)}
            alt={occupant.firstName + " " + occupant.lastName}
          />
        </StyledTableCell>
        <StyledTableCell className={clsx(classes.tableBody)}>
          {occupant.firstName + " " + occupant.lastName}
        </StyledTableCell>

        <StyledTableCell className={clsx(classes.tableBody)} align="center">
          {occupant.dob
            ? `${differenceInYears(new Date(), new Date(occupant.dob))}y`
            : "-"}
        </StyledTableCell>
        <StyledTableCell className={clsx(classes.tableBody)} align="center">
          {occupant.relationship || "-"}
        </StyledTableCell>
        <StyledTableCell className={clsx(classes.tableBody)} align="center">
          {occupant.occupation || "-"}
        </StyledTableCell>
        <StyledTableCell className={clsx(classes.tableBody)} align="center">
          {occupant.annualIncome ? formatPrice(occupant.annualIncome) : "-"}
        </StyledTableCell>
        <StyledTableCell className={clsx(classes.tableBody)} align="right">
          {occupant.coSigner === "Y" ? "Yes" : "No"}
        </StyledTableCell>
      </TableRow>
    );
  }
);

const HouseholdAccordian = ({
  household,
  onRowClick,
  selectedHousehold,
  isPrintPage,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(
    household.length === 1 ? household[0].id : false
  );
  const handleChange = occupant => (event, isExpanded) => {
    setExpanded(isExpanded ? occupant.id : false);
    onRowClick(event, occupant);
  };
  return (
    <>
      {" "}
      {household.map((occupant, i) => (
        <Accordion
          expanded={expanded === occupant.id}
          key={occupant.id}
          className={clsx(
            classes.accordian,
            !isPrintPage &&
              selectedHousehold.id === occupant.id &&
              classes.selected
          )}
          onChange={handleChange(occupant)}
        >
          <AccordionSummary id={occupant.id} className={classes.summary}>
            <img
              className={clsx(classes.picture, classes.accordianPicture)}
              src={occupant.picture || getStaticImgSrc(placeholderPicture)}
              alt={occupant.firstName + " " + occupant.lastName}
            />
            <div className={clsx(classes.row, classes.marginLeft)}>
              <Typography variant="body2">
                <strong>{occupant.firstName + " " + occupant.lastName}</strong>
              </Typography>
              <Typography variant="body2">
                <strong>{occupant.coSigner === "Y" ? "Co-Signer" : ""}</strong>
              </Typography>
            </div>

            <div className={clsx(classes.row, classes.marginLeft)}>
              <div>
                {occupant.dob
                  ? `${differenceInYears(new Date(), new Date(occupant.dob))}y`
                  : "-"}
                , {occupant.relationship || "-"}
              </div>
              <div>
                {expanded === occupant.id ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <div className={classes.row}>
              <div>
                <Typography variant="body2">
                  <strong>Occupation</strong>
                </Typography>
                <Typography variant="body2">
                  {occupant.occupation || "-"}
                </Typography>
              </div>

              <div className={classes.alignRight}>
                <Typography variant="body2">
                  <strong>Annual Income</strong>
                </Typography>
                <Typography variant="body2">
                  {occupant.annualIncome
                    ? formatPrice(occupant.annualIncome)
                    : "-"}
                </Typography>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};
export default LandlordAppHousehold;
