import React, { useEffect, useState, useMemo } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import {
  Typography,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { GLOBALS } from "../App";
import {
  approveApplication,
  getApplicationReports,
  rejectApplication,
  requestInfo,
} from "../utils/ApplicationApi";
import { getLabel } from "../nestiqaUtils";
import useAuth0Nesitqa from "../hooks/useAuth0Nestiqa";
import NestiqaAlertDialog from "../components/NestiqaAlertDialog";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import { Helmet } from "react-helmet";
import useLandlordApplicationWithHousehold from "../hooks/useLandlordApplicationWithHousehold";
import LandordAppListingInfo from "../components/landlord-application/LandordAppListingInfo";
import LandlordAppInfo from "../components/landlord-application/LandlordAppInfo";
import LandlordAppHousehold from "../components/landlord-application/LandlordAppHousehold";
import LandlordAppTenantProfile from "../components/landlord-application/LandlordAppTenantProfile";
import { differenceInYears } from "date-fns";
import LandlordAppActionDialog from "../components/landlord-application/LandlordAppActionDialog";
import LandlordEditDialog from "../components/landlord/LandlordEditDialog";
import { getLandlordInfo } from "../utils/LandlordApi";
import NestiqaActionDialog from "../components/NestiqaActionDialog";
import { getActiveUser } from "../store/selectors/userSelectors";
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  textBoxCol1: {
    minWidth: "120px",
  },
  textBoxCol2: {
    minWidth: "100px",
  },
  primaryColor: {
    color: theme.palette.primary.main,
  },
  infoGrid: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "grid",
    gap: theme.spacing(6),
    gridTemplateColumns: "1fr 1fr",
    gridTemplateAreas: `
    "status actions"
    "listing app"
    `,
  },
  statusLabel: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "70px",
    height: "25px",
    whiteSpace: "nowrap",

    "& span": {
      borderRadius: "5px",
      padding: "2px 8px",
      textAlign: "left !important",
    },
  },
  actions: {
    gridArea: "actions",
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    overflowX: "overflow",
    "& > *": {
      marginLeft: theme.spacing(2),
    },
  },
  actionButtons: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    "& > *": {
      marginLeft: theme.spacing(2),
    },
  },
  listing: {
    gridArea: "listing",
    width: "100%",
  },
  app: {
    gridArea: "app",
    width: "100%",
  },
  moreIconBtn: {
    border: `2px solid ${theme.palette.primary.main}`,

    "&:disabled": {
      borderColor: theme.palette.grey[400],
    },
  },
  inviteBtn: {
    minWidth: "150px",
  },
  printLink: {
    textDecoration: "none",
    color: "#000",
  },
  colorSubmitted: {
    backgroundColor: "#ECFAFB",
    color: "#3CE0E5",
  },
  colorApproved: {
    backgroundColor: "#EFFBEC",
    color: "#58D865",
  },
  colorRejected: {
    backgroundColor: "#FBECEC",
    color: "#CF7F7F",
  },
  colorPending: {
    backgroundColor: "#FBF7EC",
    color: "#D89A3D",
  },
  [theme.breakpoints.down("md")]: {
    infoGrid: {
      gap: theme.spacing(5),
    },
    status: {
      width: `calc(100% + ${theme.spacing(5)}px)`,
    },
  },
  [theme.breakpoints.down("xs")]: {
    infoGrid: {
      gap: theme.spacing(3),
      gridTemplateColumns: "1fr",
      gridTemplateAreas: `
      "status"
      "listing"
      "actions"
      "app"
      `,
    },
    actions: {
      flexDirection: "column",
      alignItems: "flex-start",

      "& > *": {
        marginLeft: theme.spacing(0),
      },
    },
    actionButtons: {
      marginTop: theme.spacing(2),
      width: "100%",
      justifyContent: "space-between",
      marginLeft: "auto",

      "& > *": {
        marginLeft: theme.spacing(0),
      },
    },
  },
}));

export default function LandlordApplicationPage() {
  const { applicationId } = useParams();
  const applicationResult = useLandlordApplicationWithHousehold(applicationId);
  const classes = useStyles();
  const history = useHistory();
  const { accessToken } = useAuth0Nesitqa("");
  const [app, setApp] = useState();
  const activeUser = useSelector(getActiveUser);

  const [dialogControls, setDialogControls] = useState({
    open: false,
  });

  const [actionDialogControls, setActionDialogControls] = useState({
    open: false,
  });

  const [selectedHousehold, setSelectedHousehold] = useState();
  const [moreActionsAnchorEl, setMoreActionAnchorEl] = useState(null);

  const [reports, setReports] = useState();

  function resultsCallback(isSuccess, result, error) {
    if (isSuccess) {
      setReports(result);
    }
    if (error) {
      showError();
      console.log(error);
    }
  }

  async function callGetApplicationReports() {
    getApplicationReports(applicationId, accessToken, resultsCallback);
  }

  useEffect(() => {
    if (!accessToken) return;
    callGetApplicationReports();
    // eslint-disable-next-line
  }, [accessToken]);

  const [showRequestProfileItem, showRequestReportsItem] = useMemo(() => {
    if (!app || !reports) return [false, false];

    let showRequestProfileItem = false;
    let showRequestReportsItem = false;

    for (let i = 0; i < app.household.length; i++) {
      let household = app.household[i];
      let age = household.dob
        ? differenceInYears(new Date(), new Date(household.dob))
        : null;
      if (household.hasUser === "N" && age && age >= 18) {
        showRequestProfileItem = true;
        break;
      }
    }

    let householdOver18 = app.household.filter(h => {
      let age = h.dob ? differenceInYears(new Date(), new Date(h.dob)) : null;
      return age && age >= 18;
    });

    showRequestReportsItem = householdOver18 !== reports.length;

    return [showRequestProfileItem, showRequestReportsItem];
  }, [app, reports]);

  useEffect(() => {
    if (applicationResult.status !== "COMPLETE") return;

    setApp(applicationResult.application);
    setSelectedHousehold(
      applicationResult.application.household.find(
        h => h.isHeadOfHousehold === "Y"
      )
    );
  }, [applicationResult]);

  const [step, setStep] = useState(1);
  const [landlord, setLandlord] = useState();
  // Assume accepted terms are true at this point in time
  const [acceptedTerms, setAcceptedTerms] = useState(true);

  const [editDialog, setEditDialog] = useState({
    open: false,
  });

  const openEditDialog = () => {
    setEditDialog({
      open: true,
    });
  };

  const closeEditDialog = () => {
    setEditDialog({
      open: false,
    });
    setStep(1);
    if (landlord && landlord.isComplete === "Y") {
      approveApplication(
        app.id,
        accessToken,
        applicationActionCallback(getApproveMessage())
      );
    }
  };

  const [completeDialog, setCompleteDialog] = useState({
    open: false,
  });

  const closeCompleteDialog = () => {
    setCompleteDialog({
      open: false,
    });
  };

  const openCompleteDialog = () => {
    setCompleteDialog({
      open: true,
      title: "Complete Your Information",
      message: GLOBALS.MESSAGES.COMPLETE_LANDLORD_INFO_INVITE,
      onClose: () => {
        closeCompleteDialog();
        openEditDialog();
      },
      buttonMap: [
        {
          buttonName: "No",
          handleClick: () => closeCompleteDialog(),
        },
        {
          buttonName: "Yes",
          handleClick: () => {
            closeCompleteDialog();
            openEditDialog();
          },
        },
      ],
    });
  };

  const getLandlord = approveApplication => {
    function landlordInfoCallback(isSuccess, apiResult, apiError) {
      if (isSuccess) {
        setLandlord(apiResult);
        if (apiResult.isComplete === "N") {
          openCompleteDialog();
        } else {
          approveApplication();
        }
      } else {
        console.error(apiError);
        showError();
      }
    }
    if (activeUser && activeUser.landlordId) {
      getLandlordInfo(accessToken, landlordInfoCallback);
    }
  };

  if (applicationResult.error) {
    console.error(applicationResult.error);
    return (
      <Typography variant="h5" align="center" color="error">
        {GLOBALS.MESSAGES.APPLICATION_NOT_FOUND} {applicationId}
      </Typography>
    );
  }

  if (!app) {
    return (
      <Typography variant="h5" align="center" color="primary">
        Loading...
      </Typography>
    );
  }

  const onListingClick = () => {
    history.push(`/landlord/listing/${app.listing.listingNumber}`);
  };

  const showError = (message = GLOBALS.MESSAGES.ERROR_COMPLETING_ACTION) => {
    setDialogControls({
      open: true,
      title: "Error",
      message,
      onClose: handleAlertDialogClose,
    });
  };

  const applicationActionCallback =
    message => (isSuccess, apiResult, apiError) => {
      onActionDialogClose();
      if (isSuccess) {
        setApp({ ...app, status: apiResult.status });
        setDialogControls({
          open: true,
          title: "Success",
          message,
          onClose: () => {
            handleAlertDialogClose();
            onListingClick();
          },
        });
      } else {
        console.error(apiError);
        showError();
      }
    };

  const onApproveBtnClick = message => () => {
    if (app.action === "APPLY") {
      approveApplication(
        app.id,
        accessToken,
        applicationActionCallback(message)
      );
    } else {
      getLandlord(() =>
        approveApplication(
          app.id,
          accessToken,
          applicationActionCallback(message)
        )
      );
    }
  };

  const onRejectBtnClick = () => {
    setActionDialogControls({
      title: "Reject Application",
      open: true,
      onClose: onActionDialogClose,
      handleRequestBtnClick: comments =>
        rejectApplication(
          app.id,
          accessToken,
          comments,
          applicationActionCallback(GLOBALS.MESSAGES.REJECT_SUCCESS)
        ),
      hasComments: true,
      commentsLabel: "Rejection reason",
      btnLabel: "Reject",
    });
    handleMoreActionsClose();
  };

  const onActionDialogClose = () => {
    setActionDialogControls({
      open: false,
    });
  };

  const onRequestInfoBtnClick = () => {
    setActionDialogControls({
      title: "Request Info",
      open: true,
      onClose: onActionDialogClose,
      message: GLOBALS.MESSAGES.REQUEST_INFO,
      handleRequestBtnClick: comments =>
        requestInfo(
          app.id,
          "GENERAL",
          comments,
          accessToken,
          applicationActionCallback(GLOBALS.MESSAGES.REQUEST_INFO_SUCCESS)
        ),
      hasComments: true,
    });
    handleMoreActionsClose();
  };

  const onRequestHouseholdBtnClick = () => {
    setActionDialogControls({
      title: "Request Profile",
      open: true,
      onClose: onActionDialogClose,
      message: GLOBALS.MESSAGES.REQUEST_PROFILE,
      handleRequestBtnClick: () =>
        requestInfo(
          app.id,
          "HOUSEHOLD",
          "",
          accessToken,
          applicationActionCallback(GLOBALS.MESSAGES.REQUEST_PROFILE_SUCCESS)
        ),
    });
    handleMoreActionsClose();
  };

  const onRequestReportsBtnClick = () => {
    setActionDialogControls({
      title: "Request Reports",
      open: true,
      onClose: onActionDialogClose,
      message: GLOBALS.MESSAGES.REQUEST_REPORTS,
      handleRequestBtnClick: () =>
        requestInfo(
          app.id,
          "REPORTS",
          "",
          accessToken,
          applicationActionCallback(GLOBALS.MESSAGES.REQUEST_REPORTS_SUCCESS)
        ),
    });
    handleMoreActionsClose();
  };

  const handleAlertDialogClose = () => {
    setDialogControls({
      open: false,
    });
  };

  const getApproveLabel = () => {
    return app.action === "APPLY" ? "Approve" : "Invite to Apply";
  };

  const getApproveClassName = () => {
    return app.action === "APPLY" ? "approveBtn" : "inviteBtn";
  };

  const getApproveMessage = () => {
    return app.action === "APPLY"
      ? GLOBALS.MESSAGES.ACCEPT_SUCCESS
      : GLOBALS.MESSAGES.INVITE_TO_APPLY_SUCCESS;
  };

  const handleMoreBtnClick = event => {
    setMoreActionAnchorEl(event.currentTarget);
  };

  const handleMoreActionsClose = () => {
    setMoreActionAnchorEl(null);
  };

  const getColor = status => {
    switch (status) {
      case "SUBMITTED":
        return classes.colorSubmitted;
      case "APPROVED":
        return classes.colorApproved;
      case "REJECTED":
        return classes.colorRejected;
      case "PENDING_INFO":
      case "PENDING_APPLY":
      case "PENDING_REPORTS":
      case "PENDING_HOUSEHOLD":
        return classes.colorPending;
      default:
        return "";
    }
  };

  return (
    <Container maxWidth="lg">
      <Helmet>
        <title>
          {GLOBALS.META_TAGS.TITLE.LANDLORD_APPLICATION_PAGE +
            app.listing.fullAddress}
        </title>
      </Helmet>
      <NestiqaAlertDialog {...dialogControls} />
      <div className={classes.infoGrid}>
        <div className={classes.status}>
          <Typography variant="body1" align="left" color="textPrimary">
            <strong>
              {getLabel(app.action, "APP_ACTIONS") + ` #${app.id}`}
            </strong>
          </Typography>
        </div>

        <div className={classes.actions}>
          <div className={classes.statusLabel}>
            <span className={getColor(app.status)}>
              {getLabel(app.status, "APP_STATUSES")}
            </span>
          </div>
          <div className={classes.actionButtons}>
            {app.status.startsWith("PENDING") ? (
              <>
                <Tooltip
                  title="This application is currently pending the tenant."
                  placement="top"
                >
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      align="center"
                      disabled
                      className={classes[getApproveClassName()]}
                    >
                      {getApproveLabel()}
                    </Button>
                  </div>
                </Tooltip>

                <Tooltip
                  title="This application is currently pending the tenant."
                  placement="top"
                >
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      align="center"
                      disabled
                    >
                      Reject
                    </Button>
                  </div>
                </Tooltip>
              </>
            ) : (
              <>
                <NestiqaActionDialog {...completeDialog} />
                <LandlordEditDialog
                  step={step}
                  setStep={setStep}
                  landlord={landlord}
                  setLandlord={setLandlord}
                  {...editDialog}
                  closeDialog={closeEditDialog}
                  acceptedTerms={acceptedTerms}
                  setAcceptedTerms={setAcceptedTerms}
                />
                <Button
                  variant="contained"
                  color="primary"
                  align="center"
                  onClick={onApproveBtnClick(getApproveMessage())}
                  disabled={app.status !== "SUBMITTED"}
                  className={classes[getApproveClassName()]}
                >
                  {getApproveLabel()}
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  align="center"
                  onClick={onRejectBtnClick}
                  disabled={app.status !== "SUBMITTED"}
                >
                  Reject
                </Button>
              </>
            )}
            <IconButton
              size="small"
              className={classes.moreIconBtn}
              color="primary"
              onClick={handleMoreBtnClick}
            >
              <MoreVertIcon />
            </IconButton>

            <LandlordAppActionDialog {...actionDialogControls} />
            <Menu
              id="simple-menu"
              anchorEl={moreActionsAnchorEl}
              keepMounted
              open={Boolean(moreActionsAnchorEl)}
              onClose={handleMoreActionsClose}
            >
              {app.status === "SUBMITTED" && (
                <MenuItem onClick={onRequestInfoBtnClick}>
                  Request Info
                </MenuItem>
              )}
              {app.status === "SUBMITTED" && showRequestProfileItem && (
                <MenuItem onClick={onRequestHouseholdBtnClick}>
                  Request Profiles
                </MenuItem>
              )}
              {app.status === "SUBMITTED" && showRequestReportsItem && (
                <MenuItem onClick={onRequestReportsBtnClick}>
                  Request Reports
                </MenuItem>
              )}
              <MenuItem>
                <Link
                  to={`/landlord/application/${app.id}/print`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.printLink}
                >
                  Print Application
                </Link>
              </MenuItem>
            </Menu>
          </div>
        </div>
        <div className={classes.listing}>
          <LandordAppListingInfo
            listing={app.listing}
            onClick={onListingClick}
          />
        </div>
        <div className={classes.app}>
          <LandlordAppInfo
            coverLetter={app.coverLetter}
            moveInDate={app.moveInDate}
            listingSeen={app.listingSeen}
            applicantName={app.tenant.fullName}
          />
        </div>
      </div>
      <LandlordAppHousehold
        household={app.household}
        selectedHousehold={selectedHousehold}
        setSelectedHousehold={setSelectedHousehold}
        onRequestHouseholdBtnClick={onRequestHouseholdBtnClick}
      />
      <LandlordAppTenantProfile
        selectedHousehold={selectedHousehold}
        accessToken={accessToken}
        applicationId={app.id}
        showError={showError}
        appAction={app.action}
        onRequestReportsBtnClick={onRequestReportsBtnClick}
        reports={reports}
      />
    </Container>
  );
}
