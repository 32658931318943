import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Button,
  Link,
  AppBar,
  Grid,
  IconButton,
  useMediaQuery,
} from "@material-ui/core";
import { useParams, Link as RouterLink, useHistory } from "react-router-dom";
import { GLOBALS } from "../App";
import useListing from "../hooks/useListing";
import NestiqaAlertDialog from "../components/NestiqaAlertDialog";
import { makeStyles } from "@material-ui/core/styles";
import TabPanel, { a11yProps } from "../components/TabPanel";
import RoundTabs from "../components/RoundTabs";
import RoundTab from "../components/RoundTab";
import { getFullAddress } from "../nestiqaUtils";
import ListingApplications from "../components/ListingApplications";
import RequireLogin from "../components/RequireLogin";
import useAuth0Nesitqa from "../hooks/useAuth0Nestiqa";
import { BackArrowIcon, EditIcon } from "../components/icons";
import { Helmet } from "react-helmet";
import clsx from "clsx";
import AddPropertyDialog from "../components/landlord-screen/AddPropertyDialog";
import InviteApplicants from "../components/landlord-screen/InviteApplicants";

const useStyles = makeStyles(theme => ({
  renewButton: {
    [theme.breakpoints.down("sm")]: {
      minWidth: "86px",
    },
  },
  tabsBar: {
    backgroundColor: "white",
    boxShadow: theme.shadows[0],
    color: theme.palette.primary.dark,
    marginTop: "50px",
  },
  headerWrapper: {
    display: "flex",
    alignItems: "center",
    marginTop: "40px",

    "& .MuiGrid-root.MuiGrid-item": {
      display: "flex",

      "&:nth-child(2)": {
        justifyContent: "center",
      },

      "&:nth-child(3)": {
        justifyContent: "flex-end",
      },
    },
  },
  tabs: {
    textTransform: "capitalize",
  },
  tabLabels: {
    marginLeft: "20px",
  },
  tabLabelsMobile: {
    fontSize: "13.5px",
    textTransform: "capitalize !important",
  },
  previewText: {
    marginBottom: "20px",
  },
  listingInfo: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  textBoxCol1: {
    minWidth: "120px",
    margin: "auto 0",
  },
  textBoxCol2: {
    minWidth: "100px",
    margin: "auto 0",
  },
  linkContainer: {
    padding: "12px",
  },
  linkKey: {
    padding: 0,
    margin: "auto 0",
  },
  button: {
    textTransform: "capitalize",
  },
  flexWrapper: {
    display: "flex",
    alignItems: "center",

    "& > *:first-child": {
      marginRight: "5px",
    },

    "& > *:nth-child(2)": {
      marginRight: "5px",
    },

    "& .MuiTypography-root.MuiTypography-subtitle2": {
      fontSize: "16px",
    },

    "& .MuiTypography-root.MuiTypography-subtitle1": {
      fontSize: "16px",
    },
  },
  statusWrapper: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      margin: 0,
    },
  },
  renewWrapper: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "flex-end",
      margin: 0,

      "& .MuiTypography-root:nth-child(2)": {
        marginTop: "5px",
        marginBottom: "6px",
      },
    },
  },
  status: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "70px",
    height: "25px",

    "& span": {
      borderRadius: "5px",
      padding: "2px 8px",
      textAlign: "left !important",
    },

    [theme.breakpoints.down("xs")]: {
      minWidth: "unset",
      marginBottom: "10px",
      marginTop: "5px",

      "& span": {
        width: "83px",
        padding: "4px 20px",
      },
    },
  },

  colorActive: {
    backgroundColor: "#ECFAFB",
    color: "#3CE0E5",
  },
  colorDraft: {
    backgroundColor: "#FBF7EC",
    color: "#D89A3D",
  },
  colorInactive: {
    backgroundColor: "#e0e0e0",
    color: "#000",
  },
  manageTitle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",

    "& .MuiTypography-root.MuiTypography-body1:first-child": {
      fontSize: "16px",
      fontWeight: 400,
    },

    "& .MuiTypography-root.MuiTypography-body1:last-child": {
      fontSize: "24px",
      fontWeight: 600,
    },
  },
  list: {
    [theme.breakpoints.down("sm")]: {
      "& .MuiPaper-root.MuiMenu-paper": {
        "& .MuiList-root.MuiMenu-list": {
          paddingBottom: 0,
          overflow: "hidden",
          width: "auto",

          "& .MuiButtonBase-root.MuiListItem-root": {
            float: "left",
            paddingBottom: 0,
            paddingTop: 0,

            "& .MuiButtonBase-root.MuiIconButton-root": {
              marginBottom: 0,
            },
          },
        },
      },
    },
  },
  margin: {
    marginTop: "9px",
    minWidth: "40px",
  },
  editLink: {
    "& a": {
      color: "#fff",
      fontSize: "0.875rem",
      fontWeight: 500,
      lineHeight: 1.75,
    },
  },
  titleMobile: {
    whiteSpace: "nowrap",
  },
  listingAddressMobile: {
    whiteSpace: "nowrap",
    width: "170px",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
}));

export default function LandlordScreenPropertyPage() {
  const classes = useStyles();
  const { listingNumber } = useParams();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));
  const history = useHistory();

  const [pageControls, setPageControls] = useState({
    loading: true,
    dialog: {
      open: false,
    },
  });

  const [listingParams] = useState({
    listingNumber: listingNumber,
    action: "get-listing-applications",
  });
  const listingResult = useListing(listingParams);

  const { isAuthenticated, loginWithRedirect } = useAuth0Nesitqa("");
  const [fullAddress, setFullAddress] = useState("");

  const setListing = listing => {
    setPageControls(prev => ({ ...prev, listing }));
  };

  const [addPropertyDialogControls, setAddPropertyDialogControls] = useState({
    open: false,
    listing: {},
    setListing: setListing,
  });

  const closeAddPropertyDialogControls = () => {
    setAddPropertyDialogControls({
      open: false,
      listing: {},
      setListing: setListing,
    });
  };

  useEffect(() => {
    if (listingResult.status !== "COMPLETE" || listingResult.errors) return;

    const apps = listingResult.payload.applications.filter(
      val => val.action === "APPLY"
    );

    setPageControls({
      dialog: { open: false },
      loading: false,
      listing: { ...listingResult.payload.listing },
      applications: apps,
      applicationsCount: apps.filter(val => val.status === "SUBMITTED").length,
    });
  }, [listingResult]);

  useEffect(() => {
    if (!listingResult || listingResult.errors) {
      return;
    }

    if (listingResult && listingResult.status === "COMPLETE") {
      const listing = listingResult?.payload?.listing;
      const address = getFullAddress(listing);
      setFullAddress(address);
    }
  }, [listingResult]);

  if (listingResult.status === "COMPLETE" && listingResult.errors) {
    console.error(listingResult.errors);
    return (
      <Typography variant="h5" align="center" color="error">
        {GLOBALS.MESSAGES.LISTING_NOT_FOUND}
        {listingNumber}
      </Typography>
    );
  }

  function handleLogin() {
    localStorage.setItem("previousLocation", window.location.pathname);
    loginWithRedirect();
  }

  if (!isAuthenticated) {
    return (
      <Container>
        <RequireLogin login={handleLogin} />
      </Container>
    );
  }

  if (pageControls.loading) {
    return (
      <Typography variant="h5" align="center" color="primary">
        Loading...
      </Typography>
    );
  }

  const handleClose = () => {
    if (pageControls.dialog.navigateOnclose) {
      history.push(pageControls.dialog.navigateOnclose);
    }
    setPageControls({ ...pageControls, dialog: { open: false } });
  };

  const handleEdit = () => {
    setAddPropertyDialogControls({
      open: true,
      closeDialog: closeAddPropertyDialogControls,
      listing: pageControls.listing,
      setListing: setListing,
    });
  };

  const listing = pageControls.listing;

  return (
    <Container className={classes.root}>
      <Helmet>
        <title>
          {GLOBALS.META_TAGS.TITLE.LANDLORD_LISTING_PAGE + fullAddress}
        </title>
      </Helmet>
      <AddPropertyDialog {...addPropertyDialogControls} />

      <Grid container justify="space-between" className={classes.headerWrapper}>
        <Grid item xs={4}>
          <Link
            to={GLOBALS.PATHS.LANDLORD_SCREEN_PAGE}
            component={RouterLink}
            underline="none"
          >
            {isMobile ? (
              <IconButton>
                <BackArrowIcon />
              </IconButton>
            ) : (
              <Button
                variant="outlined"
                color="primary"
                align="center"
                className={classes.button}
              >
                Back
              </Button>
            )}
          </Link>
        </Grid>
        <Grid item xs={4} className={classes.manageTitle}>
          <Typography
            variant="body1"
            align="center"
            className={clsx(isMobile && classes.titleMobile)}
          >
            Manage Property:
          </Typography>
          <Typography
            variant="body1"
            align="center"
            className={clsx(isMobile && classes.listingAddressMobile)}
          >
            {listing.address1}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="contained"
            color="primary"
            align="center"
            onClick={handleEdit}
            className={clsx(classes.button, classes.editLink)}
            startIcon={<EditIcon />}
          >
            Edit
          </Button>
        </Grid>
      </Grid>
      <Grid container justify="center" style={{ marginTop: "40px" }}>
        <InviteApplicants listingNumber={listingNumber} />
      </Grid>

      <AppBar position="static" className={classes.tabsBar}>
        <Grid container>
          <Grid item style={{ flexGrow: 1 }}>
            <RoundTabs value={0} style={{ borderLeft: "2px solid black" }}>
              <RoundTab
                className={classes.tabs}
                label={
                  <Typography
                    className={
                      isMobile ? classes.tabLabelsMobile : classes.tabLabels
                    }
                    variant={"h6"}
                  >
                    Applications({pageControls.applicationsCount})
                  </Typography>
                }
                {...a11yProps(1)}
              />
            </RoundTabs>
          </Grid>
        </Grid>
      </AppBar>

      <TabPanel value={0} index={0} style={tabPanelStyle}>
        <Typography variant="h5" align="center" color="primary">
          <ListingApplications applications={pageControls.applications} />
        </Typography>
      </TabPanel>

      <NestiqaAlertDialog {...pageControls.dialog} onClose={handleClose} />
    </Container>
  );
}

const tabPanelStyle = {
  border: "2px solid black",
  borderTop: "none",
  borderBottomLeftRadius: "30px",
  borderBottomRightRadius: "30px",
};
