export const SITE_TEXTS = {
  HOME_PAGE_TITLE_LINE1: "Skip the hassles and fees",
  HOME_PAGE_TITLE_LINE2: "to find your new home.",
  HOME_PAGE_SUBTITLE: "Nestiqa makes it simple.",
  HOME_PAGE_TENANT_SECTION: {
    TITLE_LINE1: "Are you a tenant looking",
    TITLE_LINE2: "for a new home?",
    BUTTON_TEXT: "I’m ready to view listings",
    POINTS: [
      "Fast, free pre-approval",
      "Search a variety of homes",
      "Landlords read your profile and get to know you - beyond your bank statements",
      "One-time $35 fee covers everything (including multiple applications)",
    ],
  },
  HOME_PAGE_LANDLORD_SECTION: {
    TITLE_LINE1: "Are you a landlord looking",
    TITLE_LINE2: "for a friendly, reliable tenant?",
    BUTTON_TEXT: "I’m ready to list my property",
    POINTS: [
      "Listing your property is free and takes only a few minutes",
      "Get relevant information and learn more about interested tenants immediately - before the application process",
      "Preview tenants who meet your requirements",
    ],
  },
  LANDLORD_PAGE_TITLE:
    "With Nestiqa, finding a reliable tenant is only a few clicks away.",
  LANDLORD_PAGE_POINTS: [
    "Listing your property with Nestiqa is free. Credit and application fees are paid by the tenant.",
    "Review tenants and pre-approve them. This means you get key information on tenants before you spend time showing your property.",
    "Get broad exposure with Nestiqa’s proprietary software so your listing is featured across multiple platforms and partner websites - reaching more potential tenants in your area.",
  ],
  LANDLORD_PAGE_BUTTONS: [
    {
      BUTTON_TEXT: "I’m ready to list my property",
      ACTION: "login",
    },
    { BUTTON_TEXT: "I Need help", ACTION: "help" },
  ],
  LANDLORD_PAGE_HOW_IT_WORKS_TITLE: "How it Works",
  LANDLORD_PAGE_HOW_IT_WORKS_SECTIONS: [
    {
      TITLE: "List",
      TEXT: "Share details and images about the property you’re looking to rent. You’ll get email notifications when you have interested tenants.",
      IMAGE_NAME: "list",
    },
    {
      TITLE: "Pre-Screen",
      TEXT: "Get to know your tenants’ history by reading their profiles. Who’s in their household? What is their occupation and income? Read their bio, look at their social channels and review documents to determine if they’re pre-approved and meet your initial requirements.",
      IMAGE_NAME: "prescreen",
    },
    {
      TITLE: "Show your property",
      TEXT: "If your tenants meet your initial qualifications, now is the time to schedule a showing in person.",
      IMAGE_NAME: "show",
    },
    {
      TITLE: "Receive Applications",
      TEXT: "Interested tenants will then pay a fee to complete the application process, where we provide you with credit, criminal, eviction and other relevant information for you to determine approval.",
      IMAGE_NAME: "applications",
    },
    {
      TITLE: "Approve",
      TEXT: "Approve your tenant’s application and schedule a move-in date.",
      IMAGE_NAME: "approve",
    },
  ],
  LANDLORD_SCREEN_PAGE_TITLE:
    "Guess what? Now you can get the most detailed information on potential tenants - for free.",
  LANDLORD_SCREEN_PAGE_POINTS: [
    "Screening with Nestiqa is free for landlords",
    "Our screening reports give you access to tenants’ rental history, household details, income, pets and more",
    "Our reports include:",
  ],
  LANDLORD_SCREEN_PAGE_SUB_POINTS: [
    "Full credit report with score from TransUnion",
    "Nationwide criminal report",
    "Nationwide eviction report",
  ],
  LANDLORD_SCREEN_PAGE_SECTIONS: [
    {
      TITLE: "Create Account",
      TEXT: "Create your free account. It only takes a few seconds.",
      IMAGE_NAME: "list",
      ACTION: "login",
    },
    {
      TITLE: "Invite Applicants",
      TEXT: "Invite any tenant you want to screen. They’ll pay the $35 application fee and complete the application.",
      IMAGE_NAME: "prescreen",
    },
    {
      TITLE: "Review Applications",
      TEXT: "Review the information and make a decision.",
      IMAGE_NAME: "applications",
    },
  ],
  LANDLORD_SCREEN_PAGE_BUTTONS: [
    {
      BUTTON_TEXT: "Get Started",
      ACTION: "login",
    },
    { BUTTON_TEXT: "I Need help", ACTION: "help" },
  ],
  CONTACT_US_FOOTER:
    "We hope we can ease the process of finding your home with a variety of rental listings and easy application process.",
  IMAGES_INSTRUCTIONS:
    "Upload and organize images from your device. Images fit the site best horizontally at 4:3 aspect ratio. Drag images to reorder.",
  PREVIEW_TEXT:
    "This is how your listing will be viewed by users of Nestiqa. If you wish to edit ",
  PREVIEW_TEXT_STEP:
    "This is how your listing will be viewed by users of Nestiqa",
  LISTING_LINK_TEXT:
    "Copy this link and send your potential tenants to receive applications. " +
    "You can also paste this link in listing websites.",
  BANKRUPCY_QUESTION: "Have you ever filed for bankruptcy?",
  EVICTION_QUESTION:
    "Have you ever been evicted or have you been in foreclosure?",
  REFUSED_RENT_QUESTION:
    "Have you ever willfully or intentionally refused to pay rent when due?",
  CRIMINAL_CHARGES_QUESTION:
    "Do you (or any of the potential occupants in your household), have charges " +
    "pending against you (or them) for any criminal offense?",
  CRIMINAL_CONVICTION_QUESTION:
    "Have you (or any of the potential occupants in your household) been convicted " +
    'of any criminal offense; or entered a plea of "guilty” or “no consent” to ' +
    "any criminal offense; or had any criminal matter disposed of in a manner other " +
    "than by acquittal or a finding of “not guilty”?",
  DETAILS_IF_YES:
    "If you answered “yes” to any of the above questions, please provide details and dates, including the country and state in which the incident occured.",
  PETS_QUESTION:
    "Please list all pets you own. For each pet please provide name, type," +
    " breed, weight and age.",
  VEHICLES_QUESTION:
    "Please list all vehicles you own. For each vehicle please provide make, model, " +
    "year, color, license plate and state.",
  PUBLISH_INSTRUCTIONS:
    "Your listing will be published at Nestiqa. You can also advertise your listings in partner websites such as Apartments.com",
};
