import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";

import useAuth0Nesitqa from "../../hooks/useAuth0Nestiqa";
import NestiqaAlertDialog from "../../components/NestiqaAlertDialog";
import LandlordInfo from "./LandlordInfo";
import LandlordAddress from "./LandlordAddress";
import LandlordScreenInfo from "../landlord-screen/LandlordScreenInfo";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiDialog-paper": {
      width: "100%",
      maxWidth: 1000,
      // overflow: "visible",
    },
  },
}));

export default function LandlordEditDialog({
  isScreenPage = false,
  open,
  step,
  setStep,
  closeDialog,
  landlord,
  setLandlord,
  acceptedTerms,
  setAcceptedTerms,
}) {
  const classes = useStyles();

  const { accessToken } = useAuth0Nesitqa("");
  const [dialogControls, setDialogControls] = useState({
    open: false,
    title: "",
    message: "",
  });

  function showError(message) {
    setDialogControls({
      open: true,
      title: "Error",
      message: message,
    });
  }

  const stepProps = {
    step: step,
    setStep: setStep,
    closeDialog: closeDialog,
    landlord: landlord,
    setLandlord: setLandlord,
    showError: showError,
    accessToken: accessToken,
    acceptedTerms: acceptedTerms,
    setAcceptedTerms: setAcceptedTerms,
  };

  const step1 = isScreenPage ? (
    <LandlordScreenInfo {...stepProps} />
  ) : (
    <LandlordInfo {...stepProps} />
  );

  return (
    <>
      <Dialog className={classes.root} open={open} onClose={closeDialog}>
        {step === 1 && step1}
        {step === 2 && <LandlordAddress {...stepProps} />}
      </Dialog>
      <NestiqaAlertDialog
        {...dialogControls}
        onClose={() => setDialogControls({ open: false })}
      />
    </>
  );
}
