import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import TenantPersonalInfo from "../../components/tenant/TenantPersonalInfo";
import TenantHouseHold from "../../components/tenant/TenantHousehold";
import TenantHousingHistory from "../../components/tenant/TenantHousingHistory";
import TenantEmploymentHistory from "../../components/tenant/TenantEmploymentHistory";
import TenantBackgroundInfo from "../../components/tenant/TenantBackgroundInfo";
import TenantAdditionalInfo from "../../components/tenant/TenantAdditionalInfo";
import TenantDocuments from "../../components/tenant/TenantDocuments";
import useAuth0Nesitqa from "../../hooks/useAuth0Nestiqa";
import NestiqaAlertDialog from "../../components/NestiqaAlertDialog";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiDialog-paper": {
      width: "100%",
      maxWidth: 1000,
      // overflow: "visible",
    },
  },
}));

export default function TenantEditDialog({
  open,
  step,
  closeDialog,
  tenant,
  setTenant,
}) {
  const classes = useStyles();

  const { accessToken } = useAuth0Nesitqa("");
  const [dialogControls, setDialogControls] = useState({
    open: false,
    title: "",
    message: "",
  });
  const [checkBoxState, setCheckBoxState] = useState({
    isOnlyOccupant: {
      state: false,
      error: false,
    },
    noPreviousEmployment: {
      state: false,
      error: false,
    },
    noPets: {
      state: false,
      error: false,
    },
    noVehicles: {
      state: false,
      error: false,
    },
  });

  const [currentAddressLength, setCurrentAddressLength] = useState({
    state: 0,
    error: false,
  });

  const setCheckBoxValue = (key, value) => {
    setCheckBoxState(prev => ({ ...prev, [key]: { ...prev[key], ...value } }));
  };

  function showError(message) {
    setDialogControls({
      open: true,
      title: "Error",
      message: message,
    });
  }

  const stepProps = {
    step: step,
    setStep: () => {},
    tenant: tenant,
    setTenant: setTenant,
    accessToken: accessToken,
    showError: showError,
    isEditDialog: true,
    closeDialog: closeDialog,
  };

  function showStep() {
    switch (step) {
      case 1:
        return <TenantPersonalInfo {...stepProps} />;
      case 2:
        return (
          <TenantHouseHold
            {...stepProps}
            isOnlyOccupant={checkBoxState.isOnlyOccupant}
            setCheckBoxValue={setCheckBoxValue}
          />
        );
      case 3:
        return (
          <TenantHousingHistory
            {...stepProps}
            currentAddressLength={currentAddressLength}
            setCurrentAddressLength={setCurrentAddressLength}
          />
        );
      case 4:
        return (
          <TenantEmploymentHistory
            {...stepProps}
            noPreviousEmployment={checkBoxState.noPreviousEmployment}
            setCheckBoxValue={setCheckBoxValue}
          />
        );
      case 5:
        return <TenantBackgroundInfo {...stepProps} />;
      case 6:
        return (
          <TenantAdditionalInfo
            {...stepProps}
            noVehicles={checkBoxState.noVehicles}
            noPets={checkBoxState.noPets}
            setCheckBoxValue={setCheckBoxValue}
          />
        );
      case 7:
        return <TenantDocuments {...stepProps} />;
      default:
        return <div />;
    }
  }

  return (
    <>
      <Dialog className={classes.root} open={open} onClose={closeDialog}>
        {showStep()}
      </Dialog>
      <NestiqaAlertDialog
        {...dialogControls}
        onClose={() => setDialogControls({ open: false })}
      />
    </>
  );
}
