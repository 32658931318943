import React from "react";

const QrScanIcon = props => {
  return (
    <svg
      xmlns="true"
      width="24.249"
      height="24.249"
      viewBox="0 0 24.249 24.249"
      {...props}
    >
      <g id="Group_539" data-name="Group 539" transform="translate(0.75 0.749)">
        <g id="iconfinder_7_copy_5285818" transform="translate(0 0.001)">
          <ellipse
            id="Ellipse_20"
            data-name="Ellipse 20"
            cx="4.077"
            cy="4.077"
            rx="4.077"
            ry="4.077"
            transform="translate(3.519 7.297)"
            fill="none"
            stroke="#3ce0e5"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <line
            id="Line_44"
            data-name="Line 44"
            x2="1.768"
            y2="2.581"
            transform="translate(9.196 12.871)"
            fill="none"
            stroke="#3ce0e5"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Path_381"
            data-name="Path 381"
            d="M274.28,221.59h3.245a2.446,2.446,0,0,1,2.446,2.445h0a2.446,2.446,0,0,1-2.446,2.445H274.28Z"
            transform="translate(-260.739 -214.293)"
            fill="none"
            stroke="#3ce0e5"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <line
            id="Line_45"
            data-name="Line 45"
            y2="3.264"
            transform="translate(13.541 12.187)"
            fill="none"
            stroke="#3ce0e5"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <line
            id="Line_46"
            data-name="Line 46"
            x2="1.98"
            y2="3.264"
            transform="translate(16.786 12.187)"
            fill="none"
            stroke="#3ce0e5"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Path_382"
            data-name="Path 382"
            d="M314.69,160h2.427a2,2,0,0,1,1.994,1.994v2.427"
            transform="translate(-296.361 -160)"
            fill="none"
            stroke="#3ce0e5"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Path_383"
            data-name="Path 383"
            d="M319.111,314.69v2.427a2,2,0,0,1-1.994,1.994H314.69"
            transform="translate(-296.361 -296.362)"
            fill="none"
            stroke="#3ce0e5"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Path_384"
            data-name="Path 384"
            d="M164.421,319.111h-2.427A2,2,0,0,1,160,317.117V314.69"
            transform="translate(-160 -296.362)"
            fill="none"
            stroke="#3ce0e5"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Path_385"
            data-name="Path 385"
            d="M160,164.421v-2.427A2,2,0,0,1,161.994,160h2.427"
            transform="translate(-160 -160)"
            fill="none"
            stroke="#3ce0e5"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </g>
      </g>
    </svg>
  );
};

export default QrScanIcon;
