import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import { GLOBALS } from "../../App";
import { getStaticImgSrc } from "../../nestiqaUtils";
import checkMark from "../../pages/check.png";
import { useGA4React } from "ga-4-react";
import { logEvent } from "../../utils/initGa4React";
import approve from "./approve.svg";
import applications from "./applications.svg";
import show from "./show.svg";
import prescreen from "./prescreen.svg";
import list from "./list.svg";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import useAuth0Nesitqa from "../../hooks/useAuth0Nestiqa";

const IMAGES = {
  list: list,
  prescreen: prescreen,
  show: show,
  applications: applications,
  approve: approve,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    maxWidth: 1000,
    margin: "0 auto",
  },
  title: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
    width: "100%",
    fontWeight: "400",
  },
  section: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    maxWidth: 1000,
    margin: "0 auto",

    "& + *": {
      marginTop: theme.spacing(-16),
    },
  },
  flip: {
    flexDirection: "row-reverse",
  },
  sectionLeft: {
    flexBasis: "50%",
    padding: theme.spacing(6),
  },
  sectionRight: {
    flexBasis: "50%",
    padding: theme.spacing(4),
  },
  sectionTitle: {
    marginBottom: theme.spacing(1),
    width: "fit-content",
    fontWeight: "400",
  },
  sectionTitleWrapper: {
    display: "flex",
    alignItems: "flex-start",
  },
  sectionNumber: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    fontSize: "1rem",
    minWidth: 25,
    height: 25,
    borderRadius: 12.5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: theme.spacing(1),
  },
  sectionPoints: {
    marginTop: theme.spacing(4),
    paddingLeft: theme.spacing(5.5),
    paddingRight: theme.spacing(4.5),
    listStyle: "none",
  },
  sectionPoint: {
    marginBottom: theme.spacing(1),
    position: "relative",

    "&::before": {
      content: `" "`,
      backgroundImage: `url(${getStaticImgSrc(checkMark)})`,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      position: "absolute",
      height: 12,
      width: 12,
      left: -24,
      top: 4,
    },
  },
  sectionLearnMore: {
    display: "block",
    marginLeft: theme.spacing(3.5),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    textTransform: "uppercase",
    fontWeight: 500,
    fontSize: "0.875rem",
  },
  sectionImage: {
    display: "block",
    width: "100%",
    maxWidth: 430,
    margin: "0 auto",
  },
  button: {
    marginTop: theme.spacing(1.5),
    boxShadow: "0px 6px 30px rgba(147,64,255,0.3)",
    minWidth: 350,
  },
  landlordButtons: {},
  buttons: {
    maxWidth: 1000,
    margin: "0 auto",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    paddingBottom: theme.spacing(4),
  },
  buttonWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: theme.spacing(4),
  },
  [theme.breakpoints.down("md")]: {
    title: {
      fontSize: "30px",
    },
    sectionTitle: {
      fontSize: "22px",
    },
    button: {
      minWidth: 300,
    },
  },

  [theme.breakpoints.down("xs")]: {
    title: {
      fontSize: "22px",
      marginTop: theme.spacing(5),
    },
    sectionTitle: {
      fontSize: "20px",
    },
    sectionTitleWrapper: {
      width: "100%",
    },
    section: {
      flexDirection: "column-reverse",
      alignItems: "stretch",
      marginTop: theme.spacing(0),
    },
    sectionLeft: {
      flexBasis: "100%",
      padding: theme.spacing(2),
      marginTop: theme.spacing(-3),
    },
    sectionRight: {
      flexBasis: "100%",
      padding: theme.spacing(0),
    },
    mobileFlip: {
      textAlign: "right",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
    },
    mobileAlignEnd: {
      justifyContent: "flex-end",
    },
    buttons: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-around",
      paddingBottom: theme.spacing(3),
    },
    button: {
      marginTop: theme.spacing(0),
    },
    buttonWrapper: {
      paddingTop: theme.spacing(3),
    },
  },
}));

const LandlordButtons = () => {
  const classes = useStyles();
  const ga = useGA4React();
  const history = useHistory();
  const { loginWithRedirect } = useAuth0Nesitqa();

  const help = () => {
    if (!!ga) {
      logEvent("landlord_request_help");
    }
    history.push(GLOBALS.PATHS.CONTACT_US_PAGE);
  };

  const signup = () => {
    if (!!ga) {
      logEvent("landlord_ready_to_list", { page: "landlord" });
    }
    localStorage.setItem("previousLocation", window.location.pathname);
    loginWithRedirect({ screen_hint: "signup" });
  };

  // const [loginDialog, setLoginDialog] = useState({
  //   open: false,
  // });

  // const closeLoginDialog = () => {
  //   setLoginDialog({
  //     open: false,
  //   });
  // };

  // const openLoginDialog = () => {
  //   if (!!ga) {
  //     logEvent("landlord_ready_to_list", { page: "landlord" });
  //   }
  //   setLoginDialog({
  //     open: true,
  //     preLogin: () => {
  //       localStorage.setItem("previousLocation", window.location.pathname);
  //     },
  //     action: "create listing",
  //     onClose: closeLoginDialog,
  //   });
  // };

  return (
    <div className={classes.landlordButtons}>
      {/* <LoginDialog {...loginDialog} /> */}
      <div className={classes.buttons}>
        {GLOBALS.SITE_TEXTS.LANDLORD_PAGE_BUTTONS.map(button => (
          <div key={button.BUTTON_TEXT} className={classes.buttonWrapper}>
            <Button
              component={Button}
              variant="contained"
              color="primary"
              className={classes.button}
              disableElevation
              onClick={() => {
                if (button.ACTION === "login") {
                  signup();
                }
                if (button.ACTION === "help") {
                  help();
                }
              }}
            >
              {button.BUTTON_TEXT}
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default function LandlordHowItWorks(props) {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h4">
        {GLOBALS.SITE_TEXTS.LANDLORD_PAGE_TITLE}
      </Typography>
      <ul className={classes.sectionPoints}>
        {GLOBALS.SITE_TEXTS.LANDLORD_PAGE_POINTS.map(point => (
          <li key={point} className={classes.sectionPoint}>
            {point}
          </li>
        ))}
      </ul>
      <LandlordButtons />

      <Typography className={classes.title} align="center" variant="h4">
        {GLOBALS.SITE_TEXTS.LANDLORD_PAGE_HOW_IT_WORKS_TITLE}
      </Typography>
      <div className={classes.sections}>
        {GLOBALS.SITE_TEXTS.LANDLORD_PAGE_HOW_IT_WORKS_SECTIONS.map(
          (section, idx) => (
            <div
              key={section.TITLE}
              className={clsx(classes.section, idx % 2 === 0 && classes.flip)}
            >
              <div
                className={clsx(
                  classes.sectionLeft,
                  idx % 2 !== 0 && classes.mobileFlip
                )}
              >
                <div
                  className={clsx(
                    classes.sectionTitleWrapper,
                    idx % 2 !== 0 && classes.mobileAlignEnd
                  )}
                >
                  <span className={clsx(classes.sectionNumber)}>{idx + 1}</span>
                  <Typography
                    variant="h5"
                    className={clsx(classes.sectionTitle)}
                  >
                    {section.TITLE}
                  </Typography>
                </div>
                <Typography variant="body2">{section.TEXT}</Typography>
              </div>
              <div className={clsx(classes.sectionRight)}>
                <img
                  src={getStaticImgSrc(IMAGES[section.IMAGE_NAME])}
                  alt={section.TITLE}
                  className={classes.sectionImage}
                />
              </div>
            </div>
          )
        )}
      </div>
      <LandlordButtons />
    </div>
  );
}
