import { useState, useEffect } from "react";
import {
  getListing,
  landlordGetListing,
  createListing,
  createListingImage,
  updateListingImage,
  deleteListingImage,
  getListingApplications,
} from "./ListingApi";
import { camelToSnake, formatDate } from "../nestiqaUtils";
import useAuth0Nesitqa from "./useAuth0Nestiqa";

export default function useListing(params) {
  const [results, setResults] = useState({
    status: "IDLE",
  });
  const [imageControls, setImageControls] = useState();
  const { accessToken } = useAuth0Nesitqa("");

  function clearResults() {
    setResults({ status: "IDLE" });
  }

  function preparePayload(listing) {
    const result = { ...listing };
    if (result.availabilityDate)
      result.availabilityDate = formatDate(result.availabilityDate);
    ["longitude", "latitude", "sqft"].forEach(value => {
      if (result[value] === "") result[value] = null;
    });
    return camelToSnake(result);
  }

  useEffect(() => {
    if (!params) return;
    if (!accessToken && params.action !== "get-listing") return;

    const setResultsCallback = (isSuccess, apiResult, apiError) => {
      setResults({
        status: "COMPLETE",
        listing: isSuccess ? apiResult : null,
        errors: isSuccess ? null : [apiError],
        clear: clearResults,
      });
    };

    const setApplicationsResultsCallback = (isSuccess, apiResult, apiError) => {
      setResults({
        status: "COMPLETE",
        payload: isSuccess ? apiResult : null,
        errors: isSuccess ? null : [apiError],
        clear: clearResults,
      });
    };

    setResults({ status: "LOADING" });
    switch (params.action) {
      case "get-listing":
        getListing(params.listingNumber, accessToken, setResultsCallback);
        break;
      case "landlord-get-listing":
        landlordGetListing(
          params.listingNumber,
          accessToken,
          setResultsCallback
        );
        break;
      case "create-listing":
        createListing(
          preparePayload(params.listing),
          accessToken,
          setResultsCallback
        );
        break;
      case "update-listing-images":
        setImageControls({
          results: [],
          errors: [],
          listing: params.listing,
          filesStack: params.updatedImages,
        });
        break;
      case "get-listing-applications":
        getListingApplications(
          params.listingNumber,
          accessToken,
          setApplicationsResultsCallback
        );
        break;
      default:
        setResults({
          status: "COMPLETE",
          errors: [`${params.action} is not a valid action`],
          clear: clearResults,
        });
        break;
    }
  }, [params, accessToken]);

  useEffect(() => {
    if (!imageControls || !accessToken) return;

    const fs = imageControls.filesStack;

    if (!fs || fs.length === 0) {
      let tarListing = {
        ...imageControls.listing,
        images: imageControls.results,
      };
      delete tarListing.updatedImages;
      setResults({
        status: "COMPLETE",
        listing: tarListing,
        errors: imageControls.errors.length === 0 ? null : imageControls.errors,
        clear: clearResults,
      });
      setImageControls(null);
      return;
    }

    const imageResultsCallback = (isSuccess, result, error) => {
      const tarImageControls = {
        ...imageControls,
        filesStack: fs.slice(0, -1),
      };
      if (isSuccess && result.id) {
        tarImageControls.results = [result, ...tarImageControls.results];
      }
      if (!isSuccess) {
        tarImageControls.errors = [...tarImageControls.errors, error];
      }
      setImageControls(tarImageControls);
    };

    const handleItem = async currentItem => {
      if (currentItem.localFile) {
        if (currentItem.toRemove) {
          // If local file marked for removal do nothing
          // Removing from files stack without API call
          setImageControls({
            ...imageControls,
            filesStack: fs.slice(0, -1),
          });
        } else {
          await createListingImage(
            currentItem.imageFile,
            fs.length,
            imageControls.listing.id,
            accessToken,
            imageResultsCallback
          );
        }
      } else {
        if (currentItem.toRemove) {
          await deleteListingImage(
            currentItem.id,
            accessToken,
            imageResultsCallback
          );
        } else {
          await updateListingImage(
            currentItem.id,
            fs.length,
            accessToken,
            imageResultsCallback
          );
        }
      }
    };

    handleItem(fs[fs.length - 1]);
  }, [imageControls, accessToken]);

  return results;
}
