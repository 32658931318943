import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import useAuth0Nesitqa from "../../hooks/useAuth0Nestiqa";
import NestiqaAlertDialog from "../../components/NestiqaAlertDialog";
import PropertyAddress from "./PropertyAddress";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiDialog-paper": {
      width: "100%",
      maxWidth: 1000,
      // overflow: "visible",
    },
  },
}));

export default function AddPropertyDialog({
  open,
  closeDialog,
  listing,
  setListings,
  setListing,
  isCreateListing = false,
  handlePropertyCreateSuccess,
}) {
  const classes = useStyles();

  const { accessToken } = useAuth0Nesitqa("");
  const [dialogControls, setDialogControls] = useState({
    open: false,
    title: "",
    message: "",
  });

  function showError(message) {
    setDialogControls({
      open: true,
      title: "Error",
      message: message,
    });
  }

  const stepProps = {
    listing: listing,
    setListings: setListings,
    setListing: setListing,
    accessToken: accessToken,
    showError: showError,
    isEditDialog: true,
    closeDialog: closeDialog,
    isCreateListing: isCreateListing,
    handlePropertyCreateSuccess: handlePropertyCreateSuccess,
  };

  return (
    <>
      <Dialog className={classes.root} open={open} onClose={closeDialog}>
        <PropertyAddress {...stepProps} />
      </Dialog>
      <NestiqaAlertDialog
        {...dialogControls}
        onClose={() => setDialogControls({ open: false })}
      />
    </>
  );
}
