import { createStore, applyMiddleware, compose } from "redux";
import { rootReducer } from "./reducers/rootReducer";
import thunk from "redux-thunk";

const initialState = {};
const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
      })
    : compose;
const middlewares = [thunk];
const enhancer = composeEnhancers(applyMiddleware(...middlewares));

const store = createStore(rootReducer, initialState, enhancer);

export default store;
