import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  listing: {
    marginTop: "10px",
  },
  marginTop: {
    marginTop: "15px",
  },
  rightSide: {
    color: theme.palette.primary.dark,
    justifyContent: "space-between",

    "& svg": {
      color: theme.palette.secondary.main,
    },
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: 1000,
  },
  infoLine: {
    borderRight: "2px solid",
    borderColor: theme.palette.primary.dark,
    paddingRight: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  scrollableDescWrapper: {
    whiteSpace: "pre-line",
  },
  mt50: {
    marginTop: "20px",
  },
  mlsDescription: {
    marginTop: "10px",
    fontSize: "12px",
    // fontWeight: "bold",

    "& span": {
      color: theme.palette.primary.main,

      "&:hover": {
        cursor: "pointer",
      },
    },

    "@media (max-width: 502px)": {
      fontSize: "12px",
    },
  },
  partnerInfo: {
    marginTop: "20px",
    fontSize: "14px",
    fontWeight: "bold",

    "& span": {
      color: theme.palette.primary.main,

      "&:hover": {
        cursor: "pointer",
      },
    },

    "@media (max-width: 502px)": {
      fontSize: "12px",
    },
  },
  applyButton: {
    width: "150px",
    height: "45px",
    padding: "5px",
    borderRadius: "5px",
    background: theme.palette.primary.main,
    textTransform: "uppercase",
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(2),
    },
  },
  contactButton: {
    width: "100px",
    [theme.breakpoints.down("xs")]: {
      width: "150px",
    },
  },
  btnGrp: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column-reverse",
      alignItems: "stretch",
    },
  },
  readMoreLess: {
    color: theme.palette.primary.main,
  },
  addressWrapper: {
    fontSize: "20px",
    textAlign: "center",

    "& svg": {
      position: "relative",
      top: "4px",
      marginRight: "20px",
      marginLeft: "-7px",
    },

    [theme.breakpoints.down("md")]: {
      padding: "20px",
    },

    [theme.breakpoints.down("xs")]: {
      padding: "0 !important",

      "& svg": {
        marginRight: "10px",
        marginLeft: 0,
      },
    },
  },
  title: {
    fontSize: "1.25rem",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
    lineHeight: 1.6,
    letterSpacing: "0.0075em",
  },
  secondAddress: {
    fontSize: "14px",
    fontWeight: 500,
    marginLeft: "39px",

    [theme.breakpoints.down("xs")]: {
      marginLeft: "33px",
    },
  },
  priceText: {
    fontSize: "24px",
  },
  bedsBaths: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",

    "& .MuiButtonGroup-root": {
      "& .MuiButton-label": {
        fontSize: "14px",
        fontWeight: 500,
        textTransform: "capitalize",

        "& img": {
          [theme.breakpoints.down("xs")]: {
            marginLeft: "2px",
          },
        },
      },
    },

    "& span:last-child": {
      fontSize: "22px",
      fontWeight: 700,
      marginLeft: "15px",
    },

    [theme.breakpoints.down("xs")]: {
      width: "100%",
      justifyContent: "center",
      marginTop: "20px",
    },
  },
  bedBathText: {
    marginBottom: "-5px",
    whiteSpace: "nowrap",

    [theme.breakpoints.down("xs")]: {
      whiteSpace: "pre-wrap",
      lineHeight: 0.8,
      fontSize: "13px",
    },
  },
  icons: {
    width: "21px",
    height: "21px",
    marginLeft: "7px",
  },
  details: {
    width: "100%",
    marginTop: "15px",
    background: "#F5F5F5 0% 0% no-repeat padding-box",

    [theme.breakpoints.down("md")]: {
      background: "none",
    },
  },
  scrollable: {
    padding: "20px",
    width: "100%",
    height: "599px",
    maxHeight: "599px",
    overflowY: "auto",

    [theme.breakpoints.down("md")]: {
      overflowY: "unset",
      maxHeight: "none",
      height: "auto",
    },

    [theme.breakpoints.down("xs")]: {
      padding: 0,
      overflowY: "unset",
      maxHeight: "none",
      height: "auto",
    },
  },
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
  },
  amenitiesOuter: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    paddingBottom: "10px",
  },
  borderTop: {
    borderTop: `1px solid ${theme.palette.secondary.main}`,
  },
  borderBottom: {
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
  },
  listingDetailsList: {
    listStyleType: "none",
    float: "left",
    width: "100%",
    padding: 0,
    margin: 0,
    paddingTop: "15px",
    paddingBottom: "15px",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",

    "& svg": {
      marginRight: "5px",
      color: theme.palette.secondary.main,
    },

    "& li": {
      display: "flex",
      width: "50%",
      float: "left",
      flexWrap: "wrap",
      textAlign: "start",
      alignItems: "center",
      paddingBottom: "5px",
      paddingTop: "5px",
      overflow: "hidden",

      "& .MuiTypography-root.MuiTypography-subtitle1": {
        fontSize: "14px",
        fontWeight: 500,
        marginRight: "5px",
      },

      "& .MuiTypography-root.MuiTypography-subtitle2": {
        fontSize: "14px",
        fontWeight: 400,
      },
    },

    "& li:nth-child(2n+2)": {
      paddingLeft: "50px",
    },

    [theme.breakpoints.down("md")]: {
      "& li:nth-child(2n+2)": {
        paddingLeft: "150px",
      },
    },

    [theme.breakpoints.down("xs")]: {
      "& li": {
        width: "100%",
        padding: "6px 0",
      },

      "& li:nth-child(2n+2)": {
        paddingLeft: 0,
      },
    },
  },
  amenitiesWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    flexFlow: "wrap",
    paddingBottom: "10px",

    "& svg": {
      marginRight: "5px",
      fontSize: "21px",
    },

    "& .MuiTypography-subtitle1": {
      fontSize: "15px",
      padding: "5px",
    },
  },
  amenitiesInner: {
    display: "flex",
    alignItems: "center",
    width: "33.33%",
    marginBottom: "12px !important",
    marginTop: "3px",

    "& .MuiTypography-root.Mui-Typography-h5": {
      textAlign: "flex-start",
      width: "100%",
    },

    "& .MuiTypography-root.MuiTypography-body1": {
      fontSize: "15px",
    },

    "& .MuiTypography-subtitle1": {
      fontSize: "15px",
      padding: "5px",
    },

    "& svg": {
      color: theme.palette.secondary.main,
      fontSize: "21px",
      marginRight: "10px",
    },

    [theme.breakpoints.down("xs")]: {
      width: "50%",
      marginRight: 0,

      "& .MuiTypography-root": {
        fontSize: "14px",
      },
    },
  },
  unitFeaturesWrapper: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    flexFlow: "wrap",
    paddingBottom: "10px",

    "& .MuiTypography-root.Mui-Typography-h5": {
      textAlign: "flex-start",
      width: "100%",
    },

    "& .MuiTypography-root.MuiTypography-body1": {
      fontSize: "15px",
    },

    "& svg": {
      marginRight: "5px",
      fontSize: "21px",
    },

    "& .MuiTypography-subtitle1": {
      fontSize: "15px",
      padding: "5px",
    },

    [theme.breakpoints.down("xs")]: {
      "& .MuiTypography-root": {
        fontSize: "14px",
      },
    },
  },
  landlordImg: {
    width: "45px",
    height: "45px",
    objectFit: "cover",
    borderRadius: "5px",
  },
  contactWrapper: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",

    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
    },

    "& .MuiTypography-root": {
      fontSize: "17px",
      fontWeight: 400,
      color: "#000",

      [theme.breakpoints.down("xs")]: {
        "& svg": {
          fontSize: "1.2rem",
          marginLeft: "-4px",
        },
        width: "100%",
        fontSize: "15px",
      },
    },

    "& .MuiTypography-root:nth-child(2)": {
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
      },
    },
  },
  contactNumber: {
    display: "flex",
    alignItems: "center",
    color: "#808080 !important",

    "& svg": {
      color: theme.palette.secondary.main,
    },
  },
  mapWrapper: {
    width: "100%",
    paddingTop: "15px !important",
    paddingLeft: "8px !important",
    paddingRight: "24px",

    "& a:hover": {
      cursor: "pointer",
    },

    "& a": {
      width: "100%",

      "& img": {
        width: "100%",
      },
    },

    [theme.breakpoints.down("md")]: {
      padding: "0 !important",
    },

    [theme.breakpoints.down("xs")]: {
      padding: "0 !important",
    },
  },
  tablet: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    [theme.breakpoints.up("md")]: {
      "& a": {
        width: "100% !important",

        "& img": {
          width: "100% !important",
        },
      },
    },
  },
  mobileMap: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "calc(100% - 45px) !important",
    width: "100% !important",

    "& a": {
      width: "100% !important",

      "& img": {
        width: "100% !important",
      },
    },
  },
  description: {
    padding: "15px 0",
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    alignItems: "start",
  },
  subTitle: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    paddingTop: "5px",
    paddingBottom: "5px",
    alignItems: "center",

    "& .MuiTypography-root": {
      fontSize: "15px",
      fontWeight: 600,
      padding: "10px 0 5px 0",
    },
  },
  subTitleInner: {
    width: "100%",
  },
  landlordWrapper: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  landlord: {
    display: "flex",
    alignItems: "center",
    margin: "10px 0",

    "& img": {
      marginRight: "20px",
    },
  },
  landlordName: {
    flexWrap: "wrap",
    alignItems: "center",
    margin: "auto 0",
    flexGrow: 1,

    "& div:first-child": {
      fontSize: "16px",
      fontWeight: 600,

      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
      },
    },

    "& div:last-child": {
      fontSize: "14px",
      fontWeight: 400,
      color: "#808080",

      [theme.breakpoints.down("xs")]: {
        fontSize: "13px",
      },
    },
  },
  landlordSocial: {
    display: "flex",
    alignItems: "center",

    "& .MuiButtonGroup-root": {
      "& .MuiButtonGroup-grouped": {
        [theme.breakpoints.down("xs")]: {
          minWidth: 0,
          padding: "0 2px",

          "& svg": {
            fontSize: "1.5rem",
          },
        },
      },

      "& .MuiButton-outlined": {
        border: "none",
        padding: "5px 0",
      },

      "& .MuiButtonBase-root .MuiButton-label .MuiSvgIcon-root": {
        fontSize: "1.7rem",
      },
    },
  },
  socialWrapper: {
    width: "100%",
    justifyContent: "center",
    display: "inline-flex",
    borderRadius: "4px",
    boxSizing: "inherit",
    textAlign: "center",

    "& .MuiTypography-root.MuiLink-root": {
      minWidth: "40px",
      "& svg": { fontSize: "1.7rem !important" },

      [theme.breakpoints.down("xs")]: {
        minWidth: 0,
        padding: "0 2px",

        "& svg": {
          fontSize: "1.3rem !important",
        },
      },
    },
  },
  bedBath: {
    width: "100%",
    justifyContent: "center",

    "& .MuiButtonBase-root": {
      [theme.breakpoints.down("xs")]: {
        width: "33.33%",
        height: "36px",
      },
    },
  },
  imageContainer: {
    "& div:first-child": {
      "& div:first-child": {
        display: "flex",
        justifyContent: "center",
      },
    },
  },
  imagesMobile: {
    textAlign: "center",
  },
  bedsBox: {
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
    },
  },
  sqftButton: {
    "& > *": {
      textTransform: "lowercase !important",
    },
  },
  topHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  mlsImageWrapper: {
    width: "100px",

    "& img": {
      width: "100%",
    },
  },
  paddingY: {
    padding: "15px 0",
  },
}));
