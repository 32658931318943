import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { GLOBALS } from "../App";
import useAuth0Nesitqa from "../hooks/useAuth0Nestiqa";

const useStyles = makeStyles(theme => ({
  actionLink: {
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  dialogActions: {
    width: "100%",
    paddingBottom: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& > button:first-child": {
      marginRight: theme.spacing(4),
    },
  },
}));

const LoginDialog = ({ open, onClose, action = "", preLogin }) => {
  const classes = useStyles();
  const { loginWithRedirect } = useAuth0Nesitqa();

  const login = () => {
    if (preLogin) {
      preLogin();
    }
    loginWithRedirect();
  };

  const signup = () => {
    if (preLogin) {
      preLogin();
    }
    loginWithRedirect({ screen_hint: "signup" });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="login-dialog-slide-title"
      aria-describedby="login-dialog-slide-description"
    >
      <DialogTitle id="login-dialog-slide-title">
        {GLOBALS.MESSAGES.LOGIN_DIALOG_TITLE}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="nesitqa-login-dialog-description">
          {/* Split the text and insert login & signup links */}
          {GLOBALS.MESSAGES.LOGIN_DIALOG_TEXT.replace("[action]", action)
            .split(":link")
            .map((textNode, idx) => {
              if (idx === 0) {
                return (
                  <span key={idx}>
                    {textNode}
                    <span onClick={login} className={classes.actionLink}>
                      Log In
                    </span>
                  </span>
                );
              }

              if (idx === 1) {
                return (
                  <span key={idx}>
                    {textNode}
                    <span onClick={signup} className={classes.actionLink}>
                      Sign Up
                    </span>
                  </span>
                );
              }

              return <span key={idx}>{textNode}</span>;
            })}
        </DialogContentText>
        <div className={classes.dialogActions}>
          <Button onClick={login} color="primary" variant="contained">
            Log In
          </Button>
          <Button onClick={signup} color="primary" variant="contained">
            Sign Up
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

LoginDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  action: PropTypes.string,
  preLogin: PropTypes.func,
};

export default LoginDialog;
