import { combineReducers } from "redux";
import listingsReducer from "./listingsReducer";
import tenantReducer from "./tenantReducer";
import userReducer from "./userReducer";
import reportsReducer from "./reportsReducer";
import dialogReducer from "./dialogReducer";

export const rootReducer = combineReducers({
  listings: listingsReducer,
  tenant: tenantReducer,
  reports: reportsReducer,
  activeUser: userReducer,
  dialog: dialogReducer,
});
