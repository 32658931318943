import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Typography } from "@material-ui/core";
import { GLOBALS } from "../App";
import { makeStyles } from "@material-ui/core/styles";
import useListing from "../hooks/useListing";
import NestiqaAlertDialog from "../components/NestiqaAlertDialog";
import useAuth0Nesitqa from "../hooks/useAuth0Nestiqa";
import ListingInfo from "../components/listing/ListingInfo";
import ListingAddress from "../components/listing/ListingAddress";
import ListingPhotos from "../components/listing/ListingPhotos";
import ListingPreview from "../components/listing/ListingPreview";
import { Helmet } from "react-helmet";
import { getFullAddress } from "../nestiqaUtils";
import ListingFeatures from "../components/listing/ListingFeatures";
import createTenantBg from "./create_tenant_bg.png";
import ListingPublish from "../components/listing/ListingPublish";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: "calc(100vh - 80px - 64px)",
    backgroundImage: `url(${GLOBALS.REACT_BASE_URL}${createTenantBg})`,
    backgroundPosition: "64px calc(100% + 64px)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "110%",
    marginBottom: "-64px",
    paddingBottom: "64px",
  },
}));

export default function UpdateListingPage() {
  const classes = useStyles();
  const { listingNumber } = useParams();
  const [listingParams] = useState({
    listingNumber: listingNumber,
    action: "landlord-get-listing",
  });
  const listingResult = useListing(listingParams);
  const [listing, setListing] = useState();
  const [step, setStep] = useState(1);
  const { accessToken } = useAuth0Nesitqa("");
  const [dialogControls, setDialogControls] = useState({
    open: false,
    title: "",
    message: "",
  });
  const [fullAddress, setFullAddress] = useState("");

  useEffect(() => {
    if (listingResult.status === "COMPLETE" && listingResult.listing) {
      const address = getFullAddress(listingResult.listing);
      setFullAddress(address);
      const { landlord } = listingResult.listing;
      setListing({
        ...listingResult.listing,
        contactName:
          listingResult.listing.contactName ||
          `${landlord.firstName} ${landlord.lastName}` ||
          landlord.companyName,
        contactPhone:
          listingResult.listing.contactPhone || landlord.phoneNumber,
      });
    }
  }, [listingResult]);

  if (listingResult.errors) {
    console.error(listingResult.errors);
    return (
      <Typography variant="h5" align="center" color="error">
        {GLOBALS.MESSAGES.LISTING_NOT_FOUND} {listingNumber}
      </Typography>
    );
  }

  if (!listing) {
    return (
      <Typography variant="h5" align="center" color="primary">
        Loading...
      </Typography>
    );
  }

  function showError(message) {
    setDialogControls({
      open: true,
      title: "Error",
      message: message,
    });
  }

  const stepProps = {
    step: step,
    setStep: setStep,
    listing: listing,
    setListing: setListing,
    accessToken: accessToken,
    showError: showError,
  };

  function showStep() {
    switch (step) {
      case 1:
        return <ListingAddress {...stepProps} />;
      case 2:
        return <ListingInfo {...stepProps} />;
      case 3:
        return <ListingFeatures {...stepProps} />;
      case 4:
        return <ListingPhotos {...stepProps} />;
      case 5:
        return <ListingPreview {...stepProps} />;
      case 6:
        return <ListingPublish {...stepProps} />;
      default:
        return <div />;
    }
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <title>
          {GLOBALS.META_TAGS.TITLE.UPDATE_LISTING_PAGE + fullAddress}
        </title>
      </Helmet>
      <Container maxWidth="lg">
        {showStep()}
        <NestiqaAlertDialog
          {...dialogControls}
          onClose={() => setDialogControls({ open: false })}
        />
      </Container>
    </div>
  );
}
