import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Checkbox } from "formik-material-ui";
import { Link, useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import MuiLink from "@material-ui/core/Link";
import { KeyboardDatePicker } from "formik-material-ui-pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Formik, Form, Field } from "formik";
import { GLOBALS } from "../../App";
import { updateApplication, apply } from "../../utils/ApplicationApi";
import { getAvailableReports } from "../../utils/ReportsApi";
import { useGA4React } from "ga-4-react";
import * as yup from "yup";
import { format } from "date-fns";
import { logEvent } from "../../utils/initGa4React";
import NestiqaActionDialog from "../NestiqaActionDialog";
import NestiqaAlertDialog from "../NestiqaAlertDialog";
import YesNoField from "../YesNoField";
import theme from "../../theme";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  formRoot: {
    flexGrow: 1,
    padding: "24px 30px 30px 30px",
    width: "100%",
    maxWidth: "920px",
    "& h5": {
      marginBottom: "15px",
    },

    [theme.breakpoints.down("xs")]: {
      padding: "24px 20px 30px",
    },
  },
  flexContainer: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    boxSizing: "border-box",
    alignItems: "center",
  },
  termsContainer: {
    flexGrow: 1,
    alignItems: "center",
    display: "flex",

    "& > .MuiTypography-subtitle2": {
      fontSize: "12px",
      fontWeight: 600,

      "& > a": {
        color: "inherit",
      },
    },
  },
  styledButton: {
    minWidth: "145px",
    minHeight: "38px",
    fontSize: "16px",
    fontWeight: "lighter",
    textTransform: "capitalize",
  },
  ctaContainer: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "space-between",

      "& > .MuiButton-root": {
        minWidth: "45%",
      },
    },
  },
  cancelButton: {
    marginRight: "30px",

    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
    },
  },
  checkboxField: {
    marginLeft: theme.spacing(4),

    [theme.breakpoints.down("xs")]: {
      margin: 0,
      padding: 0,
    },
  },
  checkboxText: {
    margin: "auto 0",
    whiteSpace: "nowrap",

    "& > MuiCheckbox-root": {
      padding: 0,
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
  },
  coverLetter: {
    "& label": {
      color: "#c3c3c3",
    },
  },
  sectionContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  sectionPart: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",

    [theme.breakpoints.down("xs")]: {
      width: "100%",
      justifyContent: "space-between",

      "&:nth-child(1)": {
        textAlign: "right",
      },
    },

    "& .MuiTypography-root.MuiTypography-subtitle2": {
      fontSize: "14px",
      marginRight: theme.spacing(6),

      [theme.breakpoints.down("md")]: {
        marginRight: theme.spacing(2),
      },
    },

    "&:nth-child(2)": {
      justifyContent: "flex-end",

      [theme.breakpoints.down("xs")]: {
        justifyContent: "space-between",
      },
    },
  },
  datePickerContainer: {
    minWidth: "200px",

    [theme.breakpoints.down("xs")]: {
      minWidth: "unset",
      maxWidth: "185px",
    },

    [theme.breakpoints.down("sm")]: {
      marginRight: "-25px",
      minWidth: "180px",
      maxWidth: "180px",
    },
  },
  yesOrNo: {
    minWidth: "100px",
    maxWidth: "165px",
  },
}));

export default function ApplicationForm({ application = {}, token, detail }) {
  const classes = useStyles();
  const [formikProps, setFormikProps] = useState("");
  const { tenant, listing } = application;
  const history = useHistory();
  const ga = useGA4React();
  const [messageDialogControls, setMessageDialogControls] = useState({
    open: false,
  });
  const [actionDialogControls, setActionDialogControls] = useState({
    open: false,
  });
  const [formattedDate, setFormattedDate] = useState("");
  const [reports, setReports] = useState(null);

  useEffect(() => {
    if (application.moveInDate) {
      setFormattedDate(application.moveInDate);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function callGetReports() {
      getAvailableReports(token, getReportsCallback);
    }

    if (token && !reports) callGetReports();
  }, [token, reports]);

  function getReportsCallback(isSuccess, result, error) {
    if (isSuccess) {
      setReports(result);
    }
    if (error) {
      console.log(error);
    }
  }

  function applyCallback(isSuccess, result, error) {
    formikProps.setSubmitting(false);
    if (isSuccess) {
      if (!!ga) {
        logEvent("apply_submit", {
          tenant_id: application.tenant.id,
          listing_number: application.listing.listingNumber,
          application_id: application.id,
        });
      }
      setActionDialogControls({ open: false });
      setMessageDialogControls({
        open: true,
        title: "Success",
        message: GLOBALS.MESSAGES.REPORTS_APPLY,
        onClose: () =>
          history.push("/", { caller: "applicationSubmitRedirect" }),
      });
      return result;
    } else {
      if (error) {
        console.error(error);
      }
      handleError();
    }
  }

  async function callApply(values) {
    if (application.id) {
      const app = {
        id: application.id,
        cover_letter: values.cover_letter,
        move_in_date: values.flexible
          ? null
          : format(values.move_in_date, "yyyy-MM-dd"),
        listing_seen: values.visited_property,
      };
      await apply(token, app, applyCallback);
    } else {
      handleError();
    }
  }

  const yesNoButtonMap = [
    {
      buttonName: "No",
      handleClick: () => setActionDialogControls({ open: false }),
    },
    {
      buttonName: "Yes",
      handleClick: () => handleDeleteApplication(),
    },
  ];

  function getPreQualifyOrUpgradeButtonMap(values) {
    return [
      {
        buttonName: "Get Pre-Approved",
        handleClick: () => handlePreQualified(values),
      },
      {
        buttonName: "Upgrade",
        handleClick: () => handleUpgrade(values),
      },
      {
        buttonName: "Cancel",
        handleClick: () => setActionDialogControls({ open: false }),
      },
    ];
  }

  async function handleDeleteApplication() {
    const response = await updateApplication(
      application.id,
      { status: "DELETED" },
      token
    );
    if (response.status === "DELETED") {
      return history.goBack();
    } else {
      handleError();
    }
  }

  function handleError() {
    setMessageDialogControls({
      open: true,
      title: "Error",
      message: GLOBALS.MESSAGES.ERROR_COMPLETING_ACTION,
      onClose: () => setMessageDialogControls({ open: false }),
    });
  }

  async function callApiAndShowResult(data) {
    const response = await updateApplication(application.id, data, token);
    formikProps.setSubmitting(false);
    if (response && response.status === "SUBMITTED") {
      if (data.action === "PREQUALIFY") {
        if (!!ga) {
          logEvent("prequalify_submit", {
            tenant_id: application.tenant.id,
            listing_number: application.listing.listingNumber,
            application_id: application.id,
          });
        }
      }
      setMessageDialogControls({
        open: true,
        title: "Success",
        message: GLOBALS.MESSAGES.APPLICATION_SUCCESSFUL,
        onClose: () =>
          history.push("/", { caller: "applicationSubmitRedirect" }),
      });
      return;
    }
    handleError();
  }

  async function handlePreQualified(values) {
    const data = {
      action: "PREQUALIFY",
      status: "SUBMITTED",
      move_in_date: values.flexible
        ? null
        : format(values.move_in_date, "yyyy-MM-dd"),
      cover_letter: values.cover_letter,
      listing_seen: values.visited_property,
    };
    callApiAndShowResult(data);
    setActionDialogControls({ open: false });
  }

  async function handleUpgrade(values) {
    const data = {
      action: "APPLY",
      status: application.status,
      move_in_date: values.flexible
        ? null
        : format(values.move_in_date, "yyyy-MM-dd"),
      cover_letter: values.cover_letter,
      listing_seen: values.visited_property,
    };
    callApiAndShowResult(data);
    setActionDialogControls({ open: false });
    history.push(`/tenant/${tenant.id}/plan`);
  }

  async function handleGoToReports(values) {
    const data = {
      action: "APPLY",
      status: application.status,
      move_in_date: values.flexible
        ? null
        : format(values.move_in_date, "yyyy-MM-dd"),
      cover_letter: values.cover_letter,
      listing_seen: values.visited_property,
    };
    callApiAndShowResult(data);
    setActionDialogControls({ open: false });
    history.push(`/tenant/${tenant.id}/reports`);
  }

  function getHouseholdButtonMap(val) {
    return [
      {
        buttonName: "No",
        handleClick: () => {
          formikProps.setSubmitting(false);
          setActionDialogControls({ open: false });
        },
      },
      {
        buttonName: "Yes",
        handleClick: () => {
          setActionDialogControls({ open: false });
          handleApply(val);
        },
      },
    ];
  }

  const checkHousehold = values => {
    if (tenant.toComplete.includes("household")) {
      setActionDialogControls({
        open: true,
        title: "Household Information",
        message: GLOBALS.MESSAGES.HOUSEHOLD_INFORMATION,
        buttonMap: getHouseholdButtonMap(values),
      });
    } else {
      handleApply(values);
    }
  };

  const PreQualifyMessage = () => (
    <>
      {GLOBALS.MESSAGES.PRE_QUALIFY_OR_UPGRADE}{" "}
      <MuiLink
        component="a"
        underline="none"
        color="primary"
        href="https://resources.nestiqa.com/faq/#apply-preapprove-diff"
        target="_blank"
        rel="noopener noreferrer"
      >
        Learn more
      </MuiLink>
    </>
  );

  async function handleApply(values) {
    if (listing.applyAllowed === "N") {
      handlePreQualified(values);
      return;
    }

    if (tenant.activePlan.planType !== "UN") {
      formikProps.setSubmitting(false);
      setActionDialogControls({
        open: true,
        title: "Get Pre-Approved",
        message: <PreQualifyMessage />,
        buttonMap: getPreQualifyOrUpgradeButtonMap(values),
      });
      return;
    }

    if (tenant.activePlan.planType === "UN") {
      const status = "REPORTS_READY";
      if (reports.status !== status) {
        formikProps.setSubmitting(false);
        setActionDialogControls({
          open: true,
          title: "Please Complete Reports",
          message: GLOBALS.MESSAGES.COMPLETE_REPORTS,
          buttonMap: [
            {
              buttonName: "Go to Reports",
              handleClick: () => handleGoToReports(values),
            },
          ],
        });
        return;
      }

      if (reports.dailyLimitReached === "Y") {
        const data = {
          action: "APPLY",
          status: application.status,
          move_in_date: values.flexible
            ? null
            : format(values.move_in_date, "yyyy-MM-dd"),
          cover_letter: values.cover_letter,
          listing_seen: values.visited_property,
        };
        await updateApplication(application.id, data, token);

        formikProps.setSubmitting(false);
        setActionDialogControls({ open: false });
        setMessageDialogControls({
          open: true,
          title: "Error",
          message: GLOBALS.MESSAGES.REPORTS_LIMIT_REACHED,
          onClose: () => setMessageDialogControls({ open: false }),
        });
        return;
      } else {
        await callApply(values);
        return;
      }
    }
  }

  const validationSchema = yup.object().shape({
    move_in_date: yup.date().typeError(GLOBALS.MESSAGES.ENTER_VALID_DATE),
    visited_property: yup
      .string()
      .equals(["Y", "N"])
      .required(GLOBALS.MESSAGES.PLEASE_SELECT),
  });

  return (
    <React.Fragment>
      <NestiqaAlertDialog {...messageDialogControls} />
      <NestiqaActionDialog {...actionDialogControls} />
      {detail ? (
        <Typography variant="h5" align="center" color="primary">
          {detail}
        </Typography>
      ) : Object.keys(application).length === 0 &&
        application.constructor === Object ? (
        <Typography variant="h5" align="center" color="primary">
          Loading...
        </Typography>
      ) : application.status === "DELETED" ? (
        <Typography variant="h5" align="center" color="primary">
          Application Deleted apply again
        </Typography>
      ) : (
        <Formik
          initialValues={{
            move_in_date: formattedDate || new Date(),
            cover_letter: application.coverLetter,
            flexible: false,
            visited_property: application.listing_seen || "",
          }}
          validationSchema={validationSchema}
          onSubmit={values => checkHousehold(values)}
        >
          {({ isSubmitting, handleBlur, values, submitForm, ...rest }) => (
            <div className={classes.formRoot}>
              <Form
                className={classes.flexContainer}
                noValidate
                autoComplete="off"
              >
                {isSubmitting && <LinearProgress />}
                <Grid container className={classes.sectionContainer}>
                  <Grid item xs={12} sm={6} className={classes.sectionPart}>
                    <Grid
                      item
                      xs={8}
                      md={4}
                      className={classes.datePickerContainer}
                    >
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Field
                          component={KeyboardDatePicker}
                          fullWidth
                          name="move_in_date"
                          label="Move in date"
                          format="MM/dd/yyyy"
                          disablePast
                          inputVariant="outlined"
                          variant="inline"
                          margin="normal"
                          autoOk
                          disabled={isSubmitting || values.flexible}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={4} className={classes.checkboxText}>
                      <Field
                        name="flexible"
                        type="checkbox"
                        component={Checkbox}
                        className={classes.checkboxField}
                      />
                      <span>I'm Flexible</span>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.sectionPart}>
                    <Typography variant="subtitle2">
                      Have you visited this property?
                    </Typography>
                    <YesNoField
                      className={classes.yesOrNo}
                      name="visited_property"
                      required
                      variant="outlined"
                      margin="normal"
                      label=""
                      handleBlur={handleBlur}
                    />
                  </Grid>
                </Grid>
                <Grid container justify="center">
                  <Grid item xs={12}>
                    <Field
                      className={classes.coverLetter}
                      component={TextField}
                      fullWidth
                      name="cover_letter"
                      type="text"
                      label="Cover Letter (Optional)"
                      variant="outlined"
                      margin="normal"
                      multiline
                      rows={4}
                      disabled={isSubmitting}
                    />
                  </Grid>
                </Grid>
                <Grid container justify="center" spacing={3}>
                  <Grid
                    item
                    align="left"
                    xs={12}
                    sm={4}
                    md="auto"
                    className={classes.termsContainer}
                  >
                    <Typography variant="subtitle2">
                      By clicking "Apply", you agree to our{" "}
                      <Link to="/terms-of-use">Terms of Use</Link> and{" "}
                      <Link to="/privacy-policy">Privacy Policy.</Link>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    md="auto"
                    align="right"
                    className={classes.ctaContainer}
                  >
                    <Button
                      className={clsx(
                        classes.styledButton,
                        classes.cancelButton
                      )}
                      disabled={isSubmitting}
                      variant="outlined"
                      color="primary"
                      onClick={() =>
                        setActionDialogControls({
                          open: true,
                          title: "Cancel Application",
                          message: GLOBALS.MESSAGES.CANCEL_APPLICATION,
                          buttonMap: yesNoButtonMap,
                        })
                      }
                    >
                      Cancel
                    </Button>
                    <Button
                      className={classes.styledButton}
                      disabled={isSubmitting || tenant.isComplete === "N"}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setFormikProps(rest);
                        submitForm();
                      }}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </div>
          )}
        </Formik>
      )}
    </React.Fragment>
  );
}
