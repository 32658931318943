import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
} from "@material-ui/core";
import { GLOBALS } from "../../App";


const ContactDialog = ({ open, onClose }) => {

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="contact-dialog-slide-title"
      aria-describedby="contact-dialog-slide-description"
    >
        <iframe
          src={GLOBALS.APP_BASE_URL + "/support/contact-form/"}
          title="Contact Form"
          style={{ width: "300px", height: "550px", border: "0px" }}
        ></iframe>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ContactDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ContactDialog;
