import React from "react";
import Profile from "../components/Profile";
import Container from "@material-ui/core/Container";

export default function ProfilePage() {
  return (
    <Container>
      <Profile />
    </Container>
  );
}
