import React from "react";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { GLOBALS } from "../../App";
import ChoiceField from "../ChoiceField";
import { STATES } from "../../constants/states";
import GoogleMapsAutocomplete from "../google-maps/GoogleMapsAutocomplete";
import * as yup from "yup";
import ListingFormControls from "./ListingFormControls";
import { updateListing } from "../../hooks/ListingApi";
import { getAddressFromPlace } from "../../utils/common";

const useStyles = makeStyles(theme => ({
  textGrids: {
    flexGrow: 1,
  },
  textInputs: {
    backgroundColor: "white",
    width: "100%",

    "& .MuiInputLabel-root": {
      color: theme.palette.grey[400],
    },

    "& .MuiInputLabel-shrink": {
      color: theme.palette.primary.main,
    },
  },
  address: {
    backgroundColor: "white",
    borderRadius: "10px",
    width: "100%",

    "& .MuiInputLabel-root": {
      color: theme.palette.grey[400],
    },

    "& .MuiInputLabel-shrink": {
      color: theme.palette.primary.main,
    },
  },
}));

export default function ListingAddress(props) {
  const { accessToken, listing, setListing, step, setStep, showError } = props;
  const MSG = GLOBALS.MESSAGES;

  function onStepSubmit(values, formikBag) {
    updateListing(
      listing.listingNumber,
      values,
      accessToken,
      (isSuccess, apiResult, apiError) => {
        if (isSuccess) {
          setListing(apiResult);
          setStep(step + 1);
        } else {
          console.error(apiError);
          showError(MSG.ERROR_COMPLETING_ACTION);
          formikBag.setSubmitting(false);
        }
      }
    );
  }

  function handleNext(formikprops) {
    if (formikprops.dirty) {
      formikprops.submitForm();
    } else {
      setStep(step + 1);
    }
  }

  const validationSchema = yup.object().shape({
    address1: yup
      .string()
      .required(GLOBALS.MESSAGES.FIELD_REQUIRED)
      .min(1)
      .max(50)
      .matches(
        GLOBALS.FORBIDDEN_CHARACTERS_REGEX,
        GLOBALS.MESSAGES.FORBIDDEN_CHARACTERS
      )
      .label("Street Address"),
    address2: yup.string().max(30).label("Apartment, floor or unit"),
    city: yup.string().required(GLOBALS.MESSAGES.FIELD_REQUIRED),
    state: yup
      .string()
      .matches(GLOBALS.US_STATE_REGEX, GLOBALS.MESSAGES.ENTER_VALID_STATE),
    zipCode: yup
      .string()
      .matches(GLOBALS.US_ZIP_REGEX, GLOBALS.MESSAGES.ENTER_VALID_ZIP),
  });

  return (
    <ListingFormControls
      listing={listing}
      step={step}
      setStep={setStep}
      onSubmit={onStepSubmit}
      validationSchema={validationSchema}
      handleNext={handleNext}
    >
      <ListingAddressFormikWrapper listing={listing} />
    </ListingFormControls>
  );
}

function ListingAddressFormikWrapper(props) {
  const classes = useStyles();
  const { values, setValues } = props.formikprops;

  const handleListingAddressChange = (newPlace, manual, values, setValues) => {
    if (!newPlace) return;
    if (manual) {
      setValues({
        ...values,
        address1: newPlace.address_components[0].short_name,
      });
    } else {
      const address = getAddressFromPlace(newPlace);
      setValues({ ...values, ...address });
    }
  };

  return (
    <Grid container justify="center">
      <Grid
        container
        item
        direction="column"
        alignItems="stretch"
        xs={12}
        sm={8}
        md={6}
        spacing={2}
      >
        <Grid item className={classes.textGrids}>
          <GoogleMapsAutocomplete
            className={classes.address}
            inputClassName={classes.textInputs}
            label="Street Address *"
            inputVariant="outlined"
            name="address"
            popupIcon={null}
            resultTypes={["address"]}
            initialValue={props.listing.address1}
            shortLabel
            allowManual
            onInputChange={newInputValue =>
              setValues({ ...values, address1: newInputValue })
            }
            handlePlaceChange={(place, placeSize, manual) =>
              handleListingAddressChange(place, manual, values, setValues)
            }
          />
        </Grid>
        <Grid item className={classes.textGrids}>
          <Field
            component={TextField}
            name="address2"
            type="text"
            label="Apartment, floor or unit"
            variant="outlined"
            className={classes.textInputs}
          />
        </Grid>
        <Grid item className={classes.textGrids}>
          <Field
            component={TextField}
            required
            name="city"
            type="text"
            label="City"
            variant="outlined"
            className={classes.textInputs}
          />
        </Grid>
        <Grid item className={classes.textGrids}>
          <Field
            component={ChoiceField}
            required
            name="state"
            variant="outlined"
            label="State"
            className={classes.textInputs}
            choices={STATES}
          />
        </Grid>
        <Grid item className={classes.textGrids}>
          <Field
            component={TextField}
            required
            name="zipCode"
            type="text"
            label="Zip Code"
            variant="outlined"
            className={classes.textInputs}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
