export const CREDIT_REPAIR_INFO = {
    CREDIT_SAINT: {
        name: "Credit Saint",
        logo: "https://www.ftjcfx.com/image-100405394-14079124",
        link: "https://www.tkqlhce.com/click-100405394-14079124",
    },
    CAPITAL_BANK: {
        name: "Capital Bank",
        logo: "https://www.tqlkg.com/image-100405394-10990874",
        link: "https://www.jdoqocy.com/click-100405394-10990874",
    },
  };