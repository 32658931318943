import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import {
  Grid,
  Box,
  Typography,
  Menu,
  Button,
  MenuItem,
} from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { formatNumber } from "../nestiqaUtils";
import { getSearchFilters } from "../store/selectors/listingsSelectors";
import { setSearchFilters } from "../store/actions/listingsActions";

function getTabsStyles(theme) {
  return {
    indicator: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
      "& > div": {
        maxWidth: 40,
        width: "100%",
        backgroundColor: "none",
      },
    },
    root: {
      borderColor: theme.palette.primary.dark,
    },
  };
}

function getTabsComponent(props) {
  return <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />;
}

const TabsSection = withStyles(getTabsStyles)(getTabsComponent);

const useStyles = makeStyles(theme => ({
  sideBox: {
    borderBottom: "2px solid",
    borderColor: theme.palette.primary.dark,
    height: "100%",
  },
  boxGrid: {
    flexGrow: 1,
  },
  wrapper: {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  sortingWrapper: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    borderBottom: "2px solid",
    borderColor: theme.palette.primary.dark,
  },
  activeItem: {
    fontSize: "1rem",
    fontWeight: "500",
    textTransform: "capitalize",
  },
  resultsText: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: "2px solid",
    borderColor: theme.palette.primary.dark,
    fontSize: "1rem",
    flexGrow: 1,
  },
  saveText: {
    fontSize: "1rem",
    display: "inline-block",
    marginLeft: "5px",
    cursor: "pointer",
  },
}));

export const options = [
  {
    label: "Recommended",
    value: "RECOMMENDED",
  },
  {
    label: "Cheapest",
    value: "CHEAPEST",
  },
  {
    label: "Latest",
    value: "LATEST",
  },
  {
    label: "Distance",
    value: "DISTANCE",
  },
];

export default function RoundTabs({ openSaveSearchDialog, ...props }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const searchFilters = useSelector(getSearchFilters);
  const searchTriggered = useSelector(state => state.listings.searchTriggered);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = option => {
    dispatch(
      setSearchFilters({
        ...searchFilters,
        ordering: option.value,
      })
    );
    handleClose();
  };

  return (
    <Grid container>
      <Grid item>
        <TabsSection {...props} />
      </Grid>
      <Grid item className={classes.boxGrid}>
        {props.numberoflistings !== undefined ? (
          <div className={classes.wrapper}>
            <Typography className={classes.resultsText} variant="subtitle2">
              {`${formatNumber(props.numberoflistings)} listings found`}
              {searchTriggered && (
                <>
                  ,{" "}
                  <Typography
                    className={classes.saveText}
                    variant="subtitle2"
                    color="primary"
                    onClick={openSaveSearchDialog}
                  >
                    Save Search
                  </Typography>
                </>
              )}
            </Typography>
            <div className={classes.sortingWrapper}>
              Sort by:
              <Button
                className={classes.activeItem}
                aria-controls="sorting-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                {options.find(op => op.value === searchFilters.ordering).label}
                {!!anchorEl ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </Button>
              <Menu
                id="sorting-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {options.map(option => (
                  <MenuItem
                    key={option.value}
                    onClick={() => handleMenuItemClick(option)}
                    selected={option.value === searchFilters.ordering}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </div>
        ) : (
          <Box className={classes.sideBox} />
        )}
      </Grid>
    </Grid>
  );
}
