import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Grid, Typography, Button, ButtonGroup } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  EventAvailable,
  People,
  Pets,
  LocalParking,
  LocalLaundryService,
  Smartphone,
} from "@material-ui/icons";
import {
  formatPrice,
  formatListingDate,
  formatPhoneNumber,
  getLabel,
  trimContent,
  getFullAddress,
  findEnumImage,
} from "../nestiqaUtils";
import { GLOBALS } from "../App";
import clsx from "clsx";
import QRCode from "qrcode.react";
import useListing from "../hooks/useListing";

import nestiqaLogo from "../components/nestiqa_logo.png";
import bedIcon from "../static/images/bed_icon.png";
import bathIcon from "../static/images/bath_icon.png";

const maxWidth = "3508px";
const maxHeight = "2480px";

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: maxWidth,
    maxHeight: maxHeight,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "21cm",
    alignContent: "center",
    margin: "0 auto",
  },
  grow: {
    flexGrow: 1,
  },
  address: {
    fontSize: "14px",
    fontWeight: 400,
    marginLeft: "-10px",
  },
  logo: {},
  price: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",

    "& .MuiButtonGroup-root": {
      "& .MuiButton-label": {
        fontSize: "14px",
        fontWeight: 500,
        textTransform: "none",
      },
    },

    "& span:last-child": {
      fontSize: "22px",
      fontWeight: 700,
      marginLeft: "15px",
    },
  },
  bedBathText: {
    marginBottom: "-10px",
  },
  icons: {
    width: "21px",
    height: "21px",
    marginLeft: "7px",
  },
  qrTitle: {
    fontSize: "14px",
    fontWeight: 400,
    margin: 0,
  },
  qrCode: {
    marginBottom: "5px",
  },
  wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
  },
  border: {
    borderTop: `1px solid ${theme.palette.secondary.main}`,
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
  },
  borderBottom: {
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
  },
  imageWrapper: {
    maxWidth: "462px",
    objectFit: "cover",
    objectPosition: "center center",
    marginTop: "5px",
    marginBottom: "5px",
  },
  mainImage: {
    width: "100%",
  },
  secondaryImageWrapper: {
    width: "225px",
    objectFit: "cover",
    objectPosition: "center center",
    marginTop: "5px",
    marginLeft: "5px",
  },
  secondaryImages: {
    width: "100%",
  },
  imageContainer: {
    display: "flex",
    flexWrap: "wrap",
    position: "relative",

    "&:not(:first-child)": {
      display: "flex",
      flexBasis: "50%",
      flexDirection: "row",
    },
  },
  subTitle: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    paddingTop: "5px",
    paddingBottom: "5px",
    alignItems: "center",

    "& .MuiTypography-root": {
      fontSize: "15px",
      fontWeight: 600,
      padding: "10px 0 5px 0",
    },
  },
  listingOwnerTitle: {
    width: "50%",
  },
  landlordInner: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    paddingRight: "10px",
    marginLeft: "-10px",
    width: "50%",

    "& div:first-child": {
      marginRight: "5px",
    },

    "& div:nth-child(2) div:first-child": {
      fontSize: "14px",
      fontWeight: 600,
    },

    "& div:nth-child(2) div:last-child": {
      fontSize: "14px",
      fontWeight: 400,
      color: "#808080",
      whiteSpace: "nowrap",
    },
  },
  listingDetailsList: {
    listStyleType: "none",
    float: "left",
    width: "100%",
    padding: 0,
    margin: 0,
    paddingTop: "15px",
    paddingBottom: "15px",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",

    "& svg": {
      marginRight: "2px",
      color: theme.palette.secondary.main,
    },

    "& li": {
      display: "flex",
      float: "left",
      flexWrap: "wrap",
      textAlign: "start",
      alignItems: "center",
      paddingBottom: "5px",
      paddingTop: "5px",
      overflow: "hidden",

      "& .MuiTypography-root.MuiTypography-subtitle1": {
        fontSize: "14px",
        fontWeight: 500,
      },

      "& .MuiTypography-root.MuiTypography-subtitle2": {
        fontSize: "14px",
        fontWeight: 400,
      },
    },

    "& li:nth-child(2n+2)": {
      width: "45%",
      justifyContent: "flex-start",
    },
  },
  description: {
    padding: "15px 0",
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    alignItems: "start",
  },
  amenitiesWrapper: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    flexFlow: "wrap",
    paddingBottom: "10px",

    "& svg": {
      marginRight: "5px",
      fontSize: "21px",
    },

    "& .MuiTypography-subtitle1": {
      fontSize: "15px",
      padding: "5px",
    },
  },
  amenitiesInner: {
    display: "flex",
    alignItems: "center",
    marginRight: "5px",

    "& svg": {
      color: theme.palette.secondary.main,
    },
  },
  unitFeaturesWrapper: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    flexFlow: "wrap",
    paddingBottom: "10px",

    "& .MuiTypography-root.Mui-Typography-h5": {
      textAlign: "flex-start",
      width: "100%",
    },

    "& svg": {
      marginRight: "5px",
      fontSize: "21px",
    },

    "& .MuiTypography-subtitle1": {
      fontSize: "15px",
      padding: "5px",
    },
  },
  landlordImg: {
    width: "45px",
    height: "45px",
    objectFit: "cover",
    borderRadius: "5px",
  },
  contactWrapper: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",

    "& .MuiTypography-root": {
      fontSize: "14px",
      fontWeight: 400,
      color: "#000",
    },
  },
  contactNumber: {
    display: "flex",
    alignItems: "center",
    color: "#808080",

    "& svg": {
      color: theme.palette.secondary.main,
    },
  },
  mapWrapper: {
    width: "100%",
  },
}));

const PrintPage = () => {
  const classes = useStyles();
  const { listingNumber } = useParams();
  const [listingParams] = useState({
    listingNumber: listingNumber,
    action: "get-listing",
  });
  const listingResult = useListing(listingParams);
  const [fullAddress, setFullAddress] = useState("");
  const [listing, setListing] = useState(null);
  const [images, setImages] = useState([]);
  const [errors, setErrors] = useState(false);
  const listingLink = `${GLOBALS.APP_BASE_URL}/listing/${listingNumber}`;
  let amenities = [],
    features = [],
    listingDetails = [];

  useEffect(() => {
    if (listingResult.status === "COMPLETE" && !listingResult.errors) {
      const listing = listingResult.listing;
      const address = getFullAddress(listing);
      setFullAddress(address);
      setListing(listing);

      let images = listing.images;
      if (images.length > 4) {
        const cutAfter = images.length - 5;
        images = images.slice(0, images.length - cutAfter);
      }
      setImages(images.sort((a, b) => a.number < b.number));
    } else if (listingResult.errors) {
      setErrors(true);
    }
  }, [listingResult]);

  useEffect(() => {
    if (errors) return;

    const timeout = setTimeout(printPage, 2500);

    return () => {
      clearTimeout(timeout);
    };
  }, [errors]);

  function getCurrentAmenities() {
    return listing ? listing.amenities.split(",") : [];
  }

  function getCurrentFeatures() {
    return listing ? listing.features.split(",") : [];
  }

  function hasAmenities() {
    return (
      listing && listing.amenities.length > 0 && listing.amenities[0] !== ""
    );
  }

  function hasFeatures() {
    return listing && listing.features.length > 0 && listing.features[0] !== "";
  }

  function printPage() {
    window.print();
  }

  if (listing) {
    const catAmenities = "AMENITIES";
    const catFeatures = "FEATURES";

    const currentAmenities = getCurrentAmenities();
    const currentFeatures = getCurrentFeatures();

    currentAmenities.map(amenity => {
      return amenities.push({
        name: getLabel(amenity, catAmenities),
        image: findEnumImage(amenity, catAmenities, currentAmenities),
      });
    });

    currentFeatures.map(feature => {
      return features.push({
        name: getLabel(feature, catFeatures),
        image: findEnumImage(feature, catFeatures, currentFeatures),
      });
    });

    listingDetails = [
      {
        name: "Available",
        image: <EventAvailable />,
        content: formatListingDate(listing.availabilityDate),
      },
      {
        name: "Type",
        image: <People />,
        content: getLabel(listing.leaseType, "LEASE_TYPES"),
      },
      {
        name: "Pets",
        image: <Pets />,
        content: getLabel(listing.pets, "PETS"),
      },
      {
        name: "Parking",
        image: <LocalParking />,
        content: getLabel(listing.parking, "PARKING"),
      },
      {
        name: "Laundry",
        image: <LocalLaundryService />,
        content: getLabel(listing.laundry, "LAUNDRY"),
      },
    ];
  }

  if (errors)
    return (
      <div>There was a problem loading the page, please try again later</div>
    );

  if (!listing) return <div>Please wait..</div>;
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid container item alignItems="center">
          <Grid item xs={3}>
            <div className={classes.logo}>
              <img
                src={nestiqaLogo}
                className={classes.logo}
                alt="Nestiqa Logo"
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <p className={classes.address}>{fullAddress}</p>
          </Grid>
          <Grid item xs={5}>
            <div className={classes.price}>
              <ButtonGroup
                size="small"
                aria-label="outlined primary button group"
              >
                {listing.sqft ? <Button>{listing.sqft} sqft.</Button> : null}
                <Button>
                  <span className={classes.bedBathText}>
                    {getLabel(listing.bedrooms, "BEDROOMS")}
                  </span>
                  <img className={classes.icons} src={bedIcon} alt="bedrooms" />
                </Button>
                <Button>
                  <span className={classes.bedBathText}>
                    {getLabel(listing.bathrooms, "BATHROOMS")}
                  </span>
                  <img
                    className={classes.icons}
                    src={bathIcon}
                    alt="bathrooms"
                  />
                </Button>
              </ButtonGroup>
              <span>{`${formatPrice(listing.rent)}/mo`}</span>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={7}>
          <div className={classes.imageContainer}>
            {images.map((image, index) => {
              if (image.number === 1) {
                return (
                  <div key={index} className={classes.imageWrapper}>
                    <img
                      className={classes.mainImage}
                      src={image.standard}
                      alt="Listing Preview"
                    />
                  </div>
                );
              } else {
                return (
                  <div key={index} className={classes.secondaryImageWrapper}>
                    <img
                      className={classes.secondaryImages}
                      src={image.standard}
                      alt="Listing Preview"
                    />
                  </div>
                );
              }
            })}
          </div>
          <div className={classes.mapWrapper}>
            <img
              src={`https://maps.googleapis.com/maps/api/staticmap?center=${listing.latitude},${listing.longitude}&zoom=16&size=462x250&markers=color:red%7clabel:S%7C${listing.latitude},${listing.longitude}&maptype=roadmap&key=${GLOBALS.GOOGLE_API_KEY}`}
              alt="Google Map location of listing"
            />
          </div>
        </Grid>
        <Grid item xs={5} style={{ paddingLeft: "15px", marginTop: "10px" }}>
          <div className={classes.wrapper}>
            <p className={classes.qrTitle}>
              For more info and to apply, scan the QR code
            </p>
            <QRCode
              className={classes.qrCode}
              id={`${listingNumber}-qr`}
              value={listingLink}
              includeMargin={true}
            />
          </div>
          <div className={clsx(classes.wrapper, classes.border)}>
            <ul className={classes.listingDetailsList}>
              {listing &&
                listingDetails.map((item, i) => {
                  return (
                    <li key={i}>
                      {item.image}
                      <Typography variant="subtitle1">{item.name}: </Typography>
                      <Typography variant="subtitle2">
                        &nbsp;{item.content}
                      </Typography>
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className={clsx(classes.wrapper, classes.description)}>
            <Typography variant="body1">
              {trimContent(listing.description, 320)}...
            </Typography>
          </div>
          {hasAmenities() && (
            <div className={clsx(classes.wrapper, classes.borderBottom)}>
              <div className={classes.subTitle}>
                <Typography variant="h5">Amenities</Typography>
              </div>
              <div className={classes.amenitiesWrapper}>
                {amenities.map((amenity, index) => (
                  <div key={index} className={classes.amenitiesInner}>
                    {amenity.image}
                    <Typography variant="subtitle1">{amenity.name}</Typography>
                  </div>
                ))}
              </div>
            </div>
          )}
          {hasFeatures() && (
            <div className={clsx(classes.wrapper, classes.borderBottom)}>
              <div className={classes.subTitle}>
                <Typography variant="h5" align="left">
                  Features
                </Typography>
              </div>
              <div className={classes.unitFeaturesWrapper}>
                {features.map((feature, index) => (
                  <div key={index} className={classes.amenitiesInner}>
                    {feature.image}
                    <Typography variant="subtitle1">{feature.name}</Typography>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className={clsx(classes.wrapper, classes.borderBottom)}>
            <div className={classes.subTitle}>
              <div className={classes.listingOwnerTitle}>
                <Typography variant="h5" align="left">
                  About the Listing Owner
                </Typography>
              </div>
              <div className={classes.landlordInner}>
                <div>
                  <img
                    className={classes.landlordImg}
                    src={listing.landlord.picture}
                    alt="Listing Owner Avatar"
                  />
                </div>
                <div>
                  <div>
                    {listing.landlord.firstName} {listing.landlord.lastName}
                  </div>
                  {listing.landlord.companyName && (
                    <div>{listing.landlord.companyName}</div>
                  )}
                  <div>
                    {getLabel(listing.landlord.landlordType, "LANDLORD_TYPES")}
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.unitFeaturesWrapper}>
              <Typography variant="body1">
                {trimContent(listing.landlord.bio, 40)}...
              </Typography>
            </div>
          </div>
          <div className={classes.wrapper}>
            <div className={classes.subTitle}>
              <Typography variant="h5" align="left">
                Contact
              </Typography>
            </div>
            <div className={classes.contactWrapper}>
              <Typography variant="subtitle1">{listing.contactName}</Typography>
              <Typography variant="subtitle1" className={classes.contactNumber}>
                <Smartphone />
                {formatPhoneNumber(listing.contactPhone)}
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default PrintPage;
