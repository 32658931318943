import React from "react";
import { Link } from "@material-ui/core";
import { GLOBALS } from "../../App";

const GoogleStaticMap = ({ latitude, longitude, mapWidth, mapHeight }) => {
  const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;

  return (
    <Link
      component="a"
      underline="none"
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src={`https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=16&size=${mapWidth}x${mapHeight}&markers=anchor:bottom%7Cicon:https://storage.googleapis.com/nestiqa-static/external/map-icon/map-icon.png%7C${latitude},${longitude}&maptype=roadmap&key=${GLOBALS.GOOGLE_API_KEY}`}
        alt="Google Map location of listing"
      />

    </Link>
  );
};

export default GoogleStaticMap;
