import { Button, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import React, { useState } from "react";
import { GLOBALS } from "../../App";

const useStyle = makeStyles(theme => ({
  marginTop: {
    marginTop: theme.spacing(2),
  },
}));

const ValidateInfo = ({ onSubmit }) => {
  const classes = useStyle();
  const [id, setId] = useState("");

  const handleSubmit = () => {
    onSubmit(id);
    setId("");
  };

  return (
    <div>
      <Typography gutterBottom>{GLOBALS.MESSAGES.VALIDATE_INFO_MSG}</Typography>
      <TextField
        onChange={e => setId(e.target.value)}
        value={id}
        variant="outlined"
        size="small"
      />
      <br />
      <Button
        className={classes.marginTop}
        variant="contained"
        disableElevation
        color="primary"
        onClick={handleSubmit}
      >
        Submit
      </Button>
    </div>
  );
};

export default ValidateInfo;
