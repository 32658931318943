import { SET_USER, CLEAR_USER } from "../actions/types";

const initialState = null;

export default function tenantReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_USER:
      return { ...state, ...payload };
    case CLEAR_USER:
      return null;
    default:
      return state;
  }
}
