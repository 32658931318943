import React from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  formatPrice,
  getLabel,
  getOpenApplicationStatus,
} from "../../nestiqaUtils";
import Tooltip from "../tooltip/Tooltip";
import theme from "../../theme";
import noImage from "../noimg640.png";
import bedIcon from "../../static/images/bed_icon.png";
import bathIcon from "../../static/images/bath_icon.png";
import clsx from "clsx";
import { GLOBALS } from "../../App";

const useStyles = makeStyles(() => ({
  overviewRoot: {
    flexGrow: 1,
    boxShadow: "0 0 5px rgba(0,0,0,0.2)",
    margin: "0 auto 0",
    padding: "24px 0 15px 0",
    maxWidth: "920px",
    borderBottom: "1px solid #3CE0E5",
    "& h5": {
      marginBottom: "15px",
    },

    [theme.breakpoints.down("xs")]: {
      padding: "20px 0 0 0",
    },
  },
  rootGrid: {
    justifyContent: "space-between",

    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  addressText: {
    fontSize: "20px",
    lineHeight: "1.5",

    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  cityText: {
    fontSize: "16px",
    lineHeight: "1.2",

    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  bedBathIcon: {
    justifyContent: "center",
  },
  rentContainer: {
    padding: "15px 0 15px 15px",
    paddingRight: "15px",
    marginRight: "15px",
  },
  rentText: {
    textAlign: "right",
  },
  mobileRentText: {
    textAlign: "center",
  },
  listingImageContainer: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  listingImage: {
    width: "110px",
    height: "83px",
    objectFit: "cover",
    marginLeft: "15px",

    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },
  addressContainer: {
    flexGrow: 1,

    [theme.breakpoints.down("md")]: {
      marginLeft: "-10px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "20px",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },
  appStatus: {
    display: "flex",
    justifyContent: "flex-start",
    margin: "0 auto 0",
    padding: "24px 0 15px 0",
    maxWidth: "920px",

    "& .MuiTypography-root.MuiTypography-body2": {
      fontWeight: 500,
      marginRight: theme.spacing(1),
      whiteSpace: "nowrap",
    },

    "& div": {
      "&:first-child": {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        marginRight: theme.spacing(3),
      },

      "&:nth-child(2)": {
        "& p:first-child": {
          display: "inline",

          [theme.breakpoints.down("sm")]: {
            display: "block",
          },
        },
        "& p:nth-of-type(2)": {
          display: "inline",
          wordBreak: "break-word",
        },

        [theme.breakpoints.down("xs")]: {
          textAlign: "center",

          "& p:first-child": {
            marginTop: theme.spacing(2),
          },

          "& p:last-child": {
            marginBottom: theme.spacing(2),
          },
        },
      },
    },

    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
  },
  statusWrapper: {
    marginRight: theme.spacing(1),

    "& span": {
      backgroundColor: "#FBF7EC",
      color: "#D89A3D",
      borderRadius: "5px",
      padding: "2px 8px",
    },
  },
  hidden: {
    display: "none",
  },
  centered: {
    justifyContent: "center !important",
  },
}));

const ApplicationOverview = ({ application = {} }) => {
  const classes = useStyles();
  const { listing, tenant } = application;
  const isTablet = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));

  if (!listing || !tenant) {
    return (
      <Container>
        <Typography variant="h5" align="center" color="primary">
          Loading...
        </Typography>
      </Container>
    );
  }

  return (
    <>
      <div
        className={clsx(
          classes.appStatus,
          application.comment ? "" : classes.centered
        )}
      >
        <div>
          <Typography variant="body2">Status: </Typography>
          <div className={classes.statusWrapper}>
            <span>
              {getOpenApplicationStatus(
                application.status,
                application.tenantName
              )}
            </span>
          </div>
          {!isTablet ? (
            <Tooltip
              application={application}
              clickable={true}
              iconSize="1.2rem"
            />
          ) : (
            <Tooltip application={application} clickable={false} />
          )}
        </div>
        <div className={application.comment ? "" : classes.hidden}>
          <Typography variant="body2">Landlord Comment: </Typography>
          <Typography variant="body1">{application.comment}</Typography>
        </div>
      </div>
      <div className={classes.overviewRoot}>
        <Grid container className={classes.rootGrid}>
          <Grid
            item
            xs={12}
            sm={2}
            lg="auto"
            className={classes.listingImageContainer}
          >
            <img
              className={classes.listingImage}
              src={listing.mainImage || GLOBALS.REACT_BASE_URL + noImage}
              alt="Listing Primary"
            />
          </Grid>
          <Grid item lg={8} className={classes.addressContainer}>
            <Typography
              className={classes.addressText}
              variant="body1"
              align="left"
            >
              {listing.address1}
              {listing.address2 !== "" ? `, ${listing.address2}` : null}
            </Typography>
            <Typography
              className={classes.cityText}
              variant="subtitle1"
              align="left"
            >
              {listing.city}, {listing.state} {listing.zipCode}
            </Typography>
            <Box
              alignItems="center"
              display="flex"
              mt={1}
              mb={1}
              className={isMobile ? classes.bedBathIcon : ""}
            >
              {listing.bedrooms && (
                <Box display="flex" alignItems="flex-end" mr={3}>
                  <Box>
                    <img
                      src={bedIcon}
                      width="23px"
                      height="24px"
                      alt="Bed Icon"
                    />
                  </Box>
                  <Box ml={1}>
                    <Typography variant="subtitle1" align="left">
                      {getLabel(listing.bedrooms, "BEDROOMS", true)}
                    </Typography>
                  </Box>
                </Box>
              )}
              {listing.bathrooms && (
                <Box display="flex" alignItems="flex-end">
                  <Box>
                    <img
                      src={bathIcon}
                      width="23px"
                      height="26px"
                      alt="Bath Icon"
                    />
                  </Box>
                  <Box ml={1}>
                    <Typography variant="subtitle1" align="left">
                      {getLabel(listing.bathrooms, "BATHROOMS", true)}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm="auto"
            className={classes.rentContainer}
            align="center"
          >
            {listing.rent && (
              <div>
                <Typography variant="subtitle1">Monthly Rent</Typography>
                <Typography
                  variant="h6"
                  className={
                    isMobile ? classes.mobileRentText : classes.rentText
                  }
                >
                  <strong>{formatPrice(listing.rent)}</strong>
                </Typography>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ApplicationOverview;
