import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { GLOBALS } from "../../App";

const useStyles = makeStyles(theme => ({
  serviceAgreementBox: {
    border: "1px solid #000",
    whiteSpace: "break-spaces",
    padding: theme.spacing(1),
    height: "300px",
    overflowY: "scroll",
  },
  dialogAction: {
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
}));

export default function LandlordExtAcceptTerms({ onAcceptTerms }) {
  const classes = useStyles();

  const [open, setOpen] = useState(true);

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Accept Terms</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {GLOBALS.MESSAGES.ACCEPT_TERMS_DIALOG_TEXT}
        </DialogContentText>
        <pre className={classes.serviceAgreementBox}>
          {GLOBALS.TERMS_AND_CONDITIONS.ATTACHMENT_B}
        </pre>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setOpen(false);
            onAcceptTerms();
          }}
        >
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
}
