import React, { useState } from "react";
import clsx from "clsx";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import PhoneNumberField from "../PhoneNumberField";
import InputAdornment from "@material-ui/core/InputAdornment";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { makeStyles } from "@material-ui/core/styles";
import { GLOBALS } from "../../App";
import ChoiceField from "./../ChoiceField";
import * as yup from "yup";

import { updateLandlord, setLandlordPicture } from "../../utils/LandlordApi";
import LandlordFormControls from "../landlord/landlordFormControls";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  datePicker: {},
  inputField: {
    width: "100%",

    "& .MuiInputLabel-root": {
      color: theme.palette.grey[400],
    },

    "& .MuiInputLabel-shrink": {
      color: theme.palette.primary.main,
    },
  },
  stepHeading: {
    gridArea: "stepHeading",
    color: theme.palette.primary.main,
    paddingBottom: theme.spacing(4),
  },
  stepSubText: {
    gridArea: "stepSubText",

    paddingBottom: theme.spacing(4),
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateAreas: `
      "stepHeading"
      "stepSubText"
      "firstName"
      "lastName"
      "landlordType"
      "email"
      "phone"
      `,
    columnGap: theme.spacing(2),
    maxWidth: "450px",
    margin: "0 auto",
  },
  firstName: {
    gridArea: "firstName",
  },
  lastName: {
    gridArea: "lastName",
  },

  landlordType: {
    gridArea: "landlordType",
    "& .MuiSelect-icon": {
      right: "40px",
    },
  },
  email: {
    gridArea: "email",
  },
  phone: {
    gridArea: "phone",
  },

  [theme.breakpoints.down("md")]: {
    grid: {
      position: "relative",
    },
    root: {
      marginTop: "0px",
    },
  },
  [theme.breakpoints.down("sm")]: {
    stepHeading: {
      fontSize: "1.8rem",
    },
  },
  [theme.breakpoints.down("xs")]: {
    grid: {
      position: "relative",
    },
  },
  [theme.breakpoints.up("md")]: {
    stepHeading: {
      textTransform: "uppercase",
      position: "relative",

      "&::after": {
        content: "''",
        width: "52px",
        height: "4px",
        position: "absolute",
        left: "50%",
        marginLeft: "-26px",
        bottom: "20px",
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
}));

export default function LandlordScreenInfo(props) {
  const {
    step,
    setStep,
    accessToken,
    landlord,
    setLandlord,
    showError,
    closeDialog,
  } = props;

  const classes = useStyles(props);

  function onSubmit(values, formikBag) {
    const updatedValues = {
      id: values.id,
      firstName: values.firstName,
      lastName: values.lastName,
      landlordType: values.landlordType,
      email: values.email,
      phoneNumber: values.phoneNumber,
    };
    updateLandlord(
      updatedValues,
      accessToken,
      (isSuccess, apiResult, apiError) => {
        if (isSuccess) {
          setLandlord(landlord => ({ ...landlord, ...apiResult }));
          setStep(step + 1);
        } else {
          console.error(apiError);
          showError(GLOBALS.MESSAGES.ERROR_UPDATING_LANDLORD);
          formikBag.setSubmitting(false);
        }
      }
    );
  }

  function handleSave(formikprops) {
    if (formikprops.dirty) {
      formikprops.submitForm();
    } else {
      setStep(step + 1);
    }
  }

  const validationSchema = yup.object().shape({
    firstName: yup
      .string()
      .min(2)
      .max(50)
      .matches(GLOBALS.LEGAL_NAME_REGEX, GLOBALS.MESSAGES.ONLY_ALPHA)
      .required(GLOBALS.MESSAGES.FIELD_REQUIRED)
      .label("First Name"),
    lastName: yup
      .string()
      .min(2)
      .max(50)
      .matches(GLOBALS.LEGAL_NAME_REGEX, GLOBALS.MESSAGES.ONLY_ALPHA)
      .required(GLOBALS.MESSAGES.FIELD_REQUIRED)
      .label("Last Name"),

    landlordType: yup.string().required(GLOBALS.MESSAGES.FIELD_REQUIRED),
    email: yup
      .string()
      .email(GLOBALS.MESSAGES.ENTER_VALID_EMAIL)
      .required(GLOBALS.MESSAGES.FIELD_REQUIRED),
    phoneNumber: yup
      .string()
      .matches(GLOBALS.PHONE_NUMBER_REGEX, GLOBALS.MESSAGES.ENTER_VALID_PHONE)
      .required(),
  });

  const [profilePicture, setProfilePicture] = useState(null);

  function handleImageUpload(e) {
    const [file] = e.target.files;

    setLandlordPicture(
      file,
      landlord.id,
      accessToken,
      (isSuccess, apiResult, apiError) => {
        if (isSuccess) {
          setProfilePicture(apiResult.picture);
          setLandlord(landlord => ({ ...landlord, ...apiResult }));
        }
      }
    );
  }

  return (
    <LandlordFormControls
      step={step}
      setStep={setStep}
      landlord={landlord}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      handleSave={handleSave}
      closeDialog={closeDialog}
      className={classes.root}
    >
      <LandlordInfoFormikWrapper
        handleImageUpload={handleImageUpload}
        profilePicture={profilePicture}
        landlord={landlord}
      />
    </LandlordFormControls>
  );
}

const LandlordInfoFormikWrapper = ({ formikprops }) => {
  const classes = useStyles();

  return (
    <div className={classes.grid}>
      <Typography className={classes.stepHeading} variant="h4" align="center">
        Landlord Info
      </Typography>
      <Typography className={classes.stepSubText} variant="body1">
        {GLOBALS.MESSAGES.LANDLORD_SCREEN_INFO_TEXT}
      </Typography>

      {/* First Name */}

      <Field
        className={clsx(classes.inputField, classes.firstName)}
        component={TextField}
        required
        name="firstName"
        type="text"
        label="First Name"
        variant="outlined"
        margin="normal" //Check if margins are needed or covered by classes
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <PersonOutlineIcon color="secondary" />
            </InputAdornment>
          ),
        }}
      />

      {/* Last Name */}

      <Field
        className={clsx(classes.inputField, classes.lastName)}
        component={TextField}
        required
        name="lastName"
        type="text"
        label="Last Name"
        variant="outlined"
        margin="normal"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <PersonOutlineIcon color="secondary" />
            </InputAdornment>
          ),
        }}
      />

      {/* Landlord Type */}

      <Field
        className={clsx(classes.inputField, classes.landlordType)}
        component={ChoiceField}
        required
        name="landlordType"
        variant="outlined"
        label="Landlord Type"
        margin="normal"
        choices={GLOBALS.LANDLORD_TYPES}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <PersonOutlineIcon color="secondary" />
            </InputAdornment>
          ),
        }}
      />

      {/* Email */}

      <Field
        className={clsx(classes.inputField, classes.email)}
        component={TextField}
        required
        name="email"
        type="email"
        label="E-mail"
        variant="outlined"
        margin="normal"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <MailOutlineIcon color="secondary" />
            </InputAdornment>
          ),
        }}
      />

      {/* Phone */}

      <Field
        className={clsx(classes.inputField, classes.phone)}
        component={PhoneNumberField}
        required
        name="phoneNumber"
        type="text"
        label="Phone Number"
        variant="outlined"
        margin="normal"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <PhoneIphoneIcon color="secondary" />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};
