import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  loginButton: {
    cursor: "pointer",
  },
}));

const RequireLogin = props => {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="body1" align="center">
        You have to be signed in to access this page
      </Typography>
      <Typography
        variant="body1"
        align="center"
        color="primary"
        className={classes.loginButton}
        onClick={() => props.login()}
      >
        Sign in
      </Typography>
    </div>
  );
};

export default RequireLogin;
