import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

export default function NestiqaAlertDialog(props) {
  return (
    <Dialog
      open={props.open || false}
      onClose={props.onClose}
      aria-labelledby="nestiqa-alert-dialog"
      aria-describedby="nesitqa-alert-dialog-description"
    >
      <DialogTitle id="nestiqa-alert-dialog">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText
          id="nesitqa-alert-dialog-description"
          variant="subtitle2"
        >
          {props.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
