import React, { useState, useEffect } from "react";
import { useLocation, Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Icon,
  Link,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { Twitter, Facebook } from "./icons/social";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { GLOBALS } from "../App";
import nestiqaLogo from "./nestiqa_logo.png";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(0),
    width: "100%",
    position: "absolute",
    bottom: 0,
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    borderTop: "1px solid #22213D",
    marginBottom: "20px",

    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  logoWrapper: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    marginRight: "200px",
    minWidth: "170px",
    whiteSpace: "nowrap",

    [theme.breakpoints.down("sm")]: {
      marginRight: "66px",
    },

    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
      minWidth: "unset",
      maxWidth: "145px",
      marginTop: "15px",

      "& a img": {
        width: "140px",
      },

      "& .MuiTypography-root.MuiTypography-subtitle2": {
        whiteSpace: "nowrap",
      },
    },
  },
  socials: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "15px 0",
  },
  logo: {
    marginTop: "30px",

    [theme.breakpoints.down("xs")]: {
      width: "90px",
    },
  },
  items: {
    margin: theme.spacing(1),
    fontWeight: 400,
  },
  linkWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginTop: "30px",

    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
    },
  },
  left: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",

    [theme.breakpoints.down("xs")]: {
      flexBasis: "50%",
    },
  },
  middle: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
  },

  link: {
    textDecoration: "none",
    color: "#000",
    fontSize: "16px",
    fontWeight: 400,
  },
  landlordView: {
    [theme.breakpoints.down("xs")]: {
      marginTop: "30px",
    },
  },
}));

export default function NestiqaBottomBar() {
  const classes = useStyles();
  const location = useLocation();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));
  const [isLandlordRoute, setIsLandlordRoute] = useState(false);

  useEffect(() => {
    if (location.pathname.includes(GLOBALS.PATHS.LANDLORD)) {
      setIsLandlordRoute(true);
    } else {
      setIsLandlordRoute(false);
    }
  }, [location.pathname]);

  const userView = location =>
    location.pathname.includes("/landlord") ? (
      <Link
        to={GLOBALS.PATHS.ROOT}
        component={RouterLink}
        className={classes.link}
        underline="none"
      >
        Tenant View
      </Link>
    ) : (
      <Link
        to={GLOBALS.PATHS.LANDLORD}
        component={RouterLink}
        className={classes.link}
        underline="none"
      >
        Landlord View
      </Link>
    );

  return (
    <div className={classes.root}>
      <Container>
        <div className={classes.container}>
          {!isMobile && (
            <div className={classes.logoWrapper}>
              <Link
                to={
                  isLandlordRoute ? GLOBALS.PATHS.LANDLORD : GLOBALS.PATHS.ROOT
                }
                component={RouterLink}
                underline="none"
              >
                <img
                  src={nestiqaLogo}
                  className={classes.logo}
                  alt="Nestiqa Logo"
                />
              </Link>
              <div className={classes.socials}>
                <a
                  href={GLOBALS.SOCIALS.TWITTER}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon color="secondary">
                    <Twitter />
                  </Icon>
                </a>
                <a
                  href={GLOBALS.SOCIALS.FACEBOOK}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon color="secondary">
                    <Facebook />
                  </Icon>
                </a>
                <a
                  href={GLOBALS.SOCIALS.LINKEDIN}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon color="secondary">
                    <LinkedInIcon fontSize="large" />
                  </Icon>
                </a>
                <a
                  href={GLOBALS.SOCIALS.INSTAGRAM}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon color="secondary">
                    <InstagramIcon fontSize="large" />
                  </Icon>
                </a>
              </div>
              <Typography variant="subtitle2" className={classes.copyright}>
                Copyright © 2021 Nestiqa, Inc.
              </Typography>
            </div>
          )}
          <div className={classes.linkWrapper}>
            <div className={classes.left}>
              <Typography variant="subtitle2" className={classes.items}>
                <Link
                  to="/privacy-policy"
                  component={RouterLink}
                  className={classes.link}
                  underline="none"
                >
                  Privacy Policy
                </Link>
              </Typography>
              <Typography variant="subtitle2" className={classes.items}>
                <Link
                  to="/terms-of-use"
                  component={RouterLink}
                  className={classes.link}
                  underline="none"
                >
                  Terms of Use
                </Link>
              </Typography>
              <Typography variant="subtitle2" className={classes.items}>
                <Link
                  to="/contact-us"
                  component={RouterLink}
                  className={classes.link}
                  underline="none"
                >
                  Contact Us
                </Link>
              </Typography>
            </div>
            <div className={classes.middle}>
              <Typography variant="subtitle2" className={classes.items}>
                <a
                  href={GLOBALS.SOCIALS.FAQ}
                  className={classes.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  FAQ
                </a>
              </Typography>
              <Typography variant="subtitle2" className={classes.items}>
                <a
                  href={GLOBALS.SOCIALS.BLOG}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.link}
                >
                  Blog
                </a>
              </Typography>
              <Typography variant="subtitle2" className={classes.items}>
                <Link
                  to={GLOBALS.PATHS.PARTNERSHIPS}
                  component={RouterLink}
                  className={classes.link}
                  underline="none"
                >
                  Partnerships
                </Link>
              </Typography>
            </div>
            <Typography
              variant="subtitle2"
              className={clsx(classes.items, classes.landlordView)}
            >
              {userView(location)}
            </Typography>
          </div>
          {isMobile && (
            <div className={classes.logoWrapper}>
              <Link
                to={
                  isLandlordRoute ? GLOBALS.PATHS.LANDLORD : GLOBALS.PATHS.ROOT
                }
                component={RouterLink}
                underline="none"
              >
                <img
                  src={nestiqaLogo}
                  className={classes.logo}
                  alt="Nestiqa Logo"
                />
              </Link>
              <div className={classes.socials}>
                <a
                  href={GLOBALS.SOCIALS.TWITTER}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon color="secondary">
                    <Twitter />
                  </Icon>
                </a>
                <a
                  href={GLOBALS.SOCIALS.FACEBOOK}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon color="secondary">
                    <Facebook />
                  </Icon>
                </a>
                <a
                  href={GLOBALS.SOCIALS.LINKEDIN}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon color="secondary">
                    <LinkedInIcon fontSize="large" />
                  </Icon>
                </a>
                <a
                  href={GLOBALS.SOCIALS.INSTAGRAM}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon color="secondary">
                    <InstagramIcon fontSize="large" />
                  </Icon>
                </a>
              </div>
              <Typography variant="subtitle2">
                Copyright © 2021 Nestiqa, Inc.
              </Typography>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
}
