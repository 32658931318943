import React, { useState } from "react";
import Container from "@material-ui/core/Container";
import { GLOBALS } from "../App";
import { Helmet } from "react-helmet";
import { Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ContactDialog from "../components/live-agent/ContactDialog";
import CallDialog from "../components/live-agent/CallDialog";
import InlineChatButton from "../components/live-agent/InlineChatButton";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: "24px",
    marginBottom: theme.spacing(2),
  },
  button: {
    width: "150px",
    height: "30px",
    borderRadius: "20px",
    background: theme.palette.primary.main,
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(1),
    textTransform: "none",
  },
  buttonText: {
    color: "white",
    fontWeight: "700",
  },
}));

export default function ContactUsPage() {
  const classes = useStyles();

  const [contactDialog, setContactDialog] = useState({
    open: false,
  });

  const [callDialog, setCallDialog] = useState({
    open: false,
  });

  function closeContactDialog() {
    setContactDialog({ open: false });
  }

  function closeCallDialog() {
    setCallDialog({ open: false });
  }

  return (
    <Container maxWidth="lg">
      <Helmet>
        <title>{GLOBALS.META_TAGS.TITLE.CONTACT_US_PAGE}</title>
        <meta
          name="description"
          content={GLOBALS.META_TAGS.DESCRIPTION.CONTACT_US_PAGE}
        />
      </Helmet>
      <Typography
        variant="h6"
        color="primary"
        align="center"
        className={classes.title}
      >
        Contact Us
      </Typography>
      <Grid container direction="column">
        <Grid item>
          <Button
            className={classes.button}
            startIcon={<EmailIcon className={classes.buttonText} />}
            onClick={() =>
              setContactDialog({ open: true, onClose: closeContactDialog })
            }
          >
            <Typography className={classes.buttonText}>Message us</Typography>
          </Button>
        </Grid>
        <Grid item>
          <Button
            className={classes.button}
            startIcon={<PhoneIcon className={classes.buttonText} />}
            onClick={() =>
              setCallDialog({ open: true, onClose: closeCallDialog })
            }
          >
            <Typography className={classes.buttonText}>Call us</Typography>
          </Button>
        </Grid>
        <Grid item>
          <InlineChatButton />
        </Grid>
      </Grid>
      <ContactDialog {...contactDialog} />
      <CallDialog {...callDialog} />
    </Container>
  );
}
