import { GLOBALS } from "../App";
import {
  runFetch,
  camelToSnake,
  snakeToCamel,
  formatDate,
} from "../nestiqaUtils";
import { prepareListingFields } from "../hooks/ListingApi";

export const getOrCreate = async (accessToken, callback) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.TENANT_GET_OR_CREATE}`,
    "post",
    { "Content-Type": "application/json" },
    null,
    accessToken,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, prepareTenantResult(apiResult), apiError)
  );
};

export const getTenant = async (tenantId, accessToken, callback) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.TENANTS_API}${tenantId}/`,
    "get",
    { "Content-Type": "application/json" },
    null,
    accessToken,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, prepareTenantResult(apiResult), apiError)
  );
};

export const getTenantApplications = async (
  tenantId,
  accessToken,
  callback
) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.TENANTS_API}${tenantId}/applications/`,
    "get",
    { "Content-Type": "application/json" },
    null,
    accessToken,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, prepareTenantResult(apiResult), apiError)
  );
};

export const getHouseholdApplications = async (
  tenantId,
  accessToken,
  callback
) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}/tenants/${tenantId}/applications/household/`,
    "get",
    { "Content-Type": "application/json" },
    null,
    accessToken,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, prepareAppResult(apiResult), apiError)
  );
};

export const getOpenApplicationsCount = async (
  tenantId,
  accessToken,
  callback
) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}/tenants/${tenantId}/applications/count/`,
    "get",
    { "Content-Type": "application/json" },
    null,
    accessToken,
    callback
  );
};

export const getOpenApplicationsStatus = async (
  tenantId,
  accessToken,
  callback
) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}/tenants/${tenantId}/applications/status/`,
    "get",
    { "Content-Type": "application/json" },
    null,
    accessToken,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, snakeToCamel(apiResult), apiError)
  );
};

export const updateTenant = async (tenant, accessToken, callback) => {
  const preparedTenant = prepareTenantPayload(tenant);
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.TENANTS_API}${preparedTenant.id}/`,
    "put",
    { "Content-Type": "application/json" },
    JSON.stringify(preparedTenant),
    accessToken,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, prepareTenantResult(apiResult), apiError)
  );
};

export const setTenantPicture = async (
  pictureFile,
  tenantId,
  accessToken,
  setResultsCallback
) => {
  const formData = new FormData();
  formData.set("picture", pictureFile);
  await runFetch(
    `${GLOBALS.API_BASE_URL}/tenants/${tenantId}/picture/`,
    "put",
    {},
    formData,
    accessToken,
    setResultsCallback
  );
};

export const getPaymentIntent = async (accessToken, callback) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.TENANT_UPGRADE_PLAN}`,
    "post",
    { "Content-Type": "application/json" },
    null,
    accessToken,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, camelToSnake(apiResult), apiError)
  );
};

export const applyCoupon = async (couponCode, accessToken, callback) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.TENANT_APPLY_COUPON}`,
    "post",
    { "Content-Type": "application/json" },
    JSON.stringify({"coupon_code": couponCode}),
    accessToken,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, camelToSnake(apiResult), apiError)
  );
};

export const cancelPlan = async (accessToken, callback) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.TENANT_DOWNGRADE_PLAN}`,
    "post",
    { "Content-Type": "application/json" },
    null,
    accessToken,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, camelToSnake(apiResult), apiError)
  );
};

export const updateHousingHistory = async (tenant, accessToken, callback) => {
  const preparedTenant = prepareTenantPayload({
    housingHistory: tenant.housingHistory,
  });
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.HOUSING_HISTORY_API}${tenant.id}/`,
    "put",
    { "Content-Type": "application/json" },
    JSON.stringify(preparedTenant),
    accessToken,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, prepareTenantResult(apiResult), apiError)
  );
};

export const updateEmploymentHistory = async (
  tenant,
  accessToken,
  callback
) => {
  const preparedTenant = prepareTenantPayload({
    employmentHistory: tenant.employmentHistory,
  });
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.EMPLOYMENT_HISTORY_API}${tenant.id}/`,
    "put",
    { "Content-Type": "application/json" },
    JSON.stringify(preparedTenant),
    accessToken,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, prepareTenantResult(apiResult), apiError)
  );
};

export const createTenant = async (accessToken, callback) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.TENANTS_API}`,
    "post",
    { "Content-Type": "application/json" },
    null,
    accessToken,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, snakeToCamel(apiResult), apiError)
  );
};

export const purchaseInquiry = async (accessToken, callback) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.PURCHASE_INQUIRY_API}`,
    "post",
    { "Content-Type": "application/json" },
    null,
    accessToken,
    callback
  );
};

export function prepareTenantPayload(tenant) {
  const result = { ...tenant };
  if (result.dob) result.dob = formatDate(result.dob);
  if (result.housingHistory) {
    for (let item in result.housingHistory) {
      if (result.housingHistory[item].startDate) {
        result.housingHistory[item].startDate = formatDate(
          result.housingHistory[item].startDate
        );
      }
      if (result.housingHistory[item].endDate) {
        result.housingHistory[item].endDate = formatDate(
          result.housingHistory[item].endDate
        );
      } else {
        result.housingHistory[item].endDate = null;
      }
    }
  }
  if (result.employmentHistory) {
    for (let item in result.employmentHistory) {
      if (result.employmentHistory[item].startDate) {
        result.employmentHistory[item].startDate = formatDate(
          result.employmentHistory[item].startDate
        );
      }
      if (result.employmentHistory[item].endDate) {
        result.employmentHistory[item].endDate = formatDate(
          result.employmentHistory[item].endDate
        );
      } else {
        result.employmentHistory[item].endDate = null;
      }
    }
  }

  return camelToSnake(result);
}

export function prepareTenantResult(tenant) {
  if (!tenant) return tenant;

  const result = snakeToCamel(tenant);
  if (result.phoneNumber) result.phoneNumber = result.phoneNumber.substring(2);
  if (result.dob) result.dob = new Date(`${result.dob}T00:00:00`);
  if (result.household) {
    for (let o in result.household) {
      result.household[o].dob = new Date(`${result.household[o].dob}T00:00:00`);
    }
    result.household.sort((a, b) => a.dob - b.dob);
  }
  if (result.housingHistory) {
    prepareHhResultFields(result.housingHistory);
  }
  if (result.employmentHistory) {
    prepareEhResultFields(result.employmentHistory);
  }
  if (result.applications) {
    prepareAppResultFields(result.applications);
  }
  return result;
}

function prepareHhResultFields(hh) {
  for (let item in hh) {
    if (hh[item].landlordPhoneNumber) {
      hh[item].landlordPhoneNumber = hh[item].landlordPhoneNumber.substring(2);
    }
    if (hh[item].startDate) {
      hh[item].startDate = new Date(`${hh[item].startDate}T00:00:00`);
    }
    if (hh[item].endDate) {
      hh[item].endDate = new Date(`${hh[item].endDate}T00:00:00`);
    }
  }
  hh.sort((a, b) => a.startDate - b.startDate);
}

function prepareEhResultFields(eh) {
  for (let item in eh) {
    if (eh[item].contactPhoneNumber) {
      eh[item].contactPhoneNumber = eh[item].contactPhoneNumber.substring(2);
    }
    if (eh[item].startDate) {
      eh[item].startDate = new Date(`${eh[item].startDate}T00:00:00`);
    }
    if (eh[item].endDate) {
      eh[item].endDate = new Date(`${eh[item].endDate}T00:00:00`);
    }
  }
  eh.sort((a, b) => a.startDate - b.startDate);
}

function prepareAppResultFields(apps) {
  for (let item in apps) {
    if (apps[item].moveInDate) {
      apps[item].moveInDate = new Date(`${apps[item].moveInDate}T00:00:00`);
    }
    prepareListingFields(apps[item].listing);
  }
  apps.sort((a, b) => a.submitted - b.submitted);
}

function prepareAppResult(apps) {
  let result = [];
  apps.sort((a, b) => b.submitted - a.submitted);
  for (let item in apps) {
    let res = snakeToCamel(apps[item]);
    if (res.moveInDate) {
      res.moveInDate = new Date(`${res.moveInDate}T00:00:00`);
    }
    prepareListingFields(res.listing);
    result.push(res);
  }
  return result;
}
