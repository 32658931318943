import { GLOBALS } from "../App";
import {
  runFetch,
  snakeToCamel,
  camelToSnake,
  formatDate,
} from "../nestiqaUtils";

export const getListing = async (listingNumber, accessToken, callback) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.LISTINGS_API}${listingNumber}/`,
    "get",
    { "Content-Type": "application/json" },
    null,
    accessToken,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, prepareListingResult(apiResult), apiError)
  );
};

export const landlordGetListing = async (
  listingNumber,
  accessToken,
  callback
) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.LANDLORD_LISTINGS_API}${listingNumber}/`,
    "get",
    { "Content-Type": "application/json" },
    null,
    accessToken,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, prepareListingResult(apiResult), apiError)
  );
};

export const updateListing = async (
  listingNumber,
  listing,
  accessToken,
  callback
) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.LANDLORD_LISTINGS_API}${listingNumber}/`,
    "put",
    { "Content-Type": "application/json" },
    JSON.stringify(preparePayload(listing)),
    accessToken,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, prepareListingResult(apiResult), apiError)
  );
};

export const activateListing = async (
  listingNumber,
  accessToken,
  setResultsCallback
) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.LANDLORD_LISTINGS_API}${listingNumber}/activate/`,
    "put",
    { "Content-Type": "application/json" },
    null,
    accessToken,
    setResultsCallback
  );
};

export const deactivateListing = async (
  listingNumber,
  accessToken,
  setResultsCallback
) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.LANDLORD_LISTINGS_API}${listingNumber}/deactivate/`,
    "put",
    { "Content-Type": "application/json" },
    null,
    accessToken,
    setResultsCallback
  );
};

export const inviteByEmail = async (
  listingNumber,
  accessToken,
  email,
  name,
  callback
) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.MY_LISTINGS_API}${listingNumber}/invite/email/`,
    "post",
    { "Content-Type": "application/json" },
    JSON.stringify({ name: name, email: email }),
    accessToken,
    callback,
    async res => await res
  );
};

export const inviteBySms = async (
  listingNumber,
  accessToken,
  phone,
  name,
  callback
) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.MY_LISTINGS_API}${listingNumber}/invite/sms/`,
    "post",
    { "Content-Type": "application/json" },
    JSON.stringify({ name: name, phone: phone }),
    accessToken,
    callback,
    res => res
  );
};

export const createListing = async (listing, accessToken, callback) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.LANDLORD_LISTINGS_API}`,
    "post",
    { "Content-Type": "application/json" },
    JSON.stringify(preparePayload(listing)),
    accessToken,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, prepareListingResult(apiResult), apiError)
  );
};

export const createProperty = async (listing, accessToken, callback) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.LANDLORD_PROPERTIES_API}`,
    "post",
    { "Content-Type": "application/json" },
    JSON.stringify(preparePayload(listing)),
    accessToken,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, prepareListingResult(apiResult), apiError)
  );
};

export const createListingImage = async (
  imageFile,
  imageIndex,
  listingId,
  accessToken,
  setResultsCallback
) => {
  const formData = new FormData();
  formData.append("original", imageFile);
  formData.append("listing", listingId);
  formData.append("number", imageIndex);
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.LISTING_IMAGES}`,
    "post",
    {},
    formData,
    accessToken,
    setResultsCallback
  );
};

export const updateListingImage = async (
  listingImageId,
  imageIndex,
  accessToken,
  setResultsCallback
) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.LISTING_IMAGES}${listingImageId}/`,
    "put",
    { "Content-Type": "application/json" },
    JSON.stringify({ number: imageIndex }),
    accessToken,
    setResultsCallback
  );
};

export const deleteListingImage = async (
  listingImageId,
  accessToken,
  setResultsCallback
) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.LISTING_IMAGES}${listingImageId}/`,
    "delete",
    { "Content-Type": "application/json" },
    null,
    accessToken,
    setResultsCallback
  );
};

export const getListingApplications = async (
  listingNumber,
  accessToken,
  callback
) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.LANDLORD_LISTINGS_API}${listingNumber}/applications/`,
    "get",
    { "Content-Type": "application/json" },
    null,
    accessToken,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, prepareApplicationsResult(apiResult), apiError)
  );
};

export const messageListing = async (
  listingNumber,
  contactForm,
  accessToken,
  callback
) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.LISTINGS_API}${listingNumber}/message/`,
    "post",
    { "Content-Type": "application/json" },
    JSON.stringify(camelToSnake(contactForm)),
    accessToken,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, snakeToCamel(apiResult), apiError)
  );
};

function preparePayload(listing) {
  const result = { ...listing };
  if (result.availabilityDate)
    result.availabilityDate = formatDate(result.availabilityDate);
  if (typeof result.catsAllowed === "boolean")
    result.catsAllowed = result.catsAllowed ? "Y" : "N";
  if (typeof result.smallDogsAllowed === "boolean")
    result.smallDogsAllowed = result.smallDogsAllowed ? "Y" : "N";
  if (typeof result.largeDogsAllowed === "boolean")
    result.largeDogsAllowed = result.largeDogsAllowed ? "Y" : "N";
  if (typeof result.isExclusive === "boolean")
    result.isExclusive = result.isExclusive ? "Y" : "N";
  if (typeof result.advertiseToPartners === "boolean")
    result.advertiseToPartners = result.advertiseToPartners ? "Y" : "N";
  ["longitude", "latitude", "sqft", "bedrooms", "bathrooms", "rent"].forEach(
    value => {
      if (result[value] === "") result[value] = null;
    }
  );
  return camelToSnake(result);
}

export function prepareListingFields(listing) {
  if (listing.contactPhone)
    listing.contactPhone = listing.contactPhone.substring(2);
  if (listing.availabilityDate)
    listing.availabilityDate = new Date(`${listing.availabilityDate}T00:00:00`);
  if (listing.images) {
    listing.images.sort((a, b) => {
      return a.number - b.number;
    });
  }
  listing.catsAllowed = listing.catsAllowed === "Y";
  listing.smallDogsAllowed = listing.smallDogsAllowed === "Y";
  listing.largeDogsAllowed = listing.largeDogsAllowed === "Y";
  listing.isExclusive = listing.isExclusive === "Y";
  listing.advertiseToPartners = listing.advertiseToPartners === "Y";
  for (let key in listing) {
    if (key !== "availabilityDate" && listing[key] === null) listing[key] = "";
  }
}

export function prepareListingResult(listing) {
  if (!listing) return listing;
  const result = snakeToCamel(listing);
  prepareListingFields(result);
  return result;
}

function prepareApplicationsResult(apiResult) {
  if (!apiResult) return apiResult;
  const result = snakeToCamel(apiResult);
  prepareListingFields(result.listing);
  if (result.applications) {
    for (let i = 0; i < result.applications.length; i++) {
      if (result.applications[i].tenant.phoneNumber) {
        result.applications[i].tenant.phoneNumber =
          result.applications[i].tenant.phoneNumber.substring(2);
      }
      if (result.applications[i].moveInDate)
        result.applications[i].moveInDate = new Date(
          `${result.applications[i].moveInDate}T00:00:00`
        );
      if (result.applications[i].submitted)
        result.applications[i].submitted = new Date(
          result.applications[i].submitted
        );
    }
  }

  return result;
}
