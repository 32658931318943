import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Container, Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { GLOBALS } from "../App";

// Helpers
import {
  getApplicationsAwaitingReports,
  submitAllAwaitingReports,
} from "../utils/ApplicationApi";
import useAuth0Nesitqa from "../hooks/useAuth0Nestiqa";
import { apply, joinApplication } from "../utils/ApplicationApi";

// Components
import NestiqaAlertDialog from "../components/NestiqaAlertDialog";
import ApplicationsPendingReportsTable from "../components/tenant-applications/ApplicationsPendingReportsTable";
import ApplicationsPendingReportsAccordion from "../components/tenant-applications/ApplicationsPendingReportsAccordion";
import ApplicationsPendingHeader from "../components/ApplicationsPendingHeader";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      marginTop: theme.spacing(4),
      padding: theme.spacing(3),
    },
    textWrapper: {
      width: "80%",

      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
  })
);

const ApplicationsPendingReportsPage = () => {
  const { accessToken } = useAuth0Nesitqa();
  const { tenantId } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const isTabletOrAbove = useMediaQuery(theme => theme.breakpoints.up("sm"));
  const [alertDialogControls, setAlertDialogControls] = useState({
    open: false,
    title: "",
    message: "",
  });
  const [loading, setLoading] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [data, setData] = useState([]);

  const callGetApplicationsAwaitingReports = useCallback(async () => {
    getApplicationsAwaitingReports(
      tenantId,
      accessToken,
      (isSuccess, result, error) => {
        if (isSuccess) {
          setData(result);

          if (!result.length) {
            setAlertDialogControls({
              open: true,
              title: "Info",
              message: GLOBALS.MESSAGES.EMPTY_RETRIEVING_APPLICATIONS,
              onClose: () => {
                setAlertDialogControls({ open: false });
                history.push(GLOBALS.PATHS.ROOT);
              },
            });
          }
        }

        if (error) {
          setAlertDialogControls({
            open: true,
            title: "Error",
            message: GLOBALS.MESSAGES.ERROR_RETRIEVING_APPLICATIONS,
            onClose: () => setAlertDialogControls({ open: false }),
          });
        }

        setLoading(false);
      }
    );
  }, [accessToken, tenantId, history]);

  function handleError() {
    setAlertDialogControls({
      open: true,
      title: "Error",
      message: GLOBALS.MESSAGES.ERROR_COMPLETING_ACTION,
      onClose: () => setAlertDialogControls({ open: false }),
    });
  }

  function applyCallback(isSuccess, result, error) {
    if (isSuccess) {
      setAlertDialogControls({
        open: true,
        title: "Success",
        message: GLOBALS.MESSAGES.SUBMITTED_APPLICATION_SUCCESSFUL,
        onClose: () => {
          setAlertDialogControls({ open: false });
          callGetApplicationsAwaitingReports();
        },
      });
    }
    if (error) {
      console.error(error);
      handleError();
    }
  }

  function joinCallback(isSuccess, result, error) {
    if (isSuccess) {
      setAlertDialogControls({
        open: true,
        title: "Success",
        message: GLOBALS.MESSAGES.SUBMITTED_APPLICATION_SUCCESSFUL,
        onClose: () => {
          setAlertDialogControls({ open: false });
          callGetApplicationsAwaitingReports();
        },
      });
    } else {
      console.error(error);
      handleError();
    }
  }

  async function handleApply(payload) {
    setRequesting(true);
    await apply(accessToken, payload, applyCallback);
    setRequesting(false);
  }

  async function handleJoin(applicationId) {
    setRequesting(true);
    await joinApplication(accessToken, applicationId, joinCallback);
    setRequesting(false);
  }

  useEffect(() => {
    if (accessToken) {
      callGetApplicationsAwaitingReports();
    }
  }, [accessToken, callGetApplicationsAwaitingReports]);

  const handleDialogClose = () => {
    setAlertDialogControls({ open: false });

    if (!data.length) {
      history.push(GLOBALS.PATHS.ROOT);
    }
  };

  const callSubmitAllAwaitingReports = useCallback(async () => {
    setRequesting(true);

    await submitAllAwaitingReports(
      tenantId,
      accessToken,
      (isSuccess, result, error) => {
        if (isSuccess) {
          setData(result);

          if (!result.length) {
            setAlertDialogControls({
              open: true,
              title: "Success",
              message: "Submission successful",
              onClose: () => history.push(GLOBALS.PATHS.ROOT),
            });
          } else {
            setAlertDialogControls({
              open: true,
              title: "Error",
              message: GLOBALS.MESSAGES.ERROR_SUBMITTING_APPLICATIONS,
              onClose: () => setAlertDialogControls({ open: false }),
            });
          }
        }

        if (error) {
          setAlertDialogControls({
            open: true,
            title: "Error",
            message: GLOBALS.MESSAGES.ERROR_COMPLETING_ACTION,
          });
        }
      }
    );

    setRequesting(false);
  }, [accessToken, history, tenantId]);

  const handleSubmitAll = async () => {
    await callSubmitAllAwaitingReports();
  };

  if (loading) {
    return (
      <Container>
        <Typography variant="h5" align="center" color="primary">
          Loading...
        </Typography>
      </Container>
    );
  }
  if (!data.length) {
    return (
      <div className={classes.root}>
        <NestiqaAlertDialog
          {...alertDialogControls}
          onClose={handleDialogClose}
        />
      </div>
    );
  }
  return (
    <div className={classes.root}>
      <NestiqaAlertDialog {...alertDialogControls} />
      <Container>
        <ApplicationsPendingHeader
          handleSubmitAll={handleSubmitAll}
          requesting={requesting}
        />
        {!isTabletOrAbove ? (
          data.map(app => (
            <ApplicationsPendingReportsAccordion
              key={app.id}
              application={app}
              tenantId={tenantId}
              handleApply={handleApply}
              handleJoin={handleJoin}
              loading={requesting || loading}
            />
          ))
        ) : (
          <ApplicationsPendingReportsTable
            data={data}
            tenantId={tenantId}
            handleApply={handleApply}
            handleJoin={handleJoin}
            loading={requesting || loading}
          />
        )}
      </Container>
    </div>
  );
};

export default ApplicationsPendingReportsPage;
