import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";


const CallDialog = ({ open, onClose }) => {

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="contact-dialog-slide-title"
      aria-describedby="contact-dialog-slide-description"
    >
      <DialogTitle>Call Us</DialogTitle>
      <DialogContent>973-991-9810</DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CallDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default CallDialog;
