import { useState, useEffect } from "react";
import { GLOBALS } from "../App";
import { usePosition } from "./usePosition";

export const useLocationFilters = () => {
  const { latitude, longitude } = usePosition();
  const [locFilters, setLocFilters] = useState({
    dst: GLOBALS.DEFAULT_DISTANCE * GLOBALS.MILE_TO_KM,
    frlg: Number(longitude || GLOBALS.DEFAULT_LOC.lng).toFixed(3),
    frlt: Number(latitude || GLOBALS.DEFAULT_LOC.lat).toFixed(3),
  });

  useEffect(() => {
    if (!longitude || !latitude) return;

    setLocFilters({
      frlg: Number(longitude).toFixed(3),
      frlt: Number(latitude).toFixed(3),
      dst: GLOBALS.DEFAULT_DISTANCE * GLOBALS.MILE_TO_KM,
    });
  }, [longitude, latitude]);

  return locFilters;
};
