import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { GLOBALS } from "../App";

function useAuth0Nesitqa(requestedScope = "") {
  const {
    user,
    isAuthenticated,
    getAccessTokenSilently,
    loginWithRedirect,
    logout,
  } = useAuth0();
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    let mounted = true;

    const getUserToken = async () => {
      try {
        if (mounted) {
          const token = await getAccessTokenSilently({
            audience: `${GLOBALS.AUTH0_MANAGEMENT_API_ENDPOINT}`,
            scope: requestedScope,
          });
          setAccessToken(token);
        }
      } catch (e) {
        console.log("Failed to get access token: " + e.message);
      }
    };

    if (isAuthenticated) {
      getUserToken();
    }

    return () => {
      mounted = false;
      setAccessToken(null);
    };
  }, [isAuthenticated, getAccessTokenSilently, requestedScope]);

  return {
    user,
    isAuthenticated,
    accessToken,
    loginWithRedirect,
    logout,
  };
}

export default useAuth0Nesitqa;
