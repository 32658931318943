export const LANDLORD_EXT_STATUS = {
  START: "START",
  USER_HAS_LANDLORD: "USER_HAS_LANDLORD",
  VALIDATE_INFO: "VALIDATE_INFO",
  VALIDATE_COMM: "VALIDATE_COMM",
  COMM_SENT: "COMM_SENT",
  ACCEPT_TERMS: "ACCEPT_TERMS",
  READY: "READY",
  COMPLETE: "COMPLETE",
  MANUAL_REQUIRED: "MANUAL_REQUIRED",
  UNAVAILABLE: "UNAVAILABLE",
};
