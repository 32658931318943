import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  Typography,
  List,
  ListItem,
  IconButton,
  Link,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";

import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { GLOBALS } from "../../App";
import useAuth0Nesitqa from "../../hooks/useAuth0Nestiqa";
import { getReportData } from "../../utils/ReportsApi";
import TenantProfileSection from "./TenantProfileSection";
import {
  getCreateReportsLater,
  getReports,
  getReportsStatus,
  getToShareReports,
  getReportsNotified,
} from "../../store/selectors/reportsSelectors";
import {
  callGetAvailableReports,
  setCreateReportsLater,
} from "../../store/actions/reportsActions";
import NestiqaAlertDialog from "../NestiqaAlertDialog";
import useInterval from "../../hooks/useInterval";
import NestiqaActionDialog from "../NestiqaActionDialog";
import { markNotified } from "../../utils/ReportsApi";
import { prepareReportPageContent } from "../../utils/common";

const useStyles = makeStyles(theme => ({
  noReportsText: {
    marginBottom: theme.spacing(1),
  },
  listItem: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: `2px solid ${theme.palette.secondary.main}`,

    "&.MuiListItem-gutters": {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
  listItemHeader: {
    display: "flex",
    justifyContent: "space-between",

    "&.MuiListItem-gutters": {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
  },
  iconButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginLeft: theme.spacing(2),
  },
  left: {
    display: "flex",
    alignItems: "center",
  },
  infoWrapper: {
    display: "flex",
    flexFlow: "column wrap",
    alignItems: "flex-start",
    justifyContent: "space-between",
    marginTop: "30px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const INTERVAL_DELAY = 30 * 1000;

const Reports = ({ isComplete }) => {
  const { tenantId } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const { accessToken } = useAuth0Nesitqa("");
  const dispatch = useDispatch();
  const status = useSelector(getReportsStatus);
  const reports = useSelector(getReports);
  const createReportsLater = useSelector(getCreateReportsLater);

  const [dialogControls, setDialogControls] = useState({
    open: false,
    title: "",
    message: "",
  });

  function showError(message = GLOBALS.MESSAGES.TENANT_INCOMPLETE_REPORTS) {
    setDialogControls({
      open: true,
      title: "Error",
      message,
    });
  }

  const closeAlert = () => {
    setDialogControls({ open: false });
  };

  const onGenerateReport = () => {
    if (isComplete === "N") {
      showError();
    } else {
      history.push(GLOBALS.PATHS.REPORTS_PAGE.replace(":tenantId", tenantId));
    }
  };

  useEffect(() => {
    if (!accessToken) return;
    if (!status || !reports.length) {
      dispatch(callGetAvailableReports(accessToken));
    } else if (status === "REPORTS_IN_PROGRESS") {
    }
  }, [accessToken, status, dispatch, reports.length]);

  const updateReportsProgress = () => {
    if (status !== "REPORTS_IN_PROGRESS") {
      return;
    }
    dispatch(callGetAvailableReports(accessToken));
  };

  useInterval(updateReportsProgress, INTERVAL_DELAY);

  const [actionDialogControls, setActionDialogControls] = useState({
    open: !createReportsLater && isComplete === "Y",
    title: "Create Screening Reports",
    message: (
      <>
        {GLOBALS.MESSAGES.CREATE_SCREENING_REPORTS}{" "}
        <Link
          component="a"
          underline="none"
          color="primary"
          href="https://resources.nestiqa.com/faq/#what-are-the-screening-reports"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more
        </Link>
      </>
    ),
    buttonMap: [
      {
        buttonName: "Later",
        handleClick: () => {
          dispatch(setCreateReportsLater());
          setActionDialogControls({ open: false });
        },
      },
      {
        buttonName: "Yes",
        handleClick: () => {
          setActionDialogControls({ open: false });
          onGenerateReport();
        },
      },
    ],
  });

  if (!status || !accessToken) {
    return null;
  }

  let reportSectionContent = null;

  switch (status) {
    // TODO
    case "REPORTS_READY":
      reportSectionContent = (
        <>
          <NestiqaAlertDialog {...dialogControls} onClose={closeAlert} />
          <ReportsReady
            accessToken={accessToken}
            showError={showError}
            tenantId={tenantId}
          />
        </>
      );
      break;

    case "REPORTS_IN_PROGRESS":
      reportSectionContent = (
        <Typography variant="body2" className={classes.noReportsText}>
          {GLOBALS.MESSAGES.REPORTS_IN_PROGRESS}
        </Typography>
      );
      break;

    default:
      reportSectionContent = (
        <>
          <NestiqaAlertDialog {...dialogControls} onClose={closeAlert} />
          <NestiqaActionDialog {...actionDialogControls} />
          <Typography variant="body2" className={classes.noReportsText}>
            {GLOBALS.MESSAGES.NO_REPORTS_AVAILABLE}{" "}
            <Link
              component="a"
              underline="none"
              color="primary"
              href="https://resources.nestiqa.com/faq/#what-are-the-screening-reports"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more
            </Link>
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={onGenerateReport}
          >
            Generate Reports
          </Button>
        </>
      );
  }

  return (
    <TenantProfileSection title="Reports" isEditable={false}>
      {reportSectionContent}
    </TenantProfileSection>
  );
};

const ReportsReady = ({ accessToken, showError, tenantId }) => {
  const classes = useStyles();
  const history = useHistory();
  const reports = useSelector(getReports);
  const reportsStatus = useSelector(getReportsStatus);
  const reportsNotified = useSelector(getReportsNotified);
  const toShareReports = useSelector(getToShareReports);
  const [showSubmitMessage, setShowSubmitMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (reportsStatus === "REPORTS_READY" && toShareReports > 0) {
      setShowSubmitMessage(true);
    }
  }, [reportsStatus, reportsNotified, toShareReports]);

  function reportCallback(isSuccess, result, error) {
    setLoading(false);
    if (isSuccess) {
      try {
        let wnd = window.open("", "_blank");
        let content = prepareReportPageContent(result);
        wnd.document.write(content);
        wnd.document.close();
      } catch (e) {
        console.error(e);
        showError(GLOBALS.MESSAGES.POPUP_DISABLED_ERROR);
      }
    }
    if (error) {
      showError(GLOBALS.MESSAGES.ERROR_COMPLETING_ACTION);
      console.error(error);
    }
  }

  async function callGetReportData(product) {
    setLoading(true);
    await getReportData(accessToken, product, reportCallback);
  }

  const callMarkNotified = useCallback(async () => {
    markNotified(accessToken, (isSuccess, result, error) => {
      if (isSuccess) {
        return true;
      }
      if (error) {
        console.log(error);
      }
    });
  }, [accessToken]);

  const handleViewApplications = useCallback(async () => {
    await callMarkNotified();

    history.push(
      GLOBALS.PATHS.APPLICATIONS_PENDING_REPORTS_PAGE.replace(
        ":tenantId",
        tenantId
      )
    );
  }, [history, tenantId, callMarkNotified]);

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="secondary" />
      </Backdrop>
      <List component="div">
        <ListItem component="div" className={classes.listItemHeader}>
          <Typography variant="body2">
            <strong>Reports</strong>
          </Typography>
          <Typography variant="body2">
            <strong>Actions</strong>
          </Typography>
        </ListItem>
        {reports.map((report, index) => (
          <ListItem component="div" className={classes.listItem} key={index}>
            <div className={classes.left}>
              <Typography variant="body2">{report}</Typography>
            </div>

            <div className={classes.iconButtons}>
              <>
                <IconButton
                  edge="end"
                  aria-label="get-report"
                  onClick={() => callGetReportData(report.toLowerCase())}
                >
                  <VisibilityIcon color="secondary" />
                </IconButton>
              </>
            </div>
          </ListItem>
        ))}
        {showSubmitMessage && (
          <div className={classes.infoWrapper}>
            <div>
              <p>
                You have {toShareReports}{" "}
                {toShareReports === 1 ? "application" : "applications"} awaiting
                your reports. Would you like to view and submit now?
              </p>
            </div>
            <Button
              variant="contained"
              color="primary"
              onClick={handleViewApplications}
            >
              View Applications
            </Button>
          </div>
        )}
      </List>
    </>
  );
};

export default Reports;
