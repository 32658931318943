import React from "react";

const TwitterSquare = props => {
  return (
    <svg
      xmlns="true"
      width="26.065"
      height="24.82"
      viewBox="0 0 26.065 24.82"
      {...props}
    >
      <g id="Twitter-Group" transform="translate(0 0.001)">
        <path
          id="Twitter-Square-Icon"
          d="M22.065,24.82H4a4,4,0,0,1-4-4V4A4,4,0,0,1,4,0H22.065a4,4,0,0,1,4,4V20.82A4,4,0,0,1,22.065,24.82ZM4.736,17.7h0a11.1,11.1,0,0,0,5.808,1.616A10.412,10.412,0,0,0,21.319,9.058c0-.171-.007-.332-.014-.466a7.37,7.37,0,0,0,1.9-1.872,8.225,8.225,0,0,1-2.182.57A3.625,3.625,0,0,0,22.687,5.3a7.805,7.805,0,0,1-2.4.873A3.9,3.9,0,0,0,17.52,5.028a3.7,3.7,0,0,0-3.784,3.606,3.488,3.488,0,0,0,.088.822,10.948,10.948,0,0,1-7.8-3.771A3.479,3.479,0,0,0,5.5,7.508a3.554,3.554,0,0,0,1.682,3,3.862,3.862,0,0,1-1.711-.444v.04a3.674,3.674,0,0,0,3.033,3.541,3.913,3.913,0,0,1-.993.119A3.45,3.45,0,0,1,6.8,13.7a3.8,3.8,0,0,0,3.538,2.511,7.905,7.905,0,0,1-4.694,1.538A7.526,7.526,0,0,1,4.736,17.7Z"
          transform="translate(0 -0.001)"
          fill="#3ce0e5"
        />
      </g>
    </svg>
  );
};

export default TwitterSquare;
