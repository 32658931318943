import React, { useRef, useState, useEffect } from "react";
import { useStyles } from "./ListingDetails.styles";
import {
  ButtonGroup,
  Button,
  Box,
  Grid,
  Typography,
  Link,
  useMediaQuery,
} from "@material-ui/core";
import {
  Room,
  EventAvailable,
  People,
  Pets,
  LocalParking,
  LocalLaundryService,
  Business,
  Smartphone,
  Facebook,
  LinkedIn,
  Instagram,
  Twitter,
} from "@material-ui/icons";
import {
  formatPrice,
  formatListingDate,
  formatPhoneNumber,
  getLabel,
  findEnumImage,
  getCurrentYear,
} from "../nestiqaUtils";
import PetsTooltip from "./tooltip/PetsTooltip";
import ImageGallery from "react-image-gallery";
import ReactReadMoreReadLess from "react-read-more-read-less";
import bedIcon from "../static/images/bed_icon.png";
import bathIcon from "../static/images/bath_icon.png";
import "react-image-gallery/styles/css/image-gallery.css";
import noImage from "../components/noimg640.png";
import { GLOBALS } from "../App";
import AlertDialogBox from "./AlertDialogBox";
import useAuth0Nesitqa from "../hooks/useAuth0Nestiqa";
import { createApplication } from "../utils/ApplicationApi";
import { useHistory } from "react-router-dom";
import GoogleStaticMap from "./google-maps/GoogleStaticMap";
import clsx from "clsx";
import LoginDialog from "./LoginDialog";
import ListingContactDialog from "./ListingContactDialog";
import { useDispatch, useSelector } from "react-redux";
import { getActiveUser } from "../store/selectors/userSelectors";
import { createTenant } from "../utils/TenantApi";
import NestiqaAlertDialog from "./NestiqaAlertDialog";
import { setUser as setReduxUser } from "../store/actions/userActions";
import { useGA4React } from "ga-4-react";
import { logEvent } from "../utils/initGa4React";
import CloseIcon from "@material-ui/icons/Close";

function ListingDetails(props) {
  const classes = useStyles();
  const ref = useRef(null);
  const thumbClicked = useRef(false);
  const dispatch = useDispatch();
  const activeUser = useSelector(getActiveUser);
  const [isFullScreen, setFullScreen] = useState(false);
  const listingDetails = props.listing;
  const preview = props.preview || false;
  const { isAuthenticated, accessToken } = useAuth0Nesitqa("read:users");
  const history = useHistory();
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up("lg"));
  const isTablet = useMediaQuery(theme => theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));
  const [images, setImages] = useState([]);
  const [disclosureDialogOpen, setDisclosureDialogOpen] = useState(false);
  const [messageDialogControls, setMessageDialogControls] = useState({
    open: false,
  });
  const ga = useGA4React();

  const [loginDialog, setLoginDialog] = useState({
    open: false,
  });

  const closeLoginDialog = () => {
    setLoginDialog({
      open: false,
    });
  };

  const openLoginDialog = () => {
    setLoginDialog({
      open: true,
      preLogin: () => {
        localStorage.setItem("previousLocation", window.location.pathname);
      },
      action: "apply",
      onClose: closeLoginDialog,
    });
  };

  const [listingContactDialog, setListingContactDialog] = useState({
    open: false,
  });

  const closeListingContactDialog = () => {
    setListingContactDialog({
      open: false,
    });
  };

  const openListingContactDialog = () => {
    setListingContactDialog({
      open: true,
      onClose: closeListingContactDialog,
    });
  };

  useEffect(() => {
    let images = [];
    if (listingDetails.images && listingDetails.images.length > 0) {
      for (let i = 0; i < listingDetails.images.length; i++) {
        images.push({
          original: listingDetails.images[i].standard,
          thumbnail: listingDetails.images[i].standard,
        });
      }
    } else {
      const im = GLOBALS.REACT_BASE_URL + noImage;
      images.push({ original: im, thumbnail: im });
    }
    setImages(images);
  }, [listingDetails]);

  useEffect(() => {
    const handleEsc = e => {
      if (e.keyCode === 27) {
        setFullScreen(false);
      }
    };

    document.addEventListener("keydown", handleEsc);

    return () => {
      document.removeEventListener("keydown", handleEsc);
    };
  }, [isFullScreen]);

  function getCurrentAmenities() {
    return listingDetails ? listingDetails.amenities.split(",") : [];
  }

  function getCurrentFeatures() {
    return listingDetails ? listingDetails.features.split(",") : [];
  }

  function hasAmenities() {
    return (
      listingDetails &&
      listingDetails.amenities.length > 0 &&
      listingDetails.amenities[0] !== ""
    );
  }

  function hasFeatures() {
    return (
      listingDetails &&
      listingDetails.features.length > 0 &&
      listingDetails.features[0] !== ""
    );
  }

  function getCharLimit(description) {
    // Return the min between 100 characters or the 5th line bread to control the height
    return Math.min(description.split("\r\n", 5).join("\r\n").length, 250);
  }

  async function runButtonAction(action, button, requireAuth = true) {
    if (!!ga) {
      logEvent("listing_details_button_click", {
        button: button,
      });
    }
    if (isAuthenticated) {
      if (!activeUser) return;
      if (activeUser.tenantId) {
        await action(activeUser.tenantId, false);
      } else {
        await createTenant(
          accessToken,
          async (isSuccess, apiResult, apiError) => {
            if (isSuccess) {
              dispatch(setReduxUser({ tenantId: apiResult.tenantId }));
              await action(apiResult.tenantId, true);
            } else {
              handleError();
            }
          }
        );
      }
    } else if (!isAuthenticated && !requireAuth) {
      await action(null, false);
    } else {
      return openLoginDialog();
    }
  }

  async function handleApplyButton(tenantId, isNewTenant) {
    const data = {
      status: "DRAFT",
      listing: listingDetails.id,
    };
    const response = await createApplication(data, accessToken);
    if (response.detail) {
      return;
    }
    if (response) {
      if (isNewTenant) {
        history.push(
          GLOBALS.PATHS.CREATE_TENANT.replace(":tenantId", tenantId),
          { from: "/redirect" }
        );
      } else {
        return history.push(`/application/${response.id}/update`);
      }
    }

    return false;
  }

  function handleError() {
    setMessageDialogControls({
      open: true,
      title: "Error",
      message: GLOBALS.MESSAGES.ERROR_COMPLETING_ACTION,
      onClose: () => setMessageDialogControls({ open: false }),
    });
  }

  if (preview && listingDetails.isComplete === "N") {
    return (
      <Typography variant="h5" align="center" color="error">
        {GLOBALS.MESSAGES.LISTING_NOT_COMPLETE}
      </Typography>
    );
  }

  const disclosureButtonMap = [
    {
      buttonName: "Close",
      handleClick: () => setDisclosureDialogOpen(false),
    },
  ];

  const findPetState = () => {
    const { smallDogsAllowed, largeDogsAllowed, catsAllowed, petsComment } =
      listingDetails;

    const cats = catsAllowed ? `Cats ok; ` : "";
    const smallDogs =
      smallDogsAllowed && !largeDogsAllowed ? `Small dogs ok; ` : "";
    const largeDogs =
      largeDogsAllowed && !smallDogsAllowed ? `Large dogs ok; ` : "";
    const bothDogs = smallDogsAllowed && largeDogsAllowed ? "Dogs ok; " : "";
    const comment =
      (cats || smallDogs || largeDogs) && !!petsComment.length
        ? petsComment
        : "";

    const petsData = [cats, smallDogs, largeDogs, bothDogs, comment];

    return [...petsData];
  };

  let amenities = [],
    features = [],
    currentListingDetails = [];
  if (listingDetails) {
    const catAmenities = "AMENITIES";
    const catFeatures = "FEATURES";

    const currentAmenities = getCurrentAmenities();
    const currentFeatures = getCurrentFeatures();

    currentAmenities.map(amenity => {
      return amenities.push({
        name: getLabel(amenity, catAmenities),
        image: findEnumImage(amenity, catAmenities, currentAmenities),
      });
    });

    currentFeatures.map(feature => {
      return features.push({
        name: getLabel(feature, catFeatures),
        image: findEnumImage(feature, catFeatures, currentFeatures),
      });
    });

    currentListingDetails = [
      {
        name: "Available",
        image: <EventAvailable />,
        content: formatListingDate(listingDetails.availabilityDate),
      },
      {
        name: "Type",
        image: <People />,
        content: getLabel(listingDetails.housingType, "HOUSING_TYPES"),
      },
      {
        name: "Pets",
        image: <Pets />,
        content: findPetState(),
      },
      {
        name: "Lease",
        image: <Business />,
        content: getLabel(listingDetails.leaseType, "LEASE_TYPES"),
      },
      {
        name: "Parking",
        image: <LocalParking />,
        content: getLabel(listingDetails.parking, "PARKING"),
      },
      {
        name: "Laundry",
        image: <LocalLaundryService />,
        content: getLabel(listingDetails.laundry, "LAUNDRY"),
      },
    ];
  }
  const {
    facebookId,
    instagramId,
    linkedInUrl,
    twitterId,
    firstName,
    lastName,
    companyName,
  } = listingDetails.landlord;
  const fullName = `${firstName} ${lastName}`;

  const getApplyButtonLabel = () => {
    return listingDetails.applyAllowed !== "N" ? "Apply" : "Get Pre-Approved";
  };

  return (
    <>
      <NestiqaAlertDialog {...messageDialogControls} />
      <Grid container alignItems="stretch" className={classes.listing}>
        <ListingContactDialog
          listingNumber={listingDetails.listingNumber}
          {...listingContactDialog}
        />
        {disclosureDialogOpen && (
          <AlertDialogBox
            openDialBox
            title={
              GLOBALS.MLS_INFO[listingDetails.dataSet].name + " Disclosures"
            }
            message={GLOBALS.MLS_INFO[
              listingDetails.dataSet
            ].disclosures.replace("[year]", getCurrentYear())}
            buttonMap={disclosureButtonMap}
            onClose={() => setDisclosureDialogOpen(false)}
          />
        )}
        <Grid item xs={12} sm={12} md={12} lg={6}>
          {listingDetails.virtualTourUrl && (
            <Box
              pr={isMobile ? 0 : 3}
              className={clsx(
                images.length <= 1 ? classes.imageContainer : "",
                isMobile ? classes.imagesMobile : ""
              )}
            >
              <Typography variant="subtitle1" gutterBottom align="center">
                <Link
                  component="a"
                  underline="none"
                  color="primary"
                  href={listingDetails.virtualTourUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View virtual tour
                </Link>
              </Typography>
            </Box>
          )}
          <Box
            pr={isMobile ? 0 : 3}
            className={clsx(
              images.length <= 1 ? classes.imageContainer : "",
              isMobile ? classes.imagesMobile : ""
            )}
          >
            <ImageGallery
              ref={ref}
              items={images}
              showBullets={false}
              showPlayButton={false}
              showFullscreenButton={false}
              showThumbnails={!isMobile && images.length > 1}
              thumbnailPosition="left"
              onThumbnailClick={() => {
                if (isFullScreen) {
                  thumbClicked.current = true;
                }
              }}
              onClick={() => {
                ref.current.fullScreen();
                setFullScreen(true);
              }}
              renderCustomControls={() => {
                return (
                  isMobile &&
                  isFullScreen && (
                    <div
                      variant="text"
                      style={{
                        position: "absolute",
                        right: 10,
                        top: 10,
                        color: "black",
                        padding: 10,
                        zIndex: 1,
                      }}
                      onClick={() => {
                        ref.current.exitFullScreen();
                        setFullScreen(false);
                      }}
                    >
                      <CloseIcon />
                    </div>
                  )
                );
              }}
            />
          </Box>
          {isDesktop && (
            <div className={clsx(classes.mapWrapper, "ipad--map")}>
              <GoogleStaticMap
                latitude={listingDetails.latitude}
                longitude={listingDetails.longitude}
                mapWidth="571"
                mapHeight="373"
              />
            </div>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} className={classes.rightSide}>
          <Box
            p={1}
            justifyContent="space-between"
            flexDirection="column"
            height="100%"
          >
            <div className={classes.addressWrapper}>
              <div className={classes.topHeader}>
                <div>
                  <Typography
                    className={classes.title}
                    variant="h1"
                    align="left"
                  >
                    <Room />
                    {listingDetails.address1}
                    {listingDetails.address2 !== ""
                      ? `, ${listingDetails.address2}`
                      : null}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    align="left"
                    className={classes.secondAddress}
                  >
                    {listingDetails.city}, {listingDetails.state}{" "}
                    {listingDetails.zipCode}
                  </Typography>
                </div>
                {listingDetails.dataSet &&
                  GLOBALS.MLS_INFO[listingDetails.dataSet] && (
                    <div className={classes.mlsImageWrapper}>
                      <img
                        src={GLOBALS.MLS_INFO[listingDetails.dataSet].logo}
                        alt={GLOBALS.MLS_INFO[listingDetails.dataSet].name}
                      />
                    </div>
                  )}
              </div>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mt={1}
                mb={1}
                className={classes.bedsBox}
              >
                <Typography
                  variant="h6"
                  color="primary"
                  className={classes.priceText}
                >
                  {`${formatPrice(listingDetails.rent)}/mo`}
                </Typography>
                {!isMobile && (
                  <div className={classes.bedsBaths}>
                    <ButtonGroup
                      className={classes.bedBath}
                      size="small"
                      aria-label="primary button group"
                    >
                      {listingDetails.sqft ? (
                        <Button className={classes.sqftButton}>
                          {listingDetails.sqft} sqft.
                        </Button>
                      ) : null}
                      <Button>
                        <span className={classes.bedBathText}>
                          {getLabel(listingDetails.bedrooms, "BEDROOMS")}
                        </span>
                        <img
                          className={classes.icons}
                          src={bedIcon}
                          alt="bedrooms"
                        />
                      </Button>
                      <Button>
                        <span className={classes.bedBathText}>
                          {getLabel(listingDetails.bathrooms, "BATHROOMS")}
                        </span>
                        <img
                          className={classes.icons}
                          src={bathIcon}
                          alt="bathrooms"
                        />
                      </Button>
                    </ButtonGroup>
                  </div>
                )}
                <LoginDialog {...loginDialog} />

                <div className={classes.btnGrp}>
                  {!preview && listingDetails.dataSet !== "INTERNAL" && (
                    <Button
                      variant="contained"
                      color="primary"
                      className={clsx(
                        classes.applyButton,
                        classes.contactButton
                      )}
                      onClick={() =>
                        runButtonAction(
                          openListingContactDialog,
                          "Contact",
                          false
                        )
                      }
                    >
                      Contact
                    </Button>
                  )}

                  {listingDetails.dataSet !== "RB" && (
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.applyButton}
                      disabled={preview}
                      onClick={() =>
                        runButtonAction(
                          handleApplyButton,
                          getApplyButtonLabel()
                        )
                      }
                    >
                      {getApplyButtonLabel()}
                    </Button>
                  )}
                </div>
                {isMobile && (
                  <div className={classes.bedsBaths}>
                    <ButtonGroup
                      className={classes.bedBath}
                      size="small"
                      aria-label="primary button group"
                    >
                      {listingDetails.sqft ? (
                        <Button className={classes.sqftButton}>
                          {listingDetails.sqft} sqft.
                        </Button>
                      ) : null}
                      <Button>
                        <span className={classes.bedBathText}>
                          {getLabel(listingDetails.bedrooms, "BEDROOMS")}
                        </span>
                        <img
                          className={classes.icons}
                          src={bedIcon}
                          alt="bedrooms"
                        />
                      </Button>
                      <Button>
                        <span className={classes.bedBathText}>
                          {getLabel(listingDetails.bathrooms, "BATHROOMS")}
                        </span>
                        <img
                          className={classes.icons}
                          src={bathIcon}
                          alt="bathrooms"
                        />
                      </Button>
                    </ButtonGroup>
                  </div>
                )}
              </Box>
            </div>

            <div className={classes.details}>
              <div className={classes.scrollable}>
                <Grid mb={5}>
                  <Typography
                    variant="body2"
                    align="left"
                    gutterBottom
                    className={classes.scrollableDescWrapper}
                  >
                    <ReactReadMoreReadLess
                      charLimit={getCharLimit(listingDetails.description)}
                      readMoreText={"Read more"}
                      readLessText={"Read less"}
                      readMoreClassName={classes.readMoreLess}
                      readLessClassName={classes.readMoreLess}
                    >
                      {listingDetails.description}
                    </ReactReadMoreReadLess>
                  </Typography>
                </Grid>

                <div
                  className={clsx(
                    classes.wrapper,
                    classes.borderTop,
                    classes.mt50
                  )}
                >
                  <ul className={classes.listingDetailsList}>
                    {listingDetails &&
                      currentListingDetails.map((item, i) => (
                        <li key={i}>
                          {item.image}
                          <Typography variant="subtitle1">
                            {item.name}:{" "}
                          </Typography>
                          {isDesktop ? (
                            <PetsTooltip
                              tooltipText={item.content}
                              clickable={false}
                            />
                          ) : (
                            <PetsTooltip
                              tooltipText={item.content}
                              clickable={true}
                            />
                          )}
                        </li>
                      ))}
                  </ul>
                </div>
                {hasAmenities() && (
                  <div
                    className={clsx(
                      classes.amenitiesOuter,
                      classes.borderBottom,
                      classes.borderTop
                    )}
                  >
                    <div className={classes.subTitle}>
                      <Typography variant="h5">Amenities</Typography>
                    </div>
                    <div className={classes.amenitiesWrapper}>
                      {amenities.map(col => (
                        <div key={col.name} className={classes.amenitiesInner}>
                          {col.image}
                          <Typography variant="subtitle1">
                            {col.name}
                          </Typography>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {hasFeatures() && (
                  <div
                    className={clsx(
                      classes.amenitiesOuter,
                      classes.borderBottom,
                      !hasAmenities ? classes.borderTop : ""
                    )}
                  >
                    <div className={classes.subTitle}>
                      <Typography variant="h5">Features</Typography>
                    </div>
                    <div className={classes.amenitiesWrapper}>
                      {features.map(col => (
                        <div key={col.name} className={classes.amenitiesInner}>
                          {col.image}
                          <Typography variant="subtitle1">
                            {col.name}
                          </Typography>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                <div
                  className={clsx(
                    classes.wrapper,
                    classes.borderBottom,
                    classes.paddingY,
                    !hasFeatures && !hasAmenities ? classes.borderTop : null
                  )}
                >
                  {listingDetails.landlord && (
                    <div className={classes.subTitleInner}>
                      <div className={classes.subTitle}>
                        <div className={classes.subTitleInner}>
                          <Typography variant="h5" align="left">
                            About the Listing Owner
                          </Typography>
                          <div className={classes.landlordWrapper}>
                            <div className={classes.landlord}>
                              <img
                                className={classes.landlordImg}
                                src={listingDetails.landlord.picture}
                                alt="Listing Owner Avatar"
                              />
                              <div className={classes.landlordName}>
                                <div>{fullName.length && fullName}</div>
                                {companyName && <div>{companyName}</div>}
                                <div>
                                  {getLabel(
                                    listingDetails.landlord.landlordType,
                                    "LANDLORD_TYPES"
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className={classes.landlordSocial}>
                              <div className={classes.socialWrapper}>
                                {facebookId && (
                                  <Link
                                    component="a"
                                    underline="none"
                                    color="secondary"
                                    href={`https://www.facebook.com/${facebookId}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <Facebook />
                                  </Link>
                                )}
                                {instagramId && (
                                  <Link
                                    component="a"
                                    underline="none"
                                    color="secondary"
                                    href={`https://www.instagram.com/${instagramId}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <Instagram />
                                  </Link>
                                )}
                                {twitterId && (
                                  <Link
                                    component="a"
                                    underline="none"
                                    color="secondary"
                                    href={`https://www.twitter.com/${twitterId}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <Twitter />
                                  </Link>
                                )}
                                {linkedInUrl && (
                                  <Link
                                    component="a"
                                    underline="none"
                                    color="secondary"
                                    href={linkedInUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <LinkedIn />
                                  </Link>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={clsx(
                          classes.unitFeaturesWrapper,
                          isMobile ? classes.marginTop : ""
                        )}
                      >
                        <Typography variant="body1">
                          <ReactReadMoreReadLess
                            charLimit={170}
                            readMoreText={"Read more"}
                            readLessText={"Read less"}
                            readMoreClassName={classes.readMoreLess}
                            readLessClassName={classes.readMoreLess}
                          >
                            {listingDetails.landlord.bio}
                          </ReactReadMoreReadLess>
                        </Typography>
                      </div>
                    </div>
                  )}
                </div>
                {listingDetails.applyAllowed === "Y" && (
                  <div className={classes.wrapper}>
                    <div className={classes.subTitle}>
                      <Typography variant="h5" align="left">
                        Contact
                      </Typography>
                    </div>
                    <div className={classes.contactWrapper}>
                      <Typography variant="subtitle1">
                        {listingDetails.contactName}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        className={classes.contactNumber}
                      >
                        <Smartphone />
                        {formatPhoneNumber(listingDetails.contactPhone)}
                      </Typography>
                    </div>
                  </div>
                )}
                {!isDesktop && !isMobile && isTablet && (
                  <div className={clsx(classes.mapWrapper, classes.tablet)}>
                    <GoogleStaticMap
                      latitude={listingDetails.latitude}
                      longitude={listingDetails.longitude}
                      mapWidth="700"
                      mapHeight="270"
                    />
                  </div>
                )}
                {!isDesktop && isMobile && (
                  <div className={clsx(classes.mapWrapper, classes.mobileMap)}>
                    <GoogleStaticMap
                      latitude={listingDetails.latitude}
                      longitude={listingDetails.longitude}
                      mapWidth="335"
                      mapHeight="335"
                    />
                  </div>
                )}
              </div>
            </div>
          </Box>
        </Grid>
      </Grid>
      {listingDetails.dataSet && GLOBALS.MLS_INFO[listingDetails.dataSet] && (
        <div>
          <div className={classes.mlsDescription}>
            This listing was provided by the{" "}
            {GLOBALS.MLS_INFO[listingDetails.dataSet].name}.{" "}
            {GLOBALS.MLS_INFO[listingDetails.dataSet].disclosures.replace(
              "[year]",
              getCurrentYear()
            )}
            .{" "}
          </div>
          <div className={classes.partnerInfo}>
            For more information contact our broker partner:{" "}
            {GLOBALS.MLS_INFO[listingDetails.dataSet].partnerName}{" "}
            {GLOBALS.MLS_INFO[listingDetails.dataSet].partnerPhone}
          </div>
        </div>
      )}
    </>
  );
}

export default ListingDetails;
