import React from "react";
import Iframe from "react-iframe";
import { makeStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import { GLOBALS } from "../App";

const useStyles = makeStyles(theme => ({
  iframe: {
    height: "10000px",
    border: "0",
  },
}));

export default function TermsOfUsePage() {
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>{GLOBALS.META_TAGS.TITLE.TERMS_OF_USE_PAGE}</title>
      </Helmet>
      <Iframe
        url="https://app.termly.io/document/terms-of-use-for-website/2dba6362-286a-4354-9218-be8197216520"
        width="100%"
        className={classes.iframe}
        id="privacyPolicy"
        display="initial"
        position="relative"
      />
    </>
  );
}
