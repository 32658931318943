import React, { useEffect } from "react";
import { Container } from "@material-ui/core";
import TenantHowItWorks from "../components/tenant/TenantHowItWorks";
import useAuth0Nesitqa from "../hooks/useAuth0Nestiqa";
import { GLOBALS } from "../App";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getActiveUser } from "../store/selectors/userSelectors";

export default function TenantPage() {
  const { isAuthenticated, loginWithRedirect } = useAuth0Nesitqa();
  const activeUser = useSelector(getActiveUser);
  const history = useHistory();

  useEffect(() => {
    if (isAuthenticated && activeUser !== null && activeUser.tenantId) {
      history.push(`/tenant/${activeUser.tenantId}/profile`);
    }
  }, [isAuthenticated, activeUser, history]);

  function handleLogin() {
    localStorage.setItem("previousLocation", window.location.pathname);
    loginWithRedirect();
  }

  return (
    <Container>
      <Helmet>
        <title>{GLOBALS.META_TAGS.TITLE.TENANT_PAGE}</title>
      </Helmet>
      <TenantHowItWorks login={handleLogin}></TenantHowItWorks>
    </Container>
  );
}
