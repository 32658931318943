import React from "react";
import PropTypes from "prop-types";
// import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";

// const useStyles = makeStyles({
//   avatar: {
//     backgroundColor: blue[100],
//     color: blue[600],
//   },
// });

export default function EditImageDialog(props) {
  //   const classes = useStyles();
  const { onClose, open } = props;

  const handleClose = () => {
    onClose();
  };

  //   const handleListItemClick = (value) => {
  //     onClose(value);
  //   };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="edit-image-dialog-title"
      open={open}
    >
      <DialogTitle id="edit-image-dialog-title">Edit Image</DialogTitle>
      <Typography variant="subtitle2">
        This feature is not implemented yet
      </Typography>
    </Dialog>
  );
}

EditImageDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  //   selectedValue: PropTypes.string.isRequired,
};
