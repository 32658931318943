import { useState, useEffect } from "react";
import { GLOBALS } from "../App";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getSearchFilters } from "../store/selectors/listingsSelectors";
import { setSearchFilters } from "../store/actions/listingsActions";
import { PageSize } from "../pages/ListingsPage";

function useListings() {
  const dispatch = useDispatch();
  const searchFilters = useSelector(getSearchFilters);

  const [listings, setListings] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [pagination, setPagination] = useState({
    totalCount: 0, // total count of data available from source
    currentPage: 1, // current active page
    pageSize: PageSize,
  });

  const handlePaginationChange = (e, value) => {
    dispatch(
      setSearchFilters({
        ...searchFilters,
        page: value,
      })
    );
    setPagination(pre => ({
      ...pre,
      currentPage: value,
    }));
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  async function searchListings(filters) {
    try {
      let response = await fetch(buildFetchUrl(filters), {
        method: "get",
        headers: {
          "Content-Type": "text/plain",
        },
      });
      if (response.ok) {
        let body = await response.json();
        return body;
      } else {
        setError(response.status + " " + response.statusText);
        setLoading(false);
        return [];
      }
    } catch (e) {
      setError(e);
      setLoading(false);
      return null;
    }
  }

  const history = useHistory();

  useEffect(() => {
    let mounted = true;
    if (searchFilters) {
      searchListings(searchFilters).then(({ count, results }) => {
        if (mounted) {
          const params = new URLSearchParams();
          Object.keys(searchFilters).forEach(key => {
            params.set(key, searchFilters[key]);
          });

          history.push({ pathname: "/listings", search: params.toString() });

          setListings(results);
          setPagination({
            currentPage: pagination.currentPage,
            totalCount: count,
            pageSize: PageSize,
          });
          setLoading(false);
        }
      });
    }

    return () => (mounted = false);
  }, [searchFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    listings,
    loading,
    error,
    pagination,
    handlePaginationChange,
  };
}

export default useListings;

export function buildFetchUrl(filters) {
  let fetchUrl = GLOBALS.API_BASE_URL + GLOBALS.PATHS.LISTING_SEARCH;
  for (const [key, value] of Object.entries(filters)) {
    fetchUrl += key + "=" + value + "&";
  }
  const encodedUrl = fetchUrl.replace(" ", "%20");
  return encodedUrl.slice(0, -1);
}
