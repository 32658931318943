import React from "react";

const EditIcon = props => {
  return (
    <svg
      id="edit"
      xmlns="true"
      width="15.012"
      height="15.48"
      viewBox="0 0 15.012 15.48"
      {...props}
    >
      <g id="Group_193" transform="translate(0 14.03)">
        <g id="Group_192" transform="translate(0)">
          <rect id="Edit-Icon" width="15.012" height="1.449" fill="#fff" />
        </g>
      </g>
      <g id="Group_195" transform="translate(1.501)">
        <g id="Group_194" transform="translate(0)">
          <path
            id="Edit-Icon-Underline"
            d="M73.6.514,64.338,9.456,64,13.1l4.015-.282,9.262-8.942Z"
            transform="translate(-64 -0.514)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};

export default EditIcon;
