import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { formatter } from "../../utils/common";
import {
  getLabel,
  getTooltip,
  getOpenApplicationStatus,
} from "../../nestiqaUtils";
import { makeStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Tooltip,
  ClickAwayListener,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import bedIcon from "../../static/images/bed_icon.png";
import bathIcon from "../../static/images/bath_icon.png";
import { Room } from "@material-ui/icons";
import InfoIcon from "@material-ui/icons/Info";
import { GLOBALS } from "../../App";

const useStyles = makeStyles(theme => ({
  summaryWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    flexDirection: "column",

    "& div > .MuiTypography-root": {
      fontWeight: 500,
    },
  },
  address: {
    display: "flex",
    marginLeft: "-5px",

    "& svg": {
      color: theme.palette.secondary.main,
      marginRight: "5px",
    },
  },
  row: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    paddingBottom: theme.spacing(1),

    "& div:first-child p:first-child": {
      paddingBottom: "2px",
    },

    "& div:nth-child(2)": {
      flexBasis: "47%",
    },
  },
  summary: {
    width: "100%",
    padding: 0,

    "& .MuiAccordionSummary-content": {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },

    "& .MuiAccordionSummary-root": {
      padding: 0,
    },
  },
  details: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    paddingLeft: 0,
    paddingRight: 0,
  },
  accordian: {
    boxShadow: "none",

    "&:not(:first-child)": {
      border: "none",
      borderTop: "2px solid",
      borderColor: theme.palette.secondary.main,
    },
  },
  alignIcon: {
    display: "flex",
    justifyContent: "flex-end",
  },
  bedsBaths: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "-4.5px",

    "& div:not(:first-child)": {
      margin: "0 4px",
    },

    "& div:first-child": {
      flexGrow: 1,
      marginRight: "4px",
    },

    "& div:nth-child(3)": {
      flexGrow: 1,
    },

    "& > div": {
      "& span": {
        margin: "0 5px",
        fontSize: "13px",
      },

      "& img": {
        width: "20px",
      },
    },
  },
  status: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    fontSize: "12px",

    "& span": {
      backgroundColor: "#FBF7EC",
      color: "#D89A3D",
      borderRadius: "5px",
      padding: "2px 8px",
    },
  },
  actionWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "& a": {
      color: theme.palette.primary.main,
      fontSize: "13px",
    },

    "& svg": {
      fontSize: "28px",
    },
  },
  requiredAction: {
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",

    "& svg": {
      marginLeft: "3px",
    },
  },
  statusWrapper: {
    width: "50%",
  },
  smallText: {
    fontSize: "13px",
  },
  iconWrapper: {
    minWidth: "28px",
    maxWidth: "28px",
    marginRight: "-5px",
  },
}));

const HouseholdApplicationAccordion = ({ application }) => {
  const classes = useStyles();
  const history = useHistory();
  const [expanded, setExpanded] = useState(false);
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleChange = id => (event, isExpanded) => {
    setExpanded(isExpanded ? id : false);
  };

  const handleClick = application => {
    return history.push(`/application/${application.id}/join`);
  };

  const rowAction = GLOBALS.REQUIRED_ACTIONS.find(
    ({ status }) => status === "JOIN_HOUSEHOLD"
  );

  return (
    <Accordion
      expanded={expanded === application.id}
      key={application.id}
      className={classes.accordian}
      onChange={handleChange(application.id)}
    >
      <AccordionSummary id={application.id} className={classes.summary}>
        <div
          className={classes.summaryWrapper}
          onClick={() => handleClick(application)}
        >
          <div className={classes.address}>
            <Room />
            <div>
              <Typography variant="body2">
                {application.listing.address1}
                {application.listing.address2 !== ""
                  ? `, ${application.listing.address2}`
                  : null}
              </Typography>
              <Typography variant="body2">
                {application.listing.city}, {application.listing.state},{" "}
                {application.listing.zipCode}
              </Typography>
            </div>
          </div>
        </div>
        <div className={classes.alignIcon}>
          {expanded === application.id ? (
            <ExpandLessIcon />
          ) : (
            <ExpandMoreIcon />
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <div className={classes.row} onClick={() => handleClick(application)}>
          <div>
            <Typography variant="body2">
              <strong>Info</strong>
            </Typography>
            <Typography variant="body2" className={classes.smallText}>
              {formatter.format(application.listing.rent)}
            </Typography>
            <div className={classes.bedsBaths}>
              <div>
                <span>
                  {getLabel(application.listing.bedrooms, "BEDROOMS")}
                </span>
              </div>
              <div>
                <img src={bedIcon} alt="bedrooms" />
              </div>
              <div>
                <span>
                  {getLabel(application.listing.bathrooms, "BATHROOMS")}
                </span>
              </div>
              <div>
                <img src={bathIcon} alt="bathrooms" />
              </div>
            </div>
          </div>
          <div>
            <Typography variant="body2">
              <strong>Submitted By</strong>
            </Typography>
            <Typography variant="body2" className={classes.smallText}>
              {application.tenantName}
            </Typography>
          </div>
        </div>
        <div className={classes.row}>
          <div
            className={classes.statusWrapper}
            onClick={() => handleClick(application)}
          >
            <Typography variant="body2">
              <strong>Status</strong>
            </Typography>
            <div className={classes.status}>
              <span>
                {getOpenApplicationStatus(
                  application.status,
                  application.tenantName
                )}
              </span>
            </div>
          </div>
          <div>
            <div className={classes.actionWrapper}>
              <Typography
                className={classes.requiredAction}
                variant="body2"
                onClick={() => handleClick(application)}
              >
                <strong>Required Action</strong>
              </Typography>

              <ClickAwayListener onClickAway={handleTooltipClose}>
                <Tooltip
                  onClose={handleTooltipClose}
                  open={open}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={getTooltip(
                    rowAction,
                    application.comment,
                    application.tenantName
                  )}
                >
                  <div
                    className={classes.iconWrapper}
                    onClick={() => setOpen(!open)}
                  >
                    <InfoIcon color="secondary" />
                  </div>
                </Tooltip>
              </ClickAwayListener>
            </div>

            <span
              className={classes.actionLink}
              onClick={() => handleClick(application)}
            >
              {rowAction.label}
            </span>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default HouseholdApplicationAccordion;
