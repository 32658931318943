import { isBefore, isEqual } from "date-fns";
import { ReactComponent as DefaultIcon } from "./../static/icons/file-solid.svg";
import { ReactComponent as WordIcon } from "./../static/icons/file-word-solid.svg";
import { ReactComponent as PowerPointIcon } from "./../static/icons/file-powerpoint-solid.svg";
import { ReactComponent as ExcelIcon } from "./../static/icons/file-excel-solid.svg";
import { ReactComponent as ImageIcon } from "./../static/icons/file-image-solid.svg";
import { ReactComponent as PdfIcon } from "./../static/icons/file-pdf-solid.svg";

export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const capitalize = string => {
  const str = string;

  const returnValue = str.replace(
    /(\w)+/g,
    w => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase()
  );

  return returnValue;
};

export const verbiage = {
  ERROR_COMPLETING_ACTION:
    "Failed to @Action listing, please try again later or contact support@nestiqa.com for assistance.",
};

const determineAction = type => {
  switch (type) {
    case "ACTIVATE":
      return "activate";
    case "DEACTIVATE":
      return "deactivate";
    case "PUBLISH":
      return "publish";
    case "RENEW":
      return "renew";
    default:
      return;
  }
};

/**
 * Returns templates, but dynamically populates the action
 * @param actionType
 * @returns {{ "@Action": string }}
 */
const replacementMappings = action => ({
  "@Action": determineAction(action),
});

const replaceTemplateWithString = (
  templateToReplace,
  replacementString,
  newValue
) => {
  if (Array.isArray(templateToReplace)) {
    for (const i in templateToReplace) {
      if (typeof templateToReplace[i] === "string") {
        templateToReplace[i] = templateToReplace[i].replace(
          replacementString,
          newValue
        );
      }
    }
  } else if (typeof templateToReplace === "string") {
    templateToReplace = templateToReplace.replace(replacementString, newValue);
  }
  return templateToReplace;
};

const replaceTemplateString = (content, replacementString, replacementVal) => {
  if (typeof replacementVal === "string") {
    content = replaceTemplateWithString(
      content,
      replacementString,
      replacementVal
    );
  }
  return content;
};

/**
 *
 * @param actionType
 * @returns text array of JSX
 */
export const replaceTemplateKeywords = type => {
  return text => {
    const mappings = {
      ...replacementMappings(type),
    };
    for (const replacementString in mappings) {
      text = replaceTemplateString(
        text,
        replacementString,
        mappings[replacementString]
      );
    }
    return text;
  };
};

/**
 * @params number, word in singular, word in plural
 * @returns string modified to either singular or plural noun
 * @desc Takes in a number of units, singular version, and plural version
 * of the word. If the number is 1, returns the singular word.
 * If the number is > 1 returns the plural word.
 */
export const formatWordPlurality = (number, singular, plural) => {
  if (typeof singular !== "string" && typeof plural !== "string") return "";
  const firstString = singular.charAt(0).toUpperCase() + singular.slice(1);
  const secondString = plural.charAt(0).toUpperCase() + plural.slice(1);

  if (number === 1) {
    return `${number} ${firstString}`;
  }
  return `${number} ${secondString}`;
};

// https://stackoverflow.com/a/20554416/10836189
export const sortByYesNoFieldAndDate = (array, yesNoField, dateField) => {
  return array.sort((a, b) => {
    if (a[yesNoField] === b[yesNoField]) {
      return isBefore(a[dateField], b[dateField]) ? -1 : 1;
    }
    return a[yesNoField] === "Y" ? -1 : 1;
  });
};

export const sortByYesNoFieldAndDates = (
  array,
  yesNoField,
  dateField1,
  dateField2
) => {
  return array.sort((a, b) => {
    if (a[yesNoField] === b[yesNoField]) {
      if (isEqual(a[dateField1], b[dateField1])) {
        return isBefore(a[dateField2], b[dateField2]) ? 1 : -1;
      }

      return isBefore(a[dateField1], b[dateField1]) ? 1 : -1;
    }

    return a[yesNoField] === "Y" ? -1 : 1;
  });
};

export const getFileIconFromFilename = name => {
  let extension = name.slice(name.lastIndexOf(".") + 1);

  if (["pdf"].includes(extension)) return <PdfIcon />;
  if (["doc", "docx"].includes(extension)) return <WordIcon />;
  if (["xls", "xlsx"].includes(extension)) return <ExcelIcon />;
  if (["ppt", "pptx"].includes(extension)) return <PowerPointIcon />;
  if (
    [
      "png",
      "jpg",
      "jpeg",
      "tif",
      "tiff",
      "bmp",
      "gif",
      "eps",
      "webp",
      "heic",
    ].includes(extension)
  )
    return <ImageIcon />;
  return <DefaultIcon />;
};

export const getDraftApplication = applications => {
  for (let i = 0; i < applications.length; i++) {
    const application = applications[i];

    if (application.status === "DRAFT") {
      return application;
    }
  }

  return null;
};

export const getHeadofHousehold = (occupants = [], headOfHouseholdId) => {
  let headOfHousehold;
  let otherOccupants = [];
  for (let i = 0; i < occupants.length; i++) {
    if (
      occupants[i].id === headOfHouseholdId ||
      occupants[i].isHeadOfHousehold === "Y"
    ) {
      headOfHousehold = occupants[i];
    } else {
      otherOccupants.push(occupants[i]);
    }
  }

  return { headOfHousehold, otherOccupants };
};

// https://stackoverflow.com/a/33034768/10836189

// returns elements in first array but not in second array
export const getArrayDifference = (arr1 = [], arr2 = []) => {
  let difference = arr1.filter(x => !arr2.includes(x));
  return difference;
};

// returns elements in first array and in second array
export const getArrayIntersection = (arr1 = [], arr2 = []) => {
  let intersection = arr1.filter(x => arr2.includes(x));
  return intersection;
};

export const getAddressFromPlace = place => {
  let address = {};
  let street, streetNumber;
  let comps = place.address_components;
  for (let i = 0; i < comps.length; i++) {
    if (comps[i].types.includes("street_number")) {
      streetNumber = comps[i].short_name;
    }
    if (comps[i].types.includes("route")) {
      street = comps[i].short_name;
    }
    if (comps[i].types.includes("locality")) {
      address["city"] = comps[i].short_name;
    }
    if (comps[i].types.includes("sublocality") && !address.city) {
      address["city"] = comps[i].short_name;
    }
    if (comps[i].types.includes("administrative_area_level_1")) {
      address["state"] = comps[i].short_name;
    }
    if (comps[i].types.includes("postal_code")) {
      address["zipCode"] = comps[i].short_name;
    }
  }
  if (street) {
    address["address1"] = streetNumber ? `${streetNumber} ${street}` : street;
  }
  if (place.geometry) {
    address["latitude"] = place.geometry.location.lat().toFixed(6);
    address["longitude"] = place.geometry.location.lng().toFixed(6);
  }
  return address;
};

export const checkFormikError = formikprops => {
  let errorKeys = Object.keys(formikprops.errors);
  if (errorKeys.length === 0) return false;
  let error = errorKeys.some(key => formikprops.touched[key]);
  return error;
};

export const prepareReportPageContent = result => {
  const loadingDiv = `
  <div id="loader" class="center"></div>
`;

  const loadingStyle = `
  <style>
  #loader {
    border: 8px solid #C4F5F7;
    border-radius: 50%;
    border-top: 8px solid #3CE0E5;
    width: 70px;
    height: 70px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    100% {
        transform: rotate(360deg);
    }
  }

  .center {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  </style>
`;

  const loadingScript = `
  <script>
    document.onreadystatechange = function() {
      if (document.readyState === "loading") {
        document.querySelector(
          "body").style.visibility = "hidden";
        document.querySelector(
          "#loader").style.visibility = "visible";
      } else {
        document.querySelector(
          "#loader").style.display = "none";
        document.querySelector(
          "body").style.visibility = "visible";
      }
    };
  </script>
`;

  let headEnd = result.search("</head>");

  let content =
    result.slice(0, headEnd) +
    loadingStyle +
    loadingScript +
    "</head>" +
    loadingDiv +
    result.slice(headEnd + 7);

  return content;
};
