import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { format } from "date-fns";
import { formatter } from "../../utils/common";
import StyledTableCell from "../StyledTableCell";
import { GLOBALS } from "../../App";
import { formatPhoneNumber } from "../../nestiqaUtils";

const useStyles = makeStyles(theme => ({
  table: {
    width: "100%",
  },
  tableHead: {
    border: "none",
    paddingRight: theme.spacing(1),
  },
  tableBody: {
    border: "none",
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
}));

const TenantEmploymentHistoryTable = ({ employmentHistory }) => {
  const classes = useStyles();

  return (
    <>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <StyledTableCell className={classes.tableHead}>
              Dates
            </StyledTableCell>
            <StyledTableCell className={classes.tableHead}>
              Position
            </StyledTableCell>
            <StyledTableCell className={classes.tableHead}>
              Employer Address
            </StyledTableCell>
            <StyledTableCell className={classes.tableHead}>
              Contact Name
            </StyledTableCell>
            <StyledTableCell className={classes.tableHead}>
              Contact Phone
            </StyledTableCell>
            <StyledTableCell className={classes.tableHead} align="right">
              Annual Salary
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {employmentHistory.map((history, i) => (
            <TableRow key={history.id}>
              <StyledTableCell className={clsx(classes.tableBody)}>
                {format(new Date(history.startDate), GLOBALS.DATE_FORMAT) +
                  " - "}
                {history.isCurrent === "Y"
                  ? "Current"
                  : history.endDate
                  ? format(new Date(history.endDate), GLOBALS.DATE_FORMAT)
                  : ""}
              </StyledTableCell>
              <StyledTableCell className={clsx(classes.tableBody)}>
                {history.jobTitle || "-"} at {history.employer || "-"}
              </StyledTableCell>
              <StyledTableCell className={clsx(classes.tableBody)}>
                {history.address || "-"}
              </StyledTableCell>
              <StyledTableCell className={clsx(classes.tableBody)}>
                {history.contactName || "-"}
              </StyledTableCell>
              <StyledTableCell className={clsx(classes.tableBody)}>
                {history.contactPhoneNumber
                  ? formatPhoneNumber(history.contactPhoneNumber)
                  : "-"}
              </StyledTableCell>
              <StyledTableCell
                className={clsx(classes.tableBody)}
                align="right"
              >
                {formatter.format(history.annualSalary)}
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default TenantEmploymentHistoryTable;
