import * as Sentry from "@sentry/react";
import GA4React from "ga-4-react";
import { GLOBALS } from "../App";

const initGa4React = async () => {
  if (!GLOBALS) return Sentry.captureMessage("Failed to start Ga4");

  const ga4react = new GA4React(GLOBALS.ANALYTICS_MEASUREMENT_ID);

  try {
    await ga4react.initialize();
    ga4react.gtag("event", "ga4_init", {});
  } catch (err) {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("bot") > -1) {
      Sentry.captureMessage(`GA4 initialization error: ${err}`);
      console.error("logEvent error: ", err);
    }
  }
};

export const logEvent = async (action = "", args = {}) => {
  const ga = GA4React.getGA4React();

  try {
    return ga.gtag("event", action, { ...args });
  } catch (err) {}
};

export default initGa4React;
