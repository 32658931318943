import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useParams, useHistory } from "react-router-dom";
import { Typography, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { GLOBALS } from "../App";

import { getLabel } from "../nestiqaUtils";

import { Helmet } from "react-helmet";
import useLandlordApplicationWithHousehold from "../hooks/useLandlordApplicationWithHousehold";
import LandordAppListingInfo from "../components/landlord-application/LandordAppListingInfo";
import LandlordAppInfo from "../components/landlord-application/LandlordAppInfo";
import LandlordAppHousehold from "../components/landlord-application/LandlordAppHousehold";
import LandlordAppTenantProfile from "../components/landlord-application/LandlordAppTenantProfile";

const useStyles = makeStyles(theme => ({
  root: {},
  textBoxCol1: {
    minWidth: "120px",
  },
  textBoxCol2: {
    minWidth: "100px",
  },
  primaryColor: {
    color: theme.palette.primary.main,
  },
  infoGrid: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "grid",
    gap: theme.spacing(6),
    gridTemplateColumns: "1fr 1fr",
    gridTemplateAreas: `
    "status actions"
    "listing app"
    `,
  },
  status: {
    gridArea: "status",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
  },
  statusLabel: {
    color: theme.palette.secondary.main,
    fontWeight: "bold",
    backgroundColor: "#f5f5f5",
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`,
    borderRadius: "3px",
  },
  actions: {
    gridArea: "actions",
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    "& > *": {
      marginLeft: theme.spacing(2),
    },
  },
  listing: {
    gridArea: "listing",
    width: "100%",
  },
  app: {
    gridArea: "app",
    width: "100%",
  },
  moreIconBtn: {
    border: `2px solid ${theme.palette.primary.main}`,

    "&:disabled": {
      borderColor: theme.palette.grey[400],
    },
  },
  inviteBtn: {
    minWidth: "150px",
  },
  pageBreakAfter: {
    pageBreakAfter: "always",
  },
}));

export default function LandlordAppPrintPage() {
  const { applicationId } = useParams();
  const applicationResult = useLandlordApplicationWithHousehold(applicationId);
  const classes = useStyles();
  const history = useHistory();
  const [app, setApp] = useState();

  useEffect(() => {
    if (applicationResult.status !== "COMPLETE") return;

    setApp(applicationResult.application);
  }, [applicationResult]);

  useEffect(() => {
    let timeout;
    if (app) timeout = setTimeout(window.print, 2500);

    return () => {
      clearTimeout(timeout);
    };
  }, [app]);

  if (applicationResult.error) {
    console.error(applicationResult.error);
    return (
      <Typography variant="h5" align="center" color="error">
        {GLOBALS.MESSAGES.APPLICATION_NOT_FOUND} {applicationId}
      </Typography>
    );
  }

  if (!app) {
    return (
      <Typography variant="h5" align="center" color="primary">
        Loading...
      </Typography>
    );
  }

  const onListingClick = () => {
    history.push(`/landlord/listing/${app.listing.listingNumber}`);
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Helmet>
          <title>
            {GLOBALS.META_TAGS.TITLE.LANDLORD_APPLICATION_PAGE +
              app.listing.fullAddress}
          </title>
        </Helmet>
        <div className={clsx(classes.infoGrid)}>
          <div className={classes.status}>
            <Typography variant="body1" align="left" color="textPrimary">
              <strong>
                {getLabel(app.action, "APP_ACTIONS") + ` #${app.id}`}
              </strong>
            </Typography>
          </div>
          <div className={classes.actions}></div>
          <div className={classes.listing}>
            <LandordAppListingInfo
              listing={app.listing}
              onClick={onListingClick}
            />
          </div>
          <div className={classes.app}>
            <LandlordAppInfo
              coverLetter={app.coverLetter}
              moveInDate={app.moveInDate}
              applicantName={app.tenant.fullName}
              isPrintPage
            />
          </div>
        </div>
        <div className={classes.pageBreakAfter}>
          <LandlordAppHousehold household={app.household} isPrintPage />
        </div>
        {app.household.map(occupant => {
          if (occupant.hasUser === "N") return null;
          return (
            <div key={occupant.id} className={classes.pageBreakAfter}>
              <LandlordAppTenantProfile
                selectedHousehold={occupant}
                applicationId={app.id}
                appAction={app.action}
                isPrintPage
              />
            </div>
          );
        })}
      </Container>
    </div>
  );
}
