export const MESSAGES = {
  APPLY_PROMO_CODE: "Apply gift card or promotion code:",
  APPLY_CODE_ERROR: "Failed to apply code.",
  APPLY_CODE_SUCCESS: "Code applied successfully",
  ENTER_CARD_TO_UPGRADE: "Check out with credit card:",
  UPGRADE_SUCCESSFUL: "Plan upgraded successfully",
  UPGRADE_SUCCESSFUL_APPLY:
    "Upgrade Successful. You may now apply to any listing in the site",
  UPGRADE_SUCCESSFUL_DRAFT:
    "Upgrade Successful. You may now continue your application",
  APPLICATION_SUCCESSFUL:
    "Your data was submitted successfully and sent to the landlord for review. " +
    "You may use your tenant profile to apply for additional properties listed on " +
    "NestIQa. Good luck with your search!",
  PRE_QUALIFY_OR_UPGRADE:
    "Your current plan allows you to get pre-approved to a listing. In order to apply you need to upgrade your plan.",
  COMPLETE_TENANT_PROFILE:
    "Please complete your profile to submit the application",
  CANCEL_APPLICATION:
    "Please confirm you would like to cancel your application. Your tenant profile is " +
    "saved in the system and you'll be able to use it for future applications.",
  ERROR_CONTACT_SUPPORT:
    "We're sorry! Something went wrong. Please contact support@nestiqa.com for details.",
  FIELD_REQUIRED: "This field cannot be empty",
  PLEASE_SELECT: "Please select",
  NOT_ONLY_WHITESPACE: "This field cannot contain only whitespaces.",
  ONLY_ALPHA: "This field can only contain letters",
  FORBIDDEN_CHARACTERS:
    "Asterisk, Greater Than, Less Than, Equals, Ampersand are not allowed.",
  COMPANY_NAME_ERROR: "Please enter a valid Company Name",
  SSN_ERROR: "Please enter a valid Social Security Number",
  VERIFY_SSN_ERROR: "Values do not match",
  UNDERAGE_COSIGNER: "Person under age of 18 cannot be a Co Signer",
  ONE_OF_COMPANY_OR_NAME_REQUIRED:
    "Please enter First Name & Last Name or Company Name.",
  FIELD_MAX_LENGTH: "This field has a character limit of ",
  ENTER_VALID_PHONE: "Please enter a valid phone number",
  ENTER_VALID_EMAIL: "Please enter a valid email address",
  ENTER_VALID_DATE: "Please enter a valid date",
  ENTER_VALID_STATE: "Please enter a valid US State",
  ENTER_VALID_ZIP: "Please enter a valid US zip code",
  ENTER_VALID_BEDROOM: "Please enter a valid bedroom choice",
  ENTER_VALID_BATHROOM: "Please enter a valid bathroom choice",
  ENTER_VALID_RENT: "Please enter a positive integer rent value",
  ENTER_VALID_SQFT: "Square feet must be a positive integer",
  TIMEOUT_ERROR: "Request timed out",
  ERROR_CREATING_LISTING: "Could not create listing",
  ERROR_COMPLETING_ACTION:
    "Could not complete action. Please try again or contact support@nestiqa.com.",
  ERROR_ADDING_IMAGES:
    "Something went wrong, some images may have failed to upload",
  END_DATE_OR_CURRENT_REQUIRED:
    "Please enter an end date or mark the job as current",
  END_DATE_BEFORE_START_DATE: "End date can not be before start date",
  TENANT_NOT_FOUND: "Tenant could not be retreived. ID:",
  TENANT_INCOMPLETE_REPORTS:
    "Please complete your tenant profile to generate reports",
  LANDLORD_NOT_FOUND: "Landlord info could not be retreived.",
  INVITATION_NOT_FOUND: "Invitation info could not be retreived.",
  COMPLETE_LANDLORD_INFO:
    "Please complete your landlord information to create a listing",
  APPLICATION_NOT_FOUND: "Application could not be retreived. ID: ",
  ERROR_UPDATING_TENANT:
    "Error updating tenant. Please try again or contact support@nestiqa.com.",
  ERROR_UPDATING_LANDLORD:
    "Error updating landlord. Please try again or contact support@nestiqa.com.",
  LISTING_NOT_FOUND: "Listing could not be retrieved. Listing Number: ",
  LISTING_INACTIVE: "This listing is currently inactive",
  LISTING_NOT_COMPLETE:
    "Listing information is incomplete. Cannot show preview",
  LISTING_UPDATE_FAILED:
    "Failed to update listing. Please make sure the listing information is full and try again.",
  PUBLISH_SUCCESS: "Listing was published successfully",
  NO_APPLICATIONS: "You have no applications",
  NO_LANDLORD_LISTINGS: "You do not have any active listings",
  NO_LANDLORD_PROPERTIES: "Add a property to screen your tenants",
  LANDLORD_JUST_WANT_TO_SCREEN:
    "Just want to screen your tenants? Add a property without listing it",
  NO_ITEMS: "No items to show",
  ERROR_MY_LISTINGS: "Error - could not retreive listings",
  ERROR_LISTING_APPLICATIONS: "Error - could not retreive listing applications",
  ADD_HOUSEHOLD:
    'Please add any additional occupants in your household and click "Next" when done.',
  ADD_DOCUMENTS_1:
    "Here you may add any documents to support the information you have provided in your profile, such as Driver’s License, Pay stubs, bank statements or tax returns.",
  ADD_DOCUMENTS_2:
    "IMPORTANT: Do not share sensitive personal information such as your social security # in the documents you provide. Please make sure to conceal sensitive information the documents prior to upload.",
  ONLY_OCCUPANT: "I am the only occupant in my household",
  ADD_HOUSEHOLD_ERROR: "Please add household members or check this box",
  ADD_HOUSING_HISTORY:
    "Please add any addresses you lived at in the past 3 years.",
  NO_PREVIOUS_ADDRESS: "I have no previous address to provide",
  ADD_HOUSING_HISTORY_ERROR: "Please add housing history or check this box",
  NO_CURRENT_ADDRESS_ERROR: "You must provide your current address",
  MULTIPLE_CURRENT_ADDRESS_ERROR:
    "You cannot have more than one current address",
  ADD_EMPLOYMENT_HISTORY:
    "Please add any jobs you worked at in the past 2 years and any additional recurring income you currently have.",
  NO_EMPLOYMENT_HISTORY: "I have no income",
  ADD_EMPLOYMENT_HISTORY_ERROR:
    "Please add employment history or check this box",
  LOGIN_REQUIRED: "You must be logged in to apply",
  DOCUMENT_UPDATE_FAILED: "Failed to update document.",
  DOCUMENT_CREATE_FAILED: "Some of the files failed to upload.",
  DOCUMENT_DELETE_FAILED: "Failed to delete document.",
  DOCUMENT_NOT_FOUND: "No Documents found for tenant.",
  DOCUMENT_DOWNLOAD_FAILED: "Failed to retrive document.",
  WELCOME_MESSAGE_SIGNUP:
    "Let’s create your tenant profile. This profile is visible to ONLY to you and you choose which landlords can view it. The process takes about 10-15 minutes. If you can’t fill out everything right now, don’t worry. You can always save and come back later.",
  JOIN_HOUSEHOLD_QUESTION:
    "Would you like to join the household of [from_tenant] as [to_tenant]?",
  JOIN_HOUSEHOLD_INVITATION_EXPIRED:
    "Household invitation is inactive or expired. Please ask the head of the household to create a new one.",
  ERROR_JOIN_OWN_HOUSEHOLD:
    "You are trying to join the household with the same user who sent the invitation. Please log out first. To accept the invitation, a different user has to be logged in.",
  JOIN_APPLICATION_QUESTION:
    "You are about to join application below. Your screening reports will be shared with the listing owner. Do you wish to continue?",
  HOUSEHOLD_INFORMATION:
    "You have additional adults in your household. You may want to submit the application only after all adults in their household have complete profiles, as some landlords may require it. Do you wish to continue?",
  COMPLETE_REPORTS:
    "Your plan allows you to apply, but in order to do so you have to order your screening reports first.",
  ADD_REPORTS_TO_JOIN:
    "You have to create screening reports to join an application. Please go to your tenant profile to generate the reports",
  REPORTS_LIMIT_REACHED:
    "You have reached your daily limit of shares of your reports. The system saved this draft and you may continue this application tomorrow.",
  REPORTS_LIMIT_REACHED_JOIN:
    "You have reached your daily limit of shares of your reports. Please try again tomorrow.",
  REPORTS_APPLY:
    "Your application is processing. We will notify you when it becomes available for the landlord to view. You may use your tenant profile to apply for additional properties listed on Nestiqa. Good luck with your search!",
  REMOVE_FROM_HOUSEHOLD:
    "Are you sure you want to remove [full_name] from your household? You will have to resend an invitation to add back.",
  INVITE_HOUSEHOLD_MEMBER:
    "Some landlords may require to see a full profile fo household members over 18. If [full_name] has a Nestiqa profile you can invite him/her to join your household.",
  INCOMPLETE_PROFILE: "[full_name] has an incomplete profile.",
  INVITATION_DIALOG_TEXT:
    "Invitation for [full_name] to join the household was created successfully. In order to accept, the invited tenant should follow this link:",
  LEAVE_HOUSEHOLD_CONFIRMATION:
    "Are you sure you want to leave the household? In order to rejoin you will have to be invited again by [full_name]",
  FACEBOOK_TOOLTIP_TEXT:
    "Your Facebook ID or username is in your profile page, on the browser's address bar after the .com/. Click on the Facebook icon to validate your profile links correctly.",
  INSTAGRAM_TOOLTIP_TEXT:
    "Your Instagram username is how people identify you on Instagram. Click on the Instagram icon to validate your profile links correctly.",
  TWITTER_TOOLTIP_TEXT:
    "Your Twitter username is how people identify you on Twitter. Click on the Twitter icon to validate your profile links correctly.",
  LINKEDIN_TOOLTIP_TEXT:
    "Please paste a link to your public LinkedIn profile. Click on the LinkedIn icon to validate your profile links correctly.",
  PROGRESS_BAR: {
    MANDATORY: {
      personal_info:
        "Complete all mandatory items in the personal information section.",
      background: "Complete the background section.",
      current_address:
        "Enter at least your current address in the housing history section.",
    },
    NICE_TO_HAVE: {
      household: "Complete the information of all your household members.",
      social_media: "Add at least one social media profile.",
      bio: "Fill the Bio in the personal information section.",
      documents: "Add documents.",
    },
  },
  NO_REPORTS_AVAILABLE:
    "No reports available. You must generate reports to be able to apply to a listing.",
  REPORTS_IN_PROGRESS:
    "Your reports are being generated. We will notify you when they are ready to view.",
  REPORTS_READY: "Your reports have been generated successfully",
  ACCEPT_TERMS_TEXT:
    "The reports are provided by our partner Transunion Smartmove. Please review and accept their service agreement",
  ACCEPT_TERMS_LABEL: "Accept Terms",
  PROVIDE_SSN:
    "Please provide your social security number to allow us to pull your credit report. Please type 9 digits without dashes.",
  VERIFY_SSN: "Verify your social security number",
  NEED_INFO_NOTE:
    "IMPORTANT: The system will pull sensitive credit information. If you are not [fullname] please stop now.",
  NEED_INIT:
    "The system is going to generate credit, criminal and eviction reports for you. Generating the reports will lock your tenant profile from editing for 30 days. Please make sure the information in your tenant profile is accurate. Your full name, current address, and the SSN you provided must match your credit file. Wrong information in your profile may result failure to generate the reports.",
  MANUAL_VERIFICATION_REQUIRED:
    "We are unable to verify your identity online. Please call customer support at (833) 458-6338 for assistance and provide your screening request ID: [requestId]",
  ACCEPT_TERMS_DIALOG_TEXT:
    "Nestiqa is partnering with Transunion Smartmove to provide you screening reports. Please accept the terms of our partner to create a listing",
  NEED_VERIFICATION:
    "We need to verify your identity. You will be requested to complete an identity verification quiz provided by Transunion SmartMove. Click continue when ready",
  VERIFICATION_FAILED: "Identity Verification failed",
  NO_SCREENING_REPORTS: "Screening reports not available",
  JOIN_APPLICATION_SUCCESSFUL: "You have successfully joined the application",
  SUBMITTED_APPLICATION_SUCCESSFUL: "Successfully submitted application.",
  ERROR_SUBMITTING_APPLICATIONS:
    "Could not submit all of the applications. Please try again or contact support@nestiqa.com",
  NO_PROFILE: "This tenant does not have a profile.",
  REQUEST_INFO:
    "We will send the application back to the applicant to provide more information. Please add your comment of what you need.",
  REQUEST_PROFILE:
    "This will request the applicant to provide full tenant profiles of all adults in the household. Do you wish to continue?",
  REQUEST_REPORTS:
    "This will request screening reports from all adults in the household. Do you wish to continue?",
  LOCKED_TENANT: "Your tenant is locked from editing",
  CREATE_SCREENING_REPORTS:
    "You need to create screening reports in order to apply to a listing. Would you like to do it now?",
  MAX_255_CHARS: "This field can have at most 255 characters.",
  SEARCH_PLACEHOLDER: "Enter listing ID, city, state or zip code",
  DISTANCE_FROM_PLACEHOLDER: "Enter city, state or zip code",
  ACCEPT_SUCCESS: "Successfully accepted application.",
  INVITE_TO_APPLY_SUCCESS: "Successfully invited the tenant to apply.",
  REJECT_SUCCESS: "Successfully rejected application.",
  REQUEST_INFO_SUCCESS:
    "Operation successful. Application sent back to the tenant to provide more information. You can still view it in the listing page under 'Pending Tenant'",
  REQUEST_PROFILE_SUCCESS:
    "Operation successful. Application sent back to the tenant to provide household information. You can still view it in the listing page under 'Pending Tenant'",
  REQUEST_REPORTS_SUCCESS:
    "Operation successful. Application sent back to the tenant to provide household reports. You can still view it in the listing page under 'Pending Tenant'",
  REPORTS_GENERATED_SUCCESS:
    "Your reports were generated successfully. You can view your reports in your tenant profile",
  USER_HAS_LANDLORD_MSG:
    "This system will link your user to the listings loaded from MLS. Your landlord information will be updated with your MLS data. If you created any listings within Nestiqa they would remain on your account. Do you wish to proceed?",
  VALIDATE_INFO_MSG: "Please validate your MLS Agent ID",
  VAILDATE_INFO_FAILED:
    "Agent ID Validation failed. Please try again or contact support@nestiqa.com",
  VALIDATE_COMM_MSG_PHONE:
    "The system will now send a validation code to your mobile phone ending with [phone]",
  VALIDATE_COMM_MSG_EMAIL:
    "The system will now send a validation code to your email address [email]",
  VALIDATE_COMM_NOT_AVAILABLE:
    "Communication method not available. Please contact our support team to complete the process",
  COMM_SENT_MSG: "Please enter the validation code",
  COMM_SENT_FAILED:
    "Incorrect code. Please try again or contact support@nestiqa.com",
  MANUAL_REQUIRED_MSG:
    "Operation has to be completed manually. Please contact support@nestiqa.com",
  COMPLETE_LANDLORD_EXT_INFO:
    "Please complete your landlord information in order to be able to receive applications",
  CONFIRM_LANDLORD_EXT:
    "Nestiqa contains listings loaded from the MLS. As the realtor who posted the listing you can view and manage rental applications in Nestiqa. Are you [name]?",
  LANDLORD_EXT_WELCOME:
    "Nestiqa contains listings loaded from the MLS. As the realtor who posted the listing you can view and manage rental applications in Nestiqa. Please continue only if you are [name].",
  LANDLORD_EXT_SIGN_UP:
    "You are about to view personal information of the candidates for your listings. Therefore the system will first validate you are the authorized owner. To continue, please create an account with us (takes just a few seconds).",
  POPUP_DISABLED_ERROR:
    "Could not open report. Please make sure your browser is set to allow popups and try again",
  SOCIAL_USERNAME_VALIDATION_MSG:
    "[social] Username can contain only alphanumeric characters, periods or underscores.",
  INVALID_URL_MESSAGE: "This must be a valid url.",
  PROFILE_MANDATORY_COMPLETE:
    "Your profile is ready for submitting applications. You can add some nice-to-have fields to make it better.",
  CORRECT_FORM_ERRORS: "Please correct form errors",
  NO_EMPLOYMENT_HISTORY_WARNING:
    "Please note landlords might reject your applications if you do not provide any income information",
  TIPS_FOR_STRONG_PROFILE: "For tips on how to build a strong tenant profile,",
  FIND_ANNUAL_SALARY: "To learn how to find your income,",
  ERROR_RETRIEVING_APPLICATIONS: "Could not retrieve applications.",
  EMPTY_RETRIEVING_APPLICATIONS:
    "You have no applications awaiting your reports.",
  INFO_RETRIEVING_APPLICATIONS:
    "The following applications are pending your reports. Submitting them will share your reports with the listing owner.",
  VIRTUAL_TOUR_MSG:
    "If you have a link to a video or a virtual tour, please paste it here:",
  LANDLORD_ADDRESS_MSG:
    "Please enter your company address or your personal address. This address will not be shared with any tenant. We need it for our records as you will be viewing sensitive data.",
  LOGIN_DIALOG_TITLE: "Please Log In",
  LOGIN_DIALOG_TEXT:
    "In order to [action] you need to :link. If you do not have an account please :link (takes just a few seconds)",
  LANDLORD_SCREEN_INFO_TEXT:
    "Thank you for choosing Nestiqa to screen your tenants. Please enter your info to proceed.",
  PROPERTY_CREATE_SUCCESS_MSG:
    "Property created successfully. Use one of the options below to invite applicants.",
  EMAIL_INVITE_SUCCESS: "Invitation email sent successfully",
  SMS_INVITE_SUCCESS: "Invitation text message sent successfully",
  PROPERTY_APPLY_PAGE_HEADING: "Rental Application",
  PROPERTY_APPLY_PAGE_TEXT:
    "You are currently applying to the following property:",
  NO_TENANT_DOCUMENTS: "No documents for tenant",
  NO_TENANT_HOUSING_HISTORY: "No housing history",
  NO_TENANT_EMPLOYMENT_HISTORY: "No income information provided",
  NO_PETS: "I do not have any pets",
  NO_VEHICLES: "I do not have any vehicles",
  ADD_PETS_ERROR: "Please list your pets or check this box.",
  ADD_VEHICLES_ERROR: "Please list your vehicles or check this box.",
  COMPLETE_LANDLORD_INFO_INVITE:
    "We need to collect some basic information on you to allow you to receive applications. Would you like to do it now?",
  RB_CONTACT_SUCCESS: "Your contact request was submitted successfully",
  CREATE_TENANT_TITLE: "Create Tenant Profile",
  CREATE_TENANT_MESSAGE:
    "You do not have a tenant profile. Would you like to create one now?",
  CREATE_LANDLORD_TITLE: "Create Landlord Profile",
  CREATE_LANDLORD_MESSAGE:
    "You do not have a Landlord profile. Would you like to create one now?",
  USER_TYPE_TITLE: "Welcome to Nestiqa",
  USER_TYPE_SUBTITLE: "Please let us know why you're here",
  USER_TYPE_TENANT_BUTTON: "I am a tenant looking for a home",
  USER_TYPE_LANDLORD_BUTTON: "I am a landlord or an agent",
  SAVE_SEARCH_DIALOG_TITLE: "Save This Search",
  EDIT_SEARCH_DIALOG_TITLE: "Edit Saved Search",
  SAVE_SEARCH_DIALOG_TEXT:
    "The search will be saved to your profile so that you can easily run it when you log in.",
  NEWSLETTER_DIALOG_TITLE: "Would you like to sign up to our newsletter?",
  NEWSLETTER_DIALOG_TENANT:
    "Sign up to get alerts on our best listings, helpful tips, and product updates.",
  NEWSLETTER_DIALOG_LANDLORD:
    "Sign up to get helpful tips and product updates.",
};
