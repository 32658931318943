import { useState, useEffect } from "react";
import { getTenant, getTenantApplications } from "../utils/TenantApi";
import useAuth0Nesitqa from "./useAuth0Nestiqa";

export default function useTenant(params) {
  const [results, setResults] = useState({
    status: "IDLE",
  });
  const { accessToken } = useAuth0Nesitqa("");

  function clearResults() {
    setResults({ status: "IDLE" });
  }

  useEffect(() => {
    if (!params || !accessToken) return;

    const setResultsCallback = (isSuccess, apiResult, apiError) => {
      setResults({
        status: "COMPLETE",
        tenant: isSuccess ? apiResult : null,
        error: isSuccess ? null : apiError,
        clear: clearResults,
      });
    };

    setResults({ status: "LOADING" });
    switch (params.action) {
      case "get-tenant":
        getTenant(params.tenantId, accessToken, setResultsCallback);
        break;
      case "get-tenant-applications":
        getTenantApplications(params.tenantId, accessToken, setResultsCallback);
        break;
      default:
        setResults({
          status: "COMPLETE",
          error: `${params.action} is not a valid action`,
          clear: clearResults,
        });
        break;
    }
  }, [params, accessToken]);

  return results;
}
