import React from "react";
import Box from "@material-ui/core/Box";
import LandlordApplicationsTable from "./LandlordApplicationsTable";
import LandlordApplicationsAccordion from "./LandlordApplicationsAccordion";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  box: {
    width: "calc(100% + 50px)",
    marginLeft: "-25px",
    paddingLeft: "25px",
    paddingTop: "2.5rem",
    paddingBottom: "3.5rem",

    "&:not(:first-of-type)": {
      borderTop: "2px solid black",
    },
  },
  text: {
    fontSize: "24px",
    fontWeight: 600,
  },
}));

export default function LandlordApplications(props) {
  const { applications } = props;
  const classes = useStyles();
  const history = useHistory();
  const isTabletOrAbove = useMediaQuery(theme => theme.breakpoints.up("sm"));

  if (!applications) {
    return <div />;
  }

  function goToApplication(event, applicationId) {
    event.preventDefault();
    history.push(`/landlord/application/${applicationId}`);
  }

  return (
    <Box className={classes.box}>
      {!isTabletOrAbove ? (
        <LandlordApplicationsAccordion
          onItemClick={goToApplication}
          applications={applications}
        />
      ) : (
        <LandlordApplicationsTable
          onItemClick={goToApplication}
          applications={applications}
        />
      )}
    </Box>
  );
}
