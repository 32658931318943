import React, { useMemo } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import TenantProfileSection from "./TenantProfileSection";
import TenantHousingHistoryTable from "./TenantHousingHistoryTable";
import TenantHousingHistoryAccordion from "./TenantHousingHistoryAccordion";
import { sortByYesNoFieldAndDates } from "../../utils/common";
import { Typography } from "@material-ui/core";
import { GLOBALS } from "../../App";

const TenantHousingHistorySection = ({
  housingHistory,
  isEditable = true,
  openEditDialog,
}) => {
  const onEditButtonClick = () => {
    openEditDialog(3);
  };

  return (
    <TenantProfileSection
      title="Housing History"
      onEditButtonClick={onEditButtonClick}
      isEditable={isEditable}
    >
      <HousingHistoryContent housingHistory={housingHistory} />
    </TenantProfileSection>
  );
};

export const HousingHistoryContent = ({
  housingHistory,
  isPrintPage = false,
}) => {
  const isTabletOrAbove = useMediaQuery(theme => theme.breakpoints.up("sm"));
  const sortedHistory = useMemo(() => {
    return sortByYesNoFieldAndDates(
      housingHistory,
      "isCurrent",
      "startDate",
      "endDate"
    );
  }, [housingHistory]);

  if (housingHistory.length === 0) {
    return (
      <Typography color="primary" align="center">
        {GLOBALS.MESSAGES.NO_TENANT_HOUSING_HISTORY}
      </Typography>
    );
  }

  return (
    <>
      {!isTabletOrAbove && !isPrintPage ? (
        <TenantHousingHistoryAccordion housingHistory={sortedHistory} />
      ) : (
        <TenantHousingHistoryTable housingHistory={sortedHistory} />
      )}
    </>
  );
};

export default TenantHousingHistorySection;
