import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import ListingsPage from "./pages/ListingsPage";
import ProfilePage from "./pages/ProfilePage";
import HomePage from "./pages/HomePage";
import "./App.scss";
import { initGlobals } from "./constants/globals";
import LandlordPage from "./pages/LandlordPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import TermsOfUsePage from "./pages/TermsOfUsePage";
import ListingDetailsPage from "./pages/ListingDetailsPage";
import TenantProfilePage from "./pages/TenantProfilePage";
import TenantPlanPage from "./pages/TenantPlanPage";
import UpdateListingPage from "./pages/UpdateListingPage";
import LandlordListingPage from "./pages/LandlordListingPage";
import UpdateApplicationPage from "./pages/UpdateApplicationPage";
import LandlordApplicationPage from "./pages/LandlordApplicationPage";
import CreateTenantPage from "./pages/CreateTenantPage";
import ContactUsPage from "./pages/ContactUsPage";
import TenantApplicationsPage from "./pages/TenantApplicationsPage";
import AppWrapper from "./components/AppWrapper";
import RedirectPage from "./pages/RedirectPage";
import PrintPage from "./pages/PrintPage";
import JoinHousehold from "./pages/JoinHousehold";
import ReportsPage from "./pages/ReportsPage";
import JoinApplicationPage from "./pages/JoinApplicationPage";
import UserAdministrationPage from "./pages/UserAdministrationPage";
import LandlordAppPrintPage from "./pages/LandlordAppPrintPage";
import LandlordExtPage from "./pages/LandlordExtPage";
import LandlordAllApplicationsPage from "./pages/LandlordAllApplicationsPage";
import TenantPage from "./pages/TenantPage";
import ApplicationsPendingReportsPage from "./pages/ApplicationsPendingReportsPage";
import Partnerships from "./pages/Partnerships";
import LandlordScreenPage from "./pages/LandlordScreenPage";
import LandlordScreenPropertyPage from "./pages/LandlordScreenPropertyPage";
import PropertyApplyPage from "./pages/PropertyApplyPage";
import UserTypePage from "./pages/UserTypePage";
import UserSavedSearches from "./pages/UserSavedSearches";
import TurnOffAlerts from "./pages/TurnOffAlertsPage";
import SampleReportsPage from "./pages/SampleReportsPage";

export const GLOBALS = initGlobals();

const showAdminPage =
  !GLOBALS.SENTRY_ENV ||
  GLOBALS.SENTRY_ENV === "local" ||
  GLOBALS.SENTRY_ENV === "dev";

export default function App() {
  return (
    <BrowserRouter>
      <AppWrapper className="App">
        <Switch>
          <Route path={GLOBALS.PATHS.ROOT} exact component={HomePage} />
          <Route path={GLOBALS.PATHS.LISTINGS} exact component={ListingsPage} />
          <Route
            path={GLOBALS.PATHS.REDIRECT_USER}
            exact
            component={RedirectPage}
          />
          <Route
            path={GLOBALS.PATHS.LISTING_DETAILS}
            exact
            component={ListingDetailsPage}
          />
          <Route path={GLOBALS.PATHS.PROFILE} exact component={ProfilePage} />
          <Route path={GLOBALS.PATHS.LANDLORD} exact component={LandlordPage} />
          <Route
            path={GLOBALS.PATHS.PRIVACY_POLICY}
            exact
            component={PrivacyPolicyPage}
          />
          <Route
            path={GLOBALS.PATHS.TERMS_OF_USE}
            exact
            component={TermsOfUsePage}
          />
          <Route
            path={GLOBALS.PATHS.CREATE_TENANT}
            exact
            component={CreateTenantPage}
          />
          <Route
            path={GLOBALS.PATHS.TENANT_PROFILE}
            exact
            component={TenantProfilePage}
          />
          <Route
            path={GLOBALS.PATHS.TENANT_PLAN}
            exact
            component={TenantPlanPage}
          />
          <Route
            path={GLOBALS.PATHS.TENANT_APPLICATIONS}
            exact
            component={TenantApplicationsPage}
          />
          <Route
            path={GLOBALS.PATHS.UPDATE_LISTING}
            exact
            component={UpdateListingPage}
          />
          <Route
            path={GLOBALS.PATHS.UPDATE_APPLICATION}
            exact
            component={UpdateApplicationPage}
          />
          <Route
            path={GLOBALS.PATHS.LANDLORD_APPLICATION}
            exact
            component={LandlordApplicationPage}
          />
          <Route
            path={GLOBALS.PATHS.LANDLORD_LISTING_PAGE}
            exact
            component={LandlordListingPage}
          />
          <Route
            path={GLOBALS.PATHS.CONTACT_US_PAGE}
            exact
            component={ContactUsPage}
          />
          <Route path={GLOBALS.PATHS.PRINT_PAGE} exact component={PrintPage} />
          <Route
            path={GLOBALS.PATHS.JOIN_HOUSEHOLD}
            exact
            component={JoinHousehold}
          />
          <Route
            path={GLOBALS.PATHS.REPORTS_PAGE}
            exact
            component={ReportsPage}
          />
          <Route
            path={GLOBALS.PATHS.LANDLORD_APP_PRINT_PAGE}
            exact
            component={LandlordAppPrintPage}
          />
          <Route
            path={GLOBALS.PATHS.JOIN_APPLICATION_PAGE}
            exact
            component={JoinApplicationPage}
          />
          <Route
            path={GLOBALS.PATHS.LANDLORD_EXT_PAGE}
            exact
            component={LandlordExtPage}
          />
          <Route
            path={GLOBALS.PATHS.LANDLORD_ALL_APPS_PAGE}
            exact
            component={LandlordAllApplicationsPage}
          />
          <Route
            path={GLOBALS.PATHS.APPLICATIONS_PENDING_REPORTS_PAGE}
            exact
            component={ApplicationsPendingReportsPage}
          />
          <Route
            path={GLOBALS.PATHS.PARTNERSHIPS}
            exact
            component={Partnerships}
          />
          <Route path={GLOBALS.PATHS.TENANT} exact component={TenantPage} />
          <Route
            path={GLOBALS.PATHS.LANDLORD_SCREEN_PAGE}
            exact
            component={LandlordScreenPage}
          />
          <Route
            path={GLOBALS.PATHS.LANDLORD_SCREEN_PROPERTY_PAGE}
            exact
            component={LandlordScreenPropertyPage}
          />

          <Route
            path={GLOBALS.PATHS.PROPERTY_APPLY_PAGE}
            exact
            component={PropertyApplyPage}
          />

          <Route
            path={GLOBALS.PATHS.USER_TYPE_PAGE}
            exact
            component={UserTypePage}
          />

          <Route
            path={GLOBALS.PATHS.USER_SAVED_SEARCHES_PAGE}
            exact
            component={UserSavedSearches}
          />

          <Route
            path={GLOBALS.PATHS.TURN_OFF_ALERTS_PAGE}
            exact
            component={TurnOffAlerts}
          />

          <Route
            path={GLOBALS.PATHS.SAMPLE_REPORTS_PAGE}
            exact
            component={SampleReportsPage}
          />

          {showAdminPage && (
            <Route
              path={GLOBALS.PATHS.USER_ADMINISTRATION_PAGE}
              exact
              component={UserAdministrationPage}
            />
          )}
          <Redirect to={GLOBALS.PATHS.ROOT} />
        </Switch>
      </AppWrapper>
    </BrowserRouter>
  );
}
