import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import * as yup from "yup";
import {
  Button,
  Dialog,
  DialogContentText,
  DialogContent,
  DialogActions,
  DialogTitle,
  LinearProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { GLOBALS } from "../App";
import PhoneNumberField from "./PhoneNumberField";
import useTenant from "../hooks/useTenant";
import { messageListing } from "../hooks/ListingApi";
import { getActiveUser } from "../store/selectors/userSelectors";
import { openDialog } from "../store/actions/dialogActions";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiDialog-paper": {
      width: "100%",
      maxWidth: "500px",
      padding: theme.spacing(1),
    },
    "& .MuiDialogTitle-root": {
      paddingBottom: "0px",
    },
  },
  spinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  contactForm: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "strech",

    "&:not(:last-child)": {
      paddingBottom: theme.spacing(2),
    },
  },
  inputField: {
    width: "100%",
    marginBottom: theme.spacing(2),
    marginTop: 0,

    "& .MuiInputLabel-root": {
      color: theme.palette.grey[400],
    },

    "& .MuiInputLabel-shrink": {
      color: theme.palette.primary.main,
    },
  },
  submitButton: {
    alignSelf: "center",
  },
  dialogContent: {
    paddingBottom: theme.spacing(2),
  },
  inviteText: {
    paddingBottom: theme.spacing(1),
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  linearProgress: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const ListingContactDialog = ({ open, onClose, listingNumber }) => {
  const classes = useStyles();
  const [status, setStatus] = useState("idle");

  const activeUser = useSelector(getActiveUser);

  const [tenantParams, setTenantParams] = useState();

  useEffect(() => {
    if (activeUser && activeUser.tenantId) {
      setTenantParams({
        tenantId: activeUser?.tenantId,
        action: "get-tenant",
      });
    }
  }, [activeUser]);

  const tenantResult = useTenant(tenantParams);

  const dispatch = useDispatch();

  const handleClose = () => {
    if (status === "success") {
      dispatch(openDialog("PURCHASE_INQUIRY_DIALOG"));
    }
    setStatus("idle");
    onClose();
  };

  const messageListingCallback = (isSuccess, apiResult, apiError) => {
    if (isSuccess) {
      setStatus("success");
    } else {
      setStatus("error");
      console.error(apiError);
    }
  };

  const handleSubmit = values => {
    setStatus("loading");
    messageListing(listingNumber, values, null, messageListingCallback);
  };

  const getDialogTitle = () => {
    if (status === "error") {
      return "Error";
    }

    if (status === "success") {
      return "Success";
    }

    return "Contact";
  };

  const getDialogContent = () => {
    if (status === "error") {
      return (
        <>
          <DialogContentText
            id="nesitqa-rental-beast-contact-dialog-error"
            variant="subtitle2"
          >
            {GLOBALS.MESSAGES.ERROR_COMPLETING_ACTION}
          </DialogContentText>
        </>
      );
    }

    if (status === "success") {
      return (
        <>
          <DialogContentText
            id="nesitqa-rental-beast-contact-dialog-success"
            variant="subtitle2"
          >
            {GLOBALS.MESSAGES.RB_CONTACT_SUCCESS}
          </DialogContentText>
        </>
      );
    }

    const initialValues = {
      firstName: tenantResult?.tenant?.firstName || "",
      lastName: tenantResult?.tenant?.lastName || "",
      email: tenantResult?.tenant?.email || "",
      phoneNumber: tenantResult?.tenant?.phoneNumber || "",
      body: "",
    };

    const validationSchema = yup.object().shape({
      firstName: yup
        .string()
        .min(2)
        .max(50)
        .matches(GLOBALS.LEGAL_NAME_REGEX, GLOBALS.MESSAGES.ONLY_ALPHA)
        .required(GLOBALS.MESSAGES.FIELD_REQUIRED)
        .label("First Name"),
      lastName: yup
        .string()
        .min(2)
        .max(50)
        .matches(GLOBALS.LEGAL_NAME_REGEX, GLOBALS.MESSAGES.ONLY_ALPHA)
        .required(GLOBALS.MESSAGES.FIELD_REQUIRED)
        .label("Last Name"),
      email: yup
        .string()
        .email(GLOBALS.MESSAGES.ENTER_VALID_EMAIL)
        .required(GLOBALS.MESSAGES.ENTER_VALID_EMAIL),
      phoneNumber: yup
        .string()
        .matches(GLOBALS.PHONE_NUMBER_REGEX, GLOBALS.MESSAGES.ENTER_VALID_PHONE)
        .required(),
      body: yup.string().max(255).label("Message"),
    });

    return (
      <>
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {formikprops => (
              <Form className={classes.contactForm}>
                <Field
                  className={classes.inputField}
                  label="First Name"
                  component={TextField}
                  required
                  name={`firstName`}
                  variant="outlined"
                  inputProps={{
                    autoComplete: "off",
                  }}
                />
                <Field
                  className={classes.inputField}
                  label="Last Name"
                  component={TextField}
                  required
                  name={`lastName`}
                  variant="outlined"
                  inputProps={{
                    autoComplete: "off",
                  }}
                />
                <Field
                  className={classes.inputField}
                  label="Email"
                  component={TextField}
                  required
                  name={`email`}
                  type="email"
                  variant="outlined"
                  inputProps={{
                    autoComplete: "off",
                  }}
                />
                <Field
                  className={classes.inputField}
                  component={PhoneNumberField}
                  required
                  name="phoneNumber"
                  type="text"
                  label="Phone Number"
                  variant="outlined"
                  margin="normal"
                  InputProps={{
                    autoComplete: "off",
                  }}
                />
                <Field
                  className={classes.inputField}
                  label="Message"
                  component={TextField}
                  name={`body`}
                  variant="outlined"
                  multiline
                  rows={4}
                  inputProps={{
                    autoComplete: "off",
                  }}
                />

                <Button
                  className={classes.submitButton}
                  color="primary"
                  variant="contained"
                  disabled={status === "loading"}
                  onClick={formikprops.submitForm}
                >
                  Submit
                </Button>
              </Form>
            )}
          </Formik>
        </div>
        {status === "loading" && (
          <LinearProgress className={classes.linearProgress} />
        )}
      </>
    );
  };

  return (
    <Dialog
      open={open || false}
      onClose={handleClose}
      className={classes.root}
      aria-labelledby="nestiqa-rental-beast-contact-dialog"
      aria-describedby="nesitqa-rental-beast-contact-dialog-description"
    >
      <DialogTitle id="nesitqa-rental-beast-contact-dialog-title">
        {getDialogTitle()}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {getDialogContent()}
      </DialogContent>
      {["error", "success"].includes(status) && (
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ListingContactDialog;
