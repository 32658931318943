import React, { useMemo } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { differenceInYears } from "date-fns";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import TenantProfileSection from "./TenantProfileSection";
import StyledTableCell from "../StyledTableCell";
import {
  getHeadofHousehold,
  sortByYesNoFieldAndDate,
} from "../../utils/common";
import { GLOBALS } from "../../App";
import { useState } from "react";
import NestiqaActionDialog from "../NestiqaActionDialog";
import { leaveHousehold } from "../../utils/HouseholdApi";
import useAuth0Nesitqa from "../../hooks/useAuth0Nestiqa";
import NestiqaAlertDialog from "../NestiqaAlertDialog";
import { formatPrice, getStaticImgSrc } from "../../nestiqaUtils";
import placeholderPicture from "./../tenant_picture.png";

const useStyles = makeStyles(theme => ({
  table: {
    width: "100%",
  },
  tableHead: {
    border: "none",
    padding: 0,
    paddingRight: theme.spacing(1),
  },
  tableBody: {
    border: "none",
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
    padding: 0,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  headOfHousehold: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    paddingBottom: theme.spacing(4),
  },
  headOfHouseholdheading: {
    fontWeight: 500,
    lineHeight: "1.5rem",
    fontSize: "0.875rem",
    color: theme.palette.primary.main,
    paddingBottom: theme.spacing(1),
  },
  leaveHouseholdButton: {
    marginLeft: theme.spacing(2),
  },
  picture: {
    height: "30px",
    width: "30px",
    borderRadius: "15px",
    objectFit: "cover",
    objectPosition: "center",
  },
  row: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    paddingBottom: theme.spacing(1),
  },
  summary: {
    width: "100%",
    position: "relative",

    "& .MuiAccordionSummary-content": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },

    "& .MuiAccordionSummary-root": {
      padding: 0,
    },
  },
  details: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  accordian: {
    boxShadow: "none",

    "&.MuiAccordion-root.Mui-expanded": {
      marginTop: "0px",
      paddingTop: theme.spacing(2),
    },

    "&::before": {
      display: "none",
    },

    "&:not(:last-child)": {
      border: "none",
      borderBottom: `2px solid ${theme.palette.secondary.main}`,
    },
  },
  marginLeft: {
    marginLeft: theme.spacing(4),
    width: `calc(100% - ${theme.spacing(4)}px)`,
  },
  accordianPicture: {
    position: "absolute",
    top: theme.spacing(3),
    left: theme.spacing(1),
  },
  [theme.breakpoints.down("xs")]: {
    headOfHouseholdheading: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      paddingBottom: theme.spacing(1),
    },

    leaveHouseholdButton: {
      marginLeft: "0px",
    },
  },
}));

const TenantHouseHoldSection = ({
  occupants,
  isEditable = true,
  openEditDialog,
  tenant,
  headOfHouseholdId,
}) => {
  const classes = useStyles();
  const { accessToken } = useAuth0Nesitqa();

  const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));

  const [messageDialogControls, setMessageDialogControls] = useState({
    open: false,
  });

  const [actionDialogControls, setActionDialogControls] = useState({
    open: false,
  });

  const handleError = () => {
    setMessageDialogControls({
      open: true,
      title: "Error",
      message: GLOBALS.MESSAGES.ERROR_COMPLETING_ACTION,
      onClose: () => setMessageDialogControls({ open: false }),
    });
  };

  const handleLeaveHousehold = () => {
    leaveHousehold(accessToken, (isSuccess, apiResult, apiError) => {
      if (isSuccess) {
        setActionDialogControls({ open: false });
        // Reload window to fetch tenant again
        window.location.reload();
      } else {
        console.error(apiError);
        handleError();
      }
    });
  };

  const yesNoButtonMap = [
    {
      buttonName: "No",
      handleClick: () => setActionDialogControls({ open: false }),
    },
    {
      buttonName: "Yes",
      handleClick: () => handleLeaveHousehold(),
    },
  ];

  const onEditButtonClick = () => {
    openEditDialog(2);
  };

  const { headOfHousehold, otherOccupants } = useMemo(() => {
    return getHeadofHousehold(occupants, headOfHouseholdId);
  }, [occupants, headOfHouseholdId]);

  const sortedOccupants = useMemo(() => {
    return sortByYesNoFieldAndDate(otherOccupants, "coSigner", "dob");
  }, [otherOccupants]);

  const headOfHouseholdname = `${headOfHousehold.firstName} ${headOfHousehold.lastName}`;

  const isHeadOfHousehold = tenant.id === headOfHouseholdId;

  const occupantsText = `${occupants.length} ${
    occupants.length === 1 ? "occupant" : "occupants"
  }, `;

  const cosigners = occupants.filter(occupant => occupant.coSigner === "Y")
    .length;

  const cosignersText = cosigners > 1 ? `${cosigners} cosigers, ` : "";

  const totalIncome = occupants.reduce(
    (acc, occupant) => acc + occupant.annualIncome,
    0
  );

  const totalIncomeText = `total income: ${formatPrice(totalIncome)}`;

  return (
    <TenantProfileSection
      title="Household"
      onEditButtonClick={onEditButtonClick}
      isEditable={isEditable}
      disableEdit={!isHeadOfHousehold}
    >
      <div className={classes.headOfHousehold}>
        <div className={classes.headOfHouseholdheading}>
          <span>{`Head of Hosehold: ${headOfHouseholdname}, `}</span>
          <span>{occupantsText + cosignersText + totalIncomeText}</span>
        </div>

        {!isHeadOfHousehold && isEditable && (
          <>
            <NestiqaActionDialog {...actionDialogControls} />
            <NestiqaAlertDialog {...messageDialogControls} />

            <Button
              size="small"
              className={classes.leaveHouseholdButton}
              variant="contained"
              color="primary"
              onClick={() => {
                setActionDialogControls({
                  open: true,
                  title: "Leave Household",
                  message: GLOBALS.MESSAGES.LEAVE_HOUSEHOLD_CONFIRMATION.replace(
                    "[full_name]",
                    headOfHouseholdname
                  ),
                  buttonMap: yesNoButtonMap,
                });
              }}
            >
              Leave Household
            </Button>
          </>
        )}
      </div>

      <>
        {!isMobile ? (
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell
                  className={classes.tableHead}
                ></StyledTableCell>

                <StyledTableCell className={classes.tableHead}>
                  Name
                </StyledTableCell>

                <StyledTableCell className={classes.tableHead} align="center">
                  Age
                </StyledTableCell>
                <StyledTableCell className={classes.tableHead} align="center">
                  Relationship
                </StyledTableCell>
                <StyledTableCell className={classes.tableHead} align="center">
                  Occupation
                </StyledTableCell>
                <StyledTableCell className={classes.tableHead} align="center">
                  Annual Income
                </StyledTableCell>
                <StyledTableCell className={classes.tableHead} align="right">
                  Co-Signer
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[headOfHousehold, ...sortedOccupants].map((occupant, i) => (
                <TableRow key={occupant.id}>
                  <StyledTableCell className={clsx(classes.tableBody)}>
                    <img
                      className={classes.picture}
                      src={
                        occupant.picture || getStaticImgSrc(placeholderPicture)
                      }
                      alt={occupant.firstName + " " + occupant.lastName}
                    />
                  </StyledTableCell>
                  <StyledTableCell className={clsx(classes.tableBody)}>
                    {occupant.firstName + " " + occupant.lastName}
                  </StyledTableCell>

                  <StyledTableCell
                    className={clsx(classes.tableBody)}
                    align="center"
                  >
                    {occupant.dob
                      ? `${differenceInYears(
                          new Date(),
                          new Date(occupant.dob)
                        )}y`
                      : "-"}
                  </StyledTableCell>
                  <StyledTableCell
                    className={clsx(classes.tableBody)}
                    align="center"
                  >
                    {occupant.relationship || "-"}
                  </StyledTableCell>
                  <StyledTableCell
                    className={clsx(classes.tableBody)}
                    align="center"
                  >
                    {occupant.occupation || "-"}
                  </StyledTableCell>
                  <StyledTableCell
                    className={clsx(classes.tableBody)}
                    align="center"
                  >
                    {occupant.annualIncome
                      ? formatPrice(occupant.annualIncome)
                      : "-"}
                  </StyledTableCell>
                  <StyledTableCell
                    className={clsx(classes.tableBody)}
                    align="right"
                  >
                    {occupant.coSigner === "Y" ? "Yes" : "No"}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <TenantHouseHoldTableMobile
            occupants={[headOfHousehold, ...sortedOccupants]}
          />
        )}
      </>
    </TenantProfileSection>
  );
};

export default TenantHouseHoldSection;

const TenantHouseHoldTableMobile = ({ occupants }) => {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(
    occupants.length === 1 ? occupants[0].id : false
  );
  const handleChange = occupant => (event, isExpanded) => {
    setExpanded(isExpanded ? occupant.id : false);
  };

  return (
    <>
      {occupants.map((occupant, i) => (
        <Accordion
          expanded={expanded === occupant.id}
          key={occupant.id}
          className={clsx(classes.accordian)}
          onChange={handleChange(occupant)}
        >
          <AccordionSummary id={occupant.id} className={classes.summary}>
            <img
              className={clsx(classes.picture, classes.accordianPicture)}
              src={occupant.picture || getStaticImgSrc(placeholderPicture)}
              alt={occupant.firstName + " " + occupant.lastName}
            />
            <div className={clsx(classes.row, classes.marginLeft)}>
              <Typography variant="body2">
                <strong>{occupant.firstName + " " + occupant.lastName}</strong>
              </Typography>
              <Typography variant="body2">
                <strong>{occupant.coSigner === "Y" ? "Co-Signer" : ""}</strong>
              </Typography>
            </div>

            <div className={clsx(classes.row, classes.marginLeft)}>
              <div>
                {occupant.dob
                  ? `${differenceInYears(new Date(), new Date(occupant.dob))}y`
                  : "-"}
                , {occupant.relationship || "-"}
              </div>
              <div>
                {expanded === occupant.id ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <div className={classes.row}>
              <div>
                <Typography variant="body2">
                  <strong>Occupation</strong>
                </Typography>
                <Typography variant="body2">
                  {occupant.occupation || "-"}
                </Typography>
              </div>
              <div className={classes.alignRight}>
                <Typography variant="body2">
                  <strong>Annual Income</strong>
                </Typography>
                <Typography variant="body2">
                  {occupant.annualIncome
                    ? formatPrice(occupant.annualIncome)
                    : "-"}
                </Typography>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};
