import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  TextField,
  Typography,
  Button,
  IconButton,
  MenuItem,
  Slider,
  FormGroup,
  FormControlLabel,
  Checkbox as MuiCheckbox,
} from "@material-ui/core";
import NumFormat from "./NumFormat";
import PriceFormat from "./PriceFormat";
import clsx from "clsx";
import GoogleMapsAutocomplete from "./google-maps/GoogleMapsAutocomplete";
import { GLOBALS } from "../App";
import { omit } from "lodash";
import { Clear } from "@material-ui/icons";
import { useSelector, useDispatch } from "react-redux";
import {
  setSearchFilters,
  makeFilters,
  setBeds,
  setBaths,
  setDistance as setReduxDistance,
  setDistanceFrom,
  saveLastAddress,
  setDistanceFilters,
  setHousingType,
  setMaxRent,
  setDefaultDistance,
  triggerSearch,
} from "../store/actions/listingsActions";
import {
  getAddressComponents,
  getSearchByDistanceProps,
  getAddressFilter,
  getLabel,
} from "../nestiqaUtils";
import { useLocationFilters } from "../hooks/useLocationFilters";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    width: "100%",
    height: "100%",
  },
  filtersBox: {
    position: "relative",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "0px 30px 0px 0px",
    padding: theme.spacing(2),
    width: "200px",
    flexDirection: "column",
    justifyContent: "flex-start",
    paddingTop: theme.spacing(8),
  },
  textInputs: {
    backgroundColor: "white",
    borderRadius: "10px",
    padding: "4px",
    width: "100%",
  },
  gridItem: {
    marginBottom: theme.spacing(3),
    alignSelf: "center",
    width: "90%",
    position: "relative",
  },
  activeFilters: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: "90%",
    marginBottom: "10px",

    "& $activeFiltersLink:nth-child(2)": {
      marginRight: "-8px",
    },
  },
  activeFiltersLink: {
    color: "#fff",

    "&:hover": {
      cursor: "pointer",
    },
  },
  active: {
    textDecoration: "underline",
  },
  separator: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: "90%",
    height: "auto",
    margin: "10px 0",
  },
  labels: {
    color: "white",
    marginBottom: theme.spacing(1),
  },
  slider: {
    marginTop: theme.spacing(4),
    width: "90%",
    marginLeft: theme.spacing(1),
  },
  sliderLabel: {
    color: theme.palette.secondary.main,
  },
  button: {
    backgroundColor: theme.palette.secondary.main,
    color: "white",
    width: "100%",
  },
  clearButton: {
    position: "absolute",
    right: "-0",
    top: "5px",
    padding: "4px",

    "& svg": {
      fontSize: "1.25rem",
    },
  },
  checkboxControlLabel: {
    color: "#fff",
    fontSize: "0.925rem",
  },
  checkboxRoot: {
    padding: 4,
  },
}));

const Checkbox = withStyles({
  root: {
    color: "#3CE0E5",

    "& .MuiSvgIcon-root": {
      fill: "#3CE0E5",

      "&:hover": {
        backgroundColor: "#3CE0E5",
      },
    },

    "&$checked": {
      color: "#3CE0E5",

      "& .MuiIconButton-label": {
        position: "relative",
        zIndex: 0,
      },

      "& .MuiIconButton-label:after": {
        content: '""',
        left: 4,
        top: 4,
        height: 15,
        width: 15,
        position: "absolute",
        backgroundColor: "#fff",
        zIndex: -1,
        borderColor: "transparent",
      },
    },

    "&:not($checked) .MuiIconButton-label": {
      position: "relative",
      zIndex: 0,
    },

    "&:not($checked) .MuiIconButton-label:after": {
      content: '""',
      left: 4,
      top: 4,
      height: 15,
      width: 15,
      position: "absolute",
      zIndex: -1,
      borderColor: "transparent",
    },
  },
  checked: {},
})(props => <MuiCheckbox color="default" {...props} />);

const bedroomMarks = [
  {
    value: 0,
    label: "Studio",
  },
  {
    value: 6,
    label: "6+",
  },
];

const bathroomMarks = [
  {
    value: 0,
    label: "None",
  },
  {
    value: 10,
    label: "5+",
  },
];

function getBedroomLabel(value) {
  return value === 6 ? "6+" : String(value);
}

function getBathroomLabel(value) {
  const lbl = getLabel(value, "BATHROOMS");
  return lbl === "Shared" ? "Shrd" : lbl;
}

export default function FiltersBox(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const defaultLocFilters = useLocationFilters();
  const distanceFilters = useSelector(state => state.listings.distanceFilters);
  const defaultDistance = useSelector(state => state.listings.defaultDistance);
  const distanceFrom = useSelector(state => state.listings.distanceFrom);
  const reduxFilters = useSelector(state => state.listings.filters);
  const lastAddress = useSelector(state => state.listings.lastAddress);
  const [disabled, setDisabled] = useState(false);
  const [distance, setDistance] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [activeFilter, setActiveFilter] = useState("location");
  const [searchByLocation, setSearchByLocation] = useState(true);

  useEffect(() => {}, [reduxFilters]);

  useEffect(() => {
    if (defaultLocFilters !== null) {
      dispatch(setDefaultDistance(defaultLocFilters));
    }
  }, [defaultLocFilters, dispatch]);

  useEffect(() => {
    if (activeFilter === "location") {
      setSearchByLocation(true);
    } else if (activeFilter === "distance") {
      setSearchByLocation(false);
    }
  }, [activeFilter]);

  const handleBedSliderChange = (event, newValue) => {
    dispatch(setBeds({ bd_min: newValue[0], bd_max: newValue[1] }));
  };

  const handleBathSliderChange = (event, newValue) => {
    dispatch(setBaths({ ba_min: newValue[0], ba_max: newValue[1] }));
  };

  const handleMaxRentChange = event => {
    dispatch(setMaxRent(event.target.value));
  };

  const handleHousingTypeChange = event => {
    if (event.target.value === "ANY") {
      let newFilters = reduxFilters;
      delete newFilters.htype;
      dispatch(makeFilters(newFilters));
    } else {
      dispatch(setHousingType(event.target.value));
    }
  };

  const handleDistanceChange = e => {
    setDistance(e.target.value);
    let dst = e.target.value;
    if (e.target.value === "0" || e.target.value === "") {
      dispatch(setReduxDistance(1));
    } else {
      dispatch(setReduxDistance(dst));
    }
  };

  const handleDistanceFromChange = (newPlace, newPlaceSize) => {
    if (!newPlace || !newPlace.geometry) return;

    dispatch(setDistanceFrom(inputValue.from));
    const searchByDistanceProps = getSearchByDistanceProps(
      newPlace,
      newPlaceSize
    );
    dispatch(setDistanceFilters(searchByDistanceProps));

    const comps = newPlace.address_components;
    const { address } = getAddressComponents(comps);
    dispatch(saveLastAddress(address));

    let adjustedFilters = reduxFilters;

    if (!searchByLocation) {
      // Search by distance (from input address)
      adjustedFilters = omit(reduxFilters, [
        "zc",
        "ct",
        "st",
        "frlg",
        "frlt",
        "dst",
      ]);
      dispatch(
        makeFilters({
          ...adjustedFilters,
          frlg: searchByDistanceProps.frlg,
          frlt: searchByDistanceProps.frlt,
          dst: distance ? distance : defaultDistance.dst,
        })
      );
    } else if (searchByLocation) {
      // Search by location (zip, city, state)
      if (!address.zc) {
        delete adjustedFilters.zc;
      }
      if (!address.ct) {
        delete adjustedFilters.ct;
      }
      if (!address.st) {
        delete adjustedFilters.st;
      }

      dispatch(makeFilters({ ...adjustedFilters, ...address }));
    }
  };

  async function handleSearch() {
    dispatch(triggerSearch());
    let adjustedFilters = omit(reduxFilters, ["placeSize"]);
    if (
      distanceFilters.dst &&
      distanceFilters.frlt &&
      distanceFilters.placeSize
    ) {
      adjustedFilters.dst = distanceFilters.dst + distanceFilters.placeSize;
    }

    if (!searchByLocation) {
      // Search by distance (from input address)
      adjustedFilters = omit(reduxFilters, ["zc", "ct", "st", "placeSize"]);
      if (
        !adjustedFilters.frlg ||
        adjustedFilters.frlg !== distanceFilters.frlg
      )
        adjustedFilters.frlg = distanceFilters.frlg;
      if (
        !adjustedFilters.frlt ||
        adjustedFilters.frlt !== distanceFilters.frlt
      )
        adjustedFilters.frlt = distanceFilters.frlt;
      if (!adjustedFilters.dst || distance === null) {
        adjustedFilters.dst = Number(
          defaultDistance.dst * GLOBALS.MILE_TO_KM + distanceFilters.placeSize
        );
        setDistance(defaultDistance.dst);
      }

      adjustedFilters.dst = Number(
        adjustedFilters.dst * GLOBALS.MILE_TO_KM + distanceFilters.placeSize
      );
      await dispatch(setSearchFilters({ ...adjustedFilters }));
    } else {
      // Search by location (zip, city, state)
      adjustedFilters = omit(reduxFilters, ["dst", "frlg", "frlt"]);
      const newAddr = getAddressFilter({ ...lastAddress, ...adjustedFilters });
      adjustedFilters = newAddr;

      if (!adjustedFilters || distanceFrom === "") {
        await dispatch(
          setSearchFilters({
            ...reduxFilters,
            ...defaultDistance,
          })
        );
      } else {
        delete adjustedFilters.frlg;
        delete adjustedFilters.frlt;
        delete adjustedFilters.dst;

        let locFieldEmpty = false;
        if (inputValue.from && inputValue.from.length < 1) locFieldEmpty = true;

        if (!adjustedFilters.zc && locFieldEmpty && lastAddress.zc)
          adjustedFilters.zc = lastAddress.zc;
        if (!adjustedFilters.ct && locFieldEmpty && lastAddress.ct)
          adjustedFilters.ct = lastAddress.ct;
        if (!adjustedFilters.st && locFieldEmpty && lastAddress.st)
          adjustedFilters.st = lastAddress.st;

        await dispatch(setSearchFilters({ ...adjustedFilters }));
      }
    }

    props.handleSearch(adjustedFilters);
  }

  const handleClear = () => {
    setDisabled(false);
    setDistance(null);
  };

  const getHousingTypes = () => {
    return [
      {
        value: "ANY",
        label: "Any",
      },
      ...GLOBALS.HOUSING_TYPES,
    ];
  };

  const handleCheckboxChange = e => {
    const { name, checked } = e.target;

    if (checked) {
      dispatch(makeFilters({ ...reduxFilters, [name]: "Y" }));
    } else {
      let newFilters = { ...reduxFilters };
      delete newFilters[name];
      dispatch(makeFilters({ ...newFilters }));
    }
  };

  const renderLocation = (
    <GoogleMapsAutocomplete
      className={classes.textInputs}
      size="small"
      placeholder={GLOBALS.MESSAGES.DISTANCE_FROM_PLACEHOLDER}
      popupIcon={null}
      handlePlaceChange={handleDistanceFromChange}
      onInputChange={newInputValue =>
        setInputValue({ ...inputValue, from: newInputValue })
      }
      initialValue={distanceFrom}
    />
  );

  const renderDistance = (
    <>
      <TextField
        className={classes.textInputs}
        placeholder="Miles"
        value={distance}
        onChange={handleDistanceChange}
        name="distance"
        id="formatted-distance-input"
        InputProps={{
          inputComponent: NumFormat,
        }}
        disabled={disabled}
      />
      {distance !== null && distance.length > 0 && (
        <IconButton className={classes.clearButton} onClick={handleClear}>
          <Clear />
        </IconButton>
      )}
      <div className={classes.separator} />
      <GoogleMapsAutocomplete
        className={classes.textInputs}
        size="small"
        placeholder="From"
        popupIcon={null}
        searchByLocation={searchByLocation}
        handlePlaceChange={handleDistanceFromChange}
        onInputChange={newInputValue =>
          setInputValue({ ...inputValue, from: newInputValue })
        }
        initialValue={distanceFrom}
      />
    </>
  );

  return (
    <div className={classes.root}>
      <Grid container className={clsx(classes.filtersBox, props.className)}>
        <Grid item className={classes.gridItem}>
          <Typography className={classes.labels} variant={"subtitle2"}>
            Max Rent
          </Typography>
          <TextField
            className={classes.textInputs}
            // label="Max Rent"
            placeholder="$"
            value={reduxFilters.rent_max}
            onChange={handleMaxRentChange}
            name="max-rent"
            id="formatted-numberformat-input"
            InputProps={{
              inputComponent: PriceFormat,
            }}
          />
        </Grid>
        <Grid item className={classes.gridItem}>
          <Typography className={classes.labels} variant={"subtitle2"}>
            Type
          </Typography>
          <TextField
            id="select-type"
            select
            defaultValue="ANY"
            className={classes.textInputs}
            value={reduxFilters.htype || "ANY"}
            onChange={handleHousingTypeChange}
          >
            {getHousingTypes().map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item className={classes.gridItem}>
          <Typography className={classes.labels} variant={"subtitle2"}>
            Bedrooms
          </Typography>
          <Slider
            classes={{
              root: classes.slider,
              markLabel: classes.sliderLabel,
            }}
            value={[Number(reduxFilters.bd_min), Number(reduxFilters.bd_max)]}
            getAriaValueText={getBedroomLabel}
            valueLabelFormat={getBedroomLabel}
            onChange={handleBedSliderChange}
            aria-labelledby="range-slider"
            color="secondary"
            marks={bedroomMarks}
            valueLabelDisplay="on"
            min={0}
            max={6}
          />
        </Grid>
        <Grid item className={classes.gridItem}>
          <Typography className={classes.labels} variant={"subtitle2"}>
            Bathrooms
          </Typography>
          <Slider
            classes={{
              root: classes.slider,
              markLabel: classes.sliderLabel,
            }}
            value={[Number(reduxFilters.ba_min), Number(reduxFilters.ba_max)]}
            getAriaValueText={getBathroomLabel}
            valueLabelFormat={getBathroomLabel}
            onChange={handleBathSliderChange}
            aria-labelledby="range-slider"
            color="secondary"
            marks={bathroomMarks}
            valueLabelDisplay="on"
            min={0}
            max={10}
          />
        </Grid>
        <div className={classes.activeFilters}>
          <Typography
            variant="subtitle2"
            component="a"
            className={clsx(
              classes.activeFiltersLink,
              activeFilter === "location" ? classes.active : ""
            )}
            onClick={() => setActiveFilter("location")}
          >
            Location
          </Typography>
          <Typography
            variant="subtitle2"
            component="a"
            className={clsx(
              classes.activeFiltersLink,
              activeFilter === "distance" ? classes.active : ""
            )}
            onClick={() => setActiveFilter("distance")}
          >
            Distance
          </Typography>
        </div>
        <Grid item className={classes.gridItem}>
          {searchByLocation ? renderLocation : renderDistance}
        </Grid>
        <Grid item className={classes.gridItem}>
          <FormGroup>
            <FormControlLabel
              label="Cats ok"
              classes={{ label: classes.checkboxControlLabel }}
              control={
                <Checkbox
                  classes={{ root: classes.checkboxRoot }}
                  checked={reduxFilters.ca === "Y"}
                  onChange={handleCheckboxChange}
                  name="ca"
                  color="secondary"
                />
              }
            />
            <FormControlLabel
              label="Small dogs ok"
              classes={{ label: classes.checkboxControlLabel }}
              control={
                <Checkbox
                  classes={{ root: classes.checkboxRoot }}
                  checked={reduxFilters.sd === "Y"}
                  onChange={handleCheckboxChange}
                  name="sd"
                  color="secondary"
                />
              }
            />
            <FormControlLabel
              label="Large dogs ok"
              classes={{ label: classes.checkboxControlLabel }}
              control={
                <Checkbox
                  classes={{ root: classes.checkboxRoot }}
                  checked={reduxFilters.ld === "Y"}
                  onChange={handleCheckboxChange}
                  name="ld"
                  color="secondary"
                />
              }
            />
          </FormGroup>
        </Grid>
        <Grid item className={classes.gridItem}>
          <Button className={classes.button} onClick={handleSearch}>
            Search
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
