import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

export default function SessionTimeoutDialog({
  open,
  title,
  countdown,
  onContinue,
  onLogout,
}) {
  return (
    <Dialog
      open={open || false}
      aria-labelledby="nestiqa-alert-dialog"
      aria-describedby="nesitqa-alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText
          id="nesitqa-alert-dialog-description"
          variant="subtitle2"
          style={{ minWidth: "241.7px" }}
        >
          Your session will expire in {countdown} seconds
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onContinue} color="primary">
          Keep me signed in
        </Button>
        <Button onClick={onLogout} color="primary">
          Sign Out
        </Button>
      </DialogActions>
    </Dialog>
  );
}
