import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { format } from "date-fns";
import { formatter } from "../../utils/common";
import StyledTableCell from "../StyledTableCell";
import { GLOBALS } from "../../App";
import { formatPhoneNumber } from "../../nestiqaUtils";

const useStyles = makeStyles(theme => ({
  table: {
    width: "100%",
  },
  tableHead: {
    border: "none",
    paddingRight: theme.spacing(1),
  },
  tableBody: {
    border: "none",
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
}));

const TenantHousingHistoryTable = ({ housingHistory }) => {
  const classes = useStyles();

  return (
    <>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <StyledTableCell className={classes.tableHead}>
              Dates
            </StyledTableCell>
            <StyledTableCell className={classes.tableHead}>
              Address
            </StyledTableCell>
            <StyledTableCell className={classes.tableHead}>
              Landlord
            </StyledTableCell>
            <StyledTableCell className={classes.tableHead}>
              Phone
            </StyledTableCell>
            <StyledTableCell className={classes.tableHead}>
              Reason for Leaving
            </StyledTableCell>
            <StyledTableCell className={classes.tableHead} align="right">
              Rent
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {housingHistory.map((history, i) => {
            return (
              <TableRow key={history.id}>
                <StyledTableCell className={clsx(classes.tableBody)}>
                  {format(new Date(history.startDate), GLOBALS.DATE_FORMAT) +
                    " - "}
                  {history.isCurrent === "Y"
                    ? "Current"
                    : history.endDate
                    ? format(new Date(history.endDate), GLOBALS.DATE_FORMAT)
                    : ""}
                </StyledTableCell>
                <StyledTableCell className={clsx(classes.tableBody)}>
                  {history.fullAddress || history.address1 || "-"}
                </StyledTableCell>
                <StyledTableCell className={clsx(classes.tableBody)}>
                  {history.landlordName || "-"}
                </StyledTableCell>
                <StyledTableCell className={clsx(classes.tableBody)}>
                  {history.landlordPhoneNumber
                    ? formatPhoneNumber(history.landlordPhoneNumber)
                    : "-"}
                </StyledTableCell>
                <StyledTableCell className={clsx(classes.tableBody)}>
                  {history.reasonForLeaving || "-"}
                </StyledTableCell>
                <StyledTableCell
                  className={clsx(classes.tableBody)}
                  align="right"
                >
                  {formatter.format(history.rentAmount)}
                </StyledTableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

export default TenantHousingHistoryTable;
