import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import {
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@material-ui/core";
import { format } from "date-fns";
import { GLOBALS } from "../App";
import { saveSearch, updateSavedSearch } from "../utils/UserApi";

const useStyles = makeStyles(theme => ({
  input: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  checkbox: {},
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: theme.spacing(2),
  },
  saveButton: {
    marginLeft: theme.spacing(2),
  },
  cancelButton: {},
}));

export default function SaveSearchDialog(props) {
  const classes = useStyles();
  const {
    onClose,
    open,
    savedSearchId,
    defaultSearchName,
    defaultEmailAlerts,
  } = props;

  const isEdit = Boolean(savedSearchId);

  const [searchName, setSearchName] = useState(
    Boolean(defaultSearchName)
      ? defaultSearchName
      : `My Search ${format(new Date(), GLOBALS.DATE_FORMAT)}`
  );
  const [emailAlerts, setEmailAlerts] = useState(Boolean(defaultEmailAlerts));
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    if (defaultSearchName) {
      setSearchName(defaultSearchName);
    }
  }, [defaultSearchName]);

  useEffect(() => {
    setEmailAlerts(defaultEmailAlerts);
  }, [defaultEmailAlerts]);

  const handleClose = () => {
    onClose();
    setSearchName(`My Search ${format(new Date(), GLOBALS.DATE_FORMAT)}`);
    setEmailAlerts(false);
  };

  function saveCallback(isSuccess, result, error) {
    if (isSuccess) {
      if (!isEdit) {
        props.showMessage(
          isEdit ? "Search Edited Successfully" : "Search Saved Successfully",
          isEdit
            ? ""
            : 'You can view your saved searches by clicking on the user menu and going to "My Saved Searches".'
        );
      }
      if (props.onSuccess) {
        props.onSuccess({ searchName, emailAlerts });
      }
    } else {
      props.showMessage();
    }
    handleClose();
  }

  const handleInputChange = e => {
    setSearchName(e.target.value);
    if (!e.target.value) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  };

  async function callSaveSearch() {
    if (!isValid) return;

    if (!searchName) {
      setIsValid(false);
      return;
    }

    if (isEdit) {
      updateSavedSearch(
        props.accessToken,
        savedSearchId,
        {
          searchName: searchName,
          searchParams: props.searchParams,
          emailAlerts: emailAlerts ? "Y" : "N",
        },
        saveCallback
      );
    } else {
      saveSearch(
        props.accessToken,
        {
          searchName: searchName,
          searchParams: props.searchParams,
          emailAlerts: emailAlerts ? "Y" : "N",
        },
        saveCallback
      );
    }
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="save-search-dialog-title"
      open={open}
    >
      <DialogTitle id="save-search-dialog-title">
        {isEdit
          ? GLOBALS.MESSAGES.EDIT_SEARCH_DIALOG_TITLE
          : GLOBALS.MESSAGES.SAVE_SEARCH_DIALOG_TITLE}
      </DialogTitle>
      <DialogContent>
        {!isEdit && (
          <Typography>{GLOBALS.MESSAGES.SAVE_SEARCH_DIALOG_TEXT}</Typography>
        )}
        <TextField
          className={classes.input}
          value={searchName}
          onChange={handleInputChange}
          variant="outlined"
          error={!isValid}
          helperText={!isValid ? GLOBALS.MESSAGES.FIELD_REQUIRED : ""}
          label="Search Name"
          margin="dense"
          fullWidth
        />
        <FormControlLabel
          className={classes.checkbox}
          control={
            <Checkbox
              checked={emailAlerts}
              onChange={e => setEmailAlerts(e.target.checked)}
              name="emailAlerts"
              color="primary"
            />
          }
          label="Send me email alerts for this search"
        />
        <div className={classes.buttons}>
          <Button
            className={classes.cancelButton}
            onClick={handleClose}
            variant="outlined"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            className={classes.saveButton}
            onClick={callSaveSearch}
            variant="contained"
            color="primary"
          >
            {isEdit ? "Edit" : "Save"}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

SaveSearchDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  //   selectedValue: PropTypes.string.isRequired,
};
