import { GLOBALS } from "../App";
import { runFetch, snakeToCamel } from "../nestiqaUtils";

export const getAvailableReports = async (accessToken, callback) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.REPORTS_ROOT}`,
    "get",
    { "Content-Type": "application/json" },
    null,
    accessToken,
    (isSuccess, apiResult, apiError) => {
      callback(isSuccess, snakeToCamel(apiResult), apiError);
    }
  );
};

export const resetReports = async (accessToken, callback) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.REPORTS_RESET}`,
    "delete",
    { "Content-Type": "application/json" },
    null,
    accessToken,
    callback
  );
};

export const acceptTerms = async (accessToken, callback) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.ACCEPT_TERMS}`,
    "post",
    { "Content-Type": "application/json" },
    {},
    accessToken,
    callback
  );
};

export const submitInfo = async (accessToken, ssn, callback) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.REPORTS_INFO}`,
    "put",
    { "Content-Type": "application/json" },
    JSON.stringify({ info: ssn }),
    accessToken,
    callback
  );
};

export const initReports = async (accessToken, callback) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.REPORTS_INIT}`,
    "post",
    { "Content-Type": "application/json" },
    {},
    accessToken,
    callback
  );
};

export const getExam = async (accessToken, callback) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.REPORTS_EXAM}`,
    "get",
    { "Content-Type": "application/json" },
    null,
    accessToken,
    (isSuccess, apiResult, apiError) => {
      callback(isSuccess, snakeToCamel(apiResult), apiError);
    }
  );
};

export const submitAnswers = async (accessToken, examId, answers, callback) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.REPORTS_ANSWERS}`,
    "post",
    { "Content-Type": "application/json" },
    JSON.stringify({
      exam_id: examId,
      answers: answers,
    }),
    accessToken,
    (isSuccess, apiResult, apiError) => {
      callback(isSuccess, snakeToCamel(apiResult), apiError);
    }
  );
};

export const createReports = async (accessToken, callback) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.REPORTS_ROOT}`,
    "post",
    { "Content-Type": "application/json" },
    {},
    accessToken,
    callback
  );
};

export const getReportData = async (accessToken, product, callback) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.REPORTS_ROOT}${product}/`,
    "get",
    {},
    null,
    accessToken,
    callback,
    async response => await response.text()
  );
};

export const markNotified = async (accessToken, callback) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.REPORTS_NOTIFIED}`,
    "post",
    { "Content-Type": "application/json" },
    {},
    accessToken,
    (isSuccess, apiResult, apiError) => {
      callback(isSuccess, snakeToCamel(apiResult), apiError);
    }
  );
};
