import { GLOBALS } from "../App";
import { prepareTenantResult } from "./TenantApi";
import { prepareListingResult } from "../hooks/ListingApi";
import { runFetch, snakeToCamel } from "../nestiqaUtils";

export async function createApplication(data, accessToken) {
  let returnResponseData = {};
  let fetchUrl = GLOBALS.API_BASE_URL + GLOBALS.PATHS.APPLICATION_API;
  var headers = new Headers();
  headers.append("Authorization", "Bearer " + accessToken);
  headers.append("Content-Type", "application/json");
  try {
    const requestOptions = {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    };
    await fetch(fetchUrl, requestOptions)
      .then(response => response.json())
      .then(res => (returnResponseData = res));
  } catch (e) {
    return e;
  }
  return returnResponseData;
}

export const tenantGetApplication = async (
  applicationId,
  accessToken,
  callback
) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.APPLICATION_API}${applicationId}/`,
    "get",
    { "Content-Type": "application/json" },
    null,
    accessToken,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, prepareApplicationResult(apiResult), apiError)
  );
};

export const getUserLandlordApplications = async (
  accessToken,
  action,
  callback
) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${
      GLOBALS.PATHS.LANDLORD_APPLICATION_API
    }all/${action.toLowerCase()}/`,
    "get",
    { "Content-Type": "application/json" },
    null,
    accessToken,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, prepareMultipleAppsResult(apiResult), apiError)
  );
};

export const getApplicationWithHousehold = async (
  applicationId,
  accessToken,
  callback
) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.LANDLORD_APPLICATION_API}${applicationId}/`,
    "get",
    { "Content-Type": "application/json" },
    null,
    accessToken,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, prepareApplicationResult(apiResult), apiError)
  );
};

export const getApplicationReports = async (
  applicationId,
  accessToken,
  callback
) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.LANDLORD_APPLICATION_API}${applicationId}/reports/`,
    "get",
    { "Content-Type": "application/json" },
    null,
    accessToken,
    callback
  );
};

export const approveApplication = async (
  applicationId,
  accessToken,
  callback
) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.LANDLORD_APPLICATION_API}${applicationId}/approve/`,
    "put",
    { "Content-Type": "application/json" },
    null,
    accessToken,
    callback
  );
};

export const rejectApplication = async (
  applicationId,
  accessToken,
  comment,
  callback
) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.LANDLORD_APPLICATION_API}${applicationId}/reject/`,
    "put",
    { "Content-Type": "application/json" },
    JSON.stringify({ comment: comment }),
    accessToken,
    callback
  );
};

export async function updateApplication(id, data, accessToken) {
  let returnResponseData = {};
  let fetchUrl = GLOBALS.API_BASE_URL + GLOBALS.PATHS.APPLICATION_API;
  var headers = new Headers();
  headers.append("Authorization", "Bearer " + accessToken);
  headers.append("Content-Type", "application/json");
  try {
    const requestOptions = {
      method: "PUT",
      headers: headers,
      body: JSON.stringify(data),
    };
    await fetch(fetchUrl + id + "/", requestOptions)
      .then(response => response.json())
      .then(res => (returnResponseData = res));
  } catch (e) {
    return e;
  }
  return returnResponseData;
}

function prepareMultipleAppsResult(apps) {
  const result = [];
  for (let item in apps) {
    result.push(prepareApplicationResult(apps[item]));
  }
  return result;
}

function prepareApplicationResult(application) {
  if (!application) return application;
  const { listing, tenant, household, ...other } = application;
  const result = {
    ...snakeToCamel(other),
    tenant: prepareTenantResult(tenant),
    listing: prepareListingResult(listing),
  };
  if (household) {
    result.household = [];
    for (let ten in household) {
      result.household.push(prepareTenantResult(household[ten]));
    }
  }
  if (result.moveInDate)
    result.moveInDate = new Date(`${result.moveInDate}T00:00:00`);
  if (result.submitted)
    result.submitted = new Date(result.submitted);
  return result;
}

export const apply = async (accessToken, application, callback) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.APPLICATION_API}${application.id}/apply/`,
    "put",
    { "Content-Type": "application/json" },
    JSON.stringify(application),
    accessToken,
    callback
  );
};

export const joinApplication = async (accessToken, applicationId, callback) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.APPLICATION_API}${applicationId}/join/`,
    "put",
    { "Content-Type": "application/json" },
    null,
    accessToken,
    callback
  );
};

export const getApplicationReportData = async (
  accessToken,
  applicationId,
  tenantId,
  product,
  callback
) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}/landlord/application/${applicationId}/reports/${tenantId}/${product}/`,
    "get",
    {},
    null,
    accessToken,
    callback,
    async response => await response.text()
  );
};

export const requestInfo = async (
  applicationId,
  infoType,
  comment,
  accessToken,
  callback
) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.LANDLORD_APPLICATION_API}${applicationId}/info/`,
    "put",
    { "Content-Type": "application/json" },
    JSON.stringify({
      info_type: infoType,
      comment: comment,
    }),
    accessToken,
    callback
  );
};

export const getApplicationsAwaitingReports = async (
  tenantId,
  accessToken,
  callback
) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}/tenants/${tenantId}/applications/reports/`,
    "get",
    { "Content-Type": "application/json" },
    null,
    accessToken,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, prepareMultipleAppsResult(apiResult), apiError)
  );
};

export const submitAllAwaitingReports = async (
  tenantId,
  accessToken,
  callback
) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}/tenants/${tenantId}/applications/reports/`,
    "post",
    { "Content-Type": "application/json" },
    {},
    accessToken,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, prepareMultipleAppsResult(apiResult.applications), apiError)
  );
};