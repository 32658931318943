import {
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { GLOBALS } from "../../App";
import { callGetAvailableReports } from "../../store/actions/reportsActions";
import { acceptTerms } from "../../utils/ReportsApi";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
  },
  serviceAgreementBox: {
    border: "1px solid #000",
    whiteSpace: "break-spaces",
    padding: theme.spacing(1),
    height: "300px",
    overflowY: "scroll",
  },
}));

const AcceptTerms = ({ accessToken, showError }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [accepted, setAccepted] = useState(false);

  const toggleCheckbox = () => {
    setAccepted(prev => !prev);
  };

  const acceptTermsCalback = (isSuccess, result, error) => {
    if (isSuccess) {
      dispatch(callGetAvailableReports(accessToken));
    }
    if (error) {
      showError();
      console.error(error);
    }
  };

  async function callAcceptTerms() {
    acceptTerms(accessToken, acceptTermsCalback);
  }

  return (
    <div className={classes.root}>
      <Typography variant="body1">
        {GLOBALS.MESSAGES.ACCEPT_TERMS_TEXT}
      </Typography>
      <pre className={classes.serviceAgreementBox}>
        {GLOBALS.TERMS_AND_CONDITIONS.ATTACHMENT_A}
      </pre>
      <FormControlLabel
        control={
          <Checkbox
            checked={accepted}
            onChange={toggleCheckbox}
            name="acceptTerms"
            color="primary"
          />
        }
        label={GLOBALS.MESSAGES.ACCEPT_TERMS_LABEL}
      />
      <Button
        color="primary"
        variant="contained"
        disabled={!accepted}
        onClick={callAcceptTerms}
      >
        Continue
      </Button>
    </div>
  );
};

export default AcceptTerms;
