import {
  GET_AVAILABLE_REPORTS,
  GET_AVAILABLE_REPORTS_ERROR,
  GET_AVAILABLE_REPORTS_LOADING,
  SET_REPORTS,
  SET_SCREENING_REQUEST_ID,
  CLEAR_REPORTS,
  SET_CREATE_REPORTS_LATER,
  SET_REPORTS_NOTIFIED,
  SET_TO_SHARE_REPORTS,
} from "../actions/types";

const initialState = {
  reports: [],
  status: "",
  loading: false,
  screeningRequestId: "",
  error: null,
  createReportsLater: false,
  reportsNotified: "N",
  toShareReports: 0,
};

export default function reportsReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case GET_AVAILABLE_REPORTS:
      return { ...state, status: payload, loading: false, error: null };
    case GET_AVAILABLE_REPORTS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_AVAILABLE_REPORTS_ERROR:
      return { ...state, status: "", loading: false, error: payload };
    case SET_REPORTS:
      return { ...state, reports: payload };
    case SET_SCREENING_REQUEST_ID:
      return { ...state, screeningRequestId: payload };
    case SET_CREATE_REPORTS_LATER:
      return { ...state, createReportsLater: true };
    case SET_REPORTS_NOTIFIED:
      return { ...state, reportsNotified: "Y" };
    case SET_TO_SHARE_REPORTS:
      return { ...state, toShareReports: payload };
    case CLEAR_REPORTS:
      return initialState;
    default:
      return state;
  }
}
