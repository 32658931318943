import { Button, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import NumberFormat from "react-number-format";
import { GLOBALS } from "../../App";

const useStyle = makeStyles(theme => ({
  marginRight: {
    marginRight: theme.spacing(2),
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
}));

const CommSent = ({ onSubmit, onResendCode }) => {
  const classes = useStyle();
  const [code, setCode] = useState("");

  const handleSubmit = () => {
    onSubmit(code);
    setCode("");
  };

  return (
    <div>
      <Typography gutterBottom>{GLOBALS.MESSAGES.COMM_SENT_MSG}</Typography>
      <TextField
        className={classes.marginBottom}
        onChange={e => setCode(e.target.value)}
        value={code}
        variant="outlined"
        size="small"
        InputProps={{
          inputComponent: NumberFormat,
        }}
      />
      <br />
      <Button
        className={classes.marginRight}
        variant="contained"
        disableElevation
        color="primary"
        onClick={handleSubmit}
      >
        Submit
      </Button>
      <Button color="primary" variant="outlined" onClick={onResendCode}>
        Resend Code
      </Button>
    </div>
  );
};

export default CommSent;
