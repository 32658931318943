import React, { useState, useEffect } from "react";
import { useGoogleMaps } from "./hooks/useGoogleMaps";
import { GLOBALS } from "../../App";
import { ThemeProvider } from "@material-ui/core/styles";
import mapIcon from "../../static/images/map_icon.png";
import ReactDOMServer from "react-dom/server";
import ListingCard from "../../components/ListingCard";
import Grid from "@material-ui/core/Grid";
import theme from "../../theme";

export default function NestiqaMap(props) {
  const { listings, curTab } = props;
  const [markers, setMarkers] = useState([]);
  const { ref, map, google } = useGoogleMaps(GLOBALS.GOOGLE_API_KEY, {
    center: GLOBALS.DEFAULT_LOC,
    zoom: 12,
  });

  useEffect(() => {
    let lastOpenedInfoWindow = false;

    function updateMapMarkers() {
      if (markers.length > 0) {
        clearMarkers();
      }
      if (listings.length > 0) {
        var bounds = new google.maps.LatLngBounds();
        listings.forEach(element => {
          if (!element.longitude || !element.latitude) return;
          var location = {
            lat: Number(element.latitude),
            lng: Number(element.longitude),
          };
          var marker = new google.maps.Marker({
            position: location,
            icon: mapIcon,
            map: map,
          });

          const InfoWindowContent = (
            <Grid container spacing={2}>
              <ThemeProvider theme={theme}>
                <ListingCard
                  listing={element}
                  key={element.listing_number}
                  xs={12}
                ></ListingCard>
              </ThemeProvider>
            </Grid>
          );
          const content = ReactDOMServer.renderToString(InfoWindowContent);

          var infowindow = new google.maps.InfoWindow({
            content: content,
            maxWidth: 275,
          });

          marker.addListener("click", () => {
            closeLastOpenedInfoWindow();
            infowindow.open(map, marker);
            lastOpenedInfoWindow = infowindow;
          });

          markers.push(marker);
          bounds.extend(location);
        });
        map.fitBounds(bounds);
      }
    }

    function closeLastOpenedInfoWindow() {
      if (lastOpenedInfoWindow) {
        lastOpenedInfoWindow.close();
      }
    }

    function clearMarkers() {
      for (var i = 0; i < markers.length; i++) {
        markers[i].setMap(null);
      }
      setMarkers([]);
    }

    if (
      !google ||
      google === "undefined" ||
      !ref ||
      !markers ||
      !listings ||
      !map ||
      map === "undefined"
    ) {
      return;
    }
    updateMapMarkers();
  }, [listings, curTab, google, ref, map, markers]);

  return <div ref={ref} className={props.className} />;
}
