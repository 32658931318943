import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Button,
  Link,
  AppBar,
  Grid,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@material-ui/core";
import ShareIcon from "@material-ui/icons/Share";
import { useParams, Link as RouterLink, useHistory } from "react-router-dom";
import { GLOBALS } from "../App";
import useListing from "../hooks/useListing";
import ListingDetails from "../components/ListingDetails";
import NestiqaAlertDialog from "../components/NestiqaAlertDialog";
import NestiqaActionDialog from "../components/NestiqaActionDialog";
import { makeStyles } from "@material-ui/core/styles";
import TabPanel, { a11yProps } from "../components/TabPanel";
import RoundTabs from "../components/RoundTabs";
import RoundTab from "../components/RoundTab";
import { getLabel, formatListingDate, getFullAddress } from "../nestiqaUtils";
import ListingApplications from "../components/ListingApplications";
import RequireLogin from "../components/RequireLogin";
import { activateListing, deactivateListing } from "../hooks/ListingApi";
import useAuth0Nesitqa from "../hooks/useAuth0Nestiqa";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import {
  BackArrowIcon,
  EditIcon,
  RenewIcon,
  PrintIcon,
  DuplicateIcon,
  QrScanIcon,
  FacebookSquare,
  TwitterSquare,
} from "../components/icons";
import { verbiage, replaceTemplateKeywords } from "../utils/common";
import QRCode from "qrcode.react";
import { Helmet } from "react-helmet";
import clsx from "clsx";
import { logEvent } from "../utils/initGa4React";
import { useGA4React } from "ga-4-react";

const useStyles = makeStyles(theme => ({
  renewButton: {
    [theme.breakpoints.down("sm")]: {
      minWidth: "86px",
    },
  },
  tabsBar: {
    backgroundColor: "white",
    boxShadow: theme.shadows[0],
    color: theme.palette.primary.dark,
    marginTop: "50px",
  },
  headerWrapper: {
    display: "flex",
    alignItems: "center",
    marginTop: "40px",

    "& .MuiGrid-root.MuiGrid-item": {
      display: "flex",

      "&:nth-child(2)": {
        justifyContent: "center",
      },

      "&:nth-child(3)": {
        justifyContent: "flex-end",
      },
    },
  },
  tabs: {
    textTransform: "capitalize",
  },
  tabLabels: {
    marginLeft: "20px",
  },
  tabLabelsMobile: {
    fontSize: "13.5px",
    textTransform: "capitalize !important",
  },
  previewText: {
    marginBottom: "20px",
  },
  listingInfo: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  textBoxCol1: {
    minWidth: "120px",
    margin: "auto 0",
  },
  textBoxCol2: {
    minWidth: "100px",
    margin: "auto 0",
  },
  linkContainer: {
    padding: "12px",
  },
  linkKey: {
    padding: 0,
    margin: "auto 0",
  },
  linkButton: {
    fontSize: "1rem",
    padding: "9px",
    marginLeft: "2px",
    marginBottom: "12px",
  },
  socialShareButton: {
    padding: "14px 9px 9px 9px !important",
    color: "rgba(0, 0, 0, 0.54)",

    [theme.breakpoints.down("sm")]: {
      padding: "0 !important",
    },

    [theme.breakpoints.down("xs")]: {
      padding: "14px 9px 9px 9px !important",
    },

    "&:focus": {
      outline: "none",
      border: "none",
    },
  },
  button: {
    textTransform: "capitalize",
  },
  flexWrapper: {
    display: "flex",
    alignItems: "center",

    "& > *:first-child": {
      marginRight: "5px",
    },

    "& > *:nth-child(2)": {
      marginRight: "5px",
    },

    "& .MuiTypography-root.MuiTypography-subtitle2": {
      fontSize: "16px",
    },

    "& .MuiTypography-root.MuiTypography-subtitle1": {
      fontSize: "16px",
    },
  },
  statusWrapper: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      margin: 0,
    },
  },
  renewWrapper: {
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "flex-end",
      margin: 0,

      "& .MuiTypography-root:nth-child(2)": {
        marginTop: "5px",
        marginBottom: "6px",
      },
    },
  },
  status: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "70px",
    height: "25px",

    "& span": {
      borderRadius: "5px",
      padding: "2px 8px",
      textAlign: "left !important",
    },

    [theme.breakpoints.down("xs")]: {
      minWidth: "unset",
      marginBottom: "10px",
      marginTop: "5px",

      "& span": {
        width: "83px",
        padding: "4px 20px",
      },
    },
  },
  shareWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",

    "& .MuiTypography-root.MuiTypography-subtitle2": {
      fontSize: "16px",
      marginRight: "5px",
    },

    "& button": {
      marginBottom: 0,
    },
  },
  tabletShare: {
    marginLeft: "-140px",
  },
  mobileShareWrapper: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      marginTop: "20px",

      "& h6:first-child": {
        flexGrow: 1,
      },

      "& button:last-child": {
        marginRight: "-4px",
      },
    },
  },
  floatingCopySuccess: {
    position: "absolute",
    bottom: "44px",
    right: "149px",
    transition: "all .5s ease-in",

    [theme.breakpoints.down("xs")]: {
      right: "136px",
    },
  },
  floatingCopySuccessTablet: {
    right: "-20px",
    bottom: "14px",
    whiteSpace: "nowrap",
    position: "absolute",
    transition: "all .5s ease-in",
  },
  colorActive: {
    backgroundColor: "#ECFAFB",
    color: "#3CE0E5",
  },
  colorDraft: {
    backgroundColor: "#FBF7EC",
    color: "#D89A3D",
  },
  colorInactive: {
    backgroundColor: "#e0e0e0",
    color: "#000",
  },
  manageTitle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",

    "& .MuiTypography-root.MuiTypography-body1:first-child": {
      fontSize: "16px",
      fontWeight: 400,
    },

    "& .MuiTypography-root.MuiTypography-body1:last-child": {
      fontSize: "24px",
      fontWeight: 600,
    },
  },
  list: {
    [theme.breakpoints.down("sm")]: {
      "& .MuiPaper-root.MuiMenu-paper": {
        "& .MuiList-root.MuiMenu-list": {
          paddingBottom: 0,
          overflow: "hidden",
          width: "auto",

          "& .MuiButtonBase-root.MuiListItem-root": {
            float: "left",
            paddingBottom: 0,
            paddingTop: 0,

            "& .MuiButtonBase-root.MuiIconButton-root": {
              marginBottom: 0,
            },
          },
        },
      },
    },
  },
  margin: {
    marginTop: "9px",
    minWidth: "40px",
  },
  editLink: {
    "& a": {
      color: "#fff",
      fontSize: "0.875rem",
      fontWeight: 500,
      lineHeight: 1.75,
    },
  },
  titleMobile: {
    whiteSpace: "nowrap",
  },
  listingAddressMobile: {
    whiteSpace: "nowrap",
    width: "170px",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
}));

export default function LandlordListingPage() {
  const classes = useStyles();
  const { listingNumber } = useParams();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));
  const isTablet = useMediaQuery(theme => theme.breakpoints.down("sm"));
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up("md"));
  const history = useHistory();
  const [showCopyMessage, setShowCopyMessage] = useState(false);
  const [curTab, setCurTab] = useState(0);
  const [listingLink, setListingLink] = useState(
    `${GLOBALS.APP_BASE_URL}/listing/${listingNumber}`
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [pageControls, setPageControls] = useState({
    loading: true,
    dialog: {
      open: false,
    },
  });
  const [actionDialogControls, setActionDialogControls] = useState({
    open: false,
  });
  const [listingParams] = useState({
    listingNumber: listingNumber,
    action: "get-listing-applications",
  });
  const listingResult = useListing(listingParams);
  const { accessToken, isAuthenticated, loginWithRedirect } =
    useAuth0Nesitqa("");
  const listingActions = Object.values(verbiage);
  const [fullAddress, setFullAddress] = useState("");
  const ga = useGA4React();

  useEffect(() => {
    if (listingResult.status !== "COMPLETE" || listingResult.errors) return;

    const apps = listingResult.payload.applications.filter(
      val => val.action === "APPLY"
    );
    const pqls = listingResult.payload.applications.filter(
      val => val.action === "PREQUALIFY"
    );

    setPageControls({
      dialog: { open: false },
      loading: false,
      listing: { ...listingResult.payload.listing },
      applications: apps,
      applicationsCount: apps.filter(val => val.status === "SUBMITTED").length,
      prequalifications: pqls,
      prequalificationsCount: pqls.filter(val => val.status === "SUBMITTED")
        .length,
    });
  }, [listingResult]);

  useEffect(() => {
    if (!listingResult || listingResult.errors) {
      return;
    }

    if (listingResult && listingResult.status === "COMPLETE") {
      const listing = listingResult?.payload?.listing;
      const address = getFullAddress(listing);
      setFullAddress(address);
    }
  }, [listingResult]);

  if (listingResult.status === "COMPLETE" && listingResult.errors) {
    console.error(listingResult.errors);
    return (
      <Typography variant="h5" align="center" color="error">
        {GLOBALS.MESSAGES.LISTING_NOT_FOUND}
        {listingNumber}
      </Typography>
    );
  }

  function handleLogin() {
    localStorage.setItem("previousLocation", window.location.pathname);
    loginWithRedirect();
  }

  if (!isAuthenticated) {
    return (
      <Container>
        <RequireLogin login={handleLogin} />
      </Container>
    );
  }

  if (pageControls.loading) {
    return (
      <Typography variant="h5" align="center" color="primary">
        Loading...
      </Typography>
    );
  }

  const handleClose = () => {
    if (pageControls.dialog.navigateOnclose) {
      history.push(pageControls.dialog.navigateOnclose);
    }
    setPageControls({ ...pageControls, dialog: { open: false } });
  };

  const handleTabChange = (event, newTab) => {
    setCurTab(newTab);
  };

  function getActiveButtonLabel(status) {
    if (status === "ACTIVE" || status === "PENDING_VERIFICATION")
      return "Deactivate";
    if (status === "DRAFT") return "Publish";
    return "Activate";
  }

  function activateCallback(isSuccess, result, error) {
    if (isSuccess) {
      setPageControls({
        ...pageControls,
        loading: false,
        listing: { ...pageControls.listing, ...result },
      });
      if (result.status === "ACTIVE" && !!ga) {
        logEvent("landlord_publish_listing", {
          listing_number: result.listingNumber,
        });
      }
    } else {
      console.error(error);
      setPageControls({
        ...pageControls,
        loading: false,
        dialog: {
          open: true,
          title: "Error",
          message: listingActions.map(replaceTemplateKeywords(listing.status)),
        },
      });
    }
  }

  function activeButtonClick(listing) {
    setPageControls({ ...pageControls, loading: true });
    if (listing.status === "ACTIVE" || listing.status === "PENDING_VERIFICATION") {
      deactivateListing(listing.listingNumber, accessToken, activateCallback);
    } else {
      activateListing(listing.listingNumber, accessToken, activateCallback);
    }
  }

  function renewButtonClick(listing) {
    setPageControls({ ...pageControls, loading: true });
    activateListing(listing.listingNumber, accessToken, activateCallback);
  }

  const handleCopy = () => {
    setListingLink(`${GLOBALS.APP_BASE_URL}/listing/${listingNumber}`);
    navigator.clipboard.writeText(listingLink);
    setTimeout(() => {
      setShowCopyMessage(false);
    }, 2000);
    setShowCopyMessage(true);
  };

  const handleShare = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const downloadQR = () => {
    const canvas = document.getElementById(`${listingNumber}-qr`);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${listingNumber}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const getColor = status => {
    switch (status) {
      case "ACTIVE":
        return classes.colorActive;
      case "DRAFT":
      case "PENDING_VERIFICATION":
        return classes.colorDraft;
      case "INACTIVE":
      case "EXPIRED":
      case "DELETED":
        return classes.colorInactive;
      default:
        return "";
    }
  };

  const actionDialogButtons = [
    {
      buttonName: "download",
      handleClick: () => downloadQR(),
    },
    {
      buttonName: "close",
      handleClick: () => setActionDialogControls({ open: false }),
    },
  ];

  const handleClickQR = () => {
    setActionDialogControls({
      open: true,
      title: "Scan or download QR code",
      message: (
        <QRCode
          id={`${listingNumber}-qr`}
          value={listingLink}
          includeMargin={true}
        />
      ),
      buttonMap: actionDialogButtons,
    });
  };

  const listing = pageControls.listing;
  const shareUrl =
    process.env.NODE_ENV === "development"
      ? `https://nestiqa.appspot.com/landlord/listing/${listingNumber}`
      : listingLink;

  const shareButtons = (
    <Menu
      id="listing-link-menu"
      anchorEl={anchorEl}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
      autoWidth={false}
      className={isTablet && !isMobile && classes.list}
    >
      <MenuItem onClick={handleMenuClose}>
        <Tooltip title="Get a link to this listing">
          <IconButton
            aria-label="copy"
            className={classes.linkButton}
            onClick={handleCopy}
          >
            <DuplicateIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </MenuItem>
      <MenuItem onClick={handleMenuClose}>
        <Tooltip title="Print a flyer for this listing">
          <IconButton aria-label="print" className={classes.linkButton}>
            <a
              href={GLOBALS.PATHS.PRINT_PAGE.replace(
                ":listingNumber",
                listingNumber
              )}
              target="_blank"
              rel="noopener noreferrer"
            >
              <PrintIcon fontSize="inherit" />
            </a>
          </IconButton>
        </Tooltip>
      </MenuItem>
      <MenuItem onClick={handleMenuClose}>
        <Tooltip title="Get QR code">
          <IconButton
            aria-label="qr-code"
            className={classes.linkButton}
            onClick={handleClickQR}
          >
            <QrScanIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </MenuItem>
      <MenuItem onClick={handleMenuClose}>
        <Tooltip title="Share to Facebook">
          <FacebookShareButton
            aria-label="facebook"
            className={clsx(
              classes.socialShareButton,
              classes.linkButton,
              classes.margin
            )}
            url={shareUrl}
          >
            <FacebookSquare fontSize="inherit" />
          </FacebookShareButton>
        </Tooltip>
      </MenuItem>
      <MenuItem onClick={handleMenuClose}>
        <Tooltip title="Share to Twitter">
          <TwitterShareButton
            aria-label="twitter"
            className={clsx(
              classes.socialShareButton,
              classes.linkButton,
              classes.margin
            )}
            url={shareUrl}
          >
            <TwitterSquare fontSize="inherit" />
          </TwitterShareButton>
        </Tooltip>
      </MenuItem>
    </Menu>
  );

  return (
    <Container className={classes.root}>
      <Helmet>
        <title>
          {GLOBALS.META_TAGS.TITLE.LANDLORD_LISTING_PAGE + fullAddress}
        </title>
      </Helmet>
      <NestiqaActionDialog
        {...actionDialogControls}
        style={{ width: "400px", display: "flex", justifyContent: "center" }}
      />
      <Grid container justify="space-between" className={classes.headerWrapper}>
        <Grid item xs={4}>
          <Link
            to={GLOBALS.PATHS.LANDLORD}
            component={RouterLink}
            underline="none"
          >
            {isMobile ? (
              <IconButton>
                <BackArrowIcon />
              </IconButton>
            ) : (
              <Button
                variant="outlined"
                color="primary"
                align="center"
                className={classes.button}
              >
                Back to Listings
              </Button>
            )}
          </Link>
        </Grid>
        <Grid item xs={4} className={classes.manageTitle}>
          <Typography
            variant="body1"
            align="center"
            className={clsx(isMobile && classes.titleMobile)}
          >
            Manage Listing:
          </Typography>
          <Typography
            variant="body1"
            align="center"
            className={clsx(isMobile && classes.listingAddressMobile)}
          >
            {listing.address1}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Link
            to={GLOBALS.PATHS.UPDATE_LISTING.replace(
              ":listingNumber",
              listingNumber
            )}
            component={RouterLink}
            underline="none"
          >
            <Button
              variant="contained"
              color="primary"
              align="center"
              className={clsx(classes.button, classes.editLink)}
              startIcon={<EditIcon />}
            >
              Edit
            </Button>
          </Link>
        </Grid>
      </Grid>
      <Grid container justify="space-between" style={{ marginTop: "40px" }}>
        <Grid
          item
          xs={6}
          sm={4}
          className={clsx(classes.flexWrapper, classes.statusWrapper)}
        >
          <Typography variant="subtitle2">{"Status: "}</Typography>
          <div className={classes.status}>
            <span className={getColor(listing.status)}>
              {getLabel(listing.status, "STATUSES")}
            </span>
          </div>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => activeButtonClick(listing)}
          >
            {getActiveButtonLabel(listing.status)}
          </Button>
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          className={clsx(classes.flexWrapper, classes.renewWrapper)}
          style={!isMobile ? { justifyContent: "center" } : {}}
        >
          <Typography variant="subtitle2" style={{ whiteSpace: "nowrap" }}>
            Expiration Date:{" "}
          </Typography>
          <Typography variant="subtitle1">
            {formatListingDate(listing.expirationDate)}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            className={clsx(classes.button, classes.renewButton)}
            onClick={() => renewButtonClick(listing)}
            startIcon={<RenewIcon />}
          >
            Renew
          </Button>
        </Grid>
        {isMobile || isDesktop ? (
          <Grid
            item
            xs={12}
            sm={4}
            className={clsx(classes.shareWrapper, classes.mobileShareWrapper)}
            style={{ position: "relative" }}
          >
            <>
              <Typography variant="subtitle2">Share: </Typography>
              <span
                className={classes.floatingCopySuccess}
                style={
                  showCopyMessage
                    ? { visibility: "visible" }
                    : { visibility: "hidden" }
                }
              >
                Listing link copied
              </span>
              <Tooltip title="Get a link to this listing">
                <IconButton
                  aria-label="copy"
                  className={classes.linkButton}
                  onClick={handleCopy}
                >
                  <DuplicateIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Print a flyer for this listing">
                <a
                  href={GLOBALS.PATHS.PRINT_PAGE.replace(
                    ":listingNumber",
                    listingNumber
                  )}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton aria-label="print" className={classes.linkButton}>
                    <PrintIcon fontSize="inherit" />
                  </IconButton>
                </a>
              </Tooltip>
              <Tooltip title="Get QR code">
                <IconButton
                  aria-label="qr-code"
                  className={classes.linkButton}
                  onClick={handleClickQR}
                >
                  <QrScanIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Share to Facebook">
                <FacebookShareButton
                  aria-label="facebook"
                  className={clsx(
                    classes.socialShareButton,
                    classes.linkButton
                  )}
                  url={shareUrl}
                >
                  <FacebookSquare fontSize="inherit" />
                </FacebookShareButton>
              </Tooltip>
              <Tooltip title="Share to Twitter">
                <TwitterShareButton
                  aria-label="twitter"
                  className={clsx(
                    classes.socialShareButton,
                    classes.linkButton
                  )}
                  url={shareUrl}
                >
                  <TwitterSquare fontSize="inherit" />
                </TwitterShareButton>
              </Tooltip>
            </>
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            sm={4}
            className={clsx(classes.shareWrapper, classes.tabletShare)}
          >
            <Typography variant="subtitle2">Share: </Typography>
            <IconButton
              aria-label="share"
              className={classes.linkButton}
              onClick={handleShare}
            >
              <ShareIcon color="secondary" fontSize="inherit" />
            </IconButton>
            <div style={{ position: "relative" }}>
              <span
                className={classes.floatingCopySuccessTablet}
                style={
                  showCopyMessage
                    ? { visibility: "visible" }
                    : { visibility: "hidden" }
                }
              >
                Listing link copied
              </span>
              {shareButtons}
            </div>
          </Grid>
        )}
      </Grid>

      <AppBar position="static" className={classes.tabsBar}>
        <Grid container>
          <Grid item style={{ flexGrow: 1 }}>
            <RoundTabs
              value={curTab}
              onChange={handleTabChange}
              style={{ borderLeft: "2px solid black" }}
            >
              <RoundTab
                className={classes.tabs}
                style={{ zIndex: 3 }}
                label={
                  <Typography
                    className={
                      isMobile ? classes.tabLabelsMobile : classes.tabLabels
                    }
                    variant={"h6"}
                  >
                    Preview
                  </Typography>
                }
                {...a11yProps(0)}
              />
              <RoundTab
                className={classes.tabs}
                style={{ zIndex: 2 }}
                label={
                  <Typography
                    className={
                      isMobile ? classes.tabLabelsMobile : classes.tabLabels
                    }
                    variant={"h6"}
                  >
                    Applications({pageControls.applicationsCount})
                  </Typography>
                }
                {...a11yProps(1)}
              />
              <RoundTab
                className={classes.tabs}
                style={{ zIndex: 1 }}
                label={
                  <Typography
                    className={
                      isMobile ? classes.tabLabelsMobile : classes.tabLabels
                    }
                    variant={"h6"}
                  >
                    Preapprovals({pageControls.prequalificationsCount})
                  </Typography>
                }
                {...a11yProps(2)}
              />
            </RoundTabs>
          </Grid>
        </Grid>
      </AppBar>
      <TabPanel value={curTab} index={0} style={tabPanelStyle}>
        <Typography
          variant="subtitle1"
          align="center"
          color="primary"
          className={classes.previewText}
        >
          {GLOBALS.SITE_TEXTS.PREVIEW_TEXT}
          <Link
            to={`/landlord/listing/${listingNumber}/update`}
            component={RouterLink}
            underline="always"
          >
            click here
          </Link>
        </Typography>
        <ListingDetails preview={true} listing={listing} />
      </TabPanel>
      <TabPanel value={curTab} index={1} style={tabPanelStyle}>
        <Typography variant="h5" align="center" color="primary">
          <ListingApplications applications={pageControls.applications} />
        </Typography>
      </TabPanel>
      <TabPanel value={curTab} index={2} style={tabPanelStyle}>
        <Typography variant="h5" align="center" color="primary">
          <ListingApplications applications={pageControls.prequalifications} />
        </Typography>
      </TabPanel>
      <NestiqaAlertDialog {...pageControls.dialog} onClose={handleClose} />
    </Container>
  );
}

const tabPanelStyle = {
  border: "2px solid black",
  borderTop: "none",
  borderBottomLeftRadius: "30px",
  borderBottomRightRadius: "30px",
};
