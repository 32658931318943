import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import TenantProfileSection from "../tenant-profile/TenantProfileSection";
import OpenApplicationTable from "../tenant-applications/OpenApplicationTable";
import OpenApplicationAccordion from "../tenant-applications/OpenApplicationAccordion";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
  },
}));

const OpenApplicationsSection = ({
  applicationHistory,
  tenantId,
  isEditable,
  title,
}) => {
  const isTabletOrAbove = useMediaQuery(theme => theme.breakpoints.up("sm"));
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TenantProfileSection title={title} isEditable={isEditable}>
        <>
          {!isTabletOrAbove ? (
            applicationHistory.map(app => (
              <OpenApplicationAccordion
                key={app.id}
                application={app}
                tenantId={tenantId}
              />
            ))
          ) : (
            <OpenApplicationTable
              applicationHistory={applicationHistory}
              tenantId={tenantId}
            />
          )}
        </>
      </TenantProfileSection>
    </div>
  );
};

export default OpenApplicationsSection;
