import React, { useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { Formik, Form, Field, FieldArray } from "formik";
import { TextField } from "formik-material-ui";
import { KeyboardDatePicker } from "formik-material-ui-pickers";
import {
  Typography,
  Box,
  Button,
  InputLabel,
  useMediaQuery,
  Paper,
} from "@material-ui/core";
import YesNoField from "../YesNoField";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { differenceInYears } from "date-fns";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import {
  DeleteOutline as DeleteIcon,
  EditOutlined as EditIcon,
} from "@material-ui/icons";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import InputAdornment from "@material-ui/core/InputAdornment";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import WorkOutlineOutlinedIcon from "@material-ui/icons/WorkOutlineOutlined";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import WarningIcon from "@material-ui/icons/Warning";
import CakeIcon from "@material-ui/icons/Cake";
import { makeStyles } from "@material-ui/core/styles";
import { yellow, green } from "@material-ui/core/colors";
import { GLOBALS } from "../../App";
import TenantFormControls from "./TenantFormControls";
import {
  addHouseholdMember,
  removeHouseholdMember,
  editHouseholdMember,
} from "../../utils/HouseholdApi";
import * as yup from "yup";
import {
  checkFormikError,
  formatter,
  getHeadofHousehold,
  sortByYesNoFieldAndDate,
} from "../../utils/common";
import NestiqaActionDialog from "./../NestiqaActionDialog";
import CreateTenantTable from "./CreateTenantTable";
import CreateTenantDialog from "./CreateTenantDialog";
import Popover from "@material-ui/core/Popover";
import tenantPicture from "./../tenant_picture.png";
import CircularStatic from "../application/CircularStatic";
import InvitationDialog from "../InvitationDialog";
import { logEvent } from "../../utils/initGa4React";
import { useGA4React } from "ga-4-react";

const useStyles = makeStyles(theme => ({
  topRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(4),
  },
  left: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  error: {
    fontSize: "0.75rem",
    color: "#FF2D44",
    marginTop: "3px",
  },
  addButton: {
    marginLeft: theme.spacing(4),
    whiteSpace: "nowrap",
  },
  formButtonContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginTop: theme.spacing(3),
  },
  btnContainer: {
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "column",
  },
  inputField: {
    width: "100%",

    "& .MuiInputLabel-root": {
      color: theme.palette.grey[400],
    },

    "& .MuiInputLabel-shrink": {
      color: theme.palette.primary.main,
    },
  },
  datePicker: {},
  coSignerRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  mobileRow: {
    display: "flex",
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
    alignItems: "center",
  },
  mobileRowDetails: {
    display: "flex",
    flexDirection: "column",
    flexBasis: "100%",
  },
  mobileTopRow: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  mobileBottomRow: {
    maxWidth: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },
  mobileBottomRowTextContainer: {
    maxWidth: "calc(100% - 108px)",
  },
  mobileBottomRowText: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflowX: "hidden",
  },
  householdSummary: {
    display: "flex",
    alignItems: "flex-start",
    padding: theme.spacing(2),
    maxWidth: "228px",

    "& img": {
      width: "100px",
      height: "100px",
      objectFit: "cover",
      objectPosition: "center",
      marginRight: theme.spacing(2),
    },
  },
  circularProgress: {
    paddingTop: theme.spacing(2),
  },
  popover: {
    pointerEvents: "none",
  },
  inviteHousehold: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    padding: theme.spacing(2),
    maxWidth: "320px",
  },
  inviteText: {
    paddingBottom: theme.spacing(1),
  },
  inviteDialog: {
    paddingBottom: theme.spacing(2),
  },
  [theme.breakpoints.down("xs")]: {
    topRow: {
      flexDirection: "column",

      "& .MuiFormControlLabel-root": {
        display: "block",
        margin: `0 auto`,
        marginTop: theme.spacing(2),
      },
    },
    checkboxContainer: {
      marginLeft: "-12px !important",
    },
    addButton: {
      marginTop: theme.spacing(2),
      marginLeft: "0px",
    },
  },
}));

export default function TenantHousehold(props) {
  const {
    accessToken,
    tenant,
    setTenant,
    step,
    setStep,
    showError,
    isOnlyOccupant,
    setCheckBoxValue,
    isEditDialog = false,
    closeDialog,
  } = props;
  const ga = useGA4React();

  const setIsOnlyOccupant = value => {
    setCheckBoxValue("isOnlyOccupant", value);
  };

  function onStepSubmit(values, formikBag) {
    formikBag.setSubmitting(false);
  }

  function handleNext(formikprops) {
    if (formikprops.values.household.length === 1 && !isOnlyOccupant.state) {
      setIsOnlyOccupant({ error: true });
    } else {
      setIsOnlyOccupant({ error: false });

      if (!!ga) {
        logEvent("tenant_complete_step", { step: step });
      }

      if (isEditDialog) {
        closeDialog();
      } else {
        setStep(step + 1);
      }
    }
  }

  const validationSchema = yup.object().shape({
    household: yup.array().of(
      yup.object().shape({
        firstName: yup.string().required(GLOBALS.MESSAGES.FIELD_REQUIRED),
        lastName: yup.string().required(GLOBALS.MESSAGES.FIELD_REQUIRED),
        annualIncome: yup.string(),
        dob: yup.date().typeError(GLOBALS.MESSAGES.ENTER_VALID_DATE),
        coSigner: yup.string().required(GLOBALS.MESSAGES.FIELD_REQUIRED),
        occupation: yup.string(),
      })
    ),
  });

  return (
    <TenantFormControls
      tenant={tenant}
      step={step}
      setStep={setStep}
      onSubmit={onStepSubmit}
      validationSchema={validationSchema}
      handleNext={handleNext}
      isEditDialog={isEditDialog}
      closeDialog={closeDialog}
    >
      <HouseholdFormikWrapper
        isOnlyOccupant={isOnlyOccupant}
        setIsOnlyOccupant={setIsOnlyOccupant}
        showError={showError}
        accessToken={accessToken}
        tenant={tenant}
        setTenant={setTenant}
      />
    </TenantFormControls>
  );
}

function HouseholdFormikWrapper({
  formikprops,
  isOnlyOccupant,
  setIsOnlyOccupant,
  showError,
  accessToken,
  tenant,
  setTenant,
}) {
  const classes = useStyles();

  const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));

  const arrayHelpersRef = useRef(null);
  const [dialog, setDialog] = useState({
    open: false,
  });

  const { headOfHousehold, otherOccupants } = useMemo(() => {
    return getHeadofHousehold(
      formikprops.values.household,
      formikprops.values.headOfHousehold
    );
  }, [formikprops.values.household, formikprops.values.headOfHousehold]);

  const sortedOtherOccupants = useMemo(() => {
    return sortByYesNoFieldAndDate(otherOccupants, "coSigner", "dob");
  }, [otherOccupants]);

  const sortedOccupants = [headOfHousehold, ...sortedOtherOccupants];

  const [dialogForm, setDialogForm] = useState({
    index: sortedOccupants.length,
    isNew: true,
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      dob: null,
      occupation: "",
      coSigner: "N",
    },
  });

  const handleDialogClose = () => {
    setDialog(prev => ({ ...prev, open: false }));
    setDialogForm({
      index: sortedOccupants.length,
      isNew: true,
      initialValues: {
        firstName: "",
        lastName: "",
        email: "",
        dob: null,
        occupation: "",
        coSigner: "N",
      },
    });
  };

  const handleDialogOpen = () => {
    setDialog(prev => ({ ...prev, open: true }));
  };

  const onConfirmDelete = (index, occupant) => {
    setDeleteDialogControls({ open: false });
    formikprops.setSubmitting(true);

    removeHouseholdMember(
      tenant.id,
      occupant.id,
      accessToken,
      (isSuccess, apiResult, apiError) => {
        formikprops.setSubmitting(false);

        if (isSuccess) {
          const idxToRemove = formikprops.values.household.findIndex(
            v => v.id === occupant.id
          );
          arrayHelpersRef.current?.remove(idxToRemove);

          setTenant(tenant => ({
            ...tenant,
            household: [...arrayHelpersRef.current.form.values.household],
          }));
        } else {
          console.error(apiError);
          showError(GLOBALS.MESSAGES.ERROR_COMPLETING_ACTION);
        }
      }
    );
  };

  const [deleteDialogControls, setDeleteDialogControls] = useState({
    open: false,
  });

  const getDeleteDialogButtonMap = (index, occupant) => [
    {
      buttonName: "No",
      handleClick: () => setDeleteDialogControls({ open: false }),
    },
    {
      buttonName: "Yes",
      handleClick: () => onConfirmDelete(index, occupant),
    },
  ];

  const onRowDelete = (index, occupant) => {
    setDeleteDialogControls({
      open: true,
      title: "Remove Household member",
      message: GLOBALS.MESSAGES.REMOVE_FROM_HOUSEHOLD.replace(
        "[full_name]",
        occupant.firstName + " " + occupant.lastName
      ),
      buttonMap: getDeleteDialogButtonMap(index, occupant),
    });
  };

  const [invitationDialogControls, setInvitationDialogControls] = useState({
    open: false,
    showInviteNow: false,
  });

  const onSendInvitation = (index, occupant, showInviteNow = false) => {
    setInvitationDialogControls({
      open: true,
      index,
      occupant,
      showInviteNow,
    });
  };

  const onRowEdit = index => {
    setDialogForm({
      index,
      isNew: false,
      initialValues: sortedOccupants[index],
    });
    handleDialogOpen();
  };

  const onDialogSubmit = (isNew, index, row) => {
    formikprops.setSubmitting(true);
    if (isNew) {
      addHouseholdMember(
        tenant.id,
        row,
        accessToken,
        (isSuccess, apiResult, apiError) => {
          formikprops.setSubmitting(false);

          if (isSuccess) {
            arrayHelpersRef.current.push(apiResult);
            setTenant(tenant => ({
              ...tenant,
              household: [...arrayHelpersRef.current.form.values.household],
            }));

            const age = apiResult.dob
              ? `${differenceInYears(new Date(), new Date(apiResult.dob))}`
              : "";

            if (age !== "" && age >= 18) {
              onSendInvitation(index, apiResult, true);
            }
          } else {
            console.error(apiError);
            showError(GLOBALS.MESSAGES.ERROR_COMPLETING_ACTION);
          }
        }
      );
    } else {
      editHouseholdMember(
        tenant.id,
        row,
        accessToken,
        (isSuccess, apiResult, apiError) => {
          formikprops.setSubmitting(false);

          if (isSuccess) {
            const idxToReplace = formikprops.values.household.findIndex(
              v => v.id === row.id
            );
            arrayHelpersRef.current.replace(idxToReplace, apiResult);

            setTenant(tenant => ({
              ...tenant,
              household: [...arrayHelpersRef.current.form.values.household],
            }));
          } else {
            console.error(apiError);
            showError(GLOBALS.MESSAGES.ERROR_COMPLETING_ACTION);
          }
        }
      );
    }
    handleDialogClose();
  };

  const columns = [
    {
      field: "household-status",
      headerName: "",
    },
    {
      field: "name",
      headerName: "Name",
    },
    {
      field: "age",
      headerName: "Age",
    },
    {
      field: "occupation",
      headerName: "Occupation",
    },
    {
      field: "annualIncome",
      headerName: "Annual Income",
    },
    {
      field: "coSigner",
      headerName: "Co-Signer",
    },
    {
      field: "actions",
      headerName: "Actions",
    },
  ];

  const rows = sortedOccupants.map((occupant, index) => {
    const age = occupant.dob
      ? `${differenceInYears(new Date(), new Date(occupant.dob))}`
      : "-";
    return [
      {
        field: "household-status",
        value: (
          <HouseholdStatus
            isHeadOfHousehold={occupant.id === tenant.headOfHousehold}
            occupant={occupant}
            onInvite={() => onSendInvitation(index, occupant)}
          />
        ),
      },
      {
        field: "name",
        value: occupant.firstName + " " + occupant.lastName,
      },
      {
        field: "age",
        value: age || "-",
      },
      {
        field: "occupation",
        value: occupant.occupation || "-",
      },
      {
        field: "annualIncome",
        value: occupant.annualIncome
          ? formatter.format(occupant.annualIncome)
          : "-",
      },
      {
        field: "coSigner",
        value: occupant.coSigner === "Y" ? "Yes" : "No",
      },
      {
        field: "actions",
        value: (
          <>
            {index > 0 ? (
              <>
                {occupant.hasUser === "N" && age >= 18 && (
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    onClick={() => onSendInvitation(index, occupant)}
                  >
                    <MailOutlineIcon color="secondary" />
                  </IconButton>
                )}
                {occupant.hasUser === "N" && (
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    onClick={() => onRowEdit(index)}
                  >
                    <EditIcon color="secondary" />
                  </IconButton>
                )}

                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => onRowDelete(index, occupant)}
                >
                  <DeleteIcon color="secondary" />
                </IconButton>
              </>
            ) : null}
          </>
        ),
      },
    ];
  });

  return (
    <Box justifyContent="left" textAlign="left">
      <div className={classes.topRow}>
        <div className={classes.left}>
          <Typography variant="body1">
            {GLOBALS.MESSAGES.ADD_HOUSEHOLD}
          </Typography>
          <FormControlLabel
            className={classes.checkboxContainer}
            control={
              <Checkbox
                disabled={sortedOccupants.length > 1}
                checked={isOnlyOccupant.state}
                onChange={e => setIsOnlyOccupant({ state: e.target.checked })}
                name="isOnlyOccupant"
                color="primary"
              />
            }
            label={GLOBALS.MESSAGES.ONLY_OCCUPANT}
          />
          {isOnlyOccupant.error &&
            sortedOccupants.length === 1 &&
            !isOnlyOccupant.state && (
              <Typography variant="body2" className={classes.error}>
                {GLOBALS.MESSAGES.ADD_HOUSEHOLD_ERROR}
              </Typography>
            )}
        </div>

        <Button
          disabled={isOnlyOccupant.state}
          color="primary"
          variant="contained"
          className={classes.addButton}
          onClick={handleDialogOpen}
        >
          Add Occupant
        </Button>
      </div>
      {isMobile ? (
        <TenantHouseHoldTableMobile rows={rows} />
      ) : (
        <CreateTenantTable columns={columns} rows={rows} isHousehold />
      )}
      <FieldArray
        name="household"
        render={arrayHelpers => {
          arrayHelpersRef.current = arrayHelpers;
          return null;
        }}
      />
      <CreateTenantDialog
        {...dialog}
        title={dialogForm.isNew ? "Add Occupant" : "Edit Occupant"}
        onClose={handleDialogClose}
        form={
          <AddOccupantForm
            handleDialogClose={handleDialogClose}
            onSubmit={onDialogSubmit}
            {...dialogForm}
          />
        }
      />
      <NestiqaActionDialog {...deleteDialogControls} />
      <InvitationDialog
        {...invitationDialogControls}
        tenantId={tenant.id}
        accessToken={accessToken}
        onClose={() =>
          setInvitationDialogControls({
            open: false,
            index: -1,
            occupant: {},
          })
        }
      />
    </Box>
  );
}

const AddOccupantForm = ({
  handleDialogClose,
  onSubmit,
  initialValues,
  index,
  isNew,
}) => {
  const classes = useStyles();

  const addOccupantsValidationSchema = yup.object().shape({
    firstName: yup.string().required(GLOBALS.MESSAGES.FIELD_REQUIRED),
    lastName: yup.string().required(GLOBALS.MESSAGES.FIELD_REQUIRED),
    annualIncome: yup.string(),
    dob: yup.date().typeError(GLOBALS.MESSAGES.ENTER_VALID_DATE),
    coSigner: yup
      .string()
      .required(GLOBALS.MESSAGES.FIELD_REQUIRED)
      .when("dob", {
        is: val => differenceInYears(new Date(), new Date(val)) < 18,
        then: yup
          .string()
          .matches(/(N)/, { message: GLOBALS.MESSAGES.UNDERAGE_COSIGNER }),
      }),
    occupation: yup.string(),
    relationship: yup
      .string()
      .max(20)
      .required(GLOBALS.MESSAGES.FIELD_REQUIRED)
      .label("Relationship"),
  });

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={addOccupantsValidationSchema}
        onSubmit={(values, formikBag) => {
          onSubmit(isNew, index, {
            ...values,
            dob: values.dob,
          });
        }}
      >
        {formikprops => (
          <Form>
            <Field
              className={clsx(classes.inputField)}
              component={TextField}
              required
              name={`firstName`}
              type="text"
              label="First Name"
              variant="outlined"
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PersonOutlineIcon color="secondary" />
                  </InputAdornment>
                ),
              }}
              inputProps={{
                autoComplete: "off",
              }}
            />
            <Field
              className={clsx(classes.inputField)}
              component={TextField}
              required
              name={`lastName`}
              type="text"
              label="Last Name"
              variant="outlined"
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PersonOutlineIcon color="secondary" />
                  </InputAdornment>
                ),
              }}
              inputProps={{
                autoComplete: "off",
              }}
            />

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Field
                className={clsx(classes.inputField, classes.datePicker)}
                component={KeyboardDatePicker}
                required
                name={`dob`}
                label="Date of Birth"
                format="MM/dd/yyyy"
                disableFuture
                inputVariant="outlined"
                variant="inline"
                margin="normal"
                autoOk
                KeyboardButtonProps={{ edge: "end" }}
                keyboardIcon={<CakeIcon color="secondary" />}
                inputProps={{
                  autoComplete: "off",
                }}
                InputLabelProps={{ shrink: formikprops.values.dob !== null }}
              />
            </MuiPickersUtilsProvider>
            <Field
              className={clsx(classes.inputField)}
              component={TextField}
              name={`relationship`}
              required
              type="text"
              label="Relationship"
              placeholder="Spouse, Child, etc."
              variant="outlined"
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PersonOutlineIcon color="secondary" />
                  </InputAdornment>
                ),
              }}
              inputProps={{
                autoComplete: "off",
              }}
            />
            <Field
              className={clsx(classes.inputField)}
              component={TextField}
              name={`occupation`}
              type="text"
              label="Occupation"
              variant="outlined"
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <WorkOutlineOutlinedIcon color="secondary" />
                  </InputAdornment>
                ),
              }}
              inputProps={{
                autoComplete: "off",
              }}
            />
            <Field
              className={clsx(classes.inputField)}
              component={TextField}
              name={`annualIncome`}
              type="text"
              label="Annual Income"
              variant="outlined"
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AttachMoneyIcon color="secondary" />
                  </InputAdornment>
                ),
              }}
              inputProps={{
                min: 0,
                autoComplete: "off",
              }}
            />
            <div className={classes.coSignerRow}>
              <InputLabel htmlFor="coSigner">Co Signer</InputLabel>
              <YesNoField
                required
                name={`coSigner`}
                variant="outlined"
                margin="normal"
                defaultValue="N"
                handleBlur={formikprops.handleBlur}
              />
            </div>
            <div className={classes.formButtonContainer}>
              <Button
                color="primary"
                variant="outlined"
                onClick={handleDialogClose}
              >
                Cancel
              </Button>

              <div className={classes.btnContainer}>
                <Button color="primary" variant="contained" type="submit">
                  Save
                </Button>
                {checkFormikError(formikprops) && (
                  <span className={classes.error}>
                    {GLOBALS.MESSAGES.CORRECT_FORM_ERRORS}
                  </span>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

const TenantHouseHoldTableMobile = ({ rows }) => {
  const classes = useStyles();

  return (
    <div>
      {rows.map((row, i) => {
        const nameAndAge = `${row[1].value}, ${row[2].value}`;
        const occupation = row[3].value;
        const annualIncome = row[4].value;
        const isCoSigner = row[5].value === "Yes";
        const actions = row[6].value;
        const status = row[0].value;

        return (
          <div className={classes.mobileRow} key={i}>
            <div>{status}</div>
            <div key={i} className={classes.mobileRowDetails}>
              <div className={classes.mobileTopRow}>
                <Typography variant="body2">{nameAndAge}</Typography>
                {isCoSigner && (
                  <Typography variant="body2">Co-Signer</Typography>
                )}
              </div>
              <div className={classes.mobileBottomRow}>
                <div className={classes.mobileBottomRowTextContainer}>
                  <Typography
                    variant="body2"
                    className={classes.mobileBottomRowText}
                  >
                    {annualIncome}
                  </Typography>
                  <Typography
                    variant="body2"
                    className={classes.mobileBottomRowText}
                  >
                    {occupation}
                  </Typography>
                </div>
                <div>{actions}</div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const HouseholdSummaryContent = ({ occupant }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.householdSummary}>
      <div>
        <img src={occupant.picture || tenantPicture} alt={occupant.firstName} />
        <Typography variant="body1">
          {occupant.firstName + " " + occupant.lastName}
        </Typography>
      </div>
      <div className={classes.circularProgress}>
        <CircularStatic
          value={occupant.profileCompleteness}
          is_complete={occupant.isComplete}
        />
      </div>
    </Paper>
  );
};

const InviteHouseholdContent = ({ occupant, hasUser, onInvite }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.inviteHousehold}>
      {hasUser ? (
        <Typography variant="body1">
          {GLOBALS.MESSAGES.INCOMPLETE_PROFILE.replace(
            "[full_name]",
            occupant.firstName + " " + occupant.lastName
          )}
        </Typography>
      ) : (
        <>
          <Typography variant="body1" className={classes.inviteText}>
            {GLOBALS.MESSAGES.INVITE_HOUSEHOLD_MEMBER.replace(
              "[full_name]",
              occupant.firstName + " " + occupant.lastName
            )}
          </Typography>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={onInvite}
          >
            Invite Now
          </Button>
        </>
      )}
    </Paper>
  );
};

const HouseholdStatus = ({ occupant, onInvite, isHeadOfHousehold }) => {
  const classes = useStyles();
  const [AnchorEl, setAnchorEl] = React.useState(null);

  const handleOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const Open = Boolean(AnchorEl);
  const id = Open ? "simple-popover" : undefined;

  const hasUser = occupant.hasUser === "Y";
  const isProfileComplete = occupant.isComplete === "Y";
  const isIncompleteUser = hasUser && !isProfileComplete;

  const age = occupant.dob
    ? `${differenceInYears(new Date(), new Date(occupant.dob))}`
    : "";

  const displayCheck =
    isHeadOfHousehold ||
    (hasUser && isProfileComplete) ||
    (age !== "" && age < 18);

  return (
    <>
      {displayCheck ? (
        <>
          <IconButton
            edge="start"
            aria-label="edit"
            onMouseEnter={e => {
              if (hasUser) {
                handleOpen(e);
              }
            }}
            onMouseLeave={handleClose}
          >
            <CheckCircleOutlineIcon style={{ color: green["A200"] }} />
          </IconButton>
        </>
      ) : (
        <IconButton
          edge="start"
          aria-label="edit"
          onMouseEnter={handleOpen}
          onMouseLeave={() => {
            if (isIncompleteUser) {
              handleClose();
            }
          }}
        >
          <WarningIcon style={{ color: yellow["600"] }} />
        </IconButton>
      )}
      <Popover
        id={id}
        open={Open}
        anchorEl={AnchorEl}
        className={clsx((displayCheck || isIncompleteUser) && classes.popover)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={handleClose}
      >
        {isHeadOfHousehold || (hasUser && isProfileComplete) ? (
          <HouseholdSummaryContent occupant={occupant} />
        ) : (
          <InviteHouseholdContent
            hasUser={hasUser}
            occupant={occupant}
            onInvite={onInvite}
          />
        )}
      </Popover>
    </>
  );
};
