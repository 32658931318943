import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { format } from "date-fns";
import { formatter } from "../../utils/common";
import { GLOBALS } from "../../App";
import { formatPhoneNumber } from "../../nestiqaUtils";

const useStyles = makeStyles(theme => ({
  row: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: theme.spacing(1),
  },
  rowRight: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  summary: {
    width: "100%",

    "& .MuiAccordionSummary-content": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },

    "& .MuiAccordionSummary-root": {
      padding: 0,
    },
  },
  details: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  accordian: {
    boxShadow: "none",
    border: "none",
    borderBottom: `2px solid ${theme.palette.secondary.main}`,

    "&::before": {
      display: "none",
    },
  },
  alignRight: {
    textAlign: "right",
  },
  actionsRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  rightMargin: {
    marginRight: theme.spacing(1),
  },
}));

const TenantHousingHistoryAccordion = ({ housingHistory }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(
    housingHistory.length === 1 ? housingHistory[0].id : false
  );

  const handleChange = id => (event, isExpanded) => {
    setExpanded(isExpanded ? id : false);
  };

  return (
    <>
      {housingHistory.map((history, i) => (
        <Accordion
          expanded={expanded === history.id}
          key={history.id}
          className={classes.accordian}
          onChange={handleChange(history.id)}
        >
          <AccordionSummary id={history.id} className={classes.summary}>
            <div className={classes.row}>
              <Typography variant="body2">
                {format(new Date(history.startDate), GLOBALS.DATE_FORMAT) +
                  " - "}
                {history.isCurrent === "Y"
                  ? "Current"
                  : history.endDate
                  ? format(new Date(history.endDate), GLOBALS.DATE_FORMAT)
                  : ""}
              </Typography>
              <div className={classes.rowRight}>
                <Typography variant="body2">
                  <strong>{formatter.format(history.rentAmount)}</strong>
                </Typography>
                {history.actions ? (
                  <div className={classes.actionsRow}>{history.actions}</div>
                ) : null}
              </div>
            </div>

            <div className={classes.row}>
              <Typography variant="body2">
                {history.fullAddresss || history.address1 || "-"}
              </Typography>
              <div>
                {expanded === history.id ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <div className={classes.row}>
              <div>
                <Typography variant="body2">
                  <strong>Landlord Name</strong>
                </Typography>
                <Typography variant="body2">
                  {history.landlordName || "-"}
                </Typography>
              </div>
              <div className={classes.alignRight}>
                <Typography variant="body2">
                  <strong>Landlord Phone</strong>
                </Typography>
                <Typography variant="body2">
                  {history.landlordPhoneNumber
                    ? formatPhoneNumber(history.landlordPhoneNumber)
                    : "-"}
                </Typography>
              </div>
            </div>
            <div className={classes.row}>
              <div>
                <Typography variant="body2">
                  <strong>Reason for Leaving</strong>
                </Typography>
                <Typography variant="body2">
                  {history.reasonForLeaving || "-"}
                </Typography>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default TenantHousingHistoryAccordion;
