export const PLANS = {
  FEATURES: {
    FREE: [
      "Browse through all listings at NestIQa",
      "Create tenant profile",
      "Request to get preapproved to any listing",
    ],
    UNLIMITED: [
      "Browse through all listings at NestIQa",
      "Create tenant profile",
      "We create a tenant report for you and run a background and credit check",
      "Apply to any listing at NestIQa for 30 days",
    ],
  },
  MODELS: {
    FREE: "Free",
    UNLIMITED: "Unlimited",
  },
  PRICE: 35,
};
