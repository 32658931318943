import { GLOBALS } from "../App";
import { prepareTenantResult, prepareTenantPayload } from "./TenantApi";
import { runFetch, snakeToCamel } from "../nestiqaUtils";

export const getHousehold = async (tenantId, accessToken, callback) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.HOUSEHOLD_API}${tenantId}/`,
    "get",
    { "Content-Type": "application/json" },
    null,
    accessToken,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, prepareHouseholdResult(apiResult), apiError)
  );
};

export const addHouseholdMember = async (
  tenantId,
  member,
  accessToken,
  callback
) => {
  const preparedTenant = prepareTenantPayload(member);
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.HOUSEHOLD_API}${tenantId}/add/`,
    "post",
    { "Content-Type": "application/json" },
    JSON.stringify(preparedTenant),
    accessToken,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, prepareTenantResult(apiResult), apiError)
  );
};

export const editHouseholdMember = async (
  tenantId,
  member,
  accessToken,
  callback
) => {
  const preparedTenant = prepareTenantPayload(member);
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.HOUSEHOLD_API}${tenantId}/edit/`,
    "put",
    { "Content-Type": "application/json" },
    JSON.stringify(preparedTenant),
    accessToken,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, prepareTenantResult(apiResult), apiError)
  );
};

export const removeHouseholdMember = async (
  tenantId,
  memberId,
  accessToken,
  callback
) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.HOUSEHOLD_API}${tenantId}/remove/`,
    "put",
    { "Content-Type": "application/json" },
    JSON.stringify({ id: memberId }),
    accessToken,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, prepareHouseholdResult(apiResult), apiError)
  );
};

export const createHouseholdInvitation = async (
  tenantId,
  memberId,
  accessToken,
  callback
) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.HOUSEHOLD_API}${tenantId}/create-invitation/`,
    "post",
    { "Content-Type": "application/json" },
    JSON.stringify({ id: memberId }),
    accessToken,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, snakeToCamel(apiResult), apiError)
  );
};

export const sendInvitationEmail = async (
  tenantId,
  memberId,
  accessToken,
  callback
) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.HOUSEHOLD_API}${tenantId}/email-invitation/`,
    "post",
    { "Content-Type": "application/json" },
    JSON.stringify({ id: memberId }),
    accessToken,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, snakeToCamel(apiResult), apiError)
  );
};

export const getInvitation = async (invitationCode, accessToken, callback) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.INVITATION_API}${invitationCode}/`,
    "get",
    { "Content-Type": "application/json" },
    null,
    accessToken,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, snakeToCamel(apiResult), apiError)
  );
};

export const joinHousehold = async (invitationCode, accessToken, callback) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.INVITATION_API}${invitationCode}/accept/`,
    "post",
    { "Content-Type": "application/json" },
    {},
    accessToken,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, snakeToCamel(apiResult), apiError)
  );
};

export const leaveHousehold = async (accessToken, callback) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}/tenant/leave/`,
    "post",
    { "Content-Type": "application/json" },
    {},
    accessToken,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, snakeToCamel(apiResult), apiError)
  );
};

function prepareHouseholdResult(household) {
  if (!household) return household;

  const result = [];
  for (let item in household) {
    result.push(prepareTenantResult(household[item]));
  }
  return result;
}
