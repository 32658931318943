import { GLOBALS } from "./App";
import { ENUMS } from "./constants/enums";
import CheckIcon from "@material-ui/icons/Check";
import { omit } from "lodash";
import * as Sentry from "@sentry/react";

export function formatPrice(price) {
  return "$" + price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatNumber(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export function formatListingDate(date) {
  if (!date) return "";
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear().toString().slice(2, 4);

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [month, day, year].join("/");
}

export function getCurrentYear() {
  let d = new Date();
  return d.getFullYear();
}

export function formatPhoneNumber(phoneNumberString, dashed = false) {
  const clean = ("" + phoneNumberString).replace(/\D/g, "");
  const match = clean.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match && !dashed) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  } else if (match && dashed) {
    return match[1] + "-" + match[2] + "-" + match[3];
  }
  return null;
}

/**
 *
 * @param {string} label the label of the ENUM
 * @param {string} category the category of the ENUM
 * @param {array} currentList the array with the current features/amenities in the listing
 * @returns {JSX.Element} icon - either check icon or clear icon depending on result
 */
export function findEnumImage(inputValue, category, currentList) {
  if (currentList === "" || currentList === null || currentList === undefined)
    return null;
  const showImage = ENUMS[category].find(({ value }) => value === inputValue);
  if (showImage !== undefined) {
    return <CheckIcon />;
  }
  return null;
}

/**
 *
 * @param {string} string description of meta tag
 * @returns {string} trimmed string without cutting words in the middle
 */
export function trimContent(string, length = 70) {
  if (length <= 70) return string;
  if (string.length <= 300) return string;
  const trim = string;
  const maxLength = length;

  let trimmedString = trim.substr(0, maxLength);
  if (string.length > trimmedString.length) {
    trimmedString = trimmedString.substr(
      0,
      Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
    );
    return trimmedString;
  }
}

/**
 *
 * @param {string} string description of meta tag
 * @returns {string} trimmed string without cutting words in the middle
 */
export function trimDotted(string, length) {
  if (string.length < 30) return string;
  const trim = string;
  const maxLength = length;

  let trimmedString = trim.substr(0, maxLength);
  if (string.length > trimmedString.length) {
    trimmedString = trimmedString.substr(
      0,
      Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
    );
    return `${trimmedString}...`;
  }
}

/**
 * Input both addresses and return full Address
 * @param {string} obj input listing
 * @returns {string} full address + city, state and zip code
 * `${address1}, ${address2}, ${city}, ${state} ${zipCode}’
 * `${address1}, ${city}, ${state} ${zipCode}’
 */
export function getFullAddress(obj) {
  const isEmpty = Object.values(obj).every(x => x === "");
  if (isEmpty) return "No address";

  const { address1, address2, city, state } = obj;
  let { zipCode } = obj; // useListing returns "zipCode" but fetching application returns zip_code

  if (zipCode === undefined) {
    zipCode = obj.zip_code;
  }

  if (address2 !== "") {
    const address = trimContent(
      `${address1}, ${address2}, ${city}, ${state} ${zipCode}`
    );
    return address;
  } else {
    const address = trimContent(`${address1}, ${city}, ${state} ${zipCode}`);
    return address;
  }
}

/**
 * Finds the label from ENUMS
 * @param {string} inputValue the value of the object in array
 * @param {string} label the name of the array in ENUMS
 * @returns {string} the label of the item or an empty string
 */
export function getLabel(inputValue, label, long = false) {
  if (inputValue === null || inputValue === undefined || inputValue === "")
    return "";
  let item = GLOBALS[label].find(({ value }) => value === inputValue);
  return long ? item?.labelLong : item?.label;
}

/**
 * Finds the status label from ENUMS
 * @param {string} inputValue
 * @param {string} label the name of the array in ENUMS
 * @returns {string} the label of the item or an empty string
 */
export function getOpenApplicationStatus(inputValue, tenantName) {
  if (inputValue === null || inputValue === undefined || inputValue === "")
    return "";
  let status = GLOBALS["APP_STATUSES"].find(
    ({ value }) => value === inputValue
  );
  return status.value === "SUBMITTED"
    ? `Submitted by ${tenantName}`
    : status.label;
}

function camelToSnakeKey(key) {
  return key.replace(/([A-Z])/g, "_$1").toLowerCase();
}

function snakeToCamelKey(key) {
  return key.replace(/([-_][a-z])/g, group =>
    group.toUpperCase().replace("_", "")
  );
}

function transformKeys(original, transform) {
  if (!original || typeof original !== "object") {
    return original;
  }
  let newObject = {};
  for (let key in original) {
    if (Array.isArray(original[key])) {
      let newArray = [];
      for (let index in original[key]) {
        newArray.push(transformKeys(original[key][index], transform));
      }
      newObject[transform(key)] = newArray;
    } else if (
      typeof original[key] === "object" &&
      !(original[key] instanceof Date)
    ) {
      newObject[transform(key)] = transformKeys(original[key], transform);
    } else {
      newObject[transform(key)] = original[key];
    }
  }
  return newObject;
}

export function camelToSnake(original) {
  return transformKeys(original, camelToSnakeKey);
}

export function snakeToCamel(original) {
  return transformKeys(original, snakeToCamelKey);
}

export function fetchWithTimeout(
  url,
  options,
  timeout = GLOBALS.DEFAULT_FETCH_TIMEOUT
) {
  return Promise.race([
    fetch(url, options),
    new Promise((_, reject) =>
      setTimeout(
        () => reject(new Error(GLOBALS.MESSAGES.TIMEOUT_ERROR)),
        timeout
      )
    ),
  ]);
}

export async function runFetch(
  fetchUrl,
  fetchMethod,
  fetchHeaders,
  fetchBody,
  accessToken,
  setResultsCallback,
  processResponse = async response => await response.json()
) {
  const fetchData = {
    method: fetchMethod,
    headers: fetchHeaders,
  };
  if (fetchMethod === "post" || fetchMethod === "put") {
    fetchData.body = fetchBody;
  }
  if (accessToken) {
    fetchData.headers["Authorization"] = `Bearer ${accessToken}`;
  }
  try {
    const response = await fetchWithTimeout(fetchUrl, fetchData);
    if (response.ok) {
      let body = {};
      if (response.status !== 204) body = await processResponse(response);
      await setResultsCallback(true, body, "");
    } else {
      let message =
        "API failure: " + response.status + " " + response.statusText;
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        message = await response.json();
      }
      // Commented out Sentry reporting because it causes too many events
      // Sentry.captureMessage(
      //   "API failure: " + response.status + " " + response.statusText
      // );
      await setResultsCallback(false, "", message);
    }
  } catch (e) {
    Sentry.captureException(e);
    await setResultsCallback(false, "", e.message);
  }
}

export function getStaticImgSrc(image) {
  if (image.startsWith("data:image")) {
    return image;
  }
  return GLOBALS.REACT_BASE_URL + image;
}

export const getAddressComponents = comps => {
  const placeTypes = {
    zipcode: "postal_code",
    city: ["locality", "sublocality"],
    state: "administrative_area_level_1",
  };
  let hasPlaceType = false;
  let address = {};

  for (let i = 0; i < comps.length; i++) {
    if (comps[i].types.includes(placeTypes.zipcode)) {
      address["zc"] = comps[i].short_name;
      hasPlaceType = true;
    }
    if (
      comps[i].types.includes(placeTypes.city[0]) ||
      comps[i].types.includes(placeTypes.city[1])
    ) {
      address["ct"] = comps[i].short_name;
      hasPlaceType = true;
    }
    if (comps[i].types.includes(placeTypes.state)) {
      address["st"] = comps[i].short_name;
      hasPlaceType = true;
    }
  }

  return { hasPlaceType, address };
};

export const getSearchByDistanceProps = (
  place,
  placeSize,
  distance = GLOBALS.DEFAULT_DISTANCE
) => {
  const frlg = place.geometry.location.lng().toFixed(3);
  const frlt = place.geometry.location.lat().toFixed(3);
  const dst = Number(distance * GLOBALS.MILE_TO_KM + placeSize).toFixed(3);

  return { frlg, frlt, placeSize, dst };
};

/**
 * Checks if object has any of the properties
 * in the provided array and returns boolean
 * @param {object} adjustedFilters
 * @param {array} properties
 * @returns {boolean}
 */
export const objectHasProperties = (object, array) => {
  let results = [];
  array.map(prop =>
    object.hasOwnProperty(prop) ? results.push(true) : results.push(false)
  );

  const result = results.some(n => n === true);
  return result;
};

/**
 *
 * @param {filters object} adjustedFilters
 * @returns {adjusted filters} returns the provided
 * filters object, and only one from the address params
 * depending on which it finds first (zip, state or city)
 */
export const getAddressFilter = adjustedFilters => {
  const singleParamAddress = setAddressFilter(adjustedFilters);
  return (adjustedFilters = singleParamAddress);
};

/**
 *
 * @param {filters object} filterObj
 * @returns {filters object} returns a single parameter from an address
 * first zip code if it exists, then city, then state
 */
export const setAddressFilter = filterObj => {
  let adjustedFilters = filterObj;
  const { zc, ct, st } = adjustedFilters;
  adjustedFilters = omit(adjustedFilters, ["dst"]);

  if (zc !== undefined) {
    return (adjustedFilters = omit(adjustedFilters, ["ct", "st"]));
  }
  if (ct !== undefined) {
    return (adjustedFilters = omit(adjustedFilters, ["zc", "st"]));
  }
  if (st !== undefined) {
    return (adjustedFilters = omit(adjustedFilters, ["zc", "ct"]));
  }
};

const replaceRegex = /{.*}/;

/**
 *
 * @param {object} rowAction containing the enum props from globals
 * @param {number} appId
 * @param {number} tenantId
 * @returns {string} link for the row action on click
 */
export const getLink = (rowAction, appId, tenantId) => {
  switch (rowAction.status) {
    case "DRAFT":
      return rowAction.link.replace(replaceRegex, appId);
    case "PENDING_INFO":
      return rowAction.link.replace(replaceRegex, appId);
    case "PENDING_HOUSEHOLD":
      return rowAction.link.replace(replaceRegex, tenantId);
    case "PENDING_REPORTS":
      return rowAction.link.replace(replaceRegex, appId);
    case "PENDING_APPLY":
      return rowAction.link.replace(replaceRegex, tenantId);
    case "SUBMITTED":
      return rowAction.link.replace(replaceRegex, appId);
    default:
      return "";
  }
};

/**
 *
 * @param {object} rowAction containing the enum props from globals
 * @param {string} comment application comment
 * @param {string} tenantName
 * @returns {string} tooltip text
 */
export const getTooltip = (rowAction, comment, tenantName) => {
  switch (rowAction.status) {
    case "DRAFT":
      return rowAction.tooltipText;
    case "PENDING_INFO":
      return rowAction.tooltipText.replace(replaceRegex, comment);
    case "PENDING_HOUSEHOLD":
      return rowAction.tooltipText;
    case "PENDING_REPORTS":
      return rowAction.tooltipText;
    case "PENDING_APPLY":
      return rowAction.tooltipText;
    case "SUBMITTED":
      return rowAction.tooltipText.replace(replaceRegex, tenantName);
    case "JOIN_HOUSEHOLD":
      return rowAction.tooltipText.replace(replaceRegex, tenantName);
    default:
      return "";
  }
};

export const getLoginMethod = method => {
  switch (method) {
    case "google-oauth2":
      return "google";
    case "Username-Password-Authentication":
      return "email";
    case "facebook":
      return "facebook";
    default:
      return "facebook";
  }
};
