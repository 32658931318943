import { GLOBALS } from "../App";
import { runFetch, snakeToCamel, camelToSnake } from "../nestiqaUtils";

export const getUserInfo = async (accessToken, callback) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.USER_API}`,
    "get",
    { "Content-Type": "application/json" },
    null,
    accessToken,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, snakeToCamel(apiResult), apiError)
  );
};

export const getUserSavedSearches = async (accessToken, callback) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.USER_SAVED_SEARCHES_API}`,
    "get",
    { "Content-Type": "application/json" },
    null,
    accessToken,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, snakeToCamel(apiResult), apiError)
  );
};

export const saveSearch = async (accessToken, savedSearch, callback) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.USER_SAVED_SEARCHES_API}`,
    "post",
    { "Content-Type": "application/json" },
    JSON.stringify(camelToSnake(savedSearch)),
    accessToken,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, snakeToCamel(apiResult), apiError)
  );
};

export const updateSavedSearch = async (
  accessToken,
  id,
  savedSearch,
  callback
) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.USER_SAVED_SEARCHES_API}${id}/`,
    "put",
    { "Content-Type": "application/json" },
    JSON.stringify(camelToSnake(savedSearch)),
    accessToken,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, snakeToCamel(apiResult), apiError)
  );
};

export const deleteSavedSearch = async (accessToken, id, callback) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.USER_SAVED_SEARCHES_API}${id}/`,
    "delete",
    { "Content-Type": "application/json" },
    null,
    accessToken,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, snakeToCamel(apiResult), apiError, id)
  );
};

export const turnOffSearchAlerts = async (turnOffCode, callback) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.USER_SAVED_SEARCHES_API}${turnOffCode}/turn-off/`,
    "post",
    { "Content-Type": "application/json" },
    null,
    null,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, snakeToCamel(apiResult), apiError)
  );
};

export const newsletterSignup = async (newsletterName, email, callback) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.NEWSLETTER_API}${newsletterName}/`,
    "post",
    { "Content-Type": "application/json" },
    JSON.stringify({email: email}),
    null,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, snakeToCamel(apiResult), apiError)
  );
};