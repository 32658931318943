import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";

export default function AlertDialogBox({
  title = "",
  openDialBox = false,
  onClose,
  message = "",
  buttonMap = [],
}) {
  return (
    <Dialog
      open={openDialBox}
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="nesitqa-alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {buttonMap.map(({ buttonName, handleClick }, index) => {
          return (
            <Button onClick={handleClick} color="primary" key={index}>
              {buttonName}
            </Button>
          );
        })}
      </DialogActions>
    </Dialog>
  );
}
