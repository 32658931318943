import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import noImage from "./noimg640.png";
import Grid from "@material-ui/core/Grid";
import BathIcon from "@material-ui/icons/Bathtub";
import BedIcon from "@material-ui/icons/Hotel";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import PetsIcon from "@material-ui/icons/Pets";
import ParkingIcon from "@material-ui/icons/LocalParking";
import LaundryIcon from "@material-ui/icons/LocalLaundryService";
import { makeStyles } from "@material-ui/core/styles";
import { formatPrice, getLabel } from "../nestiqaUtils";
import { useHistory } from "react-router-dom";
import { GLOBALS } from "../App";
import { useGA4React } from "ga-4-react";
import { logEvent } from "../utils/initGa4React";

const useStyles = makeStyles(theme => ({
  card: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    border: "2px solid",
    borderColor: theme.palette.primary.dark,
    borderRadius: "0px 0px 30px 30px",
    boxShadow: theme.shadows[0],
    position: "relative",
  },
  cardMedia: {
    paddingTop: "75%", // 4:3
    borderBottom: "2px solid",
    borderColor: theme.palette.primary.dark,
    borderBottomColor: theme.palette.primary.dark,
  },
  cardContent: {
    "&:last-child": {
      paddingBottom: theme.spacing(2),
    },
  },
  addressWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: theme.spacing(1),
  },
  address: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    display: "inline",
    minWidth: 0,
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      width: "180px",
    },
  },
  price: {
    position: "absolute",
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingBottom: "4px",
    paddingTop: "4px",
    right: "0px",
    color: "white",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "0px 0px 0px 30px",
  },
  link: {
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    borderRadius: "3px",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    height: "100%",
  },
}));

export default function ListingCard(props) {
  const { listing, onClick, ...other } = props;
  const classes = useStyles();
  const history = useHistory();
  const ga = useGA4React();

  function goToListing(event, listingNumber) {
    event.preventDefault();
    if (!!ga) {
      logEvent("listing_drilldown", {
        listing_number: listingNumber,
      });
    }
    history.push(`/listing/${listingNumber}`);
  }

  const showPetsIcon =
    listing.cats_allowed ||
    listing.small_dogs_allowed ||
    listing.large_dogs_allowed;
  const showPakringIcon = ["GAR", "OFS", "FEE"].includes(listing.parking);
  const showLaundryIcon = ["INU", "ONS"].includes(listing.laundry);

  return (
    <Grid item key={props.card} {...other}>
      <Card
        className={classes.card}
        onClick={e => goToListing(e, listing.listing_number)}
      >
        <Typography className={classes.price} variant={"h5"}>
          {formatPrice(listing.rent)}
        </Typography>
        <CardMedia
          className={classes.cardMedia}
          image={listing.main_image || GLOBALS.REACT_BASE_URL + noImage}
          title="Main Image"
        />
        <CardContent className={classes.cardContent}>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <Typography variant="body1">
                {getLabel(listing.bedrooms, "BEDROOMS")}
              </Typography>
            </Grid>
            <Grid item>
              <Box pr={1}>
                <BedIcon color="secondary" />
              </Box>
            </Grid>
            <Grid item>
              <Typography variant="body1">
                {getLabel(listing.bathrooms, "BATHROOMS")}
              </Typography>
            </Grid>
            <Grid item>
              <Box pr={1}>
                <BathIcon color="secondary" />
              </Box>
            </Grid>
            {showPetsIcon && (
              <Grid item>
                <Box>
                  <PetsIcon color="secondary" />
                </Box>
              </Grid>
            )}
            {showPakringIcon && (
              <Grid item>
                <Box>
                  <ParkingIcon color="secondary" />
                </Box>
              </Grid>
            )}
            {showLaundryIcon && (
              <Grid item>
                <Box>
                  <LaundryIcon color="secondary" />
                </Box>
              </Grid>
            )}
          </Grid>
          <Box className={classes.addressWrapper}>
            <Typography className={classes.address}>
              {listing.address1}, {listing.address2}
            </Typography>
          </Box>
          <Box className={classes.addressWrapper}>
            <Typography className={classes.address}>
              {listing.city}, {listing.state} {listing.zip_code}{" "}
            </Typography>
            <a
              href={`/listing/${listing.listing_number}`}
              className={classes.link}
            >
              Details{" "}
              <ArrowForwardIosIcon style={{ fontSize: 14, marginLeft: 4 }} />
            </a>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
}
