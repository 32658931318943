import React from "react";
import {
  Typography,
  Container,
  List,
  ListItem,
  SvgIcon,
  Tooltip,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { GLOBALS } from "../App";
import { Helmet } from "react-helmet";
import { getFileIconFromFilename } from "../utils/common";
import VisibilityIcon from "@material-ui/icons/Visibility";
import sampleApplication from "./SampleApplication.jpg";
import sampleCreditReport from "./SampleCreditReport.pdf";
import sampleCriminalReport from "./SampleCriminalReport.pdf";
import sampleEvictionReport from "./SampleEvictionReport.pdf";
import { useHistory } from "react-router-dom";
import { getStaticImgSrc } from "../nestiqaUtils";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
  },
  title: {
    fontSize: "24px",
  },
  iconButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginLeft: theme.spacing(2),
  },
  listItem: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: `2px solid ${theme.palette.secondary.main}`,

    "&.MuiListItem-gutters": {
      paddingLeft: "px",
      paddingRight: "0px",
    },
  },
  fileName: {
    whiteSpace: "nowrap",
    overflowX: "hidden",
    textOverflow: "ellipsis",
    marginLeft: theme.spacing(2),
  },
  left: {
    display: "flex",
    alignItems: "center",
    overflowX: "hidden",
  },
  btnContainer: {
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
}));

const SampleReportsPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const documents = [
    {
      docName: "Sample Application.jpg",
      href: getStaticImgSrc(sampleApplication),
    },
    {
      docName: "Sample Credit Report.pdf",
      href: getStaticImgSrc(sampleCreditReport),
    },
    {
      docName: "Sample Criminal Report.pdf",
      href: getStaticImgSrc(sampleCriminalReport),
    },
    {
      docName: "Sample Eviction Report.pdf",
      href: getStaticImgSrc(sampleEvictionReport),
    },
  ];

  const handleBackClick = () => {
    history.goBack();
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Helmet>
          <title>{GLOBALS.META_TAGS.TITLE.SAMPLE_REPORTS_PAGE}</title>
        </Helmet>
        <Typography
          variant="h6"
          color="primary"
          align="center"
          className={classes.title}
        >
          Sample Application and Reports
        </Typography>
        <SampleDocuments documents={documents} />
        <div className={classes.btnContainer}>
          <Button color="primary" variant="contained" onClick={handleBackClick}>
            Back
          </Button>
        </div>
      </Container>
    </div>
  );
};

const SampleDocuments = ({ documents }) => {
  const classes = useStyles();

  return (
    <List component="div">
      {documents.map((file, index) => (
        <ListItem component="div" className={classes.listItem} key={index}>
          <div className={classes.left}>
            <SvgIcon color="secondary">
              {getFileIconFromFilename(file.docName)}
            </SvgIcon>
            <Tooltip
              placement="top-start"
              title={file.docName}
              aria-label={file.docName}
            >
              <Typography className={classes.fileName} variant="body2">
                {file.docName}
              </Typography>
            </Tooltip>
          </div>

          <div className={classes.iconButtons}>
            <>
              {/* <IconButton
                edge="end"
                aria-label="download"
                onClick={() => onDownload(file)}
              > */}
              <a
                href={file.href}
                target="_blank"
                rel="noopener noreferrer"
              >
              <VisibilityIcon color="secondary" />
              </a>
              {/* </IconButton> */}
            </>
          </div>
        </ListItem>
      ))}
    </List>
  );
};

export default SampleReportsPage;
