import React from "react";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import { MenuItem } from "@material-ui/core";

export default function YesNoField(props) {
  const {
    name,
    variant,
    margin,
    required,
    defaultValue,
    handleBlur,
    label,
    className,
  } = props;
  return (
    <Field
      component={TextField}
      required={required}
      type="text"
      name={name}
      label={label}
      className={className}
      variant={variant}
      margin={margin}
      defaultValue={defaultValue || ""}
      select
      onBlur={event => {
        event.target.name = name;
        handleBlur(event);
      }}
    >
      <MenuItem key="Y" value="Y">
        Yes
      </MenuItem>
      <MenuItem key="N" value="N">
        No
      </MenuItem>
      <MenuItem key="U" value=""></MenuItem>
    </Field>
  );
}
