import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import FiltersBox from "../components/FiltersBox";

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 200,
    backgroundColor: theme.palette.primary.main,
  },
  box: {
    borderRadius: "0px",
  },
}));

export default function FiltersDrawer(props) {
  const classes = useStyles();

  const toggleDrawer = open => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    props.setDrawerOpen(open);
  };

  const handleSearch = filters => {
    props.setDrawerOpen(false);
    props.handleSearch(filters);
  };

  return (
    <Drawer
      className={classes.drawer}
      anchor={props.anchor}
      open={props.open}
      onClose={toggleDrawer(false)}
    >
      <FiltersBox
        initFilters={props.initFilters}
        handleSearch={handleSearch}
        className={classes.box}
      />
    </Drawer>
  );
}
