// listings
export const MAKE_FILTERS = "MAKE_FILTERS";
export const SET_SEARCH_FILTERS = "SET_SEARCH_FILTERS";
export const CLEAR_FILTERS = "CLEAR_FILTERS";
export const SET_HOUSING_TYPE = "SET_HOUSING_TYPE";
export const SET_MAX_RENT = "SET_MAX_RENT";
export const SET_BEDS = "SET_BEDS";
export const SET_BATHS = "SET_BATHS";
export const SET_DISTANCE = "SET_DISTANCE";
export const SET_DISTANCE_FROM = "SET_DISTANCE_FROM";
export const CLEAR_DISTANCE_FROM = "CLEAR_DISTANCE_FROM";
export const SET_DISTANCE_FILTERS = "SET_DISTANCE_FILTERS";
export const SET_DEFAULT_DISTANCE = "SET_DEFAULT_DISTANCE";
export const SAVE_LAST_ADDRESS = "SAVE_LAST_ADDRESS";
export const TRIGGER_SEARCH = "TRIGGER_SEARCH";

// tenant
export const SET_SUBMIT_APPLICATIONS_LATER = "SET_SUBMIT_APPLICATIONS_LATER";

// reports
export const GET_AVAILABLE_REPORTS = "GET_AVAILABLE_REPORTS";
export const GET_AVAILABLE_REPORTS_LOADING = "GET_AVAILABLE_REPORTS_LOADING";
export const GET_AVAILABLE_REPORTS_ERROR = "GET_AVAILABLE_REPORTS_ERROR";
export const SET_REPORTS = "SET_REPORTS";
export const SET_SCREENING_REQUEST_ID = "SET_SCREENING_REQUEST_ID";
export const CLEAR_REPORTS = "CLEAR_REPORTS";
export const SET_CREATE_REPORTS_LATER = "SET_CREATE_REPORTS_LATER";
export const SET_REPORTS_NOTIFIED = "SET_REPORTS_NOTIFIED";
export const SET_TO_SHARE_REPORTS = "SET_TO_SHARE_REPORTS";

// active user
export const SET_USER = "SET_USER";
export const CLEAR_USER = "CLEAR_USER";

// dialog
export const OPEN_DIALOG = "OPEN_DIALOG";
export const CLOSE_DIALOG = "CLOSE_DIALOG";
