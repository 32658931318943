import React, { useEffect, useState } from "react";
import { Container } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useParams } from "react-router-dom";
import { GLOBALS } from "../App";
import {
  getTenantApplications,
  getHouseholdApplications,
} from "../utils/TenantApi";
import TenantApplicationsSection from "../components/tenant-applications/TenantApplicationsSection";
import OpenApplicationsSection from "../components/tenant-applications/OpenApplicationsSection";
import HouseholdApplicationsSection from "../components/tenant-applications/HouseholdApplicationsSection";
import { Helmet } from "react-helmet";
import useAuth0Nesitqa from "../hooks/useAuth0Nestiqa";

export default function TenantApplicationsPage() {
  const { accessToken } = useAuth0Nesitqa("");
  const { tenantId } = useParams();
  const [tenant, setTenant] = useState();
  const [error, setError] = useState();
  const [householdApps, setHouseholdApps] = useState([]);

  useEffect(() => {
    if (!accessToken) return;

    const tenantCallback = (isSuccess, apiResult, apiError) => {
      if (isSuccess) {
        setTenant(apiResult);
      } else {
        setError(apiError);
      }
    };

    getTenantApplications(tenantId, accessToken, tenantCallback);
    getHouseholdApplications(
      tenantId,
      accessToken,
      (isSuccess, apiResult, apiError) => setHouseholdApps(apiResult)
    );
  }, [accessToken, tenantId]);

  if (error) {
    console.error(error);
    return (
      <Typography variant="h5" align="center" color="error">
        {GLOBALS.MESSAGES.TENANT_NOT_FOUND} {tenantId}
      </Typography>
    );
  }

  if (!tenant) {
    return (
      <Container>
        <Helmet>
          <title>{GLOBALS.META_TAGS.TITLE.TENANT_APPLICATIONS_PAGE}</title>
        </Helmet>
        <Typography variant="h5" align="center" color="primary">
          Loading...
        </Typography>
      </Container>
    );
  }

  const openStatuses = [
    "DRAFT",
    "PENDING_INFO",
    "PENDING_HOUSEHOLD",
    "PENDING_REPORTS",
    "PENDING_APPLY",
  ];
  const openApplications = tenant.applications.filter(val =>
    openStatuses.includes(val.status)
  );
  const submittedApplications = tenant.applications.filter(
    val => !openStatuses.includes(val.status)
  );

  if (
    !householdApps.length &&
    !openApplications.length &&
    !submittedApplications.length
  ) {
    return (
      <Typography variant="h5" align="center" color="primary">
        {GLOBALS.MESSAGES.NO_APPLICATIONS}
      </Typography>
    );
  }
  return (
    <Container maxWidth="lg">
      <Helmet>
        <title>{GLOBALS.META_TAGS.TITLE.TENANT_APPLICATIONS_PAGE}</title>
      </Helmet>
      {!!householdApps.length && (
        <HouseholdApplicationsSection
          applicationHistory={householdApps}
          tenantId={tenant.id}
          title="Household Applications"
          isEditable={false}
        />
      )}
      {!!openApplications.length && (
        <OpenApplicationsSection
          applicationHistory={openApplications}
          tenantId={tenant.id}
          title="Open"
          isEditable={false}
        />
      )}
      {!!submittedApplications.length && (
        <TenantApplicationsSection
          applicationHistory={submittedApplications}
          title="Submitted"
          isEditable={false}
        />
      )}
    </Container>
  );
}
