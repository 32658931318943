import React from "react";
import { GLOBALS } from "../../App";

export default function InlineChatButton() {
  return (
    <div>
      <iframe 
        src= {GLOBALS.APP_BASE_URL + "/support/chat-button/"}
        title="Chat Button"
        style={{ width: "350px", height: "500px", border: "0px" }}
      ></iframe>      
    </div>
  );
}
