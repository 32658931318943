import React from "react";
import { Button, Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { useHistory } from "react-router";
import { GLOBALS } from "../../App";

const useStyle = makeStyles(theme => ({
  marginRight: {
    marginRight: theme.spacing(2),
  },
  buttonGroup: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(2),
  },
}));

const StartExt = ({ handleContinue, name }) => {
  const history = useHistory();
  const classes = useStyle();

  const handleCancel = () => {
    history.push("/");
  };

  return (
    <Container>
      <Typography gutterBottom>
        {GLOBALS.MESSAGES.LANDLORD_EXT_WELCOME.replace("[name]", name)}
      </Typography>
      <div className={classes.buttonGroup}>
        <Button
          className={classes.marginRight}
          color="primary"
          variant="outlined"
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disableElevation
          color="primary"
          onClick={handleContinue}
        >
          Continue
        </Button>
      </div>
    </Container>
  );
};

export default StartExt;
