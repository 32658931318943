import { Button, Tooltip, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles(theme => ({
  arrow: {
    color: "#fff",
    width: "24px",
    height: "13px",
    marginTop: "-13px !important",

    "&::before": {
      borderColor: theme.palette.primary.main,
      borderWidth: 1,
      borderStyle: "solid",
      marginTop: 0,
    },
  },
  tooltip: {
    backgroundColor: "#fff",
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
    borderStyle: "solid",
    zIndex: theme.zIndex.tooltip,
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
}));

const InstructionsTooltip = ({ children, text, open, onAction, ...props }) => {
  const classes = useStyles();
  return (
    <Tooltip
      classes={classes}
      title={
        <>
          <Typography variant="body1" gutterBottom>
            {text}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={onAction}
          >
            Got It
          </Button>
        </>
      }
      {...props}
      open={open}
      arrow
      placement="bottom-start"
      interactive
    >
      {children}
    </Tooltip>
  );
};

export default InstructionsTooltip;
