import { SET_USER, CLEAR_USER } from "./types";

export function setUser(value) {
  return function (dispatch) {
    dispatch({ type: SET_USER, payload: value });
  };
}

export function clearUser() {
  return function (dispatch) {
    dispatch({ type: CLEAR_USER });
  };
}
