import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import TenantProfileSection from "../tenant-profile/TenantProfileSection";
import TenantApplicationHistoryTable from "../tenant-applications/TenantApplicationHistoryTable";
import TenantApplicationHistoryAccordion from "../tenant-applications/TenantApplicationHistoryAccordion";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
  },
}));

const TenantApplicationsSection = ({
  applicationHistory,
  isEditable,
  title,
}) => {
  const isTabletOrAbove = useMediaQuery(theme => theme.breakpoints.up("sm"));
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TenantProfileSection title={title} isEditable={isEditable}>
        <>
          {!isTabletOrAbove ? (
            <TenantApplicationHistoryAccordion
              applicationHistory={applicationHistory}
            />
          ) : (
            <TenantApplicationHistoryTable
              applicationHistory={applicationHistory}
            />
          )}
        </>
      </TenantProfileSection>
    </div>
  );
};

export default TenantApplicationsSection;
