import React, { useEffect, useState } from "react";
import {
  useMediaQuery,
  Button,
  Typography,
  Paper,
  Link,
} from "@material-ui/core";
import clsx from "clsx";
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { makeStyles } from "@material-ui/core/styles";
import TenantPlanUpgradeDialog from "./TenantPlanUpgradeDialog";
import { GLOBALS } from "../../App";
import { logEvent } from "../../utils/initGa4React";
import {
  getPaymentIntent,
  getTenantApplications,
  applyCoupon,
} from "../../utils/TenantApi";
import { useGA4React } from "ga-4-react";
import useAuth0Nesitqa from "../../hooks/useAuth0Nestiqa";
import { getLabel } from "../../nestiqaUtils";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import homeImg from "./home.png";
import NestiqaAlertDialog from "../NestiqaAlertDialog";

const useStyles = makeStyles(theme => ({
  heading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    margin: "30px 0",

    "& .MuiTypography-root.MuiTypography-h3": {
      fontSize: "24px",
      fontWeight: 400,
      textTransform: "uppercase",
      margin: 0,
    },

    "& div": {
      width: "52px",
      height: "4px",
      background: theme.palette.primary.main,
      marginTop: "2px",
    },
  },
  banner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    maxWidth: "950px",
    margin: "0 auto",

    "& div": {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      width: "100%",

      "& .MuiTypography-root": {
        fontSize: "16px",
      },

      "& .MuiTypography-body1": {
        fontWeight: "bold",
      },

      "&:not(:first-child)": {
        flexDirection: "column",
      },

      "&:last-child": {
        alignItems: "flex-end",
      },
    },

    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",

      "& div:nth-child(2)": {
        flexBasis: "25%",
        alignItems: "flex-start",
        marginLeft: "72px",
      },

      "& div:last-child": {
        flexBasis: "50%",
      },
    },
  },
  bannerTenant: {
    alignItems: "center !important",

    "& .MuiTypography-root.MuiTypography-h5": {
      color: "#000",
      fontSize: "1.2rem",
      fontWeight: 450,
    },

    "& img": {
      borderRadius: "50%",
      objectFit: "cover",
      width: "52px",
      height: "52px",
      border: "1px solid #707070",
      marginRight: "20px",
    },
  },
  plansPaper: {
    width: "100%",
    maxWidth: "950px",
    display: "block",
    margin: isCreatePage => (isCreatePage ? "0 0 20px" : "40px auto"),
    position: "relative",
  },
  activePlan: {
    border: `5px solid ${theme.palette.secondary.main}`,

    "& $triangle": {
      position: "absolute",
      top: 0,
      right: 0,
      width: 0,
      height: 0,
      borderTop: `40px solid ${theme.palette.secondary.main}`,
      borderLeft: "40px solid transparent",
    },
  },
  triangle: {},
  titleUnderline: {
    width: "25px",
    height: "3px",
    background: theme.palette.secondary.main,
    marginTop: "2px",
  },
  ctaWrapper: {
    padding: isCreatePage =>
      isCreatePage ? theme.spacing(1) : theme.spacing(2),
    paddingBottom: 0,

    "& > img": {
      width: "89px",
      height: "78px",
    },

    "& > .MuiButtonBase-root": {
      width: "142px",
      height: isCreatePage => (isCreatePage ? "36px" : "46px"),
      fontSize: isCreatePage => (isCreatePage ? "0.875rem" : "18px"),
      fontWeight: 450,
    },

    [theme.breakpoints.down("xs")]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
  list: {
    display: "flex",
    flexDirection: "column",
    marginTop: isCreatePage =>
      isCreatePage ? theme.spacing(1) : theme.spacing(3),
  },
  listItem: {
    padding: 0,
    display: "flex",
    margin: isCreatePage => (isCreatePage ? "8px 0 0" : "10px 0"),

    "& > svg": {
      marginRight: "15px",
      marginTop: isCreatePage => (isCreatePage ? "-3px" : "0"),
    },
  },
  wrapper: {
    display: "flex",
    alignItems: "start",
    justifyContent: "space-between",
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),

    "& .MuiTypography-h4": {
      fontSize: "24px",
      fontWeight: "bold",
    },
  },
  wrapperInner: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "flex-end",

    [theme.breakpoints.down("xs")]: {
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
  },
  leftDetails: {
    display: "flex",
  },
  rightDetails: {
    display: "flex",
    flexDirection: "column",
    height: "234px",
    justifyContent: "space-between",

    [theme.breakpoints.down("xs")]: {
      flexDirection: "row",
      height: "unset",
      width: "100%",
      marginTop: theme.spacing(2),
    },
  },
  price: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",

    "& .MuiTypography-body1": {
      fontSize: "36px",
      fontWeight: "bold",
    },

    "& .MuiTypography-subtitle1": {
      fontSize: "16px",
      fontWeight: 400,
    },

    [theme.breakpoints.down("xs")]: {
      flexGrow: 1,
      flexBasis: "100%",
      width: "100%",
      alignItems: "flex-start",
    },
  },
  padBottom: {
    paddingBottom: theme.spacing(1.5),
  },
  rowDir: {
    flexDirection: "row",
  },
  colDir: {
    flexDirection: "column",
  },
  link: {
    textDecoration: "none",
    color: "white",
  },
}));

const TenantPlan = props => {
  const { isCreatePage = false } = props;
  const classes = useStyles(isCreatePage);
  const { tenantId } = useParams();
  const history = useHistory();
  const ga = useGA4React();
  const { accessToken } = useAuth0Nesitqa("");
  const [tenantResult, setTenantResult] = useState({ status: "LOADING" });

  const tenantCallback = (isSuccess, apiResult, apiError) => {
    setTenantResult({
      status: "COMPLETE",
      tenant: isSuccess ? apiResult : null,
      error: isSuccess ? null : apiError,
    });
  };

  useEffect(() => {
    if (!tenantId || !accessToken) return;
    getTenantApplications(tenantId, accessToken, tenantCallback);
  }, [tenantId, accessToken]);

  const [alertDialog, setAlertDialog] = useState({
    open: false,
  });
  const [paymentDialog, setPaymentDialog] = useState({
    open: false,
    paymentIntent: null,
  });
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("xs"));

  const upgradePlanCallback = (isSuccess, apiResult, apiError) => {
    if (isSuccess) {
      setPaymentDialog({
        open: true,
        paymentIntent: apiResult,
      });
    } else {
      setAlertDialog({
        open: true,
        title: "Error",
        message: GLOBALS.MESSAGES.ERROR_COMPLETING_ACTION,
        onClose: () => setAlertDialog({ open: false }),
      });
    }
  };

  const onPlanUpgrade = () => {
    if (!tenantId || !accessToken) return;
    getPaymentIntent(accessToken, upgradePlanCallback);
  };

  const handlePaymentSuccess = async (params = {}) => {
    if (!!ga && params) {
      logEvent("purchase", params);
    }
    setPaymentDialog({
      open: false,
      paymentIntent: null,
    });
    setAlertDialog({
      open: true,
      title: "Success",
      message: GLOBALS.MESSAGES.UPGRADE_SUCCESSFUL,
      onClose: () =>
        history.push(
          GLOBALS.PATHS.TENANT_PROFILE.replace(":tenantId", tenantId)
        ),
    });
    setTenantResult({ status: "LOADING" });
    await getTenantApplications(tenantId, accessToken, tenantCallback);
  };

  const handlePaymentFailure = stripeError => {
    setPaymentDialog({
      open: false,
      paymentIntent: null,
    });
    setAlertDialog({
      open: true,
      title: "Error",
      message: stripeError,
      onClose: () => setAlertDialog({ open: false }),
    });
  };

  const handlePaymentClose = () => {
    setPaymentDialog({
      open: false,
      paymentIntent: null,
    });
  };

  const applyCouponCallback = (isSuccess, apiResult, apiError) => {
    if (isSuccess) {
      setPaymentDialog({
        open: true,
        paymentIntent: apiResult,
        couponApplied: true,
        showBackdrop: false,
      });
    } else {
      setPaymentDialog({
        ...paymentDialog,
        showBackdrop: false,
      });
      setAlertDialog({
        open: true,
        title: "Error",
        message: GLOBALS.MESSAGES.APPLY_CODE_ERROR,
        onClose: () => setAlertDialog({ open: false }),
      });
    }
  };

  const onApplyCoupon = couponCode => {
    if (!tenantId || !accessToken) return;
    setPaymentDialog({
      ...paymentDialog,
      showBackdrop: true,
    });
    applyCoupon(couponCode, accessToken, applyCouponCallback);
  };

  if (tenantResult.error) {
    console.error(tenantResult.error);
    return (
      <Typography variant="h5" align="center" color="error">
        {GLOBALS.MESSAGES.TENANT_NOT_FOUND} {tenantId}
      </Typography>
    );
  }

  const tenant = tenantResult.tenant;

  if (!tenant) {
    return (
      <Typography variant="h5" align="center" color="primary">
        Loading...
      </Typography>
    );
  }

  return (
    <div>
      {!isCreatePage && (
        <>
          <div className={classes.heading}>
            <Typography variant="h3" align="center" color="primary">
              Choose your plan
            </Typography>
            <div />
          </div>
          <div className={classes.banner}>
            <Link
              to={`/tenant/${tenant.id}/profile`}
              component={RouterLink}
              className={classes.link}
              underline="none"
            >
              <div className={classes.bannerTenant}>
                <img src={tenant.picture} alt="" />
                <Typography variant="h5">
                  {tenant.firstName} {tenant.lastName}
                </Typography>
              </div>
            </Link>
            <div>
              <Typography variant="subtitle1">Plan:</Typography>
              <Typography variant="body1">
                {getLabel(tenant.activePlan.planType, "PLANS")}
              </Typography>
            </div>
            <div>
              <Typography
                hidden={tenant.activePlan.planType === "FR"}
                variant="subtitle1"
              >
                Expiration Date:
              </Typography>
              <Typography variant="body1">
                {tenant.activePlan.endDate}
              </Typography>
            </div>
          </div>
        </>
      )}
      <Paper
        className={clsx(
          classes.plansPaper,
          tenant.activePlan.planType === "FR" && classes.activePlan
        )}
        elevation={5}
      >
        <div className={classes.triangle} />
        <div className={clsx(classes.wrapper, classes.colDir)}>
          <Typography variant="h4">
            {GLOBALS.ACCOUNT_PLANS.MODELS.FREE}
          </Typography>
          <div className={classes.titleUnderline} />
          <div className={classes.wrapperInner}>
            <div className={classes.list}>
              {GLOBALS.ACCOUNT_PLANS.FEATURES.FREE.map(el => (
                <div key={el} className={classes.listItem}>
                  <CheckCircleOutlineIcon color="secondary" />
                  <Typography variant={isCreatePage ? "body2" : "body1"}>
                    {el}
                  </Typography>
                </div>
              ))}
            </div>
            <div className={classes.ctaWrapper}>
              <img src={homeImg} alt="" />
            </div>
          </div>
        </div>
      </Paper>
      <Paper
        className={clsx(
          classes.plansPaper,
          tenant.activePlan.planType === "UN" && classes.activePlan
        )}
        elevation={5}
      >
        <div className={classes.triangle} />
        <div
          className={clsx(
            classes.wrapper,
            isMobile ? classes.colDir : classes.rowDir
          )}
        >
          <div className={clsx(classes.leftDetails, classes.colDir)}>
            <Typography variant="h4">
              {GLOBALS.ACCOUNT_PLANS.MODELS.UNLIMITED}
            </Typography>
            <div className={classes.titleUnderline} />
            <div className={classes.list}>
              {GLOBALS.ACCOUNT_PLANS.FEATURES.UNLIMITED.map(el => (
                <div key={el} className={classes.listItem}>
                  <CheckCircleOutlineIcon color="secondary" />
                  <Typography variant={isCreatePage ? "body2" : "body1"}>
                    {el}
                  </Typography>
                </div>
              ))}
            </div>
          </div>
          <div className={classes.rightDetails}>
            <div className={classes.price}>
              <Typography variant="body1">
                ${GLOBALS.ACCOUNT_PLANS.PRICE}
              </Typography>
              <Typography variant="subtitle1">One time fee</Typography>
              <Link
                component="a"
                underline="none"
                color="primary"
                href="https://resources.nestiqa.com/faq/#why-pay"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more
              </Link>
            </div>
            <div className={clsx(classes.ctaWrapper, classes.padBottom)}>
              <Button
                variant="contained"
                color="primary"
                disabled={tenant.activePlan.planType === "UN"}
                onClick={onPlanUpgrade}
              >
                Upgrade
              </Button>
              <NestiqaAlertDialog {...alertDialog} />
              <Elements stripe={GLOBALS.STRIPE_PROMISE}>
                <TenantPlanUpgradeDialog
                  {...paymentDialog}
                  handleApplyCoupon={onApplyCoupon}
                  handleSuccess={handlePaymentSuccess}
                  handleFailure={handlePaymentFailure}
                  handleClose={handlePaymentClose}
                />
              </Elements>
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default TenantPlan;
