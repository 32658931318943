import React from "react";

const Facebook = props => {
  return (
    <svg width="28" height="28" xmlns="true" fill="#3ce0e5" {...props}>
      <svg
        xmlns="true"
        width="28"
        height="28"
        viewBox="0.61 0.662 32.677 32.677"
      >
        <path
          paintOrder="stroke fill markers"
          fillRule="evenodd"
          d="M33.287 17c0-9.024-7.314-16.338-16.338-16.338C7.924.662.61 7.976.61 17c0 9.024 7.314 16.338 16.339 16.338.095 0 .191 0 .287-.006V20.62h-3.51v-4.091h3.51v-3.013c0-3.49 2.131-5.393 5.246-5.393 1.493 0 2.776.109 3.146.16v3.65h-2.144c-1.691 0-2.023.805-2.023 1.985v2.604h4.053l-.53 4.091H21.46v12.095C28.29 30.747 33.287 24.46 33.287 17z"
        />
      </svg>
    </svg>
  );
};

export default Facebook;
