import { useState, useEffect } from "react";
import { GLOBALS } from "../App";

export default function useProfileCompleteness(tenant) {
  const [messages, setMessages] = useState({
    mandatory: [],
    niceToHave: [],
  });
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (!tenant) return;

    if (tenant.profileCompleteness) {
      setProgress(tenant.profileCompleteness);
    }
    if (tenant.profile_completeness) {
      setProgress(tenant.profile_completeness);
    }
  }, [tenant]);

  useEffect(() => {
    if (!tenant) return;

    const {
      PROGRESS_BAR: { MANDATORY },
    } = GLOBALS.MESSAGES;

    let mandatory = [],
      niceToHave = [];
    if (tenant.toComplete && !!tenant.toComplete.length) {
      const missingDetails = tenant.toComplete.split(",");
      missingDetails.map(item =>
        Object.keys(MANDATORY).includes(item)
          ? mandatory.push(item)
          : niceToHave.push(item)
      );
      setMessages({
        mandatory: mandatory,
        niceToHave: niceToHave,
      });
    } else if (tenant.to_complete && !!tenant.to_complete.length) {
      const missingDetails = tenant.to_complete.split(",");
      missingDetails.map(item =>
        Object.keys(MANDATORY).includes(item)
          ? mandatory.push(item)
          : niceToHave.push(item)
      );
      setMessages({
        mandatory: mandatory,
        niceToHave: niceToHave,
      });
    }
  }, [tenant]);

  return {
    progress,
    messages,
  };
}
