import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ListingApplicationTable from "./ListingApplicationTable";
import ListingApplicationAccordion from "./ListingApplicationAccordion";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  box: {
    width: "calc(100% + 50px)",
    marginLeft: "-25px",
    paddingLeft: "25px",
    paddingTop: "2.5rem",
    paddingBottom: "3.5rem",

    "&:not(:first-of-type)": {
      borderTop: "2px solid black",
    },
  },
  text: {
    fontSize: "24px",
    fontWeight: 600,
  },
}));

export default function ListingApplications(props) {
  const { applications } = props;
  const isTabletOrAbove = useMediaQuery(theme => theme.breakpoints.up("sm"));
  const history = useHistory();
  const classes = useStyles();

  if (!applications) {
    return <div />;
  }

  function goToApplication(event, applicationId) {
    event.preventDefault();
    history.push(`/landlord/application/${applicationId}`);
  }

  return (
    <>
      <Box className={classes.box}>
        <Typography
          className={classes.text}
          variant="h5"
          align="left"
          color="primary"
        >
          Active
        </Typography>
        {!isTabletOrAbove ? (
          <ListingApplicationAccordion
            onItemClick={goToApplication}
            applications={applications.filter(
              val => val.status === "SUBMITTED"
            )}
          />
        ) : (
          <ListingApplicationTable
            onItemClick={goToApplication}
            applications={applications.filter(
              val => val.status === "SUBMITTED"
            )}
          />
        )}
      </Box>
      <Box className={classes.box}>
        <Typography
          className={classes.text}
          variant="h5"
          align="left"
          color="primary"
        >
          Approved
        </Typography>
        {!isTabletOrAbove ? (
          <ListingApplicationAccordion
            onItemClick={goToApplication}
            applications={applications.filter(val => val.status === "APPROVED")}
          />
        ) : (
          <ListingApplicationTable
            onItemClick={goToApplication}
            applications={applications.filter(val => val.status === "APPROVED")}
          />
        )}
      </Box>
      <Box className={classes.box}>
        <Typography
          className={classes.text}
          variant="h5"
          align="left"
          color="primary"
        >
          Rejected
        </Typography>
        {!isTabletOrAbove ? (
          <ListingApplicationAccordion
            onItemClick={goToApplication}
            applications={applications.filter(val => val.status === "REJECTED")}
          />
        ) : (
          <ListingApplicationTable
            onItemClick={goToApplication}
            applications={applications.filter(val => val.status === "REJECTED")}
          />
        )}
      </Box>
      <Box className={classes.box}>
        <Typography
          className={classes.text}
          variant="h5"
          align="left"
          color="primary"
        >
          Pending Tenant
        </Typography>
        {!isTabletOrAbove ? (
          <ListingApplicationAccordion
            onItemClick={goToApplication}
            applications={applications.filter(val =>
              val.status.startsWith("PENDING")
            )}
          />
        ) : (
          <ListingApplicationTable
            onItemClick={goToApplication}
            applications={applications.filter(val =>
              val.status.startsWith("PENDING")
            )}
          />
        )}
      </Box>
    </>
  );
}
