import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import { RadioGroup } from "formik-material-ui";
import * as yup from "yup";
import {
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button,
  Typography,
  FormControlLabel,
  Radio,
  LinearProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import { GLOBALS } from "../../App";
import { getExam, submitAnswers } from "../../utils/ReportsApi";
import {
  callGetAvailableReports,
  setScreeningRequestId,
  setReportStatus,
} from "../../store/actions/reportsActions";

const useStyles = makeStyles(theme => ({
  dialogAction: {
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  exam: {
    width: "100%",
  },
  question: {
    marginBottom: theme.spacing(3),
  },
  content: {
    padding: theme.spacing(3),
  },
}));

const NeedVerification = ({ accessToken, showError, tenantFullName }) => {
  const [open, setOpen] = useState(true);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [exam, setExam] = useState({
    result: "",
  });

  const getExamCalback = (isSuccess, result, error) => {
    setLoading(false);

    if (isSuccess) {
      setExam(result);
    }
    if (error) {
      showError();
      setOpen(false);
      console.error(error);
    }
  };

  async function callGetExam() {
    setLoading(true);
    getExam(accessToken, getExamCalback);
  }

  const submitAnswersCalback = formikBags => (isSuccess, result, error) => {
    formikBags.setSubmitting(false);

    if (isSuccess) {
      if (result.result === "Questioned") {
        setExam({ result: "Failed" });
      } else {
        setExam(result);
      }
    }
    if (error) {
      showError();
      setOpen(false);
      console.error(error);
    }
  };

  async function callSubmitAnswers(values, formikBags) {
    const answers = Object.keys(values).map(key => ({
      questionKeyName: key,
      selectedChoiceKeyName: values[key],
    }));

    submitAnswers(
      accessToken,
      exam.examId,
      answers,
      submitAnswersCalback(formikBags)
    );
  }

  const dialogContent = useMemo(() => {
    let dialogContent = null;

    switch (exam.result) {
      case "ManualVerificationRequired":
        dispatch(setScreeningRequestId(exam.screeningRequestId));
        dispatch(setReportStatus("NEED_MANUAL_VERIFICATION"));
        setOpen(false);
        break;

      case "Questioned":
        const initialValues = {};
        const fieldValidations = {};
        exam.authenticationQuestions.forEach(q => {
          initialValues[q.questionKeyName] = "";
          fieldValidations[q.questionKeyName] = yup
            .string()
            .required(GLOBALS.MESSAGES.FIELD_REQUIRED);
        });

        const validationSchema = yup.object({
          ...fieldValidations,
        });
        dialogContent = (
          <div className={classes.exam}>
            <Formik
              validationSchema={validationSchema}
              onSubmit={(values, formikBags) => {
                callSubmitAnswers(values, formikBags);
              }}
              initialValues={initialValues}
            >
              {formikprops => (
                <>
                  <Form>
                    {exam.authenticationQuestions.map((question, idx) => (
                      <div className={classes.question} key={idx}>
                        <Typography variat="body1">
                          {idx + 1}. {question.questionDisplayName}
                        </Typography>
                        <Field
                          type="radio"
                          required
                          component={RadioGroup}
                          name={question.questionKeyName}
                        >
                          {question.choices.map((choice, idx) => (
                            <FormControlLabel
                              key={idx}
                              value={choice.choiceKeyName}
                              control={
                                <Radio
                                  color="primary"
                                  disabled={formikprops.isSubmitting}
                                />
                              }
                              label={choice.choiceDisplayName}
                              disabled={formikprops.isSubmitting}
                            />
                          ))}
                        </Field>
                      </div>
                    ))}
                  </Form>
                  {formikprops.isSubmitting && (
                    <LinearProgress className={classes.question} />
                  )}

                  <Button
                    variant="contained"
                    color="primary"
                    disabled={formikprops.isSubmitting || loading}
                    onClick={formikprops.submitForm}
                  >
                    Submit
                  </Button>
                </>
              )}
            </Formik>
          </div>
        );
        break;

      case "Failed":
        dialogContent = (
          <>
            <DialogContentText>
              {GLOBALS.MESSAGES.VERIFICATION_FAILED}
            </DialogContentText>
            {loading && (
              <Typography variant="h5" align="center" color="primary">
                Loading...
              </Typography>
            )}
            <Button
              disabled={loading}
              variant="contained"
              color="primary"
              onClick={callGetExam}
            >
              Try Again
            </Button>
          </>
        );
        break;

      case "Passed":
        dialogContent = null;
        dispatch(callGetAvailableReports(accessToken));

        break;

      default:
        dialogContent = (
          <>
            <DialogContentText>
              {GLOBALS.MESSAGES.NEED_VERIFICATION}
            </DialogContentText>
            {loading && (
              <Typography variant="h5" align="center" color="primary">
                Loading...
              </Typography>
            )}
            <Button
              disabled={loading}
              variant="contained"
              color="primary"
              onClick={callGetExam}
            >
              Continue
            </Button>
          </>
        );
        break;
    }

    return dialogContent;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accessToken,
    exam.authenticationQuestions,
    exam.result,
    exam.screening_request_id,
    loading,
  ]);

  return (
    <div className={classes.root}>
      <Dialog open={open}>
        <DialogTitle>Identity Verification: {tenantFullName}</DialogTitle>
        <DialogContent className={classes.content}>
          {dialogContent}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default NeedVerification;
