export const MLS_INFO = {
    GSMLS: {
        name: "Garden State MLS",
        logo: "https://storage.googleapis.com/dev-nestiqa-media-public/landlord-docs/GSMLS/gsmls_logo.jpg",
        disclosures: "The data displayed relating to real estate for sale comes in part from the IDX Program of Garden State Multiple Listing Service, L.L.C. Real estate listings held by other brokerage firms are marked as IDX Listing. Information deemed reliable but not guaranteed. Copyright © [year] Garden State Multiple Listing Service, L.L.C. All rights reserved. Notice: The dissemination of listings displayed herein does not constitute the consent required by N.J.A.C. 11:5.6.1 (n) for the advertisement of listings exclusively for sale by another broker. Any such consent must be obtained in writing from the listing broker. This information is being provided for Consumers’ personal, non-commercial use and may not be used for any purpose other than to identify prospective properties Consumers may be interested in purchasing.",
        site: "https://www.gsmls.com/",
        partnerName: "Improov Homes",
        partnerPhone: "(862) 800-6855",
    },
    JERSEYMLS: {
        name: "Central Jersey MLS",
        logo: "https://storage.googleapis.com/dev-nestiqa-media-public/landlord-docs/JERSEYMLS/logo.jpg",
        disclosures: "The data relating to real estate for sale on this web-site comes in part from the Internet Listing Display database of the CENTRAL JERSEY MULTIPLE LISTING SYSTEM, INC. Real estate listings held by brokerage firms other than this site-owner are marked with the ILD logo. The CENTRAL JERSEY MULTIPLE LISTING SYSTEM, INC. does not warrant the accuracy, quality, reliability, suitability, completeness, usefulness or effectiveness of any information provided. The information being provided is for consumers' personal, non-commercial use and may not be used for any purpose other than to identify properties the consumer may be interested in purchasing or renting. Copyright [year], CENTRAL JERSEY MULTIPLE LISTING SYSTEM, INC. All rights reserved. The CENTRAL JERSEY MULTIPLE LISTING SYSTEM, INC retains all rights, title and interest in and to its trademarks, service marks and copyrighted material.",
        site: "https://www.centraljerseymls.com/",
        partnerName: "Improov Homes",
        partnerPhone: "(862) 800-6855",
    },
  };