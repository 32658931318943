import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { DialogContent, DialogContentText } from "@material-ui/core";

export default function NestiqaActionDialog({
  title = "",
  open = false,
  onClose,
  buttonMap = [],
  message = "",
  style,
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="nestiqa-action-dialog"
      aria-describedby="nesitqa-action-dialog-description"
    >
      <DialogTitle id="nesitqa-alert-dialog-title">{title}</DialogTitle>
      <DialogContent style={style}>
        {typeof message === "string" ? (
          <DialogContentText id="nesitqa-alert-dialog-description">
            {message}
          </DialogContentText>
        ) : (
          message
        )}
      </DialogContent>
      <DialogActions>
        {buttonMap?.map(({ buttonName, handleClick }, index) => {
          return (
            <Button onClick={handleClick} color="primary" key={index}>
              {buttonName}
            </Button>
          );
        })}
      </DialogActions>
    </Dialog>
  );
}
