import React, { useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import { TenantLockedContext } from "../../pages/TenantProfilePage";
import { GLOBALS } from "../../App";

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(4),
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    borderLeft: "1px solid",
    borderRight: "1px solid",
    borderTop: "1px solid",
    borderColor: theme.palette.primary.dark,
    borderBottom: "none",
    borderRadius: "0 20px 0 0",
    textTransform: "capitalize",
    fontSize: "1.2rem",
  },
  headerRight: {
    borderBottom: "1px solid",
    borderColor: theme.palette.primary.dark,
    flexGrow: 2,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingLeft: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(3),
    borderBottom: "1px solid",
    borderLeft: "1px solid",
    borderRight: "1px solid",
    borderRadius: "0 0 20px 20px",
    borderColor: theme.palette.primary.dark,
  },
  button: {
    textTransform: "capitalize",
  },
  [theme.breakpoints.up("sm")]: {
    title: {
      fontSize: "1.5rem",
    },
  },
}));

const TenantProfileSection = ({
  title,
  onEditButtonClick,
  isEditable = true,
  editButtonText = "Edit",
  showEditIcon = true,
  children,
  disableEdit = false,
}) => {
  const classes = useStyles();
  const isLocked = useContext(TenantLockedContext) ?? false;
  const lockButton = isLocked && !["Household", "Documents"].includes(title);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.header}>
          <Typography className={classes.title} variant="h5">
            {title}
          </Typography>
          <div className={classes.headerRight}>
            {isEditable && !lockButton && onEditButtonClick && (
              <Button
                color="primary"
                variant="contained"
                startIcon={showEditIcon ? <EditIcon /> : null}
                onClick={onEditButtonClick}
                className={classes.button}
                disabled={disableEdit}
              >
                {editButtonText}
              </Button>
            )}
            {isEditable && lockButton && (
              <Tooltip
                title={GLOBALS.MESSAGES.LOCKED_TENANT}
                placement="top-end"
                enterTouchDelay={0}
              >
                <div>
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<EditIcon />}
                    className={classes.button}
                    disabled
                  >
                    Edit
                  </Button>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
        <div className={classes.content}>{children}</div>
      </div>
    </>
  );
};

TenantProfileSection.propTypes = {
  title: PropTypes.string.isRequired,
  onEditButtonClick: PropTypes.func,
  isEditable: PropTypes.bool,
  children: PropTypes.node.isRequired,
  disableEdit: PropTypes.bool,
};

export default TenantProfileSection;
