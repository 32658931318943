import React, { useState, useEffect } from "react";
import useTenant from "../hooks/useTenant";
import { useParams } from "react-router-dom";
import { Container, Typography, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { GLOBALS } from "../App";
import { useLocation, useHistory } from "react-router-dom";
import TenantPersonalInfo from "../components/tenant/TenantPersonalInfo";
import TenantHouseHold from "../components/tenant/TenantHousehold";
import TenantHousingHistory from "../components/tenant/TenantHousingHistory";
import TenantEmploymentHistory from "../components/tenant/TenantEmploymentHistory";
import TenantBackgroundInfo from "../components/tenant/TenantBackgroundInfo";
import TenantAdditionalInfo from "../components/tenant/TenantAdditionalInfo";
import TenantDocuments from "../components/tenant/TenantDocuments";
import useAuth0Nesitqa from "../hooks/useAuth0Nestiqa";
import NestiqaAlertDialog from "../components/NestiqaAlertDialog";
import createTenantBg from "./create_tenant_bg.png";
import { Helmet } from "react-helmet";
import CreateTenantPlan from "../components/tenant/CreateTenantPlan";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: "calc(100vh - 80px - 64px)",
    backgroundImage: `url(${GLOBALS.REACT_BASE_URL}${createTenantBg})`,
    backgroundPosition: "64px calc(100% + 64px)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "110%",
    marginBottom: "-64px",
    paddingBottom: "64px",
  },
}));

export default function CreateTenantPage() {
  const classes = useStyles();
  const { tenantId } = useParams();
  const location = useLocation();
  const history = useHistory();
  const [tenantParams] = useState({
    tenantId: tenantId,
    action: "get-tenant",
  });
  const tenantResult = useTenant(tenantParams);
  const [tenant, setTenant] = useState();
  const [step, setStep] = useState(1);
  const { accessToken } = useAuth0Nesitqa("");
  const [dialogControls, setDialogControls] = useState({
    open: false,
    title: "",
    message: "",
  });
  const [checkBoxState, setCheckBoxState] = useState({
    isOnlyOccupant: {
      state: false,
      error: false,
    },
    noPreviousEmployment: {
      state: false,
      error: false,
    },
    noPets: {
      state: false,
      error: false,
    },
    noVehicles: {
      state: false,
      error: false,
    },
  });

  const [currentAddressLength, setCurrentAddressLength] = useState({
    state: 0,
    error: false,
  });

  const setCheckBoxValue = (key, value) => {
    setCheckBoxState(prev => ({ ...prev, [key]: { ...prev[key], ...value } }));
  };

  useEffect(() => {
    if (tenantResult.status === "COMPLETE" && tenantResult.tenant) {
      setTenant(tenantResult.tenant);
    }

    if (location.state && location.state.from === "/redirect") {
      setDialogControls({
        open: true,
        title: "Welcome to Nestiqa!",
        message: (
          <>
            {GLOBALS.MESSAGES.WELCOME_MESSAGE_SIGNUP}{" "}
            {GLOBALS.MESSAGES.TIPS_FOR_STRONG_PROFILE}{" "}
            <Link
              component="a"
              underline="none"
              color="primary"
              href="https://resources.nestiqa.com/faq/#how-to-build-tenant-profile"
              target="_blank"
              rel="noopener noreferrer"
            >
              click here
            </Link>
          </>
        ),
      });
    }
  }, [tenantResult, location.state]);

  if (tenantResult.error) {
    console.error(tenantResult.error);
    return (
      <Typography variant="h5" align="center" color="error">
        {GLOBALS.MESSAGES.TENANT_NOT_FOUND} {tenantId}
      </Typography>
    );
  }

  if (!tenant) {
    return (
      <Typography variant="h5" align="center" color="primary">
        Loading...
      </Typography>
    );
  }

  function showError(message) {
    setDialogControls({
      open: true,
      title: "Error",
      message: message,
    });
  }

  const stepProps = {
    step: step,
    setStep: setStep,
    tenant: tenant,
    setTenant: setTenant,
    accessToken: accessToken,
    showError: showError,
  };

  function showStep() {
    switch (step) {
      case 1:
        return <TenantPersonalInfo {...stepProps} />;
      case 2:
        return (
          <TenantHouseHold
            {...stepProps}
            isOnlyOccupant={checkBoxState.isOnlyOccupant}
            setCheckBoxValue={setCheckBoxValue}
          />
        );
      case 3:
        return (
          <TenantHousingHistory
            {...stepProps}
            currentAddressLength={currentAddressLength}
            setCurrentAddressLength={setCurrentAddressLength}
          />
        );
      case 4:
        return (
          <TenantEmploymentHistory
            {...stepProps}
            noPreviousEmployment={checkBoxState.noPreviousEmployment}
            setCheckBoxValue={setCheckBoxValue}
          />
        );
      case 5:
        return <TenantBackgroundInfo {...stepProps} />;
      case 6:
        return (
          <TenantAdditionalInfo
            {...stepProps}
            noVehicles={checkBoxState.noVehicles}
            noPets={checkBoxState.noPets}
            setCheckBoxValue={setCheckBoxValue}
          />
        );
      case 7:
        return <TenantDocuments {...stepProps} />;

      case 8:
        return <CreateTenantPlan {...stepProps} />;
      default:
        return <div />;
    }
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{GLOBALS.META_TAGS.TITLE.CREATE_TENANT_PAGE}</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Container maxWidth="lg">
        {showStep()}
        <NestiqaAlertDialog
          {...dialogControls}
          onClose={() => {
            setDialogControls({ open: false });
            history.replace(location.pathname);
          }}
        />
      </Container>
    </div>
  );
}
