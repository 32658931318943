import React, { useState } from "react";
import clsx from "clsx";
import { Field } from "formik";
import { TextField } from "formik-material-ui";
import InputAdornment from "@material-ui/core/InputAdornment";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";

import { makeStyles } from "@material-ui/core/styles";
import * as yup from "yup";

import LandlordFormControls from "./landlordFormControls";
import { updateLandlord } from "../../utils/LandlordApi";
import { getAddressFromPlace } from "../../utils/common";
import ChoiceField from "../ChoiceField";
import { GLOBALS } from "../../App";
import { STATES } from "../../constants/states";
import GoogleMapsAutoComplete from "../google-maps/GoogleMapsAutocomplete";
import { Typography } from "@material-ui/core";
import AcceptTermsDialog from "../AcceptTermsDialog";

const useStyles = makeStyles(theme => ({
  inputField: {
    width: "100%",

    "& .MuiInputLabel-root": {
      color: theme.palette.grey[400],
    },

    "& .MuiInputLabel-shrink": {
      color: theme.palette.primary.main,
    },
  },
  addressGrid: {
    maxWidth: "450px",
    margin: "0 auto",
  },
  state: {
    "& .MuiSelect-icon": {
      right: "40px",
    },
  },
  stepHeading: {
    color: theme.palette.primary.main,
    paddingBottom: theme.spacing(4),
  },
  stepSubText: {
    paddingBottom: theme.spacing(4),
  },
  [theme.breakpoints.down("sm")]: {
    stepHeading: {
      fontSize: "1.8rem",
    },
  },
  [theme.breakpoints.up("md")]: {
    stepHeading: {
      textTransform: "uppercase",
      position: "relative",

      "&::after": {
        content: "''",
        width: "52px",
        height: "4px",
        position: "absolute",
        left: "50%",
        marginLeft: "-26px",
        bottom: "20px",
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
}));

export default function LandlordAddress(props) {
  const {
    step,
    setStep,
    accessToken,
    landlord,
    setLandlord,
    showError,
    closeDialog,
    acceptedTerms,
    setAcceptedTerms,
  } = props;

  const [acceptDialogControls, setAcceptDialogControls] = useState({
    open: false,
  });

  const closeAcceptTermsDialog = () => {
    setAcceptDialogControls({ open: false });
    closeDialog();
  };

  const classes = useStyles(props);

  function onSubmit(values, formikBag) {
    updateLandlord(values, accessToken, (isSuccess, apiResult, apiError) => {
      if (isSuccess) {
        setLandlord(landlord => ({ ...landlord, ...apiResult }));
        if (acceptedTerms === "N") {
          setAcceptDialogControls({
            open: true,
            onClose: closeAcceptTermsDialog,
            showError,
            accessToken,
            setAcceptedTerms,
          });
        } else {
          closeDialog();
        }
      } else {
        console.error(apiError);
        showError(GLOBALS.MESSAGES.ERROR_UPDATING_LANDLORD);
        formikBag.setSubmitting(false);
      }
    });
  }

  function handleSave(formikprops) {
    if (formikprops.dirty) {
      formikprops.submitForm();
    } else {
      closeDialog();
    }
  }

  const validationSchema = yup.object().shape({
    address1: yup
      .string()
      .required(GLOBALS.MESSAGES.FIELD_REQUIRED)
      .min(1)
      .max(50)
      .matches(
        GLOBALS.FORBIDDEN_CHARACTERS_REGEX,
        GLOBALS.MESSAGES.FORBIDDEN_CHARACTERS
      )
      .label("Street Address"),
    address2: yup.string().max(30).label("Apartment, floor or unit"),
    city: yup.string().required(GLOBALS.MESSAGES.FIELD_REQUIRED),
    state: yup
      .string()
      .matches(GLOBALS.US_STATE_REGEX, GLOBALS.MESSAGES.ENTER_VALID_STATE),
    zipCode: yup
      .string()
      .matches(GLOBALS.US_ZIP_REGEX, GLOBALS.MESSAGES.ENTER_VALID_ZIP),
  });

  return (
    <>
      <LandlordFormControls
        step={step}
        setStep={setStep}
        landlord={landlord}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        handleSave={handleSave}
        closeDialog={closeDialog}
        className={classes.root}
      >
        <LandlordAddressFormikWrapper landlord={landlord} />
      </LandlordFormControls>
      <AcceptTermsDialog {...acceptDialogControls} />
    </>
  );
}

const LandlordAddressFormikWrapper = ({ formikprops, landlord }) => {
  const classes = useStyles();
  const { values, setValues } = formikprops;

  const handleAddressChange = (newPlace, manual) => {
    if (!newPlace) return;
    if (manual) {
      setValues({
        ...values,
        address1: newPlace.address_components[0].short_name,
      });
    } else {
      const address = getAddressFromPlace(newPlace);
      setValues({ ...values, ...address });
    }
  };

  return (
    <div className={classes.addressGrid}>
      <Typography className={classes.stepHeading} variant="h4" align="center">
        Address
      </Typography>

      <Typography className={classes.stepSubText} variant="body1">
        {GLOBALS.MESSAGES.LANDLORD_ADDRESS_MSG}
      </Typography>
      <GoogleMapsAutoComplete
        className={clsx(classes.inputField, classes.address1)}
        label="Street Address"
        required
        inputVariant="outlined"
        name="address1"
        popupIcon={null}
        resultTypes={["address"]}
        initialValue={formikprops.values.address1}
        shortLabel
        allowManual
        onInputChange={newInputValue =>
          setValues({ ...values, address1: newInputValue })
        }
        handlePlaceChange={(place, placeSize, manual) =>
          handleAddressChange(place, manual, values, setValues)
        }
      />
      <Field
        className={clsx(classes.inputField, classes.address2)}
        component={TextField}
        name={`address2`}
        type="text"
        label="Apartment, floor or unit"
        variant="outlined"
        margin="normal"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <HomeOutlinedIcon color="secondary" />
            </InputAdornment>
          ),
        }}
        inputProps={{
          autoComplete: "off",
        }}
      />
      <Field
        className={clsx(classes.inputField, classes.city)}
        component={TextField}
        required
        name={`city`}
        type="text"
        label="City"
        variant="outlined"
        margin="normal"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <HomeOutlinedIcon color="secondary" />
            </InputAdornment>
          ),
        }}
        inputProps={{
          autoComplete: "off",
        }}
      />
      <Field
        className={clsx(classes.inputField, classes.state)}
        component={ChoiceField}
        required
        name={`state`}
        type="text"
        label="State"
        variant="outlined"
        margin="normal"
        choices={STATES}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <HomeOutlinedIcon color="secondary" />
            </InputAdornment>
          ),
        }}
        inputProps={{
          autoComplete: "off",
        }}
      />
      <Field
        className={clsx(classes.inputField, classes.zipCode)}
        component={TextField}
        required
        name={`zipCode`}
        type="text"
        label="Zip Code"
        variant="outlined"
        margin="normal"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <HomeOutlinedIcon color="secondary" />
            </InputAdornment>
          ),
        }}
        inputProps={{
          autoComplete: "off",
        }}
      />
    </div>
  );
};
