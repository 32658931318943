import React, { useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Link, Button } from "@material-ui/core";
import { GLOBALS } from "../../App";
import NestiqaActionDialog from "../NestiqaActionDialog";
import { updateApplication } from "../../utils/ApplicationApi";
import NestiqaAlertDialog from "../NestiqaAlertDialog";
import useAuth0Nesitqa from "../../hooks/useAuth0Nestiqa";

const useStyles = makeStyles(theme => ({
  root: {
    position: "absolute",
    maxWidth: "350px",
    top: "100px",
  },
  btnGroup: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: theme.spacing(1),
  },
  btn: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  [theme.breakpoints.down("sm")]: {
    root: {
      position: "relative",
      display: "block",
      top: "initial",
      marginBottom: theme.spacing(5),
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      maxWidth: "500px",
      margin: "0px auto",
      textAlign: "center",
    },
  },
}));

const DraftNotification = ({ draftApplication, setTenant }) => {
  const classes = useStyles();
  const history = useHistory();

  const { accessToken } = useAuth0Nesitqa("");

  const [messageDialogControls, setMessageDialogControls] = useState({
    open: false,
  });
  const [actionDialogControls, setActionDialogControls] = useState({
    open: false,
  });

  const yesNoButtonMap = [
    {
      buttonName: "No",
      handleClick: () => setActionDialogControls({ open: false }),
    },
    {
      buttonName: "Yes",
      handleClick: () => handleDeleteApplication(),
    },
  ];

  async function handleDeleteApplication() {
    const response = await updateApplication(
      draftApplication.id,
      { status: "DELETED" },
      accessToken
    );
    if (response.status === "DELETED") {
      setTenant(tenant => ({
        ...tenant,
        applications: [],
      }));
      setActionDialogControls({ open: false });
    } else {
      handleError();
    }
  }

  function handleError() {
    setMessageDialogControls({
      open: true,
      title: "Error",
      message: GLOBALS.MESSAGES.ERROR_COMPLETING_ACTION,
      onClose: () => setMessageDialogControls({ open: false }),
    });
  }

  return (
    <>
      <NestiqaAlertDialog {...messageDialogControls} />
      <NestiqaActionDialog {...actionDialogControls} />
      <div className={classes.root}>
        <Typography variant="body1" color="primary">
          You have an unfinished application{" "}
          <Link
            component={RouterLink}
            underline="always"
            color="primary"
            to={`/listing/${draftApplication.listing.id}`}
          >
            {draftApplication.listing.address1}
          </Link>
          . Click below to continue when your profile is ready.
        </Typography>
        <div className={classes.btnGroup}>
          <Button
            className={classes.btn}
            variant="outlined"
            color="primary"
            onClick={() =>
              setActionDialogControls({
                open: true,
                title: "Cancel Application",
                message: GLOBALS.MESSAGES.CANCEL_APPLICATION,
                buttonMap: yesNoButtonMap,
              })
            }
          >
            Cancel
          </Button>
          <Button
            className={classes.btn}
            variant="contained"
            color="primary"
            onClick={() => {
              history.push(`/application/${draftApplication.id}/update`);
            }}
          >
            Continue
          </Button>
        </div>
      </div>
    </>
  );
};

export default DraftNotification;
