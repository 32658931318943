import React from "react";
import Iframe from "react-iframe";
import { makeStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import { GLOBALS } from "../App";

const useStyles = makeStyles(theme => ({
  iframe: {
    height: "6000px",
    border: "0",
  },
}));

export default function PrivacyPolicyPage() {
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>{GLOBALS.META_TAGS.TITLE.PRIVACY_POLICY_PAGE}</title>
      </Helmet>
      <Iframe
        url="https://app.termly.io/document/privacy-policy/f9a28a6c-0aee-4376-9d3d-23f44891ab55"
        width="100%"
        className={classes.iframe}
        id="privacyPolicy"
        display="initial"
        position="relative"
      />
    </>
  );
}
