import React from "react";

const BackArrowIcon = props => {
  return (
    <svg xmlns="true" width="30" height="30" viewBox="0 0 30 30" {...props}>
      <g id="Group_604" data-name="Group 604" transform="translate(-20 -69)">
        <g
          id="Back-Arrow-Icon"
          transform="translate(20 69)"
          fill="#fff"
          stroke="#8901f8"
          strokeWidth="1"
        >
          <circle cx="15" cy="15" r="15" stroke="none" />
          <circle cx="15" cy="15" r="14.5" fill="none" />
        </g>
        <path
          id="Back-Arrow-Icon-Path"
          d="M5.1,9.419,0,4.317,5.1,0"
          transform="translate(32.394 79.239)"
          fill="none"
          stroke="#8901f8"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

export default BackArrowIcon;
