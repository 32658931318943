import { useState, useEffect, useRef } from "react";
import { useGoogleMapsApi } from "./useGoogleMapsApi.js";

export function useGoogleMaps(apiKey, options) {
  const google = useGoogleMapsApi(apiKey);
  const ref = useRef(null);
  const [map, setMap] = useState();

  useEffect(() => {
    if (!google || google === "undefined" || !ref) {
      return;
    }
    setMap(new google.maps.Map(ref.current, options));
    // eslint-disable-next-line
  }, [google, ref]);

  return { ref, map, google };
}
