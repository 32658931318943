import React, { useState, useEffect } from "react";
import { Button, Typography, Container } from "@material-ui/core";
import { joinApplication, tenantGetApplication } from "../utils/ApplicationApi";
import { getAvailableReports } from "../utils/ReportsApi";
import { makeStyles } from "@material-ui/core/styles";
import useAuth0Nesitqa from "../hooks/useAuth0Nestiqa";
import { useHistory, useParams } from "react-router-dom";
import ApplicationOverview from "../components/application/ApplicationOverview";
import { GLOBALS } from "../App";
import NestiqaAlertDialog from "../components/NestiqaAlertDialog";
import RequireLogin from "../components/RequireLogin";
import { useSelector } from "react-redux";

import { getActiveUser } from "../store/selectors/userSelectors";

const useStyles = makeStyles(theme => ({
  buttonGroup: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(2),
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  text: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export default function JoinApplicationPage() {
  const classes = useStyles();
  const activeUser = useSelector(getActiveUser);
  const { isAuthenticated, accessToken, loginWithRedirect } = useAuth0Nesitqa();
  const { applicationId } = useParams();
  const [reports, setReports] = useState();
  const [application, setApplication] = useState();
  const [error, setError] = useState();
  const history = useHistory();
  const [dialogControls, setDialogControls] = useState({
    open: false,
    title: "",
    message: "",
  });

  useEffect(() => {
    if (!accessToken) return;

    getAvailableReports(accessToken, (isSuccess, apiResult, apiError) => {
      if (isSuccess) {
        setReports(apiResult);
      } else {
        setError(apiError);
      }
    });
    tenantGetApplication(
      applicationId,
      accessToken,
      (isSuccess, apiResult, apiError) => {
        if (isSuccess) {
          setApplication({
            ...apiResult,
            tenantName: `${apiResult?.tenant?.fullName}`,
          });
        } else {
          setError(apiError);
        }
      }
    );
  }, [accessToken, applicationId]);

  function handleLogin() {
    localStorage.setItem("previousLocation", window.location.pathname);
    loginWithRedirect();
  }

  if (!isAuthenticated) {
    return (
      <Container>
        <RequireLogin login={handleLogin} />
      </Container>
    );
  }

  if (error) {
    console.error(error);
    return (
      <Typography variant="h5" align="center" color="error">
        {GLOBALS.MESSAGES.ERROR_COMPLETING_ACTION}
      </Typography>
    );
  }

  if (!application || !reports) {
    return (
      <Container>
        <Typography variant="h5" align="center" color="primary">
          Loading...
        </Typography>
      </Container>
    );
  }

  function redirectToProfile() {
    if (activeUser && activeUser.tenantId) {
      history.push(`/tenant/${activeUser.tenantId}/profile`);
    }
  }

  if (reports.status !== "REPORTS_READY") {
    return (
      <Container>
        <Typography
          variant="body1"
          align="left"
          color="textPrimary"
          className={classes.text}
        >
          {GLOBALS.MESSAGES.ADD_REPORTS_TO_JOIN}
        </Typography>
        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={redirectToProfile}
        >
          Go to profile
        </Button>
      </Container>
    );
  }

  if (reports.dailyLimitReached === "Y") {
    return (
      <Container>
        <Typography
          variant="body1"
          align="left"
          color="textPrimary"
          className={classes.text}
        >
          {GLOBALS.MESSAGES.REPORTS_LIMIT_REACHED_JOIN}
        </Typography>
        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={() => history.push("/")}
        >
          OK
        </Button>
      </Container>
    );
  }

  function joinCallback(isSuccess, result, error) {
    if (isSuccess) {
      setDialogControls({
        open: true,
        title: "Success",
        message: GLOBALS.MESSAGES.JOIN_APPLICATION_SUCCESSFUL,
      });
    } else {
      console.error(error);
      setDialogControls({
        open: true,
        title: "Error",
        message: GLOBALS.MESSAGES.ERROR_COMPLETING_ACTION,
      });
    }
  }

  async function handleJoin() {
    joinApplication(accessToken, applicationId, joinCallback);
  }

  return (
    <Container maxWidth="lg">
      <NestiqaAlertDialog
        {...dialogControls}
        onClose={() => history.push("/")}
      />
      <Typography
        variant="body1"
        align="center"
        color="textPrimary"
        className={classes.text}
      >
        {GLOBALS.MESSAGES.JOIN_APPLICATION_QUESTION}
      </Typography>
      <div className={classes.buttonGroup}>
        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={handleJoin}
        >
          Yes
        </Button>
        <Button
          color="primary"
          variant="outlined"
          className={classes.button}
          onClick={() => history.push("/")}
        >
          No
        </Button>
      </div>
      <ApplicationOverview application={application} />
    </Container>
  );
}
