import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { formatter } from "../../utils/common";
import {
  formatPhoneNumber,
  getLabel,
  getOpenApplicationStatus,
} from "../../nestiqaUtils";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import bedIcon from "../../static/images/bed_icon.png";
import bathIcon from "../../static/images/bath_icon.png";
import { Room } from "@material-ui/icons";
import { format } from "date-fns";

import { useStyles } from "./OpenApplicationAccordion";

const ApplicationsPendingReportsAccordion = ({
  application,
  tenantId,
  handleApply,
  handleJoin,
  loading,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [expanded, setExpanded] = useState(false);

  const handleChange = id => (event, isExpanded) => {
    setExpanded(isExpanded ? id : false);
  };

  const handleClick = application => {
    if (application.status === "SUBMITTED")
      return history.push(`/application/${application.id}/join`);
    return history.push(`/application/${application.id}/update`);
  };

  const handleSubmit = app => {
    if (parseInt(app.tenantId) === parseInt(tenantId)) {
      const payload = {
        id: app.id,
        cover_letter: app.cover_letter,
        move_in_date: app.flexible
          ? null
          : format(app.moveInDate, "yyyy-MM-dd"),
        listing_seen: app.listingSeen,
      };
      return handleApply(payload);
    } else return handleJoin(app.id);
  };

  return (
    <Accordion
      expanded={expanded === application.id}
      key={application.id}
      className={classes.accordian}
      onChange={handleChange(application.id)}
    >
      <AccordionSummary id={application.id} className={classes.summary}>
        <div
          className={classes.summaryWrapper}
          onClick={() => handleClick(application)}
        >
          <div className={classes.address}>
            <Room />
            <div>
              <Typography variant="body2">
                {application.listing.address1}
                {application.listing.address2 !== ""
                  ? `, ${application.listing.address2}`
                  : null}
              </Typography>
              <Typography variant="body2">
                {application.listing.city}, {application.listing.state},{" "}
                {application.listing.zipCode}
              </Typography>
            </div>
          </div>
        </div>
        <div className={classes.alignIcon}>
          {expanded === application.id ? (
            <ExpandLessIcon />
          ) : (
            <ExpandMoreIcon />
          )}
        </div>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        <div className={classes.row} onClick={() => handleClick(application)}>
          <div>
            <Typography variant="body2">
              <strong>Info</strong>
            </Typography>
            <Typography variant="body2" className={classes.smallText}>
              {formatter.format(application.listing.rent)}
            </Typography>
            <div className={classes.bedsBaths}>
              <div>
                <span>
                  {getLabel(application.listing.bedrooms, "BEDROOMS")}
                </span>
              </div>
              <div>
                <img src={bedIcon} alt="bedrooms" />
              </div>
              <div>
                <span>
                  {getLabel(application.listing.bathrooms, "BATHROOMS")}
                </span>
              </div>
              <div>
                <img src={bathIcon} alt="bathrooms" />
              </div>
            </div>
          </div>
          <div>
            <Typography variant="body2">
              <strong>Contact</strong>
            </Typography>
            <Typography variant="body2" className={classes.smallText}>
              {application.listing.contactName}
            </Typography>
            <Typography variant="body2" className={classes.smallText}>
              {formatPhoneNumber(application.listing.contactPhone, true)}
            </Typography>
          </div>
        </div>
        <div className={classes.row}>
          <div
            className={classes.statusWrapper}
            onClick={() => handleClick(application)}
          >
            <Typography variant="body2">
              <strong>Status</strong>
            </Typography>
            <div className={classes.status}>
              <span>
                {getOpenApplicationStatus(
                  application.status,
                  application.tenantName
                )}
              </span>
            </div>
          </div>
          <div>
            <div className={classes.actionWrapper}>
              <Button
                variant="contained"
                color="primary"
                className={classes.submitButton}
                onClick={() => handleSubmit(application)}
                disabled={loading}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default ApplicationsPendingReportsAccordion;
