import React, { useState, useRef, useEffect, useMemo } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import { GLOBALS } from "../../App";
import { Search } from "@material-ui/icons";
import {
  clearDistanceFrom,
  makeFilters,
} from "../../store/actions/listingsActions";
import { useSelector, useDispatch } from "react-redux";

function getPlaceSize(place) {
  var viewport = place.geometry.viewport;
  if (viewport == null) {
    return 0;
  }
  return getDistanceFromLatLonInKm(
    viewport.getCenter().lat(),
    viewport.getCenter().lng(),
    viewport.getNorthEast().lat(),
    viewport.getNorthEast().lng()
  );
}

function getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = GLOBALS.EARTH_RADIUS_KM * c; // Distance in km
  return d;
}

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

const useStyles = makeStyles(theme => ({
  optionText: {
    // fontSize: "0.75rem",
  },
  optionSecondaryText: {
    fontSize: "0.75rem",
  },
  listbox: {
    width: "240px",
    backgroundColor: "white",
    position: "absolute",
    boxShadow: theme.shadows[2],
    borderRadius: "4px",
  },
}));

function getOptionForValue(value) {
  const val = value || "";

  return {
    manual: true,
    description: val,
    structured_formatting: {
      main_text_matched_substrings: [{ length: val.length, offset: 0 }],
      main_text: val,
      secondary_text: "",
    },
  };
}

function getPlaceForManualValue(streetAddress) {
  return {
    address_components: [
      {
        types: ["route"],
        short_name: streetAddress,
      },
    ],
  };
}

export default function GoogleMapsAutoComplete(props) {
  const {
    resultTypes,
    handlePlaceChange,
    className,
    inputClassName,
    label,
    inputVariant,
    placeholder,
    hasIcon,
    shortLabel,
    initialValue,
    allowManual,
    onInputChange,
    InputProps,
    required,
    searchByLocation,
    ...other
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const reduxFilters = useSelector(state => state.listings.filters);
  const [value, setValue] = useState(getOptionForValue(initialValue));
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const loaded = useRef(false);
  const autocompleteRef = useRef();

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        "https://maps.googleapis.com/maps/api/js?key=" +
          GLOBALS.GOOGLE_API_KEY +
          "&libraries=places",
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 300),
    []
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    let request = { input: inputValue };
    if (resultTypes) {
      request["types"] = resultTypes;
    }

    fetch(request, results => {
      if (active) {
        let newOptions = [];

        if (results) {
          newOptions.push(...results);
        }

        if (allowManual) {
          newOptions.push(getOptionForValue(inputValue));
        }

        if (value) {
          newOptions.push(value);
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch, resultTypes, allowManual]);

  useEffect(() => {
    if (!value || !window.google) {
      handlePlaceChange(null, 0, false);
      return;
    }
    if (value.manual) {
      handlePlaceChange(
        getPlaceForManualValue(value.structured_formatting.main_text),
        0,
        true
      );
      return;
    }
    const geocoder = new window.google.maps.Geocoder();
    const { OK } = window.google.maps.GeocoderStatus;
    geocoder.geocode({ placeId: value.place_id }, (results, status) => {
      if (status === OK && results.length === 1) {
        let place = results[0];
        let placeSize = getPlaceSize(place);
        handlePlaceChange(place, placeSize, false);
      }
    });
    // eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    setValue(getOptionForValue(initialValue));
    setOptions([]);
  }, [initialValue]);

  const handleResetFilters = () => {};

  let clearIndicator;
  if (autocompleteRef.current) {
    clearIndicator = autocompleteRef.current.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
  }

  useEffect(() => {
    if (clearIndicator) {
      clearIndicator.addEventListener("click", function () {
        let adjustedFilters = reduxFilters;
        dispatch(clearDistanceFrom());
        if (searchByLocation) {
          delete adjustedFilters.frlg;
          delete adjustedFilters.frlt;
          delete adjustedFilters.dst;
        } else {
          delete adjustedFilters.zc;
          delete adjustedFilters.ct;
          delete adjustedFilters.st;
        }
        dispatch(makeFilters(adjustedFilters));
      });
    }
    // eslint-disable-next-line
  }, [clearIndicator]);

  return (
    <Autocomplete
      {...other}
      ref={autocompleteRef}
      classes={{
        root: className,
        listbox: classes.listbox,
        inputRoot: inputClassName,
      }}
      // id="google-map-autocomplete"
      getOptionLabel={option => {
        if (typeof option === "string") return option;
        return shortLabel
          ? option.structured_formatting.main_text
          : option.description;
      }}
      filterOptions={x => x}
      options={options}
      clearOnBlur={!allowManual}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        if (onInputChange) {
          onInputChange(newInputValue);
        }
      }}
      renderInput={params => (
        <TextField
          {...params}
          required={required}
          label={label}
          variant={inputVariant}
          placeholder={placeholder}
          onReset={handleResetFilters}
          InputProps={
            hasIcon
              ? {
                  ...params.InputProps,
                  endAdornment: <Search color="primary" fontSize="small" />,
                }
              : { ...params.InputProps }
          }
        />
      )}
      renderOption={option => {
        const matches =
          option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map(match => [match.offset, match.offset + match.length])
        );

        return (
          <Grid container alignItems="center" className={classes.suggestion}>
            <Grid item xs>
              {parts.map((part, index) => (
                <span
                  className={classes.optionText}
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}

              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.optionSecondaryText}
              >
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
}
