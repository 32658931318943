import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";
import useAuth0Nesitqa from "../hooks/useAuth0Nestiqa";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useGA4React } from "ga-4-react";
import { GLOBALS } from "../App";
import { getLoginMethod } from "../nestiqaUtils";
import NestiqaAlertDialog from "../components/NestiqaAlertDialog";
import * as Sentry from "@sentry/react";
import { logEvent } from "../utils/initGa4React";
import { getActiveUser } from "../store/selectors/userSelectors";

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(12),
  },
}));

export default function RedirectPage() {
  const ga = useGA4React();
  const { isAuthenticated, accessToken, user } = useAuth0Nesitqa("");
  const history = useHistory();
  const classes = useStyles();
  const activeUser = useSelector(getActiveUser);
  const [hasError, setHasError] = useState(false);
  const [dialogControls, setDialogControls] = useState({
    open: false,
    title: "",
    message: "",
  });

  useEffect(() => {
    if (history.location.search.includes("error")) {
      setHasError(true);
      const previousLocation = localStorage.getItem("previousLocation");
      const [prevPath, prevSearch] = previousLocation?.split("?");

      setDialogControls({
        open: true,
        title: "Login Failed",
        message: "Please try again or contact support@nestiqa.com",
        onClose: () => {
          setDialogControls(pre => ({ ...pre, open: false }));
          history.push({
            pathname: prevPath || "/",
            search: prevSearch,
            state: { fromRedirect: true },
          });
        },
      });

      const urlParams = new URLSearchParams(history.location.search);
      const error = urlParams.get("error");
      const errorMessage = urlParams.get("error_description");

      Sentry.captureMessage(
        "Auth0 Login Error: " + error + ", " + errorMessage
      );
      console.error("Auth0 Login Error: " + error + ", " + errorMessage);
    }
  }, [history]);

  useEffect(() => {
    if (isAuthenticated && activeUser !== null) {
      const previousLocation = localStorage.getItem("previousLocation");
      const [prevPath, prevSearch] = previousLocation.split("?");

      const loginsCount = `${GLOBALS.CLAIMS_NAMESPACE}/logins_count`;
      const connectionType = `${GLOBALS.CLAIMS_NAMESPACE}/connection`;
      const loginMethod = getLoginMethod(user[connectionType]);
      if (!!ga && user) {
        if (user[loginsCount] === 1) {
          const lt_event = prevPath.includes("landlord")
            ? "landlord_sign_up"
            : "tenant_sign_up";
          logEvent("sign_up", { method: loginMethod });
          logEvent(lt_event, { method: loginMethod });
        } else if (user[loginsCount] > 1) {
          const lt_event = prevPath.includes("landlord")
            ? "landlord_login"
            : "tenant_login";
          logEvent("login", { method: loginMethod });
          logEvent(lt_event, { method: loginMethod });
        }
      }

      const isSkipChoicePath = GLOBALS.SKIP_CHOICE_PATHS.some(path =>
        prevPath.includes(path)
      );

      const shouldGoToUserType =
        !activeUser.tenantId && !activeUser.landlordId && !isSkipChoicePath;

      if (shouldGoToUserType) {
        history.push(GLOBALS.PATHS.USER_TYPE_PAGE);
      } else {
        history.push({
          pathname: prevPath,
          search: prevSearch,
          state: { fromRedirect: true },
        });
      }
    }
  }, [accessToken, activeUser, history, isAuthenticated, ga, user]);

  return (
    <>
      <NestiqaAlertDialog {...dialogControls} />
      {!hasError && (
        <div className={classes.wrapper}>
          <Typography variant="h5" align="center" color="primary">
            Loading...
          </Typography>
        </div>
      )}
    </>
  );
}
