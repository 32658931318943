import React, { useState, useEffect } from "react";
import TenantFormControls from "./TenantFormControls";
import useDocuments from "../../hooks/useDocuments";
import * as yup from "yup";

import {
  Typography,
  Button,
  Modal,
  LinearProgress,
  List,
  ListItem,
  IconButton,
  OutlinedInput,
  Tooltip,
  SvgIcon,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import {
  updateTenantDocument,
  deleteTenantDocument,
} from "../../hooks/DocumentApi";
import Dropzone from "react-dropzone";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {
  DeleteOutline as DeleteIcon,
  EditOutlined as EditIcon,
  Save as SaveIcon,
} from "@material-ui/icons";
import { GLOBALS } from "../../App";
import { snakeToCamel } from "../../nestiqaUtils";
import { getFileIconFromFilename } from "../../utils/common";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3),
    margin: "auto",
    width: "100%",
    maxWidth: "600px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "5px",
    outline: "none",
  },
  dropzoneStyle: {
    height: "250px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid",
    borderColor: theme.palette.grey[500],
    borderRadius: "5px",
    outline: "none",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  downloadBtn: {
    display: "block",
    margin: "0 auto",
    marginTop: theme.spacing(4),
  },
  listItem: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
  },
  iconButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginLeft: theme.spacing(2),
  },
  fileName: {
    whiteSpace: "nowrap",
    overflowX: "hidden",
    textOverflow: "ellipsis",
    marginLeft: theme.spacing(2),
  },
  listGroup: {
    paddingLeft: "0px",
  },
  listItemHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  topRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(4),
  },
  left: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    overflowX: "hidden",
    flex: 1,
  },
  addButton: {
    marginLeft: theme.spacing(4),
    whiteSpace: "nowrap",
  },
  dialogTitle: {
    textAlign: "center",
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    paddingBottom: theme.spacing(4),
    position: "relative",

    "&::after": {
      content: "''",
      width: "52px",
      height: "4px",
      position: "absolute",
      left: "50%",
      marginLeft: "-26px",
      bottom: "20px",
      backgroundColor: theme.palette.primary.main,
    },
  },
  dialogListItem: {
    paddingRight: "0px",
    paddingLeft: "0px",
  },
  dialogAddBtn: {
    display: "block",
    margin: "0 auto",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  leftList: {
    display: "flex",
    alignItems: "center",
    overflowX: "hidden",
  },
  [theme.breakpoints.down("xs")]: {
    topRow: {
      flexDirection: "column",
    },
    addButton: {
      marginLeft: theme.spacing(0),
      marginTop: theme.spacing(2),
    },
  },
}));

export default function TenantDocuments(props) {
  const {
    accessToken,
    tenant,
    setTenant,
    step,
    setStep,
    showError,
    isEditDialog = false,
    closeDialog,
  } = props;
  // const { accessToken, tenant, setTenant, step, setStep, showError } = props;

  function handleNext(formikprops) {
    if (isEditDialog) {
      closeDialog();
    } else {
      setStep(step + 1);
    }
  }

  const validationSchema = yup.object().shape({});

  const [documentParams, setDocumentParams] = useState();
  const [isOpen, onOpenClose] = useState(false);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [disableButtons, setDisableButtons] = useState(false);
  const [Editing, setEditing] = useState({
    index: -1,
    value: "",
  });
  let tenantDocumentResult = useDocuments(documentParams);

  useEffect(() => {
    if (!documentParams || tenantDocumentResult.status !== "COMPLETE") return;
    setUploadFiles([]);
    if (tenantDocumentResult.error) {
      let msg;
      switch (documentParams.action) {
        case "create-tenant-documents":
          msg = GLOBALS.MESSAGES.DOCUMENT_CREATE_FAILED;
          break;
        case "download-tenant-document":
          msg = GLOBALS.MESSAGES.DOCUMENT_DOWNLOAD_FAILED;
          break;
        default:
          msg = GLOBALS.MESSAGES.DOCUMENT_NOT_FOUND;
          break;
      }
      console.error(tenantDocumentResult.error);
      showError(msg);
      tenantDocumentResult.clear();
      setDisableButtons(false);
      return;
    }
    if (
      documentParams.action === "create-tenant-documents" &&
      tenantDocumentResult.status === "COMPLETE"
    ) {
      setTenant({
        ...tenant,
        documents:
          tenantDocumentResult.documents?.documents?.slice() ||
          tenant.documents,
      });
      setDisableButtons(false);
    } else if (
      documentParams.action === "download-tenant-document" &&
      tenantDocumentResult.status === "COMPLETE" &&
      tenantDocumentResult.document
    ) {
      let element = document.createElement("a");
      element.setAttribute("href", tenantDocumentResult.document);
      element.setAttribute("download", documentParams.docName);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      tenantDocumentResult.clear();
    }
    // eslint-disable-next-line
  }, [
    documentParams,
    tenantDocumentResult,
    setDocumentParams,
    setEditing,
    setUploadFiles,
  ]);

  const onDrop = files => {
    setUploadFiles([
      ...uploadFiles,
      ...files.map(file => {
        return {
          file: file,
          name: removeExt(file.name),
        };
      }),
    ]);
  };

  const removeExt = name => {
    let fileName = name.split(".");
    fileName.pop();
    return fileName.join(".");
  };

  const handleNameChange = (e, index) => {
    let uploaded = [...uploadFiles];
    uploaded[index].name = e.target.value;
    setUploadFiles(uploaded);
  };

  const handleSubmit = () => {
    setDisableButtons(true);
    setDocumentParams({
      action: "create-tenant-documents",
      documents: uploadFiles,
    });
    onOpenClose(false);
  };

  const setUpdateResultsCallback = (isSuccess, apiResult, apiError) => {
    if (isSuccess) {
      const tarDocs = tenant.documents.slice();
      const index = tarDocs.findIndex(doc => doc.id === apiResult.id);
      tarDocs[index] = snakeToCamel(apiResult);
      setTenant({
        ...tenant,
        documents: tarDocs,
      });
      setEditing({
        index: -1,
        value: "",
      });
    } else {
      console.error(apiError);
      showError(GLOBALS.MESSAGES.DOCUMENT_UPDATE_FAILED);
    }
    setDisableButtons(false);
  };

  const deleteCallback = (isSuccess, apiResult, apiError) => {
    if (isSuccess) {
      setTenant({
        ...tenant,
        documents: tenant.documents.filter(doc => doc.id !== apiResult),
      });
    } else {
      console.error(apiError);
      showError(GLOBALS.MESSAGES.DOCUMENT_DELETE_FAILED);
    }
    setDisableButtons(false);
  };

  const onUpdate = documentId => {
    setDisableButtons(true);
    updateTenantDocument(
      {
        id: documentId,
        docName: Editing.value,
      },
      accessToken,
      setUpdateResultsCallback
    );
    onOpenClose(false);
  };

  const onDownload = document => {
    setDocumentParams({
      action: "download-tenant-document",
      documentId: document.id,
      docName: document.docName,
    });
  };

  const tenantDocuments = tenant.documents;
  const classes = useStyles();

  return (
    <TenantFormControls
      tenant={tenant}
      step={step}
      setStep={setStep}
      validationSchema={validationSchema}
      handleNext={handleNext}
      isEditDialog={isEditDialog}
      closeDialog={closeDialog}
    >
      <div className="text-center">
        <Modal open={isOpen} onClose={() => onOpenClose(false)}>
          <div className={classes.paper}>
            <h2 className={classes.dialogTitle}>
              {GLOBALS.LABELS.DOCUMENT.UPLOAD}
            </h2>

            <Dropzone onDrop={onDrop} multiple={true}>
              {({ getRootProps, getInputProps }) => (
                <div>
                  <div className={classes.dropzoneStyle} {...getRootProps()}>
                    <input {...getInputProps()} />
                    {GLOBALS.LABELS.DOCUMENT.DROP}
                  </div>
                </div>
              )}
            </Dropzone>
            {uploadFiles.length > 0 && (
              <List component="div">
                {uploadFiles.length > 0 &&
                  uploadFiles.map((file, i) => (
                    <ListItem
                      component="div"
                      key={i}
                      className={classes.dialogListItem}
                    >
                      <OutlinedInput
                        value={file.name}
                        onChange={e => handleNameChange(e, i)}
                        fullWidth={true}
                        margin="dense"
                      />
                    </ListItem>
                  ))}
              </List>
            )}
            {uploadFiles && uploadFiles.length > 0 && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
                className={classes.dialogAddBtn}
              >
                Add Documents
              </Button>
            )}
          </div>
        </Modal>

        <div>
          <div className={classes.topRow}>
            <div className={classes.left}>
              <Typography variant="body1">
                {GLOBALS.MESSAGES.ADD_DOCUMENTS_1}
              </Typography>
              <br />
              <Typography variant="body1">
                {GLOBALS.MESSAGES.ADD_DOCUMENTS_2}
              </Typography>
            </div>

            <Button
              color="primary"
              variant="contained"
              className={classes.addButton}
              disabled={disableButtons}
              onClick={() => onOpenClose(true)}
            >
              {GLOBALS.LABELS.DOCUMENT.ADD}
            </Button>
          </div>
          {!!tenantDocuments?.length ? (
            <ul className={classes.listGroup}>
              <List component="div">
                <ListItem component="div" className={classes.listItemHeader}>
                  <Typography variant="body2">
                    <strong>Documents</strong>
                  </Typography>
                  <Typography variant="body2">
                    <strong>Actions</strong>
                  </Typography>
                </ListItem>
                {tenantDocuments.length > 0 &&
                  tenantDocuments.map((file, index) => (
                    <ListItem
                      component="div"
                      className={classes.listItem}
                      key={index}
                    >
                      {index !== Editing.index ? (
                        <div className={classes.leftList}>
                          <SvgIcon color="secondary">
                            {getFileIconFromFilename(file.docName)}
                          </SvgIcon>
                          <Tooltip
                            placement="top-start"
                            title={file.docName}
                            aria-label={file.docName}
                          >
                            <Typography
                              className={classes.fileName}
                              variant="body1"
                            >
                              {file.docName}
                            </Typography>
                          </Tooltip>
                        </div>
                      ) : (
                        <OutlinedInput
                          margin="dense"
                          value={Editing.value}
                          fullWidth
                          onChange={e =>
                            setEditing({
                              index,
                              value: e.target.value,
                            })
                          }
                        />
                      )}
                      <div className={classes.iconButtons}>
                        {index !== Editing.index ? (
                          <>
                            <IconButton
                              edge="end"
                              aria-label="download"
                              disabled={disableButtons}
                              onClick={() => onDownload(file)}
                            >
                              <VisibilityIcon color="secondary" />
                            </IconButton>
                            <IconButton
                              edge="end"
                              aria-label="edit"
                              disabled={disableButtons}
                              onClick={() =>
                                setEditing({
                                  index,
                                  value: removeExt(file.docName),
                                })
                              }
                            >
                              <EditIcon color="secondary" />
                            </IconButton>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              disabled={disableButtons}
                              onClick={() => {
                                setDisableButtons(true);
                                deleteTenantDocument(
                                  file.id,
                                  accessToken,
                                  deleteCallback
                                );
                              }}
                            >
                              <DeleteIcon color="secondary" />
                            </IconButton>
                          </>
                        ) : (
                          <IconButton
                            edge="end"
                            aria-label="save"
                            onClick={() => onUpdate(file.id)}
                          >
                            <SaveIcon color="secondary" />
                          </IconButton>
                        )}
                      </div>
                    </ListItem>
                  ))}
              </List>
            </ul>
          ) : null}
        </div>
        {disableButtons && <LinearProgress />}
      </div>
    </TenantFormControls>
  );
}
