import React from "react";
import { formatter } from "../../utils/common";
import {
  formatPhoneNumber,
  formatListingDate,
  getLabel,
} from "../../nestiqaUtils";
import { Table, TableBody, TableHead, TableRow } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Room } from "@material-ui/icons";
import bedIcon from "../../static/images/bed_icon.png";
import bathIcon from "../../static/images/bath_icon.png";
import clsx from "clsx";
import StyledTableCell from "../StyledTableCell";

const useStyles = makeStyles(theme => ({
  table: {
    width: "100%",
  },
  noBorder: {
    border: "none",
  },
  topBorder: {
    borderTop: "2px solid",
    borderColor: theme.palette.secondary.main,
  },
  paddingY: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  status: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "70px",
    height: "25px",

    "& span": {
      borderRadius: "5px",
      padding: "2px 8px",
      textAlign: "left !important",
    },
  },
  colorSubmitted: {
    backgroundColor: "#ECFAFB",
    color: "#3CE0E5",
  },
  colorApproved: {
    backgroundColor: "#EFFBEC",
    color: "#58D865",
  },
  colorRejected: {
    backgroundColor: "#FBECEC",
    color: "#CF7F7F",
  },
  colorPending: {
    backgroundColor: "#FBF7EC",
    color: "#D89A3D",
  },
  contact: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",

    "& span": {
      flexBasis: "100%",
    },
  },
  address: {
    display: "flex",

    "& svg": {
      color: theme.palette.secondary.main,
    },
  },
  bedsBaths: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& div:first-child": {
      flexGrow: 1,
    },

    "& div:nth-child(3)": {
      marginLeft: "10px",
      flexGrow: 1,
    },

    "& > div": {
      "& span": {
        margin: "0 5px",
      },

      "& img": {
        width: "22px",
      },
    },
  },
}));

const TenantApplicationHistoryTable = ({ applicationHistory }) => {
  const classes = useStyles();

  const getColor = status => {
    switch (status) {
      case "SUBMITTED":
        return classes.colorSubmitted;
      case "APPROVED":
        return classes.colorApproved;
      case "REJECTED":
        return classes.colorRejected;
      case "PROCESSING":
        return classes.colorPending;
      default:
        return "";
    }
  };

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow className={classes.tableHead}>
          <StyledTableCell
            className={classes.noBorder}
            align="left"
            width="20%"
          >
            Address
          </StyledTableCell>
          <StyledTableCell className={classes.noBorder} align="center">
            Monthly Rent
          </StyledTableCell>
          <StyledTableCell
            className={classes.noBorder}
            align="center"
            width="10%"
          >
            Beds/Baths
          </StyledTableCell>
          <StyledTableCell
            className={classes.noBorder}
            align="center"
            width="15%"
          >
            Available
          </StyledTableCell>
          <StyledTableCell
            className={classes.noBorder}
            align="left"
            width="20%"
          >
            Contact
          </StyledTableCell>
          <StyledTableCell className={classes.noBorder} align="center">
            Submitted
          </StyledTableCell>
          <StyledTableCell className={classes.noBorder} align="center">
            Status
          </StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {applicationHistory.map((application, i) => (
          <TableRow key={application.id}>
            <StyledTableCell
              className={clsx(
                i !== 0 && classes.topBorder,
                classes.noBorder,
                classes.paddingY
              )}
              align="left"
            >
              <div className={classes.address}>
                <Room />
                <div>
                  {application.listing.address1}
                  {application.listing.address2 !== ""
                    ? `, ${application.listing.address2}`
                    : null}
                  <br />
                  {application.listing.city}, {application.listing.state},{" "}
                  {application.listing.zipCode}
                </div>
              </div>
            </StyledTableCell>
            <StyledTableCell
              className={clsx(
                i !== 0 && classes.topBorder,
                classes.noBorder,
                classes.paddingY
              )}
              align="center"
            >
              {formatter.format(application.listing.rent)}
            </StyledTableCell>
            <StyledTableCell
              className={clsx(
                i !== 0 && classes.topBorder,
                classes.noBorder,
                classes.paddingY
              )}
              align="center"
            >
              <div className={classes.bedsBaths}>
                <div>
                  <span>
                    {getLabel(application.listing.bedrooms, "BEDROOMS")}
                  </span>
                </div>
                <div>
                  <img src={bedIcon} alt="bedrooms" />
                </div>
                <div>
                  <span>
                    {getLabel(application.listing.bathrooms, "BATHROOMS")}
                  </span>
                </div>
                <div>
                  <img src={bathIcon} alt="bathrooms" />
                </div>
              </div>
            </StyledTableCell>
            <StyledTableCell
              className={clsx(
                i !== 0 && classes.topBorder,
                classes.noBorder,
                classes.paddingY
              )}
              align="center"
            >
              {formatListingDate(application.listing.availabilityDate)}
            </StyledTableCell>
            <StyledTableCell
              className={clsx(
                i !== 0 && classes.topBorder,
                classes.noBorder,
                classes.paddingY
              )}
              align="left"
            >
              <div className={classes.contact}>
                {application.listing.contactName}
                <span>
                  {formatPhoneNumber(application.listing.contactPhone, true)}
                </span>
              </div>
            </StyledTableCell>
            <StyledTableCell
              className={clsx(
                i !== 0 && classes.topBorder,
                classes.noBorder,
                classes.paddingY
              )}
              align="center"
            >
              {formatListingDate(application.submitted)}
            </StyledTableCell>
            <StyledTableCell
              className={clsx(
                i !== 0 && classes.topBorder,
                classes.noBorder,
                classes.paddingY
              )}
              align="right"
            >
              <div className={classes.status}>
                <span className={getColor(application.status)}>
                  {getLabel(application.status, "APP_STATUSES")}
                </span>
              </div>
            </StyledTableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default TenantApplicationHistoryTable;
