import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { isBefore } from "date-fns";
import { Container, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useAuth0Nesitqa from "../hooks/useAuth0Nestiqa";
import RequireLogin from "../components/RequireLogin";
import { getInvitation, joinHousehold } from "../utils/HouseholdApi";
import { GLOBALS } from "../App";
import NestiqaActionDialog from "../components/NestiqaActionDialog";
import { createTenant } from "../utils/TenantApi";
import { useDispatch, useSelector } from "react-redux";
import { getActiveUser } from "../store/selectors/userSelectors";
import { setUser as setReduxUser } from "../store/actions/userActions";

const useStyles = makeStyles(theme => ({
  buttonGroup: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(2),
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  text: {
    marginTop: theme.spacing(2),
  },
}));

const JoinHousehold = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { isAuthenticated, accessToken, loginWithRedirect } = useAuth0Nesitqa();
  const { invitationCode } = useParams();
  const [invitation, setInvitation] = useState();
  const activeUser = useSelector(getActiveUser);

  const [error, setError] = useState();

  function handleRedirect(tenantPath) {
    if (activeUser.tenantId) {
      history.push(`/tenant/${activeUser.tenantId}/${tenantPath}`);
    }
  }

  const callCreateTenant = useCallback(async () => {
    await createTenant(accessToken, (isSuccess, apiResult, apiError) => {
      if (isSuccess) {
        dispatch(setReduxUser({ tenantId: apiResult.tenantId }));
      } else {
        console.error(apiError);
      }
    });
  }, [accessToken, dispatch]);

  useEffect(() => {
    if (accessToken && activeUser && !activeUser.tenantId) {
      callCreateTenant();
    }
  }, [accessToken, activeUser, callCreateTenant]);

  useEffect(() => {
    if (!accessToken || !activeUser || !activeUser.tenantId) return;

    function getInvitationCallback(isSuccess, apiResult, apiError) {
      if (isSuccess) {
        setInvitation(apiResult);
      } else {
        console.error(apiError);
        setError(apiError);
      }
    }

    getInvitation(invitationCode, accessToken, getInvitationCallback);
  }, [accessToken, activeUser, invitationCode]);

  function handleLogin() {
    localStorage.setItem("previousLocation", window.location.pathname);
    loginWithRedirect();
  }

  const [joinDialogControls, setJoinDialogControls] = useState({
    open: false,
  });

  const getJoinDialogButtonMap = success => [
    {
      buttonName: "OK",
      handleClick: () => {
        setJoinDialogControls({ open: false });
        if (success) {
          handleRedirect("profile");
        }
      },
    },
  ];

  const handleJoinHousehold = () => {
    joinHousehold(
      invitationCode,
      accessToken,
      (isSuccess, apiResult, apiError) => {
        if (isSuccess) {
          setJoinDialogControls({
            open: true,
            title: "Success",
            message: "Successfully Joined Household",
            buttonMap: getJoinDialogButtonMap(true),
          });
        } else {
          console.error(apiError);
          setJoinDialogControls({
            open: true,
            title: "Error",
            message: GLOBALS.MESSAGES.ERROR_COMPLETING_ACTION,
            buttonMap: getJoinDialogButtonMap(false),
          });
        }
      }
    );
  };

  if (!isAuthenticated) {
    return (
      <Container>
        <RequireLogin login={handleLogin} />
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Typography variant="h5" align="center" color="error">
          {GLOBALS.MESSAGES.INVITATION_NOT_FOUND}
        </Typography>
      </Container>
    );
  }

  if (!invitation || !activeUser || !activeUser.tenantId) {
    return (
      <Container>
        <Typography variant="h5" align="center" color="primary">
          Loading...
        </Typography>
      </Container>
    );
  }

  if (
    invitation.status !== "ACTIVE" ||
    isBefore(new Date(invitation.expiration), new Date())
  ) {
    return (
      <Container>
        <Typography variant="h5" align="center" color="error">
          {GLOBALS.MESSAGES.JOIN_HOUSEHOLD_INVITATION_EXPIRED}
        </Typography>
      </Container>
    );
  }

  if (invitation.fromTenant.id === activeUser.tenantId) {
    return (
      <Container>
        <Typography variant="subtitle1" align="center" color="primary">
          {GLOBALS.MESSAGES.ERROR_JOIN_OWN_HOUSEHOLD}
        </Typography>
      </Container>
    );
  }

  return (
    <Container>
      <Typography
        variant="h5"
        align="center"
        color="primary"
        className={classes.text}
      >
        {GLOBALS.MESSAGES.JOIN_HOUSEHOLD_QUESTION.replace(
          "[from_tenant]",
          invitation.fromTenant.fullName
        ).replace("[to_tenant]", invitation.toTenant.fullName)}
      </Typography>
      <div className={classes.buttonGroup}>
        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={handleJoinHousehold}
        >
          Yes
        </Button>
        <Button
          color="primary"
          variant="outlined"
          className={classes.button}
          onClick={() => handleRedirect("profile")}
        >
          No
        </Button>
      </div>
      <NestiqaActionDialog {...joinDialogControls} />
    </Container>
  );
};

export default JoinHousehold;
