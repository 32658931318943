import React, { useState } from "react";
import { getTooltip } from "../../nestiqaUtils";
import { Tooltip as TooltipOrig, ClickAwayListener } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/Info";
import { GLOBALS } from "../../App";

const useStyles = makeStyles(() => ({
  tooltip: {
    fontSize: "12px",
    margin: 0,
    padding: "5px",
  },
  icon: {
    fontSize: props => (props.iconSize !== "" ? props.iconSize : "1.5rem"),
  },
}));

const Tooltip = props => {
  const classes = useStyles(props);
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const rowAction = GLOBALS.REQUIRED_ACTIONS.find(({ status }) => {
    if (!props.householdApp) {
      return status === props.application.status;
    } else {
      return status === "JOIN_HOUSEHOLD";
    }
  });

  return props.clickable ? (
    <TooltipOrig
      title={
        <p className={classes.tooltip}>
          {getTooltip(
            rowAction,
            props.application.comment,
            props.application.tenantName
          )}
        </p>
      }
    >
      <InfoIcon className={classes.icon} color="secondary" />
    </TooltipOrig>
  ) : (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <TooltipOrig
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={getTooltip(
          rowAction,
          props.application.comment,
          props.application.tenantName
        )}
      >
        <div className="iconWrapper" onClick={() => setOpen(!open)}>
          <InfoIcon className={classes.icon} color="secondary" />
        </div>
      </TooltipOrig>
    </ClickAwayListener>
  );
};

export default Tooltip;
