import React, { useState, useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import NestiqaTopBar from "./NestiqaTopBar";
import clsx from "clsx";
import useActiveUser from "../hooks/useActiveUser";
import useAuth0Nesitqa from "../hooks/useAuth0Nestiqa";
import NestiqaBottomBar from "./NestiqaBottomBar";
import SessionTimeoutDialog from "./dialogs/SessionTimeoutDialog";
import { GLOBALS } from "../App";
import NewsletterDialog from "./NewsletterDialog";
import PurchaseInquiryDialog from "./PurchaseInquiryDialog";

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
    width: "100%",
    minHeight: "100vh",
    paddingBottom: theme.spacing(30),

    [theme.breakpoints.down("xs")]: {
      paddingBottom: "470px",
    },
  },
}));

const TIMEOUT = 1000 * 29.5 * 60;
const DEBOUNCE = 500;
let timeout;
let countdownInterval;

export default function AppWrapper({ children, className }) {
  const classes = useStyles();
  const location = useLocation();
  const [showBars, setShowBars] = useState(true);
  const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
  const [timeoutCountdown, setTimeoutCountdown] = useState(0);
  const { accessToken, isAuthenticated, logout } = useAuth0Nesitqa();

  useActiveUser();

  useEffect(() => {
    if (
      location.pathname.includes("/print") ||
      location.pathname === GLOBALS.PATHS.REDIRECT_USER
    ) {
      setShowBars(false);
    } else {
      setShowBars(true);
    }
  }, [location]);

  const clearSessionTimeout = () => {
    clearTimeout(timeout);
  };

  const clearSessionInterval = () => {
    clearInterval(countdownInterval);
  };

  const handleOnIdle = event => {
    const delay = 1000 * 1;
    const lastActive = localStorage.getItem("lastActive");
    const now = new Date().getTime();

    if (isAuthenticated && !timeoutModalOpen && now - lastActive > TIMEOUT) {
      timeout = setTimeout(() => {
        let countDown = 30;
        setTimeoutModalOpen(true);
        setTimeoutCountdown(countDown);
        countdownInterval = setInterval(() => {
          if (countDown > 0) {
            setTimeoutCountdown(--countDown);
          } else {
            handleLogout(true);
          }
        }, 1000);
      }, delay);
    }
  };
  const handleOnActive = event => {
    if (!timeoutModalOpen) {
      clearSessionInterval();
      clearSessionTimeout();
    }
  };
  const handleOnAction = () => {
    localStorage.setItem("lastActive", getLastActiveTime());
  };
  const { getLastActiveTime } = useIdleTimer({
    timeout: TIMEOUT,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: DEBOUNCE,
  });

  const handleContinue = () => {
    clearSessionInterval();
    clearSessionTimeout();
    setTimeoutModalOpen(false);
  };

  const handleLogout = async () => {
    try {
      const returnToPath = location.pathname.includes("/landlord")
        ? "/landlord"
        : "";
      clearSessionInterval();
      clearSessionTimeout();
      localStorage.removeItem("ns_free_consultation_declined");
      setTimeoutModalOpen(false);
      logout({ returnTo: GLOBALS.APP_BASE_URL + returnToPath });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={clsx(className, classes.root)}>
      <SessionTimeoutDialog
        title="Session Timeout"
        open={timeoutModalOpen}
        countdown={timeoutCountdown}
        onContinue={handleContinue}
        onLogout={() => handleLogout()}
      />
      <NewsletterDialog isAuthenticated={isAuthenticated} />
      {isAuthenticated && <PurchaseInquiryDialog accessToken={accessToken} />}
      {showBars ? (
        <>
          <NestiqaTopBar />
          {children}
          <NestiqaBottomBar />
        </>
      ) : (
        <>{children}</>
      )}
    </div>
  );
}
