import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles(theme => ({
  dialog: {
    width: "100%",
    maxWidth: props => (props.isWide ? "1000px" : "500px"),
    padding: theme.spacing(2),
  },
  dialogTitle: {
    textAlign: "center",
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    position: "relative",

    "&::after": {
      content: "''",
      width: "52px",
      height: "4px",
      position: "absolute",
      left: "50%",
      marginLeft: "-26px",
      bottom: "20px",
      backgroundColor: theme.palette.primary.main,
    },
  },
  dialogActions: {
    justifyContent: "center",
    paddingTop: theme.spacing(3),
  },
}));

const CreateTenantDialog = ({
  open = false,
  onClose,
  title = "",
  form = null,
  isWide = false,
}) => {
  const classes = useStyles({ isWide });

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="create-tenant-title"
        classes={{ paper: classes.dialog }}
      >
        {title && (
          <DialogTitle className={classes.dialogTitle} id="create-tenant-title">
            {title}
          </DialogTitle>
        )}
        <DialogContent>{form}</DialogContent>
      </Dialog>
    </>
  );
};

export default CreateTenantDialog;
