import React, { useMemo } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import TenantProfileSection from "./TenantProfileSection";
import TenantEmploymentHistoryTable from "./TenantEmploymentHistoryTable";
import TenantEmploymentHistoryAccordion from "./TenantEmploymentHistoryAccordian";
import TenantOtherIncomeHistoryTable from "./TenantOtherIncomeHistoryTable";
import { sortByYesNoFieldAndDates } from "../../utils/common";
import { Typography, Box } from "@material-ui/core";
import { GLOBALS } from "../../App";

const TenantEmploymentHistorySection = ({
  employmentHistory,
  isEditable = true,
  openEditDialog,
}) => {
  const onEditButtonClick = () => {
    openEditDialog(4);
  };

  if (employmentHistory.length === 0) {
    return (
      <TenantProfileSection
        title="Income"
        onEditButtonClick={onEditButtonClick}
        isEditable={isEditable}
      >
        <Typography color="primary" align="center">
          {GLOBALS.MESSAGES.NO_TENANT_EMPLOYMENT_HISTORY}
        </Typography>
      </TenantProfileSection>
    );
  }

  const jobHistory = employmentHistory.filter(
    history => history.incomeType === "EMPLOYMENT"
  );

  const otherHistory = employmentHistory.filter(
    history => history.incomeType !== "EMPLOYMENT"
  );

  return (
    <TenantProfileSection
      title="Income"
      onEditButtonClick={onEditButtonClick}
      isEditable={isEditable}
    >
      {jobHistory.length > 0 && (
        <>
          <Box>
            <Typography variant="h6" color="primary" gutterBottom>
              Employment History
            </Typography>
          </Box>
          <EmploymentHistoryContent employmentHistory={jobHistory} />
        </>
      )}
      {otherHistory.length > 0 && (
        <>
          <Box mt={4}>
            <Typography variant="h6" color="primary" gutterBottom>
              Other Income
            </Typography>
          </Box>
          <EmploymentHistoryContent
            isOtherIncome
            employmentHistory={otherHistory}
          />
        </>
      )}
    </TenantProfileSection>
  );
};

export const EmploymentHistoryContent = ({
  employmentHistory,
  isPrintPage = false,
  isOtherIncome = false,
}) => {
  const isTabletOrAbove = useMediaQuery(theme => theme.breakpoints.up("sm"));
  const sortedHistory = useMemo(() => {
    return sortByYesNoFieldAndDates(
      employmentHistory,
      "isCurrent",
      "startDate",
      "endDate"
    );
  }, [employmentHistory]);

  return (
    <>
      {!isTabletOrAbove && !isPrintPage ? (
        <TenantEmploymentHistoryAccordion
          isOtherIncome={isOtherIncome}
          employmentHistory={sortedHistory}
        />
      ) : isOtherIncome ? (
        <TenantOtherIncomeHistoryTable employmentHistory={sortedHistory} />
      ) : (
        <TenantEmploymentHistoryTable employmentHistory={sortedHistory} />
      )}
    </>
  );
};

export default TenantEmploymentHistorySection;
