import React, { useState, useEffect, useRef } from "react";
import useTenant from "../hooks/useTenant";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { format } from "date-fns";
import { useParams, useLocation } from "react-router-dom";
import { GLOBALS } from "../App";
import TenantProfileImage from "../components/tenant-profile/TenantProfileImage";
import TenantPersonalInformationSection from "../components/tenant-profile/TenantPersonalInformationSection";
import TenantHouseHoldSection from "../components/tenant-profile/TenantHouseHoldSection";
import TenantHousingHistorySection from "../components/tenant-profile/TenantHousingHistorySection";
import TenantAdditionalInformationSection from "../components/tenant-profile/TenantAdditionalInformationSection";
import TenantEmploymentHistorySection from "../components/tenant-profile/TenantEmploymentHistorySection";
import TenantBackgroundInformationSection from "../components/tenant-profile/TenantBackgroundInformationSection";
import TenantDocumentSection from "../components/tenant-profile/TenantDocumentSection";
import TenantEditDialog from "../components/tenant-profile/TenantEditDialog";
import TenantProfileCompleteness from "../components/tenant-profile/TenantProfileCompleteness.js";
import { getDraftApplication } from "../utils/common";
import DraftNotification from "../components/tenant-profile/DraftNotification";
import { Helmet } from "react-helmet";
import Reports from "../components/tenant-profile/Reports";
import TenantPlanSection from "../components/tenant-profile/TenantPlanSection";
import { useDispatch } from "react-redux";
import { openDialog } from "../store/actions/dialogActions";

export const TenantLockedContext = React.createContext(null);

export default function TenantProfilePage() {
  const { tenantId } = useParams();
  const reportsRef = useRef(null);
  const location = useLocation();
  const [tenantParams] = useState({
    tenantId: tenantId,
    action: "get-tenant-applications",
  });
  const [tenant, setTenant] = useState();
  const [editDialog, setEditDialog] = useState({
    open: false,
    step: null,
  });
  const tenantResult = useTenant(tenantParams);
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.state) {
      if (location.state.caller === "reportsRedirect") {
        if (reportsRef && reportsRef.current) {
          window.scrollTo({
            behavior: "smooth",
            top: reportsRef.current.offsetTop,
          });
          // clear the state object after viewing reports
          window.history.replaceState({}, document.title);
        }
      } else if (location.state.caller === "createTenantRedirect") {
        dispatch(openDialog("PURCHASE_INQUIRY_DIALOG"));
        // clear the state object after viewing reports
        window.history.replaceState({}, document.title);
      }
    }
  });

  useEffect(() => {
    if (tenantResult.status === "COMPLETE" && tenantResult.tenant) {
      setTenant(tenantResult.tenant);
    }
  }, [tenantResult]);

  if (tenantResult.error) {
    console.error(tenantResult.error);
    return (
      <Typography variant="h5" align="center" color="error">
        {GLOBALS.MESSAGES.TENANT_NOT_FOUND} {tenantId}
      </Typography>
    );
  }

  const openEditDialog = step => {
    setEditDialog({
      open: true,
      step,
    });
  };

  const closeDialog = () => {
    setEditDialog({
      open: false,
      step: null,
    });
  };

  const draftApplication = getDraftApplication(tenant?.applications || []);

  if (!tenant) {
    return (
      <Typography variant="h5" align="center" color="primary">
        Loading...
      </Typography>
    );
  }

  const isLocked = tenant.isLocked === "Y";

  return (
    <>
      <Helmet>
        <title>
          {GLOBALS.META_TAGS.TITLE.TENANT_PROFILE_PAGE +
            `${tenant.firstName} ${tenant.lastName}`}
        </title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <TenantLockedContext.Provider value={isLocked}>
        <Container maxWidth="lg">
          <TenantProfileImage
            image={tenant.picture}
            name={`${tenant.firstName} ${tenant.lastName}`}
            setTenant={setTenant}
          />
          {draftApplication && (
            <DraftNotification
              draftApplication={draftApplication}
              setTenant={setTenant}
            />
          )}
          <TenantProfileCompleteness tenant={tenant} />
          <TenantPersonalInformationSection
            email={tenant.email}
            phoneNumber={tenant.phoneNumber}
            dob={tenant.dob ? format(tenant.dob, GLOBALS.DATE_FORMAT) : null}
            bio={tenant.bio}
            tenantId={tenant.id}
            facebookId={tenant.facebookId}
            instagramId={tenant.instagramId}
            twitterId={tenant.twitterId}
            linkedinUrl={tenant.linkedinUrl}
            openEditDialog={openEditDialog}
          />
          <TenantHouseHoldSection
            occupants={tenant.household}
            openEditDialog={openEditDialog}
            tenant={tenant}
            headOfHouseholdId={tenant.headOfHousehold}
          />
          <TenantHousingHistorySection
            housingHistory={tenant.housingHistory}
            openEditDialog={openEditDialog}
          />
          <TenantEmploymentHistorySection
            employmentHistory={tenant.employmentHistory}
            openEditDialog={openEditDialog}
          />
          <TenantBackgroundInformationSection
            bankrupcy={tenant.bankrupcy}
            eviction={tenant.eviction}
            refusedRent={tenant.refusedRent}
            criminalOffenceCharges={tenant.criminalOffenceCharges}
            criminalOffenceConviction={tenant.criminalOffenceConviction}
            detailsForQuestions={tenant.detailsForQuestions}
            openEditDialog={openEditDialog}
          />
          <TenantAdditionalInformationSection
            pets={tenant.pets}
            vehicles={tenant.vehicles}
            openEditDialog={openEditDialog}
          />
          <TenantDocumentSection
            documents={tenant.documents}
            openEditDialog={openEditDialog}
          />
          <div ref={reportsRef} style={{ width: "100%", height: "100%" }}>
            {tenant.activePlan.planType === "UN" && (
              <Reports isComplete={tenant.isComplete} />
            )}
          </div>
          <TenantPlanSection activePlan={tenant.activePlan} />
          <TenantEditDialog
            tenant={tenant}
            setTenant={setTenant}
            {...editDialog}
            closeDialog={closeDialog}
          />
        </Container>
      </TenantLockedContext.Provider>
    </>
  );
}
