import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Box, Button, Hidden } from "@material-ui/core";
import { GLOBALS } from "../../App";

const useStyles = makeStyles(theme => ({
  title: {
    paddingBottom: theme.spacing(3),
  },
}));

export default function TenantHowItWorks(props) {
  const classes = useStyles();

  return (
    <div>
      <Typography
        variant="h4"
        align="center"
        color="primary"
        className={classes.title}
      >
        Tenant
      </Typography>
      <Typography variant="body1" align="center">
        Forget the hassles and extra fees when it comes to finding a new home.{" "}
        <Hidden xsDown>
          <tr />
        </Hidden>
        We take the worry and uncertainty out of the process to save you time
        and money.
      </Typography>
      <Box align="center" m={3}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => props.login()}
        >
          Sign in
        </Button>
        <Button
          style={{ marginLeft: "30px" }}
          variant="outlined"
          color="primary"
          onClick={() => window.open(GLOBALS.SOCIALS.FAQ, "_blank")}
        >
          Learn more
        </Button>
      </Box>
    </div>
  );
}
