import React, { useEffect, useState } from "react";
import clsx from "clsx";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { GLOBALS } from "../App";
import Link from "@material-ui/core/Link";
import { getStaticImgSrc } from "../nestiqaUtils";
import { Helmet } from "react-helmet";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import checkMark from "./check.png";
import ladyImage from "./lady.svg";
import houseImage from "./house.svg";
import homeDesktop from "./homepage_desktop.svg";
import homeMobile from "./homepage_mobile.svg";
import LoginDialog from "../components/LoginDialog";
import useAuth0Nesitqa from "../hooks/useAuth0Nestiqa";
import { useGA4React } from "ga-4-react";
import { logEvent } from "../utils/initGa4React";
import { useDispatch } from "react-redux";
import { openDialog } from "../store/actions/dialogActions";

const useStyles = makeStyles(theme => ({
  grid: {
    flexDirection: "column",
    alignItems: "center",
  },
  container: {
    marginTop: theme.spacing(8),
  },
  hero: {
    width: "100%",
    backgroundImage: `url(${getStaticImgSrc(homeDesktop)})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "150%",
    backgroundPosition: "center 140px",
    height: "600px",
  },
  title: {
    marginBottom: theme.spacing(1),
    width: "100%",
    fontWeight: "400",
  },
  subtitle: {
    marginTop: theme.spacing(2),
    width: "100%",
    fontWeight: "400",
  },
  section: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    maxWidth: 1000,
    margin: "0 auto",
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(5),
  },
  sectionLeft: {
    flexBasis: "50%",
    paddingRight: theme.spacing(3.5),
  },
  sectionRight: {
    flexBasis: "50%",
    paddingLeft: theme.spacing(3.5),
  },
  sectionTitle: {
    marginBottom: theme.spacing(0.5),
    width: "100%",
    fontWeight: "400",
  },
  sectionButton: {
    marginTop: theme.spacing(1.5),
    boxShadow: "0px 6px 30px rgba(147,64,255,0.3)",
  },
  sectionPoints: {
    marginTop: theme.spacing(4),
    paddingLeft: theme.spacing(3.5),
    listStyle: "none",
  },
  sectionPoint: {
    marginBottom: theme.spacing(1),
    position: "relative",

    "&::before": {
      content: `" "`,
      backgroundImage: `url(${getStaticImgSrc(checkMark)})`,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      position: "absolute",
      height: 12,
      width: 12,
      left: -24,
      top: 4,
    },
  },
  sectionLearnMore: {
    display: "block",
    marginLeft: theme.spacing(3.5),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    textTransform: "uppercase",
    fontWeight: 500,
    fontSize: "0.875rem",
  },
  sectionImage: {
    width: "100%",
  },

  [theme.breakpoints.down("md")]: {
    hero: {
      marginBottom: -100,
    },
    title: {
      fontSize: "36px",
    },
    subtitle: {
      fontSize: "24px",
    },
    sectionTitle: {
      fontSize: "22px",
    },
  },
  [theme.breakpoints.down("sm")]: {
    hero: {
      marginBottom: -180,
    },
  },
  [theme.breakpoints.down("xs")]: {
    hero: {
      width: "100%",
      backgroundImage: `url(${getStaticImgSrc(homeMobile)})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "350%",
      backgroundPosition: "-110px 140px",
      height: "50vh",
      marginBottom: -40,
    },
    title: {
      fontSize: "24px",
    },
    subtitle: {
      fontSize: "18px",
    },
    sectionTitle: {
      fontSize: "20px",
    },
    section: {
      flexDirection: "column-reverse",
      alignItems: "stretch",

      "&:nth-child(even)": {
        flexDirection: "column",
      },
    },
    sectionLeft: {
      flexBasis: "100%",
      paddingRight: 0,
    },
    sectionRight: {
      flexBasis: "100%",
      paddingLeft: 0,
    },
  },
}));

export default function HomePage() {
  const classes = useStyles();
  const history = useHistory();
  const ga = useGA4React();

  const [loginDialog, setLoginDialog] = useState({
    open: false,
  });

  const { isAuthenticated } = useAuth0Nesitqa();

  const closeLoginDialog = () => {
    setLoginDialog({
      open: false,
    });
  };

  const openLoginDialog = () => {
    setLoginDialog({
      open: true,
      preLogin: () => {
        localStorage.setItem("previousLocation", GLOBALS.PATHS.LANDLORD);
      },
      action: "create listing",
      onClose: closeLoginDialog,
    });
  };

  const onLandlordSectionButtonClick = () => {
    if (!!ga) {
      logEvent("landlord_ready_to_list", { page: "home" });
    }
    if (isAuthenticated) {
      history.push(GLOBALS.PATHS.LANDLORD);
    } else {
      openLoginDialog();
    }
  };

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      if (location.state.caller === "applicationSubmitRedirect") {
        dispatch(openDialog("PURCHASE_INQUIRY_DIALOG"));
        // clear the state object after viewing reports
        window.history.replaceState({}, document.title);
      }
    }
  });

  return (
    <>
      <Helmet>
        <title>{GLOBALS.META_TAGS.TITLE.HOME_PAGE}</title>
        <meta
          name="description"
          content={GLOBALS.META_TAGS.DESCRIPTION.HOME_PAGE}
        />
      </Helmet>

      <LoginDialog {...loginDialog} />

      <Container
        maxWidth="lg"
        className={clsx(classes.container, "home-container")}
      >
        <div className={classes.hero}>
          <Typography className={classes.title} variant="h3" align="center">
            {GLOBALS.SITE_TEXTS.HOME_PAGE_TITLE_LINE1}
          </Typography>
          <Typography className={classes.title} variant="h3" align="center">
            {GLOBALS.SITE_TEXTS.HOME_PAGE_TITLE_LINE2}
          </Typography>
          <Typography
            className={classes.subtitle}
            color="primary"
            variant="h4"
            align="center"
          >
            {GLOBALS.SITE_TEXTS.HOME_PAGE_SUBTITLE}
          </Typography>
        </div>
        <div className={clsx(classes.section)}>
          <div className={clsx(classes.sectionLeft)}>
            <Typography className={classes.sectionTitle} variant="h5">
              {GLOBALS.SITE_TEXTS.HOME_PAGE_TENANT_SECTION.TITLE_LINE1}
            </Typography>
            <Typography className={classes.sectionTitle} variant="h5">
              {GLOBALS.SITE_TEXTS.HOME_PAGE_TENANT_SECTION.TITLE_LINE2}
            </Typography>
            <Button
              component={Button}
              variant="contained"
              color="primary"
              className={classes.sectionButton}
              disableElevation
              onClick={() => {
                if (!!ga) {
                  logEvent("tenant_view_listings");
                }
                history.push(GLOBALS.PATHS.LISTINGS, { fromHomepage: true });
              }}
            >
              {GLOBALS.SITE_TEXTS.HOME_PAGE_TENANT_SECTION.BUTTON_TEXT}
            </Button>
            <ul className={classes.sectionPoints}>
              {GLOBALS.SITE_TEXTS.HOME_PAGE_TENANT_SECTION.POINTS.map(point => (
                <li key={point} className={classes.sectionPoint}>
                  {point}
                </li>
              ))}
            </ul>
            <Link
              component="a"
              underline="none"
              color="secondary"
              href="https://resources.nestiqa.com/faq"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.sectionLearnMore}
            >
              Learn more
            </Link>
          </div>
          <div className={clsx(classes.sectionRight)}>
            <img
              src={getStaticImgSrc(houseImage)}
              alt="House"
              className={classes.sectionImage}
            />
          </div>
        </div>

        <div className={clsx(classes.section)}>
          <div className={clsx(classes.sectionLeft)}>
            <img
              src={getStaticImgSrc(ladyImage)}
              alt="Lady"
              className={classes.sectionImage}
            />
          </div>
          <div className={clsx(classes.sectionRight)}>
            <Typography className={classes.sectionTitle} variant="h5">
              {GLOBALS.SITE_TEXTS.HOME_PAGE_LANDLORD_SECTION.TITLE_LINE1}
            </Typography>
            <Typography className={classes.sectionTitle} variant="h5">
              {GLOBALS.SITE_TEXTS.HOME_PAGE_LANDLORD_SECTION.TITLE_LINE2}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              className={classes.sectionButton}
              disableElevation
              onClick={onLandlordSectionButtonClick}
            >
              {GLOBALS.SITE_TEXTS.HOME_PAGE_LANDLORD_SECTION.BUTTON_TEXT}
            </Button>
            <ul className={classes.sectionPoints}>
              {GLOBALS.SITE_TEXTS.HOME_PAGE_LANDLORD_SECTION.POINTS.map(
                point => (
                  <li key={point} className={classes.sectionPoint}>
                    {point}
                  </li>
                )
              )}
            </ul>
            <Link
              component={RouterLink}
              underline="none"
              color="secondary"
              to={{
                pathname: GLOBALS.PATHS.LANDLORD,
                state: { fromHomepage: false },
              }}
              className={classes.sectionLearnMore}
            >
              Learn more
            </Link>
          </div>
        </div>
      </Container>
    </>
  );
}
