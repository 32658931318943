import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { GLOBALS } from "../../App";

const useStyles = makeStyles(theme => ({
  textField: {
    "& .MuiInputLabel-formControl": {
      color: theme.palette.grey[400],
    },
  },
  root: {
    "& .MuiDialog-paper": {
      width: "100%",
      maxWidth: "700px",
    },
  },
  error: {
    color: "#ff1744",
    lineHeight: 1,
  },
}));

const LandlordAppActionDialog = ({
  title = "",
  open = false,
  onClose,
  message = "",
  handleRequestBtnClick,
  hasComments = false,
  commentsLabel = "Please elaborate on... ",
  btnLabel = "Request",
}) => {
  const classes = useStyles();
  const [comments, setComments] = useState("");

  const handleClose = () => {
    setComments("");
    onClose();
  };

  const hasError = comments.length > 255;

  return (
    <Dialog open={open} onClose={handleClose} className={classes.root}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
        {hasComments && (
          <TextField
            className={classes.textField}
            variant="outlined"
            fullWidth
            rows={3}
            multiline
            value={comments}
            onChange={e => setComments(e.target.value)}
            label={commentsLabel}
            error={hasError}
          />
        )}
        {hasError && (
          <p className={classes.error}>{GLOBALS.MESSAGES.MAX_255_CHARS}</p>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (!hasError) {
              handleRequestBtnClick(comments);
            }
          }}
          color="primary"
          variant="contained"
        >
          {btnLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LandlordAppActionDialog;
