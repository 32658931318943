import React, { useState, useEffect } from "react";
import ListingDetails from "../components/ListingDetails";
import { Container, Typography } from "@material-ui/core";
import { GLOBALS } from "../App";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { trimContent, getFullAddress } from "../nestiqaUtils";
import { getListing } from "../hooks/ListingApi";

export default function ListingDetailsPage() {
  const { listingNumber } = useParams();
  const [listingResult, setListingResult] = useState();
  const [fullAddress, setFullAddress] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    const resultsCallback = (isSuccess, apiResult, apiError) => {
      setListingResult({
        listing: isSuccess ? apiResult : null,
        errors: isSuccess ? null : [apiError],
      });
    };

    getListing(listingNumber, null, resultsCallback);
  }, [listingNumber]);

  useEffect(() => {
    if (listingResult?.listing) {
      const listing = listingResult.listing;
      const address = getFullAddress(listing);
      const description = trimContent(listing.description, 300);
      setFullAddress(address);
      setDescription(description);
    }
  }, [listingResult]);

  if (!listingResult) {
    return (
      <Typography variant="h5" align="center" color="primary">
        Loading...
      </Typography>
    );
  }

  if (listingResult.errors) {
    console.error(listingResult.errors);
    return (
      <Typography variant="h5" align="center" color="error">
        {GLOBALS.MESSAGES.LISTING_NOT_FOUND} {listingNumber}
      </Typography>
    );
  }

  if (listingResult.listing.status !== "ACTIVE") {
    return (
      <Container>
        <Helmet>
          <meta name="robots" content="noindex" />
          <meta name="description" content={description} />
        </Helmet>
        <Typography variant="h5" align="center" color="primary">
          {GLOBALS.MESSAGES.LISTING_INACTIVE}
        </Typography>
      </Container>
    );
  }

  return (
    <Container>
      <Helmet>
        <title>
          {fullAddress + GLOBALS.META_TAGS.TITLE.LISTING_DETAILS_PAGE}
        </title>
        <meta name="description" content={description} />
      </Helmet>
      <ListingDetails listing={listingResult.listing} />
    </Container>
  );
}
