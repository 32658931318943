import { OPEN_DIALOG, CLOSE_DIALOG } from "./types";

export const openDialog = (dialogType, dialogProps = {}) => {
  return {
    type: OPEN_DIALOG,
    payload: {
      dialogType,
      dialogProps,
    },
  };
};

export const closeDialog = dialogType => {
  return {
    type: CLOSE_DIALOG,
    payload: {
      dialogType,
    },
  };
};
