import React, { useEffect, useState } from "react";
import { Container, Typography } from "@material-ui/core";
import { tenantGetApplication } from "../utils/ApplicationApi";
import { GLOBALS } from "../App";
import ApplicationHeader from "../components/application/ApplicationHeader";
import ApplicationOverview from "../components/application/ApplicationOverview";
import ApplicationDetailed from "../components/application/ApplicationDetailed";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import useAuth0Nesitqa from "../hooks/useAuth0Nestiqa";

export default function UpdateApplicationPage(props) {
  const [application, setApplication] = useState({});
  const { applicationId } = useParams();
  const { accessToken } = useAuth0Nesitqa("");
  const [error, setError] = useState();

  useEffect(() => {
    if (!accessToken) return;

    tenantGetApplication(
      applicationId,
      accessToken,
      (isSuccess, apiResult, apiError) => {
        if (isSuccess) {
          setApplication(apiResult);
        } else {
          setError(apiError);
        }
      }
    );
  }, [accessToken, applicationId]);

  if (error) {
    console.error(error);
    return (
      <Typography variant="h5" align="center" color="error">
        {GLOBALS.MESSAGES.ERROR_COMPLETING_ACTION}
      </Typography>
    );
  }

  if (!application || !accessToken) {
    return (
      <Container>
        <Typography variant="h5" align="center" color="primary">
          Loading...
        </Typography>
      </Container>
    );
  }

  const fullAddress = application.listing
    ? application.listing.fullAddress
    : "";

  return (
    <Container>
      <Helmet>
        <title>
          {GLOBALS.META_TAGS.TITLE.UPDATE_APPLICATION_PAGE + fullAddress}
        </title>
      </Helmet>
      <ApplicationHeader />
      {application.listing && <ApplicationOverview application={application} />}
      <ApplicationDetailed application={application} token={accessToken} />
    </Container>
  );
}
