import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";

function getStyles(theme) {
  return {
    root: {
      borderRadius: "0px 30px 0px 0px",
      borderTop: "2px solid",
      borderBottom: "2px solid",
      borderRight: "2px solid",
      backgroundColor: "white",
      borderColor: theme.palette.primary.dark,
      marginLeft: "-20px",
      textTransform: "uppercase",
      width: "auto",
      minWidth: "120px",
      color: theme.palette.primary.dark,
      "&:focus": {
        opacity: 1,
      },

      "& span": {
        [theme.breakpoints.down("xs")]: {
          marginLeft: "12px",
        },
      },
    },
    wrapper: {
      width: "auto",
    },
    selected: {
      borderBottom: "none",
      zIndex: 5,
    },
  };
}

function RoundTab(props) {
  return <Tab disableRipple {...props} />;
}

export default withStyles(getStyles)(RoundTab);
