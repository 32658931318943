import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  LinearProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { GLOBALS } from "../App";
import NestiqaAlertDialog from "./NestiqaAlertDialog";
import { purchaseInquiry } from "../utils/TenantApi";
import { useDispatch, useSelector } from "react-redux";
import { getDialog } from "../store/selectors/dialogSelectors";
import { closeDialog } from "../store/actions/dialogActions";
import { useCookies } from "react-cookie";

const useStyles = makeStyles(theme => ({
  dialogAction: {
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),

    [theme.breakpoints.down(576)]: {
      display: "flex",
      flexDirection: "column-reverse",
      alignItems: "flex-end",

      "& > button": {
        marginTop: theme.spacing(1),
      },
    },
  },
  linearProgress: {
    marginTop: theme.spacing(2),
  },
}));

export default function PurchaseInquiryDialog({ accessToken }) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [dialogControls, setDialogControls] = useState({
    open: false,
    title: "",
    message: "",
  });

  function resultsCallback(isSuccess, result, error) {
    setLoading(false);
    onClose();
    if (isSuccess) {
      setDialogControls({
        open: true,
        title: "Success",
        message: "We will be contacting you soon!",
      });
      setCookie("ns_free_consultation_obtained", "true", {
        expires: new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 30),
      });
    } else {
      setDialogControls({
        open: true,
        title: "Error",
        message: GLOBALS.MESSAGES.ERROR_COMPLETING_ACTION,
      });
    }
  }

  async function callPurchaseInquiry() {
    setLoading(true);
    purchaseInquiry(accessToken, resultsCallback);
  }

  const { open } = useSelector(getDialog("PURCHASE_INQUIRY_DIALOG"));

  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(closeDialog("PURCHASE_INQUIRY_DIALOG"));
  };

  const [cookies, setCookie] = useCookies(["ns_free_consultation_obtained"]);

  return (
    <>
      <NestiqaAlertDialog
        {...dialogControls}
        onClose={() => setDialogControls({ open: false })}
      />
      <Dialog
        open={
          open &&
          !cookies.ns_free_consultation_obtained &&
          !localStorage.getItem("ns_free_consultation_declined")
        }
        onClose={onClose}
        disableEscapeKeyDown
        disableBackdropClick
      >
        <DialogTitle>Buy and Save</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Did you know that buying a house can save you money in the long run?
            At Nestiqa we offer free consultation to check your eligibility.
          </DialogContentText>

          {loading && <LinearProgress className={classes.linearProgress} />}
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <Button
            onClick={() => {
              localStorage.setItem("ns_free_consultation_declined", "true");
              onClose();
            }}
          >
            No Thanks
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={callPurchaseInquiry}
          >
            Get Free Consultation
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
