import { GLOBALS } from "../App";
import { runFetch, camelToSnake, snakeToCamel } from "../nestiqaUtils";

export const getLandlord = async (id, accessToken, callback) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.LANDLORDS_API}${id}/`,
    "get",
    { "Content-Type": "application/json" },
    null,
    accessToken,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, prepareLandlordResult(apiResult), apiError)
  );
};

export const getLandlordInfo = async (accessToken, callback) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.LANDLORD_INFO_API}`,
    "get",
    { "Content-Type": "application/json" },
    null,
    accessToken,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, prepareLandlordResult(apiResult), apiError)
  );
};

export const getLandlordListings = async (accessToken, active, callback) => {
  let endpoint = active ? "active" : "inactive";
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.LANDLORD_LISTINGS_API}${endpoint}/`,
    "get",
    { "Content-Type": "application/json" },
    null,
    accessToken,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, snakeToCamel(apiResult), apiError)
  );
};

export const getLandlordProperties = async (accessToken, callback) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.LANDLORD_PROPERTIES_API}`,
    "get",
    { "Content-Type": "application/json" },
    null,
    accessToken,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, snakeToCamel(apiResult), apiError)
  );
};

export const updateLandlord = async (landlord, accessToken, callback) => {
  const preparedLandlord = camelToSnake(landlord);
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.LANDLORDS_API}${preparedLandlord.id}/`,
    "put",
    { "Content-Type": "application/json" },
    JSON.stringify(preparedLandlord),
    accessToken,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, prepareLandlordResult(apiResult), apiError)
  );
};

export const setLandlordPicture = async (
  pictureFile,
  landlordId,
  accessToken,
  setResultsCallback
) => {
  const formData = new FormData();
  formData.set("picture", pictureFile);
  await runFetch(
    `${GLOBALS.API_BASE_URL}/landlord/${landlordId}/picture/`,
    "put",
    {},
    formData,
    accessToken,
    setResultsCallback
  );
};

export const createLandlord = async (accessToken, callback) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.LANDLORDS_API}`,
    "post",
    { "Content-Type": "application/json" },
    null,
    accessToken,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, snakeToCamel(apiResult), apiError)
  );
};

export function prepareLandlordResult(landlord) {
  if (!landlord) return landlord;
  const result = snakeToCamel(landlord);
  if (result.phoneNumber) result.phoneNumber = result.phoneNumber.substring(2);
  return result;
}

export const acceptTerms = async (accessToken, callback) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.LANDLORD_ACCEPT_TERMS}`,
    "post",
    { "Content-Type": "application/json" },
    {},
    accessToken,
    callback
  );
};

export const resetReportsLandlord = async (accessToken, callback) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.LANDLORD_RESET_REPORTS}`,
    "delete",
    { "Content-Type": "application/json" },
    null,
    accessToken,
    callback
  );
};

export const getAcceptedTerms = async (accessToken, callback) => {
  await runFetch(
    `${GLOBALS.API_BASE_URL}${GLOBALS.PATHS.LANDLORD_ACCEPT_TERMS}`,
    "get",
    { "Content-Type": "application/json" },
    null,
    accessToken,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, prepareLandlordResult(apiResult), apiError)
  );
};

export const landlordExtAction = async (
  accessToken,
  landlordId,
  action,
  method,
  body,
  callback
) => {
  let api_path = GLOBALS.PATHS.LANDLORD_EXT_API.replace(
    ":landlordId",
    landlordId
  );
  await runFetch(
    `${GLOBALS.API_BASE_URL}${api_path}${action}/`,
    method,
    { "Content-Type": "application/json" },
    JSON.stringify(body),
    accessToken,
    (isSuccess, apiResult, apiError) =>
      callback(isSuccess, snakeToCamel(apiResult), apiError)
  );
};

export const landlordExtStatus = async (landlordId, callback) => {
  landlordExtAction(null, landlordId, "status", "get", null, callback);
};

export const landlordExtStart = async (accessToken, landlordId, callback) => {
  landlordExtAction(accessToken, landlordId, "start", "put", {}, callback);
};

export const landlordExtProceed = async (accessToken, landlordId, callback) => {
  landlordExtAction(accessToken, landlordId, "proceed", "put", {}, callback);
};

export const landlordExtInfo = async (
  accessToken,
  landlordId,
  info,
  callback
) => {
  let body = { info: info };
  landlordExtAction(accessToken, landlordId, "info", "put", body, callback);
};

export const landlordExtGetComm = async (accessToken, landlordId, callback) => {
  landlordExtAction(accessToken, landlordId, "comm", "post", {}, callback);
};

export const landlordExtValidateComm = async (
  accessToken,
  landlordId,
  code,
  callback
) => {
  let body = { code: code };
  landlordExtAction(accessToken, landlordId, "comm", "put", body, callback);
};

export const landlordExtAccept = async (accessToken, landlordId, callback) => {
  landlordExtAction(accessToken, landlordId, "accept", "put", {}, callback);
};

export const landlordExtComplete = async (
  accessToken,
  landlordId,
  callback
) => {
  landlordExtAction(accessToken, landlordId, "complete", "put", {}, callback);
};
