import React from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@material-ui/core";
import { Room } from "@material-ui/icons";
import {
  getOpenApplicationStatus,
  formatPhoneNumber,
  getLabel,
} from "../../nestiqaUtils";
import { formatter } from "../../utils/common";
import { useStyles } from "./OpenApplicationTable";
import { format } from "date-fns";
import clsx from "clsx";

import StyledTableCell from "../StyledTableCell";
import bedIcon from "../../static/images/bed_icon.png";
import bathIcon from "../../static/images/bath_icon.png";

const ApplicationsPendingReportsTable = ({
  data,
  tenantId,
  handleApply,
  handleJoin,
  loading,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = application => {
    if (application.status === "SUBMITTED")
      return history.push(`/application/${application.id}/join`);
    return history.push(`/application/${application.id}/update`);
  };

  const handleSubmit = app => {
    if (parseInt(app.tenantId) === parseInt(tenantId)) {
      const payload = {
        id: app.id,
        cover_letter: app.cover_letter,
        move_in_date: app.flexible
          ? null
          : format(app.moveInDate, "yyyy-MM-dd"),
        listing_seen: app.listingSeen,
      };

      return handleApply(payload);
    } else return handleJoin(app.id);
  };

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow className={classes.tableHead}>
          <StyledTableCell
            className={classes.noBorder}
            align="left"
            width="20%"
          >
            Address
          </StyledTableCell>
          <StyledTableCell
            className={classes.noBorder}
            align="center"
            width="15%"
          >
            Info
          </StyledTableCell>
          <StyledTableCell
            className={clsx(classes.noBorder, classes.contactHeader)}
            width="25%"
          >
            Contact
          </StyledTableCell>
          <StyledTableCell className={classes.noBorder}>Status</StyledTableCell>
          <StyledTableCell className={classes.noBorder} />
        </TableRow>
      </TableHead>
      <TableBody className={classes.tbody}>
        {data.map((application, i) => (
          <TableRow key={application.id}>
            <StyledTableCell
              className={clsx(
                i !== 0 && classes.topBorder,
                classes.noBorder,
                classes.paddingY
              )}
              align="left"
              onClick={() => handleClick(application)}
            >
              <div className={classes.address}>
                <Room />
                <div>
                  {application.listing.address1}
                  {application.listing.address2 !== ""
                    ? `, ${application.listing.address2}`
                    : null}
                  <br />
                  {application.listing.city}, {application.listing.state},{" "}
                  {application.listing.zipCode}
                </div>
              </div>
            </StyledTableCell>
            <StyledTableCell
              className={clsx(
                i !== 0 && classes.topBorder,
                classes.noBorder,
                classes.paddingY
              )}
              align="center"
              onClick={() => handleClick(application)}
            >
              <span>{formatter.format(application.listing.rent)}</span>
              <div className={classes.bedsBaths}>
                <span>
                  {getLabel(application.listing.bedrooms, "BEDROOMS")}
                </span>
                <div>
                  <img src={bedIcon} alt="bedrooms" />
                </div>
                <span>
                  {getLabel(application.listing.bathrooms, "BATHROOMS")}
                </span>
                <div>
                  <img src={bathIcon} alt="bathrooms" />
                </div>
              </div>
            </StyledTableCell>
            <StyledTableCell
              className={clsx(
                i !== 0 && classes.topBorder,
                classes.noBorder,
                classes.paddingY,
                classes.contactHeader
              )}
              align="left"
              onClick={() => handleClick(application)}
            >
              <div className={classes.contact}>
                {application.listing.contactName}
                <span>
                  {formatPhoneNumber(application.listing.contactPhone, true)}
                </span>
              </div>
            </StyledTableCell>
            <StyledTableCell
              className={clsx(
                i !== 0 && classes.topBorder,
                classes.noBorder,
                classes.paddingY
              )}
              align="left"
              onClick={() => handleClick(application)}
            >
              <div className={classes.status}>
                <span>
                  {getOpenApplicationStatus(
                    application.status,
                    application.tenantName
                  )}
                </span>
              </div>
            </StyledTableCell>
            <StyledTableCell
              className={clsx(
                i !== 0 && classes.topBorder,
                classes.noBorder,
                classes.paddingY,
                classes.alignRight
              )}
            >
              <Button
                variant="contained"
                color="primary"
                className={classes.submitButton}
                onClick={() => handleSubmit(application)}
                disabled={loading}
              >
                Submit
              </Button>
            </StyledTableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default ApplicationsPendingReportsTable;
