export const PATHS = {
  ROOT: "/",
  LISTINGS: "/listings",
  LISTING_DETAILS: "/listing/:listingNumber",
  PROFILE: "/profile",
  LANDLORD: "/landlord",
  TENANT: "/tenant",
  PRIVACY_POLICY: "/privacy-policy",
  TERMS_OF_USE: "/terms-of-use",
  CONTACT_US_PAGE: "/contact-us",
  PARTNERSHIPS: "/partnerships",
  USERS: "/users",
  CALLBACK: "/callback",
  LISTING_IMAGES: "/listing-images/",
  LISTING_SEARCH: "/listings/?",
  CREATE_TENANT: "/tenant/:tenantId/create",
  UPDATE_LISTING: "/landlord/listing/:listingNumber/update",
  LANDLORD_LISTING_PAGE: "/landlord/listing/:listingNumber",
  PRINT_PAGE: "/landlord/listing/:listingNumber/print",
  TENANT_PROFILE: "/tenant/:tenantId/profile",
  TENANT_PLAN: "/tenant/:tenantId/plan",
  TENANT_APPLICATIONS: "/tenant/:tenantId/applications",
  UPDATE_APPLICATION: "/application/:applicationId/update",
  LANDLORD_APPLICATION: "/landlord/application/:applicationId",
  LANDLORD_APP_PRINT_PAGE: "/landlord/application/:applicationId/print",
  TENANTS_API: "/tenants/",
  PURCHASE_INQUIRY_API: "/tenants/purchase-inquiry/",
  HOUSEHOLD_API: "/household/",
  USER_API: "/user/",
  USER_SAVED_SEARCHES_API: "/user/saved-searches/",
  NEWSLETTER_API: "/newsletter/",
  INVITATION_API: "/invitation/",
  LANDLORDS_API: "/landlord/",
  LANDLORD_EXT_API: "/landlord/ext/:landlordId/",
  LANDLORD_INFO_API: "/landlord/info/",
  LISTINGS_API: "/listings/",
  LANDLORD_LISTINGS_API: "/landlord/listings/",
  LANDLORD_PROPERTIES_API: "/landlord/listings/properties/",
  HOUSING_HISTORY_API: "/housing/",
  EMPLOYMENT_HISTORY_API: "/employment/",
  TENANT_GET_OR_CREATE: "/tenant/get-or-create/",
  LANDLORD_GET_OR_CREATE: "/landlord/get-or-create/",
  TENANT_UPGRADE_PLAN: "/tenant/upgrade/",
  TENANT_APPLY_COUPON: "/tenant/coupon/",
  TENANT_DOWNGRADE_PLAN: "/tenant/downgrade/",
  MY_LISTINGS_API: "/landlord/listings/",
  LISTING_APPLICATIONS: "/listing/:listingNumber/applications",
  APPLICATION_API: "/applications/",
  LANDLORD_APPLICATION_API: "/landlord/application/",
  LISTING_APPLICATIONS_API: "/listing-applications/",
  TENANT_DOCUMENTS: "/tenant/documents/",
  REDIRECT_USER: "/redirect",
  JOIN_HOUSEHOLD: "/household/join/:invitationCode",
  REPORTS_ROOT: "/tenant/reports/",
  REPORTS_RESET: "/tenant/reports/reset/",
  ACCEPT_TERMS: "/tenant/reports/accept/",
  REPORTS_INIT: "/tenant/reports/init/",
  LANDLORD_ACCEPT_TERMS: "/landlord/terms/accept/",
  LANDLORD_RESET_REPORTS: "/landlord/reports/reset/",
  REPORTS_NOTIFIED: "/tenant/reports/notified/",
  REPORTS_INFO: "/tenant/reports/info/",
  REPORTS_EXAM: "/tenant/reports/exam/",
  REPORTS_ANSWERS: "/tenant/reports/answers/",
  REPORTS_PAGE: "/tenant/:tenantId/reports",
  LANDLORD_REPORTS_PAGE: "/landlord/reports",
  APPLICATIONS_PENDING_REPORTS_PAGE: "/tenant/:tenantId/applications/reports",
  JOIN_APPLICATION_PAGE: "/application/:applicationId/join",
  LANDLORD_EXT_PAGE: "/landlord/:landlordId/ext",
  LANDLORD_ALL_APPS_PAGE: "/landlord/applications",
  USER_ADMINISTRATION_PAGE: "/useradmin",
  LANDLORD_SCREEN_PAGE: "/landlord/screen",
  LANDLORD_SCREEN_PROPERTY_PAGE: "/landlord/screen/property/:listingNumber",
  PROPERTY_APPLY_PAGE: "/listing/:listingNumber/apply",
  USER_TYPE_PAGE: "/user-type",
  USER_SAVED_SEARCHES_PAGE: "/user/saved-searches",
  TURN_OFF_ALERTS_PAGE: "/user/saved-searches/:turnOffCode/turn-off",
  SAMPLE_REPORTS_PAGE: "/landlord/sample-reports",
};
