import React from "react";

const Twitter = props => {
  return (
    <svg width="28" height="28" xmlns="true" fill="#3ce0e5" {...props}>
      <svg
        xmlns="true"
        width="28"
        height="28"
        viewBox="0.808 0.662 32.677 32.677"
      >
        <path
          paintOrder="stroke fill markers"
          fillRule="evenodd"
          d="M17.146 33.339a16.287 16.287 0 0 1-11.553-4.785A16.283 16.283 0 0 1 .808 17 16.284 16.284 0 0 1 5.593 5.447 16.282 16.282 0 0 1 17.146.662a16.283 16.283 0 0 1 11.553 4.785A16.287 16.287 0 0 1 33.484 17 16.286 16.286 0 0 1 28.7 28.553a16.285 16.285 0 0 1-11.553 4.786zm-9.027-9.052a11.068 11.068 0 0 0 5.985 1.755c6.956 0 11.112-5.65 11.112-11.112 0-.171-.004-.341-.011-.505a7.88 7.88 0 0 0 1.949-2.021 7.808 7.808 0 0 1-2.243.614 3.908 3.908 0 0 0 1.717-2.16 7.796 7.796 0 0 1-2.48.947 3.91 3.91 0 0 0-6.655 3.563 11.08 11.08 0 0 1-8.05-4.081 3.902 3.902 0 0 0-.529 1.963c0 1.308.65 2.524 1.738 3.25a3.894 3.894 0 0 1-1.769-.488v.05a3.915 3.915 0 0 0 3.133 3.829 3.9 3.9 0 0 1-1.764.067A3.905 3.905 0 0 0 13.9 22.67a7.793 7.793 0 0 1-4.85 1.672c-.313 0-.626-.019-.931-.055z"
        />
      </svg>
    </svg>
  );
};

export default Twitter;
