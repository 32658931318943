import { getAvailableReports } from "../../utils/ReportsApi";
import {
  GET_AVAILABLE_REPORTS,
  GET_AVAILABLE_REPORTS_ERROR,
  GET_AVAILABLE_REPORTS_LOADING,
  SET_REPORTS,
  SET_SCREENING_REQUEST_ID,
  CLEAR_REPORTS,
  SET_CREATE_REPORTS_LATER,
  SET_REPORTS_NOTIFIED,
  SET_TO_SHARE_REPORTS,
} from "./types";

export function callGetAvailableReports(accessToken) {
  return function (dispatch) {
    dispatch({ type: GET_AVAILABLE_REPORTS_LOADING });

    getAvailableReports(accessToken, (isSuccess, result, error) => {
      if (isSuccess) {
        dispatch({ type: GET_AVAILABLE_REPORTS, payload: result.status });
        if (result.screeningRequestId) {
          dispatch({
            type: SET_SCREENING_REQUEST_ID,
            payload: result.screeningRequestId,
          });
        }

        if (result.status === "REPORTS_READY") {
          dispatch({
            type: SET_REPORTS,
            payload: result.reports.split(","),
          });
        }
      }
      if (error) {
        dispatch({ type: GET_AVAILABLE_REPORTS_ERROR, payload: error });
        console.error(error);
      }
    });
  };
}

export function setScreeningRequestId(id) {
  return function (dispatch) {
    dispatch({ type: SET_SCREENING_REQUEST_ID, payload: id });
  };
}

export function setReportStatus(status) {
  return function (dispatch) {
    dispatch({ type: GET_AVAILABLE_REPORTS, payload: status });
  };
}

export function clearReports() {
  return function (dispatch) {
    dispatch({ type: CLEAR_REPORTS });
  };
}

export function setCreateReportsLater() {
  return function (dispatch) {
    dispatch({ type: SET_CREATE_REPORTS_LATER });
  };
}

export function setReportsNotified() {
  return function (dispatch) {
    dispatch({ type: SET_REPORTS_NOTIFIED });
  };
}

export function setToShareReports(payload) {
  return function (dispatch) {
    dispatch({ type: SET_TO_SHARE_REPORTS, payload });
  };
}
